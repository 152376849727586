/* eslint-disable camelcase */
import React from "react";

import {
    CategoriesIcon,
    ItemIcon,
    TagIcon,
    WorkflowIcon,
    CarteIcon,
} from "@components/Common/SvgIcons";

type WORKfLOWTYPE = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
};

export const WORKfLOW_LIST: WORKfLOWTYPE[] = [
    {
        title: "CATEGORIES",
        Icon: CategoriesIcon,
    },
    {
        title: "ITEMS",
        Icon: ItemIcon,
    },

    {
        title: "TAGS",

        Icon: TagIcon,
    },
    {
        title: "WORKFLOW",

        Icon: WorkflowIcon,
    },
    {
        title: "CHOOSE A CARD",

        Icon: CarteIcon,
    },
];
/* eslint-disable no-unused-vars */
export const enum WORKFLOW_LIST {
    BASICCOMPOSITION = "composition de base",
    ADD = "add",
    TRIGGER = "trigger",
    ITEMS = "items",
    CATEGORIES = "categories",
    STEPS = "steps",
    OPTIONS = "options",
    TYPESTEPS = "typeSteps",
    TYPEADD = "typeAdd",
    RankOptions = "rankOptions",
    RankBasicComposition = "rankBasicComposition",
}
