import React from "react";

import { useTranslation } from "react-i18next";
import { CardHeader } from "reactstrap";

import {
    RefreshIcon,
    CrossIcon,
    StyledIconButton,
    StepIcon,
} from "@aureskonnect/react-ui";
import { ACTIONS } from "@helpers/actions";

type StyledStepHeaderPropsType = {
    setActionColumnDisplay: Function;
    dispatchStepItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    setItemToEditVisibility: React.Dispatch<
        React.SetStateAction<visibilityCategoryType>
    >;
};
export default function StyledStepHeader({
    setActionColumnDisplay,
    dispatchStepItem,
    setItemToEditVisibility,
}: StyledStepHeaderPropsType): JSX.Element {
    const { t } = useTranslation();

    const handleCrossIconClick = (e: any) => {
        e.preventDefault();
        setActionColumnDisplay(false);
        setItemToEditVisibility({
            isVisible: true,
            basicCompositionVisibility: true,
            default: {},
        });
        dispatchStepItem({
            type: ACTIONS.ClEAR,
            payload: {},
        });
    };

    const handleRefreshIconClick = () => {
        dispatchStepItem({
            type: ACTIONS.ClEAR,
            payload: {},
        });
        setItemToEditVisibility({
            isVisible: true,
            basicCompositionVisibility: true,
            default: {},
        });
    };
    return (
        <CardHeader
            className="Headercard  d-flex
            justify-content-between"
        >
            <div className=" d-flex align-items-center justify-content-center  ">
                <StepIcon
                    fill="#6E5100"
                    width="32px"
                    height="32px"
                    style={{
                        borderRadius: "4px",
                    }}
                />

                <div
                    className="  d-flex align-items-center d-flex align-items-center"
                    style={{
                        backgroundColor: "#6E5100",
                        borderRadius: "4px",
                        fontSize: 20,
                    }}
                />

                <h4
                    className="m-0 pl-2"
                    style={{ font: "normal normal 600 20px/27px Segoe UI" }}
                >
                    {t("Step")}
                </h4>
            </div>

            <div className="d-flex align-items-center ">
                <div
                    tabIndex={0}
                    role="button"
                    onClick={handleRefreshIconClick}
                >
                    <StyledIconButton icon="RefreshIcon">
                        <RefreshIcon
                            height={20}
                            width={20}
                            cursor="pointer"
                            onClick={handleRefreshIconClick}
                        />
                    </StyledIconButton>
                </div>

                <div className=" bd-highlight align-items-center">
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={handleCrossIconClick}
                    >
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={20}
                                width={20}
                                onClick={handleCrossIconClick}
                            />
                        </StyledIconButton>
                    </div>
                </div>
            </div>
        </CardHeader>
    );
}
