import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { RootState } from "@store/index";
import { LoadingDataAnimation } from "@components/Common";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { PageTitle } from "@components/.";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import { TagsList } from "./TagsList";
import { AddTag } from "./AddTag";

import "./style.css";

export default function Tags(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );

    React.useEffect((): (() => void) => {
        return (): void => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);
    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <>
            <PageTitle title="Tags" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content "
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },
                        { item: t("tags".toUpperCase()), link: "#" },
                    ]}
                />

                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "Tags are used to group, search and filter different elements (categories, articles)."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<TagsList />}
                        ActionColumnComponent={
                            <AddTag setIsLoading={setIsLoading} />
                        }
                    />
                </Container>
            </motion.div>
        </>
    );
}
