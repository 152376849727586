import React from "react";

import { StyledModal, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
} from "@components/Common/";

type MultiDuplicateItemsModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};

export default function MultiDuplicateItemsModal({
    isOpen,
    setIsOpen,
}: MultiDuplicateItemsModalPropsType) {
    const { t } = useTranslation();
    const [isWarningModal, SetIsWarningModal] = React.useState(false);
    const [textBody, SetTextBody] = React.useState("");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
        SetIsWarningModal(true);
    };

    const getBodyTex = () => {
        let bodyTex = t(
            "Do you want to confirm the duplication of these articles?"
        );

        if (dynamicTableWrapperContext?.rowData?.data.length > 1) {
            SetTextBody(bodyTex);
            SetIsWarningModal(false);
        } else {
            bodyTex = t(`Two or more categories must be selected`);
            SetIsWarningModal(true);
        }
        SetTextBody(bodyTex);
    };

    const handleMultiDuplicate = () => {
        setIsOpen(!isOpen);
        const listUuid: any[] = dynamicTableWrapperContext?.rowData?.data.map(
            (item: any) => item.iuud
        );

        try {
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items`,
                    { itemsIuuds: listUuid }
                )

                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                })
                .then((): void => {
                    SuccessToast(t("Duplication completed successfully"));
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    React.useEffect(() => {
        getBodyTex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                {t("DUPLICATE ARTICLES")}
            </ModalHeader>
            <ModalBody>{textBody}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {!isWarningModal ? t("Cancel") : t("Close")}
                </StyledButton>
                {!isWarningModal && (
                    <StyledButton
                        onClick={handleMultiDuplicate}
                        rounded
                        variant="primary"
                    >
                        {t("Duplicate")}
                    </StyledButton>
                )}
            </ModalFooter>
        </StyledModal>
    );
}
