import React from "react";
import { DominantColor } from "@components/Common";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { StyledButtonWithIcon } from "@aureskonnect/react-ui";
import { WORKFLOW_LIST } from "@constants/WorkFlow";

type addStepModalProps = {
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    globalIndex: number;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    uuid: string;
    contentModal: any;
    workFlows: WorkFlowStepProps[];
    setContentModal: React.Dispatch<any>;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
};

export function AddStepModal({
    globalIndex,
    setIndexSelected,
    setIsClickStep,
    isClickStep,
    uuid,
    contentModal,
    workFlows,
    setContentModal,
    setIsModify,
    isModify,
}: addStepModalProps) {
    const dominantColor: string = DominantColor();
    const { t } = useTranslation();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const AddStep = () => {
        // position to start path step
        const positionStartUuids = contentModal[contentModal.length - 1].data[
            globalIndex!
        ].previousUuids!.indexOf(
            contentModal[contentModal.length - 1].data[globalIndex!].uuid
        );
        const copyPreviousUuids = [
            ...contentModal[contentModal.length - 1].data[globalIndex!]
                .previousUuids!,
        ];
        copyPreviousUuids.length = positionStartUuids + 1;

        const count = workFlows.filter((element: any) => {
            if (element.type === WORKFLOW_LIST.STEPS) {
                return true;
            }

            return false;
        }).length;

        contentModal[contentModal.length - 1].data.push({
            title: `Etape ${count + 1}`,
            data: [],
            uuid,
            type: WORKFLOW_LIST.STEPS,
            previousUuids: copyPreviousUuids,
            override: {},
            multipleStep: {
                isMultiple: false,
                listeUuidItem: [],
            },
            uuidStep: "",
        });
        setContentModal(contentModal);
        setIsModify(!isModify);
    };
    return (
        <div
            className="mt-2 "
            style={{
                minWidth: "200px",
            }}
        >
            <AvForm
                onSubmit={() => {
                    AddStep();
                    setIndexSelected(globalIndex + 1);
                    setIsClickStep(!isClickStep);
                }}
            >
                <StyledButtonWithIcon
                    icon="PlusIcon"
                    iconPosition="left"
                    disabled={
                        cardType === "shop" && cardStatus === "En production"
                    }
                    rounded
                    variant={dominantColor}
                >
                    {t("Add Step")}
                </StyledButtonWithIcon>
            </AvForm>
        </div>
    );
}
