import React from "react";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";
import uuidByString from "uuid-by-string";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import SaleAddIcon from "@components/Common/SvgIcons/SaleAddIcon";
import { checkModifierExist, ItemIsCrushed } from "@pages/WorkFlow/helpers";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { v4 as uuidv4 } from "uuid";

type SalesAddMultipleInProductProps = {
    isMultipleStep: boolean;
    globalIndex: number;
    uuid: string;
    setIsCrushed: React.Dispatch<React.SetStateAction<boolean>>;
    setAction: React.Dispatch<React.SetStateAction<string>>;
    setContentModal: React.Dispatch<any>;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    cardData: any;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SalesAddMultipleInProduct({
    isMultipleStep,
    globalIndex,
    uuid,
    setIsCrushed,
    setAction,
    setContentModal,
    setIsOpenStepModal,
    cardData,
    setIsUnsavedDataMultiple,
}: SalesAddMultipleInProductProps) {
    const dispatch = useDispatch();

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const addMultipleStepInProduct = () => {
        if (multipleWorkflow.length > 0) {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";
        const isCrushed = ItemIsCrushed(
            globalWorkflow,
            globalIndex,
            modifierWorkflow,
            WORKFLOW_LIST.TYPEADD,
            cardData
        );
        if (isCrushed) {
            setAction(WORKFLOW_LIST.ADD);
            setIsCrushed(true);
            return;
        }

        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        /* verifier si vente add existe */
        const modifierForm = checkModifierExist(
            firstUuidSelected,
            WORKFLOW_LIST.TYPESTEPS,
            globalIndex,
            modifierWorkflow,
            globalWorkflow
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidByString(
                  [
                      ...globalWorkflow[globalIndex!].previousUuids!,
                      firstUuidSelected,
                  ].join("")
              );

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: JSON.parse(JSON.stringify(modifierWorkflow)),
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        let copyModifier = { ...modifierWorkflow };
        // eslint-disable-next-line no-prototype-builtins
        if (copyModifier.hasOwnProperty(iuudMD5)) {
            copyModifier = {
                [iuudMD5]: {
                    ...modifierWorkflow[iuudMD5],
                    typeAdd: true,
                },
            };
        } else {
            copyModifier = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: false,
                    typeAdd: true,
                    typeCategorie: false,
                },
            };
        }

        // add modifier item to workflow data

        if (!modifierForm.isModifier) {
            [
                ...globalWorkflow[globalIndex!].previousUuids!,
                firstUuidSelected,
            ]?.forEach((element: string) => {
                pathIuud += `["${element}"]?.content`;
            });
            // eslint-disable-next-line no-eval
            pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
            // eslint-disable-next-line no-eval
            const stringEvaluate = eval(pathIuud);
            stringEvaluate.content = {};
            stringEvaluate.content[iuudMD5] = {
                type: "modifier",
                override: {},
                content: {},
            };
            dispatch(setWorkflowData(copyGlobalWorkflowData));
        }

        // check step rank/////////
        const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        let listeTitle: string = "";
        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                // duplicate in global workflow
                const modifierForm = checkModifierExist(
                    elementUuid,
                    WORKFLOW_LIST.TYPESTEPS,
                    globalIndex,
                    modifierWorkflow,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                // selected item
                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    selected: uuid,
                };

                listeTitle === ""
                    ? (listeTitle = cardData?.items?.[elementUuid]?.title)
                    : (listeTitle += `, ${cardData?.items?.[elementUuid]?.title}`);
            }
        );
        dispatch(setWorkflow(copyWorkflow));
        dispatch(
            setMultipleWorkflow([
                {
                    popupIndex: -1,
                    StepIndex: globalIndex,
                    VirtualModifier: copyModifier,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
        const ContentModal: any = [];
        ContentModal.push({
            title: `${listeTitle}`,
            type: WORKFLOW_LIST.ADD,
            triggerItemIuud: firstUuidSelected,
            previousUuidStep: "empty",
            previousModifierStep: "empty",
            previousModifier: iuudMD5,
            isMultiple: true,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid: firstUuidSelected,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,

                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        firstUuidSelected,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);
    };

    return (
        <div className="icon_step__CLZ text-center mr-2">
            <SaleAddIcon
                role="button"
                width={22}
                height={22}
                stopColor="white"
                fill="black"
                style={{
                    borderRadius: "100%",
                    backgroundColor: "#transparent",
                    boxShadow: "0px 3px 6px #00000029",
                }}
                onClick={() => {
                    isMultipleStep && addMultipleStepInProduct();
                }}
            />
        </div>
    );
}
