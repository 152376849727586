/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import uuidByString from "uuid-by-string";

import { RootState } from "@store/.";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";

import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { StepIcon } from "@components/Common/SvgIcons";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import { ArrowUpIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowUpIcons";
import { useTranslation } from "react-i18next";
import SaleAddIcon from "@components/Common/SvgIcons/SaleAddIcon";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

import { IconElement } from "../IconElement";
import { StepExist } from "../helpers";
import { GetlistUuidItemsInStepBasicComposition } from "../hooks/helpersBasicComposition";
import {
    GetlistUuidOptions,
    InsertOverrideOptionInItem,
} from "../hooks/helpersOption";

import ThreedotIcon from "../ThreedotIcon";

import { VisibilityProductModal } from "../hooks/modal/card/VisibilityProductModal";
import { ChangeImageProductModal } from "../hooks/modal/card/ChangeImageProductModal";
import { CheckedProductModal } from "../hooks/modal/card/CheckedProductModal";

import "../style.css";

type WorkFlowCardProps = WorkFlowStepElementProps & {
    isGolbalOptionActive?: boolean;
    setContentModal: React.Dispatch<any>;
    contentModal: any;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    globalIndex: number;
    cardData: any;
    isVisible: boolean;
    isOptionModifier: string;
    formBasicComposition: basicComposition;
    CheckStepSalesExist: boolean;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
};

export default function WorkFlowCardModal({
    item,
    title,
    uuid,
    type,
    order,
    isGolbalOptionActive,
    setContentModal,
    contentModal,
    setIsModify,
    isModify,
    colorElement,
    image,
    globalIndex,
    cardData,
    isVisible = true,
    isOptionModifier,
    formBasicComposition,
    CheckStepSalesExist,
    setIndexSelected,
}: WorkFlowCardProps) {
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");
    // eslint-disable-next-line no-unused-vars
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData: any = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );

    const IsBasicComposition: boolean =
        item?.type === WORKFLOW_LIST.ITEMS &&
        Object.keys?.(cardData?.[item.type]?.[uuid]?.basicComposition || {})
            .length > 0;
    const IsBasicCompositionStep =
        contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep ===
        WORKFLOW_LIST.BASICCOMPOSITION;

    const IsOptionStep =
        contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
        WORKFLOW_LIST.OPTIONS;

    const IsOption: boolean =
        item?.type === WORKFLOW_LIST.ITEMS &&
        Object.keys?.(cardData?.[item.type]?.[uuid]?.options || {}).length > 0;

    const dispatch = useDispatch();

    const [isOptionActive, setIsOptionActive] =
        React.useState(isGolbalOptionActive);

    // eslint-disable-next-line
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );

    const { t } = useTranslation();

    const handleOptionAddStep = (
        uuidModifier: string,
        typeStep: string,
        typeInsertStep: string
    ) => {
        const isOverride =
            Object.keys(
                modifierWorkflow[
                    contentModal[contentModal.length - 1]?.previousModifierStep
                ]?.steps[
                    contentModal[contentModal.length - 1]?.previousUuidStep
                ]?.overrides || {}
            ).length > 0;

        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[
                          contentModal[contentModal.length - 1]
                              ?.previousModifierStep
                      ]?.steps[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(
                  JSON.stringify(
                      cardData?.steps?.[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]
                  )
              );

        let copyOverrideOption: any = {};
        isOverride
            ? (copyOverrideOption =
                  modifierWorkflow[
                      contentModal[contentModal.length - 1]
                          ?.previousModifierStep
                  ]?.steps[
                      contentModal[contentModal.length - 1]?.previousUuidStep
                  ]?.overrides.stepItems[
                      contentModal[contentModal.length - 1].triggerItemIuud
                  ]?.overrideOptions)
            : (copyOverrideOption = InsertOverrideOptionInItem(
                  copyOverrideOption,
                  cardData,
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuid
              ));

        const UuidOptions =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep;

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions].content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                modifier: uuidModifier,
                            },
                        },
                    },
                },
            },
        };
        let copyModifierWorkflow = { ...modifierWorkflow };
        //  eslint-disable-next-line
        if (copyModifierWorkflow.hasOwnProperty(uuidModifier)) {
            copyModifierWorkflow = {
                ...copyModifierWorkflow,
                [uuidModifier]: {
                    ...copyModifierWorkflow[uuidModifier],
                    [typeInsertStep]: true,
                },
            };
        } else {
            copyModifierWorkflow = {
                ...copyModifierWorkflow,
                [uuidModifier]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: typeStep === WORKFLOW_LIST.STEPS,
                    typeAdd: typeStep === WORKFLOW_LIST.ADD,
                    typeCategorie: false,
                },
            };
        }
        dispatch(
            setModifier({
                ...copyModifierWorkflow,
                [contentModal[contentModal.length - 1]?.previousModifierStep]: {
                    ...copyModifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ],
                    steps: {
                        ...copyModifierWorkflow[
                            contentModal[contentModal.length - 1]
                                ?.previousModifierStep
                        ].steps,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...copyModifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    ?.previousModifierStep
                            ].steps[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
        setIsModify(!isModify);
    };

    const AddMultiple = (
        modifierMD5: string,
        modifierType: string,
        typeStep: string,
        typeInsertStep: string
    ) => {
        const copymultipleStep = [...multipleWorkflow];
        const copyVirtualModifier =
            copymultipleStep[copymultipleStep.length - 1].VirtualModifier;
        const UuidStepVirtualModifier =
            contentModal[contentModal.length - 1].data[globalIndex!].uuidStep!;

        copymultipleStep[copymultipleStep.length - 1] = {
            ...copymultipleStep[copymultipleStep.length - 1],
            VirtualModifier: {
                ...copyVirtualModifier,
                [contentModal[contentModal.length - 1].previousModifier!]: {
                    ...copyVirtualModifier[
                        contentModal[contentModal.length - 1].previousModifier!
                    ],
                    [modifierType]: {
                        ...copyVirtualModifier[
                            contentModal[contentModal.length - 1]
                                .previousModifier!
                        ][modifierType],

                        [UuidStepVirtualModifier]: {
                            ...copyVirtualModifier[
                                contentModal[contentModal.length - 1]
                                    .previousModifier!
                            ][modifierType][
                                contentModal[contentModal.length - 1].data[
                                    globalIndex!
                                ].uuidStep!
                            ],
                            itemModifiers: {
                                ...copyVirtualModifier[
                                    contentModal[contentModal.length - 1]
                                        .previousModifier!
                                ][modifierType][UuidStepVirtualModifier]
                                    .itemModifiers,
                                [uuid]: modifierMD5,
                            },
                        },
                    },
                },
            },
        };

        // add to modifier
        if (
            copymultipleStep[
                copymultipleStep.length - 1
                // eslint-disable-next-line no-prototype-builtins
            ].VirtualModifier.hasOwnProperty(modifierMD5)
        ) {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: {
                    ...copymultipleStep[copymultipleStep.length - 1]
                        .VirtualModifier,
                    [modifierMD5]: {
                        ...copymultipleStep[copymultipleStep.length - 1]
                            .VirtualModifier[modifierMD5],
                        [typeInsertStep]: true,
                    },
                },
            };
        } else {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: {
                    ...copymultipleStep[copymultipleStep.length - 1]
                        .VirtualModifier,
                    [modifierMD5]: {
                        steps: {},
                        add: {},
                        category: {},
                        typeSteps: typeStep === WORKFLOW_LIST.STEPS,
                        typeAdd: typeStep === WORKFLOW_LIST.ADD,
                        typeCategorie: false,
                    },
                },
            };
        }

        dispatch(setMultipleWorkflow(copymultipleStep));
    };
    const AddContentModal = (type: string, isStep: boolean) => {
        const previousModifier =
            contentModal[contentModal.length - 1]?.previousModifier;
        const UuidStep =
            contentModal[contentModal.length - 1].data[globalIndex!].uuidStep;

        const iuudMD5 =
            Object.keys?.(item.content || {}).length > 0
                ? Object.keys(item.content)[0]
                : uuidByString(
                      [
                          ...contentModal[contentModal.length - 1].data[
                              globalIndex!
                          ].previousUuids!,
                          uuid,
                      ].join("")
                  );

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: JSON.parse(JSON.stringify(modifierWorkflow)),
            multipleWorkflow,
            globalWorkflow,
            modalWorkflow: JSON.parse(JSON.stringify(contentModal)),
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        let typeStep: string = "";
        let typeInsertStep: string = "";
        let modifierType: string = "";

        if (
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
        ) {
            modifierType = WORKFLOW_LIST.STEPS;
            if (type === contentModal[contentModal.length - 1].type) {
                typeStep = WORKFLOW_LIST.STEPS;
                typeInsertStep = "typeSteps";
            } else {
                typeStep = WORKFLOW_LIST.ADD;
                typeInsertStep = "typeAdd";
            }
        } else {
            modifierType = WORKFLOW_LIST.ADD;
            if (type === contentModal[contentModal.length - 1].type) {
                typeStep = WORKFLOW_LIST.ADD;
                typeInsertStep = "typeAdd";
            } else {
                typeStep = WORKFLOW_LIST.STEPS;
                typeInsertStep = "typeSteps";
            }
        }

        if (multipleWorkflow.length > 0) {
            AddMultiple(iuudMD5, modifierType, typeStep, typeInsertStep);
        } else if (
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
        ) {
            handleOptionAddStep(iuudMD5, typeStep, typeInsertStep);
        } else {
            let copyModifierWorkflow: any = { ...modifierWorkflow };
            copyModifierWorkflow = {
                ...copyModifierWorkflow,
                [contentModal[contentModal.length - 1].previousModifier!]: {
                    ...copyModifierWorkflow[
                        contentModal[contentModal.length - 1].previousModifier!
                    ],

                    [modifierType]: {
                        ...copyModifierWorkflow[
                            contentModal[contentModal.length - 1]
                                .previousModifier!
                        ][modifierType],

                        [contentModal[contentModal.length - 1].data[
                            globalIndex!
                        ].uuidStep!]: {
                            ...copyModifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    .previousModifier!
                            ][modifierType][
                                contentModal[contentModal.length - 1].data[
                                    globalIndex!
                                ].uuidStep!
                            ],
                            itemModifiers: {
                                ...copyModifierWorkflow[
                                    contentModal[contentModal.length - 1]
                                        .previousModifier!
                                ][modifierType][
                                    contentModal[contentModal.length - 1].data[
                                        globalIndex!
                                    ].uuidStep!
                                ].itemModifiers,
                                [uuid]: iuudMD5,
                            },
                        },
                    },
                },
            };

            // add to modifier
            // eslint-disable-next-line no-prototype-builtins
            if (copyModifierWorkflow.hasOwnProperty(iuudMD5)) {
                dispatch(
                    setModifier({
                        ...copyModifierWorkflow,
                        [iuudMD5]: {
                            ...copyModifierWorkflow[iuudMD5],
                            [typeInsertStep]: true,
                        },
                    })
                );
            } else {
                dispatch(
                    setModifier({
                        ...copyModifierWorkflow,
                        [iuudMD5]: {
                            steps: {},
                            add: {},
                            category: {},
                            typeSteps: typeStep === WORKFLOW_LIST.STEPS,
                            typeAdd: typeStep === WORKFLOW_LIST.ADD,
                            typeCategorie: false,
                        },
                    })
                );
            }
        }

        let stepData: any = [];
        let count: number = 0;
        // get option
        if (IsOption && isStep) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    const optionForm = GetlistUuidOptions(
                        modifierWorkflow,
                        previousModifier,
                        UuidStep,
                        iuudOption,
                        uuid,
                        cardData,
                        WORKFLOW_LIST.RankOptions,
                        contentModal[contentModal.length - 1].type ===
                            WORKFLOW_LIST.TRIGGER
                            ? WORKFLOW_LIST.STEPS
                            : WORKFLOW_LIST.ADD
                    );
                    let listValue: any = {};
                    optionForm.forEach((iuudValue: any) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                            },
                        };
                    });
                    stepData = [
                        ...stepData,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousUuids: [
                                ...contentModal[contentModal.length - 1].data[
                                    globalIndex!
                                ].previousUuids!,
                                uuid,
                            ],
                            previousUuidStep:
                                contentModal[contentModal.length - 1].data[
                                    globalIndex!
                                ].uuidStep!,
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }
        // get list basic composition

        let listItems: any = {};
        count += 1;
        if (IsBasicComposition && isStep) {
            const ListUuidBasicCompostion =
                GetlistUuidItemsInStepBasicComposition(
                    modifierWorkflow,
                    previousModifier,
                    UuidStep,
                    uuid,
                    cardData,
                    contentModal[contentModal.length - 1].type ===
                        WORKFLOW_LIST.TRIGGER
                        ? WORKFLOW_LIST.STEPS
                        : WORKFLOW_LIST.ADD
                );
            ListUuidBasicCompostion.forEach((iuudItem: string) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                    },
                };
            });
            stepData = [
                ...stepData,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuid,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...contentModal[contentModal.length - 1].data[
                            globalIndex!
                        ].previousUuids!,
                        uuid,
                    ],
                    previousUuidStep:
                        contentModal[contentModal.length - 1].data[globalIndex!]
                            .uuidStep!,
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }
        // add step to modal
        contentModal.push({
            title: `${title!}`,
            type:
                typeStep === WORKFLOW_LIST.ADD
                    ? WORKFLOW_LIST.ADD
                    : WORKFLOW_LIST.TRIGGER,
            // type: WORKFLOW_LIST.ADD,
            triggerItemIuud: uuid,
            previousUuidStep:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .uuidStep!,
            previousModifierStep:
                contentModal[contentModal.length - 1].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data:
                (IsBasicComposition || IsOption) && isStep
                    ? stepData
                    : [
                          {
                              title: `Etape ${count}`,
                              data: [],
                              uuid,
                              uuidStep: "",
                              type: WORKFLOW_LIST.STEPS,
                              previousUuids: [
                                  ...contentModal[contentModal.length - 1].data[
                                      globalIndex!
                                  ].previousUuids!,
                                  uuid,
                              ],
                              previousUuidStep:
                                  contentModal[contentModal.length - 1].data[
                                      globalIndex!
                                  ].uuidStep!,
                              multipleStep: {
                                  isMultiple: false,
                                  listeUuidItem: [],
                              },
                          },
                      ],
        });

        if (
            contentModal[contentModal.length - 2]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
        ) {
            setContentModal(contentModal);
        } else {
            const updateGlobalWorkflow = [...contentModal];
            updateGlobalWorkflow[contentModal.length - 2].data[globalIndex!] = {
                ...updateGlobalWorkflow[contentModal.length - 2].data[
                    globalIndex!
                ],
                uuidModifier: iuudMD5!,
            };

            // add new step & insert modifier in globalworkflow
            const copyWorkflow = [...updateGlobalWorkflow];
            copyWorkflow[contentModal.length - 2].data[globalIndex!] = {
                ...copyWorkflow[contentModal.length - 2].data[globalIndex!],
                data: {
                    ...copyWorkflow[contentModal.length - 2].data[globalIndex!]
                        .data,
                    [uuid]: {
                        ...copyWorkflow[contentModal.length - 2].data[
                            globalIndex!
                        ].data[uuid],
                        content: {
                            ...copyWorkflow[contentModal.length - 2].data[
                                globalIndex!
                            ].data[uuid].content,
                            [iuudMD5]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            setContentModal(copyWorkflow);
        }
        setIsModify(!isModify);
    };
    const getStepInStep = () => {
        const previousModifier =
            contentModal[contentModal.length - 1]?.previousModifier;
        const UuidStep =
            contentModal[contentModal.length - 1].data[globalIndex!].uuidStep;

        const iuudMD5 =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
                ? isOptionModifier
                : Object.keys(item.content)[0];

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow,
            globalWorkflow,
            modalWorkflow: JSON.parse(JSON.stringify(contentModal)),
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        let stepData: any = [];
        let count: number = 0;
        // get option
        if (IsOption) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    const optionForm = GetlistUuidOptions(
                        copyModifier,
                        previousModifier,
                        UuidStep,
                        iuudOption,
                        uuid,
                        cardData,
                        WORKFLOW_LIST.RankOptions,
                        contentModal[contentModal.length - 1].type ===
                            WORKFLOW_LIST.TRIGGER
                            ? WORKFLOW_LIST.STEPS
                            : WORKFLOW_LIST.ADD
                    );
                    let listValue: any = {};
                    optionForm.forEach((iuudValue: any) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                            },
                        };
                    });
                    stepData = [
                        ...stepData,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousUuids: [
                                ...contentModal[contentModal.length - 1].data[
                                    globalIndex!
                                ].previousUuids!,
                                uuid,
                                "",
                            ],
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }

        // get composition de base
        if (IsBasicComposition) {
            let listItems: any = {};
            count += 1;
            const ListUuidBasicCompostion =
                GetlistUuidItemsInStepBasicComposition(
                    copyModifier,
                    previousModifier,
                    UuidStep,
                    uuid,
                    cardData,
                    contentModal[contentModal.length - 1].type ===
                        WORKFLOW_LIST.TRIGGER
                        ? WORKFLOW_LIST.STEPS
                        : WORKFLOW_LIST.ADD
                );
            ListUuidBasicCompostion.forEach((iuudItem: string) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                    },
                };
            });
            stepData = [
                ...stepData,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,
                    uuid,
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...contentModal[contentModal.length - 1].data[
                            globalIndex!
                        ].previousUuids!,
                        uuid,
                        "",
                    ],
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }
        // get list of uuid step
        Object.entries(copyModifier[iuudMD5].steps)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(([iuudStep]: any) => {
                const stepForm =
                    Object.keys(
                        copyModifier[iuudMD5].steps[iuudStep]?.overrides || {}
                    ).length > 0
                        ? copyModifier[iuudMD5].steps[iuudStep]?.overrides
                        : cardData?.steps?.[iuudStep];
                count += 1;
                let listItems: any = {};
                Object.entries(stepForm.stepItems)
                    .sort((a: any, b: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                    )
                    .forEach(([iuud]: any, index: number) => {
                        if (
                            copyModifier[iuudMD5].steps[
                                iuudStep
                                // eslint-disable-next-line no-prototype-builtins
                            ].itemModifiers.hasOwnProperty(iuud)
                        ) {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {
                                        [copyModifier[iuudMD5].steps[iuudStep]
                                            .itemModifiers[iuud]]: {
                                            type: "modifier",
                                            override: {},
                                            content: {},
                                        },
                                    },
                                    rank: index + 1,
                                },
                            };
                        } else {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {},
                                    rank: index + 1,
                                },
                            };
                        }
                    });
                stepData = [
                    ...stepData,
                    {
                        title: `Etape ${count}`,
                        data: listItems,
                        uuid,
                        uuidStep: iuudStep,
                        type: WORKFLOW_LIST.STEPS,
                        previousUuids: [
                            ...contentModal[contentModal.length - 1].data[
                                globalIndex!
                            ].previousUuids!,
                            uuid,
                            iuudStep,
                        ],
                        override:
                            Object.keys(
                                copyModifier[iuudMD5].steps[iuudStep]
                                    ?.overrides || {}
                            ).length > 0
                                ? copyModifier[iuudMD5].steps[iuudStep]
                                      ?.overrides
                                : {},
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    },
                ];
            });

        // add content to modal

        contentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.TRIGGER,
            triggerItemIuud: uuid,
            data: stepData,
            previousUuidStep:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .uuidStep!,
            previousModifierStep:
                contentModal[contentModal.length - 1].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
        });
        setContentModal(contentModal);
        setIsModify(!isModify);
    };
    const getSalesInSales = () => {
        const iuudMD5 =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
                ? isOptionModifier
                : Object.keys(item.content)[0];

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow,
            globalWorkflow,
            modalWorkflow: JSON.parse(JSON.stringify(contentModal)),
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        let stepData: any = [];

        // get list of uuid step
        Object.entries(copyModifier[iuudMD5].add)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(([iuudStep]: any, index: number) => {
                const stepForm =
                    Object.keys(
                        copyModifier[iuudMD5].add[iuudStep]?.overrides || {}
                    ).length > 0
                        ? copyModifier[iuudMD5].add[iuudStep]?.overrides
                        : cardData?.steps?.[iuudStep];

                let listItems: any = {};

                // get list uuid items in step
                Object.entries(stepForm.stepItems)
                    .sort((a: any, b: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                    )
                    .forEach(([iuud]: any, indexItem: number) => {
                        if (
                            copyModifier[iuudMD5].add[
                                iuudStep
                                // eslint-disable-next-line no-prototype-builtins
                            ].itemModifiers.hasOwnProperty(iuud)
                        ) {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {
                                        [copyModifier[iuudMD5].add[iuudStep]
                                            .itemModifiers[iuud]]: {
                                            type: "modifier",
                                            override: {},
                                            content: {},
                                        },
                                    },
                                    rank: indexItem + 1,
                                },
                            };
                        } else {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {},
                                    rank: indexItem + 1,
                                },
                            };
                        }
                    });

                stepData = [
                    ...stepData,
                    {
                        title: `Etape ${index + 1}`,
                        data: listItems,
                        uuid,
                        uuidStep: iuudStep,
                        type: WORKFLOW_LIST.STEPS,
                        previousUuids: [
                            ...contentModal[contentModal.length - 1].data[
                                globalIndex!
                            ].previousUuids!,
                            uuid,
                            iuudStep,
                        ],
                        override:
                            Object.keys(
                                copyModifier[iuudMD5].add[iuudStep]
                                    ?.overrides || {}
                            ).length > 0
                                ? copyModifier[iuudMD5].add[iuudStep]?.overrides
                                : {},
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    },
                ];
            });

        // add content to modal

        contentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.ADD,
            triggerItemIuud: uuid,
            data: stepData,
            previousUuidStep:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .uuidStep!,
            previousModifierStep:
                contentModal[contentModal.length - 1].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
        });
        setContentModal(contentModal);
        setIsModify(!isModify);
    };
    const handleOptionGetColorIcon = (
        propsType: string,
        modifierType: string,
        modifierTypeStep: string
    ) => {
        const copyModifier =
            multipleWorkflow.length > 0
                ? multipleWorkflow[multipleWorkflow.length - 1].VirtualModifier
                : modifierWorkflow;
        switch (propsType) {
            case "stopColor":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "green";
                }
                if (isOptionModifier !== "") {
                    const checkStepExist = StepExist(
                        modifierWorkflow,
                        isOptionModifier,
                        modifierTypeStep,
                        cardData
                    );
                    if (
                        copyModifier[isOptionModifier]?.[modifierType] &&
                        checkStepExist
                    ) {
                        return "green";
                    }
                    return "white";
                }
                return "white";

            case "fill":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "white";
                }
                if (isOptionModifier !== "") {
                    const checkStepExist = StepExist(
                        modifierWorkflow,
                        isOptionModifier,
                        modifierTypeStep,
                        cardData
                    );
                    if (
                        copyModifier[isOptionModifier]?.[modifierType] &&
                        checkStepExist
                    ) {
                        return "white";
                    }
                    return "black";
                }
                return "black";

            default:
                return "black";
        }
    };
    const handleGetColorIcon = (
        propsType: string,
        modifierType: string,
        modifierTypeStep: string
    ) => {
        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        switch (propsType) {
            case "stopColor":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "green";
                }
                if (Object.keys?.(item.content).length > 0) {
                    const UuidModifier = Object.keys(item.content)[0];
                    const checkStepExist = StepExist(
                        copyModifier,
                        UuidModifier,
                        modifierTypeStep,
                        cardData
                    );

                    if (
                        copyModifier[UuidModifier]?.[modifierType] &&
                        checkStepExist
                    ) {
                        return "green";
                    }
                    return "white";
                }
                return "white";

            case "fill":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "white";
                }
                if (Object.keys?.(item.content).length > 0) {
                    const UuidModifier = Object.keys(item.content)[0];
                    const checkStepExist = StepExist(
                        copyModifier,
                        UuidModifier,
                        modifierTypeStep,
                        cardData
                    );

                    if (
                        copyModifier[UuidModifier]?.[modifierType] &&
                        checkStepExist
                    ) {
                        return "white";
                    }
                    return "black";
                }
                return "black";

            default:
                return "black";
        }
    };
    const handleClickStep = () => {
        if (Object.keys(item.content).length > 0) {
            const copyModifier =
                multipleWorkflow.length > 0
                    ? {
                          ...multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier,
                          ...modifierWorkflow,
                      }
                    : modifierWorkflow;
            const UuidModifier = Object.keys(item.content)[0];
            const checkStepExist = StepExist(
                copyModifier,
                UuidModifier,
                WORKFLOW_LIST.STEPS,
                cardData
            );
            copyModifier[UuidModifier]?.typeSteps && checkStepExist
                ? getStepInStep()
                : AddContentModal(WORKFLOW_LIST.TRIGGER, true);
        } else {
            AddContentModal(WORKFLOW_LIST.TRIGGER, true);
        }
    };
    const handleClickOptionStep = () => {
        if (isOptionModifier !== "") {
            const checkStepExist = StepExist(
                modifierWorkflow,
                isOptionModifier,
                WORKFLOW_LIST.STEPS,
                cardData
            );
            modifierWorkflow[isOptionModifier]?.typeSteps && checkStepExist
                ? getStepInStep()
                : AddContentModal(WORKFLOW_LIST.TRIGGER, true);
        } else {
            AddContentModal(WORKFLOW_LIST.TRIGGER, true);
        }
    };
    const handleClickOptionSales = () => {
        if (isOptionModifier !== "") {
            const checkStepExist = StepExist(
                modifierWorkflow,
                isOptionModifier,
                WORKFLOW_LIST.ADD,
                cardData
            );
            modifierWorkflow[isOptionModifier]?.typeAdd && checkStepExist
                ? getSalesInSales()
                : AddContentModal(WORKFLOW_LIST.ADD, false);
        } else {
            AddContentModal(WORKFLOW_LIST.ADD, false);
        }
    };
    const handleClickSales = () => {
        if (Object.keys(item.content).length > 0) {
            const copyModifier =
                multipleWorkflow.length > 0
                    ? {
                          ...multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier,
                          ...modifierWorkflow,
                      }
                    : modifierWorkflow;
            const UuidModifier = Object.keys(item.content)[0];
            const checkStepExist = StepExist(
                copyModifier,
                UuidModifier,
                WORKFLOW_LIST.ADD,
                cardData
            );
            copyModifier[UuidModifier]?.typeAdd && checkStepExist
                ? getSalesInSales()
                : AddContentModal(WORKFLOW_LIST.ADD, false);
        } else {
            AddContentModal(WORKFLOW_LIST.ADD, false);
        }
    };
    const handleColorFilled = (
        modifierType: string,
        modifiertypeStep: string
    ) => {
        let color: "green" | "white" | "black";

        if (
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
        ) {
            color = handleOptionGetColorIcon(
                "stopColor",
                modifierType,
                modifiertypeStep
            );
        } else {
            color = handleGetColorIcon(
                "stopColor",
                modifierType,
                modifiertypeStep
            );
        }

        return color;
    };
    return (
        <div className="  my-2">
            <Card
                style={{
                    maxHeight: "120px",
                    minHeight: "100px",
                    cursor: "pointer",
                    border: "1px solid #F2F2F2",
                    borderRadius: "5px 5px 0px 0px",
                    boxShadow: "0px 3px 6px #00000029",
                }}
            >
                <div
                    className="card-body align-items-center  "
                    id={`${uuid}${contentModal.length}${globalIndex}`}
                    style={{
                        minHeight: "80px",
                        padding: "0px 4px",
                        display: "grid",
                        gridTemplateColumns: "10% 30% 190px 5px",
                    }}
                >
                    <CheckedProductModal
                        uuid={uuid}
                        globalIndex={globalIndex}
                        colorElement={colorElement}
                        order={order}
                        IsOptionStep={IsOptionStep}
                        IsBasicCompositionStep={IsBasicCompositionStep}
                        contentModal={contentModal}
                        setContentModal={setContentModal}
                        setIsModify={setIsModify}
                        isModify={isModify}
                        setIndexSelected={setIndexSelected}
                    />
                    {type === WORKFLOW_LIST.STEPS && !IsBasicCompositionStep ? (
                        <img
                            style={{
                                width: " 70px",
                                height: "53px",
                                marginLeft: "4px",
                            }}
                            src={image !== "" ? image : "no-pictures.svg"}
                            className="card-img-top"
                            alt="..."
                        />
                    ) : (
                        <ChangeImageProductModal
                            uuid={uuid}
                            cardData={cardData}
                            globalIndex={globalIndex}
                            image={image}
                            contentModal={contentModal}
                            setIsModify={setIsModify}
                            isModify={isModify}
                            IsOptionStep={IsOptionStep}
                        />
                    )}
                    <div
                        className="card-title"
                        style={{
                            borderBottom: "1px solid #000000",
                            paddingBottom: "12px",
                            opacity: 1,
                        }}
                    >
                        <LockIconWithToolTip
                            type="title"
                            lengthMax={25}
                            message={`${title}`}
                            id={`title2${globalIndex}${order}`}
                        />

                        <div
                            className="preview-text"
                            id={`title2${globalIndex}${order}`}
                            style={{ zIndex: "10" }}
                        >
                            {
                                // eslint-disable-next-line no-nested-ternary
                                formBasicComposition.quantity > 1 &&
                                formBasicComposition.isVisible
                                    ? title
                                        ? `${
                                              formBasicComposition.quantity
                                          } X ${title.toUpperCase()}`
                                        : `${formBasicComposition.quantity} X ${title}`
                                    : title
                                    ? title.toUpperCase()
                                    : title
                            }
                        </div>
                    </div>
                    <div
                        id={`Flèche2${globalIndex}${order}`}
                        className="icon_step__CLZ text-center ml-1"
                        onClick={() => {
                            setIsOptionActive(!isOptionActive);
                        }}
                    >
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={
                                isOptionActive
                                    ? t("Hide options")
                                    : t("Show options")
                            }
                            id={`Flèche2${globalIndex}${order}`}
                        />
                        {isOptionActive ||
                        isOptionModifier !== "" ||
                        (Object.keys?.(item.content).length > 0 &&
                            CheckStepSalesExist) ||
                        (IsBasicComposition &&
                            IsBasicCompositionStep === false) ||
                        (IsOption &&
                            IsOptionStep === false &&
                            IsBasicCompositionStep === false) ? (
                            <ArrowUpIcon color="black" />
                        ) : (
                            <ArrowDownIcon color="black" />
                        )}
                    </div>
                </div>

                <div
                    className="card-body align-items-center  "
                    style={{
                        minHeight: "40px",
                        padding: "0px 4px",
                        display:
                            isOptionActive ||
                            (Object.keys?.(item.content).length > 0 &&
                                CheckStepSalesExist) ||
                            (IsBasicComposition &&
                                IsBasicCompositionStep === false) ||
                            (IsOption &&
                                IsOptionStep === false &&
                                IsBasicCompositionStep === false) ||
                            isOptionModifier !== ""
                                ? "grid"
                                : "none",
                        gridTemplateColumns: "10% auto",
                        background: "#F2F2F2 0% 0% no-repeat padding-box",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            borderRight: `4px solid ${colorElement}`,
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    />

                    <div className="ml-2">
                        <div
                            style={{
                                justifyContent: "end",
                                paddingRight: "4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <VisibilityProductModal
                                contentModal={contentModal}
                                cardData={cardData}
                                uuid={uuid}
                                setIsModify={setIsModify}
                                isModify={isModify}
                                isVisible={isVisible}
                                globalIndex={globalIndex}
                                IsOptionStep={IsOptionStep}
                                IsBasicCompositionStep={IsBasicCompositionStep}
                                isObligatory={formBasicComposition.isObligatory}
                                order={order}
                            />
                            <div
                                className={
                                    ETKType === "3"
                                        ? "icon_step__CLZ text-center  disabledDiv__CLZ"
                                        : "icon_step__CLZ text-center "
                                }
                                style={{
                                    pointerEvents:
                                        cardType === "shop" &&
                                        cardStatus === "En production" &&
                                        handleColorFilled(
                                            "typeAdd",
                                            WORKFLOW_LIST.ADD
                                        ) === "white"
                                            ? "none"
                                            : "all",
                                    display:
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.uuidStep ===
                                        WORKFLOW_LIST.BASICCOMPOSITION
                                            ? "none"
                                            : "block",
                                    backgroundColor:
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.type ===
                                        WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "stopColor",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "stopColor",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              ),
                                }}
                            >
                                <LockIconWithToolTip
                                    type="icon"
                                    lengthMax={0}
                                    message={t("Additional sale")}
                                    id={`Vente${globalIndex}`}
                                />

                                <SaleAddIcon
                                    id={`Vente${globalIndex}`}
                                    height={20}
                                    width={20}
                                    style={{ cursor: "pointer" }}
                                    onClick={(event: any) => {
                                        if (ETKType !== "3") {
                                            contentModal[
                                                contentModal.length - 1
                                            ]?.data[globalIndex!]?.type ===
                                            WORKFLOW_LIST.OPTIONS
                                                ? handleClickOptionSales()
                                                : handleClickSales();
                                        }
                                        event.stopPropagation();
                                    }}
                                    stopColor={
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.type ===
                                        WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "stopColor",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "stopColor",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              )
                                    }
                                    fill={
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.type ===
                                        WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "fill",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "fill",
                                                  "typeAdd",
                                                  WORKFLOW_LIST.ADD
                                              )
                                    }
                                />
                            </div>
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t("Trigger workflow")}
                                id={`Déclencher4${globalIndex}`}
                            />
                            <StepIcon
                                id={`Déclencher4${globalIndex}`}
                                className={
                                    ETKType === "3"
                                        ? "mx-1  disabledDiv__CLZ"
                                        : "mx-1 "
                                }
                                width={31}
                                height={31}
                                stopColor={
                                    contentModal[contentModal.length - 1]?.data[
                                        globalIndex!
                                    ]?.type === WORKFLOW_LIST.OPTIONS
                                        ? handleOptionGetColorIcon(
                                              "stopColor",
                                              "typeSteps",
                                              WORKFLOW_LIST.STEPS
                                          )
                                        : handleGetColorIcon(
                                              "stopColor",
                                              "typeSteps",
                                              WORKFLOW_LIST.STEPS
                                          )
                                }
                                fill={
                                    contentModal[contentModal.length - 1]?.data[
                                        globalIndex!
                                    ]?.type === WORKFLOW_LIST.OPTIONS
                                        ? handleOptionGetColorIcon(
                                              "fill",
                                              "typeSteps",
                                              WORKFLOW_LIST.STEPS
                                          )
                                        : handleGetColorIcon(
                                              "fill",
                                              "typeSteps",
                                              WORKFLOW_LIST.STEPS
                                          )
                                }
                                style={{
                                    pointerEvents:
                                        cardType === "shop" &&
                                        cardStatus === "En production" &&
                                        handleColorFilled(
                                            "typeSteps",
                                            WORKFLOW_LIST.STEPS
                                        ) === "white"
                                            ? "none"
                                            : "auto",
                                    borderRadius: "100%",
                                    display:
                                        type === WORKFLOW_LIST.CATEGORIES ||
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.uuidStep ===
                                            WORKFLOW_LIST.BASICCOMPOSITION
                                            ? "none"
                                            : "block",
                                }}
                                onClick={(event: any) => {
                                    if (ETKType !== "3") {
                                        contentModal[contentModal.length - 1]
                                            ?.data[globalIndex!]?.type ===
                                        WORKFLOW_LIST.OPTIONS
                                            ? handleClickOptionStep()
                                            : handleClickStep();
                                    }
                                    event.stopPropagation();
                                }}
                            />

                            <IconElement iconType={WORKFLOW_LIST.ITEMS} />

                            <ThreedotIcon width={31} height={31} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}
