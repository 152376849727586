import React from "react";

import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DominantColor } from "@components/Common/";
import { useDispatch, useSelector } from "react-redux";

import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";
import { RootState } from "@store/index";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { v4 as uuidv4 } from "uuid";
import { checkModifierExist } from "@pages/WorkFlow/helpers";

type CrushedItemModalPropsType = {
    isCrushed: boolean;
    setIsCrushed: React.Dispatch<React.SetStateAction<boolean>>;
    globalIndex: number;
    uuid: string;
    action: string;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    cardData: any;
    setContentModal: React.Dispatch<any>;
};

export default function CrushedItemModal({
    isCrushed,
    setIsCrushed,
    globalIndex,
    uuid,
    action,
    setIsOpenStepModal,
    cardData,
    setContentModal,
}: CrushedItemModalPropsType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const addMultipleStepInProduct = () => {
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";
        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        /*  verifier si vente add existe */
        const modifierForm = checkModifierExist(
            firstUuidSelected,
            WORKFLOW_LIST.TYPEADD,
            globalIndex,
            modifierWorkflow,
            globalWorkflow
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidv4();

        let copyModifier = { ...modifierWorkflow };
        // eslint-disable-next-line no-prototype-builtins
        if (copyModifier.hasOwnProperty(iuudMD5)) {
            copyModifier = {
                [iuudMD5]: {
                    ...copyModifier[iuudMD5],
                    typeSteps: true,
                    steps: {},
                },
            };
        } else {
            copyModifier = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: true,
                    typeAdd: false,
                    typeCategorie: false,
                },
            };
        }

        // add modifier item to workflow data
        if (!modifierForm.isModifier) {
            [
                ...globalWorkflow[globalIndex!].previousUuids!,
                firstUuidSelected,
            ]?.forEach((element: string) => {
                pathIuud += `["${element}"]?.content`;
            });
            pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
            // eslint-disable-next-line no-eval
            const stringEvaluate = eval(pathIuud);
            stringEvaluate.content = {};
            stringEvaluate.content[iuudMD5] = {
                type: "modifier",
                override: {},
                content: {},
            };
            dispatch(setWorkflowData(copyGlobalWorkflowData));
        }

        // check step rank/////////
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        /* Add previous state  */
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            previousState: {
                modifierSlice: JSON.parse(JSON.stringify(modifierWorkflow)),
                multipleWorkflow: [],
                globalWorkflow,
                modalWorkflow: [],
                globalWorkflowData: JSON.parse(
                    JSON.stringify(globalWorkflowData)
                ),
            },
        };
        let count = copyWorkflow.filter((element: any) => {
            if (element.title.includes("Etape")) {
                return true;
            }

            return false;
        }).length;
        if (count > 0 && copyWorkflow.length > globalIndex! + 1) {
            copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
            count = 0;
        }

        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                // duplicate in global workflow
                const modifierForm = checkModifierExist(
                    elementUuid,
                    WORKFLOW_LIST.TYPEADD,
                    globalIndex,
                    modifierWorkflow,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                // selected item
                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    selected: uuid,
                };
            }
        );
        dispatch(
            setWorkflow([
                ...copyWorkflow,
                {
                    title: `Etape 1
                        `,
                    data: [],
                    uuid: firstUuidSelected,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousModifier: iuudMD5,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!.slice(
                            0,
                            globalIndex!
                        ),
                        firstUuidSelected,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ])
        );
        dispatch(
            setMultipleWorkflow([
                {
                    popupIndex: -1,
                    StepIndex: globalIndex,
                    VirtualModifier: copyModifier,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
    };
    const addMultipleSalesAddInProduct = () => {
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";

        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        /* verifier si trigger existe */
        const modifierForm = checkModifierExist(
            firstUuidSelected,
            WORKFLOW_LIST.TYPESTEPS,
            globalIndex,
            modifierWorkflow,
            globalWorkflow
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidv4();

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: JSON.parse(JSON.stringify(modifierWorkflow)),
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        let copyModifier = { ...modifierWorkflow };
        // eslint-disable-next-line no-prototype-builtins
        if (copyModifier.hasOwnProperty(iuudMD5)) {
            copyModifier = {
                [iuudMD5]: {
                    ...modifierWorkflow[iuudMD5],
                    typeAdd: true,
                    add: {},
                },
            };
        } else {
            copyModifier = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: false,
                    typeAdd: true,
                    typeCategorie: false,
                },
            };
        }

        // add modifier item to workflow data

        if (!modifierForm.isModifier) {
            [
                ...globalWorkflow[globalIndex!].previousUuids!,
                firstUuidSelected,
            ]?.forEach((element: string) => {
                pathIuud += `["${element}"]?.content`;
            });
            // eslint-disable-next-line no-eval

            pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
            // eslint-disable-next-line no-eval
            const stringEvaluate = eval(pathIuud);
            stringEvaluate.content = {};
            stringEvaluate.content[iuudMD5] = {
                type: "modifier",
                override: {},
                content: {},
            };

            dispatch(setWorkflowData(copyGlobalWorkflowData));
        }
        // check step rank/////////
        const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        let listeTitle: string = "";
        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                // duplicate in global workflow
                const modifierForm = checkModifierExist(
                    elementUuid,
                    WORKFLOW_LIST.TYPESTEPS,
                    globalIndex,
                    modifierWorkflow,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                // selected item
                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    selected: uuid,
                };

                listeTitle === ""
                    ? (listeTitle = cardData?.items?.[elementUuid]?.title)
                    : (listeTitle += `, ${cardData?.items?.[elementUuid]?.title}`);
            }
        );
        dispatch(setWorkflow(copyWorkflow));
        dispatch(
            setMultipleWorkflow([
                {
                    popupIndex: -1,
                    StepIndex: globalIndex,
                    VirtualModifier: copyModifier,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
        const ContentModal: any = [];
        ContentModal.push({
            title: `${listeTitle}`,
            type: WORKFLOW_LIST.ADD,
            triggerItemIuud: firstUuidSelected,
            previousUuidStep: "empty",
            previousModifierStep: "empty",
            previousModifier: iuudMD5,
            isMultiple: true,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid: firstUuidSelected,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,

                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        firstUuidSelected,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);
    };
    const AddMultipleStepInStep = () => {
        let copymodifierWorkflow = { ...modifierWorkflow };
        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        /* verifier si vente add existe */
        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        const modifierTypeCkecked =
            action === WORKFLOW_LIST.STEPS
                ? WORKFLOW_LIST.TYPEADD /* type vente additionnel */
                : WORKFLOW_LIST.TYPESTEPS; /* type steps */

        const workflowType =
            action === WORKFLOW_LIST.STEPS
                ? WORKFLOW_LIST.TRIGGER
                : WORKFLOW_LIST.ADD;

        const modifierChecked =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        const modifierForm = checkModifierExist(
            firstUuidSelected,
            modifierTypeCkecked,
            globalIndex,
            modifierChecked,
            globalWorkflow
        );
        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidv4();

        /* add modifier */
        // eslint-disable-next-line no-prototype-builtins
        if (copymodifierWorkflow.hasOwnProperty(iuudMD5)) {
            copymodifierWorkflow =
                workflowType === WORKFLOW_LIST.TRIGGER
                    ? {
                          /* type steps */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeSteps: true,
                              steps: {},
                          },
                      }
                    : {
                          /* type vente additionnel */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeAdd: true,
                              add: {},
                          },
                      };
        } else {
            copymodifierWorkflow = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: workflowType === WORKFLOW_LIST.TRIGGER,
                    typeAdd: workflowType === WORKFLOW_LIST.ADD,
                    typeCategorie: false,
                },
            };
        }

        const copyWorkflow = [...globalWorkflow];
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            uuidModifier: iuudMD5!,
        };

        // insert modifier to global workflow
        let listeTitle: string = "";
        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                const modifierForm = checkModifierExist(
                    elementUuid,
                    modifierTypeCkecked,
                    globalIndex,
                    modifierChecked,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                ...copyWorkflow[globalIndex!].data[elementUuid]
                                    .content,
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                listeTitle === ""
                    ? (listeTitle = cardData?.items?.[elementUuid]?.title)
                    : (listeTitle += `, ${cardData?.items?.[elementUuid]?.title}`);
            }
        );

        const ContentModal: any = [];
        ContentModal.push({
            title: listeTitle,
            type: workflowType,
            triggerItemIuud:
                globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0],

            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: true,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid: globalWorkflow[globalIndex!].multipleStep
                        .listeUuidItem[0],
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);

        dispatch(
            setMultipleWorkflow([
                ...multipleWorkflow,
                {
                    popupIndex: 0,
                    StepIndex: globalIndex,
                    VirtualModifier: copymodifierWorkflow,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
        dispatch(setWorkflow(copyWorkflow));
    };

    return (
        <Modal centered isOpen={isCrushed}>
            <ModalHeader toggle={() => setIsCrushed(false)}>
                <StyledH2>{t("Avertissement")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                {t(
                    "One or more items contain a workflow, the validation of the modifications will overwrite the existing one."
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsCrushed(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        if (
                            globalWorkflow[globalIndex!].type ===
                            WORKFLOW_LIST.CATEGORIES
                        ) {
                            action === WORKFLOW_LIST.STEPS
                                ? addMultipleStepInProduct()
                                : addMultipleSalesAddInProduct();
                        } else {
                            AddMultipleStepInStep();
                        }
                        setIsCrushed(false);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
