import { ACTIONS } from "@helpers/.";

export const initialState: AllergenType = {
    title: "",
    icon: "",
    group: {
        label: "",
        value: "",
    },
    archive: false,
};

export const allergenReducer = (
    state: AllergenType,
    action: ReducerActionType
): AllergenType => {
    switch (action.type) {
        case ACTIONS.TITLE:
            return { ...state, title: action.payload };

        case ACTIONS.ICON:
            return { ...state, icon: action.payload };

        case ACTIONS.ALLERGEN_GROUP:
            return { ...state, group: action.payload };

        case ACTIONS.RESET:
            return initialState;

        case ACTIONS.EDIT:
            return action.payload;
        default:
            return state;
    }
};
