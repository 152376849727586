import React from "react";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import {
    DynamicTableWrapperContext,
    CommonMenu,
    DominantColor,
    CustomCRUDColumn,
    LoadingDataAnimation,
} from "@components/Common";
import { RootState } from "@store/.";

import {
    isMultiModify,
    resetMultipleEditSlice,
} from "@store/features/multipleEditSlice";

import { getNestedId } from "@helpers/getNestedId";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import SelectRowCategory from "./SelectRowCategory";
import DeleteCategoryModal from "./DeleteCategoryModal";
import DuplicateCategoryModal from "./DuplicateCategoryModal";

type selectedCategoryType = {
    iuud: string;
    title: string;
    nestedRowsId: any[];
};
type categoryListProps = {
    setInitialData: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function CategoriesList({
    setInitialData,
}: categoryListProps): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const ETKType = localStorage.getItem("ETKType");
    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const dispatchStore = useDispatch();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const [selectedCategory, setSelectedCategory] =
        React.useState<selectedCategoryType>({
            iuud: "",
            title: "",
            nestedRowsId: [],
        });
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleEdit = (e: any): void => {
        const nestedRowsId = getNestedId((x: any) => x.iuud)(e.selectedRow);
        const category: CategoryType[] = [];
        category.push(e.selectedRow.category);
        dynamicTableWrapperContext?.setRowData({
            iuud: e.selectedRow.iuud,
            data: category,
            nestedRowsId,
        });
        dynamicTableWrapperContext?.setActionColumnDisplay(true);
    };

    const handleDuplicate = (e: any): void => {
        const nestedRowsId = getNestedId((x: any) => x.iuud)(e.selectedRow);
        setSelectedCategory({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.Désignation,
            nestedRowsId,
        });
        setIsShow(!isShow);
    };

    const handleDelete = (e: any): void => {
        setSelectedCategory({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.Désignation,
            nestedRowsId: e.selectedRow,
        });
        setIsOpen(!isOpen);
    };
    const handleSubmit = () => {
        selectedRows.length === 0 &&
            dynamicTableWrapperContext?.setRowData(null);
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed) => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };
    const dispatch = useDispatch();
    // const handleCrossIconClick = () => {
    //     dynamicTableWrapperContext?.setActionColumnDisplay(false);
    //     dispatch(initEditObserver());
    // };
    const customJsxAction = (e: any): JSX.Element => (
        <CustomCRUDColumn
            e={e}
            handleDuplicate={handleDuplicate}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
        />
    );

    const customJsxColor = ({ selectedRow }: any): JSX.Element => (
        <span
            style={{
                borderColor: "#0000",
                borderStyle: "solid",
                borderWidth: "thin",
                backgroundColor: selectedRow.Color,
                height: "30px",
                width: "25px",
                borderRadius: "6px",
            }}
        />
    );

    const handelRow = (row: any) => {
        const nestedRowsId = getNestedId((x: any) => x.iuud)(row.original);

        dynamicTableWrapperContext?.setRowData({
            iuud: row.original.iuud,

            data: [row.original.category],

            nestedRowsId,
        });
    };
    const arrayOfCustomColumns = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxAction,
        },
        {
            indexOFColumn: 3,
            columnName: "Couleur",
            customJsx: customJsxColor,
        },
    ];

    React.useEffect(() => {
        if (selectedRows.length === 0) {
            dynamicTableWrapperContext?.setRowData(null);
        } else if (selectedRows.length === 1) {
            dynamicTableWrapperContext?.setRowData({
                iuud: selectedRows[0].iuud,
                data: [selectedRows[0].category],
                subRows: selectedRows[0].subRows,
            });
        } else {
            dynamicTableWrapperContext?.setRowData({
                data: selectedRows.map((elm) => ({
                    ...elm.category,
                    iuud: elm.iuud,
                    selectedRows: selectedRows.length,
                    subRows: elm.subRows,
                    depth: elm.depth,
                })),
            });
            dispatchStore(isMultiModify());
        }

        selectedRows.length <= 1 && dispatchStore(resetMultipleEditSlice());

        // eslint-disable-next-line
    }, [selectedRows, dispatchStore]);

    return !isLoading ? (
        <div>
            <div>
                <DynamicTable
                    url={`${process.env.REACT_APP_ADB_API_URL}/cards/${schemaAdbFranchise}/${cardIuud}/categories`}
                    name="Category_table"
                    canSort
                    canSelect
                    customSelect
                    setData={setInitialData}
                    setSelectedRows={setSelectedRows}
                    actionColumn={SelectRowCategory}
                    customJsxSideFilterButton={
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                className="d-flex align-items-center"
                                onSubmit={() => {
                                    if (isDirty) {
                                        setUnsavedChangesWarningModalAction(
                                            () => handleSubmit
                                        );
                                        setIsUnsavedChangesWarningModalOpen(
                                            true
                                        );
                                    } else {
                                        dispatch(setIsEditing(false));
                                        // handleCrossIconClick();
                                        handleSubmit();
                                    }
                                }}
                            >
                                {selectedRows.length > 0 && (
                                    <div className="mx-1">
                                        <StyledButtonWithIcon
                                            icon="MultiEditIcon"
                                            iconPosition="left"
                                            rounded
                                            outline
                                            variant="light"
                                        >
                                            {selectedRows.length === 1
                                                ? t("Edit category")
                                                : t("Multiple edit")}
                                        </StyledButtonWithIcon>
                                    </div>
                                )}
                                {selectedRows.length === 0 &&
                                    !dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                        <StyledButtonWithIcon
                                            disabled={
                                                selectedRows.length > 1 ||
                                                ETKType === "1" ||
                                                (cardType === "shop" &&
                                                    cardStatus ===
                                                        "En production")
                                            }
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            rounded
                                            variant={dominantColor}
                                        >
                                            {t("Add a category")}
                                        </StyledButtonWithIcon>
                                    )}
                                {selectedRows.length === 0 &&
                                    dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                        <StyledIconButton
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                            }}
                                            icon="PlusIcon"
                                            className="bg-dark"
                                            rounded
                                            disabled={
                                                selectedRows.length > 1 ||
                                                ETKType === "1" ||
                                                (cardType === "shop" &&
                                                    cardStatus ===
                                                        "En production")
                                            }
                                        >
                                            <div className="d-flex justify-content-start">
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </div>
                                        </StyledIconButton>
                                    )}
                            </AvForm>
                            {cardType === "shop" &&
                            cardStatus === "En production" ? null : (
                                <CommonMenu title="Categories" />
                            )}
                        </div>
                    }
                    canResize
                    canExpand
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        dynamicTableWrapperContext?.setDataUpdated
                    }
                    dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                    filterActive={dynamicTableWrapperContext?.filterActive}
                    setLocalFilterActive={
                        dynamicTableWrapperContext?.setLocalFilterActive
                    }
                    minHeight={`calc(100vh - (370px + ${
                        isVisible ? "100px" : "0px"
                    }))`}
                    maxHeight={`calc(100vh - (370px + ${
                        isVisible ? "125px" : "0px"
                    }))`}
                    onClick={(row: any) => {
                        if (selectedRows.length <= 1) {
                            if (isDirty) {
                                setIsUnsavedChangesWarningModalOpen(true);
                                setUnsavedChangesWarningModalAction(
                                    () => () => {
                                        handelRow(row);
                                    }
                                );
                            } else {
                                dispatch(setIsEditing(false));

                                const nestedRowsId = getNestedId(
                                    (x: any) => x.iuud
                                )(row.original);

                                dynamicTableWrapperContext?.setRowData({
                                    iuud: row.original.iuud,

                                    data: [row.original.category],

                                    nestedRowsId,
                                });
                            }
                        }
                    }}
                />

                <DeleteCategoryModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    cardIuud={cardIuud}
                    categoryToDelete={selectedCategory}
                    setIsLoading={setIsLoading}
                />
                <DuplicateCategoryModal
                    categoryToDuplicate={selectedCategory}
                    isShow={isShow}
                    setIsShow={setIsShow}
                    setIsLoading={setIsLoading}
                />
            </div>
            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    ) : (
        <LoadingDataAnimation />
    );
}
