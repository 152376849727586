import React from "react";
import { PencilIcon } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { DynamicTableWrapperContext } from "@components/Common";
import { setUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type editProductProps = {
    item: any;
    uuid: string;
    type: string;
    globalIndex: number;
    order?: number;
    cardData: any;
    setTypeElement: React.Dispatch<React.SetStateAction<string>> | undefined;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
};

export function EditProduct({
    order,
    item,
    uuid,
    type,
    globalIndex,
    cardData,
    setTypeElement,
    setIsUnsavedDataMultiple,
}: editProductProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const handleEdit = (event: any) => {
        event.stopPropagation();
        if (
            multipleWorkflow.length > 0 &&
            (type === "items" || type === "categories")
        ) {
            setIsUnsavedDataMultiple(true);

            return;
        }
        let elementForm: any = {};
        Object.keys(item.override).length > 0
            ? (elementForm = item.override[uuid])
            : (elementForm = cardData?.[type]?.[uuid]);

        const copyPathUpdateItem: any = {
            pathUpdateItem: [
                ...globalWorkflow[globalIndex!].previousUuids!,
                uuid,
            ],
            indexWorkflowGlobal: globalIndex!,
            uuid,
        };
        type === WORKFLOW_LIST.CATEGORIES
            ? setTypeElement?.(WORKFLOW_LIST.CATEGORIES)
            : setTypeElement?.(WORKFLOW_LIST.ITEMS);

        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed) => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setRowData({
            iuud: uuid,
            data: [elementForm],
        });

        dispatch(setUpdateItemWorkflow(copyPathUpdateItem));
    };

    return (
        <div className="icon_step__CLZ text-center mr-1">
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={t("Edit")}
                id={`Modifier${globalIndex}${order}`}
            />
            <PencilIcon
                id={`Modifier${globalIndex}${order}`}
                role="button"
                width={20}
                height={20}
                onClick={handleEdit}
            />
        </div>
    );
}
