export const handlePrixChange = (
    selectedrowUpdated: any,

    newData: any
) => {
    selectedrowUpdated.forEach((row: any) => {
        newData.forEach((data: any, index1: number) => {
            if (data.key === row.key) {
                newData.splice(index1, 1, {
                    ...row,
                });
            } else if (data.key !== row.key) {
                data?.children.forEach((item: any, index2: any) => {
                    if (item.key === row.key) {
                        newData[index1].children.splice(index2, 1, {
                            ...row,
                        });
                    } else if (item.key !== row.key) {
                        if ("children" in item) {
                            item.children.forEach((it: any, index3: any) => {
                                if (it.key === row.key) {
                                    newData[index1].children[
                                        index2
                                    ].children.splice(index3, 1, {
                                        ...row,
                                    });
                                }
                            });
                        }
                    }
                });
            }
        });
    });

    return JSON.parse(JSON.stringify(newData));
};

export const handleIncreasePriceTtcWithAmount = (
    selectedrowUpdated: any,
    price: any,
    newData: any
) => {
    selectedrowUpdated.forEach((row: any) => {
        newData.forEach((data: any, index1: number) => {
            if (data.key === row.key) {
                newData.splice(index1, 1, {
                    ...row,
                });
            } else if (data.key !== row.key) {
                data?.children.forEach((item: any, index2: any) => {
                    if (item.key === row.key) {
                        newData[index1].children.splice(index2, 1, {
                            ...row,
                        });
                    } else if (item.key !== row.key) {
                        item.children.forEach((it: any, index3: any) => {
                            if (it.key === row.key) {
                                newData[index1].children[
                                    index2
                                ].children.splice(index3, 1, {
                                    ...row,
                                });
                            }
                        });
                    }
                });
            }
        });
    });

    return JSON.parse(JSON.stringify(newData));
};
