import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";
import CustomCurrencyInput from "@components/Common/CurrencyInput";
import PriceModal from "@components/Common_DB/AdvancePrice/PriceModal";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/.";
import { RootState } from "@store/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import VatRate from "./VatRate";

type PricePropsType = {
    itemForm: ItemType;
    allOptions: { Option: OptionType; iuud: string }[];
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
};

type VatRateContextType = {
    salesModes: SaleModeType[];
    vatRates: {
        label: string;
        value: string;
    }[];
    itemPrice: ItemPrice;
    allOptions: { Option: OptionType; iuud: string }[];
    itemOptions: itemOptions;
    advancedPriceCopy: Record<string, Price>;
    setAdvancedPriceCopy: React.Dispatch<
        React.SetStateAction<Record<string, Price>>
    >;
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
};
export const VatRateContext = React.createContext<VatRateContextType>({
    salesModes: [],
    vatRates: [],
    allOptions: [],
    itemOptions: {},
    itemPrice: {
        default: 0,
        priceHT: 0,
        tva: 0,
        saleModeVatRates: [],
        override: [],
        advancedPrice: {},
    },
    advancedPriceCopy: {},
    setAdvancedPriceCopy: () => {},
    dispatchItem: () => {},
});

export default function Price({
    itemForm,
    allOptions,
    dispatchItem,
}: PricePropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const salesModes = useSelector(
        (state: RootState) => state.settingsSlice.saleModes
    );
    const settingsVatRates = useSelector(
        (state: RootState) => state.settingsSlice.vatRates
    );
    const [advancedPriceCopy, setAdvancedPriceCopy] = React.useState<
        Record<string, Price>
    >(itemForm.price.advancedPrice);

    const defaultContext: VatRateContextType = React.useMemo(
        () => ({
            salesModes,
            vatRates: settingsVatRates.map((vatRate: any) => {
                return {
                    label: `${vatRate.TauxTVA} %`,
                    value: vatRate.TauxTVA as string,
                };
            }),
            allOptions,
            itemOptions: itemForm.options,
            itemPrice: itemForm.price,
            advancedPriceCopy,
            setAdvancedPriceCopy,
            dispatchItem,
        }),
        [
            salesModes,
            settingsVatRates,
            itemForm.price,
            dispatchItem,
            advancedPriceCopy,
            allOptions,
            itemForm.options,
        ]
    );

    const handlePrice = (e: string | number): void => {
        isMultiModify && dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.PRICE));
        dispatchItem({
            type: ACTIONS.PRICE_HT,
            payload: e,
        });
    };
    const handleVatRate = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatchItem({
            type: ACTIONS.TVA,
            payload: e,
        });

        if (itemForm.price.saleModeVatRates.length === 0) {
            const updatedSaleModeVatRates = salesModes.map((saleMode) => {
                return {
                    saleModeUuid: saleMode.id,
                    value: e,
                };
            });
            dispatchItem({
                type: ACTIONS.SALE_MODE_TVA,
                payload: updatedSaleModeVatRates,
            });
        }
        isMultiModify && dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.PRICE));
    };

    React.useEffect(() => {
        setAdvancedPriceCopy(itemForm.price.advancedPrice);
    }, [itemForm.price.advancedPrice]);

    return (
        <div id="price">
            <VatRateContext.Provider value={defaultContext}>
                <StyledLabel>{t("Price")}</StyledLabel>
                <div className="border rounded p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <StyledLabel>{`${t("Price")} TTC (€)`}</StyledLabel>

                        <PriceModal
                            dispatchItem={dispatchItem}
                            advancedPrice={itemForm.price.override}
                        />
                    </div>

                    <div
                        style={
                            itemForm.isRedirect
                                ? { pointerEvents: "none", opacity: "0.4" }
                                : { opacity: "1" }
                        }
                    >
                        <CustomCurrencyInput
                            id="PriceTTC"
                            name="PriceTTC"
                            decimalsLimit={2}
                            defaultValue={itemForm.price.default}
                            disabled
                            onChange={(e: any) => {
                                dispatchItem({
                                    type: ACTIONS.PRICE_TTC,
                                    payload: e,
                                });
                                isMultiModify &&
                                    dispatchStore(
                                        addListKeytoEdit(MULTI_EDIT_KEY.PRICE)
                                    );
                            }}
                            onBlur={() => {
                                dispatchItem({
                                    type: ACTIONS.PRICE_TTC,
                                    payload: parseFloat(
                                        String(itemForm.price.default)
                                    ).toFixed(2),
                                });
                                isMultiModify &&
                                    dispatchStore(
                                        addListKeytoEdit(MULTI_EDIT_KEY.PRICE)
                                    );
                            }}
                        />
                    </div>
                    <div className="my-4">
                        <VatRate handleVatRateChange={handleVatRate} />
                    </div>

                    <div className="py-2" id="PriceTTC">
                        <StyledLabel>{`${t("Price")} HT (€)`}</StyledLabel>
                        <CustomCurrencyInput
                            id="price"
                            name="Price"
                            decimalsLimit={2}
                            defaultValue={itemForm.price.priceHT}
                            disabled
                            onChange={handlePrice}
                            onBlur={() => {
                                dispatchItem({
                                    type: ACTIONS.PRICE_HT,
                                    payload: parseFloat(
                                        String(itemForm.price.priceHT)
                                    ).toFixed(2),
                                });
                                isMultiModify &&
                                    dispatchStore(
                                        addListKeytoEdit(MULTI_EDIT_KEY.PRICE)
                                    );
                            }}
                        />
                    </div>
                </div>
            </VatRateContext.Provider>
        </div>
    );
}
