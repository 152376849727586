/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-regex-literals */
import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExclamationIcon, StyledTextInput } from "@aureskonnect/react-ui";

import { RootState } from "@store/.";

import "./Style.css";

export type StyledInputWithTooltipPropsType = {
    id: string;
    name: string;
    type: string;
    value: string;
    maxlength?: number;
    requiredValidateValue: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    onFocus?: Function;
    innerRef?: Function;
    valueSetterFunction: Function;
};
export default function StyledInputWithTooltip({
    id,
    name,
    type,
    value,
    requiredValidateValue,
    disabled,
    maxlength,
    autoFocus,
    onFocus,
    innerRef,
    valueSetterFunction,
}: StyledInputWithTooltipPropsType) {
    const { t } = useTranslation();
    const i18nextLng: string | null = localStorage.getItem("i18nextLng");
    //  eslint-disable-next-line
    const forbiddenCharactersRegex = new RegExp(/^[^\\|\"@^#?!²``°<>§;]+$/);
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [inputValueIsConfirmed, setInputValueIsConfirmed] =
        React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<boolean>(false);

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value) {
            if (e.target.value.match(forbiddenCharactersRegex)) {
                setInputValueIsConfirmed(true);
                setInputValue(true);
            } else {
                setInputValueIsConfirmed(false);
                setInputValue(true);
            }
        } else {
            setInputValue(false);
        }
        valueSetterFunction(e.target.value);
    };

    React.useEffect(() => {
        setTitle(
            `${t(
                "List of forbidden characters"
                // eslint-disable-next-line
            )}   | \  " @ ^  # ?!   /  ²  ° < > § ;
                  `
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18nextLng, title]);

    return (
        <div
            className="col-12"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
            <div className="new-input-toggle__clz py-1">
                {!inputValueIsConfirmed && inputValue && (
                    <Tooltip
                        title={title}
                        arrow
                        followCursor
                        placement="bottom-end"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        onClose={handleTooltipClose}
                        open={open}
                    >
                        <h5>
                            <ExclamationIcon
                                fill="red"
                                style={{ cursor: "pointer" }}
                                height={15}
                                width={15}
                                onClick={handleTooltipOpen}
                            />
                        </h5>
                    </Tooltip>
                )}
            </div>

            <StyledTextInput
                maxlength={maxlength}
                style={{ backgroundImage: "unset" }}
                innerRef={innerRef}
                id={id}
                name={name}
                placeholder={isMultiModify ? "" : t("Enter")}
                type={type}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onBlur={() => {
                    valueSetterFunction(value.trim());
                }}
                validate={{
                    required: {
                        value: requiredValidateValue,
                        errorMessage: t("This field is required"),
                    },
                    pattern: {
                        value: forbiddenCharactersRegex,
                        errorMessage: t("Forbidden characters"),
                    },
                }}
                autoFocus={autoFocus}
                onFocus={onFocus}
            />
        </div>
    );
}
