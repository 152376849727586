import React from "react";
import { Card } from "reactstrap";
import { useDispatch } from "react-redux";

import { DominantColor } from "@components/Common";
import { setVisible } from "@store/features/headerCompoHeighSlice";

import "../index.css";

type HeaderPropsType = {
    title: any;
};
function HeaderComponent({ title }: HeaderPropsType) {
    const [isShow, SetIsShow] = React.useState(true);
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();

    function handleVisible() {
        dispatch(setVisible());

        SetIsShow(!isShow);
    }
    return isShow ? (
        <Card
            id="headercompo"
            style={{
                height: "71px",
                minHeight: "71px",
                maxHeight: "71px",
                marginTop: "-5px",
            }}
            className=" my-1"
        >
            <div className="d-flex justify-content-md-between">
                <div
                    className="d-flex justify-content-start "
                    style={{ height: "71px" }}
                >
                    <div
                        className="d-flex justify-content-center d-flex align-items-center"
                        style={{
                            backgroundColor: dominantColor,
                            width: "71px",
                        }}
                    >
                        <span
                            className="iconify"
                            data-icon="bx:message-rounded-error"
                            style={{ color: "white" }}
                            data-width="32"
                        />
                    </div>
                    <div className=" px-3 d-flex justify-content-center d-flex align-items-center">
                        <h6 className="footer__clz"> {title} </h6>
                    </div>
                </div>

                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleVisible}
                    className=" p-2 d-flex justify-content-center d-flex align-items-center d-flex justify-content-end "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                        />
                        <path
                            fillRule="evenodd"
                            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                        />
                    </svg>
                </div>
            </div>
        </Card>
    ) : null;
}

export default HeaderComponent;
