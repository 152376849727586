import React from "react";

import { useTranslation } from "react-i18next";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { RootState } from "@store/.";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router";

import ListConfiguration from "./ListConfiguration";

import "./Configuration.css";

export default function Configuration() {
    const { t } = useTranslation();
    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    const location = useLocation();
    const redirection: any = location.state;
    const pathname = window.location.pathname.toString().split("/")[2];
    const [currentOption, setCurrentOption] = React.useState<Array<Setting>>(
        []
    );

    const [addOption, setAddOption] = React.useState<Array<Setting>>([]);
    const [initialData, setInitialData] = React.useState(false);

    let URL: any = "";
    let configurationType: string = "";
    let configurationLink: string = "";
    let CategoryParent: string = "";
    if (pathname === "Categories") {
        URL = "/fakeData/configurationCategory.json";
        configurationType = "configurationCathegory";
        configurationLink = t("CONFIGURATION CATEGORY");
        CategoryParent = "";
    } else {
        URL = "/fakeData/configurationItem.json";
        configurationType = "configurationItem";
        configurationLink = t("ITEM SETUP");
        CategoryParent = "category parent";
    }
    function OrderByRank() {
        setAddOption((prevState: any) => {
            const newState = [...prevState];
            newState.sort((rank1, rank2) => rank1.rank - rank2.rank);
            return newState;
        });
        setCurrentOption((prevState: any) => {
            const newState = [...prevState];
            newState.sort((rank1, rank2) => rank1.rank - rank2.rank);
            return newState;
        });
    }
    function OrderByIndex() {
        setAddOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any, indice: number) => {
                element.rank = indice + 1;
                return element;
            });

            return newState;
        });
        setCurrentOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any, indice: number) => {
                element.rank = indice + 1;
                return element;
            });
            return newState;
        });
    }
    function handleChangeVisibility() {
        setAddOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any) => {
                element.visibility = false;
                return element;
            });

            return newState;
        });
        setCurrentOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any) => {
                element.visibility = true;
                return element;
            });
            return newState;
        });
    }
    React.useEffect(() => {
        (async () => {
            const data: any = localStorage.getItem(configurationType);

            if (data !== undefined && data !== null) {
                setCurrentOption(
                    JSON.parse(data).filter(
                        (element: any) => element.visibility === true
                    )
                );
                setAddOption(
                    JSON.parse(data).filter(
                        (element: any) => element.visibility === false
                    )
                );

                OrderByRank();
            } else {
                const response = await fetch(URL);
                const data = await response.json();
                localStorage.setItem(configurationType, JSON.stringify(data));
                setCurrentOption(
                    data.filter((element: any) => element.visibility === true)
                );
                setAddOption(
                    data.filter((element: any) => element.visibility === false)
                );
                OrderByRank();
            }
        })();
        // eslint-disable-next-line
    }, [URL, initialData]);

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (
            (currentOption[source.index].id === "designation" ||
                currentOption[source.index].id === CategoryParent ||
                currentOption[source.index].id === "reference") &&
            destination?.droppableId === "AddItem"
        ) {
            return;
        }
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        let addItem;
        const active = addOption;
        const complete = currentOption;

        if (result.type === "droppableItem") {
            // Source Item Logic
            if (source.droppableId === "AddItem") {
                addItem = active[source.index];
                active.splice(source.index, 1);
            } else {
                addItem = complete[source.index];
                complete.splice(source.index, 1);
            }

            // Destination Item Logic
            if (destination.droppableId === "AddItem") {
                active.splice(destination.index, 0, addItem);
            } else {
                complete.splice(destination.index, 0, addItem);
            }
        }
        setCurrentOption(complete);
        setAddOption(active);
        handleChangeVisibility();
        OrderByIndex();
    };

    return (
        <div className="page-content">
            <StyledBreadcrumb
                items={[
                    {
                        item: "CATALOGUE ",
                        link: "home",
                    },
                    {
                        item: selectedStore.toUpperCase(),
                        link: "#",
                    },

                    { item: configurationLink, link: "#" },
                ]}
            />
            <Container fluid>
                <DragDropContext onDragEnd={onDragEnd}>
                    <ListConfiguration
                        addOption={addOption}
                        setAddOption={setAddOption}
                        currentOption={currentOption}
                        setCurrentOption={setCurrentOption}
                        setInitialData={setInitialData}
                        initialData={initialData}
                        redirection={redirection}
                    />
                </DragDropContext>
            </Container>
        </div>
    );
}
