export function synchronizeVisibilityWithSettings(
    visibility: Record<string, visibilityInfoType>,
    visibilityInfo: visibilityCategoryType
): visibilityCategoryType {
    const updatedDefaultVisibility = Object.entries(visibility)
        .sort()
        .reduce(
            (
                updatedVisibility: Record<string, visibilityInfoType>,
                [saleMode, saleModeVisibility]
            ) => {
                if (visibilityInfo.default[saleMode] !== undefined) {
                    updatedVisibility[saleMode] = {
                        ...visibilityInfo.default[saleMode],
                        sellingSupport: [
                            ...visibilityInfo.default[saleMode].sellingSupport
                                .filter((sellingSupport) =>
                                    visibility[saleMode].sellingSupport.find(
                                        (settingsSaleSupport) =>
                                            settingsSaleSupport.sellingSupport ===
                                            sellingSupport.sellingSupport
                                    )
                                )
                                .sort((a, b) =>
                                    a.sellingSupport < b.sellingSupport ? -1 : 1
                                ),
                        ],
                    };
                } else {
                    updatedVisibility[saleMode] = saleModeVisibility;
                }
                return updatedVisibility;
            },
            {}
        );
    return {
        ...visibilityInfo,
        default: updatedDefaultVisibility,
    };
}
