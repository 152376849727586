import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";

type BarCodePropsType = {
    itemForm: ItemType;
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
};

export default function BarCode({
    itemForm,
    dispatchItem,
    addToRefs,
}: BarCodePropsType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isInputNumber = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleBarCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatchItem({
            type: ACTIONS.BARCODE,
            payload: e.target.value,
        });
        dispatch(addListKeytoEdit(MULTI_EDIT_KEY.BARCODE));
    };
    return (
        <div className="py-2" id="barCode">
            <StyledLabel>{t("Bar code")}</StyledLabel>
            <StyledTextInput
                id="barCode"
                innerRef={addToRefs}
                name="barCode"
                min={0}
                onKeyPress={isInputNumber}
                placeholder={t("Enter")}
                value={itemForm.barCode}
                onChange={handleBarCode}
                autoFocus
                disabled={
                    itemForm.isRedirect ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                }
            />
        </div>
    );
}
