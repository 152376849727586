import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import { DynamicTableWrapperContext } from "@components/Common/";
import { RootState } from "@store/.";
import DropdownActions from "./DropdownActions";
import { SelectedCard } from "./SelectedCard";

import "./Style.css";

type customColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function CardsList() {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const { t } = useTranslation();
    const isVisible = useSelector(
        (state: RootState) => state.headerCompoHeighSlice.isVisible
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const customJsxStatus = ({ selectedRow }: any) => {
        if (selectedRow?.Statut === "En attente") {
            return (
                <div
                    className="badge_en_attente"
                    style={{
                        color: "#FB8D10",
                        textAlign: "center",
                        font: "normal normal normal 16px/21px Segoe UI",
                        letterSpacing: "0px",
                    }}
                >
                    {t("Pending")}
                </div>
            );
        }
        if (selectedRow?.Statut === "En production") {
            return (
                <div className="badge_en_prod">
                    <span
                        style={{
                            color: "#00A500",
                            textAlign: "center",
                            font: "normal normal normal 16px/21px Segoe UI",
                            letterSpacing: "0px",
                            marginLeft: "1rem",
                        }}
                    >
                        {t("In production")}
                    </span>
                </div>
            );
        }
        return null;
    };

    const arrayOfCustomColumns: customColumnProps[] = [];
    arrayOfCustomColumns.push(
        {
            indexOFColumn: 7,
            columnName: "Action",
            customJsx: SelectedCard,
        },
        {
            indexOFColumn: 3,
            columnName: "Status",
            customJsx: customJsxStatus,
        }
    );

    return (
        <DynamicTable
            url={`${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`}
            canSort
            actionColumn={DropdownActions}
            name="Card_table"
            customJsxSideFilterButton={
                <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                    {/* <AvForm onSubmit={toggleAddCardForm}>
                        <StyledButtonWithIcon
                            icon="PlusIcon"
                            iconPosition="left"
                            rounded
                            variant={dominantColor}
                            onClick={toggleAddCardForm}
                            // disabled
                        >
                            {t("Add menu")}
                        </StyledButtonWithIcon>
                    </AvForm> */}
                </div>
            }
            canExpand
            canResize
            showGlobalFilter
            showFilter
            filterActive={dynamicTableWrapperContext?.filterActive}
            setLocalFilterActive={
                dynamicTableWrapperContext?.setLocalFilterActive
            }
            setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
            dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
            arrayOfCustomColumns={arrayOfCustomColumns}
            // onClick={(row: any): void => {
            //     dynamicTableWrapperContext?.setRowData(row.original);
            //     dynamicTableWrapperContext?.setActionColumnDisplay(true);
            //     dynamicTableWrapperContext?.setLocalFilterActive(false);
            // }}
            minHeight={`calc(100vh - (375px + ${isVisible ? "70px" : "0px"}))`}
            maxHeight={`calc(100vh - (375px + ${isVisible ? "70px" : "0px"}))`}
        />
    );
}
