import React from "react";

import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DominantColor } from "@components/Common/";
import { useDispatch, useSelector } from "react-redux";

import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";
import { RootState } from "@store/index";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { v4 as uuidv4 } from "uuid";
import { resetMultipleWorkflow } from "@pages/WorkFlow/helpers";
import { setModifier } from "@store/features/modifierSlice";

type CheckUnsaveDataMultiplePropsType = {
    isUnsavedDataMultiple: boolean;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CheckUnsaveDataMultiple({
    isUnsavedDataMultiple,
    setIsUnsavedDataMultiple,
}: CheckUnsaveDataMultiplePropsType) {
    const dispatch = useDispatch();
    const dominantColor: string = DominantColor();
    const { t } = useTranslation();

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    let copyModifierMultiple =
        multipleWorkflow.length > 0
            ? JSON.parse(
                  JSON.stringify(
                      multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier
                  )
              )
            : {};
    const checkSalesAddExist = (
        firstUuidSelected: string,
        startIndex: number
    ) => {
        const salesAdd: any = { isSalesAdd: false, modifierSalesAdd: "" };

        if (
            Object.keys?.(
                globalWorkflow[startIndex].data[firstUuidSelected].content || {}
            ).length > 0
        ) {
            // eslint-disable-next-line prefer-destructuring
            salesAdd.modifierSalesAdd = Object.keys(
                globalWorkflow[startIndex].data[firstUuidSelected].content
            )[0];
            if (
                Object.keys?.(
                    copyModifierMultiple[salesAdd.modifierSalesAdd] || {}
                ).length > 0 &&
                copyModifierMultiple[salesAdd.modifierSalesAdd][
                    WORKFLOW_LIST.TYPEADD
                ]
            ) {
                salesAdd.isSalesAdd = true;
            }
        }
        return salesAdd;
    };
    const insertUuidMultipleModifier = (
        previousModifier: string,
        MD5modifier: string,
        modifierType: string,
        stepObject: any,
        action: string
    ) => {
        if (action === "second") {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: copyModifierMultiple?.[previousModifier!],
            };
            // eslint-disable-next-line no-prototype-builtins
        } else if (copyModifierMultiple.hasOwnProperty(MD5modifier)) {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: {
                    ...copyModifierMultiple[MD5modifier],
                    typeSteps: true,
                    steps: stepObject,
                },
            };
        } else {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: {
                    steps: stepObject,
                    add: {},
                    category: {},
                    typeSteps: true,
                    typeAdd: false,
                    typeCategorie: false,
                },
            };
        }

        if (
            Object.keys?.(copyModifierMultiple[MD5modifier][modifierType] || {})
                .length > 0
        ) {
            Object.entries(
                copyModifierMultiple[MD5modifier][modifierType]
            )?.forEach(([iuudStepModifier]: any) => {
                if (
                    Object.keys?.(
                        copyModifierMultiple[MD5modifier][modifierType][
                            iuudStepModifier
                        ].itemModifiers
                    ).length > 0
                ) {
                    Object.entries(
                        copyModifierMultiple[MD5modifier][modifierType][
                            iuudStepModifier
                        ].itemModifiers
                    )?.forEach(([iuudItemModifier]: any) => {
                        const MD5modifier2 = uuidv4();
                        previousModifier =
                            copyModifierMultiple[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers[iuudItemModifier];
                        copyModifierMultiple = {
                            ...copyModifierMultiple,
                            [MD5modifier]: {
                                ...copyModifierMultiple[MD5modifier],
                                [modifierType]: {
                                    ...copyModifierMultiple[MD5modifier][
                                        modifierType
                                    ],
                                    [iuudStepModifier]: {
                                        ...copyModifierMultiple[MD5modifier][
                                            modifierType
                                        ][iuudStepModifier],
                                        itemModifiers: {
                                            ...copyModifierMultiple[
                                                MD5modifier
                                            ][modifierType][iuudStepModifier]
                                                .itemModifiers,
                                            [iuudItemModifier]: MD5modifier2,
                                        },
                                    },
                                },
                            },
                        };

                        insertUuidMultipleModifier(
                            previousModifier,
                            MD5modifier2,
                            modifierType,
                            stepObject,
                            "second"
                        );
                    });
                }
            });
        }
    };
    const validateMultipleWorkflow = () => {
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );

        const globalIndex = multipleWorkflow[0]?.StepIndex;
        const listUuidItem = multipleWorkflow[0]?.listeUuidItem;
        const previousModifier = Object.keys(
            multipleWorkflow[0]?.VirtualModifier
        )[0];
        if (
            Object.keys(
                multipleWorkflow[0]?.VirtualModifier[previousModifier].steps
            ).length === 0
        ) {
            canceledMultipleWorkflow();
            return;
        }
        const stepObject = copyModifierMultiple?.[previousModifier!].steps;
        // concatenate virtual modifier to modifier
        copyModifierMultiple = { ...copyModifierMultiple, ...modifierWorkflow };

        listUuidItem.forEach((elementUuid: string) => {
            const salesAdd = checkSalesAddExist(elementUuid, globalIndex);

            const MD5modifier = salesAdd.modifierSalesAdd;
            // const MD5modifier = uuidv4();
            let pathIuud: string = "copyGlobalWorkflowData?.";
            insertUuidMultipleModifier(
                previousModifier,
                MD5modifier,
                WORKFLOW_LIST.STEPS,
                stepObject,
                "first"
            );
            // insert modifier to global workflow
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflow[globalIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            // duplicate workflow data
            if (!salesAdd.isSalesAdd) {
                [
                    ...globalWorkflow[globalIndex!].previousUuids!,
                    elementUuid,
                ]?.forEach((element: string) => {
                    pathIuud += `["${element}"]?.content`;
                });

                // eslint-disable-next-line no-eval
                const stringEvaluate = eval(pathIuud);
                stringEvaluate[MD5modifier] = {
                    type: "modifier",
                    override: {},
                    content: {},
                };
            }
        });

        // duplicate in workflow data
        dispatch(setWorkflowData(copyGlobalWorkflowData));

        // reset multiple workflow
        copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
        resetMultipleWorkflow(copyWorkflow, globalIndex);

        dispatch(setModifier(copyModifierMultiple));
        dispatch(setWorkflow(copyWorkflow));
        dispatch(setMultipleWorkflow([]));
    };
    const canceledMultipleWorkflow = () => {
        const globalIndex = multipleWorkflow[0]?.StepIndex;
        const copyWorkflow: any = JSON.parse(JSON.stringify(globalWorkflow));

        const copyglobalWorkflow = JSON.parse(
            JSON.stringify(
                copyWorkflow[globalIndex!]?.previousState?.globalWorkflow
            )
        );
        copyglobalWorkflow[globalIndex] = {
            ...copyglobalWorkflow[globalIndex],
            multipleStep: {
                isMultiple: false,
                listeUuidItem: [],
            },
        };

        dispatch(setWorkflow(copyglobalWorkflow));
        dispatch(
            setWorkflowData(
                copyWorkflow[globalIndex!]?.previousState?.globalWorkflowData
            )
        );
        dispatch(setMultipleWorkflow([]));
    };
    return (
        <Modal centered isOpen={isUnsavedDataMultiple}>
            <ModalHeader toggle={() => setIsUnsavedDataMultiple(false)}>
                <StyledH2 className="text-uppercase">CONFIRMATION</StyledH2>
            </ModalHeader>
            <ModalBody>
                {t(
                    "would you like to confirm the multiple changes that have been made ?"
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        canceledMultipleWorkflow();

                        setIsUnsavedDataMultiple(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        validateMultipleWorkflow();
                        setIsUnsavedDataMultiple(false);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
