import { components } from "react-select";

export function Option(props: any) {
    return (
        <div>
            <components.Option {...props}>
                {/* <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "} */}
                <span>{props.label}</span>
            </components.Option>
        </div>
    );
}
