import React from "react";
import { RootState } from "@store/.";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { useDispatch, useSelector } from "react-redux";
import { setWorkflow } from "@store/features/workflowSlice";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { InsertOverrideBasicCompositionInItem } from "@pages/WorkFlow/hooks/helpersBasicComposition";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { uploadGetUrlImage } from "@helpers/uploadGetUrlImage";

type changeImageProductProps = {
    typeGolbal: string | undefined;
    type: string;
    item: any;
    uuid: string;
    cardData: any;
    globalIndex: number;
    startIndex: number;
    image: string | undefined;
    IsBasicCompositionStep: boolean;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ChangeImageProduct({
    typeGolbal,
    type,
    item,
    uuid,
    cardData,
    globalIndex,
    startIndex,
    image,
    IsBasicCompositionStep,
    setIsUnsavedDataMultiple,
}: changeImageProductProps) {
    const dispatch = useDispatch();
    const schemaAdbFranchise: any = localStorage.getItem("schemaAdbFranchise");

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData: any = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const handleImageUpdate = async (event: any) => {
        event.stopPropagation();
        if (
            event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpeg"
        ) {
            const result: any = await uploadGetUrlImage(
                event.target.files[0],
                schemaAdbFranchise,
                usedCard
            );
            const pathImage = result ? result.data.imagePath : "";

            const elementToUpdate: ItemType | CategoryType =
                Object.keys(item.override).length > 0
                    ? item.override[uuid]
                    : cardData?.[type]?.[uuid];
            const updatedWorkflowStepElement = {
                ...elementToUpdate,
                imageUrl: {
                    ...elementToUpdate.imageUrl,
                    Default: {
                        ...elementToUpdate.imageUrl.Default,
                        urlDefault: pathImage,
                    },
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        uuid,
                        globalIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[globalIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        uuid
                    )
                )
            );
        }
    };
    const handleOptionImage = async (event: any) => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;

        if (globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS) {
            let pathImage: string = "";

            if (
                event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg"
            ) {
                const result: any = await uploadGetUrlImage(
                    event.target.files[0],
                    schemaAdbFranchise,
                    usedCard
                );
                pathImage = result ? result.data.imagePath : "";
            } else {
                return;
            }

            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[globalUuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]
                    : cardData?.items?.[globalUuid];

            let copyOverrideOption: WorkflowOptionType = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.overrideOptions || {}
            ).length > 0
                ? (copyOverrideOption =
                      globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]?.overrideOptions)
                : (copyOverrideOption = InsertOverrideOptionInItem(
                      copyOverrideOption,
                      cardData,
                      globalUuid
                  ));

            const UuidOptions = globalWorkflow[globalIndex!]?.uuidStep;
            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions]?.content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                image: pathImage,
                            },
                        },
                    },
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        globalUuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        globalUuid
                    )
                )
            );
        }
        event.stopPropagation();
    };
    const handleBasicCompositionImage = async (event: any) => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;

        if (IsBasicCompositionStep) {
            let pathImage: string = "";

            if (
                event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg"
            ) {
                const result: any = await uploadGetUrlImage(
                    event.target.files[0],
                    schemaAdbFranchise,
                    usedCard
                );
                pathImage = result ? result.data.imagePath : "";
            } else {
                return;
            }

            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[globalUuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]
                    : cardData?.items?.[globalUuid];

            let copyOverrideBasicComposition: WorkflowOptionType = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.overrideBasicComposition || {}
            ).length > 0
                ? (copyOverrideBasicComposition =
                      globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]?.overrideBasicComposition)
                : (copyOverrideBasicComposition =
                      InsertOverrideBasicCompositionInItem(
                          copyOverrideBasicComposition,
                          cardData,
                          globalUuid
                      ));

            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideBasicComposition: {
                    ...copyOverrideBasicComposition,
                    [WORKFLOW_LIST.BASICCOMPOSITION]: {
                        ...copyOverrideBasicComposition[
                            WORKFLOW_LIST.BASICCOMPOSITION
                        ],
                        content: {
                            ...copyOverrideBasicComposition[
                                WORKFLOW_LIST.BASICCOMPOSITION
                            ]?.content,
                            [uuid]: {
                                ...copyOverrideBasicComposition[
                                    WORKFLOW_LIST.BASICCOMPOSITION
                                ]?.content[uuid],
                                image: pathImage,
                            },
                        },
                    },
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        globalUuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        globalUuid
                    )
                )
            );
        }
        event.stopPropagation();
    };
    const handleClickImage = (event: any) => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            // option
            handleOptionImage(event);
        } else if (IsBasicCompositionStep) {
            // composition de base
            handleBasicCompositionImage(event);
        } else {
            // item
            handleImageUpdate(event);
        }
    };

    return (
        <label
            htmlFor={`file-${uuid}`}
            style={
                cardType === "shop" && cardStatus === "En production"
                    ? {
                          pointerEvents: "none",
                          cursor: "pointer",
                          width: " 70px",
                          height: "53px",
                          marginLeft: "4px",
                      }
                    : {
                          cursor: "pointer",
                          width: " 70px",
                          height: "53px",
                          marginLeft: "4px",
                      }
            }
            className=" d-flex justify-content-center align-items-center "
        >
            <img
                width="100%"
                height="100%"
                style={
                    cardType === "shop" && cardStatus === "En production"
                        ? { pointerEvents: "none" }
                        : { objectFit: "contain" }
                }
                src={image === "" ? "no-pictures.svg" : image}
                className="card-img-top"
                alt="..."
            />
            <input
                type="file"
                id={`file-${uuid}`}
                name="imgCollection"
                onChange={(event: any) => {
                    handleClickImage(event);
                }}
                style={{ display: "none" }}
            />
        </label>
    );
}
