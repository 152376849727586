export default function setFocus(id: number, index: number, event: any) {
    switch (event.keyCode) {
        case 37: // left
            return document?.getElementById(`${id - 1}+${index}`)?.focus();
        case 38: // up
            return document?.getElementById(`${id}+${index - 1}`)?.focus();
        case 39: //  right
            return document?.getElementById(`${id + 1}+${index}`)?.focus();
        case 40: // down
            return document?.getElementById(`${id}+${index + 1}`)?.focus();
        case 13: // enter
            return document?.getElementById(`${id}+${index + 1}`)?.focus();
        default:
            return event.keyCode;
    }
}
