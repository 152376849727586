/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description: string;
    title: string;
    number: number;
    path: string;
    // eslint-disable-next-line react/require-default-props
    selectedCardDesignation?: string | "";
};

export default function CardMenu({
    title,
    number,
    Icon,
    description,
    path,
    selectedCardDesignation,
}: CardMenuType): JSX.Element {
    const { t } = useTranslation();
    const [Survol, setSurvol] = React.useState<boolean>(false);
    return (
        <div
            className=" page-card-carte col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 my-2"
            style={{
                cursor: "pointer",
                pointerEvents:
                    !selectedCardDesignation && title !== t("CHOOSE A CARD")
                        ? "none"
                        : "auto",
            }}
            onMouseEnter={() => {
                setSurvol(true);
            }}
            onMouseLeave={() => {
                setSurvol(false);
            }}
        >
            <Link to={path}>
                <div
                    className="card  h-100 float-right page-content_Carte"
                    style={{
                        padding: "1em",
                        borderRadius: "12px",
                        boxShadow: "0px 5px 10px #00000042",
                        backgroundColor:
                            (selectedCardDesignation === "" ||
                                selectedCardDesignation === undefined) &&
                            title !== t("CHOOSE A CARD") &&
                            title !== t("CHOOSE A CARD")
                                ? "#5b5b5b"
                                : "auto",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            minHeight: "120px",
                        }}
                    >
                        <div className="col-lg-12 col-xl-4 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                            <div>
                                {Survol ? (
                                    <Icon
                                        className="d-block w-100 "
                                        fill="#292929"
                                        stopColor="#FFFFFF"
                                    />
                                ) : (
                                    <Icon
                                        fill="#FFFFFF"
                                        stopColor="#5B5B5B"
                                        className="d-block w-100"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-8 col-md-12 col-sm-12 col-xs-12 ">
                            <div className="card-block">
                                <div className="d-flex flex-column pl-1">
                                    <div
                                        className="d-flex justify-content-between card-title"
                                        style={{
                                            color: "rgb(41, 41, 41)",
                                            letterSpacing: "1.4px",
                                        }}
                                    >
                                        <strong>{title}</strong>
                                        <strong>{number ?? 0}</strong>
                                    </div>

                                    <p
                                        style={{
                                            color: "rgb(33, 39, 57)",
                                        }}
                                        className="card-text"
                                    >
                                        {t(description)}
                                    </p>
                                    {selectedCardDesignation &&
                                    title === t("CHOOSE A CARD") ? (
                                        <div className="d-flex align-items-center justify-content-between maher ">
                                            <div
                                                style={{
                                                    color: "rgb(8, 0, 255)",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                    font: "15px / 20px Segoe UI",

                                                    opacity: 1,
                                                }}
                                            >
                                                {t("Selected card")}:
                                            </div>
                                            {Survol ? (
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        font: "15px / 20px Segoe UI",
                                                        fontWeight: "bold",
                                                        color: "#FFFFFF",
                                                        opacity: 1,
                                                    }}
                                                >
                                                    {selectedCardDesignation}
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        font: "15px / 20px Segoe UI",
                                                        fontWeight: "bold",
                                                        color: "rgb(33, 39, 57)",
                                                        opacity: 1,
                                                    }}
                                                >
                                                    {selectedCardDesignation}
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                    {!selectedCardDesignation &&
                                    title === t("CHOOSE A CARD") ? (
                                        <div className="d-flex align-items-center justify-content-between maher ">
                                            <div
                                                style={{
                                                    color: "rgb(8, 0, 255)",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                    font: "15px / 20px Segoe UI",

                                                    opacity: 1,
                                                }}
                                            >
                                                {t("Select a card")}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}
