import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "@store/.";

import Visibility from "@components/Common_DB/Visibilty/.";
import Image from "@components/Common_DB/ImageInput/Image";
import LinkedCategories from "@pages/Catalogue/Common/LinkedCategories";
import LinkedTags from "@pages/Catalogue/Common/LinkedTags";

import ParentCategories from "@pages/Catalogue/Common/ParentCategories";
import Designation from "@pages/Catalogue/Common/Designation";
import Reference from "@pages/Catalogue/Common/Reference";
import DisplayName from "@pages/Catalogue/Common/DisplayName";
import Description from "@pages/Catalogue/Common/Description";
import Color from "@pages/Catalogue/Common/Color";
import Allergens from "./allergens";
import SelectStepItem from "./SelectStepItem";
import BasicComposition from "../BasicComposition";
import IsOutOfStock from "./IsOutOfStock";
import RedirectItem from "./RedirectItem";
import Price from "./Price";
import BarCode from "./FormBodyComponents/BarCode";
import Calories from "./FormBodyComponents/Calories";
import LoyaltyPoints from "./FormBodyComponents/LoyaltyPoints";
import Options from "./FormBodyComponents/Options";

type FormBodyPropsType = {
    setStepIuud: React.Dispatch<React.SetStateAction<string>>;
    itemForm: ItemType;
    allParentsCategory: categoryLiaisonType[];
    defaultParentCategory: {
        label: string;
        value: string;
    };

    selectedLinkCategories: string[];
    setSelectedLinkCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setDefaultParentCategory: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        }>
    >;

    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;

    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
    setSelectedLinkTags: React.Dispatch<React.SetStateAction<string[]>>;

    initialDataItem?: any;
};

export default function FormBody({
    itemForm,
    setStepIuud,
    allParentsCategory,
    defaultParentCategory,
    selectedLinkCategories,
    setSelectedLinkCategories,
    setDefaultParentCategory,
    dispatchItem,
    addToRefs,
    setSelectedLinkTags,
    initialDataItem,
}: FormBodyPropsType) {
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const [isDisplayNameModalOpen, setIsDisplayNameModalOpen] =
        React.useState(false);
    const [isOptionModalOpen, setIsOptioneModalOpen] = React.useState(false);
    const [isDescriptionNameModalOpen, setisDescriptionNameModalOpen] =
        React.useState(false);

    const InitialBasicComposition = (data: ItemType[]) => {
        const listComposition = data?.reduce(
            (previousValue: any, currentValue: any) => {
                return {
                    ...previousValue,
                    [currentValue.iuud]: {
                        iuud: currentValue.iuud,
                        quantity: 1,
                        title: currentValue.item.title,
                        isVisible: true,
                        isObligatory: false,
                    },
                };
            },
            {}
        );

        const result = Object.values(listComposition).map((elm: any) => ({
            label: elm.title,
            value: elm,
        }));
        return result;
    };
    const InitialBasicCompositionWorkflow = (data: any) => {
        const result: any = Object.entries(data).map(([iuud, elm]: any) => ({
            label: elm.title,
            value: { ...elm, iuud },
        }));

        return result;
    };

    // eslint-disable-next-line no-nested-ternary
    const basicComposition: any[] = initialDataItem.data
        ? InitialBasicComposition(initialDataItem.data)
        : initialDataItem.Workflow
        ? InitialBasicCompositionWorkflow(initialDataItem.Workflow)
        : [];

    let itemBasicComposition: any[] = [];
    if (itemForm.basicComposition) {
        itemBasicComposition = Object.entries(
            itemForm.basicComposition as any
        ).map((key: any) => {
            let label = "";
            const composition = basicComposition!.filter(
                (elm: any) => elm.value.iuud === key[0]
            );

            if (composition.length > 0) {
                label = composition[0].label;
            }

            return {
                label,
                value: {
                    ...key[1],
                    iuud: key[0],
                    title: label,
                },
            };
        });
    }

    return (
        <div
            className=" px-3 py-2"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <SelectStepItem
                itemSteps={itemForm.steps}
                setStepIuud={setStepIuud}
                dispatch={dispatchItem}
                stepsList={initialDataItem.othersData.stepsList}
            />
            <BarCode
                addToRefs={addToRefs}
                dispatchItem={dispatchItem}
                itemForm={itemForm}
            />
            <Designation
                addToRefs={addToRefs}
                dispatch={dispatchItem}
                value={itemForm?.title}
            />
            <Reference
                addToRefs={addToRefs}
                dispatch={dispatchItem}
                value={itemForm?.reference}
            />
            <LinkedTags
                setSelectedLinkTagsIuud={setSelectedLinkTags}
                dispatch={dispatchItem}
                tagForm={itemForm.linkedTags}
                allTags={initialDataItem.othersData.tagsList}
            />
            <DisplayName
                DefaultValueName={itemForm?.displayName?.Default?.nameDefault}
                dispatch={dispatchItem}
                displayName={itemForm?.displayName}
                isDisplayNameModalOpen={isDisplayNameModalOpen}
                setIsDisplayNameModalOpen={setIsDisplayNameModalOpen}
            />
            <Description
                DefaultValueName={itemForm?.description?.Default?.nameDefault}
                dispatch={dispatchItem}
                description={itemForm.description}
                isDescriptionNameModalOpen={isDescriptionNameModalOpen}
                setisDescriptionNameModalOpen={setisDescriptionNameModalOpen}
            />
            <ParentCategories
                type="item"
                defaultParentCategory={defaultParentCategory}
                setDefaultParentCategory={setDefaultParentCategory}
                allParentsCategory={allParentsCategory}
                selectedLinkCategories={selectedLinkCategories}
                dispatch={dispatchItem}
                // eslint-disable-next-line
                isRequired={isMultiModify ? false : true}
                addToRefs={addToRefs}
            />
            <LinkedCategories
                allParentsCategory={allParentsCategory}
                defaultParentCategory={defaultParentCategory}
                selectedLinkCategories={selectedLinkCategories}
                setSelectedLinkCategories={setSelectedLinkCategories}
                dispatch={dispatchItem}
            />
            <Allergens
                itemAllergens={itemForm?.allergens}
                isRedirect={itemForm?.isRedirect}
                dispatch={dispatchItem}
            />
            <Color dispatch={dispatchItem} value={itemForm?.color} />
            <Calories
                dispatch={dispatchItem}
                value={itemForm?.calories}
                isRedirect={itemForm?.isRedirect}
            />
            <LoyaltyPoints
                dispatch={dispatchItem}
                value={itemForm?.fidelity}
                isRedirect={itemForm?.isRedirect}
            />
            <Options
                dispatch={dispatchItem}
                itemOptions={itemForm?.options}
                isOptionModalOpen={isOptionModalOpen}
                setIsOptioneModalOpen={setIsOptioneModalOpen}
                listOptions={initialDataItem?.othersData?.optionsList}
                advancedPrice={itemForm.price.advancedPrice}
            />
            {/* <Rank dispatch={dispatchItem} value={itemForm?.ranks.default} /> */}
            <Visibility
                dispatch={dispatchItem}
                visibilityInfo={itemForm.visibilityInfo}
            />
            <Image dispatch={dispatchItem} imageUrl={itemForm.imageUrl} />
            <IsOutOfStock
                dispatchItem={dispatchItem}
                isOutofstock={itemForm.outOfStock}
            />
            <RedirectItem
                dispatchItem={dispatchItem}
                isRedirect={itemForm.isRedirect}
            />
            <BasicComposition
                isRedirect={itemForm.isRedirect}
                listOfItems={basicComposition}
                dispatch={dispatchItem}
                initialValue={itemBasicComposition}
                isWithQuantity={itemForm.active_quantity}
            />
            <Price
                itemForm={itemForm}
                dispatchItem={dispatchItem}
                allOptions={initialDataItem?.othersData?.optionsList}
            />
        </div>
    );
}
