import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";

import { ACTIONS } from "@helpers/.";

type AllergenGroupPropsType = {
    group: AllergenType["group"];
    dispatchAllergenForm: React.Dispatch<ReducerActionType>;
};

export default function AllergenGroupSelectInput({
    group,
    dispatchAllergenForm,
}: AllergenGroupPropsType) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const { t } = useTranslation();

    const [allergenGroups, setAllergenGroups] = React.useState<
        Record<string, AllergenGroupType>[]
    >([]);

    const unfilteredAllergenGroups: {
        label: string;
        value: string;
    }[] = Object.entries(allergenGroups).map(([iuud, allergen]) => ({
        label: String(allergen.title),
        value: iuud,
    }));

    const handleUpdateSelectedGroups = (createdGroup: any) => {
        dispatchAllergenForm({
            type: ACTIONS.ALLERGEN_GROUP,
            payload: createdGroup,
        });
    };
    const newGroupeAllergeneJsx = (allergenGroupInputValue: string) => (
        <span>
            {allergenGroupInputValue} ({t("New group")})
        </span>
    );
    React.useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/allergen-groups`
            )
            .then((res) => setAllergenGroups(res.data));
    }, [cardIuud, schemaAdbFranchise]);

    return (
        <CreatableSelect
            name="allergens"
            options={unfilteredAllergenGroups}
            onChange={handleUpdateSelectedGroups}
            isClearable
            placeholder={t("Select")}
            // eslint-disable-next-line react/no-unstable-nested-components
            noOptionsMessage={() => (
                <React.Fragment>{t("No options")}</React.Fragment>
            )}
            isSearchable
            formatCreateLabel={newGroupeAllergeneJsx}
            isValidNewOption={(newOption: string): boolean => {
                const alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
                return !!(
                    newOption !== "" && newOption.match(alphanumericRegex)
                );
            }}
            value={group?.label ? group : null}
        />
    );
}
