import React from "react";

import { Table } from "react-bootstrap";
import { usedPostRequest } from "@helpers/axios ";
import shortid from "shortid";
import { useTranslation } from "react-i18next";
import { TitleIndex } from "@helpers/ImportFile";
import { LoadingDataAnimation } from "@components/Common";

type ThreadTableType = {
    file: any;
    setListTitle: Function;
    ListTitle: any;
};
export default function ThreadTable({
    file,
    ListTitle,
    setListTitle,
}: ThreadTableType) {
    const { t } = useTranslation();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const pathname = window.location.pathname
        .toString()
        .split("/")[2]
        .toUpperCase();

    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const titleList = TitleIndex(file);
    const dataTitleList = {
        titleList,
    };

    React.useEffect(() => {
        if (titleList.length > 0) {
            let url = `${process.env.REACT_APP_ADB_API_URL}cards/${schemaAdbFranchise}/${cardIuud}/verifyCategories`;
            if (pathname === "ARTICLES") {
                url = `${process.env.REACT_APP_ADB_API_URL}cards/${schemaAdbFranchise}/${cardIuud}/verifyItem`;
            } else if (pathname === "STEPS") {
                url = `${process.env.REACT_APP_ADB_API_URL}cards/${schemaAdbFranchise}/${cardIuud}/verifySteps`;
            }

            // eslint-disable-next-line react-hooks/rules-of-hooks
            usedPostRequest(url, dataTitleList).then((response: any) => {
                setListTitle(response);
            });
        } else {
            setListTitle("To add");
        }
        // eslint-disable-next-line
    }, []);

    return !ListTitle ? (
        <LoadingDataAnimation />
    ) : (
        <Table responsive className="text-center ">
            <thead>
                <tr>
                    {file.cols.map((row: any, index: number) => {
                        return (
                            <React.Fragment key={shortid.generate()}>
                                {row.visibility === true && (
                                    <th key={shortid.generate()}>
                                        <div className="d-flex bd-highlight">
                                            {index === 0 && (
                                                <input
                                                    type="checkbox"
                                                    name="checklist-list"
                                                    className="checkbox-box"
                                                    checked
                                                />
                                            )}
                                            <div className="p-2 flex-fill bd-highlight">
                                                {row.name
                                                    ? t(row.index)
                                                    : row.key}
                                            </div>
                                        </div>
                                    </th>
                                )}
                            </React.Fragment>
                        );
                    })}
                    <th>
                        <div className="d-flex bd-highlight">
                            <div className="p-2 flex-fill bd-highlight">
                                {t("Type of action")}
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <>
                {file.rows.map((row: any) => {
                    return (
                        <tr key={shortid.generate()}>
                            {row.map((col: any, index: number) => {
                                return (
                                    <React.Fragment key={shortid.generate()}>
                                        {row[row.length - 3] === true &&
                                            index !== row.length - 2 &&
                                            index !== row.length - 1 &&
                                            (row.length - 3 === index ? (
                                                <th key={shortid.generate()}>
                                                    <div
                                                        className={` p-2 flex-fill bd-highlight d-flex justify-content-center ${
                                                            row[
                                                                row.length - 2
                                                            ] === "To add"
                                                                ? " btn_add_clz"
                                                                : "btn_update_clz"
                                                        }`}
                                                    >
                                                        {row[row.length - 2] ===
                                                        "To add"
                                                            ? `${t("Add")}`
                                                            : `${t(
                                                                  "Mis a jour"
                                                              )}`}
                                                    </div>
                                                </th>
                                            ) : (
                                                file.cols[index].visibility && (
                                                    <th>
                                                        <div className="d-flex bd-highlight d-flex align-items-center">
                                                            {index === 0 && (
                                                                <input
                                                                    type="checkbox"
                                                                    name="checklist-list"
                                                                    className="checkbox-box"
                                                                    checked
                                                                />
                                                            )}
                                                            <div className="p-2 flex-fill bd-highlight">
                                                                {col}
                                                            </div>
                                                        </div>
                                                    </th>
                                                )
                                            ))}
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        </Table>
    );
}
