import { LoadingDataAnimation } from "@components/Common";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import AddCategory from "@pages/Catalogue/Categories/AddCategory";
import AddItem from "@pages/Catalogue/Items/addItem";
import AddStep from "@pages/Catalogue/steps/AddStep";

type OverrideWorkflowElementPropsType = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    othersData: {
        itemOthersData: any;
        categoryOthersData: any;
        stepOthersData: any;
    };
    typeElement: string;
    initialData: any;
};
export function OverrideWorkflowElement({
    othersData,
    typeElement,
    setIsLoading,
    initialData,
}: OverrideWorkflowElementPropsType) {
    if (typeElement === WORKFLOW_LIST.CATEGORIES) {
        return (
            othersData.categoryOthersData && (
                <AddCategory
                    setIsLoading={setIsLoading}
                    initialDataCategory={{
                        othersData: othersData.categoryOthersData,
                    }}
                />
            )
        );
    }
    if (typeElement === WORKFLOW_LIST.ITEMS) {
        return (
            othersData.itemOthersData && (
                <AddItem
                    setIsLoading={setIsLoading}
                    initialDataItem={{
                        othersData: othersData.itemOthersData,
                        Workflow: initialData.items ?? {},
                    }}
                />
            )
        );
    }
    if (typeElement === WORKFLOW_LIST.STEPS) {
        return (
            othersData.stepOthersData && (
                <AddStep
                    initialData={{
                        othersData: othersData.itemOthersData,
                    }}
                />
            )
        );
    }
    return <LoadingDataAnimation />;
}
