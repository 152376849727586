import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

type customCurrencyInputProps = {
    id: string;
    name: string;
    decimalsLimit: number;

    defaultValue: string | number;
    disabled?: boolean;
    onChange: Function;
    onBlur?: Function;
};

const isInputPrice = (e: any) => {
    if (!/[0-9-.]/.test(e.key)) {
        e.preventDefault();
    }
};

export default function CustomCurrencyInput({
    id,
    name,
    decimalsLimit = 2,
    defaultValue,
    onChange,
    disabled,
    onBlur,
}: customCurrencyInputProps) {
    const { t } = useTranslation();

    return (
        <CurrencyInput
            disabled={disabled}
            className="form-control"
            id={id}
            name={name}
            allowNegativeValue={false}
            placeholder={t("Enter")}
            min={0}
            inputMode="decimal"
            decimalsLimit={decimalsLimit}
            onKeyPress={isInputPrice}
            decimalSeparator="."
            value={defaultValue || 0}
            onValueChange={onChange as () => void}
            onBlur={onBlur as () => void}
        />
    );
}
