/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import uuidByString from "uuid-by-string";

import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";

import { RootState } from "@store/.";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { StepIcon } from "@components/Common/SvgIcons";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { DominantColor } from "@components/Common";
import { ArrowUpIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowUpIcons";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";
import SaleAddIcon from "@components/Common/SvgIcons/SaleAddIcon";
import { IconElement } from "./IconElement";
import ThreedotIcon from "./ThreedotIcon";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
    StepExist,
    checkMultipleItemInStep,
} from "./helpers";
import {
    GetlistUuidBasicComposition,
    GetlistUuidItemsInStepBasicComposition,
} from "./hooks/helpersBasicComposition";
import {
    GetlistUuidOptions,
    InsertOverrideOptionInItem,
} from "./hooks/helpersOption";

import { EditProduct } from "./hooks/global/card/EditProduct";
import { VisibilityProduct } from "./hooks/global/card/VisibilityProduct";
import { ChangeImageProduct } from "./hooks/global/card/ChangeImageProduct";
import { CheckedProduct } from "./hooks/global/card/CheckedProduct";

import "./style.css";

type WorkFlowCardProps = WorkFlowStepElementProps & {
    isGolbalOptionActive?: boolean;
    globalIndex: number;
    setTypeElement: React.Dispatch<React.SetStateAction<string>> | undefined;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    setContentModal: React.Dispatch<any>;
    cardData: any;
    isVisible?: boolean;
    startIndex: number;
    isOptionModifier: string;
    formBasicComposition: basicComposition;
    CheckStepSalesExist: boolean;
    checkstepItem: boolean;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
};

export default function WorkFlowCardV2({
    item,
    title,
    uuid,
    type,
    order,
    typeGolbal,
    isGolbalOptionActive,
    globalIndex,
    setTypeElement,
    setIsOpenStepModal,
    setContentModal,
    image,
    colorElement,
    cardData,
    isVisible = true,
    startIndex,
    isOptionModifier,
    formBasicComposition,
    CheckStepSalesExist,
    checkstepItem,
    setIsUnsavedDataMultiple,
    setIndexSelected,
}: WorkFlowCardProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData: any = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const IsBasicComposition: boolean =
        item?.type === WORKFLOW_LIST.ITEMS &&
        Object.keys?.(cardData?.[item.type]?.[uuid]?.basicComposition || {})
            .length > 0;
    const IsBasicCompositionStep =
        globalWorkflow[globalIndex!]?.uuidStep ===
        WORKFLOW_LIST.BASICCOMPOSITION;

    const IsOptionStep =
        globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS;

    const IsOption: boolean =
        item?.type === WORKFLOW_LIST.ITEMS &&
        Object.keys?.(cardData?.[item.type]?.[uuid]?.options || {}).length > 0;

    // eslint-disable-next-line no-unused-vars
    const [isActionColumnDisplayed, setActionColumnDisplay] =
        React.useState(false);

    const [isOptionActive, setIsOptionActive] =
        React.useState(isGolbalOptionActive);
    const dispatch = useDispatch();

    function handleClick() {
        if (globalWorkflow.find((elm) => elm.uuid === uuid)) {
            return;
        }
        if (multipleWorkflow.length > 0) {
            setIsUnsavedDataMultiple(true);

            return;
        }

        // get item to workflow data
        let pathIuud: string = "globalWorkflowData?.";
        let listItems: any = {};

        if (globalWorkflow[globalIndex!].previousUuids.length > 0) {
            [...globalWorkflow[globalIndex!].previousUuids, uuid]?.forEach(
                (element: string) => {
                    pathIuud += `["${element}"]?.content`;
                }
            );
            // eslint-disable-next-line no-eval
            listItems = JSON.parse(JSON.stringify(eval(pathIuud)));
        } else {
            listItems = JSON.parse(
                JSON.stringify(globalWorkflowData[uuid].content)
            );
        }
        /* add rank item */
        listItems = Object.entries(listItems)
            .sort((a: any) =>
                // eslint-disable-next-line no-nested-ternary
                a[1] ? (a[1].type === WORKFLOW_LIST.CATEGORIES ? -1 : 1) : 1
            )
            .sort((a: any, b: any) =>
                // eslint-disable-next-line no-nested-ternary
                a[1]
                    ? // eslint-disable-next-line no-nested-ternary
                      a[1].rank
                        ? b[1].rank
                            ? a[1].rank - b[1].rank
                            : -1
                        : 1
                    : 1
            )
            .reduce((prev: any, [key, Value]: any, index: number) => {
                if (Value !== null) {
                    const workflowData = Value;
                    if (workflowData.rank === undefined) {
                        workflowData.rank = index + 1;
                    }
                    return {
                        ...prev,
                        [key]: workflowData,
                    };
                }
                return { ...prev };
            }, {});

        if (type === WORKFLOW_LIST.CATEGORIES) {
            const updateGlobalWorkflow = globalWorkflow.slice(
                0,
                globalIndex! + 1
            );
            // selected categorie
            updateGlobalWorkflow[globalIndex!] = {
                ...updateGlobalWorkflow[globalIndex!],
                selected: uuid,
            };
            dispatch(
                setWorkflow([
                    ...updateGlobalWorkflow,
                    {
                        title,
                        data: listItems,
                        uuid,
                        type: WORKFLOW_LIST.CATEGORIES,
                        previousUuids: [
                            ...globalWorkflow[
                                globalIndex!
                            ].previousUuids!.slice(0, globalIndex!),
                            uuid,
                        ],
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                        selected: "",
                        previousState: {},
                    },
                ])
            );
        }
    }
    const handleOptionAddStep = (uuidModifier: string) => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;

        if (globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS) {
            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[globalUuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]
                    : cardData?.items?.[globalUuid];

            let copyOverrideOption: any = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.overrideOptions || {}
            ).length > 0
                ? (copyOverrideOption =
                      globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]?.overrideOptions)
                : (copyOverrideOption = InsertOverrideOptionInItem(
                      copyOverrideOption,
                      cardData,
                      globalUuid
                  ));

            const UuidOptions = globalWorkflow[globalIndex!]?.uuidStep;
            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions]?.content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                modifier: uuidModifier,
                            },
                        },
                    },
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        globalUuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        globalUuid
                    )
                )
            );
        }
    };

    const AddContentStepModal = () => {
        const { previousModifier } = globalWorkflow[globalIndex!];
        const UuidStep = globalWorkflow[globalIndex!].uuidStep;
        let copymodifierWorkflow =
            multipleWorkflow.length > 0
                ? JSON.parse(
                      JSON.stringify(
                          multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier
                      )
                  )
                : JSON.parse(JSON.stringify(modifierWorkflow));

        const copyMultipleWorkflow = JSON.parse(
            JSON.stringify(multipleWorkflow)
        );
        let dispatchModifier: any = {};

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };
        const iuudMD5 =
            Object.keys?.(item.content || {}).length > 0
                ? Object.keys(item.content)[0]
                : uuidByString(
                      [
                          ...globalWorkflow[globalIndex!].previousUuids!,
                          uuid,
                      ].join("")
                  );

        // add modifier
        // eslint-disable-next-line no-prototype-builtins
        if (copymodifierWorkflow.hasOwnProperty(iuudMD5)) {
            copymodifierWorkflow = {
                ...copymodifierWorkflow,
                [iuudMD5]: {
                    ...copymodifierWorkflow[iuudMD5],
                    typeSteps: true,
                },
            };
        } else {
            copymodifierWorkflow = {
                ...copymodifierWorkflow,
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: true,
                    typeAdd: false,
                    typeCategorie: false,
                },
            };
        }
        let stepData: any = [];
        let count: number = 0;
        // get option
        if (IsOption) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    let listValue: any = {};
                    const optionForm = GetlistUuidOptions(
                        copymodifierWorkflow,
                        previousModifier,
                        UuidStep,
                        iuudOption,
                        uuid,
                        cardData,
                        WORKFLOW_LIST.RankOptions,
                        WORKFLOW_LIST.STEPS
                    );
                    optionForm.forEach((iuudValue: any) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                            },
                        };
                    });
                    stepData = [
                        ...stepData,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousModifier: iuudMD5,
                            previousUuids: [
                                ...globalWorkflow[globalIndex!].previousUuids!,
                                uuid,
                            ],
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }
        // get list basic composition
        let listItems: any = {};
        count += 1;
        if (IsBasicComposition) {
            const ListUuidBasicCompostion =
                GetlistUuidItemsInStepBasicComposition(
                    copymodifierWorkflow,
                    previousModifier,
                    UuidStep,
                    uuid,
                    cardData,
                    WORKFLOW_LIST.STEPS
                );
            ListUuidBasicCompostion.forEach((iuudItem: string) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                    },
                };
            });
            stepData = [
                ...stepData,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuid,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }
        // add liste iuud
        const ContentModal: any = [];
        ContentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.TRIGGER,
            triggerItemIuud: uuid,

            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data:
                IsBasicComposition || IsOption
                    ? stepData
                    : [
                          {
                              title: `Etape 1`,
                              data: IsBasicComposition ? listItems : [],
                              uuid,
                              uuidStep: IsBasicComposition
                                  ? WORKFLOW_LIST.BASICCOMPOSITION
                                  : "",
                              type: WORKFLOW_LIST.STEPS,
                              previousUuids: [
                                  ...globalWorkflow[globalIndex!]
                                      .previousUuids!,
                                  uuid,
                              ],
                              override: {},
                              multipleStep: {
                                  isMultiple: false,
                                  listeUuidItem: [],
                              },
                          },
                      ],
        });
        setContentModal(ContentModal);

        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            handleOptionAddStep(iuudMD5);
            dispatchModifier = copymodifierWorkflow;
        } else {
            const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                uuidModifier: iuudMD5!,
            };

            // add new step & insert modifier in globalworkflow
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [uuid]: {
                        ...copyWorkflow[globalIndex!].data[uuid],
                        content: {
                            ...copyWorkflow[globalIndex!].data[uuid].content,
                            [iuudMD5]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            dispatch(setWorkflow(copyWorkflow));

            // eslint-disable-next-line no-unused-expressions
            if (
                copymodifierWorkflow[
                    globalWorkflow[globalIndex!].previousModifier!
                ]?.steps[
                    globalWorkflow[globalIndex!].uuidStep!
                    // eslint-disable-next-line no-prototype-builtins
                ]?.itemModifiers.hasOwnProperty(uuid)
            ) {
                dispatchModifier = copymodifierWorkflow;
            } else {
                dispatchModifier = {
                    ...copymodifierWorkflow,
                    [globalWorkflow[globalIndex!].previousModifier!]: {
                        ...copymodifierWorkflow[
                            globalWorkflow[globalIndex!].previousModifier!
                        ],
                        steps: {
                            ...copymodifierWorkflow[
                                globalWorkflow[globalIndex!].previousModifier!
                            ].steps,

                            [globalWorkflow[globalIndex!].uuidStep!]: {
                                ...copymodifierWorkflow[
                                    globalWorkflow[globalIndex!]
                                        .previousModifier!
                                ].steps[globalWorkflow[globalIndex!].uuidStep!],
                                itemModifiers: {
                                    ...copymodifierWorkflow[
                                        globalWorkflow[globalIndex!]
                                            .previousModifier!
                                    ].steps[
                                        globalWorkflow[globalIndex!].uuidStep!
                                    ].itemModifiers,
                                    [uuid]: iuudMD5,
                                },
                            },
                        },
                        add: {
                            ...copymodifierWorkflow[
                                globalWorkflow[globalIndex!].previousModifier!
                            ].add,
                        },
                        category: {},
                    },
                };
            }
        }

        if (multipleWorkflow.length > 0) {
            copyMultipleWorkflow[copyMultipleWorkflow.length - 1] = {
                ...copyMultipleWorkflow[copyMultipleWorkflow.length - 1],
                VirtualModifier: dispatchModifier,
            };
            dispatch(setMultipleWorkflow(copyMultipleWorkflow));
        } else {
            dispatch(setModifier(dispatchModifier));
        }
        setIsOpenStepModal(true);
    };
    const AddContentSalesModal = () => {
        let copymodifierWorkflow =
            multipleWorkflow.length > 0
                ? JSON.parse(
                      JSON.stringify(
                          multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier
                      )
                  )
                : JSON.parse(JSON.stringify(modifierWorkflow));

        const copyMultipleWorkflow = JSON.parse(
            JSON.stringify(multipleWorkflow)
        );
        let dispatchModifier: any = {};

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };
        const iuudMD5 =
            Object.keys?.(item.content || {}).length > 0
                ? Object.keys(item.content)[0]
                : uuidByString(
                      [
                          ...globalWorkflow[globalIndex!].previousUuids!,
                          uuid,
                      ].join("")
                  );

        // add to modifier
        // eslint-disable-next-line no-prototype-builtins
        if (copymodifierWorkflow.hasOwnProperty(iuudMD5)) {
            copymodifierWorkflow = {
                ...copymodifierWorkflow,
                [iuudMD5]: {
                    ...copymodifierWorkflow[iuudMD5],
                    typeAdd: true,
                },
            };
        } else {
            copymodifierWorkflow = {
                ...copymodifierWorkflow,
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: false,
                    typeAdd: true,
                    typeCategorie: false,
                },
            };
        }

        // add liste iuud
        const ContentModal: any = [];
        ContentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.ADD,

            triggerItemIuud: uuid,

            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            handleOptionAddStep(iuudMD5);
            dispatchModifier = copymodifierWorkflow;
        } else {
            const updateGlobalWorkflow = [...globalWorkflow];
            updateGlobalWorkflow[globalIndex!] = {
                ...updateGlobalWorkflow[globalIndex!],
                uuidModifier: iuudMD5!,
            };

            // add new step & insert modifier in globalworkflow
            const copyWorkflow = [...updateGlobalWorkflow];
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [uuid]: {
                        ...copyWorkflow[globalIndex!].data[uuid],
                        content: {
                            ...copyWorkflow[globalIndex!].data[uuid].content,
                            [iuudMD5]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            dispatch(setWorkflow(copyWorkflow));

            // eslint-disable-next-line no-unused-expressions
            if (
                copymodifierWorkflow[
                    globalWorkflow[globalIndex!].previousModifier!
                ]?.steps[
                    globalWorkflow[globalIndex!].uuidStep!
                    // eslint-disable-next-line no-prototype-builtins
                ]?.itemModifiers.hasOwnProperty(uuid)
            ) {
                dispatchModifier = copymodifierWorkflow;
            } else {
                dispatchModifier = {
                    ...copymodifierWorkflow,
                    [globalWorkflow[globalIndex!].previousModifier!]: {
                        ...copymodifierWorkflow[
                            globalWorkflow[globalIndex!].previousModifier!
                        ],
                        steps: {
                            ...copymodifierWorkflow[
                                globalWorkflow[globalIndex!].previousModifier!
                            ].steps,
                            [globalWorkflow[globalIndex!].uuidStep!]: {
                                ...copymodifierWorkflow[
                                    globalWorkflow[globalIndex!]
                                        .previousModifier!
                                ].steps[globalWorkflow[globalIndex!].uuidStep!],
                                itemModifiers: {
                                    ...copymodifierWorkflow[
                                        globalWorkflow[globalIndex!]
                                            .previousModifier!
                                    ].steps[
                                        globalWorkflow[globalIndex!].uuidStep!
                                    ].itemModifiers,
                                    [uuid]: iuudMD5,
                                },
                            },
                        },
                        add: {
                            ...copymodifierWorkflow[
                                globalWorkflow[globalIndex!].previousModifier!
                            ].add,
                        },
                        category: {},
                    },
                };
            }
        }
        if (multipleWorkflow.length > 0) {
            copyMultipleWorkflow[copyMultipleWorkflow.length - 1] = {
                ...copyMultipleWorkflow[copyMultipleWorkflow.length - 1],
                VirtualModifier: dispatchModifier,
            };
            dispatch(setMultipleWorkflow(copyMultipleWorkflow));
        } else {
            dispatch(setModifier(dispatchModifier));
        }
        setIsOpenStepModal(true);
    };
    const AddSalesAdd = () => {
        const iuudMD5 =
            Object.keys?.(item.content || {}).length > 0
                ? Object.keys(item.content)[0]
                : uuidByString(
                      [
                          ...globalWorkflow[globalIndex!].previousUuids!,
                          uuid,
                      ].join("")
                  );
        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: [],
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };
        // add modifier to modifier
        // eslint-disable-next-line no-prototype-builtins
        if (modifierWorkflow.hasOwnProperty(iuudMD5)) {
            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [iuudMD5]: {
                        ...modifierWorkflow[iuudMD5],
                        typeAdd: true,
                    },
                })
            );
        } else {
            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [iuudMD5]: {
                        steps: {},
                        add: {},
                        category: {},
                        typeSteps: false,
                        typeAdd: true,
                        typeCategorie: false,
                    },
                })
            );
        }
        // add modifier item to workflow data
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";

        [...globalWorkflow[globalIndex!].previousUuids!, uuid]?.forEach(
            (element: string) => {
                pathIuud += `["${element}"]?.content`;
            }
        );
        // eslint-disable-next-line no-eval
        const stringEvaluate = eval(pathIuud);
        stringEvaluate[iuudMD5] = {
            type: "modifier",
            override: {},
            content: {},
        };
        dispatch(setWorkflowData(copyGlobalWorkflowData));

        // add modal sales content
        const ContentModal: any = [];
        ContentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.ADD,
            triggerItemIuud: uuid,
            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);

        // add new step & insert modifier in globalworkflow
        const copyWorkflow = [...globalWorkflow];
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            data: {
                ...copyWorkflow[globalIndex!].data,
                [uuid]: {
                    ...copyWorkflow[globalIndex!].data[uuid],
                    content: {
                        ...copyWorkflow[globalIndex!].data[uuid].content,
                        [iuudMD5]: {
                            type: "modifier",
                            override: {},
                            content: {},
                        },
                    },
                },
            },
        };

        dispatch(setWorkflow(copyWorkflow));
    };
    const AddStep = () => {
        const iuudMD5 =
            Object.keys?.(item.content || {}).length > 0
                ? Object.keys(item.content)[0]
                : uuidByString(
                      [
                          ...globalWorkflow[globalIndex!].previousUuids!,
                          uuid,
                      ].join("")
                  );
        // eslint-disable-next-line no-prototype-builtins
        if (modifierWorkflow.hasOwnProperty(iuudMD5)) {
            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [iuudMD5]: {
                        ...modifierWorkflow[iuudMD5],
                        typeSteps: true,
                    },
                })
            );
        } else {
            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [iuudMD5]: {
                        steps: {},
                        add: {},
                        category: {},
                        typeSteps: true,
                        typeAdd: false,
                        typeCategorie: false,
                    },
                })
            );
        }

        // add modifier item to workflow data
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";

        [...globalWorkflow[globalIndex!].previousUuids!, uuid]?.forEach(
            (element: string) => {
                pathIuud += `["${element}"]?.content`;
            }
        );

        // eslint-disable-next-line no-eval
        const stringEvaluate = eval(pathIuud);
        stringEvaluate[iuudMD5] = {
            type: "modifier",
            override: {},
            content: {},
        };
        dispatch(setWorkflowData(copyGlobalWorkflowData));

        // check step rank/////////
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        let count = copyWorkflow.filter((element: any) => {
            if (element.title.includes("Etape")) {
                return true;
            }

            return false;
        }).length;
        if (copyWorkflow.length > globalIndex! + 1) {
            copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
            count = 0;
        }

        // add option
        if (IsOption) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    let listValue: any = {};

                    const optionForm =
                        Object.keys(
                            globalWorkflow[globalIndex].data[uuid].override ||
                                {}
                        ).length > 0 &&
                        globalWorkflow[globalIndex].data[uuid].override[
                            uuid
                            // eslint-disable-next-line no-prototype-builtins
                        ].options.hasOwnProperty(iuudOption)
                            ? globalWorkflow[globalIndex].data[uuid].override[
                                  uuid
                              ].options[iuudOption]
                            : cardData?.[item.type]?.[uuid]?.options[
                                  iuudOption
                              ];
                    optionForm.forEach((iuudValue: any, index: number) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                                rank: index + 1,
                            },
                        };
                    });
                    copyWorkflow = [
                        ...copyWorkflow,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousModifier: iuudMD5,
                            previousUuids: [
                                ...globalWorkflow[
                                    globalIndex!
                                ].previousUuids!.slice(0, globalIndex!),
                                uuid,
                            ],
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }
        // add basic composition
        count += 1;
        let listItems: any = {};
        if (IsBasicComposition) {
            const listBasicComposition = GetlistUuidBasicComposition(
                copyWorkflow,
                globalIndex,
                uuid,
                cardData
            );
            listBasicComposition.forEach((iuudItem: string, index: number) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                        rank: index + 1,
                    },
                };
            });

            copyWorkflow = [
                ...copyWorkflow,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuid,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,

                    type: WORKFLOW_LIST.STEPS,
                    previousModifier: iuudMD5,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!.slice(
                            0,
                            globalIndex!
                        ),
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            data: {
                ...copyWorkflow[globalIndex!].data,
                [uuid]: {
                    ...copyWorkflow[globalIndex!].data[uuid],
                    content: {
                        ...copyWorkflow[globalIndex!].data[uuid].content,
                        [iuudMD5]: {
                            type: "modifier",
                            override: {},
                            content: {},
                        },
                    },
                },
            },
        };

        // selected item
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            selected: uuid,
        };
        // deselected check item
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            multipleStep: {
                isMultiple: false,
                listeUuidItem: [],
            },
        };
        if (IsBasicComposition || IsOption) {
            dispatch(setWorkflow(copyWorkflow));
        } else {
            dispatch(
                setWorkflow([
                    ...copyWorkflow,
                    {
                        title: `Etape 1
                        `,
                        data: [],
                        uuid,
                        uuidStep: "",
                        type: WORKFLOW_LIST.STEPS,
                        previousModifier: iuudMD5,
                        previousUuids: [
                            ...globalWorkflow[
                                globalIndex!
                            ].previousUuids!.slice(0, globalIndex!),
                            uuid,
                        ],
                        override: {},
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    },
                ])
            );
        }
    };

    const GetStepInCategory = () => {
        const iuudMD5 = Object.keys(item.content)[0];
        // check step order
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        let count = copyWorkflow.filter((element: any) => {
            if (element.title.includes("Etape")) {
                return true;
            }

            return false;
        }).length;
        if (copyWorkflow.length > globalIndex! + 1) {
            copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
            count = 0;
        }

        /* get option */
        if (IsOption) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    const optionForm =
                        Object.keys(
                            globalWorkflow[globalIndex].data[uuid].override ||
                                {}
                        ).length > 0 &&
                        globalWorkflow[globalIndex].data[uuid].override[
                            uuid
                            // eslint-disable-next-line no-prototype-builtins
                        ].options.hasOwnProperty(iuudOption)
                            ? globalWorkflow[globalIndex].data[uuid].override[
                                  uuid
                              ].options[iuudOption]
                            : cardData?.[item.type]?.[uuid]?.options[
                                  iuudOption
                              ];
                    let listValue: any = {};
                    optionForm.forEach((iuudValue: any) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                            },
                        };
                    });
                    copyWorkflow = [
                        ...copyWorkflow,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousModifier: iuudMD5,
                            previousUuids: [
                                ...globalWorkflow[
                                    globalIndex!
                                ].previousUuids!.slice(0, globalIndex!),
                                uuid,
                            ],
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }

        /* get composition de base */
        if (IsBasicComposition) {
            count += 1;
            let listItems: any = {};
            const listBasicComposition = GetlistUuidBasicComposition(
                copyWorkflow,
                globalIndex,
                uuid,
                cardData
            );
            listBasicComposition.forEach((iuudItem: string) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                    },
                };
            });
            copyWorkflow = [
                ...copyWorkflow,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,
                    uuid,
                    type: WORKFLOW_LIST.STEPS,
                    previousModifier: iuudMD5,
                    override: {},
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!.slice(
                            0,
                            globalIndex!
                        ),
                        uuid,
                        "",
                    ],
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }

        // get list of uuid step
        // eslint-disable-next-line no-unused-expressions
        Object.keys(item.content).length > 0 &&
            Object.entries(modifierWorkflow[iuudMD5].steps)
                .sort((a: any, b: any) => a[1].rank - b[1].rank)
                .filter(
                    ([iuudStep]: any) =>
                        cardData.steps[iuudStep].archive === false &&
                        Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                            .length > 0
                )
                .forEach(([iuudStep]: any) => {
                    const stepForm =
                        Object.keys(
                            modifierWorkflow[iuudMD5].steps[iuudStep]
                                ?.overrides || {}
                        ).length > 0
                            ? modifierWorkflow[iuudMD5].steps[iuudStep]
                                  ?.overrides
                            : cardData?.steps?.[iuudStep];

                    count += 1;
                    // get list uuid items in step
                    const listItems = Object.entries(stepForm.stepItems)
                        .sort((a: any, b: any) =>
                            // eslint-disable-next-line no-nested-ternary
                            a[1].rank
                                ? b[1].rank
                                    ? a[1].rank - b[1].rank
                                    : -1
                                : 1
                        )
                        .reduce((prev: any, [key]: any, index: number) => {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    modifierWorkflow[iuudMD5].steps[iuudStep]
                                        .itemModifiers,
                                    key
                                )
                            ) {
                                return {
                                    ...prev,
                                    [key]: {
                                        type: WORKFLOW_LIST.ITEMS,
                                        override: {},
                                        content: {
                                            [modifierWorkflow[iuudMD5].steps[
                                                iuudStep
                                            ].itemModifiers[key]]: {
                                                type: "modifier",
                                                override: {},
                                                content: {},
                                            },
                                        },
                                        rank: index + 1,
                                    },
                                };
                            }
                            return {
                                ...prev,
                                [key]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {},
                                    rank: index + 1,
                                },
                            };
                        }, {});

                    copyWorkflow = [
                        ...copyWorkflow,
                        {
                            title: `Etape ${count}`,
                            data: JSON.parse(JSON.stringify(listItems)),
                            uuidStep: iuudStep,
                            uuid,
                            type: WORKFLOW_LIST.STEPS,
                            previousModifier: iuudMD5,
                            previousUuids: [
                                ...globalWorkflow[
                                    globalIndex!
                                ].previousUuids!.slice(0, globalIndex!),
                                uuid,
                                iuudStep,
                            ],
                            override:
                                Object.keys(
                                    modifierWorkflow[iuudMD5].steps[iuudStep]
                                        ?.overrides || {}
                                ).length > 0
                                    ? modifierWorkflow[iuudMD5].steps[iuudStep]
                                          ?.overrides
                                    : {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                });
        // selected item
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            selected: uuid,
        };
        dispatch(setWorkflow(copyWorkflow));
    };

    const GetSalesInSales = () => {
        const iuudMD5 =
            typeGolbal === WORKFLOW_LIST.OPTIONS
                ? isOptionModifier
                : Object.keys(item.content)[0];
        // const iuudMD5 = Object.keys(item.content)[0];
        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        // get list of uuid step
        let stepData: any = [];

        Object.entries(copyModifier[iuudMD5].add)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(([iuudStep]: any, index: number) => {
                let listItems: any = {};
                const stepForm =
                    Object.keys(
                        copyModifier[iuudMD5].add[iuudStep]?.overrides || {}
                    ).length > 0
                        ? copyModifier[iuudMD5].add[iuudStep]?.overrides
                        : cardData?.steps?.[iuudStep];

                // get list uuid items in step
                Object.entries(stepForm.stepItems)
                    .sort((a: any, b: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                    )
                    .forEach(([iuud]: any, indexItem: number) => {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                copyModifier[iuudMD5].add[iuudStep]
                                    .itemModifiers,
                                iuud
                            )
                        ) {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {
                                        [copyModifier[iuudMD5].add[iuudStep]
                                            .itemModifiers[iuud]]: {
                                            type: "modifier",
                                            override: {},
                                            content: {},
                                        },
                                    },
                                    rank: indexItem + 1,
                                },
                            };
                        } else {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {},
                                    rank: indexItem + 1,
                                },
                            };
                        }
                    });
                stepData = [
                    ...stepData,
                    {
                        title: `Etape ${index + 1}`,
                        data:
                            Object.keys(listItems).length > 0 ? listItems : [],
                        uuid,
                        uuidStep: iuudStep,
                        type: WORKFLOW_LIST.STEPS,
                        previousUuids: [
                            ...globalWorkflow[globalIndex!].previousUuids!,
                            uuid,
                            iuudStep,
                        ],
                        override:
                            Object.keys(
                                copyModifier[iuudMD5].add[iuudStep]
                                    ?.overrides || {}
                            ).length > 0
                                ? copyModifier[iuudMD5].add[iuudStep]?.overrides
                                : {},
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    },
                ];
            });

        // add content to modal
        const ContentModal: any = [];
        ContentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.ADD,
            triggerItemIuud: uuid,
            data: stepData,
            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);
    };
    const getStepInStep = () => {
        const { previousModifier } = globalWorkflow[globalIndex!];
        const UuidStep = globalWorkflow[globalIndex!].uuidStep;
        const iuudMD5 =
            typeGolbal === WORKFLOW_LIST.OPTIONS
                ? isOptionModifier
                : Object.keys(item.content)[0];

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        let stepData: any[] = [];
        let count: number = 0;
        /* get option */
        if (IsOption) {
            Object.entries(cardData?.[item.type]?.[uuid]?.options).forEach(
                ([iuudOption]: any) => {
                    const optionForm = GetlistUuidOptions(
                        copyModifier,
                        previousModifier,
                        UuidStep,
                        iuudOption,
                        uuid,
                        cardData,
                        WORKFLOW_LIST.RankOptions,
                        WORKFLOW_LIST.STEPS
                    );
                    let listValue: any = {};
                    optionForm.forEach((iuudValue: any, index: number) => {
                        listValue = {
                            ...listValue,
                            [iuudValue]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                                rank: index + 1,
                            },
                        };
                    });
                    stepData = [
                        ...stepData,
                        {
                            title: `Option`,
                            data: listValue,
                            uuid,
                            uuidStep: iuudOption,
                            type: WORKFLOW_LIST.OPTIONS,
                            previousUuids: [
                                ...globalWorkflow[globalIndex!].previousUuids!,
                                uuid,
                                "",
                            ],
                            override: {},
                            multipleStep: {
                                isMultiple: false,
                                listeUuidItem: [],
                            },
                        },
                    ];
                }
            );
        }

        /* get composition de base */
        if (IsBasicComposition) {
            count += 1;
            let listItems: any = {};
            const ListUuidBasicCompostion =
                GetlistUuidItemsInStepBasicComposition(
                    copyModifier,
                    previousModifier,
                    UuidStep,
                    uuid,
                    cardData,
                    WORKFLOW_LIST.STEPS
                );
            ListUuidBasicCompostion.forEach((iuudItem: string) => {
                listItems = {
                    ...listItems,
                    [iuudItem]: {
                        type: WORKFLOW_LIST.ITEMS,
                        override: {},
                        content: {},
                    },
                };
            });
            stepData = [
                ...stepData,
                {
                    title: `Etape ${count}`,
                    data: listItems,
                    uuidStep: WORKFLOW_LIST.BASICCOMPOSITION,
                    uuid,
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                        "",
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ];
        }
        /* get list of uuid step */
        Object.entries(copyModifier[iuudMD5].steps)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(([iuudStep]: any) => {
                let listItems: any = {};
                const stepForm =
                    Object.keys(
                        copyModifier[iuudMD5].steps[iuudStep]?.overrides || {}
                    ).length > 0
                        ? copyModifier[iuudMD5].steps[iuudStep]?.overrides
                        : cardData?.steps?.[iuudStep];

                count += 1;

                // get list uuid items in step
                Object.entries(stepForm.stepItems).forEach(
                    ([iuud]: any, index: number) => {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                copyModifier[iuudMD5].steps[iuudStep]
                                    .itemModifiers,
                                iuud
                            )
                        ) {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {
                                        [copyModifier[iuudMD5].steps[iuudStep]
                                            .itemModifiers[iuud]]: {
                                            type: "modifier",
                                            override: {},
                                            content: {},
                                        },
                                    },
                                    rank: index + 1,
                                },
                            };
                        } else {
                            listItems = {
                                ...listItems,
                                [iuud]: {
                                    type: WORKFLOW_LIST.ITEMS,
                                    override: {},
                                    content: {},
                                    rank: index + 1,
                                },
                            };
                        }
                    }
                );
                stepData = [
                    ...stepData,
                    {
                        title: `Etape ${count}`,
                        data:
                            Object.keys(listItems).length > 0 ? listItems : [],
                        uuid,
                        uuidStep: iuudStep,
                        type: WORKFLOW_LIST.STEPS,
                        previousUuids: [
                            ...globalWorkflow[globalIndex!].previousUuids!,
                            uuid,
                            iuudStep,
                        ],
                        override:
                            Object.keys(
                                copyModifier[iuudMD5].steps[iuudStep]
                                    ?.overrides || {}
                            ).length > 0
                                ? copyModifier[iuudMD5].steps[iuudStep]
                                      ?.overrides
                                : {},
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    },
                ];
            });

        // add content to modal
        const ContentModal: any = [];
        ContentModal.push({
            title: `${title!}`,
            type: WORKFLOW_LIST.TRIGGER,
            triggerItemIuud: uuid,
            data: stepData,
            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: false,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);
    };
    const handleClickStep = () => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        if (typeGolbal === WORKFLOW_LIST.STEPS) {
            if (Object.keys(item.content).length > 0) {
                const UuidModifier = Object.keys(item.content)[0];
                const checkStepExist = StepExist(
                    copyModifier,
                    UuidModifier,
                    WORKFLOW_LIST.STEPS,
                    cardData
                );
                copyModifier[UuidModifier]?.typeSteps && checkStepExist
                    ? getStepInStep()
                    : AddContentStepModal();
            } else {
                AddContentStepModal();
            }
        } else if (Object.keys(item.content).length > 0) {
            const UuidModifier = Object.keys(item.content)[0];
            const checkStepExist = StepExist(
                copyModifier,
                UuidModifier,
                WORKFLOW_LIST.STEPS,
                cardData
            );
            copyModifier[UuidModifier]?.typeSteps &&
            Object.keys(copyModifier[UuidModifier]?.steps || {}).length > 0 &&
            checkStepExist
                ? GetStepInCategory()
                : AddStep();
        } else {
            AddStep();
        }
    };
    const handleClickOptionStep = () => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            if (isOptionModifier !== "") {
                const checkStepExist = StepExist(
                    modifierWorkflow,
                    isOptionModifier,
                    WORKFLOW_LIST.STEPS,
                    cardData
                );
                modifierWorkflow[isOptionModifier]?.typeSteps && checkStepExist
                    ? getStepInStep()
                    : AddContentStepModal();
            } else {
                AddContentStepModal();
            }
        }
    };

    const handleClickSales = () => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;
        if (globalWorkflow[globalIndex].type === WORKFLOW_LIST.CATEGORIES) {
            if (Object.keys(item.content).length > 0) {
                const UuidModifier = Object.keys(item.content)[0];
                const checkStepExist = StepExist(
                    copyModifier,
                    UuidModifier,
                    WORKFLOW_LIST.ADD,
                    cardData
                );
                copyModifier[UuidModifier]?.typeAdd && checkStepExist
                    ? GetSalesInSales()
                    : AddSalesAdd();
            } else {
                AddSalesAdd();
            }
        } else if (Object.keys(item.content).length > 0) {
            if (Object.keys(item.content).length > 0) {
                const UuidModifier = Object.keys(item.content)[0];
                const checkStepExist = StepExist(
                    copyModifier,
                    UuidModifier,
                    WORKFLOW_LIST.ADD,
                    cardData
                );
                copyModifier[UuidModifier]?.typeAdd && checkStepExist
                    ? GetSalesInSales()
                    : AddContentSalesModal();
            } else {
                AddContentSalesModal();
            }
        } else {
            AddContentSalesModal();
        }
    };
    const handleClickOptionSales = () => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            if (isOptionModifier !== "") {
                const checkStepExist = StepExist(
                    modifierWorkflow,
                    isOptionModifier,
                    WORKFLOW_LIST.ADD,
                    cardData
                );
                modifierWorkflow[isOptionModifier]?.typeAdd && checkStepExist
                    ? GetSalesInSales()
                    : AddContentSalesModal();
            } else {
                AddContentSalesModal();
            }
        }
    };

    const handleGetColorIcon = (
        propsType: string,
        modifierType: string,
        modifiertypeStep: string
    ) => {
        const copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        const multipleForm = checkMultipleItemInStep(
            multipleWorkflow,
            globalWorkflow,
            globalIndex,
            uuid
        );

        switch (propsType) {
            case "stopColor":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "green";
                }

                if (Object.keys?.(item.content).length > 0) {
                    const UuidModifier = multipleForm.isMultiple
                        ? Object.keys(multipleForm.firstItemContent)[0]
                        : Object.keys(item.content)[0];
                    const checkStepExist = StepExist(
                        copyModifier,
                        UuidModifier,
                        modifiertypeStep,
                        cardData
                    );
                    if (
                        copyModifier[UuidModifier]?.[modifierType] &&
                        Object.keys?.(
                            copyModifier[UuidModifier]?.[modifiertypeStep] || {}
                        ).length > 0 &&
                        checkStepExist
                    ) {
                        return "green";
                    }
                    return "white";
                }
                return "white";

            case "fill":
                if (
                    (IsBasicComposition || IsOption) &&
                    modifierType === "typeSteps"
                ) {
                    return "white";
                }
                if (Object.keys?.(item.content).length > 0) {
                    const UuidModifier = multipleForm.isMultiple
                        ? Object.keys(multipleForm.firstItemContent)[0]
                        : Object.keys(item.content)[0];
                    const checkStepExist = StepExist(
                        copyModifier,
                        UuidModifier,
                        modifiertypeStep,
                        cardData
                    );

                    if (
                        copyModifier[UuidModifier]?.[modifierType] &&
                        Object.keys?.(
                            copyModifier[UuidModifier]?.[modifiertypeStep] || {}
                        ).length > 0 &&
                        checkStepExist
                    ) {
                        return "white";
                    }
                    return "black";
                }
                return "black";

            default:
                return "black";
        }
    };
    const handleOptionGetColorIcon = (
        propsType: string,
        modifierType: string,
        modifiertypeStep: string
    ) => {
        switch (propsType) {
            case "stopColor":
                if (isOptionModifier !== "") {
                    const checkStepExist = StepExist(
                        modifierWorkflow,
                        isOptionModifier,
                        modifiertypeStep,
                        cardData
                    );
                    if (
                        modifierWorkflow[isOptionModifier]?.[modifierType] &&
                        Object.keys?.(
                            modifierWorkflow[isOptionModifier]?.[
                                modifiertypeStep
                            ] || {}
                        ).length > 0 &&
                        checkStepExist
                    ) {
                        return "green";
                    }
                    return "white";
                }
                return "white";

            case "fill":
                if (isOptionModifier !== "") {
                    const checkStepExist = StepExist(
                        modifierWorkflow,
                        isOptionModifier,
                        modifiertypeStep,
                        cardData
                    );
                    if (
                        modifierWorkflow[isOptionModifier]?.[modifierType] &&
                        Object.keys?.(
                            modifierWorkflow[isOptionModifier]?.[
                                modifiertypeStep
                            ] || {}
                        ).length > 0 &&
                        checkStepExist
                    ) {
                        return "white";
                    }
                    return "black";
                }
                return "black";

            default:
                return "black";
        }
    };
    const handleColorFilled = (
        modifierType: string,
        modifiertypeStep: string
    ) => {
        let color: "green" | "white" | "black";
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            color = handleOptionGetColorIcon(
                "stopColor",
                modifierType,
                modifiertypeStep
            );
        } else {
            color = handleGetColorIcon(
                "stopColor",
                modifierType,
                modifiertypeStep
            );
        }

        return color;
    };

    return (
        <div className="  my-2">
            <Card
                style={{
                    maxHeight: "120px",
                    minHeight: "100px",
                    // cursor: type === "categories" ? "pointer" : "auto",
                    cursor: "pointer",
                    border: "1px solid #F2F2F2",
                    borderRadius: "5px 5px 0px 0px",
                    boxShadow: "0px 3px 6px #00000029",
                }}
                onClick={() => {
                    typeGolbal !== WORKFLOW_LIST.STEPS &&
                        typeGolbal !== WORKFLOW_LIST.OPTIONS &&
                        handleClick();
                }}
            >
                <div
                    className="card-body align-items-center  "
                    id={`${uuid}${globalIndex}`}
                    style={{
                        minHeight: "80px",
                        padding: "0px 4px",
                        display: "grid",
                        backgroundColor:
                            globalWorkflow[globalIndex!]?.selected === uuid &&
                            typeGolbal === WORKFLOW_LIST.CATEGORIES
                                ? `${dominantColor}26`
                                : "transparent",
                        gridTemplateColumns:
                            typeGolbal === WORKFLOW_LIST.STEPS ||
                            typeGolbal === WORKFLOW_LIST.OPTIONS
                                ? "10% 30% 190px 5px"
                                : "10% 30% auto 5px",
                    }}
                >
                    <CheckedProduct
                        uuid={uuid}
                        globalIndex={globalIndex}
                        colorElement={colorElement}
                        order={order}
                        IsOptionStep={IsOptionStep}
                        IsBasicCompositionStep={IsBasicCompositionStep}
                        checkstepItem={checkstepItem}
                        type={type}
                        setIsUnsavedDataMultiple={setIsUnsavedDataMultiple}
                        typeGolbal={typeGolbal}
                        setIndexSelected={setIndexSelected}
                        cardData={cardData}
                    />

                    {typeGolbal === WORKFLOW_LIST.STEPS &&
                    !IsBasicCompositionStep ? (
                        <img
                            width=" 70px"
                            height="53px"
                            style={{
                                objectFit: "contain",
                            }}
                            src={image !== "" ? image : "no-pictures.svg"}
                            className="card-img-top"
                            alt="..."
                        />
                    ) : (
                        <ChangeImageProduct
                            typeGolbal={typeGolbal}
                            type={type}
                            item={item}
                            uuid={uuid}
                            cardData={cardData}
                            globalIndex={globalIndex}
                            startIndex={startIndex}
                            image={image}
                            IsBasicCompositionStep={IsBasicCompositionStep}
                            setIsUnsavedDataMultiple={setIsUnsavedDataMultiple}
                        />
                    )}
                    <div
                        className="card-title"
                        style={{
                            borderBottom: "1px solid #000000",
                            paddingBottom: "12px",
                            opacity: 1,
                        }}
                    >
                        <LockIconWithToolTip
                            type="title"
                            lengthMax={25}
                            message={`${title}`}
                            id={`title${globalIndex}${order}`}
                        />

                        <div id={`title${globalIndex}${order}`}>
                            <div className="preview-text">
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    formBasicComposition.quantity > 1 &&
                                    formBasicComposition.isVisible
                                        ? title
                                            ? `${
                                                  formBasicComposition.quantity
                                              } X ${title.toUpperCase()}`
                                            : `${formBasicComposition.quantity} X ${title}`
                                        : title
                                        ? title.toUpperCase()
                                        : title
                                }
                            </div>
                        </div>
                    </div>
                    {typeGolbal === WORKFLOW_LIST.STEPS ||
                    typeGolbal === WORKFLOW_LIST.OPTIONS ? (
                        <div
                            id={`Flèche3${globalIndex}${order}`}
                            role="button"
                            tabIndex={0}
                            className="icon_step__CLZ text-center ml-1"
                            onClick={() => {
                                setIsOptionActive(!isOptionActive);
                            }}
                        >
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={
                                    isOptionActive
                                        ? t("Hide options")
                                        : t("Show options")
                                }
                                id={`Flèche3${globalIndex}${order}`}
                            />
                            {isOptionActive ||
                            isOptionModifier !== "" ||
                            (Object.keys?.(item.content || {}).length > 0 &&
                                CheckStepSalesExist) ||
                            (IsBasicComposition &&
                                IsBasicCompositionStep === false) ||
                            (IsOptionStep === false &&
                                IsOption &&
                                IsBasicCompositionStep === false) ? (
                                <ArrowUpIcon color="black" />
                            ) : (
                                <ArrowDownIcon color="black" />
                            )}
                        </div>
                    ) : null}
                </div>

                <div
                    className="card-body align-items-center  "
                    style={{
                        minHeight: "40px",
                        padding: "0px 4px",
                        display:
                            // eslint-disable-next-line no-nested-ternary
                            typeGolbal !== WORKFLOW_LIST.STEPS &&
                            typeGolbal !== WORKFLOW_LIST.OPTIONS
                                ? "grid"
                                : isOptionActive ||
                                  (Object.keys?.(item.content || {}).length >
                                      0 &&
                                      CheckStepSalesExist) ||
                                  (IsBasicComposition &&
                                      IsBasicCompositionStep === false) ||
                                  (IsOptionStep === false &&
                                      IsOption &&
                                      IsBasicCompositionStep === false) ||
                                  isOptionModifier !== ""
                                ? "grid"
                                : "none",
                        gridTemplateColumns: "10% auto",
                        background: "#F2F2F2 0% 0% no-repeat padding-box",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            borderRight: `4px solid ${colorElement}`,
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    />

                    <div className="ml-2">
                        <div
                            style={{
                                justifyContent: "end",
                                paddingRight: "4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {(typeGolbal !== WORKFLOW_LIST.STEPS ||
                                IsBasicCompositionStep) && (
                                <VisibilityProduct
                                    order={order}
                                    isVisible={isVisible}
                                    typeGolbal={typeGolbal}
                                    type={type}
                                    item={item}
                                    uuid={uuid}
                                    cardData={cardData}
                                    globalIndex={globalIndex}
                                    startIndex={startIndex}
                                    IsBasicCompositionStep={
                                        IsBasicCompositionStep
                                    }
                                    setIsUnsavedDataMultiple={
                                        setIsUnsavedDataMultiple
                                    }
                                    isObligatory={
                                        formBasicComposition.isObligatory
                                    }
                                />
                            )}
                            <div
                                className={
                                    ETKType === "3" &&
                                    !cardData?.items?.[
                                        globalWorkflow[globalIndex!]?.uuid
                                    ]?.isRedirect &&
                                    (typeGolbal === WORKFLOW_LIST.OPTIONS ||
                                        typeGolbal === WORKFLOW_LIST.STEPS)
                                        ? "icon_step__CLZ text-center  disabledDiv__CLZ"
                                        : "icon_step__CLZ text-center "
                                }
                                style={{
                                    display:
                                        type === WORKFLOW_LIST.CATEGORIES ||
                                        IsBasicCompositionStep
                                            ? "none"
                                            : "block",
                                    pointerEvents:
                                        cardType === "shop" &&
                                        cardStatus === "En production" &&
                                        handleColorFilled(
                                            WORKFLOW_LIST.TYPEADD,
                                            WORKFLOW_LIST.ADD
                                        ) === "white"
                                            ? "none"
                                            : "all",
                                    backgroundColor:
                                        typeGolbal === WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "stopColor",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "stopColor",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              ),
                                }}
                            >
                                <LockIconWithToolTip
                                    type="icon"
                                    lengthMax={0}
                                    message={t("Additional sale")}
                                    id={`icon${globalIndex}${order}`}
                                />
                                <SaleAddIcon
                                    id={`icon${globalIndex}${order}`}
                                    height={20}
                                    width={20}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={(event: any) => {
                                        if (
                                            ETKType !== "3" ||
                                            cardData?.items?.[
                                                globalWorkflow[globalIndex!]
                                                    ?.uuid
                                            ]?.isRedirect ||
                                            typeGolbal ===
                                                WORKFLOW_LIST.CATEGORIES
                                        ) {
                                            typeGolbal === WORKFLOW_LIST.OPTIONS
                                                ? handleClickOptionSales()
                                                : handleClickSales();
                                        }
                                        event.stopPropagation();
                                    }}
                                    stopColor={
                                        typeGolbal === WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "stopColor",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "stopColor",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              )
                                    }
                                    fill={
                                        typeGolbal === WORKFLOW_LIST.OPTIONS
                                            ? handleOptionGetColorIcon(
                                                  "fill",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              )
                                            : handleGetColorIcon(
                                                  "fill",
                                                  WORKFLOW_LIST.TYPEADD,
                                                  WORKFLOW_LIST.ADD
                                              )
                                    }
                                />
                            </div>
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t("Trigger workflow")}
                                id={`Parcours${globalIndex}${order}`}
                            />
                            <StepIcon
                                id={`Parcours${globalIndex}${order}`}
                                className={
                                    ETKType === "3" &&
                                    !cardData?.items?.[
                                        globalWorkflow[globalIndex!]?.uuid
                                    ]?.isRedirect &&
                                    (typeGolbal === WORKFLOW_LIST.OPTIONS ||
                                        typeGolbal === WORKFLOW_LIST.STEPS)
                                        ? "mx-1  disabledDiv__CLZ"
                                        : "mx-1"
                                }
                                width={31}
                                height={31}
                                stopColor={
                                    typeGolbal === WORKFLOW_LIST.OPTIONS
                                        ? handleOptionGetColorIcon(
                                              "stopColor",
                                              WORKFLOW_LIST.TYPESTEPS,
                                              WORKFLOW_LIST.STEPS
                                          )
                                        : handleGetColorIcon(
                                              "stopColor",
                                              WORKFLOW_LIST.TYPESTEPS,
                                              WORKFLOW_LIST.STEPS
                                          )
                                }
                                fill={
                                    typeGolbal === WORKFLOW_LIST.OPTIONS
                                        ? handleOptionGetColorIcon(
                                              "fill",
                                              WORKFLOW_LIST.TYPESTEPS,
                                              WORKFLOW_LIST.STEPS
                                          )
                                        : handleGetColorIcon(
                                              "fill",
                                              WORKFLOW_LIST.TYPESTEPS,
                                              WORKFLOW_LIST.STEPS
                                          )
                                }
                                style={{
                                    pointerEvents:
                                        cardType === "shop" &&
                                        cardStatus === "En production" &&
                                        handleColorFilled(
                                            WORKFLOW_LIST.TYPESTEPS,
                                            WORKFLOW_LIST.STEPS
                                        ) === "white"
                                            ? "none"
                                            : "all",
                                    borderRadius: "100%",
                                    cursor: "pointer",
                                    display:
                                        type === WORKFLOW_LIST.CATEGORIES ||
                                        IsBasicCompositionStep
                                            ? "none"
                                            : "block",
                                }}
                                onClick={(event: any) => {
                                    if (
                                        ETKType !== "3" ||
                                        cardData?.items?.[
                                            globalWorkflow[globalIndex!]?.uuid
                                        ]?.isRedirect ||
                                        typeGolbal === WORKFLOW_LIST.CATEGORIES
                                    ) {
                                        typeGolbal === WORKFLOW_LIST.OPTIONS
                                            ? handleClickOptionStep()
                                            : handleClickStep();
                                    }
                                    event.stopPropagation();
                                }}
                            />

                            {typeGolbal !== WORKFLOW_LIST.STEPS &&
                                typeGolbal !== WORKFLOW_LIST.OPTIONS && (
                                    <EditProduct
                                        order={order}
                                        item={item}
                                        uuid={uuid}
                                        type={type}
                                        globalIndex={globalIndex}
                                        cardData={cardData}
                                        setTypeElement={setTypeElement}
                                        setIsUnsavedDataMultiple={
                                            setIsUnsavedDataMultiple
                                        }
                                    />
                                )}
                            <IconElement
                                iconType={
                                    type === WORKFLOW_LIST.CATEGORIES
                                        ? WORKFLOW_LIST.CATEGORIES
                                        : WORKFLOW_LIST.ITEMS
                                }
                                globalIndex={globalIndex}
                                order={order}
                            />

                            <ThreedotIcon width={31} height={31} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}
