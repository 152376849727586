import React from "react";

import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";

import { useTranslation } from "react-i18next";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type DuplicateModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    itemToDuplicate: { iuud: string; title: string };
    setIsLoading: Function;
};

export default function DuplicateItemModal({
    isOpen,
    setIsOpen,
    itemToDuplicate,
    setIsLoading,
}: DuplicateModalPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const toggleOpen = (): boolean => setIsOpen(!isOpen);
    const handleDuplicateButtonOnClickEvent = async (): Promise<void> => {
        if (validateOnclick) {
            setValidateOnclick(false);

            setIsOpen(!isOpen);
            setIsLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items/${itemToDuplicate.iuud}/duplicate`
                )
                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    setIsLoading(false);
                    SuccessToast(t("The item duplicated successfully"));
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        }
    };

    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    <div className="d-flex">
                        {t("Duplicate item")}

                        {itemToDuplicate.title.length > 20 ? (
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={itemToDuplicate.title}
                                id="Duplicate"
                            />
                        ) : null}
                        <span className="mx-1">:</span>

                        <div id="Duplicate" className="modal_clz">
                            {itemToDuplicate.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t(" Do you want to duplicate the item ?")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleDuplicateButtonOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
