import React from "react";

import unset from "lodash.unset";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@aureskonnect/react-ui";
import { setModifier } from "@store/features/modifierSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";

type deleteStepProps = {
    isMultipleStep: boolean;
    globalIndex: number;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    startIndex: number;
};

export function DeleteStep({
    isMultipleStep,
    globalIndex,
    setIndexSelected,
    setIsClickStep,
    isClickStep,
    IsBasicCompositionStep,
    IsOptionStep,
    startIndex,
}: deleteStepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const listIuudModifier: any = [];

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const deleteUuidModifier = (
        copyModifier: any,
        iuudStep: string,
        previousModifier: string
    ) => {
        Object.entries(
            copyModifier[previousModifier].steps[iuudStep].itemModifiers
        )?.forEach(([, iuudModifier]: any) => {
            listIuudModifier.push(iuudModifier);
            if (Object.keys?.(copyModifier[iuudModifier].steps).length > 0) {
                Object.entries(copyModifier[iuudModifier].steps)?.forEach(
                    ([iuudStepModifier]: any) => {
                        if (
                            Object.keys?.(
                                copyModifier[iuudModifier].steps[
                                    iuudStepModifier
                                ].itemModifiers
                            ).length > 0
                        ) {
                            deleteUuidModifier(
                                copyModifier,
                                iuudStepModifier,
                                iuudModifier
                            );
                        }
                    }
                );
            }
        });
    };
    const deleteStep = () => {
        const newArray = [...globalWorkflow];
        const indexDelete = newArray.indexOf(globalWorkflow[globalIndex]);
        // let copyModifier: any = JSON.parse(JSON.stringify(modifierWorkflow));
        const copymultipleStep = JSON.parse(JSON.stringify(multipleWorkflow));
        let copyModifier =
            multipleWorkflow.length > 0
                ? JSON.parse(
                      JSON.stringify(
                          multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier
                      )
                  )
                : JSON.parse(JSON.stringify(modifierWorkflow));
        const iuudStep = globalWorkflow[globalIndex].uuidStep!;
        const previousModifier = globalWorkflow[globalIndex].previousModifier!;
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );

        const positionStartUuids = globalWorkflow[
            globalIndex!
        ].previousUuids!.indexOf(globalWorkflow[globalIndex!].uuid);
        const copyPreviousUuids = [
            ...globalWorkflow[globalIndex!].previousUuids!,
        ];
        copyPreviousUuids.length = positionStartUuids + 1;

        if (indexDelete !== -1) {
            newArray.splice(globalIndex, 1);
        }
        let count = 1;
        // eslint-disable-next-line array-callback-return
        newArray.map((element: any, index: number) => {
            if (element.type === WORKFLOW_LIST.OPTIONS) {
                count += 1;
            }
            if (element.type === WORKFLOW_LIST.STEPS) {
                newArray[index] = {
                    ...newArray[index],
                    title: `Etape ${count}`,
                };
                count += 1;
            }
        });
        const copyGlobalWorkflow: any = JSON.parse(JSON.stringify(newArray));
        // delete modifier from modifier
        iuudStep !== "" &&
            iuudStep !== undefined &&
            iuudStep !== WORKFLOW_LIST.BASICCOMPOSITION &&
            Object.keys?.(
                copyModifier[previousModifier].steps[iuudStep].itemModifiers
            ).length > 0 &&
            deleteUuidModifier(copyModifier, iuudStep, previousModifier);

        listIuudModifier.length > 0 &&
            listIuudModifier.forEach((element: string) => {
                unset(copyModifier, element);
            });

        if (
            count === 1 &&
            (copyModifier[previousModifier].typeSteps === false ||
                copyModifier[previousModifier].typeAdd === false)
        ) {
            // delete modifier from modifier
            delete copyModifier[previousModifier];

            // delete modifier from worflowData
            let pathIuudWorkflowData: string = "";

            copyPreviousUuids?.forEach((element: string) => {
                pathIuudWorkflowData += `${element}.content.`;
            });

            pathIuudWorkflowData += `${globalWorkflow[globalIndex]
                .previousModifier!}`;
            unset(copyGlobalWorkflowData, pathIuudWorkflowData);
            if (multipleWorkflow.length > 0) {
                const listUuidItem = multipleWorkflow[0]?.listeUuidItem;
                listUuidItem.forEach((elementUuid: string) => {
                    // delete from globalworkflow
                    copyGlobalWorkflow[globalIndex - 1] = {
                        ...copyGlobalWorkflow[globalIndex - 1],
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    };
                    copyGlobalWorkflow[globalIndex - 1] = {
                        ...copyGlobalWorkflow[globalIndex - 1],
                        data: {
                            ...copyGlobalWorkflow[globalIndex - 1].data,
                            [elementUuid]: {
                                ...copyGlobalWorkflow[globalIndex - 1].data[
                                    elementUuid
                                ],
                                content: {},
                            },
                        },
                    };
                });
            } else {
                // delete from globalworkflow
                delete copyGlobalWorkflow[startIndex].data[
                    globalWorkflow[globalIndex].uuid
                ].content[globalWorkflow[globalIndex].previousModifier!];
            }
        } else if (count === 1) {
            copyModifier = {
                ...copyModifier,
                [previousModifier]: {
                    ...copyModifier[previousModifier],
                    steps: {},
                    typeSteps: false,
                },
            };
        } else {
            const path: string = `${previousModifier}.steps.${iuudStep}`;
            iuudStep !== "" && unset(copyModifier, path);
        }
        setIndexSelected(copyGlobalWorkflow.length - 1);
        // dispatch(setModifier(copyModifier));
        if (multipleWorkflow.length > 0) {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: copyModifier,
            };

            count > 1
                ? dispatch(setMultipleWorkflow(copymultipleStep))
                : dispatch(setMultipleWorkflow([]));
        } else {
            dispatch(setModifier(copyModifier));
        }
        dispatch(setWorkflow(copyGlobalWorkflow));
        dispatch(setWorkflowData(copyGlobalWorkflowData));
    };
    return (
        <div
            className={
                isMultipleStep
                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                    : "icon_step__CLZ text-center mr-2"
            }
            style={{
                display:
                    IsBasicCompositionStep || IsOptionStep
                        ? "none"
                        : " d-flex justify-content-center align-items-center",
            }}
        >
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={t("Remove step")}
                id={`Modifier${globalIndex}`}
            />
            <TrashIcon
                style={
                    cardType === "shop" && cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "0.5" }
                        : { opacity: "1" }
                }
                fill="red"
                id={`Modifier${globalIndex}`}
                className=""
                role="button"
                width={20}
                height={20}
                onClick={() => {
                    deleteStep();
                    setIsClickStep(!isClickStep);
                }}
            />
        </div>
    );
}
