/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";

import { setNewFranchise } from "@store/features/franchiseNameSlice";
import { fetchSettings } from "@store/features/settingsSlice";
import { DominantColor } from "@components/Common";
import EtkTypeChoiceModal from "./EtkTypeChoiceModal";

export default function SelectedAccount({ selectedRow }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dominantColor: string = DominantColor();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    function handleFranchiseSelection() {
        localStorage.setItem("shopId", "0");
        localStorage.setItem("franchiseId", selectedRow.uid);
    }

    function handleShopSelection() {
        localStorage.setItem("shopId", selectedRow.uid);
        localStorage.setItem("franchiseId", selectedRow.uuidFranchise);
    }

    return (
        <StyledButton
            outline
            rounded
            variant={dominantColor}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                localStorage.setItem(
                    "schemaAdbFranchise",
                    selectedRow.franchise_schema
                );

                if (selectedRow.Nature.toLowerCase() === "franchise") {
                    handleFranchiseSelection();
                } else {
                    handleShopSelection();
                }

                dispatch(fetchSettings());

                dispatch(setNewFranchise(selectedRow.Nature));

                selectedRow.ETK_Type === 0 ? (
                    <EtkTypeChoiceModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        Id={selectedRow.Id}
                    />
                ) : (
                    navigate("/cards")
                );

                setIsOpen(true);
            }}
        >
            <EtkTypeChoiceModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                Id={selectedRow.Id}
            />

            {t("To access")}
        </StyledButton>
    );
}
