import React from "react";

import { ModalBody, ModalHeader } from "reactstrap";
import { StyledModal, StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import PlanningModalFooter from "./PlanningModalFooter";
import HeureOuverture from "./HeureOuverture";
import Planning from "./Planning";

export type PeriodType = {
    handleSave?: any;
    setselectedrow?: React.Dispatch<any>;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    active: boolean;
    setPeriod: React.Dispatch<any>;
    period: any;
    record?: any;
};

export default function PlanningModal({
    handleSave,
    setselectedrow,
    setActive,
    active,
    setPeriod,
    period,
    record,
}: PeriodType) {
    const { t } = useTranslation();
    const [isActive, setIsActive] = React.useState(false);
    const [enable, setEnable] = React.useState(false);

    return (
        <div>
            <StyledModal
                size="xl"
                isOpen={active}
                style={{ minWidth: "max-content" }}
                backdrop={false}
                centered
                fade={false}
            >
                <ModalHeader
                    toggle={() => {
                        setActive(false);
                    }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("TIME SLOTS")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody
                    style={{
                        maxHeight: "max-content",
                        overflowY: "auto",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            columnGap: "4px",
                            gridTemplateColumns: "3fr 6fr",
                        }}
                    >
                        <Planning
                            setIsActive={setIsActive}
                            setPeriod={setPeriod}
                            period={
                                record.period !== "" ? record.period : period
                            }
                            enable={enable}
                            setEnable={setEnable}
                        />

                        <HeureOuverture
                            isActive={isActive}
                            setPeriod={setPeriod}
                            period={
                                record.period !== "" ? record.period : period
                            }
                        />
                    </div>
                </ModalBody>

                <PlanningModalFooter
                    handleSave={handleSave}
                    setselectedrow={setselectedrow}
                    setActive={setActive}
                    record={record}
                    period={period}
                />
            </StyledModal>
        </div>
    );
}
