import React from "react";

import { useTranslation } from "react-i18next";

type ItemSelectionDropdownHeaderType = {
    showSteps: boolean;
    showCategories: boolean;
    setShowSteps: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCategories: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ItemSelectionDropdownHeader({
    showSteps,
    showCategories,
    setShowSteps,
    setShowCategories,
}: ItemSelectionDropdownHeaderType) {
    const { t } = useTranslation();
    return (
        <div
            className="d-flex justify-content-around align-items-center"
            style={{ border: "1px solid #E8E8E8", borderRadius: "6px" }}
        >
            <span>
                <input
                    type="checkbox"
                    id="step"
                    checked={showSteps}
                    onChange={() =>
                        setShowSteps((previousState) => !previousState)
                    }
                />
                <label htmlFor="step" className="ml-2">
                    {t("Step")}
                </label>
            </span>
            <span>
                <input
                    type="checkbox"
                    id="category"
                    checked={showCategories}
                    onChange={() =>
                        setShowCategories((previousState) => !previousState)
                    }
                />
                <label htmlFor="category" className="ml-2">
                    {t("Category")}
                </label>
            </span>
        </div>
    );
}
