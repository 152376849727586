import axios from "axios";

const axiosClient = axios.create();
export function usedGetRequest(url: string, payload: any) {
    return axiosClient.get(url, payload).then((response) => response.data);
}

export function usedPostRequest(url: string, payload: any) {
    return axiosClient.post(url, payload).then((response) => response.data);
}

export function usedPutRequest(url: string, payload: any) {
    return axiosClient.put(url, payload).then((response) => response.data);
}

export function usedDeleteRequest(url: string, payload: any) {
    return axiosClient.delete(url, payload).then((response) => response.data);
}
