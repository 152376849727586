import styled from "styled-components";

export const ValuesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

export const Value = styled.div`
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    margin: 5px;
    font-size: 0.75rem;
    color: black;
    background-color: hsl(0, 0%, 90%);
    user-select: none;
    border-radius: 0.3rem;
`;

export const XButton = styled.button`
    all: unset;
    margin-left: 0.3rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
        color: #bb392d;
    }
    &:focus {
        color: #c82f21;
    }
`;
