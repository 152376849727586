import React from "react";

import axios from "axios";
import { useSelector } from "react-redux";

import { RootState } from "@store/.";
import LinkedCategories from "@pages/Catalogue/Common/LinkedCategories";
import Image from "@components/Common_DB/ImageInput/Image";
import LinkedTags from "@pages/Catalogue/Common/LinkedTags";
import Visibility from "@components/Common_DB/Visibilty/";
import Designation from "@pages/Catalogue/Common/Designation";
import DisplayName from "@pages/Catalogue/Common/DisplayName";
import Description from "@pages/Catalogue/Common/Description";
import Reference from "@pages/Catalogue/Common/Reference";
import Color from "@pages/Catalogue/Common/Color";
import ParentCategories from "@pages/Catalogue/Common/ParentCategories";
import VideoAndImageUploader from "../VideoAndImageUploader";

type FormBodyPropsType = {
    initialDataCategory?: any;
    categoryState: CategoryType;
    allParentsCategory: categoryLiaisonType[];
    defaultParentCategory: {
        label: string;
        value: string;
    };
    selectedLinkCategories: string[];
    setSelectedLinkCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setDefaultParentCategory: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        }>
    >;
    dispatchCategory: React.Dispatch<ReducerActionType>;
    setSelectedLinkTags: React.Dispatch<React.SetStateAction<string>>;

    // eslint-disable-next-line no-unused-vars
    addToRefs: (ref: any) => void;
};

export default function FormBody({
    categoryState,
    allParentsCategory,
    defaultParentCategory,
    selectedLinkCategories,
    setSelectedLinkCategories,
    setDefaultParentCategory,
    dispatchCategory,
    setSelectedLinkTags,
    addToRefs,
    initialDataCategory,
}: FormBodyPropsType) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const [isDescriptionNameModalOpen, setisDescriptionNameModalOpen] =
        React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [lastReferenceAdded, setLastReferenceAdded] =
        React.useState<string>("");
    const [, setReferenceValue] = React.useState<string>("");

    const getCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/cards/${schemaAdbFranchise}/${cardIuud}/categories`
            )
            .then((res) => res.data)
            .then((data) =>
                setLastReferenceAdded(
                    data.data[data.data.length - 1]?.category.reference
                )
            );
    };
    React.useEffect(() => {
        getCategories();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (categoryState?.reference) {
            setReferenceValue(categoryState?.reference);
        } else {
            setReferenceValue(lastReferenceAdded);
        }
        // eslint-disable-next-line
    }, [lastReferenceAdded]);

    return (
        <div
            className="px-3 py-2"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <Designation
                addToRefs={addToRefs}
                dispatch={dispatchCategory}
                value={categoryState?.title}
            />
            <DisplayName
                DefaultValueName={
                    categoryState?.displayName?.Default?.nameDefault
                }
                dispatch={dispatchCategory}
                displayName={categoryState?.displayName}
                isDisplayNameModalOpen={isOpen}
                setIsDisplayNameModalOpen={setIsOpen}
            />
            <Description
                DefaultValueName={
                    categoryState?.description?.Default?.nameDefault
                }
                dispatch={dispatchCategory}
                isDescriptionNameModalOpen={isDescriptionNameModalOpen}
                description={categoryState?.description}
                setisDescriptionNameModalOpen={setisDescriptionNameModalOpen}
            />

            <ParentCategories
                type="category"
                defaultParentCategory={defaultParentCategory}
                FilteredCategories={
                    initialDataCategory?.othersData?.FilteredCategories
                }
                setDefaultParentCategory={setDefaultParentCategory}
                allParentsCategory={allParentsCategory}
                selectedLinkCategories={selectedLinkCategories}
                dispatch={dispatchCategory}
                isRequired={false}
                isDisabled={isMultiModify}
                addToRefs={addToRefs}
            />
            <LinkedCategories
                allParentsCategory={allParentsCategory}
                defaultParentCategory={defaultParentCategory}
                selectedLinkCategories={selectedLinkCategories}
                setSelectedLinkCategories={setSelectedLinkCategories}
                dispatch={dispatchCategory}
            />

            <LinkedTags
                setSelectedLinkTagsIuud={setSelectedLinkTags}
                dispatch={dispatchCategory}
                tagForm={categoryState.linkedTags}
                allTags={initialDataCategory.othersData.tagsList}
            />
            <Reference
                addToRefs={addToRefs}
                dispatch={dispatchCategory}
                value={categoryState?.reference}
            />
            <Color dispatch={dispatchCategory} value={categoryState?.color} />

            {/* <Rank
                dispatch={dispatchCategory}
                value={categoryState.ranks?.default}
            /> */}

            <Visibility
                dispatch={dispatchCategory}
                visibilityInfo={categoryState.visibilityInfo}
            />

            <Image
                dispatch={dispatchCategory}
                imageUrl={categoryState.imageUrl}
            />

            <VideoAndImageUploader
                dispatch={dispatchCategory}
                video={categoryState.video}
            />
        </div>
    );
}
