/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import classname from "classnames";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";

import { setActiveMenu } from "@store/features/layoutSlice";
import { RootState } from "@store/.";
import { Collapse } from "reactstrap";
import { COLORS } from "./colors";
import { ArrowDownIcon } from "./ArrowDownIcon";
import { ArrowUpIcon } from "./ArrowUpIcons";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

type MenuItemType = {
    index: number;
} & WrapperPropsType &
    MenuItemsType;

const Wrapper = styled.div<WrapperPropsType>`
    .collapsed__clz {
        display: none;
        z-index: 1;
        position: fixed;

        margin-left: 220px !important;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px !important;
        background-color: ${({ backgroundColor }: WrapperPropsType) =>
            backgroundColor || COLORS.principal};
    }
    &:hover .collapsed__clz {
        display: block;
    }
`;

export function Menu({
    link,
    menuItem,
    subMenu,
    icon,
    index,
    collapsed,
    backgroundColor,
}: MenuItemType) {
    const location = useLocation();
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] =
        React.useState<boolean>(false);
    const [isMenuCollapsed, setIsMenuCollapsed] =
        React.useState<boolean>(false);

    const dispatch = useDispatch();
    const activeMenu = useSelector(
        (state: RootState): number => state.layoutSlice.activeMenu
    );

    return (
        <li>
            <Link
                onClick={() => {
                    index === activeMenu
                        ? dispatch(setActiveMenu(-1))
                        : dispatch(setActiveMenu(index));

                    setIsSubMenuCollapsed(!isSubMenuCollapsed);
                }}
                role="menuitem"
                tabIndex={0}
                to={subMenu !== undefined ? "#" : link}
                className={classname(
                    "d-flex align-items-center justify-content-between menu-item__clz",
                    {
                        "has-arrow": subMenu !== undefined,
                        active:
                            location.pathname === `/${link}` &&
                            subMenu === undefined,
                    }
                )}
            >
                <div className="d-flex align-items-center justify-content-between menu-item__clz">
                    <div
                        style={{
                            marginRight: "7px",
                            fill: COLORS.white,
                        }}
                    >
                        {icon}
                    </div>

                    <span
                        className={classname({
                            "d-none": collapsed === true,
                        })}
                    >
                        {menuItem}
                    </span>
                </div>

                {collapsed === true || subMenu === undefined
                    ? null
                    : activeMenu === index
                    ? (function () {
                          setActiveMenu(index);
                          return <ArrowUpIcon />;
                      })()
                    : (function () {
                          setActiveMenu(-1);
                          return <ArrowDownIcon />;
                      })()}
            </Link>

            {collapsed === true ? (
                <ul className="collapsed-submenu__clz">
                    {subMenu !== undefined ? (
                        <li
                            key={shortid.generate()}
                            className="menuitem-collapsed-header__clz"
                        >
                            <span>{menuItem}</span>
                        </li>
                    ) : (
                        <li
                            key={shortid.generate()}
                            style={{ padding: "0px" }}
                            className="menuitem-collapsed-header__clz"
                        >
                            <Link to={link}>{menuItem}</Link>
                        </li>
                    )}

                    {subMenu !== undefined
                        ? subMenu?.map(
                              ({ link, menuItem, subMenu }: MenuItemsType) => (
                                  <Wrapper
                                      key={shortid.generate()}
                                      backgroundColor={backgroundColor}
                                  >
                                      <li className="collapsed-sub-submenu__clz">
                                          <Link to={link}>{menuItem}</Link>
                                      </li>
                                      {subMenu !== undefined ? (
                                          <ul className="collapsed__clz">
                                              <li
                                                  key={shortid.generate()}
                                                  className="menuitem-collapsed-header__clz "
                                              >
                                                  <span>{menuItem}</span>
                                              </li>
                                              {subMenu.map(
                                                  ({
                                                      link,
                                                      menuItem,
                                                  }: MenuItemsType) => {
                                                      return (
                                                          <li
                                                              key={shortid.generate()}
                                                          >
                                                              <li>
                                                                  <Link
                                                                      to={link}
                                                                  >
                                                                      {menuItem}
                                                                  </Link>
                                                              </li>
                                                          </li>
                                                      );
                                                  }
                                              )}
                                          </ul>
                                      ) : null}
                                  </Wrapper>
                              )
                          )
                        : null}
                </ul>
            ) : null}

            {subMenu !== undefined && collapsed !== true ? (
                <Collapse isOpen={activeMenu === index}>
                    <ul>
                        {subMenu?.map(
                            ({
                                link,
                                menuItem,
                                icon,
                                subMenu,
                            }: MenuItemsType) => {
                                const Icon: any =
                                    icon !== undefined ? icon : null;
                                return (
                                    <li key={shortid.generate()}>
                                        <Link
                                            to={
                                                subMenu !== undefined
                                                    ? "#"
                                                    : link
                                            }
                                            onClick={() => {
                                                if (subMenu !== undefined) {
                                                    setIsMenuCollapsed(
                                                        !isMenuCollapsed
                                                    );
                                                }
                                            }}
                                            className={classname(
                                                "d-flex align-items-center justify-content-between",
                                                {
                                                    active:
                                                        location.pathname ===
                                                        `/${link}`,
                                                }
                                            )}
                                            style={{
                                                padding:
                                                    ".4rem 1.5rem .4rem 3.5rem",
                                            }}
                                        >
                                            {icon ? (
                                                <Icon
                                                    style={{
                                                        marginRight: "7px",
                                                        height: "1.25em",
                                                        width: "1.25em",
                                                        fill: COLORS.white,
                                                    }}
                                                />
                                            ) : null}
                                            {menuItem}
                                            {subMenu ===
                                            undefined ? null : isMenuCollapsed ===
                                              true ? (
                                                <ArrowUpIcon />
                                            ) : (
                                                <ArrowDownIcon />
                                            )}
                                        </Link>

                                        {subMenu !== undefined ? (
                                            <Collapse isOpen={isMenuCollapsed}>
                                                <ul>
                                                    {subMenu.map(
                                                        ({
                                                            link,
                                                            menuItem,
                                                            icon,
                                                            subMenu,
                                                        }: MenuItemsType) => {
                                                            const Icon: any =
                                                                icon !==
                                                                undefined
                                                                    ? icon
                                                                    : null;
                                                            return (
                                                                <li
                                                                    key={shortid.generate()}
                                                                >
                                                                    <Link
                                                                        to={
                                                                            link
                                                                        }
                                                                        className={classname(
                                                                            "maher d-flex align-items-center justify-content-between",
                                                                            {
                                                                                active:
                                                                                    location.pathname ===
                                                                                    `/${link}`,
                                                                            }
                                                                        )}
                                                                        style={{
                                                                            padding:
                                                                                ".4rem 1.5rem .4rem 4.5rem",
                                                                        }}
                                                                    >
                                                                        {icon ? (
                                                                            <Icon
                                                                                style={{
                                                                                    marginRight:
                                                                                        "7px",
                                                                                    height: "1.25em",
                                                                                    width: "1.25em",
                                                                                    fill: COLORS.white,
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                        {
                                                                            menuItem
                                                                        }
                                                                        {subMenu ===
                                                                        undefined ? null : isSubMenuCollapsed ===
                                                                          true ? (
                                                                            <ArrowDownIcon />
                                                                        ) : (
                                                                            <ArrowUpIcon />
                                                                        )}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </Collapse>
                                        ) : null}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </Collapse>
            ) : null}
        </li>
    );
}
