import { toast } from "react-toastify";
import "./Style.css";

export default function errorToast(message: string): void {
    toast.error(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}
