import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { DynamicTableWrapperContext } from "@components/Common";
import {
    ValuesContainer,
    Value,
    XButton,
} from "@components/Common/StyledComponent";
import { filterNameWithAlphabeticRank } from "@helpers/general";
import { RootState } from "@store/index";

export const initialParentCategory = {
    label: "",
    value: "",
};
type LinkedCategoriesProps = {
    allParentsCategory: categoryLiaisonType[];
    defaultParentCategory: categoryLiaisonType;
    selectedLinkCategories: string[];
    setSelectedLinkCategories: Function;
    dispatch: React.Dispatch<ReducerActionType>;
};
export default function LinkedCategories({
    allParentsCategory,
    defaultParentCategory,
    selectedLinkCategories,
    setSelectedLinkCategories,
    dispatch,
}: LinkedCategoriesProps) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const ETKType = localStorage.getItem("ETKType");
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const link: string[] =
        dynamicTableWrapperContext?.rowData !== null
            ? dynamicTableWrapperContext?.rowData?.data[0]
                  ?.linkedChildCategories ?? []
            : [];

    const filterParentCategories = () => {
        let filteredCategories = [];
        filteredCategories = allParentsCategory
            ? allParentsCategory
                  .filter(
                      (category): boolean =>
                          category.value !== defaultParentCategory?.value
                  )
                  .filter((cat) => !link.includes(cat.value))
                  .filter((cat) => !selectedLinkCategories.includes(cat.value))
                  .sort(filterNameWithAlphabeticRank)
            : [];
        return filteredCategories;
    };

    const handleOnchangeLinkedCategories = (selectedValue: any): void => {
        const updatedlinkedCatgories = [
            ...selectedLinkCategories,
            selectedValue?.value,
        ];
        setSelectedLinkCategories(updatedlinkedCatgories);
        dispatch({
            type: ACTIONS.LINK_CATEGORIES,
            payload: updatedlinkedCatgories,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.CATEGORYLIAISON));
    };

    const handleXButton = (e: React.MouseEvent<HTMLElement>, iuud: string) => {
        e.preventDefault();
        const updatedlinkedCatgories = selectedLinkCategories.filter(
            (catgoryIuud): boolean => catgoryIuud !== iuud
        );
        setSelectedLinkCategories(updatedlinkedCatgories);
        dispatch({
            type: ACTIONS.LINK_CATEGORIES,
            payload: updatedlinkedCatgories,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.CATEGORYLIAISON));
    };

    return (
        <div className="py-2" id="linked category">
            <StyledLabel htmlFor="categorie-liaison">
                {t("Link Categories")}
            </StyledLabel>
            <StyledSelectInput
                id="Step"
                name="Step"
                placeholder={t("Select")}
                noOptionsMessage={() => "Pas d'option"}
                options={filterParentCategories()}
                value={t("Choose")}
                onChange={handleOnchangeLinkedCategories}
                isDisabled={
                    updateItemWorkflow.pathUpdateItem.length > 0 ||
                    ETKType === "3" ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                }
            />
            <div
                className="d-flex flex-wrap "
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                {allParentsCategory.map(
                    (cat: any) =>
                        selectedLinkCategories.includes(cat.value) && (
                            <ValuesContainer key={cat.value}>
                                <Value>
                                    {cat.label}
                                    <XButton
                                        onClick={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) => handleXButton(e, cat.value)}
                                    >
                                        ✕
                                    </XButton>
                                </Value>
                            </ValuesContainer>
                        )
                )}
            </div>
        </div>
    );
}
