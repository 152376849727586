import React from "react";

import { Draggable } from "react-beautiful-dnd";
import { StyledLabel, PlusIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import "../Configuration.css";

export type optionAddType = {
    index: number;
    element: Setting;
    addOption: Array<Setting>;
    setAddOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
    currentOption: Array<Setting>;
    setCurrentOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
};
export default function OptionAdd({
    index,
    element,
    addOption,
    setCurrentOption,
    currentOption,
    setAddOption,
}: optionAddType) {
    const { t } = useTranslation();
    const handleAdd = (index: number, NameItem: any) => {
        const indexOfItem: number = currentOption.findIndex(
            (el: any) => el.name! === NameItem
        );

        if (indexOfItem === -1) {
            currentOption.push({
                name: addOption[index].name,
                id: addOption[index].id,
                rank: currentOption.length + 1,
                visibility: true,
            });
        }

        setCurrentOption(currentOption);
    };

    const handleDelete = (NameItem: string) => {
        const indexOfItem: number = addOption.findIndex(
            (item: any) => item.name! === NameItem
        );

        if (indexOfItem !== -1) {
            setAddOption((prevState: any) => {
                const newState = [...prevState];
                newState.splice(indexOfItem, 1);
                return newState;
            });
        }
    };
    const OrderByIndex = () => {
        setAddOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any, indice: number) => {
                element.rank = indice + 1;
                return element;
            });

            return newState;
        });
    };

    return (
        <Draggable draggableId={`${element.id.toString()} Add`} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="mt-2 ml-2 mr-2 option_btn__clz align-items-center"
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            className="mt-2 d-flex justify-content-between align-items-center "
                            id={element.id}
                        >
                            <StyledLabel className="ml-3 mt-1">
                                {t(element.name)}
                            </StyledLabel>

                            <div>
                                <span
                                    role="button"
                                    tabIndex={0}
                                    className="mr-2 "
                                    onClick={() => {
                                        handleAdd(index, element.name);
                                        handleDelete(element.name);
                                        OrderByIndex();
                                    }}
                                >
                                    <PlusIcon width={20} height={20} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
