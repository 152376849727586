import React from "react";

import { useTranslation } from "react-i18next";

type StepSelectionDropdownHeaderType = {
    showSteps: boolean;
    showCategories: boolean;
    showWorkflows: boolean;
    showOptions: boolean;
    setShowSteps: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCategories: React.Dispatch<React.SetStateAction<boolean>>;
    setShowWorkflows: React.Dispatch<React.SetStateAction<boolean>>;
    setShowOptions: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function StepSelectionDropdownHeader({
    showSteps,
    showCategories,
    showWorkflows,
    showOptions,
    setShowSteps,
    setShowCategories,
    setShowWorkflows,
    setShowOptions,
}: StepSelectionDropdownHeaderType) {
    const { t } = useTranslation();
    return (
        <div
            className="d-flex justify-content-around align-items-center"
            style={{ border: "1px solid #E8E8E8", borderRadius: "6px" }}
        >
            <span>
                <input
                    type="checkbox"
                    id="step"
                    checked={showSteps}
                    onChange={() =>
                        setShowSteps((previousState) => !previousState)
                    }
                />
                <label htmlFor="step" className="ml-2">
                    {t("Step")}
                </label>
            </span>
            <span style={{ display: "none" }}>
                <input
                    type="checkbox"
                    id="category"
                    checked={showCategories}
                    onChange={() =>
                        setShowCategories((previousState) => !previousState)
                    }
                />
                <label htmlFor="category" className="ml-2">
                    {t("Category")}
                </label>
            </span>
            <span>
                <input
                    type="checkbox"
                    id="workflows"
                    checked={showWorkflows}
                    onChange={() =>
                        setShowWorkflows((previousState) => !previousState)
                    }
                />
                <label htmlFor="workflows" className="ml-2">
                    {t("Workflows")}
                </label>
            </span>
            <span>
                <input
                    type="checkbox"
                    id="option"
                    checked={showOptions}
                    onChange={() =>
                        setShowOptions((previousState) => !previousState)
                    }
                />
                <label htmlFor="option" className="ml-2">
                    {t("Options")}
                </label>
            </span>
        </div>
    );
}
