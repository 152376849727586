import React from "react";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { Card, CardFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

import {
    ErrorToast,
    SuccessToast,
    RequirementToast,
    DynamicTableWrapperContext,
    DominantColor,
    CustomCardBody,
} from "@components/Common/";
import { setIsEditing } from "@store/features/editObserverSlice";
import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";

import { ACTIONS } from "@helpers/.";
import { RootState } from "@store/.";
import { setWorkflow } from "@store/features/workflowSlice";
import { resetUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { uploadGetUrlImage } from "@helpers/uploadGetUrlImage";
import { initialParentCategory } from "@pages/Catalogue/Common/ParentCategories";
import { initialStateItem, reducerItem } from "../reducer";
import AddItemHeader from "./AddItemHeader";
import FormBody from "./FormBody";
import FormFooter from "../../Common/FormFooter";

import "../Style.css";

type addItemPropsType = { setIsLoading: Function; initialDataItem?: any };

export default function AddItem({
    setIsLoading,
    initialDataItem,
}: addItemPropsType) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );
    const ETKType = localStorage.getItem("ETKType");

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const refs = React.useRef<HTMLDivElement[]>([]);
    const dominantColor: string = DominantColor();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const defaultVisibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const listMultiEditKeys = useSelector(
        (state: RootState): string[] => state.multipleEditSlice.listkey
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [currentOption, setCurrentOption] = React.useState<Array<Setting>>(
        []
    );

    const [defaultParentCategory, setDefaultParentCategory] = React.useState(
        initialParentCategory
    );
    const [selectedLinkCategories, setSelectedLinkCategories] = React.useState<
        string[]
    >([]);
    const [selectedLinkTags, setSelectedLinkTags] = React.useState<string[]>(
        []
    );

    const pathname = window.location.pathname.toString().split("/")[1];
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const [itemForm, dispatchItem] = React.useReducer(
        reducerItem,
        initialStateItem
    );

    const [stepIuud, setStepIuud] = React.useState<string>("");

    const allParentsCategory: categoryLiaisonType[] =
        initialDataItem?.othersData?.allParentsCategory;

    const addToRefs = (el: HTMLDivElement) => {
        if (el && !refs?.current?.includes(el)) {
            refs?.current?.push(el);
        }
    };

    const handleOnscroll = (): void => {
        if (refs) {
            refs?.current?.forEach((elm: any) => {
                if (itemForm.title === "") {
                    if (elm.id === "designation") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''Designation'' field must be filled in")
                        );
                    }
                    // } else if (itemForm.reference === "") {
                    //     if (elm.id === "reference") {
                    //         elm.scrollIntoView({
                    //             behavior: "smooth",
                    //             block: "nearest",
                    //             inline: "start",
                    //         });
                    //         elm.focus();
                    //         RequirementToast(
                    //             t("The field ''Reference'' must be filled")
                    //         );
                    //     }
                } else if (itemForm.categoryParent === "") {
                    if (elm.id === "category parent") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The field ''Parent category'' must be filled")
                        );
                    }
                }
            });
            setValidateOnclick(true);
        }
    };

    const updateItem = async (data: FormData): Promise<void> => {
        try {
            axios
                .put(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items/${dynamicTableWrapperContext?.rowData?.iuud}`,
                    data
                )
                .then((response) => {
                    if (response.data.error === true) {
                        ErrorToast(t("Reference already exists try another!"));
                    } else {
                        setIsLoading(true);
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("Item updated successfully"));
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    ErrorToast(t("There is a mistake!"));
                })
                .finally(() => {
                    setValidateOnclick(true);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };
    const insertNewItem = async (data: FormData): Promise<void> => {
        try {
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/item`,
                    data
                )
                .then((response): void => {
                    if (
                        response.data.message === "reference  already exists!"
                    ) {
                        ErrorToast(t("Reference already exists try another!"));
                    } else if (
                        response.data.message === "title  already exists!"
                    ) {
                        ErrorToast(
                            t("Designation already exists try another!")
                        );
                    } else {
                        setIsLoading(true);
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );

                        ETKType === "1"
                            ? SuccessToast(
                                  t(
                                      "A redirect button was created successfully"
                                  )
                              )
                            : SuccessToast(t("The item added successfully"));

                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    ErrorToast(t("There is a mistake!"));
                })
                .finally(() => {
                    setValidateOnclick(true);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };
    async function multiEditSubmit(): Promise<void> {
        setIsLoading(true);
        const data = new FormData();
        data.append("item", JSON.stringify(itemForm));
        data.append("DEFAULT_IMAGE", itemForm.imageUrl.Default.urlDefault);
        data.append("stepIuud", stepIuud);
        data.append("selectedLinkTags", JSON.stringify(selectedLinkTags));

        itemForm.imageUrl.Default.salesSupport?.forEach((image): void => {
            image.Langue.forEach((imageByLanguage): void => {
                data.append(
                    `${image.sellingSupport}|${imageByLanguage.designation}`,
                    imageByLanguage.value
                );
            });
        });

        data.append(
            "itemsIuuds",
            JSON.stringify(
                dynamicTableWrapperContext?.rowData?.data.map(
                    (item: any): string => item.iuud
                )
            )
        );
        data.append("keysToEdit", JSON.stringify(listMultiEditKeys));

        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items/`,
                data
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                setIsLoading(false);
                SuccessToast("Modification effectuée  avec succès");
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    }

    async function overrideWorkflowItem() {
        let ItemFormWorkflow = { ...itemForm };
        if (
            (ItemFormWorkflow.imageUrl.Default.urlDefault as any) instanceof
            Blob
        ) {
            const result: any = await uploadGetUrlImage(
                ItemFormWorkflow.imageUrl.Default.urlDefault,
                schemaAdbFranchise!,
                usedCard
            );

            const pathImage = result ? result.data.imagePath : "";
            ItemFormWorkflow = {
                ...ItemFormWorkflow,
                imageUrl: {
                    ...ItemFormWorkflow.imageUrl,
                    Default: {
                        ...ItemFormWorkflow.imageUrl.Default,
                        urlDefault: pathImage,
                    },
                },
            };
        }

        dispatch(
            setWorkflow(
                overrideWorkflowDataElementByUuid(
                    globalWorkflow,
                    ItemFormWorkflow,
                    updateItemWorkflow.uuid,
                    updateItemWorkflow.indexWorkflowGlobal
                )
            )
        );

        const { previousUuids } =
            globalWorkflow[updateItemWorkflow.indexWorkflowGlobal];

        dispatch(
            setWorkflowData(
                overrideWorkflowDataElementWithPath(
                    globalWorkflowData,
                    previousUuids,
                    ItemFormWorkflow,
                    updateItemWorkflow.uuid
                )
            )
        );
    }

    async function handleOneItemSubmit(): Promise<void> {
        if (updateItemWorkflow.pathUpdateItem.length > 0) {
            overrideWorkflowItem();

            dispatch(resetUpdateItemWorkflow());
            dynamicTableWrapperContext?.setActionColumnDisplay(false);
            SuccessToast(t("Item updated successfully"));
        } else if (validateOnclick) {
            setValidateOnclick(false);

            if (itemForm.categoryParent === "") {
                RequirementToast(
                    t("The field ''Parent category'' must be filled")
                );
                setValidateOnclick(true);
            } else {
                const data = new FormData();

                data.append("item", JSON.stringify(itemForm));
                data.append("stepIuud", stepIuud);
                data.append(
                    "selectedLinkTags",
                    JSON.stringify(selectedLinkTags)
                );
                data.append(
                    "DEFAULT_IMAGE",
                    itemForm.imageUrl.Default.urlDefault
                );

                itemForm.imageUrl.Default.salesSupport?.forEach(
                    (image): void => {
                        image.Langue.forEach((imageByLanguage): void => {
                            data.append(
                                `${image.sellingSupport}|${imageByLanguage.designation}`,
                                imageByLanguage.value
                            );
                        });
                    }
                );

                dynamicTableWrapperContext?.rowData?.iuud ||
                (dynamicTableWrapperContext?.rowData?.data[0].selectedRows !==
                    undefined &&
                    dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows === 1)
                    ? await updateItem(data)
                    : await insertNewItem(data);
                dynamicTableWrapperContext?.setDataUpdated(true);
            }
        }
    }

    const handleCancelButton = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault();
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        updateItemWorkflow.pathUpdateItem.length > 0 &&
            dispatch(resetUpdateItemWorkflow());
    };

    const findSelectedLinkCategories = () => {
        return dynamicTableWrapperContext?.rowData?.data[0]?.categoryLiaison.filter(
            (cat: any) => {
                return allParentsCategory.find((elm: any): boolean => {
                    return elm.value === cat;
                });
            }
        );
    };
    const orderElement = () => {
        currentOption.forEach((element: any) =>
            document
                .getElementById(element.id.toString())
                ?.style.setProperty("order", element.rank)
        );
    };
    const visibilityElement = () => {
        currentOption.forEach((element: any) =>
            document
                .getElementById(element.id.toString())
                ?.style.setProperty(
                    "display",
                    element.visibility ? "block" : "none"
                )
        );
    };
    React.useEffect(() => {
        orderElement();
        visibilityElement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption]);

    React.useEffect(() => {
        (async () => {
            const data: any = localStorage.getItem("configurationItem");
            if (data !== undefined && data !== null) {
                setCurrentOption(JSON.parse(data));
            } else {
                const response = await fetch(
                    "/fakeData/configurationItem.json"
                );
                const data = await response.json();
                localStorage.setItem("configurationItem", JSON.stringify(data));
                setCurrentOption(data);
            }
        })();

        if (dynamicTableWrapperContext?.rowData?.data) {
            if (dynamicTableWrapperContext.rowData.data.length === 1) {
                if (
                    dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows === undefined ||
                    (dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows !== undefined &&
                        dynamicTableWrapperContext?.rowData?.data[0]
                            .selectedRows === 1)
                ) {
                    dispatchItem({
                        type: ACTIONS.EDIT,
                        payload: dynamicTableWrapperContext!.rowData!.data[0],
                    });

                    dispatch(setIsEditing(true));
                }
                setSelectedLinkTags(
                    dynamicTableWrapperContext?.rowData?.data[0]?.linkedTags
                );
                setDefaultParentCategory(
                    allParentsCategory.filter(
                        (cat): boolean =>
                            cat.value ===
                            dynamicTableWrapperContext?.rowData?.data[0]
                                ?.categoryParent
                    )[0]
                );
                setSelectedLinkCategories(findSelectedLinkCategories());
            } else if (dynamicTableWrapperContext.rowData.data.length > 1) {
                dispatchItem({
                    type: ACTIONS.RESET,
                    payload: {},
                });
                dispatchItem({
                    type: ACTIONS.INIT_VISIBILITY,
                    payload: defaultVisibility,
                });
                setDefaultParentCategory(initialParentCategory);
                setSelectedLinkCategories([]);
            }
        } else {
            dispatchItem({
                type: ACTIONS.INIT_VISIBILITY,
                payload: defaultVisibility,
            });
            dispatchItem({
                type: ACTIONS.COLOR,
                payload: "#FFFFFF",
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dynamicTableWrapperContext,
        dynamicTableWrapperContext?.rowData?.data,
        allParentsCategory,
        dispatch,
        dominantColor,
        isMultiModify,
        pathname,
        defaultVisibility,
    ]);
    useUnsavedChangesWarning(itemForm);

    return (
        <Card style={{ border: "0px " }}>
            <AddItemHeader
                dispatchItem={dispatchItem}
                setDefaultParentCategory={setDefaultParentCategory}
                setselectedLinkCategories={setSelectedLinkCategories}
            />
            <AvForm
                onValidSubmit={
                    isMultiModify ? multiEditSubmit : handleOneItemSubmit
                }
                onInvalidSubmit={
                    isMultiModify ? multiEditSubmit : handleOnscroll
                }
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                    const targetEvent: any = e.target;
                    const idEvent = targetEvent.id;
                    if (
                        e.key === "Enter" &&
                        idEvent !== "Description" &&
                        !idEvent.includes("Advanced description")
                    ) {
                        e.preventDefault();
                    }
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <FormBody
                        itemForm={itemForm}
                        setStepIuud={setStepIuud}
                        // setStepItems={setStepItems}
                        dispatchItem={dispatchItem}
                        allParentsCategory={allParentsCategory}
                        defaultParentCategory={defaultParentCategory}
                        setDefaultParentCategory={setDefaultParentCategory}
                        selectedLinkCategories={selectedLinkCategories}
                        setSelectedLinkCategories={setSelectedLinkCategories}
                        addToRefs={addToRefs}
                        setSelectedLinkTags={setSelectedLinkTags}
                        initialDataItem={initialDataItem}
                    />
                </CustomCardBody>
                <CardFooter className="Footercard p-0">
                    <FormFooter
                        handleCancelButton={handleCancelButton}
                        itemForm={itemForm}
                        elementType="item"
                    />
                </CardFooter>
            </AvForm>
        </Card>
    );
}
