import React from "react";
import { PlusIcon } from "@aureskonnect/react-ui";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type AddPeriodType = {
    setPeriod: React.Dispatch<any>;
    period: any;
    lengthPeriod: number;
    setRender: React.Dispatch<React.SetStateAction<string>>;
};

export default function AddPeriod({
    setPeriod,
    period,
    lengthPeriod,
    setRender,
}: AddPeriodType) {
    const { t } = useTranslation();
    const addPeriode = () => {
        let ListItemsPeriodeAdd: any = {
            id: "",
            name: "",
            date: [{ DateDebut: "", DateFin: "" }],
            active: false,
            Day: [
                {
                    name: "Toute  le semaine",
                    id: "1",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Lundi",
                    id: "2",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Mardi",
                    id: "3",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Mercredi",
                    id: "4",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Jeudi",
                    id: "5",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Vendredi",
                    id: "6",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: "Samedi",
                    id: "7",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
                {
                    name: t("Dimanche"),
                    id: "8",
                    Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                    visibility: false,
                },
            ],
        };
        ListItemsPeriodeAdd.id = `${lengthPeriod + 1}`;
        ListItemsPeriodeAdd.name = "";
        ListItemsPeriodeAdd.date = [];
        ListItemsPeriodeAdd.active = false;
        period.push(ListItemsPeriodeAdd);
        ListItemsPeriodeAdd = [];
        setPeriod(period);
    };
    return (
        <div className="d-flex justify-content-center mt-auto align-items-center ">
            <div>
                <PlusIcon
                    height={15}
                    width={15}
                    onClick={() => {
                        addPeriode();
                        setRender(`${lengthPeriod} Add period`);
                    }}
                />
            </div>
            <div>
                <Button
                    variant="link"
                    className="w-auto"
                    style={{
                        color: "#323333",
                        textDecoration: "underline",
                    }}
                    onClick={() => {
                        addPeriode();
                        setRender(`${lengthPeriod} Add period`);
                    }}
                >
                    {t("to add")}
                </Button>
            </div>
        </div>
    );
}
