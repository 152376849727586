import React from "react";

import axios from "axios";
import shortid from "shortid";
// import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    setElementsTreeAsync,
    setStepSelectionDropdownElementsAsync,
    setWorkflow,
} from "@store/features/workflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { resetUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";

import { RootState } from "@store/.";
import { setDominantColor } from "@store/features/dominantColorSlice";
import { ErrorToast, LoadingDataAnimation } from "@components/Common";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { ItemStepExist } from "./helpers";

import WorkFlowFooter from "./WorkFlowFooter";
import WorkflowModal from "./WorflowStepModal/WorkflowModal";
import WorkFlowStepV2 from "./WorkFlowStepV2";
import UnsavedChangeModal from "./UnsavedChangeModal";

import "@components/Common/CardMenu/Cartestyle.css";

import "./style.css";

export type WorkFlowGlobalProps = {
    setTypeElement: React.Dispatch<React.SetStateAction<string>>;
    othersData: {
        itemOthersData: null;
        categoryOthersData: null;
        stepOthersData: null;
    };
    setInitialData: React.Dispatch<any>;
};

export default function WorkFlowGlobalV2({
    setTypeElement,
    othersData,
    setInitialData,
}: WorkFlowGlobalProps) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const ref = React.useRef<any>(null);
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const [resetGlobalWorkflow, setResetGlobalWorkflow] =
        React.useState<WorkFlowResetProps>({
            modifier: {},
            WorkflowData: {},
            GlobalWorkflow: {},
        });
    const [isLoading, setIsLoading] = React.useState(false);
    // const [isDragging, setIsDragging] = React.useState(false);
    const [isOpenStepModal, setIsOpenStepModal] = React.useState(false);
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        React.useState(false);
    const [indexSelected, setIndexSelected] = React.useState(0);
    const [isClickStep, setIsClickStep] = React.useState(false);

    const [contentModal, setContentModal] = React.useState<any>([]);
    const [workFlows, setWorkFlows] = React.useState<WorkFlowStepProps[]>([]);
    const [cardData, setCardData] = React.useState<any>();
    React.useState<number>(0);
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );

    dispatch(setDominantColor({ dominantColor: "#232323" }));

    const getCardData = React.useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/cards/${schemaAdbFranchise}/${usedCard.iuud}`
            )
            .then((response: any) => {
                if (response.data.data[0].data?.workflow) {
                    const workflow = Object.entries(
                        response.data.data[0].data?.workflow
                    ).reduce((prev: any, [key, Value]: any) => {
                        if (
                            response.data.data[0].data?.workflow &&
                            response.data.data[0].data?.workflow[key]
                        ) {
                            const workflowData = Value;
                            if (workflowData.rank === undefined) {
                                workflowData.rank = "";
                            }
                            return {
                                ...prev,
                                [key]: workflowData,
                            };
                        }
                        return { ...prev };
                    }, {});
                    response.data.data[0].data!.workflow = workflow;
                }
                setCardData(response.data.data[0].data);
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [schemaAdbFranchise, t, usedCard.iuud]);

    React.useEffect(() => {
        setIsLoading(true);
        getCardData();
        dispatch(setElementsTreeAsync());
        dispatch(setStepSelectionDropdownElementsAsync());

        return () => {
            dispatch(setModifier({}));
            dispatch(setWorkflowData({}));
            dispatch(resetUpdateItemWorkflow());
            dispatch(setWorkflow([]));
            dispatch(setMultipleWorkflow([]));
        };
    }, [getCardData, dispatch]);

    React.useEffect(() => {
        setWorkFlows(globalWorkflow);
    }, [globalWorkflow, dispatch]);

    React.useEffect(() => {
        setIsLoading(true);
        if (cardData) {
            setInitialData(cardData);

            dispatch(setWorkflowData(cardData?.workflow));
            if (cardData?.modifier) {
                dispatch(setModifier(cardData?.modifier));
            } else {
                dispatch(setModifier({}));
            }
            const firstWorkflow: any = [
                {
                    title: "Ma carte",
                    data: cardData?.workflow,
                    type: WORKFLOW_LIST.CATEGORIES,
                    previousUuids: [],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                    selected: "",
                    previousState: {},
                },
            ];
            dispatch(setWorkflow(firstWorkflow));
            setResetGlobalWorkflow({
                modifier: cardData?.modifier ? { ...cardData?.modifier } : {},
                WorkflowData: { ...cardData?.workflow },
                GlobalWorkflow: [...firstWorkflow],
            });
            dispatch(setMultipleWorkflow([]));
            setIsLoading(false);
        }
        //  eslint-disable-next-line
    }, [cardData, dispatch]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if (indexSelected !== -1) {
            setTimeout(() => {
                const divStepItem = document.getElementById(`${indexSelected}`);
                divStepItem && divStepItem?.scrollIntoView({ block: "start" });
            }, 5);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClickStep]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <div style={{ overflow: "hidden auto" }}>
            <div
                ref={ref}
                id="globalWorkflow"
                style={{
                    alignItems: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    overflowY: "auto",
                    background: "#F7F7FA 0% 0% no-repeat padding-box",
                    minHeight: `calc(100vh - 370px )`,
                    maxHeight: `calc(100vh - 370px )`,
                }}
            >
                {workFlows.map((workFlow, index) => {
                    const checkstepItem = ItemStepExist(workFlow);

                    let maxNbrClick =
                        cardData?.steps?.[globalWorkflow[index]?.uuidStep!]
                            ?.maxNbrOfChoices;

                    let isRequired =
                        cardData?.steps?.[globalWorkflow[index]?.uuidStep!]
                            ?.required;

                    const isMultipleStep =
                        globalWorkflow[index]?.multipleStep.isMultiple;
                    const workflowStep: any = JSON.parse(
                        JSON.stringify(workFlow)
                    );

                    if (
                        workflowStep.type === WORKFLOW_LIST.STEPS &&
                        Object.keys(workflowStep.override || {}).length > 0
                    ) {
                        maxNbrClick = workflowStep.override.maxNbrOfChoices;

                        isRequired = workflowStep.override.required;

                        workflowStep.title = workflowStep.override.title;

                        const previousData = JSON.parse(
                            JSON.stringify(workflowStep.data)
                        );

                        workflowStep.data = {};
                        Object.entries(
                            workflowStep.override.stepItems as Record<
                                string,
                                StepItemType
                            >
                        )
                            .sort((a: any, b: any) =>
                                // eslint-disable-next-line no-nested-ternary
                                a[1].rank
                                    ? b[1].rank
                                        ? a[1].rank - b[1].rank
                                        : -1
                                    : 1
                            )
                            .forEach(([iuud], index: number) => {
                                workflowStep.data[iuud] = {
                                    type: "items",
                                    content:
                                        previousData[iuud] !== undefined &&
                                        Object.keys(
                                            previousData[iuud].content || {}
                                        ).length > 0
                                            ? previousData[iuud].content
                                            : {},
                                    override: {},
                                    rank: index + 1,
                                };
                            });
                    }

                    return (
                        <WorkFlowStepV2
                            key={shortid.generate()}
                            title={workflowStep.title}
                            data={workflowStep.data}
                            type={workflowStep.type}
                            uuid={workflowStep.uuid}
                            globalIndex={index}
                            setTypeElement={setTypeElement}
                            setIsOpenStepModal={setIsOpenStepModal}
                            setContentModal={setContentModal}
                            cardData={cardData}
                            // isDragging={isDragging}
                            isClickStep={isClickStep}
                            setIsClickStep={setIsClickStep}
                            setIndexSelected={setIndexSelected}
                            isMultipleStep={isMultipleStep}
                            maxNbrClick={maxNbrClick}
                            isRequired={isRequired}
                            checkstepItem={checkstepItem}
                        />
                    );
                })}
            </div>
            <WorkFlowFooter
                setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
                resetGlobalWorkflow={resetGlobalWorkflow}
                setResetGlobalWorkflow={setResetGlobalWorkflow}
            />
            <WorkflowModal
                isOpenStepModal={isOpenStepModal}
                setIsOpenStepModal={setIsOpenStepModal}
                contentModal={contentModal}
                setContentModal={setContentModal}
                cardData={cardData}
                othersData={othersData}
            />
            <UnsavedChangeModal
                isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
                setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
                resetGlobalWorkflow={resetGlobalWorkflow}
            />
        </div>
    );
}
