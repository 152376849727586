import React from "react";

import Step from "./Step";
import StepItems from "./StepItems";

type StepTreePropsType = {
    step: StepWithChildrenType;
    selectedStepItems: StepItemWithIuud[];
    collapsedStepsUuids: string[];
    setSelectedStepItems: React.Dispatch<
        React.SetStateAction<StepItemWithIuud[]>
    >;
    setCollapsedStepsUuids: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function StepWithItems({
    step,
    collapsedStepsUuids,
    selectedStepItems,
    setCollapsedStepsUuids,
    setSelectedStepItems,
}: StepTreePropsType) {
    const isCurrentStepSelected =
        step.items.length === 0
            ? false
            : step.items.every((item) =>
                  selectedStepItems.some(
                      (selectedItem) => item.itemIuud === selectedItem.itemIuud
                  )
              );
    const addSelectedItem = (selectedItem: StepItemWithIuud) =>
        setSelectedStepItems((selectedStepItems) => [
            ...selectedStepItems,
            selectedItem,
        ]);
    const deleteSelectedItem = (selectedItem: StepItemWithIuud) =>
        setSelectedStepItems((selectedStepItems) => [
            ...selectedStepItems.filter(
                (selectedStepItem) =>
                    selectedStepItem.itemIuud !== selectedItem.itemIuud
            ),
        ]);

    return (
        <React.Fragment>
            <Step
                step={step}
                isCurrentStepSelected={isCurrentStepSelected}
                deleteSelectedItem={deleteSelectedItem}
                addSelectedItem={addSelectedItem}
                setCollapsedStepsUuids={setCollapsedStepsUuids}
            />

            <StepItems
                step={step}
                selectedStepItems={selectedStepItems}
                collapsedStepsUuids={collapsedStepsUuids}
                addSelectedItem={addSelectedItem}
                deleteSelectedItem={deleteSelectedItem}
            />
        </React.Fragment>
    );
}
