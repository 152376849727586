import React from "react";

import { Card } from "reactstrap";

import ThreadTable from "./ThreadTable";
import Importreport from "../Report/Importreport";
import FirstTable from "./FirstTable";
import SecondTable from "./SecondTable ";
import "./style.css";

type Tabletype = {
    file: any;
    etap: number;
    setListTitle: Function;
    titleList: any;
    createdNumber: number;
    selectednumber: number;
    Updatenumber: number;
    setFile: React.Dispatch<any>;
    filteredRow: any;
};
function Table({
    setListTitle,
    file,
    etap,
    titleList,
    Updatenumber,
    createdNumber,
    selectednumber,
    setFile,
    filteredRow,
}: Tabletype) {
    return (
        <div className="mt-2">
            {!file ? (
                <FirstTable />
            ) : (
                (() => {
                    switch (etap) {
                        case 2:
                            return (
                                <Card>
                                    <ThreadTable
                                        file={file}
                                        setListTitle={setListTitle}
                                        ListTitle={titleList}
                                    />
                                </Card>
                            );
                        case 3:
                            return (
                                <Card>
                                    <Importreport
                                        file={file}
                                        selectednumber={selectednumber}
                                        Updatenumber={Updatenumber}
                                        creatednumber={createdNumber}
                                    />
                                </Card>
                            );
                        default:
                            return (
                                <Card>
                                    <SecondTable
                                        file={file}
                                        setFile={setFile}
                                        rowFilter={filteredRow}
                                    />
                                </Card>
                            );
                    }
                })()
            )}
        </div>
    );
}

export default Table;
