/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { Collapse, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import {
    StyledIconButton,
    PlusIcon,
    EyeIcon,
    LockIcon,
    TrashIcon,
    EyeCrossedIcon,
} from "@aureskonnect/react-ui";
import styled from "styled-components";

import { RootState } from "@store/index";
import { ArrowUpIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowUpIcons";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { Option } from "./Option";
import { HeaderComposition } from "./HeaderComposition";

import "./styles.css";

type BasicCompositionProps = {
    isRedirect: boolean;
    listOfItems?: any[];
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    initialValue: any;
    isWithQuantity: boolean;
};

const Quantity = styled.input`
   border:none ;
   border-radius: 2px;
   width: 48px;
   height: 28px;
   font-size: 18px;
   text-align: center;
   margin-right: 10px

   //hide the default button
   &::-webkit-appearance: textfield;
   &::-moz-appearance: textfield;
   appearance: textfield;

   &::-webkit-inner-spin-button, 
   &::-webkit-outer-spin-button 
   &::-webkit-appearance: none;

`;

export default function BasicComposition({
    listOfItems,
    dispatch,
    initialValue,
    isWithQuantity,
    isRedirect,
}: BasicCompositionProps) {
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
    const [isChecked, setisChecked] = React.useState(isWithQuantity);
    const [selectedInputValues, setSelectedInputValues] = React.useState<any>(
        []
    );

    const [filteredOptions, setFilteredOptions] = React.useState<any>([]);

    const [selectedComposition, setSelectedComposition] = React.useState<any>(
        Object.keys(initialValue).length > 0 ? initialValue : []
    );
    const handleObligation = (index: number) => {
        const newState = [...selectedComposition];
        newState[index].value.isObligatory =
            !newState[index].value.isObligatory;
        setSelectedComposition(newState);
        dispatch({
            type: ACTIONS.BASIC_COMPOSITION,
            payload: itemBasicComposition(newState),
        });
    };
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleAddComposition();
        }
    };
    const { t } = useTranslation();
    const handleVisibility = (index: number) => {
        const newState = [...selectedComposition];
        newState[index].value.isVisible = !newState[index].value.isVisible;
        setSelectedComposition(newState);
        dispatch({
            type: ACTIONS.BASIC_COMPOSITION,
            payload: itemBasicComposition(newState),
        });
    };

    const handleChange = React.useCallback((selected: any) => {
        setSelectedInputValues(selected);
    }, []);

    const handleAddComposition = () => {
        const newState =
            Object.keys(initialValue).length > 0
                ? [...[...selectedComposition], ...[...selectedInputValues]]
                : [...selectedInputValues];

        setSelectedInputValues([]);
        dispatch({
            type: ACTIONS.BASIC_COMPOSITION,
            payload: itemBasicComposition(newState),
        });
        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.BASIC_COMPOSITION));
        setIsCollapsed(true);
    };

    const handleIncrement = (index: number) => {
        const newState = [...selectedComposition];
        newState[index].value.quantity += 1;
        setSelectedComposition(newState);
        dispatch({
            type: ACTIONS.BASIC_COMPOSITION,
            payload: itemBasicComposition(newState),
        });
    };

    const handleDecrement = (index: number) => {
        if (selectedComposition[index].value.quantity > 1) {
            const newState = [...selectedComposition];
            newState[index].value.quantity -= 1;
            setSelectedComposition(newState);
            dispatch({
                type: ACTIONS.BASIC_COMPOSITION,
                payload: itemBasicComposition(newState),
            });
        }
    };

    const handleDelete = (index: number) => {
        const newState = [...selectedComposition];
        newState.splice(index, 1);
        setSelectedComposition(newState);
        dispatch({
            type: ACTIONS.BASIC_COMPOSITION,
            payload: itemBasicComposition(newState),
        });
    };

    const itemBasicComposition = (selectedCompo: any) =>
        selectedCompo?.reduce((previousValue: any, currentValue: any) => {
            return {
                ...previousValue,
                [currentValue.value.iuud]: {
                    quantity: currentValue.value.quantity,
                    isVisible: currentValue.value.isVisible,
                    isObligatory: currentValue.value.isObligatory,
                },
            };
        }, {});

    const filteredComposition = () => {
        let myArrayFiltered = listOfItems;
        if (Object.keys(initialValue).length > 0) {
            myArrayFiltered = listOfItems!.filter((elm) => {
                return initialValue.every(
                    (myfilt: any) => myfilt.value.iuud !== elm.value.iuud
                );
            });
        }
        setFilteredOptions(myArrayFiltered);
    };

    React.useEffect(() => {
        if (Object.keys(initialValue).length > 0) {
            setSelectedComposition(initialValue);
        }
        setIsCollapsed(Object.keys(initialValue).length > 0);
        setisChecked(isWithQuantity);
        filteredComposition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, listOfItems]);

    return (
        <div id="basicComposition">
            <HeaderComposition
                isRedirect={isRedirect}
                setIsChecked={setisChecked}
                isChecked={isChecked}
                dispatchItem={dispatch}
            />
            <div
                className="d-inline-block  align-items-betwwen d-flex align-items-center "
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
                style={
                    isRedirect ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                        ? { pointerEvents: "none", opacity: "0.4" }
                        : { opacity: "1" }
                }
            >
                <ReactSelect
                    className="borderless_input__CLZ"
                    options={filteredOptions.sort((x: any, y: any) => {
                        if (x.label > y.label) {
                            return 1;
                        }
                        if (x.label < y.label) {
                            return -1;
                        }
                        return 0;
                    })}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions
                    components={{
                        Option,
                    }}
                    onChange={handleChange}
                    value={selectedInputValues}
                    onKeyDown={handleKeyPress}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                />
                <div
                    className="ml-2"
                    role="button"
                    tabIndex={0}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                        handleAddComposition();
                    }}
                >
                    <StyledIconButton
                        icon="PlusIcon"
                        style={{
                            border: "1px solid #C6C6C6",
                            borderRadius: "6px",
                        }}
                    >
                        <PlusIcon height={15} width={15} />
                    </StyledIconButton>
                </div>
            </div>
            <Collapse isOpen={isCollapsed} className="mt-2">
                <Card>
                    <CardHeader
                        className="py-0 CompositionDeBae_clz "
                        lang="fr"
                        style={{ minHeight: "32px" }}
                    >
                        <Row style={{ minHeight: "32px" }}>
                            {isChecked && (
                                <Col xs="4" className="border-right">
                                    Quantité
                                </Col>
                            )}
                            <Col
                                xs={isChecked ? "4" : "8"}
                                className="border-right "
                            >
                                Composant
                            </Col>
                            <Col xs="4">Actions</Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="py-0">
                        {selectedComposition
                            .sort((a: any, b: any) => {
                                return a?.value?.rank - b?.value?.rank;
                            })
                            ?.map(
                                // eslint-disable-next-line array-callback-return
                                (compo: any, index: number) => {
                                    if (compo.label !== "") {
                                        return (
                                            <Row
                                                className="border-bottom "
                                                key={compo.value.iuud}
                                            >
                                                {isChecked && (
                                                    <Col
                                                        xs="4"
                                                        className=" justify-content-between px-0 border-right  container_quantity"
                                                    >
                                                        <div
                                                            className="d-flex justify-content-center align-items-center border-right "
                                                            style={{
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <LockIconWithToolTip
                                                                type="icon"
                                                                lengthMax={0}
                                                                message={
                                                                    compo.value
                                                                        .isVisible
                                                                        ? t(
                                                                              "Visible"
                                                                          )
                                                                        : t(
                                                                              "Invisible"
                                                                          )
                                                                }
                                                                id={`iviseble${index}`}
                                                            />
                                                            {!compo.value
                                                                .isVisible ? (
                                                                <div
                                                                    id={`iviseble${index}`}
                                                                >
                                                                    <EyeCrossedIcon
                                                                        height={
                                                                            20
                                                                        }
                                                                        width={
                                                                            20
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        fill="#EB0000"
                                                                        onClick={() => {
                                                                            handleVisibility(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    id={`iviseble${index}`}
                                                                >
                                                                    <EyeIcon
                                                                        height={
                                                                            20
                                                                        }
                                                                        width={
                                                                            20
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            handleVisibility(
                                                                                index
                                                                            );
                                                                        }}
                                                                        fill="#707070"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Quantity
                                                            value={
                                                                compo.value
                                                                    .quantity
                                                            }
                                                        />
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-center  border-left "
                                                            style={{
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <div
                                                                onClick={() =>
                                                                    handleIncrement(
                                                                        index
                                                                    )
                                                                }
                                                                role="button"
                                                                tabIndex={0}
                                                            >
                                                                <ArrowUpIcon color="black" />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleDecrement(
                                                                        index
                                                                    )
                                                                }
                                                                role="button"
                                                                tabIndex={0}
                                                            >
                                                                <ArrowDownIcon color="black" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col
                                                    xs={isChecked ? "4" : "8"}
                                                    className="d-flex  align-items-center border-right "
                                                >
                                                    <span
                                                        className="my-2 CompositionDeBae_clz"
                                                        lang="fr"
                                                    >
                                                        {compo.value.title}
                                                    </span>
                                                </Col>
                                                <Col
                                                    xs="4"
                                                    className="d-flex align-items-center justify-content-center "
                                                    style={{
                                                        flexWrap: "wrap",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <div id={`icon${index}`}>
                                                        <LockIconWithToolTip
                                                            type="icon"
                                                            lengthMax={0}
                                                            message={
                                                                !compo.value
                                                                    .isObligatory
                                                                    ? t(
                                                                          "Component not required"
                                                                      )
                                                                    : t(
                                                                          "Obligatory component"
                                                                      )
                                                            }
                                                            id={`icon${index}`}
                                                        />
                                                        <LockIcon
                                                            height={25}
                                                            width={25}
                                                            fill={
                                                                !compo.value
                                                                    .isObligatory
                                                                    ? "#707070"
                                                                    : "#EB0000"
                                                            }
                                                            onClick={() =>
                                                                handleObligation(
                                                                    index
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                    <div id={`lock${index}`}>
                                                        <LockIconWithToolTip
                                                            message={t(
                                                                "Remove component"
                                                            )}
                                                            type="icon"
                                                            lengthMax={0}
                                                            id={`lock${index}`}
                                                        />
                                                        <div>
                                                            <TrashIcon
                                                                height={25}
                                                                width={25}
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        index
                                                                    )
                                                                }
                                                                fill="#EB0000"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    }
                                }
                            )}
                    </CardBody>
                </Card>
            </Collapse>
        </div>
    );
}
