import { generateItemPriceUuid } from "./general";

export function updateAdvancedPrice(
    saleModeVatRates: { saleModeUuid: string; value: number }[],

    salesModes: {
        id: string;
        designation: string;
        saleSupports: string[];
    }[],

    updatedAdvancedPrice: Record<string, Price>
) {
    saleModeVatRates.forEach((saleModeVatRate) => {
        salesModes.forEach((saleMode) => {
            if (saleModeVatRate.saleModeUuid === saleMode.id) {
                saleMode.saleSupports.forEach((saleSupport) => {
                    const advancedPriceUuid = generateItemPriceUuid([
                        saleModeVatRate.saleModeUuid,
                        saleSupport,
                    ]);
                    const advancedPriceElement =
                        updatedAdvancedPrice[advancedPriceUuid];
                    if (advancedPriceElement !== undefined) {
                        updatedAdvancedPrice = {
                            ...updatedAdvancedPrice,
                            [advancedPriceUuid]: {
                                ...updatedAdvancedPrice[advancedPriceUuid],
                                tva: saleModeVatRate.value,
                                pricettc: Number(
                                    advancedPriceElement?.pricettc ?? 0
                                ),
                                priceHT:
                                    Number(
                                        advancedPriceElement?.pricettc ?? 0
                                    ) /
                                    (1 + Number(saleModeVatRate.value) / 100),
                            },
                        };
                    }
                });
            }
        });
    });
    return updatedAdvancedPrice;
}

export function updateAdvancedPriceWithOption(
    saleModeVatRates: { saleModeUuid: string; value: number }[],
    itemOptions: itemOptions,
    salesModes: {
        id: string;
        designation: string;
        saleSupports: string[];
    }[],

    updatedAdvancedPrice: Record<string, Price>
) {
    function fillAdvancedPriceOptions(
        itemOptionKeys: string[],
        saleModeVatRate: {
            saleModeUuid: string;
            value: number;
        },
        key: string = ""
    ) {
        if (itemOptionKeys.length > 0) {
            const itemOptionKeysCopy: string[] = JSON.parse(
                JSON.stringify(itemOptionKeys)
            );
            const itemOption = itemOptionKeysCopy.shift();
            if (itemOption !== undefined) {
                itemOptions[itemOption].forEach((itemOptionValue) => {
                    if (itemOptionKeysCopy.length > 0) {
                        fillAdvancedPriceOptions(
                            itemOptionKeysCopy,
                            saleModeVatRate,
                            `${key}|${itemOption}|${itemOptionValue}`
                        );
                    }
                    const updatedKey = `${key}|${itemOption}|${itemOptionValue}`;
                    const advancedPriceUuid = generateItemPriceUuid(
                        updatedKey.split("|")
                    );
                    const advancedPriceElement =
                        updatedAdvancedPrice[advancedPriceUuid];
                    if (advancedPriceElement !== undefined) {
                        updatedAdvancedPrice = {
                            ...updatedAdvancedPrice,
                            [advancedPriceUuid]: {
                                ...updatedAdvancedPrice[advancedPriceUuid],
                                tva: saleModeVatRate.value,
                                pricettc: Number(
                                    advancedPriceElement?.pricettc ?? 0
                                ),
                                priceHT:
                                    Number(
                                        advancedPriceElement?.pricettc ?? 0
                                    ) /
                                    (1 + Number(saleModeVatRate.value) / 100),
                            },
                        };
                    }
                });
            }
        }
        return updatedAdvancedPrice;
    }
    saleModeVatRates.forEach((saleModeVatRate) => {
        salesModes.forEach((saleMode) => {
            if (saleModeVatRate.saleModeUuid === saleMode.id) {
                saleMode.saleSupports.forEach((saleSupport) => {
                    fillAdvancedPriceOptions(
                        Object.keys(itemOptions),
                        saleModeVatRate,
                        `${saleModeVatRate.saleModeUuid}|${saleSupport}`
                    );
                });
            }
        });
    });
    return updatedAdvancedPrice;
}

export function fillAdvancedPriceOptions(
    itemOptionKeys: string[],
    allOptions: { Option: OptionType; iuud: string }[],
    itemOptions: itemOptions,
    tva: number,
    filteredOptions: string[],
    key: string = ""
) {
    if (itemOptionKeys.length > 0) {
        const itemOptionKeysCopy: string[] = JSON.parse(
            JSON.stringify(itemOptionKeys)
        );
        const itemOption = itemOptionKeysCopy.shift();
        if (itemOption !== undefined) {
            const itemOptionData = allOptions.find(
                (option) => option.iuud === itemOption
            );

            const filteredItemOptions = filterOptions(
                filteredOptions,
                itemOptions,
                itemOption
            );

            return filteredItemOptions.map((itemOptionValue): any => {
                if (itemOptionData !== undefined) {
                    const optionValueDesignation =
                        itemOptionData.Option.values?.[itemOptionValue]?.title;
                    if (itemOptionKeysCopy.length > 0) {
                        return {
                            designation: optionValueDesignation,
                            key: `${key}|${itemOptionData?.iuud}|${itemOptionValue}`,
                            type: "option",
                            PrixHT: 0,
                            TVA: "",
                            PrixTTC: 0,
                            period: 0,
                            children: fillAdvancedPriceOptions(
                                itemOptionKeysCopy,
                                allOptions,
                                itemOptions,
                                tva,
                                filteredOptions,
                                `${key}|${itemOptionData?.iuud}|${itemOptionValue}`
                            ),
                        };
                    }

                    return {
                        designation: optionValueDesignation,
                        key: `${key}|${itemOptionData?.iuud}|${itemOptionValue}`,
                        type: "option",
                        PrixHT: 0,
                        TVA: tva,
                        PrixTTC: 0,
                        period: 0,
                    };
                }
                return {};
            });
        }
    }
    return [];
}

function filterOptions(
    filteredOptions: string[],
    itemOptions: itemOptions,
    itemOption: string
) {
    if (filteredOptions.length === 0) {
        return itemOptions[itemOption];
    }
    const isCurrentOptionContainsFilteredValues = itemOptions[itemOption].some(
        (itemOptionValue) => filteredOptions.includes(itemOptionValue)
    );
    if (isCurrentOptionContainsFilteredValues) {
        return itemOptions[itemOption].filter((itemOptionValue) =>
            filteredOptions.includes(itemOptionValue)
        );
    }
    return itemOptions[itemOption];
}

export function multipleUpdateAdvanced(
    selectedOption: string,
    amount: "" | number,
    saleModeVatRates: SaleModeVatRateType[],
    percentage: "" | number,
    rowKeysToUpdate: string[],
    updatedAdvancedPrice: Record<string, Price>
) {
    const addedAmount =
        selectedOption === "decrease" ? -Number(amount) : Number(amount);
    const addedPercentage =
        selectedOption === "decrease"
            ? -Number(percentage)
            : Number(percentage);
    rowKeysToUpdate.forEach((key) => {
        const keys = key.split("|");
        const advancedPriceUuid = generateItemPriceUuid(keys);
        const advancedPriceElement = updatedAdvancedPrice[advancedPriceUuid];
        const oldTTC = advancedPriceElement?.pricettc ?? 0;
        const saleModeVatRate = saleModeVatRates.find(
            (saleModeVatRate) => saleModeVatRate.saleModeUuid === keys[0]
        );
        let newTTC = 0;
        if (selectedOption === "newPrice") {
            newTTC = Number(amount);
        } else if (amount !== "") {
            newTTC = oldTTC + addedAmount;
        } else {
            newTTC = oldTTC + (oldTTC * addedPercentage) / 100;
        }
        if (newTTC < 0) {
            newTTC = 0;
        }
        if (updatedAdvancedPrice[advancedPriceUuid] === undefined) {
            updatedAdvancedPrice = {
                ...updatedAdvancedPrice,
                [advancedPriceUuid]: {
                    pricettc: newTTC,
                    tva: Number(
                        advancedPriceElement?.tva ?? saleModeVatRate?.value ?? 0
                    ),
                    priceHT:
                        newTTC /
                        (1 +
                            Number(
                                advancedPriceElement?.tva ??
                                    saleModeVatRate?.value ??
                                    0
                            ) /
                                100),
                    methodePrice: "",
                    serviceAvailability: {},
                    scheduledPrice: [],
                    originalKeyElements: keys,
                },
            };
        } else {
            updatedAdvancedPrice = {
                ...updatedAdvancedPrice,
                [advancedPriceUuid]: {
                    ...updatedAdvancedPrice[advancedPriceUuid],

                    pricettc: newTTC,
                    tva: Number(
                        advancedPriceElement?.tva ?? saleModeVatRate?.value ?? 0
                    ),
                    priceHT:
                        newTTC /
                        (1 +
                            Number(
                                advancedPriceElement?.tva ??
                                    saleModeVatRate?.value ??
                                    0
                            ) /
                                100),
                    originalKeyElements: keys,
                },
            };
        }
    });
    return updatedAdvancedPrice;
}

export function getGroupedOptions(
    itemOptions: Record<string, string[]>,
    allOptions: { Option: OptionType; iuud: string }[]
) {
    return Object.entries(itemOptions).map(([optionUuid, optionValues]) => {
        const currentOption = allOptions.find(
            (option) => option.iuud === optionUuid
        );
        return {
            label: currentOption?.Option.title ?? "",
            value: optionUuid,
            options:
                currentOption === undefined
                    ? []
                    : optionValues.map((optionValueUuid: string) => {
                          return {
                              label: currentOption.Option.values![
                                  optionValueUuid
                              ].title,
                              value: optionValueUuid,
                          };
                      }),
        };
    });
}

export function filterKeysToUpdate(
    selectedRows: any,
    filteredSaleModes: string[],
    filteredSaleSupports: string[],
    filteredOptions: string[]
) {
    let rowKeysToUpdate: string[] = selectedRows
        .filter((row: any) => row.children === undefined)
        .map((row: any) => row.key);

    if (filteredSaleModes.length > 0) {
        rowKeysToUpdate = rowKeysToUpdate.filter((rowKey: string) =>
            filteredSaleModes.some((saleMode) =>
                rowKey.split("|").includes(saleMode)
            )
        );
    }
    if (filteredSaleSupports.length > 0) {
        rowKeysToUpdate = rowKeysToUpdate.filter((rowKey: string) =>
            filteredSaleSupports.some((saleSupport) =>
                rowKey.split("|").includes(saleSupport)
            )
        );
    }
    if (filteredOptions.length > 0) {
        rowKeysToUpdate = rowKeysToUpdate.filter((rowKey: string) =>
            filteredOptions.some((option) => rowKey.split("|").includes(option))
        );
    }
    return rowKeysToUpdate;
}
