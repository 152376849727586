import { WORKFLOW_LIST } from "@constants/WorkFlow";

export function formElementBasicComposition(
    UuidBasicComposition: string,
    UuidItem: string,
    cardData: any
) {
    const basicCompositionForm: basicComposition = {
        quantity: 1,
        title: "",
        isVisible: true,
        isObligatory: false,
    };
    if (cardData?.items?.[UuidItem]?.active_quantity) {
        basicCompositionForm.quantity =
            cardData?.items?.[UuidItem]?.basicComposition[
                UuidBasicComposition
            ].quantity;
        basicCompositionForm.isVisible =
            cardData?.items?.[UuidItem]?.basicComposition[
                UuidBasicComposition
            ].isVisible;
    }
    basicCompositionForm.isObligatory =
        cardData?.items?.[UuidItem]?.basicComposition[
            UuidBasicComposition
        ].isObligatory;

    return basicCompositionForm;
}
export function basicCompositionFormModalWorkflow(
    modifierWorkflow: any,
    contentModal: any,
    globalIndex: number,
    iuud: string,
    cardData: any,
    list: any
) {
    const optionForm: any = {};
    if (
        Object.keys(
            modifierWorkflow[
                contentModal[contentModal.length - 1]?.previousModifierStep
            ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                ?.overrides || {}
        ).length <= 0 ||
        Object.keys(
            modifierWorkflow[
                contentModal[contentModal.length - 1]?.previousModifierStep
            ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                ?.overrides?.stepItems[
                contentModal[contentModal.length - 1]?.triggerItemIuud
            ]?.overrideBasicComposition || {}
        ).length <= 0
    ) {
        optionForm.image =
            cardData?.[list.type]?.[iuud].imageUrl?.Default?.urlDefault;
        optionForm.isVisible = true;
        optionForm.isOptionModifier = "";

        return optionForm;
    }
    optionForm.image =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideBasicComposition[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.image !== ""
            ? modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                  ?.overrides?.stepItems[
                  contentModal[contentModal.length - 1]?.triggerItemIuud
              ]?.overrideBasicComposition[
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuidStep
              ]?.content[iuud]?.image
            : cardData?.[list.type]?.[iuud].imageUrl?.Default?.urlDefault;

    optionForm.isOptionModifier =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideBasicComposition[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.modifier !== ""
            ? modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                  ?.overrides?.stepItems[
                  contentModal[contentModal.length - 1]?.triggerItemIuud
              ]?.overrideBasicComposition[
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuidStep
              ]?.content[iuud]?.modifier
            : "";

    optionForm.isVisible =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideBasicComposition[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.isVisible ?? true;

    return optionForm;
}
export function basicCompositionFormWorkflow(
    globalWorkflow: WorkFlowStepProps[],
    startIndex: number,
    uuidPrincipalItem: string,
    globalIndex: number,
    iuud: string,
    cardData: any,
    list: any
) {
    const optionForm: any = {};
    if (
        Object.keys(
            globalWorkflow[startIndex]?.data[uuidPrincipalItem]?.override || {}
        ).length <= 0 ||
        Object.keys(
            globalWorkflow[startIndex].data[uuidPrincipalItem]?.override[
                uuidPrincipalItem
            ]?.overrideBasicComposition || {}
        ).length <= 0
    ) {
        optionForm.image =
            cardData?.[list.type]?.[iuud]?.imageUrl?.Default?.urlDefault;
        optionForm.isVisible = true;
        optionForm.isOptionModifier = "";
        return optionForm;
    }

    optionForm.image = globalWorkflow[startIndex].data[uuidPrincipalItem]
        ?.override[uuidPrincipalItem]?.overrideBasicComposition[
        globalWorkflow[globalIndex!]?.uuidStep
    ]?.content[iuud]?.image
        ? globalWorkflow[startIndex].data[uuidPrincipalItem]?.override[
              uuidPrincipalItem
          ]?.overrideBasicComposition[globalWorkflow[globalIndex!]?.uuidStep]
              ?.content[iuud]?.image
        : cardData?.[list.type]?.[iuud]?.imageUrl?.Default?.urlDefault;

    optionForm.isOptionModifier = globalWorkflow[startIndex].data[
        uuidPrincipalItem
    ]?.override[uuidPrincipalItem]?.overrideBasicComposition[
        globalWorkflow[globalIndex!]?.uuidStep
    ]?.content[iuud]?.modifier
        ? globalWorkflow[startIndex].data[uuidPrincipalItem]?.override[
              uuidPrincipalItem
          ]?.overrideBasicComposition[globalWorkflow[globalIndex!]?.uuidStep]
              ?.content[iuud]?.modifier
        : "";

    optionForm.isVisible =
        globalWorkflow[startIndex].data[uuidPrincipalItem]?.override[
            uuidPrincipalItem
        ]?.overrideBasicComposition[globalWorkflow[globalIndex!]?.uuidStep]
            ?.content[iuud]?.isVisible ?? true;

    return optionForm;
}
export function InsertOverrideBasicCompositionInItem(
    copyOverrideOption: WorkflowOptionType,
    cardData: any,
    uuidItem: string
) {
    let listValue: WorkflowContentOptionType = {};

    Object.entries(cardData?.items?.[uuidItem]?.basicComposition).forEach(
        ([iuudItem, element]: any) => {
            listValue = {
                ...listValue,
                [iuudItem]: {
                    image: "",
                    isVisible: element.isVisible,
                    modifier: "",
                },
            };
        }
    );
    copyOverrideOption = {
        ...copyOverrideOption,
        [WORKFLOW_LIST.BASICCOMPOSITION]: {
            content: listValue,
            isVisible: true,
            ranks: Object.keys(listValue),
        },
    };

    return copyOverrideOption;
}
export function GetlistUuidItemsInStepBasicComposition(
    copymodifierWorkflow: any,
    previousModifier: string,
    UuidStep: string,
    uuid: string,
    cardData: any,
    typeSteps: string
) {
    if (
        // eslint-disable-next-line no-constant-condition, no-cond-assign
        Object.keys(
            copymodifierWorkflow[previousModifier][typeSteps][UuidStep]
                .overrides || {}
        ).length === 0 ||
        Object.keys(
            copymodifierWorkflow[previousModifier][typeSteps][UuidStep]
                .overrides.stepItems[uuid].overrideBasicComposition || {}
        ).length === 0
    ) {
        return Object.keys(cardData?.items?.[uuid]?.basicComposition);
    }

    return copymodifierWorkflow[previousModifier].steps[UuidStep].overrides
        .stepItems[uuid].overrideBasicComposition[
        WORKFLOW_LIST.BASICCOMPOSITION
    ].ranks;
}
export function GetlistUuidBasicComposition(
    globalWorkflow: any,
    startIndex: number,
    globalUuid: string,
    cardData: any
) {
    if (
        Object.keys(globalWorkflow[startIndex].data[globalUuid].override || {})
            .length === 0 ||
        Object.keys(
            globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                ?.overrideBasicComposition || {}
        ).length === 0
    ) {
        return Object.keys(cardData?.items?.[globalUuid]?.basicComposition);
    }
    return globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
        ?.overrideBasicComposition[WORKFLOW_LIST.BASICCOMPOSITION].ranks;
}
export function GlobalVisibilityBasicComposition(
    globalWorkflow: any,
    startIndex: number,
    globalIndex: number,
    cardData: any
) {
    if (
        Object.keys(
            globalWorkflow[startIndex]?.data[globalWorkflow[globalIndex!]?.uuid]
                ?.override || {}
        ).length > 0
    ) {
        return globalWorkflow[startIndex]?.data[
            globalWorkflow[globalIndex!]?.uuid
        ].override[globalWorkflow[globalIndex]?.uuid]?.visibilityInfo
            .basicCompositionVisibility;
    }
    return cardData?.items[globalWorkflow[globalIndex]?.uuid]?.visibilityInfo
        .basicCompositionVisibility;
}
export function GlobalVisibilityBasicCompositionInModal(
    modifierWorkflow: any,
    previousModifierStep: string,
    previousUuidStep: string,
    triggerItemIuud: string
) {
    if (
        Object.keys(
            modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
                ?.overrides || {}
        ).length > 0 &&
        modifierWorkflow[previousModifierStep]?.steps[
            previousUuidStep
            // eslint-disable-next-line no-prototype-builtins
        ]?.overrides.stepItems[triggerItemIuud].hasOwnProperty(
            "basicCompositionVisibility"
        )
    ) {
        return modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
            ?.overrides.stepItems[triggerItemIuud].basicCompositionVisibility;
    }
    return true;
}
