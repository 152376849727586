import React from "react";

import { Container } from "reactstrap";
import { UpdateFile } from "@helpers/ImportFile";
import { useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { RootState } from "@store/.";
import Filtre from "./Filtre";
import SelectFile from "./SelectFile";
import NavBar from "./NavBar/NavBar";
import Table from "./Table";

import "./styles.css";

export default function File() {
    const { t } = useTranslation();
    const [file, setFile] = React.useState<any>(null);
    const [filteredRow, setFilteredRow] = React.useState<any>([]);
    const [titleList, setTitleList] = React.useState<any>("");
    const [createdNumber, setCreatedNumber] = React.useState<number>(0);
    const [selectedNumber, SetSelectedNumber] = React.useState<number>(0);
    const [updatedNumber, SetUpdatedNumber] = React.useState<number>(0);
    const [step, setStep] = React.useState<number>(1);
    const [render, setRender] = React.useState(false);
    const pathname = window.location.pathname
        .toString()
        .split("/")[2]
        .toUpperCase();

    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    let pageType: string = "";
    let pageLing: string = "";

    if (pathname === "ARTICLES") {
        pageType = t("Items".toUpperCase());
        pageLing = "/items";
    }
    if (pathname === "WORKFLOW") {
        pageType = t("Workflow".toUpperCase());
        pageLing = "/WORKFLOW";
    }
    if (pathname === "STEPS") {
        pageType = t("Steps".toUpperCase());
        pageLing = "/steps";
    } else if (pageType === "" && pageLing === "") {
        pageType = t("Categories".toUpperCase());
        pageLing = "/categories";
    }
    React.useEffect(() => {
        if (titleList) {
            const { selected, created, Update } = UpdateFile(file, titleList);
            setCreatedNumber(created);
            SetSelectedNumber(selected);
            SetUpdatedNumber(Update);
        }
    }, [file, titleList]);

    const newFilteredRow = filteredRow.filter((row: any) => row[0] !== true);

    return (
        <React.Fragment>
            <StyledBreadcrumb
                items={[
                    {
                        item: "CATALOGUE ",
                        link: "home",
                    },
                    {
                        item: selectedStore.toUpperCase(),
                        link: "#",
                    },
                    { item: pageType, link: pageLing },
                    { item: t("Import excel file").toUpperCase(), link: "#" },
                ]}
            />
            <NavBar etape={step} pathname={pathname} />
            <Container fluid className="mt-2" style={{ overflowY: "scroll" }}>
                <SelectFile
                    setFile={setFile}
                    setStep={setStep}
                    step={step}
                    file={file}
                    createdNumber={createdNumber}
                    selectedNumber={selectedNumber}
                    updatedNumber={updatedNumber}
                    setFilteredRow={setFilteredRow}
                    filteredRow={newFilteredRow}
                />

                <Filtre
                    step={step}
                    setStep={setStep}
                    file={file}
                    setFilteredRow={setFilteredRow}
                    filteredRow={newFilteredRow}
                    setRender={setRender}
                    render={render}
                />

                <Table
                    setListTitle={setTitleList}
                    titleList={titleList}
                    file={file}
                    setFile={setFile}
                    etap={step}
                    createdNumber={createdNumber}
                    selectednumber={selectedNumber}
                    Updatenumber={updatedNumber}
                    filteredRow={newFilteredRow}
                />
            </Container>
        </React.Fragment>
    );
}
