import React from "react";

import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    StyledButtonWithIcon,
    StyledIconButton,
    PlusIcon,
} from "@aureskonnect/react-ui";

import {
    DynamicTableWrapperContext,
    DominantColor,
    LoadingDataAnimation,
    CustomCRUDColumn,
} from "@components/Common/.";
import { RootState } from "@store/.";

import "./style.css";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import DeleteTagModal from "./DeleteTagModal";
import DuplicateTagModal from "./DuplicateTagModal";

export function TagsList() {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const [isLoading, setIsLoading] = React.useState(false);
    const [tagIuud, setTagIuud] = React.useState("");
    const [isOpen, setIsOpen] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const dispatch = useDispatch();

    const customJsxAction = (e: any): JSX.Element => (
        <CustomCRUDColumn
            e={e}
            handleDuplicate={duplicateTag}
            handleEdit={editTag}
            handleDelete={deleteTag}
        />
    );

    const customJsxColor = ({ selectedRow }: any): JSX.Element => (
        <span
            style={{
                backgroundColor: selectedRow.Couleur,
                borderColor: "#0000",
                borderStyle: "solid",
                borderWidth: "thin",
                height: "30px",
                width: "25px",
                borderRadius: "6px",
            }}
        />
    );

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxAction,
        },
        {
            indexOFColumn: 1,
            columnName: "Couleur",
            customJsx: customJsxColor,
        },
    ];
    const arrayOfCustomColumnsNoActions: any[] = [
        {
            indexOFColumn: 1,
            columnName: "Couleur",
            customJsx: customJsxColor,
        },
    ];
    function editTag(e: any) {
        handleOnEditTag(e.selectedRow);
        dynamicTableWrapperContext?.setActionColumnDisplay(true);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    }

    function duplicateTag(e: any) {
        handleOnEditTag(e.selectedRow);

        setIsShow(!isShow);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    }

    function deleteTag(e: any) {
        handleOnEditTag(e.selectedRow);
        setIsOpen(!isOpen);
        setTagIuud(e.selectedRow.iuud);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    }

    function handleOnEditTag(e: any): void {
        dynamicTableWrapperContext?.setRowData({
            title: e.Désignation,
            color: e.Couleur,
            imageUrl: e.image,
            iuud: e.iuud,
            itemsIuud: e.tag.itemsIuud,
            categoriesIuud: e.tag.categoriesIuud,
        });
    }

    const switchFilterAction = (): void => {
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <div>
            <DynamicTable
                url={`${process.env.REACT_APP_ADB_API_URL}/tags/${schemaAdbFranchise}/${CardIuud}`}
                canSort
                name="Tag_table"
                customJsxSideFilterButton={
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            onSubmit={(): void => {
                                if (isDirty) {
                                    setUnsavedChangesWarningModalAction(
                                        () => switchFilterAction
                                    );
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dynamicTableWrapperContext?.setRowData(
                                        null
                                    );
                                    switchFilterAction();
                                }
                            }}
                        >
                            {!dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                    variant={dominantColor}
                                    onClick={switchFilterAction}
                                >
                                    {t("Add a tag")}
                                </StyledButtonWithIcon>
                            )}
                            {dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledIconButton
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                    }}
                                    icon="PlusIcon"
                                    className="bg-dark"
                                    rounded
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    <PlusIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            )}
                        </AvForm>
                    </div>
                }
                canResize
                showGlobalFilter
                showFilter
                filterActive={dynamicTableWrapperContext?.filterActive}
                setLocalFilterActive={
                    dynamicTableWrapperContext?.setLocalFilterActive
                }
                arrayOfCustomColumns={
                    cardType === "shop" && cardStatus === "En production"
                        ? arrayOfCustomColumnsNoActions
                        : arrayOfCustomColumns
                }
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                onClick={(row: any) => {
                    if (isDirty) {
                        setIsUnsavedChangesWarningModalOpen(true);
                        setUnsavedChangesWarningModalAction(() => () => {
                            handleOnEditTag(row.original);
                        });
                    } else {
                        dispatch(setIsEditing(false));
                        handleOnEditTag(row.original);
                    }
                }}
            />

            <DeleteTagModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                tagIuud={tagIuud}
                setIsLoading={setIsLoading}
            />
            <DuplicateTagModal
                isShow={isShow}
                setIsShow={setIsShow}
                setIsLoading={setIsLoading}
            />
            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    );
}
