/* eslint-disable no-nested-ternary */
import React from "react";
import { useDispatch } from "react-redux";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { StyledModal, StyledH2 } from "@aureskonnect/react-ui";
import { setWorkflow } from "@store/features/workflowSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import WorkFlowModalIndex from "./index";
import WorkFlowFooterModal from "./WorkFlowFooterModal";

import "../style.css";

type WorflowStepModalProps = {
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    isOpenStepModal: boolean;
    contentModal: any;
    setContentModal: React.Dispatch<any>;
    cardData?: any;
    othersData: {
        itemOthersData: null;
        categoryOthersData: null;
        stepOthersData: null;
    };
};
export default function WorkflowModal({
    setIsOpenStepModal,
    isOpenStepModal,
    contentModal,
    setContentModal,
    cardData,
    othersData,
}: WorflowStepModalProps) {
    const [isModify, setIsModify] = React.useState(false);
    const dispatch = useDispatch();
    const closeModal = () => {
        if (contentModal.length === 1) {
            setIsOpenStepModal(false);
        }
        dispatch(
            setMultipleWorkflow(
                contentModal[contentModal.length - 1].previousState
                    .multipleWorkflow
            )
        );
        dispatch(
            setWorkflowData(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflowData
            )
        );

        dispatch(
            setWorkflow(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflow
            )
        );

        dispatch(
            setModifier(
                contentModal[contentModal.length - 1].previousState
                    .modifierSlice
            )
        );

        setContentModal(
            contentModal[contentModal.length - 1].previousState.modalWorkflow
        );
        setIsModify(!isModify);
    };
    React.useEffect(() => {
        setContentModal(contentModal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModify]);
    return (
        <StyledModal
            size="xl"
            className="styled-Modal-wrapper__clz"
            isOpen={isOpenStepModal}
        >
            <ModalHeader
                toggle={() => {
                    closeModal();
                }}
            >
                <StyledH2 className="text-uppercase">
                    {contentModal?.length > 0
                        ? contentModal[contentModal.length - 1].type ===
                          WORKFLOW_LIST.TRIGGER
                            ? `PARCOURS: ${
                                  contentModal[contentModal.length - 1].title
                              } `
                            : `VENTE ADDITIONNELLE : ${
                                  contentModal[contentModal.length - 1].title
                              } `
                        : ""}
                </StyledH2>
            </ModalHeader>
            <ModalBody
                style={{
                    maxHeight: "750px",
                    overflowY: "auto",
                    minHeight: "750px",
                }}
            >
                <WorkFlowModalIndex
                    contentModal={contentModal}
                    setContentModal={setContentModal}
                    isModify={isModify}
                    setIsModify={setIsModify}
                    cardData={cardData}
                    setIsOpenStepModal={setIsOpenStepModal}
                    othersData={othersData}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <WorkFlowFooterModal
                    contentModal={contentModal}
                    setContentModal={setContentModal}
                    isModify={isModify}
                    setIsModify={setIsModify}
                    setIsOpenStepModal={setIsOpenStepModal}
                    // cardData={cardData}
                />
            </ModalFooter>
        </StyledModal>
    );
}
