import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type ExtensionModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};

export default function DuplicateNameModal({
    isOpen,
    setIsOpen,
}: ExtensionModalPropsType): JSX.Element {
    const { t } = useTranslation();
    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader
                toggle={() => {
                    setIsOpen(false);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                {t(
                    "It is impossible for two or more columns to have the same title"
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Close")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
