/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-await */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";

import {
    DominantColor,
    DynamicTableWrapperContext,
    ErrorToast,
    LoadingDataAnimation,
    SuccessToast,
} from "@components/Common";

import "./Style.css";

import Listshop from "./Listeshop";

type ListshopProps = {
    isOpen: boolean;
    cardmaster: boolean;
    setIsOpen: Function;
    rowData: any;
    pendingcardmaster: boolean;
    prodCardName: any;
    isLoading: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    Open: boolean;
    shopCarlistedeCartesenattentePlanifierds: any[];
};

function ModalPublish({
    isOpen,
    setIsOpen,
    rowData,
    cardmaster,
    pendingcardmaster,
    prodCardName,
    isLoading,
    setOpen,
    Open,
    shopCarlistedeCartesenattentePlanifierds,
}: ListshopProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const [OpenModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        if (
            shopCarlistedeCartesenattentePlanifierds.length >= 2 &&
            pendingcardmaster === false &&
            cardmaster === false
        ) {
            setOpenModal(!OpenModal);
        }
    }, [shopCarlistedeCartesenattentePlanifierds]);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };
    const toggleOpenModal = (): void => {
        setOpenModal(!OpenModal);
    };
    const PublishCardShop = () => {
        const cardData: any = [];

        const data = { shopid: rowData.selectedRow.original.shopid };
        cardData.push(data);

        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}publiccards/${schemaAdbFranchise}/${rowData.selectedRow.original.iuudcardreference}`,
                cardData
            )
            .then((): void => {
                SuccessToast(
                    `${rowData.selectedRow.original.Désignation} ${t(
                        "is successfully published"
                    )}`
                );
                dynamicTableWrapperContext?.setDataUpdated(true) &&
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                toggleOpen();
            })
            .catch((): void => {
                ErrorToast(t("There is a mistake!"));
            });
    };

    isLoading && <LoadingDataAnimation />;
    // eslint-disable-next-line no-lone-blocks

    if (shopCarlistedeCartesenattentePlanifierds.length >= 2) {
        return (
            <Modal centered isOpen={OpenModal}>
                <ModalHeader toggle={toggleOpenModal}>
                    {t("Alerte")}
                </ModalHeader>
                <ModalBody>
                    Attention! Vous avez des cartes boutiques planifiées. Pour
                    faire la publication de cette carte il est indispensable
                    d'annuler cette planification.{" "}
                </ModalBody>
                <ModalFooter>
                    <StyledButton
                        rounded
                        onClick={toggleOpenModal}
                        variant={dominantColor}
                    >
                        {t("Close")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <div>
            {rowData.selectedRow.original.iuudcardreference === "0" && (
                <Listshop
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    rowData={rowData}
                    pendingcardmaster={pendingcardmaster}
                    prodCardName={prodCardName}
                    setOpen={setOpen}
                    Open={Open}
                />
            )}
            {/* il n a pa du carte masterpubliée et on va publier carte boutique */}
            {rowData.selectedRow.original.iuudcardreference !== "0" && (
                <Modal centered isOpen={isOpen}>
                    <ModalHeader toggle={toggleOpen}>
                        <StyledH2 className="text-uppercase">
                            PUBLICATION
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        {t(
                            "Do you want to confirm the publication of the map for card"
                        )}{" "}
                        {rowData.selectedRow.original.shoptitle}
                    </ModalBody>
                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpen}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            onClick={PublishCardShop}
                            rounded
                            variant={dominantColor}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            )}

            {isLoading && (
                <Modal centered isOpen={isOpen}>
                    <ModalHeader toggle={toggleOpen}>
                        <StyledH2 className="text-uppercase">
                            PUBLICATION
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        <LoadingDataAnimation />
                    </ModalBody>
                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpen}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            onClick={PublishCardShop}
                            rounded
                            variant={dominantColor}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
}

export default ModalPublish;
