import React from "react";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { useTranslation } from "react-i18next";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { PageTitle } from "@components/Common";

import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
    filterName?: string;
    canFilter?: boolean;
};

function VatRate() {
    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const franchiseId = localStorage.getItem("franchiseId") || "";
    const shopId = localStorage.getItem("shopId") || "";
    let url = `${
        process.env.REACT_APP_SETTINGS_API_URL
    }/settings/general/vatRate/associated?userId=${106}&franchiseId=${franchiseId}&typeDisplay=notArchived`;

    if (shopId !== "0" && shopId !== "") {
        url += `&shopId=${shopId}`;
    }

    const arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Nbre TVA"),

            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any) => <NbreVatRate e={e} />,
        },
        {
            indexOFColumn: 997,
            columnName: t("Pays"),

            customJsx: (e: any) => {
                if (e.selectedRow.Pays.labels.length > 0) {
                    if (e.selectedRow.Pays.labels.length === 0) {
                        return e.selectedRow.Pays.labels[0];
                    }
                    return e.selectedRow.Pays.labels.join();
                }
                return "";
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Tag"),
            customJsx: (e: any) => e.selectedRow.tag,
        },
    ];
    // eslint-disable-next-line react/no-unstable-nested-components
    function NbreVatRate(e: any) {
        return e.e.selectedRow?.subRows !== undefined ? (
            <h6 className="badge__clz d-flex justify-content-center">
                {e.e.selectedRow?.Boutique !== ""
                    ? e.e.selectedRow.subRows?.length
                    : 0}
            </h6>
        ) : null;
    }

    return (
        <div>
            <PageTitle title="TVA" />
            <StyledBreadcrumb
                items={[
                    {
                        item: "CATALOGUE ",
                        link: "home",
                    },
                    {
                        item: "CONFIGIRATION",
                        link: "cards",
                    },
                    { item: t("VAT"), link: "cards" },
                ]}
            />
            <HeaderComponent title={t("Please set your VAT...")} />

            <DynamicTable
                url={url}
                canResize
                canExpand
                arrayOfCustomColumns={arrayOfCustomColumns}
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                elevationTable={1}
                name="VatRate"
            />
        </div>
    );
}

export default VatRate;
