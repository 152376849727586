/* eslint-disable react/destructuring-assignment */
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/index";
import { selectedCategory } from "@store/features";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";

interface selectedcategoryProps {
    isAddingCatOpen: boolean;
    category: {};
}

export default function SelectRowCategories({ selectedRow }: any): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let selectedcategory: selectedcategoryProps = useSelector(
        (State: RootState) => State.categorySlice.value
    );

    function dispatchCategory() {
        selectedcategory = {
            ...selectedcategory,
            category: selectedRow.original,
        };
        dispatch(selectedCategory(selectedcategory));
    }
    return (
        <div
            className="w-100
"
        >
            <div className="dropdown d-none">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        onClick={dispatchCategory}
                    >
                        {t("Select category")}
                    </div>
                    <div className="dropdown-item">
                        {t("See the list of stores")}
                    </div>
                </div>
            </div>
        </div>
    );
}
