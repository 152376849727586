import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";

import { StyledButton, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { DominantColor, SuccessToast } from "@components/Common";

type EtkTypeChoiceModalProps = {
    isOpen: boolean;
    setIsOpen: Function;
    Id?: string;
};

export default function EtkTypeChoiceModal({
    isOpen,
    setIsOpen,
    Id,
}: EtkTypeChoiceModalProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [etkType, setETKType] = React.useState<number>(0);
    const dominantColor: string = DominantColor();
    const handleOnChooseEtkType = () => {
        navigate("/cards");
        axios
            .put(
                `${process.env.REACT_APP_Access_API_URL}setETKType?ETK_Type=${etkType}&uidEntity=${Id}`
            )
            .then(() => {
                setIsOpen(!isOpen);
                SuccessToast(t("ETK Type Chosen successfully"));
                localStorage.setItem("ETKType", etkType.toString());
            })

            .catch(() => {
                //  ErrorToast(t("There is a mistake!"));
            });
    };

    return (
        <Modal centered isOpen={isOpen} size="lg">
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)}>
                <StyledH2 className="text-uppercase">
                    {t("ETK TYPE CHOICE")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <fieldset className="fieldset__clz scrollAdd__clz">
                    <legend style={{ marginLeft: "10px" }} className="w-auto">
                        <StyledLabel>
                            {t("Select the type of ETK that suits you")}
                        </StyledLabel>
                    </legend>
                    <div className="ml-4">
                        <div
                            className="form-check"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="standard"
                                id="standard"
                                onChange={() => {
                                    setETKType(1);
                                }}
                                disabled={false}
                            />
                            <StyledLabel>{t("ETK Standard")}</StyledLabel>
                        </div>
                        <label htmlFor=" ETKStandard">
                            {/* <input type="text" /> */}
                            ETK Standard ne permet que de gérer les applications
                            métier. L'alimentation du catalogue se fait via une
                            API ou importation d'un fichier Excel. Cette version
                            ne permet pas d’ajouter un article ou une catégorie.
                            La modification est possible (Sauf pour les
                            informations traitées sur le BO partenaire).
                            Catalogue va servir pour compléter le paramétrage
                            avec les options avancées non supportées par le BO
                            partenaire.
                        </label>
                    </div>
                    <div className="ml-4">
                        <div
                            className="form-check"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="Evolutionary"
                                id="Evolutionary"
                                onChange={() => {
                                    setETKType(2);
                                }}
                                disabled={false}
                            />
                            <StyledLabel>{t("ETK Evolutionary")}</StyledLabel>
                        </div>
                        <label htmlFor=" ETKLight">
                            {/* <input type="text" /> */}
                            Cette version offre les mêmes fonctionnalités de ETK
                            STANDARD avec lapossibilité de créer un article ou
                            une catégorie à partir du catalogue.
                        </label>
                    </div>
                    <div className="ml-4">
                        <div>
                            <div
                                className="form-check"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    value="Light"
                                    id="Light"
                                    onChange={() => {
                                        setETKType(3);
                                    }}
                                    disabled={false}
                                />
                                <StyledLabel>{t("ETK Light")}</StyledLabel>
                            </div>
                        </div>
                        <label htmlFor="CESAR">
                            {/* <input type="text" /> */}
                            Compatible avec la version de CESAR 15.06
                        </label>
                    </div>
                </fieldset>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    rounded
                    variant="light"
                    outline
                    onClick={() => {
                        setETKType(0);
                        setIsOpen(!isOpen);
                    }}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    disabled={etkType === 0}
                    onClick={handleOnChooseEtkType}
                >
                    {t("Next")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
