import { SVGProps } from "react";

export default function StepIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || "107"}
            height={props.height || "107"}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27818"
                data-name="Groupe 27818"
                transform="translate(18139 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                        rx={15}
                        transform="translate(-2)"
                        fill={props.stopColor}
                    />
                </g>
                <g
                    id="steps_1_"
                    data-name="steps (1)"
                    transform="translate(-18135 -4322)"
                >
                    <path
                        id="Trac\xE9_21060"
                        data-name="Trac\xE9 21060"
                        d="M215,2.647a6.017,6.017,0,0,0-1.384,1.718.64.64,0,0,0,.584.9h2.222a.639.639,0,0,0,0-1.279h-.957c.14-.144.285-.286.425-.421.628-.61,1.171-1.136,1.171-1.8a1.768,1.768,0,0,0-3.4-.688.639.639,0,0,0,1.178.5.489.489,0,0,1,.94.179,4.066,4.066,0,0,1-.783.891Zm0,0"
                        transform="translate(-204.459 0)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21061"
                        data-name="Trac\xE9 21061"
                        d="M1.738,2.859l.271-.271V4.367H1.562a.639.639,0,0,0,0,1.279H3.734a.639.639,0,1,0,0-1.279H3.288V1.044A.639.639,0,0,0,2.2.592L.834,1.955a.639.639,0,1,0,.9.9Zm0,0"
                        transform="translate(-0.619 -0.387)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21062"
                        data-name="Trac\xE9 21062"
                        d="M422.978,147.07a1.94,1.94,0,1,0,1.939,1.94A1.925,1.925,0,0,0,422.978,147.07Zm.467,2.407a.661.661,0,1,1,.193-.467A.656.656,0,0,1,423.445,149.477Zm0,0"
                        transform="translate(-403.092 -140.801)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21063"
                        data-name="Trac\xE9 21063"
                        d="M211.31,150.953a1.939,1.939,0,0,0,0-3.879h0a1.939,1.939,0,0,0,0,3.879Zm-.467-2.407a.661.661,0,1,1-.193.467A.655.655,0,0,1,210.844,148.546Zm0,0"
                        transform="translate(-200.447 -140.805)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21064"
                        data-name="Trac\xE9 21064"
                        d="M110.229,180.345a.6.6,0,1,0-.6.6A.6.6,0,0,0,110.229,180.345Zm0,0"
                        transform="translate(-104.376 -172.08)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21065"
                        data-name="Trac\xE9 21065"
                        d="M164.733,180.345a.6.6,0,1,0-.6.6A.6.6,0,0,0,164.733,180.345Zm0,0"
                        transform="translate(-156.556 -172.08)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21066"
                        data-name="Trac\xE9 21066"
                        d="M318.882,180.345a.6.6,0,1,0-.6.6A.6.6,0,0,0,318.882,180.345Zm0,0"
                        transform="translate(-304.135 -172.08)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21067"
                        data-name="Trac\xE9 21067"
                        d="M373.386,180.345a.6.6,0,1,0-.6.6A.6.6,0,0,0,373.386,180.345Zm0,0"
                        transform="translate(-356.315 -172.08)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21068"
                        data-name="Trac\xE9 21068"
                        d="M207.749,261.6h-3.18a.639.639,0,1,0,0,1.279h3.18a.639.639,0,0,0,0-1.279Zm0,0"
                        transform="translate(-195.237 -250.451)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21069"
                        data-name="Trac\xE9 21069"
                        d="M3.294,144.793,1.522,146.57l-.43-.43a.639.639,0,1,0-.9.9l.883.884a.639.639,0,0,0,.452.187h0a.639.639,0,0,0,.452-.188L4.2,145.7a.639.639,0,0,0-.905-.9Zm0,0"
                        transform="translate(0 -138.441)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21070"
                        data-name="Trac\xE9 21070"
                        d="M428.375,5.727a1.776,1.776,0,0,0,1.768-1.751,1.93,1.93,0,0,0-.506-1.379,1.624,1.624,0,0,0-.3-.245l.641-.863A.639.639,0,0,0,429.46.469h-1.917a.639.639,0,0,0,0,1.279h.646l-.432.582a.639.639,0,0,0,.513,1.021.614.614,0,0,1,.451.138.663.663,0,0,1,.145.452.5.5,0,0,1-.49.508.488.488,0,0,1-.45-.3.639.639,0,1,0-1.178.5,1.764,1.764,0,0,0,1.629,1.081Zm0,0"
                        transform="translate(-408.508 -0.449)"
                        fill={props.fill}
                    />
                </g>
            </g>
        </svg>
    );
}
