import React from "react";

import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DominantColor } from "@components/Common/";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { v4 as uuidv4 } from "uuid";
import { checkModifierExist } from "@pages/WorkFlow/helpers";

type CrushedItemModalPropsType = {
    isCrushed: boolean;
    setIsCrushed: React.Dispatch<React.SetStateAction<boolean>>;
    globalIndex: number;
    uuid: string;
    action: string;
    cardData: any;
    setContentModal: React.Dispatch<any>;
    contentModal: any;
};

export default function CrushedItemModalInModal({
    isCrushed,
    setIsCrushed,
    globalIndex,
    uuid,
    action,
    cardData,
    setContentModal,
    contentModal,
}: CrushedItemModalPropsType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const AddMultipleStepInStepInModal = () => {
        let copymodifierWorkflow: any = { ...modifierWorkflow };
        const workflowModal: any = contentModal[contentModal.length - 1].data;

        const modifierTypeCkecked =
            action === WORKFLOW_LIST.STEPS
                ? WORKFLOW_LIST.TYPEADD /* type vente additionnel */
                : WORKFLOW_LIST.TYPESTEPS; /* type steps */

        const workflowType =
            action === WORKFLOW_LIST.STEPS
                ? WORKFLOW_LIST.TRIGGER
                : WORKFLOW_LIST.ADD;

        const modifierChecked =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow,
            globalWorkflow,
            modalWorkflow: contentModal,
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        const firstUuidSelected =
            workflowModal[globalIndex!]?.multipleStep?.listeUuidItem[0];

        const modifierForm = checkModifierExist(
            firstUuidSelected,
            modifierTypeCkecked,
            globalIndex,
            modifierChecked,
            workflowModal
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidv4();

        /* add modifier */
        // eslint-disable-next-line no-prototype-builtins
        if (copymodifierWorkflow.hasOwnProperty(iuudMD5)) {
            copymodifierWorkflow =
                workflowType === WORKFLOW_LIST.TRIGGER
                    ? {
                          /* type steps */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeSteps: true,
                              steps: {},
                          },
                      }
                    : {
                          /* type vente additionnel */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeAdd: true,
                              add: {},
                          },
                      };
        } else {
            copymodifierWorkflow = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: workflowType === WORKFLOW_LIST.TRIGGER,
                    typeAdd: workflowType === WORKFLOW_LIST.ADD,
                    typeCategorie: false,
                },
            };
        }

        const copyWorkflow = [...workflowModal];
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            uuidModifier: iuudMD5!,
        };

        // insert modifier to global workflow
        let listeTitle: string = "";
        contentModal[contentModal.length - 1].data[
            globalIndex!
        ]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                const modifierForm = checkModifierExist(
                    elementUuid,
                    modifierTypeCkecked,
                    globalIndex,
                    modifierChecked,
                    workflowModal
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                ...copyWorkflow[globalIndex!].data[elementUuid]
                                    .content,
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                listeTitle === ""
                    ? (listeTitle = cardData?.items?.[elementUuid]?.title)
                    : (listeTitle += `, ${cardData?.items?.[elementUuid]?.title}`);
            }
        );
        const ContentModal = [...contentModal];
        ContentModal[ContentModal.length - 1].data = copyWorkflow;
        ContentModal.push({
            title: listeTitle,
            type: workflowType,
            triggerItemIuud:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .multipleStep.listeUuidItem[0],

            previousUuidStep:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .uuidStep!,
            previousModifierStep:
                contentModal[contentModal.length - 1].data[globalIndex!]
                    .previousModifier,
            previousModifier: iuudMD5,
            isMultiple: true,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid: contentModal[contentModal.length - 1].data[
                        globalIndex!
                    ].multipleStep.listeUuidItem[0],
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...contentModal[contentModal.length - 1].data[
                            globalIndex!
                        ].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);

        dispatch(
            setMultipleWorkflow([
                ...multipleWorkflow,
                {
                    popupIndex: contentModal.length,
                    StepIndex: globalIndex,
                    VirtualModifier: copymodifierWorkflow,
                    listeUuidItem:
                        contentModal[contentModal.length - 1].data[globalIndex!]
                            ?.multipleStep?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
    };

    return (
        <Modal centered isOpen={isCrushed}>
            <ModalHeader toggle={() => setIsCrushed(false)}>
                <StyledH2>{t("Avertissement")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                {t(
                    "One or more items contain a workflow, the validation of the modifications will overwrite the existing one."
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsCrushed(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        AddMultipleStepInStepInModal();

                        setIsCrushed(false);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
