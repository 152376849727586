import React from "react";

import axios from "axios";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/.";
import { renameObjKey } from "@helpers/renameObjKey";
import { DominantColor } from "@components/Common";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { StepIcon } from "@components/Common/SvgIcons";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { checkStepExistEtkLight, InsertStepUuidInModifer } from "../helpers";
import StepSelectionDropdownHeader from "./StepSelectionDropdownHeader";

import "../style.css";

type StepSelectionDropdownType = {
    searchedStepDesignation: string;
    title: string;
    type: string;
    triggerItemIuud: string;
    isModal?: boolean;
    contentModal?: any;
    setIsModify?: React.Dispatch<React.SetStateAction<boolean>>;
    isModify?: boolean;
    globalIndex: number;
    cardData: any;
    listUuidStep: string[];
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
};

type StepSelectionDropdownElementsType = {
    categories: { iuud: string; category: CategoryType }[];
    steps: { iuud: string; step: StepType }[];
    workflows: { iuud: string; designation: string }[];
    options: string[];
};

export default function StepSelectionDropdown({
    searchedStepDesignation,
    title,
    type,
    triggerItemIuud,
    isModal,
    contentModal,
    setIsModify,
    isModify,
    globalIndex,
    cardData,
    listUuidStep,
    setIndexSelected,
    setIsClickStep,
    isClickStep,
}: StepSelectionDropdownType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const filteredDropdownElements: StepSelectionDropdownElementsType = {
        categories: [],
        steps: [],
        workflows: [],
        options: [],
    };

    const [showSteps, setShowSteps] = React.useState(true);
    const [showCategories, setShowCategories] = React.useState(true);
    const [showWorkflows, setShowWorkflows] = React.useState(false);
    const [showOptions, setShowOptions] = React.useState(true);
    const dropdownElements = useSelector(
        (State: RootState) => State.workFlowSlice.stepSelectionDropdownElements
    );

    const [stepItemsSource, setStepItemsSource] = React.useState<{
        type: "step" | "category" | "workflow" | "option";
        iuud: string;
    }>({ type: "step", iuud: "" });
    if (searchedStepDesignation === "") {
        filteredDropdownElements.categories = dropdownElements.categories;
        filteredDropdownElements.steps = dropdownElements.steps;
        filteredDropdownElements.workflows = dropdownElements.workflows;
    } else {
        filteredDropdownElements.categories =
            dropdownElements.categories.filter((categoryDetail) =>
                categoryDetail.category.title.includes(searchedStepDesignation)
            );
        filteredDropdownElements.steps = dropdownElements.steps;

        filteredDropdownElements.workflows = dropdownElements.workflows.filter(
            (workflowDetail) =>
                workflowDetail.designation.includes(searchedStepDesignation)
        );
    }
    const addMultipleStep = (isUpdate: boolean, uuidStepOld: string) => {
        const copymultipleStep = JSON.parse(JSON.stringify(multipleWorkflow));

        const { VirtualModifier } =
            copymultipleStep[copymultipleStep.length - 1];

        const modifierType =
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS // step
                : WORKFLOW_LIST.ADD; // sales add

        // update uuid step
        if (isUpdate) {
            const uuidStepNew = stepItemsSource.iuud;
            const previousModifier =
                contentModal[contentModal.length - 1].previousModifier!;

            copymultipleStep[copymultipleStep.length - 1].VirtualModifier[
                previousModifier
            ][modifierType] = renameObjKey(
                copymultipleStep[copymultipleStep.length - 1].VirtualModifier[
                    previousModifier
                ][modifierType],
                uuidStepOld,
                uuidStepNew
            );
        } else {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: {
                    ...copymultipleStep[copymultipleStep.length - 1]
                        .VirtualModifier,
                    [contentModal[contentModal.length - 1].previousModifier]: {
                        ...copymultipleStep[copymultipleStep.length - 1]
                            .VirtualModifier[
                            contentModal[contentModal.length - 1]
                                .previousModifier
                        ],
                        "uuid-item": triggerItemIuud,
                        [modifierType]: {
                            ...VirtualModifier[
                                contentModal[contentModal.length - 1]
                                    .previousModifier
                            ][modifierType],
                            [stepItemsSource.iuud]: {
                                overrides: {},
                                itemModifiers: {},
                                rank: contentModal[contentModal.length - 1].data
                                    .length,
                            },
                        },
                    },
                },
            };
        }
        dispatch(setMultipleWorkflow(copymultipleStep));
    };
    const addModifierStep = (isUpdate: boolean, uuidStepOld: string) => {
        const modifierType =
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS // step
                : WORKFLOW_LIST.ADD; // sales add
        // update uuid step
        if (isUpdate) {
            const uuidStepNew = stepItemsSource.iuud;
            const previousModifier =
                contentModal[contentModal.length - 1].previousModifier!;

            const copyModifier = JSON.parse(JSON.stringify(modifierWorkflow));

            copyModifier[previousModifier][modifierType] = renameObjKey(
                copyModifier[previousModifier][modifierType],
                uuidStepOld,
                uuidStepNew
            );

            dispatch(setModifier(copyModifier));
        } else {
            dispatch(
                setModifier(
                    InsertStepUuidInModifer(
                        modifierWorkflow,
                        triggerItemIuud,
                        modifierType,
                        stepItemsSource,
                        contentModal[contentModal.length - 1],
                        globalIndex + 1
                    )
                )
            );
        }
    };
    const addModifierInProduct = (isUpdate: boolean, uuidStepOld: string) => {
        if (isUpdate) {
            const uuidStepNew = stepItemsSource.iuud;

            const previousModifier =
                globalWorkflow[globalIndex!].previousModifier!;

            const copyModifier = JSON.parse(JSON.stringify(modifierWorkflow));

            copyModifier[previousModifier].steps = renameObjKey(
                copyModifier[previousModifier].steps,
                uuidStepOld!,
                uuidStepNew
            );

            dispatch(setModifier(copyModifier));
        } else {
            // insert uuid step

            dispatch(
                setModifier(
                    InsertStepUuidInModifer(
                        modifierWorkflow,
                        triggerItemIuud,
                        WORKFLOW_LIST.STEPS,
                        stepItemsSource,
                        globalWorkflow[globalIndex!],
                        globalIndex + 1
                    )
                )
            );
        }
    };
    const addMultipleStepInProduct = (
        isUpdate: boolean,
        uuidStepOld: string
    ) => {
        const copymultipleStep = JSON.parse(JSON.stringify(multipleWorkflow));

        const { VirtualModifier } =
            copymultipleStep[copymultipleStep.length - 1];
        const previousModifier = globalWorkflow[globalIndex!]?.previousModifier;
        // update uuid step
        if (isUpdate) {
            const uuidStepNew = stepItemsSource.iuud;

            copymultipleStep[copymultipleStep.length - 1].VirtualModifier[
                previousModifier
            ].steps = renameObjKey(
                copymultipleStep[copymultipleStep.length - 1].VirtualModifier[
                    previousModifier
                ].steps,
                uuidStepOld,
                uuidStepNew
            );
        } else {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: {
                    ...copymultipleStep[copymultipleStep.length - 1]
                        .VirtualModifier,
                    [previousModifier]: {
                        ...copymultipleStep[copymultipleStep.length - 1]
                            .VirtualModifier[previousModifier],
                        "uuid-item": triggerItemIuud,
                        steps: {
                            ...VirtualModifier[previousModifier].steps,
                            [stepItemsSource.iuud]: {
                                overrides: {},
                                itemModifiers: {},
                                rank: globalIndex + 1,
                            },
                        },
                    },
                },
            };
        }
        dispatch(setMultipleWorkflow(copymultipleStep));
    };

    const getSelectedItems = () => {
        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/workflows/step-items/?type=${stepItemsSource.type}&iuud=${stepItemsSource.iuud}`
            )
            .then(() => {
                // get item step
                let listItems: any = {};
                let ListpreviousUuids: string[] = [];

                Object.entries(
                    cardData?.steps?.[stepItemsSource.iuud]?.stepItems
                )
                    .sort((a: any, b: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                    )
                    .forEach(([iuud]: any, index: number) => {
                        listItems = {
                            ...listItems,
                            [iuud]: {
                                type: WORKFLOW_LIST.ITEMS,
                                override: {},
                                content: {},
                                rank: index + 1,
                            },
                        };
                    });
                if (isModal) {
                    const isUpdate =
                        contentModal[contentModal.length - 1].data[globalIndex!]
                            .uuidStep !== "";

                    const uuidStepOld =
                        contentModal[contentModal.length - 1].data[globalIndex!]
                            .uuidStep !== "" &&
                        contentModal[contentModal.length - 1].data[globalIndex!]
                            .uuidStep;

                    if (isUpdate) {
                        // update uuid step in list path uuid
                        ListpreviousUuids = [
                            ...contentModal[contentModal.length - 1].data[
                                globalIndex!
                            ].previousUuids!,
                        ];

                        const indexOldStep = ListpreviousUuids.findIndex(
                            (element: string) => element === uuidStepOld
                        );

                        if (indexOldStep !== -1) {
                            ListpreviousUuids[indexOldStep] =
                                stepItemsSource.iuud;
                        }
                    } else {
                        ListpreviousUuids = [
                            ...contentModal[contentModal.length - 1].data[
                                globalIndex!
                            ].previousUuids!,
                            stepItemsSource.iuud,
                        ];
                    }

                    multipleWorkflow.length > 0
                        ? addMultipleStep(isUpdate, uuidStepOld) // add modifier multiple
                        : addModifierStep(isUpdate, uuidStepOld);

                    contentModal[contentModal.length - 1].data[globalIndex!] = {
                        ...contentModal[contentModal.length - 1].data[
                            globalIndex!
                        ],

                        data: listItems,
                        uuidStep: stepItemsSource.iuud,
                        previousUuids: ListpreviousUuids,
                        multipleStep: {
                            isMultiple: false,
                            listeUuidItem: [],
                        },
                    };
                    setIsModify?.(!isModify);
                } else {
                    // update uuid step
                    const isUpdate =
                        globalWorkflow[globalIndex!].uuidStep !== "";

                    const uuidStepOld = globalWorkflow[globalIndex!].uuidStep;

                    if (isUpdate) {
                        // update uuid step in list path uuid
                        ListpreviousUuids = [
                            ...globalWorkflow[globalIndex!].previousUuids!,
                        ];
                        const uuidStepNew = stepItemsSource.iuud;

                        const indexOldStep = ListpreviousUuids.findIndex(
                            (element: string) => element === uuidStepOld
                        );

                        if (indexOldStep !== -1) {
                            ListpreviousUuids[indexOldStep] = uuidStepNew;
                        }
                    } else {
                        ListpreviousUuids = [
                            ...globalWorkflow[globalIndex!].previousUuids!,
                            stepItemsSource.iuud,
                        ];
                    }
                    multipleWorkflow.length > 0
                        ? addMultipleStepInProduct(
                              isUpdate,
                              uuidStepOld
                          ) /* add modifier multiple */
                        : addModifierInProduct(isUpdate, uuidStepOld);

                    /* update uuid step globale workflow */
                    dispatch(
                        setWorkflow([
                            ...globalWorkflow.map((workflow) =>
                                workflow.title === title &&
                                workflow.type === type
                                    ? {
                                          ...workflow,
                                          data: listItems,
                                          uuidStep: stepItemsSource.iuud,
                                          previousUuids: ListpreviousUuids,
                                          multipleStep: {
                                              isMultiple: false,
                                              listeUuidItem: [],
                                          },
                                      }
                                    : workflow
                            ),
                        ])
                    );
                }
                setIndexSelected(globalIndex!);
                setIsClickStep(!isClickStep);
            });
    };

    const fillWorkflowStep = () => {
        getSelectedItems();
    };

    return (
        <div className="p-2" style={{ width: "25vw" }}>
            <StepSelectionDropdownHeader
                showSteps={showSteps}
                showCategories={showCategories}
                showWorkflows={showWorkflows}
                showOptions={showOptions}
                setShowSteps={setShowSteps}
                setShowCategories={setShowCategories}
                setShowWorkflows={setShowWorkflows}
                setShowOptions={setShowOptions}
            />
            <hr />
            <div
                className="px-3"
                style={{
                    overflow: "auto",
                    height: "20vh",
                }}
            >
                {showSteps && (
                    <div>
                        <h5>{t("Step")}</h5>

                        {filteredDropdownElements.steps
                            .filter(
                                (stepDetail) =>
                                    !listUuidStep?.includes(stepDetail.iuud) &&
                                    checkStepExistEtkLight(
                                        stepDetail.iuud,
                                        ETKType,
                                        multipleWorkflow,
                                        modifierWorkflow,
                                        isModal
                                            ? contentModal.length > 0 &&
                                                  contentModal[
                                                      contentModal.length - 1
                                                  ]?.previousModifier
                                            : globalWorkflow[globalIndex!]
                                                  ?.previousModifier
                                    ) &&
                                    stepDetail.step.title
                                        .toUpperCase()
                                        .includes(searchedStepDesignation)
                            )
                            .map((stepDetails) => {
                                const isCurrentStepSelected =
                                    stepItemsSource.iuud === stepDetails.iuud;
                                return (
                                    <div
                                        key={stepDetails.iuud}
                                        className="d-flex my-2"
                                    >
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            id={stepDetails.iuud}
                                            checked={isCurrentStepSelected}
                                            onChange={() =>
                                                setStepItemsSource({
                                                    type: "step",
                                                    iuud: stepDetails.iuud,
                                                })
                                            }
                                        />
                                        <div>
                                            <StepIcon
                                                width={20}
                                                height={20}
                                                fill="white"
                                                stopColor="#c50db9"
                                                className="mr-2 "
                                                style={
                                                    cardType === "shop" &&
                                                    cardStatus ===
                                                        "En production"
                                                        ? {
                                                              pointerEvents:
                                                                  "none",
                                                          }
                                                        : {
                                                              borderRadius:
                                                                  "4px",
                                                          }
                                                }
                                            />
                                        </div>
                                        <div>
                                            <LockIconWithToolTip
                                                type="title"
                                                lengthMax={41}
                                                message={`${stepDetails.step.title}`}
                                                id={`step${stepDetails.iuud}`}
                                            />
                                        </div>

                                        <span
                                            className="preview-textStep"
                                            id={`step${stepDetails.iuud}`}
                                        >
                                            {stepDetails.step.title}
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                )}
                {/* {showCategories && (
                    <div className="mb-3">
                        <h5>{t("Category")}</h5>
                        {filteredDropdownElements.categories.map(
                            (categoryDetails) => {
                                const isCurrentCategorySelected =
                                    stepItemsSource.iuud ===
                                    categoryDetails.iuud;
                                return (
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            id={categoryDetails.iuud}
                                            checked={isCurrentCategorySelected}
                                            onChange={() =>
                                                setStepItemsSource({
                                                    type: "category",
                                                    iuud: categoryDetails.iuud,
                                                })
                                            }
                                        />
                                        <CategoryIcon
                                            width={20}
                                            height={20}
                                            fill="#6800C2"
                                            className="mr-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        />
                                        {categoryDetails.category.title}
                                    </div>
                                );
                            }
                        )}
                    </div>
                )} */}
                {/* {showWorkflows && (
                    <div className="mb-3">
                        <h5>{t("Workflows")}</h5>
                        {filteredDropdownElements.workflows.map(
                            (workFlowDetails) => (
                                <div key={shortid.generate()}>
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        id={workFlowDetails.iuud}
                                        checked={false}
                                        onChange={() => {}}
                                    />
                                    <CategoryIcon
                                        width={20}
                                        height={20}
                                        fill="#6E5100"
                                        className="mr-2"
                                        style={{
                                            backgroundColor: "#6E5100",
                                            borderRadius: "4px",
                                        }}
                                    />
                                    {workFlowDetails.designation}
                                </div>
                            )
                        )}
                    </div>
                )} */}
            </div>
            <hr />
            <StyledButton
                onClick={fillWorkflowStep}
                rounded
                disabled={
                    stepItemsSource.iuud === "" ||
                    (cardType === "shop" && cardStatus === "En production")
                }
                variant={dominantColor}
                style={{ marginLeft: "auto" }}
            >
                {t("Insert")}
            </StyledButton>
        </div>
    );
}
