export function optionFormModalWorkflow(
    modifierWorkflow: any,
    contentModal: any,
    globalIndex: number,
    iuud: string
) {
    const optionForm: any = {};
    if (
        Object.keys(
            modifierWorkflow[
                contentModal[contentModal.length - 1]?.previousModifierStep
            ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                ?.overrides || {}
        ).length <= 0 ||
        Object.keys(
            modifierWorkflow[
                contentModal[contentModal.length - 1]?.previousModifierStep
            ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                ?.overrides?.stepItems[
                contentModal[contentModal.length - 1]?.triggerItemIuud
            ]?.overrideOptions || {}
        ).length <= 0
    ) {
        optionForm.image = "no-pictures.svg";
        optionForm.isVisible = true;
        optionForm.isOptionModifier = "";

        return optionForm;
    }
    optionForm.image =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideOptions[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.image !== ""
            ? modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                  ?.overrides?.stepItems[
                  contentModal[contentModal.length - 1]?.triggerItemIuud
              ]?.overrideOptions[
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuidStep
              ]?.content[iuud]?.image
            : "no-pictures.svg";

    optionForm.isOptionModifier =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideOptions[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.modifier !== ""
            ? modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
                  ?.overrides?.stepItems[
                  contentModal[contentModal.length - 1]?.triggerItemIuud
              ]?.overrideOptions[
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuidStep
              ]?.content[iuud]?.modifier
            : "";

    optionForm.isVisible =
        modifierWorkflow[
            contentModal[contentModal.length - 1]?.previousModifierStep
        ]?.steps[contentModal[contentModal.length - 1]?.previousUuidStep]
            ?.overrides?.stepItems[
            contentModal[contentModal.length - 1]?.triggerItemIuud
        ]?.overrideOptions[
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
        ]?.content[iuud]?.isVisible ?? true;

    return optionForm;
}
export function optionFormWorkflow(
    globalWorkflow: WorkFlowStepProps[],
    startIndex: number,
    uuid: string,
    globalIndex: number,
    iuud: string,
    rank: number
) {
    const optionForm: any = {};
    if (
        Object.keys(globalWorkflow[startIndex]?.data[uuid]?.override || {})
            .length <= 0 ||
        Object.keys(
            globalWorkflow[startIndex].data[uuid]?.override[uuid]
                ?.overrideOptions || {}
        ).length <= 0
    ) {
        optionForm.image = "no-pictures.svg";
        optionForm.isVisible = true;
        optionForm.isOptionModifier = "";
        optionForm.rank = rank;
        return optionForm;
    }

    optionForm.image = globalWorkflow[startIndex].data[uuid]?.override[uuid]
        ?.overrideOptions[globalWorkflow[globalIndex!]?.uuidStep]?.content[iuud]
        ?.image
        ? globalWorkflow[startIndex].data[uuid]?.override[uuid]
              ?.overrideOptions[globalWorkflow[globalIndex!]?.uuidStep]
              ?.content[iuud]?.image
        : "no-pictures.svg";

    optionForm.isOptionModifier = globalWorkflow[startIndex].data[uuid]
        ?.override[uuid]?.overrideOptions[
        globalWorkflow[globalIndex!]?.uuidStep
    ]?.content[iuud]?.modifier
        ? globalWorkflow[startIndex].data[uuid]?.override[uuid]
              ?.overrideOptions[globalWorkflow[globalIndex!]?.uuidStep]
              ?.content[iuud]?.modifier
        : "";

    optionForm.isVisible =
        globalWorkflow[startIndex].data[uuid]?.override[uuid]?.overrideOptions[
            globalWorkflow[globalIndex!]?.uuidStep
        ]?.content[iuud]?.isVisible ?? true;

    return optionForm;
}
export function InsertOverrideOptionInItem(
    copyOverrideOption: any,
    cardData: any,
    uuidItem: string
) {
    Object.entries(cardData?.items?.[uuidItem]?.options).forEach(
        ([iuudOption]: any) => {
            let listValue: WorkflowContentOptionType = {};

            cardData?.items?.[uuidItem]?.options[iuudOption].forEach(
                (iuudValue: string) => {
                    listValue = {
                        ...listValue,
                        [iuudValue]: {
                            image: "",
                            isVisible: true,
                            modifier: "",
                        },
                    };
                }
            );
            copyOverrideOption = {
                ...copyOverrideOption,
                [iuudOption]: {
                    content: listValue,
                    isVisible: true,
                },
            };
        }
    );
    return copyOverrideOption;
}
export function GetlistUuidOptions(
    copymodifierWorkflow: any,
    previousModifier: string,
    UuidStep: string,
    iuudOption: string,
    uuid: string,
    cardData: any,
    typeRank: string,
    typeSteps: string
) {
    if (
        Object.keys(
            copymodifierWorkflow[previousModifier][typeSteps][UuidStep]
                .overrides || {}
        ).length > 0 &&
        Object.keys(
            copymodifierWorkflow[previousModifier][typeSteps][UuidStep]
                .overrides.stepItems[uuid][typeRank] || {}
        ).length > 0 &&
        copymodifierWorkflow[previousModifier][typeSteps][
            UuidStep
            // eslint-disable-next-line no-prototype-builtins
        ].overrides.stepItems[uuid][typeRank].hasOwnProperty(iuudOption)
    ) {
        return copymodifierWorkflow[previousModifier][typeSteps][UuidStep]
            .overrides.stepItems[uuid][typeRank][iuudOption];
    }

    return cardData?.items?.[uuid]?.options[iuudOption];
}
export function GlobalVisibilityOption(
    globalWorkflow: any,
    startIndex: number,
    UuidItem: string,
    UuidStep: string
) {
    if (
        Object.keys(globalWorkflow[startIndex]?.data[UuidItem]?.override || {})
            .length > 0 &&
        Object.keys(
            globalWorkflow[startIndex]?.data[UuidItem].override[UuidItem]
                ?.overrideOptions || {}
        ).length > 0
    ) {
        return globalWorkflow[startIndex]?.data[UuidItem].override[UuidItem]
            ?.overrideOptions[UuidStep]?.isVisible;
    }
    return true;
}
export function GlobalVisibilityOptionInModal(
    modifierWorkflow: any,
    previousModifierStep: string,
    previousUuidStep: string,
    triggerItemIuud: string,
    uuidStep: string
) {
    if (
        Object.keys(
            modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
                ?.overrides || {}
        ).length > 0 &&
        Object.keys(
            modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
                ?.overrides?.stepItems[triggerItemIuud]?.overrideOptions || {}
        ).length > 0
    ) {
        return modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
            ?.overrides?.stepItems[triggerItemIuud]?.overrideOptions[uuidStep]
            ?.isVisible;
    }
    return true;
}
