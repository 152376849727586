export const getNestedId = (fn: any) => (o: any) =>
    [fn(o), ...(o.subRows || []).flatMap(getNestedId(fn))];

export const getNestedIdofArray = (data: any) => {
    const listUuid: any[] = [];
    data?.forEach((item: any) => {
        const listid = getNestedId((x: any) => x.iuud)(item);

        listUuid.push(...listid);
    });

    return [...listUuid];
};
