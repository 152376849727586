import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type deleteModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    stepToDeleteIuud: string;
    setIsLoading: Function;
};
export default function DeleteStepModal({
    isOpen,
    setIsOpen,
    stepToDeleteIuud,
    setIsLoading,
}: deleteModalPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const dominantColor: string = DominantColor();

    const handleRemoveButtonOnClickEvent = () => {
        setIsLoading(true);
        const cardIuud = JSON.parse(
            localStorage.getItem("selectedCard") || "{}"
        ).iuud;

        axios
            .delete(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/steps/${stepToDeleteIuud}`
            )
            .then((): void => {
                setIsOpen(!isOpen);
                setIsLoading(false);
                dynamicTableWrapperContext?.setDataUpdated(true);
            })
            .then((): void => {
                SuccessToast(t("The step removed successfully"));
            })
            .catch((): void => {
                setIsLoading(false);
                ErrorToast(t("There is a mistake!"));
            });
    };
    const toggleOpen = (): boolean => setIsOpen(!isOpen);
    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex">
                        {t("Archive step")} <span className="mx-1">:</span>
                        <LockIconWithToolTip
                            type="title"
                            lengthMax={20}
                            message={
                                dynamicTableWrapperContext?.rowData?.data?.title
                            }
                            id="archiver"
                        />
                        <div id="archiver" className="modal_clz ">
                            {dynamicTableWrapperContext?.rowData?.data?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <h3 className="d-flex justify-content-center">
                    {t("Warning")} !
                </h3>
                <div className="my-2">
                    {t("Do you want to confirm the archive of this step ?")}
                </div>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleRemoveButtonOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
