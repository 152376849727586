/* eslint-disable no-nested-ternary */
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import StepSelectionDropdown from "../../../StepSelectionDropdown";

type dropDownStepModalProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    isDropdownMenuActive: boolean;
    setIsDropdownMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
    searchedStepDesignation: string;
    cardData: any;
    globalIndex: number;
    ListeUuidStep: string[];
    setSearchedStepDesignation: React.Dispatch<React.SetStateAction<string>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    contentModal: any;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
};

export function DropdownStepModal({
    IsBasicCompositionStep,
    IsOptionStep,
    isDropdownMenuActive,
    setIsDropdownMenuActive,
    searchedStepDesignation,
    cardData,
    globalIndex,
    ListeUuidStep,
    setSearchedStepDesignation,
    setIsClickStep,
    isClickStep,
    setIndexSelected,
    contentModal,
    setIsModify,
    isModify,
}: dropDownStepModalProps) {
    return (
        <Dropdown
            isOpen={
                IsBasicCompositionStep || IsOptionStep
                    ? false
                    : isDropdownMenuActive
            }
            toggle={() => setIsDropdownMenuActive((prevState) => !prevState)}
        >
            <DropdownToggle data-toggle="dropdown" tag="span">
                <div className="add_step__CLZ  d-flex justify-content-between align-items-center">
                    <LockIconWithToolTip
                        type="title"
                        lengthMax={21}
                        message={
                            isDropdownMenuActive &&
                            !IsOptionStep &&
                            !IsBasicCompositionStep
                                ? searchedStepDesignation // search value
                                : // eslint-disable-next-line no-nested-ternary
                                IsOptionStep // selected value
                                ? cardData?.options?.[
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex!]?.uuidStep!
                                  ]?.title
                                : // eslint-disable-next-line no-nested-ternary
                                IsBasicCompositionStep
                                ? WORKFLOW_LIST.BASICCOMPOSITION
                                : Object.keys(
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex]?.override || {}
                                  ).length > 0
                                ? contentModal[contentModal.length - 1]?.data[
                                      globalIndex
                                  ]?.override.title
                                : cardData?.steps?.[
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex!]?.uuidStep!
                                  ]?.title
                        }
                        id={`title${globalIndex}`}
                    />
                    <input
                        id={`title${globalIndex}`}
                        className="borderless_input__CLZ preview-textStep-card"
                        type="text"
                        value={
                            // eslint-disable-next-line no-nested-ternary
                            isDropdownMenuActive &&
                            !IsOptionStep &&
                            !IsBasicCompositionStep
                                ? searchedStepDesignation // search value
                                : // eslint-disable-next-line no-nested-ternary
                                IsOptionStep // selected value
                                ? cardData?.options?.[
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex!]?.uuidStep!
                                  ]?.title
                                : // eslint-disable-next-line no-nested-ternary
                                IsBasicCompositionStep
                                ? WORKFLOW_LIST.BASICCOMPOSITION
                                : Object.keys(
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex]?.override || {}
                                  ).length > 0
                                ? contentModal[contentModal.length - 1]?.data[
                                      globalIndex
                                  ]?.override.title
                                : cardData?.steps?.[
                                      contentModal[contentModal.length - 1]
                                          ?.data[globalIndex!]?.uuidStep!
                                  ]?.title
                        }
                        onChange={(e: any) =>
                            isDropdownMenuActive &&
                            setSearchedStepDesignation(
                                e.target.value.toUpperCase()
                            )
                        }
                        onKeyPress={(e: any) => {
                            !isDropdownMenuActive && e.preventDefault();
                        }}
                    />

                    <ArrowDownIcon color="black" />
                </div>
            </DropdownToggle>
            <DropdownMenu
                style={{
                    cursor: "auto",
                    border: "1px solid #E8E8E8",
                    borderRadius: "6px",
                    boxShadow: "0px 3px 6px #000000BD",
                }}
            >
                <StepSelectionDropdown
                    searchedStepDesignation={searchedStepDesignation}
                    title={contentModal[contentModal.length - 1]?.title}
                    type={contentModal[contentModal.length - 1]?.type}
                    triggerItemIuud={
                        contentModal[contentModal.length - 1]?.triggerItemIuud
                    }
                    isModal
                    contentModal={contentModal}
                    setIsModify={setIsModify}
                    isModify={isModify}
                    globalIndex={globalIndex}
                    cardData={cardData}
                    listUuidStep={ListeUuidStep}
                    isClickStep={isClickStep}
                    setIsClickStep={setIsClickStep}
                    setIndexSelected={setIndexSelected}
                />
            </DropdownMenu>
        </Dropdown>
    );
}
