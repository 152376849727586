import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DominantColor } from "@components/Common/";
import { MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { RootState } from "@store/index";

export default function DeleteImageModal({
    isOpen,
    setIsOpen,
    setUploadedImage,
}: {
    isOpen: boolean;
    setIsOpen: Function;
    setUploadedImage: React.Dispatch<React.SetStateAction<string | Blob>>;
}) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const dominantColor: string = DominantColor();

    const toggleOpen = () => setIsOpen(!isOpen);

    const handleDelete = () => {
        setUploadedImage("");
        toggleOpen();
        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.IMAGEURL));
    };
    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    {t("Archive Image")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to confirm the archive of this image?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleDelete}
                    rounded
                    variant={dominantColor}
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
