import { ACTIONS } from "@helpers/actions";

export const initialStateStepItem: StepItemType = {
    minNbrOfChoicesByItems: 0,
    maxNbrOfChoicesByItems: null,
    nbrOfChoicesWithspecialPrice: null,
    nbrOfChoicesWithItemPriceInStep: null,
    color: "#FFFFFF",
    specialPrice: 0,
    priceOfItemInStep: 0,
    rank: 1,
    title: "",
    refrence: "",
    parentCategory: "",
    price: 0,
    basicCompositionVisibility: false,
    imageUrl: "",
    fidelity: 0,
    visibilityInfo: {
        isVisible: true,
        basicCompositionVisibility: true,
        default: {},
    },
};
export function reducer(
    state: StepItemType,
    action: { type: string; payload: any }
): StepItemType {
    switch (action.type) {
        case ACTIONS.MAX_NUMBER_OF_CHOICES:
            return {
                ...state,
                maxNbrOfChoicesByItems: parseInt(action.payload, 10),
            };

        case ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE:
            return {
                ...state,
                nbrOfChoicesWithspecialPrice: parseInt(action.payload, 10),
            };
        case ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE:
            return {
                ...state,
                nbrOfChoicesWithItemPriceInStep: parseInt(action.payload, 10),
            };
        case ACTIONS.SPECIAL_PRICE:
            return {
                ...state,
                specialPrice: action.payload,
            };
        case ACTIONS.ITEM_PRICE_IN_STEP:
            return {
                ...state,
                priceOfItemInStep: action.payload,
            };
        case ACTIONS.COLOR:
            return {
                ...state,
                color: action.payload,
            };

        case ACTIONS.FIDELITY:
            return {
                ...state,
                fidelity: action.payload,
            };

        case ACTIONS.DEFAULT_IMAGE_URL:
            return {
                ...state,
                imageUrl: action.payload,
            };
        case ACTIONS.EDIT:
            return action.payload;

        case ACTIONS.ClEAR:
            return initialStateStepItem;
        default:
            return state;
    }
}
