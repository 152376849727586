import React from "react";

import { useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";

import { RootState } from "@store/.";
import { StyledButton, StyledLabel } from "@aureskonnect/react-ui";

import {
    SuccessToast,
    RequirementToast,
    DynamicTableWrapperContext,
    DominantColor,
    CustomCardBody,
    CustomCardFooter,
    ErrorToast,
} from "@components/Common/";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";

import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";

type addCardPropsType = { setIsLoading: Function };
export default function AddCard({ setIsLoading }: addCardPropsType) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState) => state.layoutSlice.isTablet
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [cards, setCards] = React.useState<CardType>({
        title: "",
        remark: "",
    });
    const card: CardType = {
        idEntite: [0],
        iuudCardReference: 0,

        title: cards.title,
        remark: cards.remark,
        operator: "Administrateur",
        status: "En attente",
        categories: {},
        items: {},
        DisplayName: {},
        dateModification: Date.now().toString(),
        serviceAvailability: [
            {
                dayOfWeek: "",
                timePeriods: [
                    {
                        startTime: "",
                        endTime: "",
                        nameService: "",
                        activityDay: "",
                    },
                ],
            },
        ],
        steps: {},
        allergens: {},
        allergenGroups: {},
    };
    const handleCardFromChanget = (title: string) => {
        setCards({ ...cards, title });
    };
    const handleCardFromChange = (remark: string) => {
        setCards({ ...cards, remark });
    };
    const createCard = async (): Promise<void> => {
        setIsLoading(true);
        if (validateOnclick) {
            setValidateOnclick(false);

            dynamicTableWrapperContext?.rowData?.iuud
                ? axios
                      .put(
                          `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/card/${dynamicTableWrapperContext?.rowData?.iuud}`,
                          card
                      )
                      .then((): void => {
                          dynamicTableWrapperContext?.setDataUpdated(true);
                          dynamicTableWrapperContext?.setActionColumnDisplay(
                              false
                          );
                          setIsLoading(false);
                          SuccessToast(t("Card is successfully updated"));
                      })
                      .catch((): void => {
                          ErrorToast(t("There is a mistake!"));
                      })
                : axios
                      .post(
                          `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/card`,
                          card
                      )
                      .then((): void => {
                          dynamicTableWrapperContext?.setDataUpdated(true);
                          dynamicTableWrapperContext?.setActionColumnDisplay(
                              false
                          );
                          setIsLoading(false);
                          SuccessToast(t("Card is successfully added"));
                      })
                      .catch((): void => {
                          ErrorToast(t("There is a mistake!"));
                      });
        }
    };
    const hideForm = (): void => {
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };
    const handleRefreshIconClick = (): void => {
        dynamicTableWrapperContext?.setRowData({
            designation: "",
            remark: "",
        });
        dynamicTableWrapperContext?.setDataUpdated(true);
    };

    React.useEffect((): void => {
        setCards({
            title: dynamicTableWrapperContext?.rowData?.designation,
            remark: dynamicTableWrapperContext?.rowData?.remark,
        });
    }, [dynamicTableWrapperContext?.rowData]);

    return (
        <Card style={{ border: "0" }}>
            <StyleCardHeader
                title="Menu"
                refrechIconFunction={handleRefreshIconClick}
            />

            <AvForm
                onValidSubmit={createCard}
                onInvalidSubmit={(): JSX.Element =>
                    RequirementToast(
                        t("The ''Designation'' field must be filled in")
                    )
                }
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") e.preventDefault();
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <div className=" px-3 py-2">
                        <StyledLabel htmlFor="example-input">
                            {t("Designation")} *
                        </StyledLabel>
                        <StyledInputWithTooltip
                            id="title"
                            name="title"
                            type="text"
                            value={cards.title}
                            valueSetterFunction={handleCardFromChanget}
                            requiredValidateValue
                            autoFocus
                            onFocus={(event: any) => {
                                event.target.select();
                            }}
                        />
                        <StyledLabel htmlFor="example-input">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledInputWithTooltip
                            requiredValidateValue={false}
                            id="remark"
                            name="remark"
                            type="text"
                            value={cards.remark}
                            valueSetterFunction={handleCardFromChange}
                        />
                    </div>
                </CustomCardBody>

                <CustomCardFooter>
                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                        <div className="p-2 bd-highlight">
                            <StyledButton rounded variant={dominantColor}>
                                {t("Validate")}
                            </StyledButton>
                        </div>
                        <div className="p-2 bd-highlight ">
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={hideForm}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                    </div>
                </CustomCardFooter>
            </AvForm>
        </Card>
    );
}
