/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react";

import { StyledButton, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useTranslation } from "react-i18next";

import { SuccessToast, DominantColor } from "@components/Common/";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/.";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";

type AllergensModalPropsType = {
    itemAllergens: string[];
    dispatch: Function;
    isRedirect: boolean;
};

export default function AllergensModal({
    itemAllergens,
    dispatch,
    isRedirect,
}: AllergensModalPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const dominantColor = DominantColor();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [, setValidateOnclick] = React.useState<boolean>(true);
    const allergensList = useSelector(
        (state: RootState): { iuud: string; allergen: AllergenType }[] =>
            state.allergenSlice.allergens
    );
    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );
    const [updatedAllergens, setUpdatedAllergens] =
        React.useState<string[]>(itemAllergens);

    function handleUpdate(iuud: string) {
        updatedAllergens.includes(iuud)
            ? setUpdatedAllergens((previousAllergens): string[] => [
                  ...previousAllergens.filter(
                      (allergenIuud): boolean => allergenIuud !== iuud
                  ),
              ])
            : setUpdatedAllergens((previousAllergens): string[] => [
                  ...previousAllergens,
                  iuud,
              ]);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setUpdatedAllergens(itemAllergens);
    };

    const handleValidateButtonOnClickEvent = () => {
        if (
            isEditing &&
            cardType === "shop" &&
            cardStatus === "En production"
        ) {
            setIsModalOpen(false);
        } else {
            setValidateOnclick(false);

            setIsModalOpen(false);
            dispatch({
                type: ACTIONS.ALLERGENS,
                payload: updatedAllergens,
            });
            isMultiModify &&
                dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.ALLERGENS));
            SuccessToast(t("Successful registration"));
        }
    };

    React.useEffect(() => {
        setUpdatedAllergens(itemAllergens);
    }, [itemAllergens]);

    return (
        <div
            style={
                isRedirect
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : { opacity: "1" }
            }
        >
            <div
                style={{
                    cursor: "pointer",
                    color: "blue",
                    border: "transparent",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    textDecoration: "underline",
                    font: "normal normal 600 16px/21px Segoe UI",
                }}
                onClick={(): void => {
                    setIsModalOpen(true);
                }}
                role="button"
                tabIndex={0}
            >
                {t("Advanced")}
            </div>
            <StyledModal
                centered
                isOpen={isModalOpen}
                size="xl"
                style={{
                    overflowX: "hidden",
                }}
            >
                <ModalHeader toggle={closeModal}>{t("Allergens")}</ModalHeader>
                <ModalBody
                    style={
                        isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production"
                            ? { pointerEvents: "none", opacity: "1" }
                            : {
                                  maxHeight: "calc(100vh - 210px)",
                                  overflowY: "auto",
                              }
                    }
                    className="d-flex flex-wrap  row"
                >
                    {allergensList.map(
                        ({ iuud, allergen }): JSX.Element => (
                            <div
                                key={iuud}
                                className="d-flex col-lg-3 col-md-4 col-6 mt-2"
                            >
                                <img
                                    alt={allergen.title}
                                    style={{
                                        width: "110px",
                                        height: "60px",
                                        borderRadius: "10%",
                                        background: updatedAllergens.includes(
                                            iuud
                                        )
                                            ? "#e0f7ff   "
                                            : "#e6e6e6",
                                    }}
                                    src={allergen.icon}
                                    onClick={() => {
                                        handleUpdate(iuud);
                                    }}
                                    role="button"
                                    tabIndex={0}
                                />

                                <p className="p-2 w-100">{allergen.title}</p>
                            </div>
                        )
                    )}
                </ModalBody>
                <ModalFooter>
                    <StyledButton
                        rounded
                        variant="light"
                        outline
                        onClick={closeModal}
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
