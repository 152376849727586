/* eslint-disable max-len */
import React from "react";

export default function Calander(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="calendrier_1_"
            data-name="calendrier (1)"
            xmlns="http://www.w3.org/2000/svg"
            width="29.005"
            height="29.005"
            viewBox="0 0 29.005 29.005"
            {...props}
        >
            <path
                id="Tracé_18383"
                data-name="Tracé 18383"
                d="M27.305,16.556V2.606a.85.85,0,0,0-.85-.85H22.15V.85a.85.85,0,0,0-1.7,0v.906h-3.4V.85a.85.85,0,1,0-1.7,0v.906h-3.4V.85a.85.85,0,1,0-1.7,0v.906h-3.4V.85a.85.85,0,0,0-1.7,0v.906H.85a.85.85,0,0,0-.85.85v22.15a.85.85,0,0,0,.85.85H15a7.645,7.645,0,1,0,12.3-9.05ZM5.155,3.456v.85a.85.85,0,0,0,1.7,0v-.85h3.4v.85a.85.85,0,1,0,1.7,0v-.85h3.4v.85a.85.85,0,1,0,1.7,0v-.85h3.4v.85a.85.85,0,0,0,1.7,0v-.85h3.456v3.4H1.7v-3.4ZM1.7,23.906V8.554H25.606V15a7.639,7.639,0,0,0-10.665,2.2.845.845,0,0,0-.382-.091h-1.7a.85.85,0,0,0,0,1.7h1.287a7.584,7.584,0,0,0-.39,1.7h-.9a.85.85,0,0,0,0,1.7h.9a7.572,7.572,0,0,0,.39,1.7Zm19.657,3.4a5.948,5.948,0,1,1,5.948-5.948A5.955,5.955,0,0,1,21.357,27.305Zm0,0"
            />
            <path
                id="Tracé_18384"
                data-name="Tracé 18384"
                d="M366.249,305.4H363.7V302.85a.85.85,0,0,0-1.7,0v3.4a.85.85,0,0,0,.85.85h3.4a.85.85,0,1,0,0-1.7Zm0,0"
                transform="translate(-341.493 -284.892)"
            />
            <path
                id="Tracé_18385"
                data-name="Tracé 18385"
                d="M93.549,182h-1.7a.85.85,0,1,0,0,1.7h1.7a.85.85,0,1,0,0-1.7Zm0,0"
                transform="translate(-85.845 -171.69)"
            />
            <path
                id="Tracé_18386"
                data-name="Tracé 18386"
                d="M93.549,242h-1.7a.85.85,0,1,0,0,1.7h1.7a.85.85,0,1,0,0-1.7Zm0,0"
                transform="translate(-85.845 -228.291)"
            />
            <path
                id="Tracé_18387"
                data-name="Tracé 18387"
                d="M93.549,302h-1.7a.85.85,0,1,0,0,1.7h1.7a.85.85,0,1,0,0-1.7Zm0,0"
                transform="translate(-85.845 -284.892)"
            />
            <path
                id="Tracé_18388"
                data-name="Tracé 18388"
                d="M212.85,183.7h1.7a.85.85,0,0,0,0-1.7h-1.7a.85.85,0,1,0,0,1.7Zm0,0"
                transform="translate(-199.99 -171.69)"
            />
            <path
                id="Tracé_18389"
                data-name="Tracé 18389"
                d="M212.85,243.7h1.7a.85.85,0,0,0,0-1.7h-1.7a.85.85,0,1,0,0,1.7Zm0,0"
                transform="translate(-199.99 -228.291)"
            />
            <path
                id="Tracé_18390"
                data-name="Tracé 18390"
                d="M93.549,362h-1.7a.85.85,0,1,0,0,1.7h1.7a.85.85,0,1,0,0-1.7Zm0,0"
                transform="translate(-85.845 -341.493)"
            />
            <path
                id="Tracé_18391"
                data-name="Tracé 18391"
                d="M332.85,183.7h1.7a.85.85,0,1,0,0-1.7h-1.7a.85.85,0,1,0,0,1.7Zm0,0"
                transform="translate(-313.192 -171.69)"
            />
        </svg>
    );
}
