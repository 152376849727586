import React, { useState } from "react";

import { StyledLabel } from "@aureskonnect/react-ui";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import shortid from "shortid";
import { useTranslation } from "react-i18next";

import SwitchVisibility from "./SwitchVisibility";
import AddHour from "./AddHour";
import ModifyHour from "./ModifyHour";

import "../Price.css";

const ACTIONS = {
    START_TIME: "HeureDebut",
    END_TIME: "HeureFin",
    PRICE: "Price",
    ALL: "All",
};
export type HourType = {
    isActive: boolean;
    setPeriod: React.Dispatch<any>;
    period: any;
};

function HeureOuverture({ isActive, setPeriod, period }: HourType) {
    const { t } = useTranslation();
    const [render, setRender] = useState("");

    const indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    const { principalIndex } = indexPeriodCopyPrincipal;

    const Allday: string = t("The whole week");
    const numberInput = /^[0-9\b]+$/;

    function VerifyAllDayy(NameDay: string, value: any, Field: string) {
        const indexAllDay = period[principalIndex].Day.findIndex(
            (element: any) => element.name === Allday
        );

        if (
            Allday === NameDay &&
            period[principalIndex].Day[indexAllDay].visibility === true
        ) {
            period[principalIndex].Day.map((item: any, index: number) => {
                item.Hour.map((element: any, indice: number) => {
                    switch (Field) {
                        case "HeureDebut":
                            handlechangeEvent(
                                indice,
                                value,
                                index,
                                "HeureDebut"
                            );
                            return element;

                        case "HeureFin":
                            handlechangeEvent(indice, value, index, "HeureFin");
                            return element;

                        case "Price":
                            handlechangeEvent(indice, value, index, "Price");
                            return element;
                        default:
                            return element;
                    }
                });
                return item;
            });
        } else {
            period[principalIndex].Day[indexAllDay].visibility = false;

            handlechangeEvent(0, "", indexAllDay, "All");
            return period[principalIndex].Day;
        }
    }

    function handlechangeEvent(
        indexData: any,
        Value: any,
        indexday: number,
        actionType: string
    ) {
        setPeriod((prevState: any) => {
            const newState = [...prevState];

            function NewHeure() {
                const selectedHeure = {
                    ...newState[principalIndex].Day[indexday].Hour[indexData],
                };

                switch (actionType) {
                    case ACTIONS.START_TIME:
                        selectedHeure.HeureDebut = Value;
                        return selectedHeure;
                    case ACTIONS.END_TIME:
                        selectedHeure.HeureFin = Value;
                        return selectedHeure;
                    case ACTIONS.PRICE:
                        selectedHeure.Price = Value;
                        return selectedHeure;
                    case ACTIONS.ALL:
                        selectedHeure.Price = "";
                        selectedHeure.HeureFin = "";
                        selectedHeure.HeureDebut = "";
                        period[principalIndex].Day[indexday].Hour = [
                            { HeureDebut: "", HeureFin: "", Price: "" },
                        ];
                        return selectedHeure;
                    default:
                        return selectedHeure;
                }
            }

            const updatedHeure = NewHeure();

            const newArr = [...newState[principalIndex].Day[indexday].Hour];
            newArr[indexData] = updatedHeure;

            newState[principalIndex].Day[indexday] = {
                ...newState[principalIndex].Day[indexday],
                Hour: newArr,
            };
            period[principalIndex] = newState[principalIndex];

            return newState;
        });
    }

    return (
        <fieldset className="fieldset__clz scrollAdd__clz">
            <legend style={{ marginLeft: "10px" }} className="w-auto">
                <StyledLabel className="text-uppercase">
                    {t("CHOOSE THE TIMETABLE")}
                </StyledLabel>
            </legend>

            <div className="fieldsetInterne__clz  ml-2 mr-2  mb-2">
                {period[principalIndex].Day.map((item: any, indice: number) => {
                    return (
                        <div
                            className=" ml-2 mr-2  mb-2 mt-2"
                            style={{
                                border: "1px solid #dedede",

                                borderRadius: "5px",

                                opacity: "1",
                            }}
                            key={shortid.generate()}
                        >
                            <div className="container">
                                <div className=" row-12 d-flex  ml-2 mr-2 mt-2 mb-2  align-items-center">
                                    <div
                                        className=" col-3 d-flex   justify-content-start "
                                        style={{ paddingLeft: "0px" }}
                                    >
                                        <div className="mr-3">
                                            <SwitchVisibility
                                                data={item.name}
                                                setRender={setRender}
                                                indiceData={indice}
                                                isActive={isActive}
                                                setPeriod={setPeriod}
                                                period={period}
                                            />
                                        </div>
                                        <StyledLabel
                                            style={{
                                                width: "max-content",
                                            }}
                                        >
                                            {t(item.name)}
                                        </StyledLabel>
                                    </div>

                                    <div
                                        className="  col-7 ml-2 mr-2 pt-2 pb-2    "
                                        style={{
                                            border: "1px solid #dedede",

                                            borderRadius: "5px",

                                            opacity: "1",
                                        }}
                                    >
                                        {item.Hour.map(
                                            (element: any, index: number) => {
                                                return (
                                                    <div
                                                        className="d-flex justify-content-center align-items-center mb-2"
                                                        key={shortid.generate()}
                                                    >
                                                        <Input
                                                            id={item.id}
                                                            name={item.name}
                                                            placeholder=""
                                                            type="time"
                                                            value={
                                                                element.HeureDebut
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    item.visibility ===
                                                                    true
                                                                ) {
                                                                    handlechangeEvent(
                                                                        index,
                                                                        e.target
                                                                            .value,
                                                                        indice,
                                                                        "HeureDebut"
                                                                    );
                                                                    VerifyAllDayy(
                                                                        item.name,
                                                                        e.target
                                                                            .value,
                                                                        "HeureDebut"
                                                                    );

                                                                    setRender(
                                                                        `${item.name} ${index} ${element.HeureDebut} ${render} Change HeureDebut`
                                                                    );
                                                                }
                                                            }}
                                                            style={{
                                                                width: "95px",
                                                            }}
                                                            disabled={
                                                                isActive ===
                                                                false
                                                            }
                                                        />
                                                        <StyledLabel className="ml-2 mr-2">
                                                            {t("To")}
                                                        </StyledLabel>
                                                        <Input
                                                            id={item.id}
                                                            name={item.name}
                                                            placeholder=""
                                                            type="time"
                                                            value={
                                                                element.HeureFin
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    item.visibility ===
                                                                    true
                                                                ) {
                                                                    handlechangeEvent(
                                                                        index,
                                                                        e.target
                                                                            .value,
                                                                        indice,
                                                                        "HeureFin"
                                                                    );
                                                                    VerifyAllDayy(
                                                                        item.name,
                                                                        e.target
                                                                            .value,
                                                                        "HeureFin"
                                                                    );

                                                                    setRender(
                                                                        `${item.name} ${index} ${element.HeureFin} Change HeureFin`
                                                                    );
                                                                }
                                                            }}
                                                            style={{
                                                                width: "95px",
                                                            }}
                                                            disabled={
                                                                isActive ===
                                                                false
                                                            }
                                                        />
                                                        <StyledLabel className="ml-2 mr-1">
                                                            {t("Price")}
                                                        </StyledLabel>
                                                        <Input
                                                            id={item.id}
                                                            name={item.name}
                                                            placeholder=""
                                                            type="text"
                                                            value={
                                                                element.Price
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    item.visibility ===
                                                                        true &&
                                                                    (numberInput.test(
                                                                        e.target
                                                                            .value
                                                                    ) ||
                                                                        e.target
                                                                            .value ===
                                                                            "")
                                                                ) {
                                                                    handlechangeEvent(
                                                                        index,
                                                                        e.target
                                                                            .value,
                                                                        indice,
                                                                        "Price"
                                                                    );

                                                                    VerifyAllDayy(
                                                                        item.name,
                                                                        e.target
                                                                            .value,
                                                                        "Price"
                                                                    );

                                                                    setRender(
                                                                        `${item.name} ${index} ${element.Price} Change Price`
                                                                    );
                                                                }
                                                            }}
                                                            style={{
                                                                width: "70px",
                                                            }}
                                                            disabled={
                                                                isActive ===
                                                                false
                                                            }
                                                        />

                                                        <ModifyHour
                                                            setPeriod={
                                                                setPeriod
                                                            }
                                                            period={period}
                                                            ValueRender={`${item.name} ${index} ${item.Hour.length}`}
                                                            indexDay={indice}
                                                            indexHour={index}
                                                            setRender={
                                                                setRender
                                                            }
                                                            visibility={
                                                                item.visibility
                                                            }
                                                            NameOfDay={
                                                                item.name
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <AddHour
                                        setPeriod={setPeriod}
                                        period={period}
                                        ValueRender={`${item.name}+${item.Hour.length}`}
                                        NameOfDay={item.name}
                                        setRender={setRender}
                                        visiblity={item.visibility}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </fieldset>
    );
}

export default HeureOuverture;
