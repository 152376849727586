import React from "react";

import { useSelector } from "react-redux";

import { DynamicTableWrapperContext } from "@components/Common/";
import { ACTIONS } from "@helpers/actions";
import { RootState } from "@store/.";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import CategoriesIcon from "@components/Common/SvgIcons/CategoriesIconMinimal";

import "../Style.css";

type CategoryHeaderPropsType = {
    dispatchCategory: Function;
    setDefaultParentCategory: Function;
    setselectedLinkCategories: Function;
};
export default function AddCategoryHeader({
    dispatchCategory,
    setDefaultParentCategory,
    setselectedLinkCategories,
}: CategoryHeaderPropsType): JSX.Element {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const defaultVisibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );

    const updateItemWorkflow = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const oldValue = useSelector(
        (state: RootState) => state.editObserverSlice.oldValue
    );

    const handleRefreshIconClick = (): void => {
        if (updateItemWorkflow.pathUpdateItem.length > 0) {
            dispatchCategory({
                type: ACTIONS.EDIT,
                payload: oldValue,
            });
        } else {
            dispatchCategory({
                type: ACTIONS.RESET,
                payload: {},
            });

            dispatchCategory({
                type: ACTIONS.INIT_VISIBILITY,
                payload: defaultVisibility,
            });
            setDefaultParentCategory({});
            setselectedLinkCategories([]);
            dynamicTableWrapperContext?.setDataUpdated(true);
        }
    };

    return (
        <StyleCardHeader
            title="Category"
            refrechIconFunction={handleRefreshIconClick}
            Icon={
                <CategoriesIcon
                    width={31}
                    height={31}
                    style={{
                        borderRadius: "100%",
                    }}
                    fill="#9E1470"
                />
            }
        />
    );
}
