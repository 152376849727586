import { DocumentIcon, TrashIcon, FolderIcon } from "@aureskonnect/react-ui";
import React from "react";
import { copyHourTable } from "@store/features";
import { RootState } from "@store/index";
import { useDispatch, useSelector } from "react-redux";

export type ModifyHourType = {
    setPeriod: React.Dispatch<any>;
    period: any;
    ValueRender: string;
    indexDay: number;
    indexHour: number;
    setRender: React.Dispatch<React.SetStateAction<string>>;
    visibility: boolean;
    NameOfDay: string;
};

export default function ModifyHour({
    setPeriod,
    period,
    ValueRender,
    indexDay,
    indexHour,
    setRender,
    visibility,
    NameOfDay,
}: ModifyHourType) {
    const indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    const { principalIndex } = indexPeriodCopyPrincipal;
    const dispatch = useDispatch();
    let ListItems: any = useSelector(
        (State: RootState) => State.copyHourSlice.value
    );
    const DeleteIndex = period[principalIndex].Day.findIndex(
        (element: any) => element.name === NameOfDay
    );

    function CopyPasteDelete(action: string) {
        switch (action) {
            case "Copy":
                ListItems =
                    period[principalIndex].Day[indexDay].Hour[indexHour];
                dispatch(copyHourTable(ListItems));

                return ListItems;
            case "Paste":
                if (
                    ListItems.HeureDebut.length !== 0 ||
                    ListItems.HeureFin.length !== 0 ||
                    ListItems.Price.length !== 0
                ) {
                    setPeriod((prevState: any) => {
                        const newState = [...prevState];

                        const selectedDate: any = {};
                        selectedDate.HeureDebut = ListItems.HeureDebut;
                        selectedDate.HeureFin = ListItems.HeureFin;
                        selectedDate.Price = ListItems.Price;

                        const newArr = [
                            ...newState[principalIndex].Day[indexDay].Hour,
                        ];
                        newArr[indexHour] = selectedDate;

                        newState[principalIndex].Day[indexDay] = {
                            ...newState[principalIndex].Day[indexDay],
                            Hour: newArr,
                        };

                        return newState;
                    });
                }
                return period;
            case "Delete":
                if (
                    DeleteIndex !== -1 &&
                    period[principalIndex].Day[DeleteIndex].Hour.length > 1
                ) {
                    setPeriod((prevState: any) => {
                        const newState = [...prevState];

                        const newArr = [
                            ...newState[principalIndex].Day[DeleteIndex].Hour,
                        ];
                        newArr.splice(indexHour, 1);

                        newState[principalIndex].Day[DeleteIndex] = {
                            ...newState[principalIndex].Day[DeleteIndex],
                            Hour: newArr,
                        };

                        return newState;
                    });
                }
                break;
            default:
        }
    }
    return (
        <React.Fragment>
            <FolderIcon
                height={20}
                width={20}
                className="ml-2 mr-1"
                onClick={() => {
                    if (visibility === true) {
                        CopyPasteDelete("Paste");
                        setRender(`${ValueRender} Paste Date`);
                    }
                }}
            />
            <DocumentIcon
                height={20}
                width={20}
                onClick={() => {
                    CopyPasteDelete("Copy");
                }}
            />
            <TrashIcon
                height={20}
                width={20}
                className="mr-1"
                onClick={() => {
                    CopyPasteDelete("Delete");
                    setRender(`${NameOfDay} ${ValueRender} Delete hour`);
                }}
                style={{
                    color: "#FF1122",
                }}
            />
        </React.Fragment>
    );
}
