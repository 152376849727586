import React from "react";

import { PlusIcon } from "@aureskonnect/react-ui";
import { Button } from "react-bootstrap";
import { RootState } from "@store/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export type AddHourType = {
    setPeriod: React.Dispatch<any>;
    period: any;
    ValueRender: string;
    NameOfDay: string;
    setRender: React.Dispatch<React.SetStateAction<string>>;
    visiblity: boolean;
};

export default function AddHour({
    setPeriod,
    period,
    ValueRender,
    NameOfDay,
    setRender,
    visiblity,
}: AddHourType) {
    const { t } = useTranslation();
    const indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    const { principalIndex } = indexPeriodCopyPrincipal;
    function handleAddHour() {
        setPeriod((prevState: any) => {
            const newState = [...prevState];
            const index = period[principalIndex].Day.findIndex(
                (element: any) => element.name === NameOfDay
            );
            const selectedDate: any = {};
            selectedDate.HeureDebut = "";
            selectedDate.HeureFin = "";
            selectedDate.Price = "";

            const newArr = [...newState[principalIndex].Day[index].Hour];
            newArr.push(selectedDate);

            newState[principalIndex].Day[index] = {
                ...newState[principalIndex].Day[index],
                Hour: newArr,
            };

            return newState;
        });
    }
    return (
        <div className="col-2 d-flex justify-content-end align-items-center">
            <div>
                <PlusIcon
                    height={15}
                    width={15}
                    onClick={() => {
                        if (visiblity === true) {
                            handleAddHour();
                            setRender(ValueRender);
                        }
                    }}
                />
            </div>
            <div>
                <Button
                    variant="link"
                    className="w-auto"
                    onClick={() => {
                        if (visiblity === true) {
                            handleAddHour();
                            setRender(ValueRender);
                        }
                    }}
                    style={{
                        color: "#323333",
                        textDecoration: "underline",
                    }}
                >
                    {t("to add")}
                </Button>
            </div>
        </div>
    );
}
