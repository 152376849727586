/* eslint-disable no-unused-vars */
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { AvField } from "availity-reactstrap-validation";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { filterNameWithAlphabeticRank } from "@helpers/general";
import { DynamicTableWrapperContext } from "@components/Common";
import { RootState } from "@store/index";

export const initialParentCategory = {
    label: "",
    value: "",
};

type ParentCategoriesProps = {
    type: string;
    allParentsCategory: categoryLiaisonType[];
    FilteredCategories?: categoryLiaisonType[];
    selectedLinkCategories: string[];
    dispatch: React.Dispatch<ReducerActionType>;
    defaultParentCategory: { label: string; value: string };
    setDefaultParentCategory: React.Dispatch<
        React.SetStateAction<categoryLiaisonType>
    >;
    isRequired: Boolean;
    isDisabled?: Boolean;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
};
export default function ParentCategories({
    type,
    allParentsCategory,
    FilteredCategories,
    selectedLinkCategories,
    defaultParentCategory,
    setDefaultParentCategory,
    dispatch,
    isRequired,
    isDisabled,
    addToRefs,
}: ParentCategoriesProps) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const ETKType = localStorage.getItem("ETKType");
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const handleCategorieParent = (selectedValue: any): void => {
        setDefaultParentCategory(selectedValue === null ? "" : selectedValue);
        dispatch({
            type: ACTIONS.PARENTS_CATEGORIES,
            payload: selectedValue ? selectedValue.value : "",
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.CATEGORYPARENT));
    };
    const link: string[] =
        dynamicTableWrapperContext?.rowData !== null
            ? dynamicTableWrapperContext?.rowData?.data[0]
                  ?.linkedChildCategories ?? []
            : [];

    const filterParentCategories = () => {
        let filteredCategories = [];
        if (ETKType === "3" && type === "category") {
            filteredCategories = FilteredCategories
                ? FilteredCategories.filter(
                      (category): Boolean =>
                          category.value !== defaultParentCategory?.value
                  )
                      .filter(
                          (cat) => !selectedLinkCategories.includes(cat.value)
                      )
                      .filter((cat) => !link.includes(cat.value))
                      .sort(filterNameWithAlphabeticRank)
                : [];
            return filteredCategories;
        }
        filteredCategories = allParentsCategory
            ? allParentsCategory
                  .filter(
                      (category): Boolean =>
                          category.value !== defaultParentCategory?.value
                  )
                  .filter((cat) => !selectedLinkCategories.includes(cat.value))
                  .filter((cat) => !link.includes(cat.value))
                  .sort(filterNameWithAlphabeticRank)
            : [];
        return filteredCategories;
    };
    const sortedallParentsCategory = allParentsCategory.sort(
        (previousCat, nextCat) => {
            const previousCatlabel = previousCat.label.toLowerCase();
            const nextCatlabel = nextCat.label.toLowerCase();

            if (previousCatlabel < nextCatlabel) {
                return -1;
            }
            if (previousCatlabel > nextCatlabel) {
                return 1;
            }
            return 0;
        }
    );

    return (
        <div className="py-2" id="category parent" ref={addToRefs}>
            <StyledLabel htmlFor="categorie-parente">
                {t("Parent category")} {isRequired && "*"}
            </StyledLabel>

            <StyledSelectInput
                // eslint-disable-next-line react/no-unstable-nested-components
                noOptionsMessage={() => (
                    <React.Fragment>{t("No options")}</React.Fragment>
                )}
                name="categorie-parente"
                placeholder={t("Select")}
                value={
                    defaultParentCategory && defaultParentCategory.label === ""
                        ? t("Choose")
                        : defaultParentCategory
                }
                options={
                    sortedallParentsCategory ? filterParentCategories() : []
                }
                onChange={handleCategorieParent}
                isClearable={!!defaultParentCategory?.value}
                isDisabled={
                    updateItemWorkflow.pathUpdateItem.length > 0 ||
                    (isDisabled && true)
                    // ||
                    // (isEditing &&
                    //     cardType === "shop" &&
                    //     (cardStatus === "En attente" ||
                    //         cardStatus === "En production"))
                }
                id="parent-category-select"
            />

            <AvField
                name="categorie-parente"
                value={defaultParentCategory?.label ?? ""}
                className="d-none"
                autoComplete="off"
                validate={{
                    required: {
                        value: isRequired,
                        errorMessage: t("This field is required"),
                    },
                }}
            />
        </div>
    );
}
