import React from "react";

import { Container } from "reactstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";

import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { LoadingDataAnimation } from "@components/Common";
import { useTranslation } from "react-i18next";
import { RootState } from "@store/.";
import CategoriesList from "./CategoriesList";
import AddCategory from "./AddCategory";

export default function Categories() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    const [initialData, setInitialData] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        return () => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <>
            <PageTitle title="Categories" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE ",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },
                        { item: t("Categories".toUpperCase()), link: "#" },
                    ]}
                />

                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is essential to have categories because they allow you to group together similar products. For a better result, it is preferable to add an image for the different categories."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={
                            <CategoriesList setInitialData={setInitialData} />
                        }
                        ActionColumnComponent={
                            <AddCategory
                                setIsLoading={setIsLoading}
                                initialDataCategory={initialData}
                            />
                        }
                    />
                </Container>
            </motion.div>
        </>
    );
}
