import React from "react";

import axios from "axios";

import { ErrorToast } from "@components/Common";

export default function useParentsCategory(cardIuud: any, expectCardIuud: any) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const [allParentsCategory, setAllParentsCategory] = React.useState<any[]>(
        []
    );
    React.useEffect(() => {
        (async () => {
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}/cards/${schemaAdbFranchise}/${cardIuud}/parents-categories`
                )
                .then((response) => {
                    setAllParentsCategory(
                        expectCardIuud
                            ? response.data.filter(
                                  (cat: any) => cat.value !== expectCardIuud
                              )
                            : response.data
                    );
                })
                .catch(() => {
                    ErrorToast("il y a une erreur parents category");
                });
        })();
    }, [expectCardIuud, cardIuud, schemaAdbFranchise]);

    return allParentsCategory;
}
