import React from "react";

import { VatRateContext } from "@pages/Catalogue/Items/addItem/Price";
import { generateItemPriceUuid } from "@helpers/general";

export default function useUpdateAdvancedPriceStructure() {
    const vatRateContext = React.useContext(VatRateContext);
    React.useEffect(() => {
        const updatedAdvancedPrice: Record<string, Price> = JSON.parse(
            JSON.stringify(vatRateContext.advancedPriceCopy)
        );
        vatRateContext.salesModes.forEach((saleMode) => {
            saleMode.saleSupports.forEach((saleSupport) => {
                const advancedPriceUuid = generateItemPriceUuid([
                    saleMode.id,
                    saleSupport,
                ]);
                if (updatedAdvancedPrice[advancedPriceUuid]) {
                    if (
                        updatedAdvancedPrice[advancedPriceUuid]
                            .originalKeyElements === undefined
                    ) {
                        updatedAdvancedPrice[advancedPriceUuid] = {
                            ...updatedAdvancedPrice[advancedPriceUuid],
                            originalKeyElements: [saleMode.id, saleSupport],
                        };
                    }
                }
            });
        });
        vatRateContext.setAdvancedPriceCopy(updatedAdvancedPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
