import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { RootState } from "@store/index";
import { VatRateContext } from "..";

type VatRateSelectInputPropTypes = {
    handleVatRateChange: Function;
    selectedVatRate: {
        label: string;
        value: string;
    };
    isDisabled?: boolean;
};

export default function VatRateSelectInput({
    handleVatRateChange,
    selectedVatRate,
    isDisabled = false,
}: VatRateSelectInputPropTypes) {
    const { t } = useTranslation();
    const vatRateContext = React.useContext(VatRateContext);
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    return (
        <StyledSelectInput
            placeholder={t("Select")}
            name="vatRates"
            options={vatRateContext.vatRates}
            menuPlacement="auto"
            value={isDisabled ? { label: "", value: "" } : selectedVatRate}
            onChange={(e: any) => {
                handleVatRateChange(Number(e.value));
            }}
            isDisabled={
                isDisabled ||
                (isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production")
            }
        />
    );
}
