import React, { useEffect, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";

type CardModalProps = {
    isOpen: boolean;
    setIsOpen: Function;
    rowData: any;
    setIsLoading: Function;
    shopCards: any;
};
export default function DeleteCard({
    isOpen,
    setIsOpen,
    rowData,
    setIsLoading,
    shopCards,
}: CardModalProps) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [cardToDuplicate, setcardToDuplicate] = useState<string>("");
    function handleClick(isSucees: boolean) {
        setIsLoading(true);
        setIsOpen(!isOpen);
        setIsLoading(false);
        if (isSucees) {
            SuccessToast(t("Card deleted successfully"));
            dynamicTableWrapperContext?.setDataUpdated(true) &&
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
        } else {
            setIsLoading(false);
            ErrorToast(t(`There is a mistake!`));
        }
    }

    const handleRemoveButtonOnClickEvent = () => {
        const api = axios.create({
            baseURL: `${process.env.REACT_APP_ADB_API_URL}`,
        });
        try {
            if (cardToDuplicate.length > 0) {
                api.delete(
                    `${schemaAdbFranchise}/deletePendingCard/${rowData.selectedRow.original.iuud}/${cardToDuplicate}`
                )
                    .then(() => {
                        handleClick(true);
                        if (rowData?.selectedRow?.original.iuud === cardIuud) {
                            localStorage.removeItem("selectedCard");
                        }
                    })
                    .catch(() => {
                        handleClick(false);
                        ErrorToast(t("There is a mistake!"));
                    });
            }

            api.delete(
                `${schemaAdbFranchise}/deletecard/${rowData.selectedRow.original.iuud}`
            )
                .then((data: any): void => {
                    if (data.data === "card in production") {
                        setIsOpen(!isOpen);
                        setIsLoading(false);
                        ErrorToast(
                            t(
                                "L'archivage d'une carte qui contient des cartes boutiques en production n'est pas possible"
                            )
                        );
                    } else {
                        handleClick(true);
                        if (rowData?.selectedRow?.original.iuud === cardIuud) {
                            localStorage.removeItem("selectedCard");
                        }
                    }
                })
                .catch(() => {
                    handleClick(false);
                    ErrorToast(t("There is a mistake!"));
                });
        } catch (error: unknown) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (Array.isArray(shopCards)) {
            const shopCardsInProduction = shopCards.filter((el) => {
                return el.data.status === "En production";
            });

            setcardToDuplicate([...shopCardsInProduction][0].iuud);
        }
    }, [shopCards]);

    return (
        <div>
            <Modal centered isOpen={isOpen}>
                <ModalHeader toggle={toggleOpen}>
                    <StyledH2 className="text-uppercase">
                        {t("ARCHIVAGE")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    {cardToDuplicate.length > 0
                        ? t(
                              "L'archivage de cette carte créera une copie de la carte de production existante Voulez-vous continuer ?"
                          )
                        : t("you will archive a master card")}
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <StyledButton
                        onClick={toggleOpen}
                        rounded
                        variant="light"
                        outline
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        onClick={handleRemoveButtonOnClickEvent}
                        rounded
                        variant={dominantColor}
                    >
                        {t("Archive")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}
