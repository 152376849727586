import { ACTIONS } from "@helpers/actions";
import { DEFAULT_LANGUAGE, SELLING_SUPPORT } from "@constants/.";

export const initialStateCategory: CategoryType = {
    title: "",
    reference: "",
    idCard: [],
    categoryParent: "",
    categoryLiaison: [],
    categoryChild: [],
    linkedTags: [],
    linkedItems: [],
    linkedChildCategories: [],
    items: [],
    color: "#FFFFFF",
    description: {
        Default: {
            nameDefault: "",
            salesSupport: [],
            impression: [],
        },
    },
    displayName: {
        Default: {
            nameDefault: "",
            salesSupport: SELLING_SUPPORT,
            impression: DEFAULT_LANGUAGE,
        },
    },
    ranks: { default: 0, orderOverride: [] },
    imageUrl: {
        Default: {
            urlDefault: "",
            salesSupport: [],
        },
        override: [
            { shopId: "" },

            {
                info: [],
                salesSupport: [],
            },
        ],
    },
    visibilityInfo: {
        default: {},
        basicCompositionVisibility: true,
        isVisible: true,
    },
    archive: false,
    video: {
        url: "",
        type: "",
    },
};

export const reducerCategory = (
    state: CategoryType,
    action: ReducerActionType
): CategoryType => {
    switch (action?.type) {
        case ACTIONS.TITLE:
            return { ...state, title: action.payload };
        case ACTIONS.REFERENCE:
            return { ...state, reference: action.payload };

        case ACTIONS.COLOR:
            return { ...state, color: action.payload };
        case ACTIONS.PARENTS_CATEGORIES:
            return { ...state, categoryParent: action.payload };
        case ACTIONS.LINK_CATEGORIES:
            return { ...state, categoryLiaison: action.payload };
        case ACTIONS.LINK_TAGS:
            return { ...state, linkedTags: action.payload };
        case ACTIONS.DEFAULT_DISPLAY_NAME:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName?.Default,
                        nameDefault: action.payload,
                    },
                },
            };

        case ACTIONS.DISPLAY_NAME:
            return {
                ...state,
                displayName: action.payload,
            };

        case ACTIONS.DISCRIPTION:
            return {
                ...state,
                description: action.payload,
            };
        case ACTIONS.DEFAULT_DISCRIPTION:
            return {
                ...state,
                description: {
                    ...state.description,
                    Default: {
                        ...state.description?.Default,
                        nameDefault: action.payload,
                    },
                },
            };
        case ACTIONS.DEFAULT_RANK:
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    default: action.payload,
                },
            };
        case ACTIONS.VISIBILITY_INFO:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    default: {
                        ...state.visibilityInfo?.default,
                        [action.payload.salesMode]: action.payload.data,
                    },
                },
            };
        case ACTIONS.IS_VISIBLE:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    isVisible: action.payload,
                },
            };
        case ACTIONS.BASIC_COMPOSITION_VISIBILITY:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    basicCompositionVisibility: action.payload,
                },
            };

        case ACTIONS.DEFAULT_IMAGE_URL:
            return {
                ...state,
                imageUrl: {
                    ...state.imageUrl,
                    Default: {
                        ...state.imageUrl.Default,
                        urlDefault: action.payload,
                    },
                },
            };

        case ACTIONS.SALES_SUPPORT_IMAGE_URL:
            return {
                ...state,
                imageUrl: {
                    ...state.imageUrl,
                    Default: {
                        ...state.imageUrl.Default,
                        salesSupport: action.payload,
                    },
                },
            };

        case ACTIONS.RESET:
            return initialStateCategory;

        case ACTIONS.INIT_VISIBILITY:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    default: action.payload,
                },
            };
        case ACTIONS.INIT_SELLING_SUPPORT:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName.Default,
                        salesSupport: action.payload,
                    },
                },
                description: {
                    ...state.description,
                    Default: {
                        ...state.description.Default,
                        salesSupport: action.payload,
                    },
                },
            };
        case ACTIONS.INIT_IMPRESSION:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName.Default,
                        impression: action.payload,
                    },
                },
                description: {
                    ...state.description,
                    Default: {
                        ...state.description.Default,
                        impression: action.payload,
                    },
                },
            };
        case ACTIONS.VIDEO:
            return { ...state, video: action.payload };
        case ACTIONS.EDIT:
            return action.payload;
        default:
            return state;
    }
};
