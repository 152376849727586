import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { DominantColor, ErrorToast, SuccessToast } from "@components/Common/";
import { RootState } from "@store/index";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";

export type WorkFlowGlobalResetProps = {
    setIsUnsavedChangesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    resetGlobalWorkflow: WorkFlowResetProps;
    setResetGlobalWorkflow: React.Dispatch<
        React.SetStateAction<WorkFlowResetProps>
    >;
};
export default function WorkFlowFooter({
    setIsUnsavedChangesModalOpen,
    resetGlobalWorkflow,
    setResetGlobalWorkflow,
}: WorkFlowGlobalResetProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const dispatch = useDispatch();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const saveWorkflow = () => {
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/workflow`,
                {
                    workflow: globalWorkflowData,
                    modifier: modifierWorkflow,
                }
            )
            .then((): void => {
                SuccessToast(t("Workflow saved successfully"));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    };

    const resetWorkflow = () => {
        const firstWorkflow: any = [
            {
                title: "Ma carte",
                data: globalWorkflowData,
                type: WORKFLOW_LIST.CATEGORIES,
                previousUuids: [],
                override: {},
                multipleStep: {
                    isMultiple: false,
                    listeUuidItem: [],
                },
                selected: "",
                previousState: {},
            },
        ];
        setResetGlobalWorkflow({
            modifier: modifierWorkflow,
            WorkflowData: globalWorkflowData,
            GlobalWorkflow: [...firstWorkflow],
        });
        dispatch(setWorkflow(firstWorkflow));
    };

    const compareOldAndNewJson = () => {
        JSON.stringify(resetGlobalWorkflow.modifier) !==
            JSON.stringify(modifierWorkflow) ||
        JSON.stringify(resetGlobalWorkflow.WorkflowData) !==
            JSON.stringify(globalWorkflowData)
            ? setIsUnsavedChangesModalOpen(true)
            : setIsUnsavedChangesModalOpen(false);
    };

    return (
        <Card
            id="headercompo"
            className=" my-1 "
            style={{ height: "65px", width: "100%" }}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div className="mt-2 ml-2">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        style={{
                            // visibility: count > 0 ? "visible" : "hidden",
                            visibility: "hidden",
                        }}
                        onClick={() => {}}
                    >
                        Enregistrer parcours
                    </StyledButton>
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center">
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            compareOldAndNewJson();
                        }}
                        disabled={multipleWorkflow.length > 0}
                    >
                        {t("Cancel")}
                    </StyledButton>

                    <div className="ml-3 mr-2">
                        <StyledButton
                            rounded
                            variant={dominantColor}
                            onClick={() => {
                                saveWorkflow();
                                resetWorkflow();
                            }}
                            disabled={
                                (cardType === "shop" &&
                                    cardStatus === "En production") ||
                                multipleWorkflow.length > 0
                            }
                        >
                            {t("Validate")}
                        </StyledButton>
                    </div>
                </div>
            </div>
        </Card>
    );
}
