export function categorySize(
    category: CategoryWithChildrenType,
    collapsedCategoriesUuids: string[]
) {
    if (collapsedCategoriesUuids.includes(category.iuud)) {
        return 0;
    }
    let depth = category.items.length + category.childCategories.length;

    category.childCategories.forEach((category) => {
        depth += categorySize(category, collapsedCategoriesUuids);
    });

    return depth;
}
