import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@aureskonnect/react-ui";
import { RootState } from "@store/index";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import CameraIcon from "@components/Common/SvgIcons/CameraIcon";
import { ErrorToast } from "@components/Common";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { MULTI_EDIT_KEY } from "@helpers/actions";
import DeleteImageModal from "./DeleteImageModal";

type ImageUploaderPropsType = {
    uploadedImage: string | Blob;
    setUploadedImage: React.Dispatch<React.SetStateAction<string | Blob>>;
    acceptedFilesExtentions?: string[];
    id?: string;
    fileType?: "image" | "video" | "";
    setFileType: React.Dispatch<React.SetStateAction<"" | "image" | "video">>;
};

export default function ImageUploader({
    uploadedImage,
    setUploadedImage,
    acceptedFilesExtentions = ["image/png", "image/jpeg"],
    id = "",
    fileType,
    setFileType,
}: ImageUploaderPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [dragging, setDragging] = React.useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);

    function onFileChange(e: any) {
        if (e.target.files && e.target.files.length > 0) {
            if (!acceptedFilesExtentions.includes(e.target.files[0].type)) {
                ErrorToast("Only images are accepted");
            } else {
                setUploadedImage(e.target.files[0]);
                if (id === "video") {
                    isMultiModify &&
                        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.VIDEO));
                } else {
                    isMultiModify &&
                        dispatchStore(
                            addListKeytoEdit(MULTI_EDIT_KEY.IMAGEURL)
                        );
                }
            }
        }

        if (
            e.target.files[0].type === "image/png" ||
            e.target.files[0].type === "image/jpeg"
        ) {
            setFileType("image");
        } else {
            setFileType("video");
        }
    }

    const handleDelete = () => {
        setIsImageModalOpen(!isImageModalOpen);
    };

    return (
        <div
            className=" d-flex justify-content-center align-items-center"
            style={
                isEditing &&
                cardType === "shop" &&
                cardStatus === "En production"
                    ? {
                          pointerEvents: "none",
                          border: dragging ? "dashed grey 4px" : "unset",
                          borderRadius: "6px",
                          height: "100%",
                          width: "100%",
                          padding: "1rem",
                      }
                    : {
                          border: dragging ? "dashed grey 4px" : "unset",
                          borderRadius: "6px",
                          height: "100%",
                          width: "100%",
                          padding: "1rem",
                      }
            }
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setDragging(true);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    if (
                        !acceptedFilesExtentions.includes(
                            e.dataTransfer.files[0].type
                        )
                    ) {
                        ErrorToast("Only images are accepted");
                    } else {
                        setUploadedImage(e.dataTransfer.files[0]);
                        if (fileType === "video") {
                            isMultiModify &&
                                dispatchStore(
                                    addListKeytoEdit(MULTI_EDIT_KEY.VIDEO)
                                );
                        } else {
                            isMultiModify &&
                                dispatchStore(
                                    addListKeytoEdit(MULTI_EDIT_KEY.IMAGEURL)
                                );
                        }
                    }
                }

                if (
                    e.dataTransfer.files[0].type === "image/png" ||
                    e.dataTransfer.files[0].type === "image/jpeg"
                ) {
                    setFileType("image");
                } else {
                    setFileType("video");
                }
                setDragging(false);
            }}
        >
            {uploadedImage ? (
                <div
                    style={{
                        height: "100%",
                    }}
                >
                    <div
                        className=" d-flex justify-content-center align-items-center "
                        style={{
                            height: "77%",
                            overflow: "hidden",
                        }}
                    >
                        <label
                            htmlFor={`file-${id}`}
                            style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "90%",
                            }}
                            className=" d-flex justify-content-center align-items-center "
                        >
                            {fileType === "image" ? (
                                <img
                                    src={
                                        uploadedImage instanceof Blob
                                            ? URL.createObjectURL(uploadedImage)
                                            : uploadedImage
                                    }
                                    alt="default"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: "contain" }}
                                />
                            ) : (
                                <video
                                    muted
                                    autoPlay
                                    width="100%"
                                    height="100%"
                                    src={
                                        uploadedImage instanceof Blob
                                            ? URL.createObjectURL(uploadedImage)
                                            : uploadedImage
                                    }
                                />
                            )}

                            <input
                                type="file"
                                id={`file-${id}`}
                                name="imgCollection"
                                onChange={onFileChange}
                                style={{ display: "none" }}
                            />
                        </label>
                    </div>

                    <div className="mt-2 d-flex justify-content-center mx-auto ">
                        <LockIconWithToolTip
                            message={t(`Favorite`)}
                            type="icon"
                            lengthMax={0}
                            id="Favoris"
                        />
                        <img
                            id="Favoris"
                            src="star.svg"
                            alt="star"
                            width="23px"
                            height="23px"
                            style={{ cursor: "pointer" }}
                        />
                        <label htmlFor={`file-${id}`}>
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t(`Change`)}
                                id="remplacer"
                            />
                            <img
                                id="remplacer"
                                src="exchange.svg"
                                alt="exchange"
                                width="23px"
                                height="23px"
                                style={{ cursor: "pointer" }}
                            />

                            <input
                                type="file"
                                id={`file-${id}`}
                                name="imgCollection"
                                onChange={onFileChange}
                                style={{ display: "none" }}
                            />
                        </label>
                        <div id="supprimer">
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t(`Delete`)}
                                id="supprimer"
                            />
                            <TrashIcon
                                style={{ cursor: "pointer" }}
                                height={23}
                                width={23}
                                fill="red"
                                onClick={handleDelete}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <label
                    htmlFor={`file-${id}`}
                    style={
                        isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production"
                            ? { pointerEvents: "none", opacity: "0.4" }
                            : {
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                              }
                    }
                    className=" d-flex justify-content-center align-items-center "
                >
                    <CameraIcon
                        width="60%"
                        height="60%"
                        fill="#d7d8d9"
                        style={{ objectFit: "contain" }}
                    />
                    <input
                        type="file"
                        id={`file-${id}`}
                        name="imgCollection"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                    />
                </label>
            )}
            <DeleteImageModal
                isOpen={isImageModalOpen}
                setIsOpen={setIsImageModalOpen}
                setUploadedImage={setUploadedImage}
            />
        </div>
    );
}
