import React from "react";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { HeartIcon, StyledBreadcrumb } from "@aureskonnect/react-ui";
import { PageTitle } from "@components/Common";
import { DisplayName } from "./DisplayName";

import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
    filterName?: string;
    canFilter?: boolean;
};

function Languages() {
    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const franchiseId = localStorage.getItem("franchiseId") || "";
    const shopId = localStorage.getItem("shopId") || "";

    let url = `${process.env.REACT_APP_SETTINGS_API_URL}/settings/general/language?userId=106&franchiseId=${franchiseId}`;

    if (shopId !== "0" && shopId !== "") {
        url += `&shopId=${shopId}`;
    }

    const arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Langue"),
            //  eslint-disable-next-line
            customJsx: (e: any) => <NumberLangues e={e} />,
            filterName: "Langue",
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Display name"),
            //  eslint-disable-next-line
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: "display_name",
            canFilter: true,
        },

        {
            indexOFColumn: 6,
            columnName: t("Pays"),
            //  eslint-disable-next-line
            customJsx: (e: any) => <GetCountry e={e} />,
            filterName: "Pays",
            canFilter: true,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any) => (
                <div className="d-flex justify-content-around">
                    {e.selectedRow?.subRows === undefined ? (
                        <HeartIcon
                            height={25}
                            width={25}
                            name="heart"
                            fill={e.selectedRow.isFavorite ? "red" : "black"}
                            className="pointer__clz"
                        />
                    ) : null}
                </div>
            ),
        },
    ];

    // eslint-disable-next-line react/no-unstable-nested-components
    function GetCountry(e: any) {
        return (
            <React.Fragment>
                {" "}
                {e.e.selectedRow?.subRows !== undefined
                    ? e.e.selectedRow.pays
                    : e.e.selectedRow.Country}
            </React.Fragment>
        );
    }

    // eslint-disable-next-line react/no-unstable-nested-components
    function NumberLangues(e: any) {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz d-flex justify-content-center">
                        {e.e.selectedRow?.Boutique !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow.Language
                )}
            </React.Fragment>
        );
    }

    return (
        <div>
            <PageTitle title="Langues" />
            <StyledBreadcrumb
                items={[
                    {
                        item: "CATALOGUE ",
                        link: "home",
                    },
                    {
                        item: "CONFIGIRATION",
                        link: "cards",
                    },
                    { item: t("LANGUAGES"), link: "cards" },
                ]}
            />
            <HeaderComponent title={t("Please set your language...")} />
            <DynamicTable
                url={url}
                canResize
                canExpand
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                elevationTable={1}
                name="language"
            />
        </div>
    );
}

export default Languages;
