/* eslint-disable react/no-unstable-nested-components */
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PrintersTable from "@components/Common_DB/PrintersModal/PrintersTable";
import SvgNoData from "@components/Common/SvgIcons/SvgNoData";
import { RootState } from "@store/index";
import Uploader from "./Uploader";

import styles from "./AdvancedImage.module.css";

type TableImagePropsType = {
    setUpdatedAdvancedImages: React.Dispatch<
        React.SetStateAction<sellingSupportType[] | undefined>
    >;
    advancedImages: sellingSupportType[] | undefined;
};

type TableImageDataPropsType = {
    saleSupport: string;
    advancedImages: sellingSupportType[] | undefined;
    setUpdatedAdvancedImages: React.Dispatch<
        React.SetStateAction<sellingSupportType[] | undefined>
    >;
};

export default function TableImage({
    setUpdatedAdvancedImages,
    advancedImages,
}: TableImagePropsType) {
    const languages = useSelector(
        (state: RootState) => state.settingsSlice.languages
    );
    const salesSupports = useSelector(
        (state: RootState) => state.settingsSlice.saleSupports
    );
    const { t } = useTranslation();
    const imagesTableData: TableImageDataPropsType[] = [];

    const imagesTableColumns: ColumnDef<TableImageDataPropsType>[] = [
        {
            id: "language/saleSupport",
            header: () => (
                <div className={`${styles.cell_divided__clz} text-capitalize`}>
                    <div className={styles.cell_topRight__clz}>
                        {`${t("language")}s`}
                    </div>
                    <div className={styles.cell_bottomLeft__clz}>
                        {`${t("sales supports")}`}
                    </div>
                </div>
            ),
            cell: (props) => (
                <div
                    className="d-flex align-items-center justify-content-center
                 position-absolute w-100 h-100 font-weight-bold"
                >
                    {props.row.original.saleSupport}
                </div>
            ),
        },
    ];
    salesSupports.forEach((saleSupport) => {
        imagesTableData.push({
            saleSupport,
            advancedImages,
            setUpdatedAdvancedImages,
        });
    });

    languages.forEach((language) =>
        imagesTableColumns.push({
            id: language.Langue,
            header: () => (
                <div
                    className="d-flex align-items-center justify-content-center
                 position-absolute w-100 h-100 font-weight-bold"
                    style={{ top: 0, left: 0 }}
                >
                    {language.Langue}
                </div>
            ),
            cell: ImageUploaderCell,
        })
    );

    return languages.length === 0 || salesSupports.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <SvgNoData />
        </div>
    ) : (
        <div className={styles.advanced_image_table}>
            <PrintersTable
                tableColumns={imagesTableColumns}
                data={imagesTableData}
            />
        </div>
    );
}

function ImageUploaderCell({
    row,
    column,
}: CellContext<TableImageDataPropsType, unknown>) {
    return (
        <div
            style={{
                width: "150px",
                height: "150px",
                margin: "auto",
            }}
        >
            <Uploader
                language={column.id}
                salesSupport={row.original.saleSupport}
                advancedImages={row.original.advancedImages}
                setUpdatedAdvancedImages={row.original.setUpdatedAdvancedImages}
            />
        </div>
    );
}
