import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useTranslation } from "react-i18next";

import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/.";
import { filterNameWithAlphabeticRank } from "@helpers/general";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import {
    ValuesContainer,
    Value,
    XButton,
} from "@components/Common/StyledComponent";

type SelectStepItemPropsType = {
    itemSteps: string[];
    dispatch: React.Dispatch<ReducerActionType>;
    setStepIuud: Function;
    stepsList: any[];
};
export const initialStep = {
    label: "",
    value: "",
};
export default function SelectStepItem({
    dispatch,
    itemSteps,
    setStepIuud,
    stepsList,
}: SelectStepItemPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );

    const stepsOptions = stepsList.map((step: any) => {
        return { label: step.label, value: step.value };
    });

    const handleOnchangeItemStep = (selectedValue: any): void => {
        const updatedSteps = [...itemSteps, selectedValue?.value];
        setStepIuud(updatedSteps);
        dispatch({
            type: ACTIONS.STEP,
            payload: updatedSteps,
        });
        isMultiModify && dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.STEP));
    };

    const handleXButton = (e: React.MouseEvent<HTMLElement>, iuud: string) => {
        e.preventDefault();
        const updatedSteps = itemSteps.filter(
            (stepIuud): boolean => stepIuud !== iuud
        );
        setStepIuud(updatedSteps);
        dispatch({
            type: ACTIONS.STEP,
            payload: updatedSteps,
        });
    };

    return (
        <div className="py-2" id="step">
            <StyledLabel>{t("Step")}</StyledLabel>

            <StyledSelectInput
                id="Step"
                name="Step"
                placeholder={t("Select")}
                noOptionsMessage={() => "Pas d'option"}
                options={stepsOptions
                    .filter(
                        (step: any): any => !itemSteps?.includes(step.value)
                    )
                    .sort(filterNameWithAlphabeticRank)}
                value={t("Choose")}
                onChange={handleOnchangeItemStep}
                isDisabled={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                }
            />
            <div
                className="d-flex flex-wrap "
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                {stepsList.map(
                    ({ value, label }: any) =>
                        itemSteps.includes(value) && (
                            <ValuesContainer key={value}>
                                <Value>
                                    {label}
                                    <XButton
                                        onClick={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) => handleXButton(e, value)}
                                    >
                                        ✕
                                    </XButton>
                                </Value>
                            </ValuesContainer>
                        )
                )}
            </div>
        </div>
    );
}
