import axios from "axios";
import uuid from "uuid-by-string";

export function updateAdvancedImages(
    advancedImages: sellingSupportType[] | undefined,
    file: string,
    language: string,
    salesSupport: string
): sellingSupportType[] | undefined {
    if (advancedImages) {
        const sellingSupportIndex = advancedImages.findIndex(
            (image) => image.sellingSupport === salesSupport
        );
        if (sellingSupportIndex === -1) {
            advancedImages.push({
                sellingSupport: salesSupport,
                Langue: [
                    {
                        designation: language,
                        value: file,
                    },
                ],
            });
        } else {
            const imageByLanguageIndex = advancedImages[
                sellingSupportIndex
            ].Langue.findIndex(
                (imageByLanguage) => imageByLanguage.designation === language
            );
            if (imageByLanguageIndex === -1) {
                advancedImages[sellingSupportIndex].Langue.push({
                    designation: language,
                    value: file,
                });
            } else {
                advancedImages[sellingSupportIndex].Langue[
                    imageByLanguageIndex
                ].value = file;
            }
        }
    }
    return advancedImages;
}

export async function convertStepItemsObjectToArray(
    stepItems: Record<string, StepItemType>
): Promise<StepItemWithIuud[]> {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const stepItemsArray: StepItemWithIuud[] = [];
    const categoriesTitles: Record<string, string> = {};
    for (const [iuud, item] of Object.entries(stepItems)) {
        // eslint-disable-next-line
        const data = await axios.get(
            `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/item/${item.parentCategory}`
        );
        categoriesTitles[iuud] = data?.data?.title;
    }

    for (const [iuud, item] of Object.entries(stepItems)) {
        stepItemsArray.push({
            itemIuud: iuud,
            parentCategory: item.parentCategory,
            price: item.price,
            color: item.color,
            title: item.title,
            refrence: item.refrence,
            maxNbrOfChoicesByItems: item.maxNbrOfChoicesByItems,
            minNbrOfChoicesByItems: item.minNbrOfChoicesByItems,
            nbrOfChoicesWithItemPriceInStep:
                item.nbrOfChoicesWithItemPriceInStep,
            nbrOfChoicesWithspecialPrice: item.nbrOfChoicesWithspecialPrice,
            priceOfItemInStep: item.priceOfItemInStep,
            specialPrice: item.specialPrice,
            rank: item.rank,
            basicCompositionVisibility: item.basicCompositionVisibility,
            parentCategoryTitle: categoriesTitles[iuud],
            imageUrl: item.imageUrl,
            visibilityInfo: item.visibilityInfo,
            fidelity: item.fidelity,
        });
    }
    return stepItemsArray.sort((a: StepItemType, b: StepItemType) => {
        return a.rank - b.rank;
    });
}
export function filterNameWithAlphabeticRank(
    firstLabel: any,
    secondLabel: any
) {
    if (firstLabel.label?.toLowerCase() < secondLabel.label?.toLowerCase()) {
        return -1;
    }
    if (firstLabel.label?.toLowerCase() > secondLabel.label?.toLowerCase()) {
        return 1;
    }
    return 0;
}

export function generateItemPriceUuid(strings: string[]) {
    const lowercasedStrings = strings.map((element) => element.toLowerCase());
    const sorted = [...lowercasedStrings].sort((a, b) => a.localeCompare(b));
    const sortedStrings = sorted.join("");
    const sortedLetters = sortedStrings.split("").sort().join("");
    return uuid(sortedLetters + sortedStrings);
}
