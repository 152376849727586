import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    Value,
    XButton,
    ValuesContainer,
} from "@components/Common/StyledComponent";
import { RootState } from "@store/.";
import { filterNameWithAlphabeticRank } from "@helpers/general";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";

type AllergensSelectInputPropsType = {
    itemAllergens: string[];
    dispatch: Function;
    isRedirect: boolean;
};

export default function AllergensSelectInput({
    itemAllergens,
    dispatch,
    isRedirect,
}: AllergensSelectInputPropsType) {
    const dispatchStore = useDispatch();
    const { t } = useTranslation();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const allergensList = useSelector(
        (
            state: RootState
        ): {
            iuud: string;
            allergen: AllergenType;
        }[] => state.allergenSlice.allergens
    );
    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );
    const unfilteredSelectAllergens = allergensList
        .map(({ iuud, allergen }): { label: string; value: string } => ({
            label: allergen.title,
            value: iuud,
        }))
        .sort(filterNameWithAlphabeticRank);
    const handleXButton = (e: React.MouseEvent<HTMLElement>, iuud: string) => {
        e.preventDefault();
        const updatedAllergens = itemAllergens.filter(
            (allergenIuud): boolean => allergenIuud !== iuud
        );
        dispatch({
            type: ACTIONS.ALLERGENS,
            payload: updatedAllergens,
        });
        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.ALLERGENS));
    };
    const apdateAllergen = (e: any) => {
        const updatedAllergens = [...itemAllergens, e.value];
        dispatch({
            type: ACTIONS.ALLERGENS,
            payload: updatedAllergens,
        });
        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.ALLERGENS));
    };
    return (
        <div
            style={
                isRedirect
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : { opacity: "1" }
            }
        >
            <StyledSelectInput
                placeholder={t("Select")}
                name="allergens"
                options={unfilteredSelectAllergens.filter(
                    (allergen): boolean =>
                        !itemAllergens.includes(allergen.value)
                )}
                value={t("Choose")}
                onChange={apdateAllergen}
                isDisabled={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                }
            />
            <div
                className="d-flex flex-wrap "
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                {allergensList.map(
                    ({ iuud, allergen }) =>
                        itemAllergens.includes(iuud) && (
                            <ValuesContainer key={iuud}>
                                <Value>
                                    {allergen.title}
                                    <XButton
                                        onClick={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) => handleXButton(e, iuud)}
                                    >
                                        ✕
                                    </XButton>
                                </Value>
                            </ValuesContainer>
                        )
                )}
            </div>
        </div>
    );
}
