import React from "react";

import { useDispatch } from "react-redux";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { ACTIONS } from "@helpers/.";

import ImageUploader from "@components/Common_DB/ImageUploader";

type ImagePropsType = {
    dispatch: Function;
    video: { url: string | Blob; type: "image" | "video" | "" };
};

export default function VideoAndImageUploader({
    dispatch,
    video,
}: ImagePropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();

    const [uploadedVideo, setUploadedVideo] = React.useState<string | Blob>(
        video.url
    );

    const [fileType, setFileType] = React.useState<"image" | "video" | "">(
        video.type
    );

    React.useEffect(() => {
        dispatch({
            type: ACTIONS.VIDEO,
            payload: { url: uploadedVideo, type: fileType },
        });
    }, [uploadedVideo, dispatch, dispatchStore, fileType]);

    React.useEffect(() => {
        setUploadedVideo(video.url);
        setFileType(video.type);
    }, [video.url, video.type]);

    return (
        <div id="videoUploader">
            <div className="d-flex flex-row  justify-content-between bd-highlight pt-4">
                <StyledLabel>{t("Advertising banner")}</StyledLabel>
            </div>
            <div
                className="d-flex justify-content-center align-items-center p-2"
                style={{
                    border: "1px solid #C6C6C6",
                    width: "100%",
                    height: "240px",
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <ImageUploader
                    uploadedImage={uploadedVideo}
                    setUploadedImage={setUploadedVideo}
                    fileType={fileType}
                    setFileType={setFileType}
                    id="video"
                    acceptedFilesExtentions={[
                        "video/mp4",
                        "video/webm",
                        "image/png",
                        "image/jpeg",
                    ]}
                />
            </div>
        </div>
    );
}
