import React from "react";

import ReactSwitch from "react-switch";

type StepItemSalesModeVisibilityPropsType = {
    ItemModalVisibility: visibilityCategoryType;
    setItemModalVisibility: React.Dispatch<
        React.SetStateAction<visibilityCategoryType>
    >;
    salesMode: string;
};
export default function StepItemSalesModeVisibility({
    salesMode,
    ItemModalVisibility,
    setItemModalVisibility,
}: StepItemSalesModeVisibilityPropsType) {
    const handleVisibility = () => {
        setItemModalVisibility({
            ...ItemModalVisibility,
            default: {
                ...ItemModalVisibility.default,
                [salesMode]: {
                    ...ItemModalVisibility.default[salesMode],
                    visibility:
                        !ItemModalVisibility.default[salesMode].visibility,
                },
            },
        });
    };

    return (
        <div>
            <ReactSwitch
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={20}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={handleVisibility}
                checked={ItemModalVisibility?.default[salesMode]?.visibility}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={45}
                height={15}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!ItemModalVisibility.isVisible}
            />
        </div>
    );
}
