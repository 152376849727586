import React from "react";

import SvgNoData from "@components/Common/SvgIcons/SvgNoData";
import { GridOverlay } from "@mui/x-data-grid";

export default function customNoRowsOverlay() {
    return (
        <GridOverlay>
            <SvgNoData />
        </GridOverlay>
    );
}
