import React from "react";

import axios from "axios";
import shortid from "shortid";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import CardMenu from "@components/Common/CardMenu/CardMenu/.";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { CARD_MENU_LIST } from "@constants/cardMenu";
import { PageTitle } from "@components/index";
import { setDominantColor } from "@store/features/dominantColorSlice";
import { setSelectedCard } from "@store/features/CardSlice";
import { ErrorToast } from "@components/Common";

import "@components/Common/CardMenu/Cartestyle.css";

type CardElementsCountType = {
    categories: number;
    items: number;
    tags: number;
    workflows: number;
    steps: number;
    options: number;
    allergens: number;
};

export default function Home(): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    const [usedCard, setUsedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );
    const [cardCount, setCardCount] = React.useState<number>(0);
    const [cardElementsCount, setCardElementsCount] =
        React.useState<CardElementsCountType>({
            categories: 0,
            items: 0,
            tags: 0,
            workflows: 0,
            steps: 0,
            options: 0,
            allergens: 0,
        });
    dispatch(setDominantColor({ dominantColor: "#232323" }));

    function fillCardMenuNumber(title: string): number {
        switch (title) {
            case t("CHOOSE A CARD"):
                return cardCount;
            case t("CATEGORIES"):
                return cardElementsCount.categories;
            case t("ITEMS"):
                return cardElementsCount.items;
            case t("TAGS"):
                return cardElementsCount.tags;
            case t("WORKFLOW"):
                return cardElementsCount.workflows;
            case t("STEPS"):
                return cardElementsCount.steps;
            case t("OPTIONS"):
                return cardElementsCount.options;
            case t("ALLERGENES"):
                return cardElementsCount.allergens;
            default:
                return 0;
        }
    }

    const setSelectUniqueCard = React.useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`
            )
            .then((res): void => {
                setUsedCard(res.data.data[0]);

                localStorage.setItem(
                    "selectedCard",
                    JSON.stringify({
                        iuud: res.data.data[0].iuud,
                        designation: res.data.data[0].designation,
                    })
                );
                dispatch(setSelectedCard(res.data.data[0].iuud));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    }, [schemaAdbFranchise, dispatch, t]);

    const countCard = React.useCallback(() => {
        schemaAdbFranchise !== "" &&
            schemaAdbFranchise !== null &&
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}profiles/${schemaAdbFranchise}/0/cards/count`
                )
                .then((res) => {
                    setCardCount(res.data.cardCount);

                    if (res.data.cardCount === "1") {
                        //  setSelectUniqueCard();
                    }
                })
                .catch(() => {
                    ErrorToast(t("There is a mistake"));
                });
    }, [schemaAdbFranchise, t]);

    React.useEffect(() => {
        countCard();
    }, [countCard]);

    React.useEffect(() => {
        if (cardCount === 1) {
            setSelectUniqueCard();
        }
    }, [cardCount, setSelectUniqueCard]);

    React.useEffect(() => {
        if (usedCard?.iuud !== undefined && usedCard?.iuud !== "") {
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}cards/${schemaAdbFranchise}/${usedCard?.iuud}/elements/count`
                )
                .then((res) => {
                    setCardElementsCount(res.data);
                })
                .catch(() => {
                    ErrorToast(t("There is a mistake!"));
                });
        }
    }, [usedCard, t, schemaAdbFranchise]);

    return (
        <React.Fragment>
            <PageTitle title="Catalogue" />
            <div style={{ overflow: "auto" }}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="page-content"
                >
                    <StyledBreadcrumb
                        items={[
                            {
                                item: `${
                                    selectedStore
                                        ? selectedStore.toUpperCase()
                                        : selectedStore
                                } ${t("OUR MENU")}`,
                                link: "#",
                            },
                        ]}
                    />
                    <Container fluid>
                        <HeaderComponent
                            title={t(
                                "The dashboard displays the number of elements in a card if it is selected, if not, it will be the association of all elements in all cards."
                            )}
                        />

                        <div className="row">
                            {CARD_MENU_LIST.map((cardMenu) => {
                                cardMenu.number = fillCardMenuNumber(
                                    t(cardMenu.title)
                                );
                                return (
                                    <CardMenu
                                        key={shortid.generate()}
                                        title={t(cardMenu.title)}
                                        number={cardMenu.number}
                                        Icon={cardMenu.Icon}
                                        description={cardMenu.description}
                                        path={cardMenu.path}
                                        selectedCardDesignation={
                                            usedCard?.designation
                                        }
                                    />
                                );
                            })}
                        </div>
                    </Container>
                </motion.div>
            </div>
        </React.Fragment>
    );
}
