import React from "react";

import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { StyledLabel } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";

type isOutOfStockProps = {
    dispatchItem: any;
    isOutofstock: boolean;
};

export default function IsOutOfStock({
    dispatchItem,
    isOutofstock,
}: isOutOfStockProps) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    return (
        <div
            className="d-flex justify-content-between  align-items-betwwen align-items-center my-2"
            id="outOfStock"
        >
            <StyledLabel>{t("Item out of stock")}</StyledLabel>

            <ReactSwitch
                className=""
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={20}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={() => {
                    dispatchItem({
                        type: ACTIONS.OUT_OF_STOCK,
                        payload: !isOutofstock,
                    });
                    isMultiModify &&
                        dispatchStore(
                            addListKeytoEdit(MULTI_EDIT_KEY.OUT_OF_STOCK)
                        );
                }}
                checked={isOutofstock}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={45}
                height={15}
                checkedIcon={false}
                uncheckedIcon={false}
            />
        </div>
    );
}
