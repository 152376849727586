import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";

import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { RootState } from "@store/index";

type ColorPropsType = {
    value: string;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
};

export default function Color({ value, dispatch }: ColorPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const handleColor = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({
            type: ACTIONS.COLOR,
            payload: e.target.value,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.COLOR));
    };
    return (
        <div className="py-2" id="couleur">
            <div className="  row d-flex align-content-center flex-wrap py-2">
                <div className=" col-6 ">
                    <StyledLabel>{t("Color")}</StyledLabel>

                    <StyledTextInput
                        id="couleur"
                        name="Couleur"
                        type="color"
                        placeholder={t("Enter")}
                        defaultValue="#FFFFFF"
                        value={value}
                        onChange={handleColor}
                        disabled={
                            isEditing &&
                            cardType === "shop" &&
                            cardStatus === "En production"
                        }
                    />
                </div>
                <div className=" col-6 ">
                    <StyledLabel>{t("Hexadecimal code")}</StyledLabel>

                    <StyledTextInput
                        id="Hexadecimal"
                        name="Hexadecimal"
                        placeholder={t("Enter")}
                        type="text"
                        value={value}
                        onChange={handleColor}
                        disabled={
                            isEditing &&
                            cardType === "shop" &&
                            cardStatus === "En production"
                        }
                    />
                </div>
            </div>
        </div>
    );
}
