import React from "react";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";
import { setWorkflow } from "@store/features/workflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type visibilityStepProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    optionIsVisible: boolean;
    basicCompositionIsVisible: any;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    globalIndex: number;
    startIndex: number;
    uuid: string;
    cardData: any;
};

export function VisibilityStep({
    IsBasicCompositionStep,
    IsOptionStep,
    optionIsVisible,
    basicCompositionIsVisible,
    setIndexSelected,
    setIsClickStep,
    isClickStep,
    globalIndex,
    startIndex,
    uuid,
    cardData,
}: visibilityStepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ETKType = localStorage.getItem("ETKType");

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );

    const handleOptionVisibility = (event: any) => {
        if (globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS) {
            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[uuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[uuid].override[uuid]
                    : cardData?.items?.[uuid];

            let copyOverrideOption: any = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[uuid].override[uuid]
                    ?.overrideOptions || {}
            ).length > 0
                ? (copyOverrideOption =
                      globalWorkflow[startIndex].data[uuid].override[uuid]
                          ?.overrideOptions)
                : (copyOverrideOption = InsertOverrideOptionInItem(
                      copyOverrideOption,
                      cardData,
                      globalWorkflow[globalIndex!]?.uuid
                  ));

            const UuidOptions = globalWorkflow[globalIndex!]?.uuidStep;
            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        isVisible: !copyOverrideOption[UuidOptions].isVisible,
                    },
                },
            };

            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        uuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        uuid
                    )
                )
            );
        }

        event.stopPropagation();
    };
    const handleVisibility = (event: any) => {
        if (
            globalWorkflow[globalIndex!]?.uuidStep ===
            WORKFLOW_LIST.BASICCOMPOSITION
        ) {
            const currentItem: ItemType =
                Object.keys(
                    globalWorkflow[startIndex].data[uuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[uuid].override[uuid]
                    : cardData?.items?.[uuid];

            const updatedWorkflowStepElement = {
                ...currentItem,
                visibilityInfo: {
                    ...currentItem.visibilityInfo,
                    basicCompositionVisibility:
                        !currentItem.visibilityInfo.basicCompositionVisibility,
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        uuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        uuid
                    )
                )
            );
        }
        event.stopPropagation();
    };
    return (
        <div
            className={
                ETKType === "3"
                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                    : "icon_step__CLZ text-center mr-2"
            }
            style={{
                visibility:
                    IsBasicCompositionStep || IsOptionStep
                        ? "visible"
                        : "hidden",
            }}
        >
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={
                    optionIsVisible
                        ? t("Global visibility")
                        : t("Global visibility")
                }
                id={`cad222${globalIndex}`}
            />
            {(optionIsVisible && IsOptionStep) ||
            (basicCompositionIsVisible && IsBasicCompositionStep) ? (
                <EyeIcon
                    id={`cad222${globalIndex}`}
                    height={20}
                    width={20}
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        IsOptionStep
                            ? handleOptionVisibility(event)
                            : handleVisibility(event);
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                    }}
                />
            ) : (
                <EyeCrossedIcon
                    id={`cad222${globalIndex}`}
                    height={20}
                    width={20}
                    fill="red"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        IsOptionStep
                            ? handleOptionVisibility(event)
                            : handleVisibility(event);
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                    }}
                />
            )}
        </div>
    );
}
