import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel } from "@aureskonnect/react-ui";

import { addListKeytoEdit } from "@store/features/multipleEditSlice";
// import { DynamicTableWrapperContext } from "@components/Common";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { RootState } from "@store/index";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";

type DesignationPropsType = {
    value: string;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
};

export default function Designation({
    value,
    dispatch,
    addToRefs,
}: DesignationPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    // const cardType = JSON.parse(
    //     localStorage.getItem("selectedCard") || "{}"
    // ).type;
    // const cardStatus = JSON.parse(
    //     localStorage.getItem("selectedCard") || "{}"
    // ).statut;

    // const isEditing = useSelector(
    //     (state: RootState) => state.editObserverSlice.isEditing
    // );
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    const handleOnchangeDesignation = (title: string): void => {
        dispatch({
            type: ACTIONS.TITLE,
            payload: title,
        });
    };
    return (
        <div className="py-2" id="designation">
            <StyledLabel>{t("Designation *")}</StyledLabel>
            <StyledInputWithTooltip
                id="designation"
                innerRef={addToRefs}
                name="designation"
                type="text"
                value={value}
                requiredValidateValue={!isMultiModify}
                disabled={
                    !!isMultiModify ||
                    updateItemWorkflow.pathUpdateItem.length > 0
                    // ||
                    // (isEditing &&
                    //     cardType === "shop" &&
                    //     (cardStatus === "En attente" ||
                    //         cardStatus === "En production"))
                }
                autoFocus
                onFocus={(event: any) => {
                    event.target.select();
                }}
                valueSetterFunction={handleOnchangeDesignation}
            />
        </div>
    );
}
