import React from "react";

import { useTranslation } from "react-i18next";
import { CustomCardBody } from "@components/Common/StyledComponent/styledCard";
import { Card } from "react-bootstrap";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";

import { ACTIONS } from "@helpers/actions";
import Image from "@pages/Catalogue/steps/Image";
import StyledStepHeader from "./StyledStepHeader";
import StepItemVisibility from "./StepItemVisibility";

import "./Style.css";

type MultipleStepEditPropsType = {
    setActionColumnDisplay: Function;
    stepItemForm: StepItemType;
    dispatchStepItem: React.Dispatch<ReducerActionType>;
    itemToEditVisibility: visibilityCategoryType;
    setItemToEditVisibility: React.Dispatch<
        React.SetStateAction<visibilityCategoryType>
    >;
};

export default function MultipleStepEdit({
    setActionColumnDisplay,
    stepItemForm,
    dispatchStepItem,
    itemToEditVisibility,
    setItemToEditVisibility,
}: MultipleStepEditPropsType) {
    const { t } = useTranslation();
    const isInputNumber = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };
    return (
        <Card style={{ border: "0px " }}>
            <StyledStepHeader
                setActionColumnDisplay={setActionColumnDisplay}
                dispatchStepItem={dispatchStepItem}
                setItemToEditVisibility={setItemToEditVisibility}
            />
            <CustomCardBody>
                <div
                    className="px-3 py-2"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="py-2 d-flex justify-content-between ">
                        <StyledLabel>{t("Number of choices")}</StyledLabel>
                    </div>
                    <div className="py-2   border border-1">
                        <div className="  d-flex align  justify-content-between  px-2 py-2">
                            <StyledLabel className="col-5 px-1 py-1">
                                {t("Maximum per item")}
                            </StyledLabel>
                            <StyledTextInput
                                id="Maximum_per_item"
                                name="Maximum_per_item"
                                type="number"
                                autoComplete="off"
                                min={0}
                                onKeyPress={isInputNumber}
                                value={stepItemForm.maxNbrOfChoicesByItems}
                                placeholder={t("Enter")}
                                onChange={(e: any) => {
                                    dispatchStepItem?.({
                                        type: ACTIONS.MAX_NUMBER_OF_CHOICES,
                                        payload: Number(e.target.value),
                                    });
                                }}
                            />
                        </div>

                        <div className="  d-flex align  justify-content-between  px-2 py-2">
                            <StyledLabel className="col-5 px-1 py-1">
                                {t("Group 1 per item")}
                            </StyledLabel>

                            <StyledTextInput
                                id="With_special_price"
                                name="With_special_price"
                                type="number"
                                min={0}
                                onKeyPress={isInputNumber}
                                placeholder={t("Enter")}
                                value={
                                    stepItemForm.nbrOfChoicesWithspecialPrice
                                }
                                onChange={(e: any) => {
                                    dispatchStepItem?.({
                                        type: ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE,
                                        payload: Number(e.target.value),
                                    });
                                }}
                            />
                        </div>
                        <div className="  d-flex align  justify-content-between  px-2 py-2">
                            <StyledLabel className="col-5 px-1 py-1">
                                {t("Group 2 per item")}
                            </StyledLabel>
                            <StyledTextInput
                                id="nbrOfChoicesWithSpecialPrice"
                                name="nbrOfChoicesWithSpecialPrice"
                                type="number"
                                min={0}
                                onKeyPress={isInputNumber}
                                placeholder={t("Enter")}
                                value={
                                    stepItemForm.nbrOfChoicesWithItemPriceInStep
                                }
                                onChange={(e: any) => {
                                    dispatchStepItem?.({
                                        type: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
                                        payload: Number(e.target.value),
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="py-2 d-flex justify-content-between ">
                        <StyledLabel>{t("Price")}</StyledLabel>
                    </div>

                    <div className="py-2   border border-1">
                        <div className="  d-flex align  justify-content-between  px-2 py-2">
                            <StyledLabel className="col-5 px-1 py-1">
                                {t("Price group 1")}
                            </StyledLabel>
                            <StyledTextInput
                                min={0}
                                type="number"
                                id="step-item-special_price"
                                name="step-item-special_price"
                                placeholder={t("Enter")}
                                value={stepItemForm.specialPrice}
                                onKeyPress={(event: any) => {
                                    const keyCode =
                                        event.keyCode || event.which;
                                    const keyValue =
                                        String.fromCharCode(keyCode);
                                    if (/\+|-/.test(keyValue))
                                        event.preventDefault();
                                }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatchStepItem?.({
                                        type: ACTIONS.SPECIAL_PRICE,
                                        payload: Number(e.target.value),
                                    });
                                }}
                            />
                        </div>
                        <div className="d-flex align  justify-content-between  px-2 py-2">
                            <StyledLabel className="col-5 px-1 py-1">
                                {t("Price group 2")}
                            </StyledLabel>
                            <StyledTextInput
                                min={0}
                                type="number"
                                id="step-item-special_price"
                                name="step-item-special_price"
                                placeholder={t("Enter")}
                                value={stepItemForm.priceOfItemInStep}
                                onKeyPress={(event: any) => {
                                    const keyCode =
                                        event.keyCode || event.which;
                                    const keyValue =
                                        String.fromCharCode(keyCode);
                                    if (/\+|-/.test(keyValue))
                                        event.preventDefault();
                                }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatchStepItem?.({
                                        type: ACTIONS.ITEM_PRICE_IN_STEP,
                                        payload: Number(e.target.value),
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="py-2" id="couleur">
                        <div className="  row d-flex align-content-left flex-wrap py-2">
                            <div className=" col-6 ">
                                <StyledLabel>{t("Color")}</StyledLabel>

                                <StyledTextInput
                                    id="step-item-multi-couleur"
                                    name="step-item-multi-couleur"
                                    type="color"
                                    value={stepItemForm.color}
                                    placeholder={t("Enter")}
                                    onChange={(e: any) => {
                                        dispatchStepItem?.({
                                            type: ACTIONS.COLOR,
                                            payload: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className=" col-6 ">
                                <StyledLabel>
                                    {t("Hexadecimal code")}
                                </StyledLabel>

                                <StyledTextInput
                                    id="Hexadecimal"
                                    name="Hexadecimal"
                                    placeholder={t("Enter")}
                                    type="text"
                                    defaultValue="#FFFFFF"
                                    value={stepItemForm.color}
                                    onChange={(e: any) => {
                                        dispatchStepItem?.({
                                            type: ACTIONS.COLOR,
                                            payload: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-2" id="LoyaltyPoints">
                        <StyledLabel>{t("Loyalty points")}</StyledLabel>
                        <StyledTextInput
                            id="LoyaltyPoints"
                            name="LoyaltyPoints"
                            min="0"
                            type="number"
                            onKeyPress={isInputNumber}
                            placeholder={t("Enter")}
                            onChange={(e: any) => {
                                dispatchStepItem?.({
                                    type: ACTIONS.FIDELITY,
                                    payload: Number(e.target.value),
                                });
                            }}
                        />
                    </div>
                    <div className="py-2" id="stepItemImage">
                        <StyledLabel>{t("Picture")}</StyledLabel>
                        <Image
                            id="stepItemImage"
                            dispatch={dispatchStepItem}
                            stepImageUrl={stepItemForm.imageUrl}
                        />
                    </div>

                    <div className="py-2" id="StepItemVisibility">
                        <StepItemVisibility
                            title={t("Visibility")}
                            ItemModalVisibility={itemToEditVisibility}
                            setItemModalVisibility={setItemToEditVisibility}
                        />
                    </div>
                </div>
            </CustomCardBody>
        </Card>
    );
}
