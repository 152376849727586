import React from "react";

import { PlusIcon } from "@aureskonnect/react-ui";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type AddDateType = {
    setPeriod: React.Dispatch<any>;
    ValueRender: any;
    indice: number;
    LengthName: number;
    setRender: React.Dispatch<React.SetStateAction<string>>;
};

export default function AddDate({
    setPeriod,
    ValueRender,
    indice,
    LengthName,
    setRender,
}: AddDateType) {
    const { t } = useTranslation();
    function handleAddDate() {
        setPeriod((prevState: any) => {
            const newState = [...prevState];
            const selectedDate: any = {};

            selectedDate.DateDebut = "";
            selectedDate.DateFin = "";

            const newArr = [...newState[indice].date];

            newArr.push(selectedDate);

            newState[indice] = {
                ...newState[indice],
                date: newArr,
            };

            return newState;
        });
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div>
                <PlusIcon
                    height={15}
                    width={15}
                    onClick={() => {
                        if (LengthName !== 0) {
                            handleAddDate();
                            setRender(ValueRender);
                        }
                    }}
                />
            </div>
            <div>
                <Button
                    variant="link"
                    className="w-auto"
                    onClick={() => {
                        if (LengthName !== 0) {
                            handleAddDate();
                            setRender(ValueRender);
                        }
                    }}
                    style={{
                        color: "#323333",
                        textDecoration: "underline",
                    }}
                >
                    {t("to add")}
                </Button>
            </div>
        </div>
    );
}
