import { StyledButton } from "@aureskonnect/react-ui";
import { SuccessToast, DominantColor } from "@components/Common/";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type optionFooterType = {
    currentOption: Array<Setting>;
    addOption: Array<Setting>;
    setInitialData: React.Dispatch<React.SetStateAction<boolean>>;
    initialData: boolean;
    redirection: any;
};

export default function OptionFooter({
    currentOption,
    addOption,
    setInitialData,
    initialData,
    redirection,
}: optionFooterType) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const pathname = window.location.pathname.toString().split("/")[2];
    const configurationType: string =
        pathname === "Categories"
            ? "configurationCathegory"
            : "configurationItem";

    const dominantColor = DominantColor();

    const saveData = () => {
        localStorage.setItem(
            configurationType,
            JSON.stringify([...currentOption, ...addOption])
        );
        SuccessToast(t("Successful registration"));

        navigate(`${pathname === "Categories" ? "/categories" : "/items"}`);
    };
    const cancelData = () => {
        setInitialData(!initialData);
        redirection !== null &&
            navigate(`${pathname === "Categories" ? "/categories" : "/items"}`);
    };
    return (
        <div className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
                <div>
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={cancelData}
                    >
                        {t("Cancel")}
                    </StyledButton>
                </div>
                <div className="ml-3">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={() => {
                            saveData();
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
}
