import React from "react";

import { ACTIONS } from "@helpers/.";

import ImageUploader from "@components/Common_DB/ImageUploader";

type ImagePropsType = {
    allergenImageUrl: string;
    dispatch: Function;
};

export default function Image({ allergenImageUrl, dispatch }: ImagePropsType) {
    const [uploadedImage, setUploadedImage] = React.useState<string | Blob>(
        allergenImageUrl
    );
    const [, setFileType] = React.useState<"image" | "video" | "">("image");

    React.useEffect(() => {
        dispatch({
            type: ACTIONS.ICON,
            payload: uploadedImage,
        });
    }, [uploadedImage, dispatch]);

    React.useEffect(() => {
        setUploadedImage(allergenImageUrl);
    }, [allergenImageUrl]);

    return (
        <div
            className="d-flex justify-content-center align-items-center p-2"
            style={{
                border: "1px solid #C6C6C6",
                width: "100%",
                height: "240px",
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <ImageUploader
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                fileType="image"
                setFileType={setFileType}
            />
        </div>
    );
}
