import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import {
    clientSlice,
    articleSlice,
    categorySlice,
    cardSlice,
    cardExchangeSlice,
    layoutSlice,
    dominantColorSlice,
    headerCompoHeighSlice,
    copyHourSlice,
    copyDateSlice,
    indexPeriodSlice,
    visibilitySlice,
    allergenSlice,
    multipleEditSlice,
    franchiseNameSlice,
    editObserverSlice,
    workFlowSlice,
    workFlowDataSlice,
    modifierSlice,
    updateItemWorkflowSlice,
    parentCategorieSlice,
    multipleWorkflowSlice,
    settingsSlice,
    selectedCardListSlice,
} from "./features";

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        clientSlice,
        articleSlice,
        cardSlice,
        cardExchangeSlice,
        categorySlice,
        workFlowSlice,
        workFlowDataSlice,
        modifierSlice,
        updateItemWorkflowSlice,
        multipleWorkflowSlice,
        layoutSlice,
        dominantColorSlice,
        headerCompoHeighSlice,
        copyHourSlice,
        copyDateSlice,
        indexPeriodSlice,
        visibilitySlice,
        allergenSlice,
        multipleEditSlice,
        franchiseNameSlice,
        editObserverSlice,
        parentCategorieSlice,
        settingsSlice,
        selectedCardListSlice,
    });

export default createRootReducer;
