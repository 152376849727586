import React from "react";

import { Spinner } from "reactstrap";

import "./Loading.css";

export default function LoadingDataAnimation() {
    return (
        <div className="centeranimation">
            <Spinner type="grow" className="text-primary">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-success">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-danger">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-warning">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-info">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-light">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-default">
                <span className=" sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}
