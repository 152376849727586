/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import {
    StyledModal,
    StyledH2,
    StyledLabel,
    PlusIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { Button } from "react-bootstrap";
import moment from "moment";
import { Input, Label, ModalBody, ModalHeader } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import { fr } from "date-fns/locale";
import { DominantColor } from "@components/Common/dominantColor";
import CardFooterPlaning from "./CardFooterPlaning";
import "./style.css";
import Listshop from "./Listeshop";

type ToplanType = {
    setSelectedRowsToPlanification: React.Dispatch<React.SetStateAction<any[]>>;
    Toplan: boolean;
    isOpenShopList: boolean;
    setIsOpenShopList: Function;
    isOpen: boolean;
    setIsOpenToplan: Function;
    rowData: any;
    selectedRowsToPlanification: any[];
    farthestPlaningDate: any;
    cardmaster: boolean;
    pendingcardmaster: boolean;
    closerPlaningDate: string;
};
export default function ModalPlaning({
    setSelectedRowsToPlanification,
    selectedRowsToPlanification,
    isOpenShopList,
    setIsOpenShopList,
    rowData,
    isOpen,
    setIsOpenToplan,
    farthestPlaningDate,
    cardmaster,
    pendingcardmaster,
    closerPlaningDate,
}: ToplanType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const [isCardPlanified, setIsCardPlanified] =
        React.useState<boolean>(false);
    const [endDate, setEndDate] = React.useState<any>("");
    const [startDate, setStartDate] = React.useState<any>(new Date());
    const [definedTime, setDefinedTime] = React.useState<boolean>(true);
    const [hourlyInterval, setHourlyInterval] = React.useState<boolean>(false);
    const [titleFieldset, setTitleFieldset] = React.useState<any>(
        `Aujourd'hui ${moment().format("DD/MM/YY")}`
    );
    const [time, setTime] = React.useState<any>(new Date());

    const [interval, setInterval] = React.useState<any>([
        {
            id: 0,
            startDate: new Date(),
            endDate: new Date(),
        },
    ]);
    const [cancelPlanification, setCancelPlanification] = useState(false);

    const selectSwitchPlanification = () => {
        setCancelPlanification(!cancelPlanification);
    };
    const addInterval = () => {
        const newInterval: any = [...interval];
        newInterval.push({
            id: interval.length + 1,
            startDate,
            endDate: endDate !== null ? endDate : new Date(),
        });
        setInterval(newInterval);
    };

    const handleStartDateChange = (element: any, key: number) => {
        const newInterval = [...interval] as any;
        newInterval[key].startDate = element;

        setInterval(newInterval);
    };

    const handleEndDateChange = (element: any, key: number) => {
        const newInterval = [...interval] as any;
        newInterval[key].endDate = element;

        setInterval(newInterval);
    };
    registerLocale("fr", fr);
    function handleIntervalDateChange(date: any) {
        const [start, end] = date;

        setStartDate(start);

        setEndDate(end);

        if (interval.length > 1) {
            setEndDate(start);
        }
    }
    function deleteInterval(key: number) {
        const newInterval = [...interval];
        const index = newInterval.indexOf(interval[key]);
        if (index !== -1) {
            newInterval.splice(index, 1);
        }
        setInterval(newInterval);
    }
    function nextDay(date: any) {
        const tomorrow = new Date(date);

        tomorrow.setDate(tomorrow.getDate() + 1);
        return new Date(tomorrow);
    }
    function prevDay(date: any) {
        const yesterday = new Date(date);

        yesterday.setDate(yesterday.getDate() - 1);
        return new Date(yesterday);
    }

    const minDateToplaning = !!farthestPlaningDate
        ? nextDay(farthestPlaningDate)
        : new Date();
    const maxDateToplaning = !!closerPlaningDate
        ? prevDay(closerPlaningDate)
        : null;

    React.useEffect(() => {
        const weekday: any = [
            "Dimanche",
            "Lundi",
            "Mardi",
            "Marcruedi",
            "Jeudi",
            "Vendredi",
            "Samdi",
        ];
        if (startDate && !endDate) {
            const datesty = new Date();
            if (
                moment(startDate).format("DD/MM/YY") ===
                moment(datesty).format("DD/MM/YY")
            ) {
                setTitleFieldset(`Aujourd'hui ${moment().format("DD/MM/YY")}`);
            } else {
                setTitleFieldset(
                    ` 
                ${weekday[startDate.getDay()]}   ${startDate.getDate()}/${
                        startDate.getMonth() + 1
                    }/${startDate.getFullYear()} `
                );
            }
        }

        if (startDate && endDate) {
            setTitleFieldset(
                `  ${weekday[startDate.getDay()]} ${startDate.getDate()}/${
                    startDate.getMonth() + 1
                }/${startDate.getFullYear()} -
                
                 ${weekday[endDate.getDay()]} ${endDate.getDate()}/${
                    endDate.getMonth() + 1
                }/${endDate.getFullYear()} 
                
                                `
            );
        }
    }, [endDate, startDate]);

    React.useEffect(() => {
        const data = rowData.selectedRow.original?.Planning;

        const ac = new AbortController();

        if (!!data) {
            const ParsingDate = JSON.parse(data);

            setStartDate(new Date(ParsingDate?.startDate));
            setEndDate(new Date(ParsingDate?.endDate));
            if (ParsingDate?.definedTime === true) {
                setTime(new Date(ParsingDate?.startDate));
            } else {
                setDefinedTime(false);
            }
            if (ParsingDate?.definedTime === false) {
                setHourlyInterval(true);
                ParsingDate.TimeInterval.forEach((element: any) => {
                    const newInterval: any = [...interval];
                    newInterval.push({
                        id: interval.length + 1,
                        startDate: new Date(element?.Start),
                        endDate:
                            element.End !== null
                                ? new Date(element.End)
                                : new Date(),
                    });
                    setInterval(newInterval);
                });
            }
            return () => ac.abort();
        }
    }, [interval, rowData.selectedRow.original?.Planning]);

    return (
        <div>
            {rowData.selectedRow.original.iuudcardreference === "0" &&
                pendingcardmaster == false &&
                cardmaster === false && (
                    <Listshop
                        setSelectedRowsToPlanification={
                            setSelectedRowsToPlanification
                        }
                        isCardPlanified={isCardPlanified}
                        setIsCardPlanified={setIsCardPlanified}
                        selectedRowsToPlanification={
                            selectedRowsToPlanification
                        }
                        isOpen={isOpenShopList}
                        setIsOpen={setIsOpenShopList}
                        setIsOpenToplan={setIsOpenToplan}
                        isOpenToplan={isOpen}
                        rowData={rowData}
                    />
                )}

            <StyledModal
                centered
                isOpen={isOpen}
                style={{ minWidth: "max-content" }}
            >
                <ModalHeader
                    className="text-uppercase"
                    toggle={() => {
                        setIsOpenToplan(!isOpen);
                    }}
                >
                    <StyledH2>{t("planning")}</StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div className="d-flex justify-content-start">
                        <ReactSwitch
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={20}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={selectSwitchPlanification}
                            checked={cancelPlanification}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={45}
                            height={15}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            // disabled={}
                        />
                        <span>suprimer la planification </span>
                    </div>

                    <div
                        style={{
                            display: "grid",
                            columnGap: "5px",
                            gridTemplateColumns: "3fr 3fr",
                        }}
                    >
                        <fieldset
                            className="fieldsetInterne__clz"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "350px",
                            }}
                        >
                            <legend
                                style={{ textAlign: "center" }}
                                className="w-auto"
                            >
                                <StyledLabel>{titleFieldset}</StyledLabel>
                            </legend>
                            <div className="d-flex justify-content-center ml-2 mr-2 mt-2 mb-2">
                                {!definedTime ? (
                                    <DatePicker
                                        wrapperClassName="date_picker full-width"
                                        selected={startDate}
                                        onChange={(dates: any) => {
                                            handleIntervalDateChange(dates);
                                        }}
                                        minDate={minDateToplaning}
                                        maxDate={maxDateToplaning}
                                        inline
                                        // startDate={startDate}
                                        endDate={endDate}
                                        locale="fr"
                                        selectsRange
                                    />
                                ) : (
                                    <DatePicker
                                        wrapperClassName="date_picker full-width"
                                        selected={startDate}
                                        minDate={minDateToplaning}
                                        maxDate={maxDateToplaning}
                                        onChange={(dates: Date) => {
                                            setStartDate(dates);
                                        }}
                                        inline
                                        locale="fr"
                                    />
                                )}
                            </div>
                        </fieldset>

                        <fieldset
                            className="fieldsetInterne__clz "
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <legend
                                style={{ marginLeft: "10px" }}
                                className="w-auto"
                            >
                                <StyledLabel>
                                    {t("Publication Time")}
                                </StyledLabel>
                            </legend>

                            <div className="px-2">
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        defaultChecked={definedTime}
                                        onChange={() => {
                                            setDefinedTime(true);
                                            setHourlyInterval(false);
                                            setStartDate(new Date());
                                            setEndDate(null);
                                            setTitleFieldset(
                                                `Aujourd'hui ${moment().format(
                                                    "DD/MM/YY"
                                                )}`
                                            );
                                        }}
                                        style={{
                                            position: "relative",
                                        }}
                                        className="mb-2"
                                    />
                                    <span
                                        className="ml-1 "
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("Well-defined time")}
                                    </span>
                                </Label>

                                <DatePicker
                                    className={`${classnames(
                                        "react-datepicker__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                !definedTime,
                                        }
                                    )}`}
                                    selected={time}
                                    onChange={(date: any) => {
                                        setTime(date);
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                />
                            </div>
                            <div className="px-2 mt-3">
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        defaultChecked={hourlyInterval}
                                        onChange={() => {
                                            setDefinedTime(false);
                                            setHourlyInterval(true);
                                            setStartDate(new Date());
                                        }}
                                        style={{
                                            position: "relative",
                                        }}
                                    />
                                    <span
                                        className="ml-1"
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("Hourly interval")} *
                                    </span>
                                </Label>
                            </div>
                            <div
                                className="ml-2 mr-2 mt-2 mb-2"
                                style={{
                                    border: "1px solid #dedede",
                                    height: "120px",
                                    overflowY: "auto",
                                }}
                            >
                                {interval.map((element: any, key: number) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={key}>
                                        <div
                                            className={`${classnames(
                                                " mt-2 d-flex justify-content-between align-items-center ml-1 mr-2",
                                                {
                                                    "not-allowed-icon__clz":
                                                        !hourlyInterval,
                                                }
                                            )}`}
                                        >
                                            <div className="d-flex justify-content-center align-items-center ml-2 mr-2 pt-2 pb-2 ">
                                                <span className="mr-2">
                                                    {t("Of")}
                                                </span>

                                                <DatePicker
                                                    className="react-datepicker__clz"
                                                    selected={element.startDate}
                                                    onChange={(date: any) => {
                                                        handleStartDateChange(
                                                            date,
                                                            key
                                                        );
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                />
                                                <span className="mr-2 ml-1">
                                                    {t("at")}
                                                </span>
                                                <DatePicker
                                                    className="react-datepicker__clz"
                                                    selected={element.endDate}
                                                    onChange={(date: any) =>
                                                        handleEndDateChange(
                                                            date,
                                                            key
                                                        )
                                                    }
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                />
                                            </div>
                                            <TrashIcon
                                                height={25}
                                                width={25}
                                                className="mt-0"
                                                fill="red"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    interval.length !== 1 &&
                                                        deleteInterval(key);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div
                                    className={`${classnames(
                                        "d-flex justify-content-end align-items-center ml-3 p-1",
                                        {
                                            "not-allowed-icon__clz":
                                                !hourlyInterval,
                                        }
                                    )}`}
                                >
                                    <div>
                                        <PlusIcon height={15} width={15} />
                                    </div>

                                    <Button
                                        variant="link"
                                        className="w-auto btn-style__clz"
                                        onClick={() => {
                                            addInterval();
                                        }}
                                        style={{
                                            color: "black",
                                            textDecoration: "underline",
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("Add an interval")}
                                    </Button>
                                </div>
                            </div>

                            <span
                                className="d-flex justify-content-start align-items-center mt-5 ml-2"
                                style={{
                                    fontSize: "9px",
                                    fontWeight: "bold",
                                }}
                            >
                                *{" "}
                                {t(
                                    "updating is only possible during this interval"
                                )}
                            </span>
                        </fieldset>
                    </div>
                </ModalBody>
                <div className="border-Footer__clz" />
                <CardFooterPlaning
                    cancelPlanification={cancelPlanification}
                    selectedRowsToPlanification={selectedRowsToPlanification}
                    setIsCardPlanified={setIsCardPlanified}
                    isCardPlanified={isCardPlanified}
                    rowData={rowData}
                    startDate={startDate}
                    time={time}
                    interval={interval}
                    hourlyInterval={hourlyInterval}
                    definedTime={definedTime}
                    endDate={endDate}
                    setIsOpenToplan={setIsOpenToplan}
                    isOpen={isOpen}
                />
            </StyledModal>
        </div>
    );
}
