import { ACTIONS } from "@helpers/actions";

export const initialStateItem: ItemType = {
    title: "",
    reference: "",
    color: "#FFFFFF",
    categoryParent: "",
    sizeList: [],
    variants: [],
    printers: [],
    price: {
        default: 0,
        priceHT: 0,
        tva: 0,
        saleModeVatRates: [],
        override: [],
        advancedPrice: {},
    },
    categoryLiaison: [],
    linkedTags: [],
    description: {
        Default: {
            nameDefault: "",
            salesSupport: [],
            impression: [],
        },
    },
    options: {},
    displayName: {
        Default: {
            nameDefault: "",
            salesSupport: [],
            impression: [],
        },
    },
    calories: 0,
    fidelity: 0,
    ranks: { default: 0, orderOverride: [] },
    visibilityInfo: {
        isVisible: true,
        basicCompositionVisibility: true,
        default: {},
    },
    imageUrl: {
        Default: {
            urlDefault: "",
            salesSupport: [],
        },
        override: [
            { shopId: "" },

            {
                info: [],
                salesSupport: [],
            },
        ],
    },
    allergens: [],
    archive: false,
    steps: [],
    basicComposition: {},
    outOfStock: false,
    barCode: "",
    active_quantity: false,
    isRedirect: false,
};

export function reducerItem(
    state: ItemType,
    action: { type: string; payload: any }
): ItemType {
    switch (action.type) {
        case ACTIONS.TITLE:
            return { ...state, title: action.payload };
        case ACTIONS.REFERENCE:
            return { ...state, reference: action.payload };
        case ACTIONS.COLOR:
            return { ...state, color: action.payload };

        case ACTIONS.DESCRIPTION:
            return { ...state, description: action.payload };
        case ACTIONS.PARENTS_CATEGORIES:
            return { ...state, categoryParent: action.payload };
        case ACTIONS.LINK_CATEGORIES:
            return { ...state, categoryLiaison: action.payload };
        case ACTIONS.LINK_TAGS:
            return { ...state, linkedTags: action.payload };
        case ACTIONS.DEFAULT_DISPLAY_NAME:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName?.Default,
                        nameDefault: action.payload,
                    },
                },
            };

        case ACTIONS.DISCRIPTION:
            return {
                ...state,
                description: action.payload,
            };
        case ACTIONS.DEFAULT_DISCRIPTION:
            return {
                ...state,
                description: {
                    ...state.description,
                    Default: {
                        ...state.description?.Default,
                        nameDefault: action.payload,
                    },
                },
            };

        case ACTIONS.DISPLAY_NAME:
            return {
                ...state,
                displayName: action.payload,
            };
        case ACTIONS.DEFAULT_PRICE:
            return {
                ...state,
                price: {
                    ...state.price,
                    default: Number(action.payload),
                },
            };
        case ACTIONS.DEFAULT_TVA:
            return {
                ...state,
                price: {
                    ...state.price,
                    tva: Number(action.payload),
                },
            };
        case ACTIONS.PRICE_HT:
            return {
                ...state,
                price: {
                    ...state.price,
                    priceHT: action.payload ?? 0,
                    default: parseFloat(
                        String(
                            Number(action.payload ?? 0) *
                                (1 + state.price.tva / 100)
                        )
                    ).toFixed(2),
                },
            };
        case ACTIONS.PRICE_TTC:
            return {
                ...state,
                price: {
                    ...state.price,
                    priceHT: parseFloat(
                        String(
                            Number(action.payload ?? 0) /
                                (1 + state.price.tva / 100)
                        )
                    ).toFixed(2),
                    default: action.payload ?? 0,
                },
            };
        case ACTIONS.ADVANCED_PRICE:
            return {
                ...state,
                price: {
                    ...state.price,
                    advancedPrice: action.payload,
                },
            };
        case ACTIONS.TVA:
            return {
                ...state,
                price: {
                    ...state.price,
                    tva: action.payload,
                    default: parseFloat(
                        String(
                            Number(state.price.priceHT) *
                                (1 + action.payload / 100)
                        )
                    ).toFixed(2),
                    priceHT: parseFloat(
                        String(
                            Number(state.price.default) /
                                (1 + state.price.tva / 100)
                        )
                    ).toFixed(2),
                },
            };
        case ACTIONS.SALE_MODE_TVA:
            return {
                ...state,
                price: {
                    ...state.price,
                    saleModeVatRates: action.payload,
                },
            };
        case ACTIONS.OVER_PRICE:
            return {
                ...state,
                price: {
                    ...state.price,
                    override: action.payload,
                },
            };

        case ACTIONS.OPTIONS:
            return {
                ...state,
                options: action.payload,
            };

        case ACTIONS.FIDELITY:
            return {
                ...state,
                // eslint-disable-next-line no-bitwise
                fidelity: action.payload | 0,
            };

        case ACTIONS.CALORIES:
            return { ...state, calories: action.payload };

        case ACTIONS.DEFAULT_RANK:
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    default: action.payload,
                },
            };

        case ACTIONS.ALLERGENS:
            return {
                ...state,
                allergens: action.payload,
            };

        case ACTIONS.VISIBILITY_INFO:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    default: {
                        ...state.visibilityInfo.default,
                        [action.payload.salesMode]: action.payload.data,
                    },
                },
            };
        case ACTIONS.IS_VISIBLE:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    isVisible: action.payload,
                },
            };
        case ACTIONS.BASIC_COMPOSITION_VISIBILITY:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    basicCompositionVisibility: action.payload,
                },
            };

        case ACTIONS.DEFAULT_IMAGE_URL:
            return {
                ...state,
                imageUrl: {
                    ...state.imageUrl,
                    Default: {
                        ...state.imageUrl.Default,
                        urlDefault: action.payload,
                    },
                },
            };

        case ACTIONS.SALES_SUPPORT_IMAGE_URL:
            return {
                ...state,
                imageUrl: {
                    ...state.imageUrl,
                    Default: {
                        ...state.imageUrl.Default,
                        salesSupport: action.payload,
                    },
                },
            };

        case ACTIONS.RESET:
            return initialStateItem;

        case ACTIONS.INIT_VISIBILITY:
            return {
                ...state,
                visibilityInfo: {
                    ...state.visibilityInfo,
                    default: action.payload,
                },
            };

        case ACTIONS.INIT_SELLING_SUPPORT:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName.Default,
                        salesSupport: action.payload,
                    },
                },
                description: {
                    ...state.description,
                    Default: {
                        ...state.description.Default,
                        salesSupport: action.payload,
                    },
                },
            };
        case ACTIONS.INIT_IMPRESSION:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName.Default,
                        impression: action.payload,
                    },
                },
                description: {
                    ...state.description,
                    Default: {
                        ...state.description.Default,
                        impression: action.payload,
                    },
                },
            };
        case ACTIONS.OUT_OF_STOCK:
            return { ...state, outOfStock: action.payload };
        case ACTIONS.IS_REDIRECT:
            return { ...state, isRedirect: action.payload };
        case ACTIONS.BARCODE:
            return { ...state, barCode: action.payload };
        case ACTIONS.STEP:
            return { ...state, steps: action.payload };
        case ACTIONS.EDIT:
            return action.payload;
        case ACTIONS.BASIC_COMPOSITION:
            return { ...state, basicComposition: action.payload };
        case ACTIONS.QUANTITY_OF_BASIC_COMPOSITION:
            return { ...state, active_quantity: action.payload };
        default:
            return state;
    }
}
