import React from "react";

export default function CarteIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={107}
            height={107}
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor={props.stopColor} />
                    <stop offset={1} stopColor={props.stopColor} />
                </linearGradient>
            </defs>
            <path
                d="M53.5 0A53.5 53.5 0 1 1 0 53.5 53.5 53.5 0 0 1 53.5 0Z"
                fill={props.stopColor}
                transform="translate(.001)"
            />
            <g fill={props.fill}>
                <path d="M24.55 33.946a1.9 1.9 0 0 0 1.089 1.7l6.79 3.276v5.381l-6.79 3.276a1.9 1.9 0 0 0-1.089 1.702v7.6a1.9 1.9 0 0 0 1.089 1.7l6.79 3.276v5.255l-6.79 3.276a1.9 1.9 0 0 0-1.089 1.705v7.6a1.9 1.9 0 0 0 1.065 1.689l7.879 3.929a2.036 2.036 0 0 0 1.809 0l7.879-3.929a1.9 1.9 0 0 0 1.068-1.684v-7.6a1.9 1.9 0 0 0-1.089-1.7l-6.79-3.276v-5.26l6.79-3.276a1.9 1.9 0 0 0 1.089-1.7v-7.6a1.9 1.9 0 0 0-1.089-1.7l-6.79-3.276v-5.387l6.79-3.276a1.9 1.9 0 0 0 1.089-1.7v-7.608a1.9 1.9 0 0 0-1.093-1.7l-7.878-3.8a2.035 2.035 0 0 0-1.762 0l-7.878 3.8a1.9 1.9 0 0 0-1.089 1.7Zm15.757 44.593-5.909 2.948-5.909-2.948v-5.271l5.909-2.852 5.909 2.852Zm0-22.83-5.909 2.853-5.909-2.852v-5.254l5.909-2.852 5.909 2.852ZM28.489 27.517l5.909-2.852 5.909 2.852v5.255l-5.909 2.851-5.909-2.852ZM87.469 24.578H47.814a1.97 1.97 0 1 0 0 3.939h39.655a1.97 1.97 0 1 0 0-3.939ZM48.882 35.959h19.83a1.97 1.97 0 1 0 0-3.939h-19.83a1.97 1.97 0 1 0 0 3.939ZM48.882 58.846h19.83a1.97 1.97 0 1 0 0-3.939h-19.83a1.97 1.97 0 1 0 0 3.939ZM68.712 81.456a1.97 1.97 0 1 0 0-3.939h-19.83a1.97 1.97 0 1 0 0 3.939ZM87.469 47.32H47.814a1.97 1.97 0 1 0 0 3.939h39.655a1.97 1.97 0 1 0 0-3.939ZM87.469 70.081H47.814a1.97 1.97 0 1 0 0 3.939h39.655a1.97 1.97 0 1 0 0-3.939Zm0 0" />
                <path d="M36.364 30.488a1.97 1.97 0 1 1-1.97-1.97 1.97 1.97 0 0 1 1.97 1.97ZM36.364 53.084a1.97 1.97 0 1 1-1.97-1.97 1.97 1.97 0 0 1 1.97 1.97ZM36.364 75.548a1.97 1.97 0 1 1-1.97-1.97 1.97 1.97 0 0 1 1.97 1.97Zm0 0" />
            </g>
        </svg>
    );
}
