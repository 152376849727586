/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import axios from "axios";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import {
    DuplicateIcon,
    EyeIcon,
    GridIcon,
    RedoIcon,
    SettingsIcon,
    TrashIcon,
    VerticalDotsIcon,
    RefreshIcon,
} from "@aureskonnect/react-ui";

import {
    DynamicTableWrapperContext,
    LoadingDataAnimation,
} from "@components/Common";
import { useDispatch } from "react-redux";
import { setSelectedExchangeCard } from "@store/features/cardExchangeSlice";
import DuplicateCard from "./Duplicate/DuplicateCard";
import ModalPublish from "./Publish/ModalPublish";
import ExchangeCardModal from "./Retrieve/ExchangeCardModal";
import ModalPlaning from "./Toplan/ModalPlaning";
import DeleteCard from "./DeleteCard";
import ConfigureCard from "./Configure/ConfigureCard";

import "./Style.css";

export default function DropdownActions(rowData: any): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [cardmaster, setcardmaster] = React.useState(true);

    const [pendingcardmaster, setpendingcardmaster] = React.useState(true);

    const [prodCardName, setprodCardName] = React.useState<any>("");

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenToplan, setIsOpenToplan] = React.useState(false);
    const [isOpenList, setIsOpenList] = React.useState(false);
    const [Toplan, setToplan] = React.useState(false);
    const [isOpenDelete, setisOpenDelete] = React.useState(false);
    const [isOpenconfigure, setisOpenConfigure] = React.useState(false);
    const [isOpenShop, setIsOpenShop] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpenShopList, setIsOpenShopList] = React.useState(false);

    const [selectedRowsToPlanification, setSelectedRowsToPlanification] =
        React.useState<any[]>([]);
    const [Archive, setArchive] = React.useState(true);
    const [canDuplicate, setcanDuplicate] = React.useState(true);
    const [farthestPlaningDate, setfarthestPlaningDate] =
        React.useState<any>("");
    const [closerPlaningDate, setcloserPlaningDate] = React.useState<any>("");
    const [shopCards, setShopCards] = React.useState<string>("");
    const [
        shopCarlistedeCartesenattentePlanifierds,
        setlistedeCartesenattentePlanifier,
    ] = React.useState<any[]>([]);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleSuppression = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        setisOpenDelete(true);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
        event.stopPropagation();
    };

    const handlereproduction = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        setIsOpen(!isOpen);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
        event.stopPropagation();
    };

    const handleToplan = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): Promise<void> => {
        await verifyPlanification();

        if (rowData.selectedRow.original.iuudcardreference !== "0") {
            setIsOpenToplan(true);
        }
        if (
            rowData.selectedRow.original.iuudcardreference === "0" &&
            (pendingcardmaster === true || cardmaster === true)
        ) {
            // si on va planifier une carte master et on a une carte master en production
            setIsOpenShopList(false);
            setIsOpenToplan(true);
        }
        if (
            rowData.selectedRow.original.iuudcardreference === "0" &&
            pendingcardmaster === false &&
            cardmaster === false
        ) {
            // si on va planifier une carte master et on n a pas  une carte master en production

            setIsOpenShopList(true);
        }

        event.stopPropagation();
    };

    const stopPropagation = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        event.stopPropagation();
    };
    const [Open, setOpen] = React.useState(false);

    const handPublish = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        // verifier si il ya une carte master  publier
        await verifycarteMasterenProduction();
        // verifier si il ya une carte master planifier et il n y a pas une carte master publier
        await verifycarteMasterenplanifier();

        setIsOpenShop(!isOpenShop);

        pendingcardmaster &&
            rowData.selectedRow.original.iuudcardreference === "0" &&
            setOpen(true);

        dynamicTableWrapperContext?.setLocalFilterActive(false);
        event.stopPropagation();
    };

    const testCardCanBeArchived = () => {
        // test sur les cartes de boutique
        if (!rowData.selectedRow.original.CardMaster) {
            // if the card is a shop card  we need to see if there is one card or not
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}allcardsofshop/${schemaAdbFranchise}/${rowData.selectedRow.original.iuudcardreference}/${rowData.selectedRow.original.shopid}`
                )
                .then((res: any) => {
                    if (res.data.data.length === 2) {
                        setShopCards(res.data.data);
                    }

                    /// if we have one card (pending or in production) we can not delete it
                    if (res.data.data.length === 1) {
                        setArchive(true);
                    }
                });
        }
        // test sur les cartes de franchise
        if (rowData.selectedRow.original.CardMaster) {
            // if the card is a franchise card  we need to see if there is one card franchise  or not
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}allcardsFranchise/${schemaAdbFranchise}`
                )
                .then((res) => {
                    /// if there is two two card master : we can archive the card master which has not card shop in production
                    if (res.data.data.length > 1) {
                        rowData.selectedRow.original.CardProd === false
                            ? setArchive(false)
                            : setArchive(true);
                    }
                    res.data.data.length === 1 && setArchive(true);
                });
        }
    };
    const verifycarteMasterenProduction = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`
            )
            .then((res) => {
                const dataCardMasterEnProduction: any[] = res.data.data?.filter(
                    (el: any) => {
                        return el.CardProd === true;
                    }
                );
                if (dataCardMasterEnProduction.length === 0) {
                    setcardmaster(false);
                    setpendingcardmaster(false);
                }

                if (dataCardMasterEnProduction.length > 0) {
                    setprodCardName({
                        designation: dataCardMasterEnProduction[0].Désignation,
                        iuud: dataCardMasterEnProduction[0].iuud,
                    });

                    if (rowData.selectedRow.original.CardProd === true) {
                        /// si il  y a  des card master en prod et la carte choisir est une carte master en production

                        setcardmaster(true);
                        setpendingcardmaster(false);
                    } else {
                        /// si il  y a  des card master en prod et la carte choisir est une carte master en attente
                        setpendingcardmaster(true);
                        setcardmaster(false);
                    }
                }
            });
    };
    const verifycarteMasterenplanifier = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`
            )
            .then((res) => {
                const dataCardMasterEnProduction: any[] = res.data.data?.filter(
                    (el: any) => {
                        return el.CardProd === true;
                    }
                );
                if (dataCardMasterEnProduction.length === 0) {
                    const dataCardMasterEnAttente: any[] =
                        res.data.data?.filter((el: any) => {
                            return el.CardProd === false;
                        });
                    //! regle de gestion pour plusieurs carte master en attente et !E de carte master publier et il exist une carte master planifier

                    const CartesenattentePlanifier = [""];

                    dataCardMasterEnAttente
                        .filter(
                            (card) =>
                                (card.iuud || card.iuudcardreference) !==
                                    rowData.selectedRow.original
                                        .iuudcardreference &&
                                (card.iuud || card.iuudcardreference) !==
                                    rowData.selectedRow.original.iuud
                        )
                        .map((el) => {
                            el.subRows.map((el: any) => {
                                el.subRows.map((el: any) => {
                                    const cardPlaningObject = JSON.parse(
                                        JSON.stringify(el?.Planning)
                                    );
                                    !!el?.Planning &&
                                        CartesenattentePlanifier.push(
                                            JSON.parse(cardPlaningObject)
                                                ?.startDate
                                        );
                                });
                            });
                        });
                    setlistedeCartesenattentePlanifier(
                        CartesenattentePlanifier
                    );
                }
            });
    };
    const verifyPlanification = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`
            )
            .then((res) => {
                const dataCardMasterEnProduction: any[] = res.data.data?.filter(
                    (el: any) => {
                        return el.CardProd === true;
                    }
                );
                const dataCardMasterEnAttente: any[] = res.data.data?.filter(
                    (el: any) => {
                        return el.CardProd === false;
                    }
                );

                if (dataCardMasterEnProduction.length > 0) {
                    /// si on une carte master en prod
                    if (rowData.selectedRow.original.CardProd === true) {
                        /// si il  y a  des card master en prod et la carte choisir est une carte master en production
                        const lstOfPlaningDateOfallCardofTheFranchise: any[] =
                            [];
                        dataCardMasterEnAttente.map((el) => {
                            el.subRows.map((el: any) => {
                                el.subRows.map((el: any) => {
                                    const cardPlaningObject = JSON.parse(
                                        JSON.stringify(el?.Planning)
                                    );
                                    !!el?.Planning &&
                                        lstOfPlaningDateOfallCardofTheFranchise.push(
                                            JSON.parse(cardPlaningObject)
                                                ?.startDate
                                        );
                                });
                            });
                        });
                        let closerDatePlan =
                            lstOfPlaningDateOfallCardofTheFranchise[0];
                        for (const datePlan of lstOfPlaningDateOfallCardofTheFranchise) {
                            if (datePlan < closerDatePlan) {
                                closerDatePlan = datePlan;
                            }
                        }
                        setcloserPlaningDate(closerDatePlan);
                    } else {
                        /// si il  y a  des card master en prod et la carte choisir est une carte master en attente
                        const listOfPlaningDateOfallCardofTheFranchise: any[] =
                            [];

                        dataCardMasterEnProduction.map((el) => {
                            el.subRows.map((el: any) => {
                                el.subRows.map((el: any) => {
                                    const cardPlaningObject = JSON.parse(
                                        JSON.stringify(el?.Planning)
                                    );
                                    !!el.Planning &&
                                        listOfPlaningDateOfallCardofTheFranchise.push(
                                            JSON.parse(cardPlaningObject)
                                                ?.startDate
                                        );
                                });
                            });
                        });
                        let farthestDatePlan =
                            listOfPlaningDateOfallCardofTheFranchise[0];
                        for (const datePlan of listOfPlaningDateOfallCardofTheFranchise) {
                            if (datePlan > farthestDatePlan) {
                                farthestDatePlan = datePlan;
                            }
                        }

                        setfarthestPlaningDate(farthestDatePlan);
                    }
                } else {
                    //! regle de gestion pour plusieurs carte master en attente et !E de carte master publier
                }
            });
    };

    useEffect(() => {
        verifycarteMasterenProduction();

        if (
            rowData.selectedRow.original.CardMaster &&
            rowData.selectedRow.original.CardProd !== true
        ) {
            setArchive(false);
        }
        if (
            rowData.selectedRow.original.Cardshop &&
            rowData.selectedRow.original.Etat !== "publier"
        ) {
            setArchive(false);
        }
    }, []);

    return (
        <div>
            {isLoading ? (
                <LoadingDataAnimation />
            ) : (
                <div>
                    {(rowData.selectedRow.original.CardMaster ||
                        rowData.selectedRow.original.Cardshop) && (
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle
                                className="dropdown_custom"
                                color="white"
                                border="none"
                                onClick={(
                                    event: React.MouseEvent<
                                        HTMLDivElement,
                                        MouseEvent
                                    >
                                ) => {
                                    stopPropagation(event);
                                    testCardCanBeArchived();
                                }}
                            >
                                <VerticalDotsIcon height={25} width={25} />
                            </DropdownToggle>

                            <DropdownMenu
                                dropdown-menu
                                aria-labelledby="dropdownMenuButton"
                            >
                                <DropdownItem
                                    className="dropdown-item"
                                    onClick={handlereproduction}
                                    disabled={
                                        (!rowData.selectedRow.original
                                            .CardMaster &&
                                            rowData.selectedRow.original
                                                .CardShopProd === false) ||
                                        (!rowData.selectedRow.original
                                            .CardMaster &&
                                            rowData.selectedRow.original
                                                .Statut !== "En production")
                                    }
                                >
                                    <DuplicateIcon height={25} width={25} />
                                    {t("Duplicate")}
                                </DropdownItem>

                                <DropdownItem
                                    className="dropdown-item"
                                    disabled
                                >
                                    <EyeIcon
                                        className="mr-1"
                                        height={25}
                                        width={25}
                                    />
                                    {t("View")}
                                </DropdownItem>

                                <DropdownItem
                                    className="dropdown-item "
                                    disabled={
                                        rowData.selectedRow.original.Statut ===
                                            "En production" ||
                                        (rowData.selectedRow.original
                                            .iuudcardreference !== "0" &&
                                            (pendingcardmaster === true ||
                                                cardmaster === true))
                                    }
                                    onClick={handleToplan}
                                >
                                    <GridIcon
                                        className="mr-1"
                                        height={25}
                                        width={25}
                                    />
                                    {t("To plan")}
                                </DropdownItem>

                                <DropdownItem
                                    className="dropdown-item "
                                    onClick={handPublish}
                                    disabled={
                                        rowData.selectedRow.original.Statut ===
                                            "En production" ||
                                        (pendingcardmaster &&
                                            rowData.selectedRow.original
                                                .Cardshop === true &&
                                            prodCardName.iuud !==
                                                rowData.selectedRow.original
                                                    .iuudcardreference)
                                    }
                                >
                                    <RedoIcon
                                        className="mr-1"
                                        height={25}
                                        width={25}
                                    />
                                    {t("Publish")}
                                </DropdownItem>

                                <DropdownItem
                                    className="dropdown-item"
                                    disabled={
                                        rowData.selectedRow.original.Statut ===
                                        "En production"
                                    }
                                    onClick={() => {
                                        setisOpenConfigure(true);
                                    }}
                                >
                                    <SettingsIcon
                                        className="mr-1"
                                        height={25}
                                        width={25}
                                    />
                                    {t("Configure")}
                                </DropdownItem>

                                {rowData.selectedRow.original
                                    .iuudcardreference !== "0" && (
                                    <DropdownItem
                                        className="dropdown-item"
                                        disabled={
                                            rowData.selectedRow.original
                                                .Statut === "En production"
                                        }
                                        onClick={() => {
                                            setIsOpenList(true);
                                            dispatch(
                                                setSelectedExchangeCard({
                                                    Card: "",
                                                    Iuud: rowData.selectedRow
                                                        .original.iuud,
                                                })
                                            );
                                        }}
                                    >
                                        <RefreshIcon
                                            height={25}
                                            width={25}
                                            className="mr-1"
                                        />{" "}
                                        {t("Retrieve")}
                                    </DropdownItem>
                                )}

                                <DropdownItem
                                    className="dropdown-item "
                                    onClick={handleSuppression}
                                    disabled={
                                        Archive ||
                                        rowData.selectedRow.original.Statut ===
                                            "En production"
                                    }
                                >
                                    <TrashIcon
                                        className="mr-1"
                                        height={25}
                                        width={25}
                                        fill="red"
                                    />
                                    Archive
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}

                    <ConfigureCard
                        isOpen={isOpenconfigure}
                        setIsOpen={setisOpenConfigure}
                        rowData={rowData}
                        setIsLoading={setIsLoading}
                    />

                    <DeleteCard
                        isOpen={isOpenDelete}
                        setIsOpen={setisOpenDelete}
                        rowData={rowData}
                        setIsLoading={setIsLoading}
                        shopCards={shopCards}
                    />
                    <DuplicateCard
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        rowData={rowData}
                        setIsLoading={setIsLoading}
                    />
                    <ModalPublish
                        isOpen={isOpenShop}
                        setIsOpen={setIsOpenShop}
                        rowData={rowData}
                        cardmaster={cardmaster}
                        pendingcardmaster={pendingcardmaster}
                        prodCardName={prodCardName}
                        isLoading={isLoading}
                        setOpen={setOpen}
                        Open={Open}
                        shopCarlistedeCartesenattentePlanifierds={
                            shopCarlistedeCartesenattentePlanifierds
                        }
                    />

                    <ModalPlaning
                        setSelectedRowsToPlanification={
                            setSelectedRowsToPlanification
                        }
                        selectedRowsToPlanification={
                            selectedRowsToPlanification
                        }
                        isOpenShopList={isOpenShopList}
                        setIsOpenShopList={setIsOpenShopList}
                        Toplan={Toplan}
                        isOpen={isOpenToplan}
                        setIsOpenToplan={setIsOpenToplan}
                        rowData={rowData}
                        farthestPlaningDate={farthestPlaningDate}
                        closerPlaningDate={closerPlaningDate}
                        cardmaster={cardmaster}
                        pendingcardmaster={pendingcardmaster}
                    />

                    <ExchangeCardModal
                        isOpenList={isOpenList}
                        setIsOpenList={setIsOpenList}
                        rowData={rowData}
                    />
                </div>
            )}
        </div>
    );
}
