import unset from "lodash.unset";
import React from "react";

import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { RootState } from "@store/.";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";

import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@aureskonnect/react-ui";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type DeleteStepModalProps = {
    isMultipleStep: boolean;
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    isClickStep: boolean;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    contentModal: any;
    setContentModal: React.Dispatch<any>;
    globalIndex: number;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
};

export function DeleteStepModal({
    isMultipleStep,
    IsBasicCompositionStep,
    IsOptionStep,
    isClickStep,
    setIsClickStep,
    contentModal,
    setContentModal,
    globalIndex,
    setIsOpenStepModal,
    setIndexSelected,
    setIsModify,
    isModify,
}: DeleteStepModalProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const listIuudModifier: any = [];

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const resetOldData = (
        IndexPreviousStep: number,
        previousUuidItem: string,
        previousModifier: string,
        deleteAction: boolean,
        modifierType: string,
        modifierTypeSteps: string,
        isOptionStep: boolean,
        copyModifier: any
    ) => {
        const copyWorkflowData = JSON.parse(
            JSON.stringify(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflowData
            )
        );
        const copyglobalWorkflow = JSON.parse(
            JSON.stringify(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflow
            )
        );
        copyglobalWorkflow[IndexPreviousStep] = {
            ...copyglobalWorkflow[IndexPreviousStep],
            multipleStep: {
                isMultiple: false,
                listeUuidItem: [],
            },
        };

        if (isOptionStep && deleteAction) {
            deleteModifierOptionWorkflow(copyglobalWorkflow, IndexPreviousStep);
        }

        deleteAction &&
            !isOptionStep &&
            delete copyglobalWorkflow[IndexPreviousStep].data[previousUuidItem]
                .content[previousModifier];

        dispatch(setWorkflow(copyglobalWorkflow));

        dispatch(setModifier(copyModifier));
        // copyWorkflowData =
        isOptionStep &&
            deleteAction &&
            deleteModifierOptionWorkflowData(
                copyWorkflowData,
                IndexPreviousStep,
                copyglobalWorkflow
            );

        contentModal[contentModal.length - 1].type === WORKFLOW_LIST.ADD ||
            (isOptionStep && dispatch(setWorkflowData(copyWorkflowData)));

        dispatch(setMultipleWorkflow([]));
        setContentModal([]);
    };
    const deleteModifierOptionWorkflowData = (
        copyWorkflowData: any,
        IndexPreviousStep: number,
        copyglobalWorkflow: any
    ) => {
        const startIndex = copyglobalWorkflow[
            IndexPreviousStep!
        ]?.previousUuids!.indexOf(copyglobalWorkflow[IndexPreviousStep!]?.uuid);

        const UuidSelected = copyglobalWorkflow[startIndex]?.selected;
        const updatedKeyPath = copyglobalWorkflow[startIndex]?.previousUuids;
        let pathIuud: string = "copyWorkflowData?.";

        updatedKeyPath.forEach((key: string) => {
            pathIuud += `["${key}"]?.content`;
        });

        // eslint-disable-next-line no-eval
        const stringEvaluate = eval(pathIuud);
        stringEvaluate[UuidSelected] = {
            ...stringEvaluate[UuidSelected],
            override: {
                ...stringEvaluate[UuidSelected].override,
                [UuidSelected]: {
                    ...stringEvaluate[UuidSelected].override[UuidSelected],
                    overrideOptions: {
                        ...stringEvaluate[UuidSelected].override[UuidSelected]
                            .overrideOptions,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...stringEvaluate[UuidSelected].override[
                                UuidSelected
                            ].overrideOptions[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            content: {
                                ...stringEvaluate[UuidSelected].override[
                                    UuidSelected
                                ].overrideOptions[
                                    contentModal[contentModal.length - 1]
                                        ?.previousUuidStep
                                ].content,
                                [contentModal[contentModal.length - 1]
                                    ?.triggerItemIuud]: {
                                    ...stringEvaluate[UuidSelected].override[
                                        UuidSelected
                                    ].overrideOptions[
                                        contentModal[contentModal.length - 1]
                                            ?.previousUuidStep
                                    ].content[
                                        contentModal[contentModal.length - 1]
                                            ?.triggerItemIuud
                                    ],
                                    modifier: "",
                                },
                            },
                        },
                    },
                },
            },
        };
    };

    const deleteModifierOptionWorkflow = (
        copyGlobalWorkflow: any,
        IndexPreviousStep: number
    ) => {
        const startIndex = copyGlobalWorkflow[
            IndexPreviousStep!
        ]?.previousUuids!.indexOf(copyGlobalWorkflow[IndexPreviousStep!]?.uuid);

        const UuidSelected = copyGlobalWorkflow[startIndex]?.selected;

        copyGlobalWorkflow[startIndex] = {
            ...copyGlobalWorkflow[startIndex],
            data: {
                ...copyGlobalWorkflow[startIndex].data,
                [UuidSelected]: {
                    ...copyGlobalWorkflow[startIndex].data[UuidSelected],
                    override: {
                        ...copyGlobalWorkflow[startIndex].data[UuidSelected]
                            .override,
                        [UuidSelected]: {
                            ...copyGlobalWorkflow[startIndex].data[UuidSelected]
                                .override[UuidSelected],
                            overrideOptions: {
                                ...copyGlobalWorkflow[startIndex].data[
                                    UuidSelected
                                ].override[UuidSelected].overrideOptions,
                                [contentModal[contentModal.length - 1]
                                    ?.previousUuidStep]: {
                                    ...copyGlobalWorkflow[startIndex].data[
                                        UuidSelected
                                    ].override[UuidSelected].overrideOptions[
                                        contentModal[contentModal.length - 1]
                                            ?.previousUuidStep
                                    ],
                                    content: {
                                        ...copyGlobalWorkflow[startIndex].data[
                                            UuidSelected
                                        ].override[UuidSelected]
                                            .overrideOptions[
                                            contentModal[
                                                contentModal.length - 1
                                            ]?.previousUuidStep
                                        ].content,
                                        [contentModal[contentModal.length - 1]
                                            ?.triggerItemIuud]: {
                                            ...copyGlobalWorkflow[startIndex]
                                                .data[UuidSelected].override[
                                                UuidSelected
                                            ].overrideOptions[
                                                contentModal[
                                                    contentModal.length - 1
                                                ]?.previousUuidStep
                                            ].content[
                                                contentModal[
                                                    contentModal.length - 1
                                                ]?.triggerItemIuud
                                            ],
                                            modifier: "",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        };
    };
    const deleteModifierOptionFromModifier = (copyModifier: any) => {
        const UuidModifier =
            contentModal[contentModal.length - 2].previousModifierStep;

        const UuidStep = contentModal[contentModal.length - 2].previousUuidStep;

        const UuidItem = contentModal[contentModal.length - 2].triggerItemIuud;

        const UuidOptionParent =
            contentModal[contentModal.length - 1].previousUuidStep;

        const UuidOptionValue =
            contentModal[contentModal.length - 1].triggerItemIuud;

        const modifierType =
            contentModal[contentModal.length - 2].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS
                : WORKFLOW_LIST.ADD;

        copyModifier[UuidModifier] = {
            ...copyModifier[UuidModifier],
            [modifierType]: {
                ...copyModifier[UuidModifier][modifierType],
                [UuidStep]: {
                    ...copyModifier[UuidModifier][modifierType][UuidStep],
                    overrides: {
                        ...copyModifier[UuidModifier][modifierType][UuidStep]
                            .overrides,
                        stepItems: {
                            ...copyModifier[UuidModifier][modifierType][
                                UuidStep
                            ].overrides.stepItems,
                            [UuidItem]: {
                                ...copyModifier[UuidModifier][modifierType][
                                    UuidStep
                                ].overrides.stepItems[UuidItem],
                                overrideOptions: {
                                    ...copyModifier[UuidModifier][modifierType][
                                        UuidStep
                                    ].overrides.stepItems[UuidItem]
                                        .overrideOptions,
                                    [UuidOptionParent]: {
                                        ...copyModifier[UuidModifier][
                                            modifierType
                                        ][UuidStep].overrides.stepItems[
                                            UuidItem
                                        ].overrideOptions[UuidOptionParent],
                                        content: {
                                            ...copyModifier[UuidModifier][
                                                modifierType
                                            ][UuidStep].overrides.stepItems[
                                                UuidItem
                                            ].overrideOptions[UuidOptionParent]
                                                .content,
                                            [UuidOptionValue]: {
                                                ...copyModifier[UuidModifier][
                                                    modifierType
                                                ][UuidStep].overrides.stepItems[
                                                    UuidItem
                                                ].overrideOptions[
                                                    UuidOptionParent
                                                ].content[UuidOptionValue],
                                                modifier: "",
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        };
    };

    const deleteUuidModifier = (
        copyModifier: any,
        iuudStep: string,
        previousModifier: string,
        modifierType: string
    ) => {
        Object.entries(
            copyModifier[previousModifier][modifierType][iuudStep].itemModifiers
        )?.forEach(([, iuudModifier]: any) => {
            listIuudModifier.push(iuudModifier);
            if (
                Object.keys?.(copyModifier[iuudModifier][modifierType]).length >
                0
            ) {
                Object.entries(
                    copyModifier[iuudModifier][modifierType]
                )?.forEach(([iuudStepModifier]: any) => {
                    if (
                        Object.keys?.(
                            copyModifier[iuudModifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers
                        ).length > 0
                    ) {
                        deleteUuidModifier(
                            copyModifier,
                            iuudStepModifier,
                            iuudModifier,
                            modifierType
                        );
                    }
                });
            }
        });
    };
    const deleteStep = () => {
        const copymultipleStep = JSON.parse(JSON.stringify(multipleWorkflow));
        let copyModifier = contentModal[contentModal.length - 1]?.isMultiple
            ? JSON.parse(
                  JSON.stringify(
                      copymultipleStep[copymultipleStep.length - 1]
                          .VirtualModifier
                  )
              )
            : JSON.parse(JSON.stringify(modifierWorkflow));

        const newArray = [...contentModal[contentModal.length - 1].data];
        const indexDelete = newArray.indexOf(
            contentModal[contentModal.length - 1].data[globalIndex]
        );
        const { previousUuidStep } = contentModal[contentModal.length - 1];
        const previousUuidItem =
            contentModal[contentModal.length - 1].triggerItemIuud;
        const { previousModifierStep } = contentModal[contentModal.length - 1];
        const iuudStep =
            contentModal[contentModal.length - 1].data[globalIndex].uuidStep!;
        const previousModifier =
            contentModal[contentModal.length - 1].previousModifier!;
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        const copyGlobalWorkflow: any = JSON.parse(
            JSON.stringify(globalWorkflow)
        );
        const IndexPreviousStep =
            contentModal[contentModal.length - 1].indexPreviousStep;

        const isOptionStep =
            contentModal.length === 1
                ? copyGlobalWorkflow[IndexPreviousStep].type ===
                  WORKFLOW_LIST.OPTIONS
                : contentModal.length > 1 &&
                  contentModal[contentModal.length - 2]?.data[IndexPreviousStep]
                      ?.type === WORKFLOW_LIST.OPTIONS;

        let modifierType: string = "";
        let modifierTypeSteps: string = "";
        if (
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
        ) {
            modifierType = WORKFLOW_LIST.STEPS;
            modifierTypeSteps = WORKFLOW_LIST.TYPESTEPS;
        } else {
            modifierType = WORKFLOW_LIST.ADD;
            modifierTypeSteps = WORKFLOW_LIST.TYPEADD;
        }

        if (indexDelete !== -1) {
            newArray.splice(globalIndex, 1);
        }
        let count = 1;
        // eslint-disable-next-line array-callback-return
        newArray.map((element: any, index: number) => {
            if (element.type === WORKFLOW_LIST.OPTIONS) {
                count += 1;
            }
            if (element.type === WORKFLOW_LIST.STEPS) {
                newArray[index] = {
                    ...newArray[index],
                    title: `Etape ${count}`,
                };
                count += 1;
            }
        });
        // delete modifier from modifier
        iuudStep !== "" &&
            iuudStep !== undefined &&
            iuudStep !== WORKFLOW_LIST.BASICCOMPOSITION &&
            Object.keys?.(
                copyModifier[previousModifier][modifierType][iuudStep]
                    .itemModifiers
            ).length > 0 &&
            deleteUuidModifier(
                copyModifier,
                iuudStep,
                previousModifier,
                modifierType
            );

        listIuudModifier.length > 0 &&
            listIuudModifier.forEach((element: string) => {
                unset(copyModifier, element);
            });
        if (
            count === 1 &&
            (copyModifier[previousModifier].typeSteps === false ||
                copyModifier[previousModifier].typeAdd === false)
        ) {
            // delete modifier from modifier
            const pathPreviousModifierStep: string = `${previousModifierStep}.${modifierType}.${previousUuidStep}.itemModifiers.${previousUuidItem}`;
            unset(copyModifier, pathPreviousModifierStep);

            delete copyModifier[previousModifier];

            // delete from globalworkflow
            if (contentModal.length === 1) {
                copyGlobalWorkflow[IndexPreviousStep] = {
                    ...copyGlobalWorkflow[IndexPreviousStep],
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                };

                resetOldData(
                    IndexPreviousStep,
                    previousUuidItem,
                    previousModifier,
                    true,
                    modifierType,
                    modifierTypeSteps,
                    isOptionStep,
                    copyModifier
                );

                setIsOpenStepModal(false);
                return;
            }
            isOptionStep && deleteModifierOptionFromModifier(copyModifier);

            const IndexPreviousStepModal = contentModal[
                contentModal.length - 2
            ].data.findIndex(
                (element: any) =>
                    element.uuidStep ===
                    contentModal[contentModal.length - 1].previousUuidStep
            );
            delete contentModal[contentModal.length - 2].data[
                IndexPreviousStepModal
            ].data[previousUuidItem].content[previousModifier];

            dispatch(
                setMultipleWorkflow(
                    contentModal[contentModal.length - 1].previousState
                        .multipleWorkflow
                )
            );
            contentModal.splice(contentModal.length - 1, 1);
            contentModal[contentModal.length - 1].data[IndexPreviousStep] = {
                ...contentModal[contentModal.length - 1].data[
                    IndexPreviousStep
                ],
                multipleStep: {
                    isMultiple: false,
                    listeUuidItem: [],
                },
            };
        } else if (count === 1) {
            copyModifier = {
                ...copyModifier,
                [previousModifier]: {
                    ...copyModifier[previousModifier],
                    [modifierType]: {},
                    [modifierTypeSteps]: false,
                },
            };
            if (contentModal.length === 1) {
                copyGlobalWorkflow[IndexPreviousStep] = {
                    ...copyGlobalWorkflow[IndexPreviousStep],
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                };
                resetOldData(
                    IndexPreviousStep,
                    previousUuidItem,
                    previousModifier,
                    false,
                    modifierType,
                    modifierTypeSteps,
                    isOptionStep,
                    copyModifier
                );

                setIsOpenStepModal(false);
                return;
            }
            isOptionStep && deleteModifierOptionFromModifier(copyModifier);

            dispatch(
                setMultipleWorkflow(
                    contentModal[contentModal.length - 1].previousState
                        .multipleWorkflow
                )
            );

            contentModal.splice(contentModal.length - 1, 1);
            contentModal[contentModal.length - 1].data[IndexPreviousStep] = {
                ...contentModal[contentModal.length - 1].data[
                    IndexPreviousStep
                ],
                multipleStep: {
                    isMultiple: false,
                    listeUuidItem: [],
                },
            };
        } else {
            const path: string = `${previousModifier}.${modifierType}.${iuudStep}`;
            iuudStep !== "" && unset(copyModifier, path);
            contentModal[contentModal.length - 1] = {
                ...contentModal[contentModal.length - 1],
                data: newArray,
            };
        }

        if (contentModal[contentModal.length - 1]?.isMultiple) {
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: copyModifier,
            };

            count > 1 && dispatch(setMultipleWorkflow(copymultipleStep));
        } else {
            dispatch(setModifier(copyModifier));
        }
        setIndexSelected(copyGlobalWorkflow.length - 1);
        setIsModify(!isModify);
        dispatch(setWorkflow(copyGlobalWorkflow));
        dispatch(setWorkflowData(copyGlobalWorkflowData));
    };

    return (
        <div
            className={
                isMultipleStep
                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                    : "icon_step__CLZ text-center mr-2"
            }
            style={
                cardType === "shop" && cardStatus === "En production"
                    ? { pointerEvents: "none" }
                    : {
                          display:
                              IsBasicCompositionStep || IsOptionStep
                                  ? "none"
                                  : "d-flex justify-content-center align-items-center",
                      }
            }
        >
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={t("Remove step")}
                id={`Corbeille${globalIndex}`}
            />

            <TrashIcon
                id={`Corbeille${globalIndex}`}
                fill="red"
                role="button"
                width={20}
                height={20}
                onClick={() => {
                    deleteStep();
                    setIsClickStep(!isClickStep);
                }}
            />
        </div>
    );
}
