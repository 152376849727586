import shortid from "shortid";
import { VerticalDotsIcon, PlusIcon } from "@aureskonnect/react-ui";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import "./style.css";

function FirstTable() {
    const { t } = useTranslation();
    return (
        <Table responsive>
            <thead>
                <tr style={{ border: "none" }}>
                    {Array(7)
                        .fill(0)
                        .map((item: any, index) => {
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <th className="border_table">
                                    <select
                                        style={{
                                            border: "0px none",
                                            backgroundColor:
                                                " rgb(255, 255, 255)",
                                            width: " 100%",
                                        }}
                                    >
                                        <option key={shortid.generate() + item}>
                                            {t("column")} {index + 1}
                                        </option>
                                    </select>
                                </th>
                            );
                        })}
                    <th
                        style={{
                            borderBottomColor: " #555b6d ",
                            visibility: "hidden",
                        }}
                    >
                        <div className=" d-flex align-items-center d-flex justify-content-end">
                            <PlusIcon
                                height={10}
                                width={10}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                            <VerticalDotsIcon
                                height={20}
                                width={20}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Array(16)
                    .fill(0)
                    .map(() => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <tr>
                                <th colSpan={8}> </th>
                            </tr>
                        );
                    })}
            </tbody>
        </Table>
    );
}

export default FirstTable;
