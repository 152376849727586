/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from "react";

export default function SelectRowDropdown() {
    return (
        <div
            className="w-100
"
        >
            <div className="dropdown d-none">
                {/* <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />  */}
                {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            dispatch(selectedClient(props.selectedRow.original));
                        }}
                    >
                        {t("Access the card")}
                    </div>
                    <div className="dropdown-item">{t("See the list of stores")}</div>
                </div> */}
            </div>
        </div>
    );
}
