import ReactSwitch from "react-switch";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/.";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";

type SellingSupportVisibilityPropsType = {
    sellingSupportVisibility: sellingSupportVisibilityType;
    salesMode: string;
    defaultVisibility: visibilityCategoryType;
    dispatch: Function;
};

export default function SellingSupportVisibility({
    sellingSupportVisibility,
    salesMode,
    defaultVisibility,
    dispatch,
}: SellingSupportVisibilityPropsType) {
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );

    function updateSaleSupportVisibility(
        defaultVisibility: Record<string, visibilityInfoType>,
        salesMode: string,
        sellingSupportVisibility: sellingSupportVisibilityType
    ) {
        dispatch({
            type: ACTIONS.INIT_VISIBILITY,
            payload: {
                ...defaultVisibility,
                [salesMode]: {
                    ...defaultVisibility[salesMode],
                    visibility: defaultVisibility[salesMode].visibility,
                    sellingSupport: [
                        ...defaultVisibility[salesMode].sellingSupport.map(
                            (sellingSupport): sellingSupportVisibilityType =>
                                sellingSupport.sellingSupport ===
                                sellingSupportVisibility.sellingSupport
                                    ? {
                                          ...sellingSupport,
                                          visibility:
                                              !sellingSupportVisibility.visibility,
                                      }
                                    : sellingSupport
                        ),
                    ],
                },
            },
        });

        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.VISIBILITYINFO));
    }

    return (
        <div>
            <ReactSwitch
                checkedIcon={false}
                uncheckedIcon={false}
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={20}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={() => {
                    updateSaleSupportVisibility(
                        defaultVisibility.default,
                        salesMode,
                        sellingSupportVisibility
                    );
                }}
                disabled={
                    defaultVisibility.isVisible === false ||
                    defaultVisibility.default[salesMode].visibility === false
                }
                checked={sellingSupportVisibility.visibility}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={45}
                height={15}
            />
        </div>
    );
}
