import React, { useState } from "react";

import { useSelector } from "react-redux";
import { StyledLabel } from "@aureskonnect/react-ui";
import { Input } from "reactstrap";
import shortid from "shortid";
import { useTranslation } from "react-i18next";

import { RootState } from "@store/index";
import AddPeriod from "./AddPeriod";
import AddDate from "./AddDate";
import ModifyDate from "./ModifyDate";
import ModifyPeriod from "./ModifyPeriod";

const ACTIONS = {
    START_DATE: "DateDebut",
    END_DATE: "DateFin",
};
export type PlanningType = {
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    setPeriod: React.Dispatch<any>;
    period: any;
    enable: boolean;
    setEnable: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Planning({
    setIsActive,
    setPeriod,
    period,
    enable,
    setEnable,
}: PlanningType) {
    const { t } = useTranslation();
    const [, setRender] = useState("");

    const indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    const { principalIndex } = indexPeriodCopyPrincipal;

    /* Verify Name periode */

    if (period.length === 1 && period[0].name === "") {
        setIsActive(false);
    }

    function handlechangeEventDate(
        index: any,
        data: any,
        indexdata: number,
        datedebut: any,
        actionType: string
    ) {
        setPeriod((prevState: any) => {
            const newState = [...prevState];

            function NewDate() {
                const selectedDate = {
                    ...newState[index].date[indexdata],
                };

                switch (actionType) {
                    case ACTIONS.START_DATE:
                        if (data.length === 0) {
                            selectedDate.DateFin = "";
                        }
                        selectedDate.DateDebut = data;
                        return selectedDate;

                    case ACTIONS.END_DATE:
                        if (data < datedebut || datedebut.length === 0) {
                            data = "";
                        }

                        selectedDate.DateFin = data;
                        return selectedDate;
                    default:
                        return selectedDate;
                }
            }
            const updatedDate = NewDate();

            const newArr = [...newState[index].date];
            newArr[indexdata] = updatedDate;

            newState[index] = {
                ...newState[index],
                date: newArr,
            };
            /* Verify Enable field */
            const VerifyDateDebut = newState[index].date.findIndex(
                (element: any) => element.DateDebut.length !== 0
            );
            const VerifyDateFin = newState[index].date.findIndex(
                (element: any) => element.DateFin.length !== 0
            );

            if (newState[index].active === true) {
                if (VerifyDateDebut !== -1) {
                    setEnable(true);
                } else {
                    setEnable(false);
                    setIsActive(false);
                }
                if (VerifyDateFin !== -1) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            }

            return newState;
        });
    }

    return (
        <fieldset
            className="fieldset__clz scrollAdd__clz"
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <legend style={{ marginLeft: "10px" }} className="w-auto">
                <StyledLabel className="text-uppercase">
                    {t("Choose the period")}
                </StyledLabel>
            </legend>
            {period.map((element: any, indice: number) => {
                return (
                    <fieldset
                        className="fieldset__clz  ml-2 mr-2  mb-2"
                        key={`${element.id} periode`}
                    >
                        <ModifyPeriod
                            setPeriod={setPeriod}
                            period={period}
                            ValueRender={`${element.name} ${indice} ${element.length} ${period.length}`}
                            indexPeriod={indice}
                            setRender={setRender}
                            setEnable={setEnable}
                            setIsActive={setIsActive}
                        />

                        {element.date.map((item: any, index: number) => {
                            return (
                                <fieldset
                                    className="fieldset__clz ml-2 mr-2  mb-2"
                                    key={shortid.generate()}
                                >
                                    <div className=" d-flex ml-2 mr-2 mt-2 mb-2  justify-content-between align-items-center">
                                        <div className=" ml-2 d-flex flex-column">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <StyledLabel className="pr-2 ">
                                                    {t("Start date")}
                                                </StyledLabel>

                                                <Input
                                                    type="date"
                                                    id="start"
                                                    name="trip-start"
                                                    value={item.DateDebut}
                                                    style={{
                                                        marginLeft: "auto",
                                                        width: "auto",
                                                    }}
                                                    onChange={(e: any) => {
                                                        handlechangeEventDate(
                                                            indice,
                                                            e.target.value,
                                                            index,
                                                            e.target.value,
                                                            ACTIONS.START_DATE
                                                        );

                                                        setRender(
                                                            `${item.name} ${index} ${item.DateDebut} Change date debut`
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="pt-2 d-flex justify-content-center align-items-center">
                                                <StyledLabel className="pr-2">
                                                    {t("End date")}
                                                </StyledLabel>
                                                <Input
                                                    type="date"
                                                    id="end"
                                                    name="trip-end"
                                                    value={item.DateFin}
                                                    style={{
                                                        marginLeft: "auto",
                                                        width: "auto",
                                                    }}
                                                    onChange={(e: any) => {
                                                        handlechangeEventDate(
                                                            indice,
                                                            e.target.value,
                                                            index,
                                                            item.DateDebut,
                                                            ACTIONS.END_DATE
                                                        );

                                                        setRender(
                                                            `${item.name} ${index} ${item.DateFin} Change date fin`
                                                        );
                                                    }}
                                                    disabled={
                                                        enable === false &&
                                                        principalIndex ===
                                                            indice
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <ModifyDate
                                            setPeriod={setPeriod}
                                            period={period}
                                            ValueRender={`${item.name} ${index} ${element.date.length}`}
                                            indexPeriod={indice}
                                            indexDate={index}
                                            setRender={setRender}
                                        />
                                    </div>
                                </fieldset>
                            );
                        })}

                        <AddDate
                            setPeriod={setPeriod}
                            ValueRender={`${element.name}+${element.date.length}`}
                            indice={indice}
                            LengthName={element.name.length}
                            setRender={setRender}
                        />
                    </fieldset>
                );
            })}

            <AddPeriod
                setPeriod={setPeriod}
                period={period}
                lengthPeriod={period.length}
                setRender={setRender}
            />
        </fieldset>
    );
}
