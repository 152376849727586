import React from "react";
import { RootState } from "@store/.";
import { DominantColor } from "@components/Common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { StyledButton, StyledButtonWithIcon } from "@aureskonnect/react-ui";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { resetMultipleWorkflow } from "@pages/WorkFlow/helpers";
import { v4 as uuidv4 } from "uuid";
import { setModifier } from "@store/features/modifierSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";

type addStepProps = {
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    globalIndex: number;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    uuid: string;
    startIndex: number;
    isRedirect?: boolean;
};

export function AddStep({
    globalIndex,
    setIndexSelected,
    setIsClickStep,
    isClickStep,
    uuid,
    startIndex,
    isRedirect,
}: addStepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dominantColor: string = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    let copyModifierMultiple =
        multipleWorkflow.length > 0
            ? JSON.parse(
                  JSON.stringify(
                      multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier
                  )
              )
            : {};

    const addStep = () => {
        // position to start path step
        const positionStartUuids = globalWorkflow[
            globalIndex!
        ].previousUuids!.indexOf(globalWorkflow[globalIndex!].uuid);
        const copyPreviousUuids = [
            ...globalWorkflow[globalIndex!].previousUuids!,
        ];
        copyPreviousUuids.length = positionStartUuids + 1;

        const count = globalWorkflow.filter((element: any) => {
            if (element.type === WORKFLOW_LIST.STEPS) {
                return true;
            }

            return false;
        }).length;
        dispatch(
            setWorkflow([
                ...globalWorkflow,
                {
                    title: `Etape ${count + 1}`,
                    data: [],
                    uuid,
                    type: WORKFLOW_LIST.STEPS,
                    previousModifier:
                        globalWorkflow[globalIndex!].previousModifier,
                    previousUuids: copyPreviousUuids,
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                    uuidStep: "",
                    previousState: {},
                },
            ])
        );
    };
    const checkSalesAddExist = (firstUuidSelected: string) => {
        const salesAdd: any = { isSalesAdd: false, modifierSalesAdd: "" };

        if (
            Object.keys?.(
                globalWorkflow[startIndex].data[firstUuidSelected].content || {}
            ).length > 0
        ) {
            // eslint-disable-next-line prefer-destructuring
            salesAdd.modifierSalesAdd = Object.keys(
                globalWorkflow[startIndex].data[firstUuidSelected].content
            )[0];
            if (
                Object.keys?.(
                    copyModifierMultiple[salesAdd.modifierSalesAdd] || {}
                ).length > 0 &&
                copyModifierMultiple[salesAdd.modifierSalesAdd][
                    WORKFLOW_LIST.TYPEADD
                ]
            ) {
                salesAdd.isSalesAdd = true;
            }
        }
        return salesAdd;
    };
    const insertUuidMultipleModifier = (
        previousModifier: string,
        MD5modifier: string,
        modifierType: string,
        stepObject: any,
        action: string
    ) => {
        // const MD5modifier2 = uuidv4();

        if (action === "second") {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: copyModifierMultiple?.[previousModifier!],
            };
            // eslint-disable-next-line no-prototype-builtins
        } else if (copyModifierMultiple.hasOwnProperty(MD5modifier)) {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: {
                    ...copyModifierMultiple[MD5modifier],
                    typeSteps: true,
                    steps: stepObject,
                },
            };
        } else {
            copyModifierMultiple = {
                ...copyModifierMultiple,
                [MD5modifier]: {
                    steps: stepObject,
                    add: {},
                    category: {},
                    typeSteps: true,
                    typeAdd: false,
                    typeCategorie: false,
                },
            };
        }

        if (
            Object.keys?.(copyModifierMultiple[MD5modifier][modifierType] || {})
                .length > 0
        ) {
            Object.entries(
                copyModifierMultiple[MD5modifier][modifierType]
            )?.forEach(([iuudStepModifier]: any) => {
                if (
                    Object.keys?.(
                        copyModifierMultiple[MD5modifier][modifierType][
                            iuudStepModifier
                        ].itemModifiers
                    ).length > 0
                ) {
                    Object.entries(
                        copyModifierMultiple[MD5modifier][modifierType][
                            iuudStepModifier
                        ].itemModifiers
                    )?.forEach(([iuudItemModifier]: any) => {
                        const MD5modifier2 = uuidv4();
                        previousModifier =
                            copyModifierMultiple[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers[iuudItemModifier];
                        copyModifierMultiple = {
                            ...copyModifierMultiple,
                            [MD5modifier]: {
                                ...copyModifierMultiple[MD5modifier],
                                [modifierType]: {
                                    ...copyModifierMultiple[MD5modifier][
                                        modifierType
                                    ],
                                    [iuudStepModifier]: {
                                        ...copyModifierMultiple[MD5modifier][
                                            modifierType
                                        ][iuudStepModifier],
                                        itemModifiers: {
                                            ...copyModifierMultiple[
                                                MD5modifier
                                            ][modifierType][iuudStepModifier]
                                                .itemModifiers,
                                            [iuudItemModifier]: MD5modifier2,
                                        },
                                    },
                                },
                            },
                        };

                        insertUuidMultipleModifier(
                            previousModifier,
                            MD5modifier2,
                            modifierType,
                            stepObject,
                            "second"
                        );
                    });
                }
            });
        }
    };
    const validateMultipleWorkflow = () => {
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );

        const globalIndex = startIndex;
        const listUuidItem = multipleWorkflow[0]?.listeUuidItem;
        const previousModifier = multipleWorkflow[0]?.PreviousModifier;
        const stepObject = copyModifierMultiple?.[previousModifier!].steps;

        /* concatenate virtual modifier to modifier */
        copyModifierMultiple = { ...copyModifierMultiple, ...modifierWorkflow };

        listUuidItem.forEach((elementUuid: string) => {
            const salesAdd = checkSalesAddExist(elementUuid);

            const MD5modifier = salesAdd.modifierSalesAdd;
            // const MD5modifier = uuidv4();
            let pathIuud: string = "copyGlobalWorkflowData?.";
            insertUuidMultipleModifier(
                previousModifier,
                MD5modifier,
                WORKFLOW_LIST.STEPS,
                stepObject,
                "first"
            );
            // insert modifier to global workflow
            copyWorkflow[startIndex!] = {
                ...copyWorkflow[startIndex!],
                data: {
                    ...copyWorkflow[startIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflow[startIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            /* duplicate workflow data */
            if (!salesAdd.isSalesAdd) {
                [
                    ...globalWorkflow[globalIndex!].previousUuids!,
                    elementUuid,
                ]?.forEach((element: string) => {
                    pathIuud += `["${element}"]?.content`;
                });

                pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
                // eslint-disable-next-line no-eval
                const stringEvaluate = eval(pathIuud);
                stringEvaluate.content = {};
                stringEvaluate.content[MD5modifier] = {
                    type: "modifier",
                    override: {},
                    content: {},
                };
            }
        });

        /* duplicate in workflow data */
        dispatch(setWorkflowData(copyGlobalWorkflowData));

        /* reset multiple workflow */
        copyWorkflow = copyWorkflow.slice(0, startIndex! + 1);
        resetMultipleWorkflow(copyWorkflow, globalIndex);

        dispatch(setModifier(copyModifierMultiple));
        dispatch(setWorkflow(copyWorkflow));
        dispatch(setMultipleWorkflow([]));
    };
    const CancelMultipleWorkflow = () => {
        const globalIndex = multipleWorkflow[0]?.StepIndex;
        const copyWorkflow: any = JSON.parse(JSON.stringify(globalWorkflow));

        const copyglobalWorkflow = JSON.parse(
            JSON.stringify(
                copyWorkflow[globalIndex!]?.previousState?.globalWorkflow
            )
        );
        copyglobalWorkflow[globalIndex] = {
            ...copyglobalWorkflow[globalIndex],
            multipleStep: {
                isMultiple: false,
                listeUuidItem: [],
            },
        };

        dispatch(setWorkflow(copyglobalWorkflow));
        dispatch(
            setWorkflowData(
                copyWorkflow[globalIndex!]?.previousState?.globalWorkflowData
            )
        );
        dispatch(setMultipleWorkflow([]));
        setIndexSelected(globalIndex + 1);
        setIsClickStep(!isClickStep);
    };
    return (
        <div
            className="ml-2"
            style={{
                minWidth: "200px",
            }}
            id="add step"
        >
            {!isRedirect && (
                <AvForm
                    onSubmit={() => {
                        addStep();
                        setIndexSelected(globalIndex + 1);
                        setIsClickStep(!isClickStep);
                    }}
                    style={{
                        visibility:
                            Object.keys(
                                globalWorkflow[globalWorkflow.length - 1]
                                    ?.data || {}
                            ).length > 0
                                ? "visible"
                                : "hidden",
                    }}
                >
                    <StyledButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant={dominantColor}
                        disabled={
                            cardType === "shop" &&
                            cardStatus === "En production"
                        }
                        style={{ width: "90%" }}
                    >
                        {t("Add Step")}
                    </StyledButtonWithIcon>
                </AvForm>
            )}
            <div
                style={{
                    visibility:
                        globalWorkflow[startIndex]?.multipleStep.isMultiple &&
                        multipleWorkflow.length > 0
                            ? "visible"
                            : "hidden",
                }}
            >
                <StyledButton
                    className="mt-5"
                    rounded
                    variant={dominantColor}
                    style={{ width: "90%" }}
                    onClick={() => {
                        validateMultipleWorkflow();
                    }}
                >
                    Valider parcours
                </StyledButton>
                <StyledButton
                    className="mt-2"
                    rounded
                    outline
                    variant="light"
                    style={{ width: "90%" }}
                    onClick={() => {
                        CancelMultipleWorkflow();
                    }}
                >
                    {t("Cancel")}
                </StyledButton>
            </div>
        </div>
    );
}
