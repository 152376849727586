import React from "react";

import { Container } from "reactstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { PageTitle } from "@components/index";
import { DynamicTableWrapper } from "@components/Common";

import AddStep from "@pages/Catalogue/steps/AddStep";
import WorkflowGlobalModal from "./WorkflowGlobalModal";

import "@components/Common/CardMenu/Cartestyle.css";

export type WorkFlowModalProps = {
    contentModal: any;
    setContentModal: React.Dispatch<any>;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    cardData?: any;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    othersData: {
        itemOthersData: null;
        categoryOthersData: null;
        stepOthersData: null;
    };
};

export default function WorkFlowModalIndex({
    contentModal,
    setContentModal,
    setIsModify,
    isModify,
    cardData,
    setIsOpenStepModal,
    othersData,
}: WorkFlowModalProps) {
    return (
        <React.Fragment>
            <PageTitle title="WorkFlow" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <Container fluid>
                    <DynamicTableWrapper
                        TableComponent={
                            <WorkflowGlobalModal
                                setContentModal={setContentModal}
                                contentModal={contentModal}
                                setIsModify={setIsModify}
                                isModify={isModify}
                                cardData={cardData}
                                setIsOpenStepModal={setIsOpenStepModal}
                            />
                        }
                        ActionColumnComponent={
                            <AddStep
                                contentModal={contentModal}
                                setContentModal={setContentModal}
                                setIsModify={setIsModify}
                                isModify={isModify}
                                initialData={{
                                    othersData: othersData.stepOthersData
                                        ? othersData.itemOthersData
                                        : {},
                                }}
                            />
                        }
                        showFormsInModal
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
