import React from "react";

import { useTranslation } from "react-i18next";
import { AvRadioGroup, AvRadio, AvForm } from "availity-reactstrap-validation";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";

import "../Style.css";

import {
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
    ErrorToast,
} from "@components/Common/";
import { getNestedId } from "@helpers/getNestedId";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DeleteCategoryModal({
    isOpen,
    setIsOpen,
    cardIuud,
    categoryToDelete,
    setIsLoading,
}: {
    isOpen: boolean;
    setIsOpen: Function;
    cardIuud: string;
    categoryToDelete: {
        iuud: string;
        title: string;
        nestedRowsId: any;
    };
    setIsLoading: Function;
}): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const [isMultiDelete, setIsMultiDelete] = React.useState(false);

    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    let categoryIuuds: any[] = [];

    let url = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/categories/${categoryToDelete.iuud}`;
    if (isMultiDelete === true) {
        url = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/categories`;
        categoryIuuds = getNestedId((x: any) => x.iuud)(
            categoryToDelete.nestedRowsId
        );
    }

    const deleteNestedCategories = async (): Promise<void> => {
        try {
            axios
                .delete(url, {
                    data: {
                        itemsIuuds: categoryIuuds,
                    },
                })
                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    setIsOpen(false);
                })
                .then((): void => {
                    SuccessToast(t("The category  deleted successfully"));
                })
                .finally((): void => {
                    setIsLoading(false);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    const handleRemoveButtonOnClickEvent = () => {
        setIsLoading(true);

        deleteNestedCategories();
        /*  categoryToDelete.nestedRowsId.length > 1
            ? deleteNestedCategories()
            : deleteCategory(); */
    };

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader
                className="text-uppercase h2 "
                toggle={(): boolean => setIsOpen(!isOpen)}
            >
                <div className="d-flex" style={{ whiteSpace: "nowrap" }}>
                    {categoryToDelete.title.length > 20 ? (
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={categoryToDelete.title}
                            id="archiver"
                        />
                    ) : null}
                    {t("Archive Category")} <span className="mx-1 ">:</span>
                    <div>
                        <div id="archiver" className="modal_clz  ">
                            {categoryToDelete.title}
                        </div>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                {categoryToDelete.nestedRowsId.length === 1
                    ? t("Do you want to confirm the deletion of this category?")
                    : t("Do you want to archive this category")}
                :
                <AvForm
                    model={{
                        deleteMode: "simpleDelete",
                        checkItOut: true,
                    }}
                >
                    <AvRadioGroup
                        name="deleteMode"
                        required
                        errorMessage="Pick one!"
                    >
                        <AvRadio
                            label={t("with items")}
                            value="simpleDelete"
                            onChange={() => {
                                setIsMultiDelete(false);
                            }}
                        />
                        <AvRadio
                            label={t("with items and  subcategories")}
                            value="withSubRows"
                            onChange={() => {
                                if (
                                    categoryToDelete.nestedRowsId.category
                                        .categoryChild.length > 0
                                ) {
                                    setIsMultiDelete(true);
                                } else {
                                    setIsMultiDelete(false);
                                }
                            }}
                        />
                    </AvRadioGroup>
                </AvForm>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleRemoveButtonOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
