/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { ModalHeader } from "reactstrap";
import { ColumnDef, Row } from "@tanstack/react-table";
import { ModalBody, ModalFooter } from "react-bootstrap";
import {
    StyledButton,
    StyledH2,
    StyledModal,
    StyledSearchInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { DominantColor } from "@components/Common/dominantColor";
import {
    DynamicTableWrapperContext,
    ErrorToast,
    SuccessToast,
} from "@components/Common";
import { RootState } from "@store/index";
import PrintersTable from "./PrintersTable";

type PrintersModalPropsType = {
    isPrintersModalOpen: boolean;
    setIsPrintersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PrintersModal({
    isPrintersModalOpen,
    setIsPrintersModalOpen,
}: PrintersModalPropsType) {
    const { t } = useTranslation();

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const shopId = localStorage.getItem("shopId");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const printers = useSelector(
        (state: RootState) => state.settingsSlice.printers
    );

    const printersTableColumns: ColumnDef<PrinterType>[] = React.useMemo(() => {
        return [
            {
                accessorKey: "name",

                header: () => (
                    <span className="text-capitalize">{t("printer name")}</span>
                ),
            },
            {
                accessorKey: "brand",

                header: () => (
                    <span className="text-capitalize">{t("brand")}</span>
                ),
            },
            {
                id: "IP/Port",
                accessorFn: (row) => {
                    if (row.ip !== "" && row.port !== "") {
                        return `${row.ip}:${row.port}`;
                    }
                    if (row.ip === "") {
                        return row.port;
                    }
                    return row.ip;
                },
            },
            {
                accessorKey: "remark",

                header: () => (
                    <span className="text-capitalize">{t("remark")}</span>
                ),
            },
            {
                accessorKey: "shopName",

                header: () => (
                    <span className="text-capitalize">{t("Shop")}</span>
                ),
            },
        ];
    }, [t]);

    const [selectedPrinters, setSelectedPrinters] = React.useState<
        Row<PrinterType>[]
    >([]);
    const [globalFilter, setGlobalFilter] = React.useState<string>("");
    const [customRowSelection, setRowSelection] = React.useState<
        Record<string, boolean>
    >({});

    const validateDispatchPrinting = () => {
        let selectedItems = [];
        if (dynamicTableWrapperContext?.rowData.data.length === 1) {
            selectedItems.push(dynamicTableWrapperContext?.rowData.iuud);
        } else if (dynamicTableWrapperContext?.rowData !== null) {
            selectedItems = dynamicTableWrapperContext?.rowData.data.map(
                (item: any) => item.iuud
            );
        }
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items/associate-printers`,
                {
                    shopId,
                    items: selectedItems,
                    printers: selectedPrinters.map(
                        (printer) => printer.original.id
                    ),
                }
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                SuccessToast(t("Printers associated successfully"));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
        setIsPrintersModalOpen(false);
    };

    React.useEffect(() => {
        let activePrinters: Record<string, boolean> = {};
        if (dynamicTableWrapperContext?.rowData?.data?.length === 1) {
            activePrinters = printers.reduce(
                (activePrinters: Record<string, boolean>, printer, index) => {
                    if (
                        dynamicTableWrapperContext?.rowData?.data[0].printers?.includes(
                            printer.id
                        )
                    ) {
                        activePrinters[index] = true;
                    }
                    return activePrinters;
                },
                {}
            );
        }
        setRowSelection(activePrinters);
    }, [dynamicTableWrapperContext?.rowData?.data, printers]);

    return (
        <StyledModal size="xl" isOpen={isPrintersModalOpen} centered>
            <ModalHeader
                toggle={() => {
                    setIsPrintersModalOpen(false);
                }}
            >
                <StyledH2 className="text-uppercase">
                    {t("dispatch printing")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex align-items-center justify-content-between mb-2 px-3 py-1 border">
                    <StyledSearchInput
                        value={globalFilter ?? ""}
                        placeholder={t("Search")}
                        onChange={(value: any) =>
                            setGlobalFilter(value.target.value)
                        }
                    />
                    <div className="text-capitalize">{`${t(
                        "number of selected lines"
                    )} : ${Object.keys(customRowSelection).length}`}</div>
                </div>
                <PrintersTable
                    tableColumns={printersTableColumns}
                    data={printers}
                    globalFilter={globalFilter}
                    isRowSelectionEnabled
                    setGlobalFilter={setGlobalFilter}
                    setSelectedRows={setSelectedPrinters}
                    customRowSelection={customRowSelection}
                    setRowSelection={setRowSelection}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <div className="d-flex align-items-center">
                    <div>
                        <StyledButton
                            rounded
                            outline
                            variant="light"
                            onClick={() => {
                                setIsPrintersModalOpen(false);
                            }}
                        >
                            {t("Cancel")}
                        </StyledButton>
                    </div>
                    <div className="ml-3">
                        <StyledButton
                            rounded
                            disabled={
                                dynamicTableWrapperContext?.rowData === null ||
                                Object.keys(customRowSelection).length < 1
                            }
                            variant={DominantColor}
                            onClick={validateDispatchPrinting}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </div>
                </div>
            </ModalFooter>
        </StyledModal>
    );
}
