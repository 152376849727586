import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    ErrorToast,
    SuccessToast,
    RequirementToast,
    DynamicTableWrapperContext,
} from "@components/Common/";
import { DominantColor } from "@components/Common/dominantColor";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DuplicateOptionModal({
    isShow,
    setIsShow,
    // eslint-disable-next-line no-unused-vars
    setIsLoading,
}: {
    isShow: boolean;
    setIsShow: Function;
    setIsLoading: Function;
}) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const dominantColor: string = DominantColor();
    const { t } = useTranslation();
    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const handleOnDuplicateOption = () => {
        if (validateOnclick) {
            setValidateOnclick(false);
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}/option/${schemaAdbFranchise}/${CardIuud}/${dynamicTableWrapperContext?.rowData?.iuud}/optionduplicated`
                )
                .then((data: any) => {
                    if (data.data.error === true) {
                        RequirementToast(
                            t("Designation already exists try another!")
                        );
                    } else {
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("The option duplicated successfully"));
                    }
                    setIsShow(!isShow);
                    setValidateOnclick(true);
                })
                .catch(() => {
                    ErrorToast(t("There is a mistake!"));
                });
        }
    };
    return (
        <Modal centered isOpen={isShow}>
            <ModalHeader toggle={() => setIsShow(!isShow)}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex">
                        {t("Duplicate option")} <span className="mx-1">:</span>
                        {dynamicTableWrapperContext?.rowData?.title.length >
                        20 ? (
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={
                                    dynamicTableWrapperContext?.rowData?.title
                                }
                                id="Duplicate"
                            />
                        ) : null}
                        <div id="Duplicate" className="modal_clz ">
                            {dynamicTableWrapperContext?.rowData?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t("Do you want to duplicate option")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    outline
                    rounded
                    variant="light"
                    onClick={() => {
                        setIsShow(!isShow);
                    }}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleOnDuplicateOption}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
