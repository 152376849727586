export const DEFAULT_LANGUAGE: LanguageType[] = [
    {
        designation: "Francais",
        value: "",
        visibility: true,
    },
    {
        designation: "Anglais",
        value: "",
        visibility: true,
    },
    {
        designation: "Italien",
        value: "",
        visibility: true,
    },
    {
        designation: "Allemand",
        value: "",
        visibility: true,
    },
];
