import React from "react";

import { Container, Card } from "react-bootstrap";
import { StyledStepButton } from "@aureskonnect/react-ui";
import { PageTitle } from "@components/index";
import { useTranslation } from "react-i18next";

import "./Style.css";

type NavProps = {
    etape: number;
    pathname: string;
};
function NavBar({ etape, pathname }: NavProps) {
    const { t } = useTranslation();
    let pageType: any;
    if (pathname === "ARTICLES") {
        pageType = t("Items".toUpperCase());
    }
    if (pathname === "STEPS") {
        pageType = t("Steps".toUpperCase());
    } else if (pathname === "CATEGORIES") {
        pageType = t("Categories".toUpperCase());
    }

    return (
        <React.Fragment>
            <div className="mb-2 ">
                <div
                    className="d-flex align-items-center px-3"
                    style={{ height: "61px" }}
                >
                    <h4>{`${t("IMPORT")} ${pageType}`}</h4>
                </div>
            </div>
            <Container fluid>
                <PageTitle title={`${t("IMPORT")} ${pathname}`} />
                <Card className="d-flex ">
                    <div
                        className=" d-flex align-items-center"
                        style={{ minHeight: "79px" }}
                    >
                        <div className="mx-2 my-2">
                            <StyledStepButton
                                color="white"
                                rounded
                                step={etape === 1 ? "waiting" : "in progress"}
                                stepDetail={t("Import file selection")}
                                stepNumber={1}
                                stepTitle=""
                            />
                        </div>
                        <div className="mx-2 my-2">
                            <StyledStepButton
                                color="white"
                                rounded
                                step={etape === 2 ? "waiting" : "in progress"}
                                stepDetail={t("Data Verification")}
                                stepNumber={2}
                                stepTitle=""
                            />
                        </div>
                        <div className="mx-2 my-2">
                            <StyledStepButton
                                color="white"
                                rounded
                                step={etape === 3 ? "waiting" : "in progress"}
                                stepDetail={t("Data import")}
                                stepNumber={3}
                                stepTitle=""
                            />
                        </div>
                    </div>
                </Card>
            </Container>
        </React.Fragment>
    );
}

export default NavBar;
