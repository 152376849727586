/* eslint-disable no-unused-vars */
export default function HomeIcon(_props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 54.851 52"
        >
            <path
                id="accueil"
                d="M54.034,34.658,28.859,13.821a2.25,2.25,0,0,0-2.869,0L.815,34.658a2.25,2.25,0,1,0,2.869,3.466l.7-.576V58.555A6.757,6.757,0,0,0,11.128,65.3H43.721a6.757,6.757,0,0,0,6.749-6.749V37.548l.7.576a2.25,2.25,0,1,0,2.869-3.466ZM33.07,60.805H21.779V47.837H33.07Zm12.9-2.25a2.252,2.252,0,0,1-2.25,2.25H37.57V45.587a2.25,2.25,0,0,0-2.25-2.25H19.529a2.25,2.25,0,0,0-2.25,2.25V60.805H11.128a2.252,2.252,0,0,1-2.25-2.25V33.824l18.546-15.35L45.97,33.824Z"
                transform="translate(0.001 -13.304)"
            />
        </svg>
    );
}
