import React from "react";

import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

import { DominantColor } from "@components/Common/";
import { ACTIONS } from "@helpers/actions";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

type stepSpecificOption = {
    withoutStep: boolean;
    nextButton: boolean;
    noButton: boolean;
    zeroPrice: boolean;
    isNext: boolean;
    isCheapest: boolean;
    isExpensive: boolean;
};
type StepItemManagementModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    dispatchStep: Function;
    stepForm: StepType;
};
export default function BorneOptionsModal({
    isOpen,
    setIsOpen,
    dispatchStep,
    stepForm,
}: StepItemManagementModalPropsType): JSX.Element {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const toggleOpen = (): boolean => setIsOpen(!isOpen);
    const [stepSpecificOption, setStepSpecificOption] =
        React.useState<stepSpecificOption>(stepForm.specificOption);

    const handleValidateButtonOnClickEvent = () => {
        dispatchStep({
            type: ACTIONS.SPECIFIC_OPTION,
            payload: stepSpecificOption,
        });

        setIsOpen(!isOpen);
    };

    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    {t("Specific Options")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <fieldset className="fieldset__clz scrollAdd__clz">
                    <legend style={{ marginLeft: "10px" }} className="w-auto ">
                        <StyledLabel>{t("Enabled/Disabled")}</StyledLabel>
                    </legend>
                    <div className="d-flex d-flex justify-content-between mt-2">
                        <StyledLabel className="ml-2">Etape "Sans"</StyledLabel>
                        <ReactSwitch
                            className="mr-4"
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={20}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    withoutStep:
                                        !stepForm.specificOption.withoutStep,
                                });
                            }}
                            checked={stepSpecificOption.withoutStep}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={45}
                            height={15}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                    </div>
                    <div className="d-flex d-flex justify-content-between mt-4">
                        <StyledLabel className="ml-2">
                            Bouton "Suivant"
                        </StyledLabel>
                        <ReactSwitch
                            className="mr-4"
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={20}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    nextButton:
                                        !stepForm.specificOption.nextButton,
                                });
                            }}
                            checked={stepSpecificOption.nextButton}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={45}
                            height={15}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                    </div>
                    <div className="d-flex d-flex justify-content-between  mt-4">
                        <StyledLabel className="ml-2">
                            Bouton "Non,Merci"
                        </StyledLabel>
                        <ReactSwitch
                            className="mr-4"
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={20}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    noButton: !stepForm.specificOption.noButton,
                                });
                            }}
                            checked={stepSpecificOption.noButton}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={45}
                            height={15}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                    </div>
                    <div className="d-flex d-flex justify-content-between  mt-4 mb-4">
                        <StyledLabel className="ml-2">
                            Afficher prix zéro
                        </StyledLabel>
                        <ReactSwitch
                            className="mr-4"
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={20}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    zeroPrice:
                                        !stepForm.specificOption.zeroPrice,
                                });
                            }}
                            checked={stepSpecificOption.zeroPrice}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={45}
                            height={15}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                    </div>
                </fieldset>
                <fieldset className="fieldset__clz scrollAdd__clz mt-4">
                    <legend style={{ marginLeft: "10px" }} className="w-auto">
                        <StyledLabel>
                            {t("Rules for deleting items in step")}
                        </StyledLabel>
                    </legend>
                    <div className="d-flex d-flex mt-2">
                        <Input
                            className="ml-2"
                            type="radio"
                            name="type"
                            value="type2"
                            id="type2"
                            checked={stepSpecificOption.isNext}
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    isNext: true,
                                    isExpensive: false,
                                    isCheapest: false,
                                });
                            }}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                        <StyledLabel className=" ml-4">
                            {t(
                                "Retrieve the following from the list of items taken"
                            )}
                        </StyledLabel>
                    </div>
                    <div className="d-flex d-flex mt-4">
                        <Input
                            className="ml-2"
                            type="radio"
                            name="type"
                            value="type2"
                            id="type2"
                            checked={stepSpecificOption.isCheapest}
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    isExpensive: false,
                                    isCheapest: true,
                                    isNext: false,
                                });
                            }}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                        <StyledLabel className="ml-4">
                            {t("the cheapest item")}
                        </StyledLabel>
                    </div>
                    <div className="mt-4 mb-4">
                        <Input
                            className=" ml-2 "
                            type="radio"
                            name="type"
                            value="type2"
                            id="type2"
                            checked={stepSpecificOption.isExpensive}
                            onChange={() => {
                                setStepSpecificOption({
                                    ...stepSpecificOption,
                                    isExpensive: true,
                                    isCheapest: false,
                                    isNext: false,
                                });
                            }}
                            disabled={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                            }
                        />
                        <StyledLabel className="ml-4">
                            {t("Most expensive item")}
                        </StyledLabel>
                    </div>
                </fieldset>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleValidateButtonOnClickEvent}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
