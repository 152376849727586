/* eslint-disable no-param-reassign */
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { ModalFooter } from "react-bootstrap";

import { SuccessToast, DominantColor } from "@components/Common/";
import { ACTIONS } from "@helpers/actions";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

type DisplayNameModalFooterProps = {
    dispatchDisplayName: Function;
    setActive: any;
    impression: LanguageType[];
    sellingSupport: sellingSupportType[];
    displayName: DisplayNameType;
};
export default function DisplayNameModalFooter({
    setActive,
    impression,
    sellingSupport,
    displayName,
    dispatchDisplayName,
}: DisplayNameModalFooterProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const handleValidateButtonOnClickEvent = () => {
        if (validateOnclick) {
            if (
                isEditing &&
                cardType === "shop" &&
                cardStatus === "En production"
            ) {
                setActive(false);
            } else {
                setValidateOnclick(false);
                AddListe();
                setActive(false);
                SuccessToast(t("Successful registration"));
            }
        }
    };
    function AddListe() {
        displayName = {
            Default: {
                ...displayName.Default,
                salesSupport: sellingSupport,
                impression,
            },
            override: [
                { shopId: "idboutique" },
                {
                    salesSupport: sellingSupport,
                    impression,
                },
            ],
        };
        dispatchDisplayName({
            type: ACTIONS.DISPLAY_NAME,
            payload: displayName,
        });
    }
    return (
        <ModalFooter className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
                <div>
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            setActive(false);
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                </div>
                <div className="ml-3">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </ModalFooter>
    );
}
