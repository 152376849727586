import React from "react";

export function DisplayName({ e }: any) {
    const url = e.selectedRow.url_image;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div>
                    {e.selectedRow.display_name}
                    <span>
                        {"  "}
                        <img
                            alt="no data"
                            src={url}
                            height="25px"
                            width="35px"
                        />
                    </span>
                </div>
            ) : null}
        </React.Fragment>
    );
}
