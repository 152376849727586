import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
} from "@components/Common/";
// import { getNestedIdofArray } from "@helpers/getNestedId";

type MultiActionsModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};
type MultiDuplicatePropsType = {
    SetMultiDuplicateType: React.Dispatch<React.SetStateAction<number>>;
};

function MultiDuplicate({
    SetMultiDuplicateType,
}: MultiDuplicatePropsType): JSX.Element {
    const { t } = useTranslation();

    return (
        <div>
            <div
                className="form-check"
                style={{ display: "flex", alignItems: "center" }}
            >
                <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value="type1"
                    id="type1"
                    onChange={() => SetMultiDuplicateType(1)}
                    disabled={false}
                />
                <label className="form-check-label" htmlFor="type1">
                    {t("Only")}
                </label>
            </div>
            <div
                className="form-check"
                style={{ display: "flex", alignItems: "center" }}
            >
                <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value="type2"
                    id="type2"
                    onChange={() => SetMultiDuplicateType(2)}
                    disabled={false}
                />
                <label className="form-check-label" htmlFor="type2">
                    {t("With subcategories and articles")}
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    id="type3"
                    type="radio"
                    name="type"
                    value="type3"
                    onChange={() => SetMultiDuplicateType(2)}
                    disabled={false}
                />
                <label className="form-check-label" htmlFor="type3">
                    {t(
                        " With sub-categories and articles and journey (workflow)"
                    )}
                </label>
            </div>
        </div>
    );
}
export default function MultiDuplicateModal({
    isOpen,
    setIsOpen,
}: MultiActionsModalPropsType) {
    const { t } = useTranslation();
    const [isWarningModal, SetIsWarningModal] = React.useState(false);
    const [multiDuplicateType, SetMultiDuplicateType] = React.useState(1);
    const [textBody, SetTextBody] = React.useState("");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
        SetIsWarningModal(true);
    };

    const canDuplicateCategories = () => {
        const result = dynamicTableWrapperContext?.rowData?.data.every(
            (category: any) => {
                return (
                    category.depth ===
                    dynamicTableWrapperContext?.rowData?.data[0].depth
                );
            }
        );

        return result;
    };

    const getBodyTex = () => {
        let bodyTex = t(
            "Do you want to confirm the duplication of these categories?"
        );

        if (dynamicTableWrapperContext?.rowData?.data.length > 1) {
            if (!canDuplicateCategories()) {
                bodyTex =
                    "La duplication n’est possible que pour des éléments ayant un même niveau";

                SetIsWarningModal(true);
            }
        } else {
            bodyTex = t(`Two or more categories must be selected`);
            SetIsWarningModal(true);
        }
        SetTextBody(bodyTex);
    };
    const isLinkedCategoriesFound = (listUuid: string[]) => {
        return listUuid.some((item, index) => index !== listUuid.indexOf(item));
    };
    const handleMultiDuplicate = () => {
        setIsOpen(!isOpen);
        let listUuid: any[] = [];
        switch (multiDuplicateType) {
            case 1:
                listUuid = dynamicTableWrapperContext?.rowData?.data.map(
                    (cat: any) => cat.iuud
                );

                break;
            case 2:
                listUuid = dynamicTableWrapperContext?.rowData?.data.map(
                    (cat: any) => cat.iuud
                );
                // listUuid = getNestedIdofArray(
                //     dynamicTableWrapperContext?.rowData?.data
                // );
                if (isLinkedCategoriesFound(listUuid)) {
                    setIsOpen(true);
                    SetIsWarningModal(true);
                    SetTextBody(
                        "La duplication d'une catégorie qui contient des sous-catégories de liaisons est impossible."
                    );
                }

                break;
            default:
                listUuid = dynamicTableWrapperContext?.rowData?.data.map(
                    (cat: any) => cat.iuud
                );
                break;
        }

        try {
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/${multiDuplicateType}/categories`,
                    { itemsIuuds: listUuid }
                )

                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                })
                .then((): void => {
                    SuccessToast(t("Duplication completed successfully"));
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    React.useEffect(() => {
        getBodyTex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textBody]);

    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                {t("DUPLICATE CATEGORIES")}
            </ModalHeader>
            <ModalBody>
                {isWarningModal ? (
                    textBody
                ) : (
                    <MultiDuplicate
                        SetMultiDuplicateType={SetMultiDuplicateType}
                    />
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {!isWarningModal ? t("Cancel") : t("Close")}
                </StyledButton>
                {!isWarningModal && (
                    <StyledButton
                        onClick={handleMultiDuplicate}
                        rounded
                        variant="primary"
                    >
                        {t("Duplicate")}
                    </StyledButton>
                )}
            </ModalFooter>
        </StyledModal>
    );
}
