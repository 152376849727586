/* eslint-disable no-nested-ternary */
import { RootState } from "@store/.";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import StepSelectionDropdown from "../../../StepSelectionDropdown";

type dropDownStepProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    isDropdownMenuActive: boolean;
    setIsDropdownMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
    searchedStepDesignation: string;
    cardData: any;
    globalIndex: number;
    title: string;
    type: string;
    ListeUuidStep: string[];
    setSearchedStepDesignation: React.Dispatch<React.SetStateAction<string>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    uuid: string;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
};
export function DropdownStep({
    IsBasicCompositionStep,
    IsOptionStep,
    isDropdownMenuActive,
    setIsDropdownMenuActive,
    searchedStepDesignation,
    cardData,
    globalIndex,
    title,
    type,
    ListeUuidStep,
    setSearchedStepDesignation,
    setIsClickStep,
    isClickStep,
    uuid,
    setIndexSelected,
}: dropDownStepProps) {
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );

    return (
        <Dropdown
            isOpen={
                IsBasicCompositionStep || IsOptionStep
                    ? false
                    : isDropdownMenuActive
            }
            toggle={() => setIsDropdownMenuActive((prevState) => !prevState)}
        >
            <DropdownToggle data-toggle="dropdown" tag="span">
                <div className="add_step__CLZ d-flex justify-content-between align-items-center">
                    <LockIconWithToolTip
                        type="title"
                        lengthMax={21}
                        message={
                            isDropdownMenuActive &&
                            !IsOptionStep &&
                            !IsBasicCompositionStep
                                ? searchedStepDesignation
                                : IsOptionStep
                                ? cardData?.options?.[
                                      globalWorkflow[globalIndex!]?.uuidStep!
                                  ]?.title
                                : IsBasicCompositionStep
                                ? WORKFLOW_LIST.BASICCOMPOSITION
                                : Object.keys(
                                      globalWorkflow[globalIndex]?.override ||
                                          {}
                                  ).length > 0
                                ? title
                                : cardData?.steps?.[
                                      globalWorkflow[globalIndex!]?.uuidStep!
                                  ]?.title
                        }
                        id={`title${globalIndex}`}
                    />
                    <input
                        id={`title${globalIndex}`}
                        className="borderless_input__CLZ preview-textStep-card "
                        type="text"
                        value={
                            // eslint-disable-next-line no-nested-ternary
                            isDropdownMenuActive &&
                            !IsOptionStep &&
                            !IsBasicCompositionStep
                                ? searchedStepDesignation
                                : IsOptionStep
                                ? cardData?.options?.[
                                      globalWorkflow[globalIndex!]?.uuidStep!
                                  ]?.title
                                : IsBasicCompositionStep
                                ? WORKFLOW_LIST.BASICCOMPOSITION
                                : Object.keys(
                                      globalWorkflow[globalIndex]?.override ||
                                          {}
                                  ).length > 0
                                ? title
                                : cardData?.steps?.[
                                      globalWorkflow[globalIndex!]?.uuidStep!
                                  ]?.title
                        }
                        onChange={(e: any) => {
                            isDropdownMenuActive &&
                                setSearchedStepDesignation(
                                    e.target.value.toUpperCase()
                                );
                        }}
                        onKeyPress={(e: any) => {
                            !isDropdownMenuActive && e.preventDefault();
                        }}
                        autoComplete="off"
                    />
                    <ArrowDownIcon color="black" />
                </div>
            </DropdownToggle>
            <DropdownMenu
                style={{
                    cursor: "auto",
                    border: "1px solid #E8E8E8",
                    borderRadius: "6px",
                    boxShadow: "0px 3px 6px #000000BD",
                }}
            >
                <StepSelectionDropdown
                    searchedStepDesignation={searchedStepDesignation}
                    title={title}
                    type={type}
                    triggerItemIuud={uuid}
                    globalIndex={globalIndex}
                    cardData={cardData}
                    listUuidStep={ListeUuidStep}
                    setIndexSelected={setIndexSelected}
                    setIsClickStep={setIsClickStep}
                    isClickStep={isClickStep}
                />
            </DropdownMenu>
        </Dropdown>
    );
}
