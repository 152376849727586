/* eslint-disable no-unused-vars */

export const enum ACTIONS {
    VIDEO = "video",
    REFERENCE = "REFERENCE",
    MIN_NUMBER_OF_CHOICES = "MIN_NUMBER_OF_CHOICES",
    MAX_NUMBER_OF_CHOICES = "MAX_NUMBER_OF_CHOICES",
    NUMBER_OF_CHOICE_WITH_ITEM_PRICE = "NUMBER_OF_CHOICE_WITH_ITEM_PRICE",
    NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE = "NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE",
    REQUIRED = "REQUIRED",
    DEFAULT_DISCRIPTION = "DefaultDescription",
    PARENTS_CATEGORIES = "categoryParent",
    LINK_CATEGORIES = "categoryLiaison",
    LINK_TAGS = "balisedeliaison",
    DISCRIPTION = "descripton",
    DESCRIPTION = "descriptionone",
    EDIT = "edit",
    TITLE = "title",
    COLOR = "color",
    DEFAULT_DISPLAY_NAME = "defaultDisplayName",
    DISPLAY_NAME = "displayName",
    RANKS = "ranks",
    VISIBILITY_INFO = "visibilityInfo",
    DEFAULT_IMAGE_URL = "defaultImageUrl",
    SALES_SUPPORT_IMAGE_URL = "salesSupportImageUrl",
    SALES_SUPPORT = "salesSupport",
    IMPRESSION = "impression",
    CALORIES = "calories",
    FIDELITY = "fidelity",
    DEFAULT_RANK = "defaultRank",
    ALLERGENS = "allergens",
    RESET = "reset",
    INIT_VISIBILITY = "initVisibility",
    INIT_SELLING_SUPPORT = "initSellingSupport",
    INIT_IMPRESSION = "INIT_IMPRESSION",
    OPTIONS = "options",
    ICON = "icon",
    ALLERGEN_GROUP = "allergenGroup",
    DEFAULT_PRICE = "defaultPrice",
    DEFAULT_TVA = "defaultTVA",
    Clear = "Clear",
    STEP_ITEMS = "StepItems",
    IS_BASIC = "IsBasic",
    IS_CREATIONAL = "IsCreational",
    IS_MODIFIABLE = "IsModifiable",
    IS_WIHOUT_STEP = "IsWithoutStep",
    IS_NEXT_BUTTON = "IsNextButton",
    IS_NO_BUTTON = "IsNoButton",
    IS_ZERO_PRICE = "IsZeroPrice",
    SPECIFIC_OPTION = "specificOption",
    BASIC_COMPOSITION = "basicComposition",
    OUT_OF_STOCK = "outOfStock",
    BARCODE = "barCode",
    REMARQUE = "remark",
    QUANTITY_OF_BASIC_COMPOSITION = "active_quantity",
    IS_REDIRECT = "isRedirect",
    IS_VISIBLE = "isVisible",
    BASIC_COMPOSITION_VISIBILITY = "basicCompositionVisibility",
    OVER_PRICE = "OVER_PRICE",
    STEP = "steps",
    PRICE_HT = "priceHT",
    PRICE_TTC = "priceTTC",
    ADVANCED_PRICE = "advancedPrice",
    TVA = "tva",
    SALE_MODE_TVA = "saleModeTVA",
    SPECIAL_PRICE = "specialPrice",
    ITEM_PRICE_IN_STEP = "priceOfItemInStep",
    ClEAR = "clear",
}
export const enum MULTI_EDIT_KEY {
    COLOR = "color",
    PRICE = "price",
    TITLE = "title",
    ARCHIVE = "archive",
    OPTIONS = "options",
    CALORIES = "calories",
    FIDELITY = "fidelity",
    IMAGEURL = "imageUrl",
    SIZELIST = "sizeList",
    ALLERGENS = "allergens",
    DESCRIPTION = "description",
    DISPLAYNAME = "displayName",
    CATEGORYPARENT = "categoryParent",
    VISIBILITYINFO = "visibilityInfo",
    CATEGORYLIAISON = "categoryLiaison",
    REFERENCE = "reference",
    BARCODE = "barCode",
    STEP = "steps",
    LINK_TAGS = "linkedTags",
    BASIC_COMPOSITION = "basicComposition",
    OUT_OF_STOCK = "outOfStock",
    IS_REDIRECT = "isRedirect",
    VIDEO = "video",
    SALE_MODE_TVA = "saleModeVatRates",
}
