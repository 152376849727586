import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { ACTIONS } from "@helpers/.";
import { RootState } from "@store/.";
import ImageModal from "./AdvanceImage/ImageModal";
import ImageUploader from "../ImageUploader";

type ImagePropsType = {
    dispatch: Function;
    imageUrl: ImageUrlType;
};

export default function Image({ dispatch, imageUrl }: ImagePropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();

    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );

    const [isImageModalActive, setIsImageModalActive] = React.useState(false);
    const [uploadedImage, setUploadedImage] = React.useState<string | Blob>(
        imageUrl.Default.urlDefault
    );
    const [, setFileType] = React.useState<"image" | "video" | "">("image");

    React.useEffect(() => {
        dispatch({
            type: ACTIONS.DEFAULT_IMAGE_URL,
            payload: uploadedImage,
        });
    }, [uploadedImage, dispatch, dispatchStore, isMultiModify]);

    React.useEffect(() => {
        setUploadedImage(imageUrl.Default.urlDefault);
    }, [imageUrl.Default.urlDefault]);

    return (
        <div className="py-2" id="image">
            <div className="d-flex flex-row  justify-content-between bd-highlight pt-4">
                <StyledLabel>{t("Picture")}</StyledLabel>
                <div
                    style={{
                        color: "blue",
                        cursor: "pointer",
                        border: "transparent",
                        backgroundColor: "transparent",
                        textAlign: "center",
                        textDecoration: "underline",
                        font: " normal normal 600 16px/21px Segoe UI",
                    }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        setIsImageModalActive(true);
                    }}
                >
                    {t("Advanced")}
                </div>
            </div>
            <div
                className="d-flex justify-content-center align-items-center p-2"
                style={{
                    border: "1px solid #C6C6C6",
                    width: "100%",
                    height: "240px",
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <ImageUploader
                    uploadedImage={uploadedImage}
                    setUploadedImage={setUploadedImage}
                    id="image"
                    acceptedFilesExtentions={["image/png", "image/jpeg"]}
                    fileType="image"
                    setFileType={setFileType}
                />
            </div>
            <ImageModal
                dispatch={dispatch}
                advancedImages={imageUrl.Default.salesSupport}
                setIsImageModalActive={setIsImageModalActive}
                isImageModalActive={isImageModalActive}
            />
        </div>
    );
}
