import React from "react";

import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion/dist/framer-motion";

import { RootState } from "@store/index";
import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import AllergensList from "./AllergensList";
import AddAllergen from "./AddAllergen";

export default function Allergens() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );

    React.useEffect(() => {
        return () => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);

    return (
        <>
            <PageTitle title={t("Allergens")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },
                        { item: t("Allergens"), link: "items" },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is mandatory to create and declare allergens in articles. This is to enable the various solutions to alert the consumer to the presence of allergens in an article."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<AllergensList />}
                        ActionColumnComponent={<AddAllergen />}
                    />
                </Container>
            </motion.div>
        </>
    );
}
