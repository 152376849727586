/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { setSelectedCard } from "@store/features/CardSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";
import AlerteModal from "./AlerteModal";

type CardModalProps = {
    isOpen: boolean;
    setIsOpen: Function;
    rowData: any;
    setIsLoading: Function;
};
export default function DuplicateCard({
    isOpen,
    setIsOpen,
    rowData,
    setIsLoading,
}: CardModalProps) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const api = axios.create({
        baseURL: `${process.env.REACT_APP_ADB_API_URL}`,
    });

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [type, setType] = React.useState("");

    const [isOpen2, setIsOpen2] = React.useState<boolean>(false);
    const [validateOnclick] = React.useState<boolean>(true);
    const bodymessage =
        "La duplication de la carte master engendre la duplication des cartes boutiques. Voulez-vous que ces cartes boutiques soient:";

    const [copyType, setCopyType] = useState<string>("card pending copy");

    function duplicateShop(): void {
        try {
            api.post(
                `${schemaAdbFranchise}/cards/${rowData.selectedRow.original.iuud}/duplicatecardshop/`,
                {
                    withItem: true,
                    withCategory: true,
                    listeiuud: rowData.selectedRow.original.iuud,
                }
            )
                .then((data): void => {
                    if (cardIuud === rowData.selectedRow.original.iuud) {
                        localStorage.setItem(
                            "selectedCard",
                            JSON.stringify({
                                iuud: data.data,
                                designation:
                                    rowData.selectedRow.original.designation,
                            })
                        );
                        dispatch(setSelectedCard(data.data));
                    }
                    SuccessToast(t("Card updated successfully"));
                    setIsOpen(!isOpen);
                    dynamicTableWrapperContext?.setDataUpdated(true) &&
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch {
            ErrorToast(t("There is a mistake!"));
        }
    }

    function duplicateMaster(type: any): void {
        try {
            const status =
                type === "In production" ? "En production" : "En attente";

            api.post(
                `${schemaAdbFranchise}/cards/${rowData.selectedRow.original.iuud}/duplicate/`,
                {
                    withItem: true,
                    withCategory: true,
                    status,
                    copyType,
                }
            )
                .then((): void => {
                    SuccessToast("toastSuccessMessage");
                    dynamicTableWrapperContext?.setDataUpdated(true) &&
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch {
            ErrorToast(t("There is a mistake!"));
        }
    }

    const handleRemoveButtonOnClickEvent = () => {
        if (validateOnclick) {
            if (rowData.selectedRow.original.iuudcardreference !== "0") {
                // Card shop
                duplicateShop();
            } else if (
                // rowData.selectedRow.original.CardProd === "En attente" &&
                rowData.selectedRow.original.CardMaster &&
                copyType !== "card production copy"
            ) {
                // card master n a pas des cartes en prod
                duplicateMaster(type);
            } else if (
                rowData.selectedRow.original.CardMaster &&
                rowData.selectedRow.original.CardProd === true &&
                copyType === "card production copy"
            ) {
                // card master a des cartes bpoutique en production
                setIsOpen(false);
                setIsOpen2(true);
            }
        }
        setIsLoading(false);
    };

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Modal centered isOpen={isOpen}>
                <ModalHeader toggle={toggleOpen}>
                    <StyledH2 className="text-uppercase">
                        {/* {t("Duplicate")} {t("Card")} */}
                        DUPLICATION
                    </StyledH2>
                </ModalHeader>
                {rowData.selectedRow.original.iuudcardreference !== "0" ? (
                    <ModalBody>
                        {t(
                            "Duplicating this card results in the archiving of the existing pending card."
                        )}
                        {t("Do you want to continue ?")}

                        <br />
                    </ModalBody>
                ) : (
                    <ModalBody>
                        {bodymessage}
                        <div
                            className="form-check"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="type1"
                                id="type1"
                                onChange={() => {
                                    setCopyType("card pending copy");
                                    setType("Pending");
                                }}
                                disabled={false}
                            />
                            <label className="form-check-label" htmlFor="type1">
                                {t("Une copie des cartes en attente")}
                            </label>
                        </div>
                        {rowData.selectedRow.original.CardProd === true && (
                            <div
                                className="form-check"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    value="type1"
                                    id="type1"
                                    onChange={() => {
                                        setCopyType("card production copy");
                                        setType("In production");
                                    }}
                                    disabled={false}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="type1"
                                >
                                    {t("Une copie des cartes en production")}
                                </label>
                            </div>
                        )}
                        <div
                            className="form-check"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="type2"
                                id="type2"
                                onChange={() => setCopyType("card master copy")}
                                disabled={false}
                            />
                            <label className="form-check-label" htmlFor="type2">
                                {t("Une copie de la carte master")}
                            </label>
                        </div>
                    </ModalBody>
                )}

                <ModalFooter className="border-top-0">
                    <StyledButton
                        onClick={toggleOpen}
                        rounded
                        variant="light"
                        outline
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        onClick={handleRemoveButtonOnClickEvent}
                        rounded
                        variant={dominantColor}
                    >
                        {t("Valider")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
            <AlerteModal
                duplicateMaster={duplicateMaster}
                isOpen={isOpen2}
                setIsOpen={setIsOpen2}
            />
        </div>
    );
}
