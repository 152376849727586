import { createSlice } from "@reduxjs/toolkit";

type selectedCardSliceStateType = {
    selectedChoice: number;
    selectedCardList: string[];
    isSelectionMemoized: boolean;
    isModificationConfirmed: boolean;
};

const initialState: selectedCardSliceStateType = {
    selectedChoice: 0,
    selectedCardList: [],
    isSelectionMemoized: false,
    isModificationConfirmed: false,
};
export const selectedCardListSlice = createSlice({
    name: "selectedCardListSlice",
    initialState,
    reducers: {
        setSelectedCardList: (state, action) => {
            state.selectedCardList = action.payload;
        },
        setIsSelectionMemoized: (state, action) => {
            state.isSelectionMemoized = action.payload;
        },
        setSelectedChoice: (state, action) => {
            state.selectedChoice = action.payload;
        },
        setIsModificationConfirmed: (state, action) => {
            state.isModificationConfirmed = action.payload;
        },
    },
});

export const {
    setSelectedCardList,
    setIsSelectionMemoized,
    setSelectedChoice,
    setIsModificationConfirmed,
} = selectedCardListSlice.actions;

export default selectedCardListSlice.reducer;
