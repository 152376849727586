/* eslint-disable no-param-reassign */
import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledButton } from "@aureskonnect/react-ui";

import { SuccessToast, DominantColor } from "@components/Common/";
import { ACTIONS } from "@helpers/actions";
import { ModalFooter } from "react-bootstrap";
import { RootState } from "@store/index";

type DescritptionModalFooterProps = {
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    setActive: any;
    impression: LanguageType[];
    sellingSupport: sellingSupportType[];
    description: DisplayNameType;
};
export default function DescriptionModalFooter({
    setActive,
    impression,
    sellingSupport,
    description,
    dispatchItem,
}: DescritptionModalFooterProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const ValueToCheck = [
        "[",
        "|",
        "@",
        "#",
        "^",
        "?",
        "!",
        "²",
        "/",
        "°",
        "<",
        ">",
        "§",
        ";",
    ];
    const checkValueForbidden = () => {
        let count: number = 0;
        // check seling support forbidden characters
        sellingSupport.forEach((element: any) => {
            if (
                element.Langue.some((element: any) =>
                    ValueToCheck.some((value) => element.value.includes(value))
                )
            ) {
                count += 1;
            }
        });
        if (count > 0) {
            return true;
        }
        return false;
    };

    const handleValidateButtonOnClickEvent = () => {
        const isForbidden = checkValueForbidden();
        if (isForbidden) {
            return;
        }

        if (validateOnclick) {
            if (
                isEditing &&
                cardType === "shop" &&
                cardStatus === "En production"
            ) {
                setActive(false);
            } else {
                setValidateOnclick(false);
                addDescription();
                setActive(false);
                SuccessToast(t("Successful registration"));
            }
        }
    };

    function addDescription() {
        description = {
            Default: {
                ...description.Default,
                salesSupport: sellingSupport,
                impression,
            },
            override: [
                { shopId: "idboutique" },
                {
                    salesSupport: sellingSupport,
                    impression,
                },
            ],
        };
        dispatchItem({
            type: ACTIONS.DISCRIPTION,
            payload: description,
        });
    }
    return (
        <ModalFooter className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
                <div>
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            setActive(false);
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                </div>
                <div className="ml-3">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </ModalFooter>
    );
}
