/* eslint-disable no-unused-vars */
import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import { SuccessToast, DominantColor } from "@components/Common/";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import BodyOptions, { optionValueProps } from "./BodyOptions";

type OptionsPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    dispatchOptions: Function;
    itemOptions: itemOptions;
    listOptions: any[];
    advancedPrice: Record<string, Price>;
};
export default function OptionsModal({
    dispatchOptions,
    itemOptions,
    isOpen,
    setIsOpen,
    listOptions,
    advancedPrice,
}: OptionsPropsType) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [isCancelled, setIsCancelled] = React.useState<boolean>(false);
    const [selectedItemOptions, setSelectedItemOptions] = React.useState<
        optionValueProps[]
    >([]);

    const optionsTobeInsertedToItem: itemOptions = selectedItemOptions.reduce(
        (result, option) => {
            result[option.optionIuud] = result[option.optionIuud] || [];
            result[option.optionIuud].push(option.optionValueKey);
            return result;
        },
        Object.create(null)
    );

    const handleValidateButtonOnClickEvent = () => {
        if (
            isEditing &&
            cardType === "shop" &&
            cardStatus === "En production"
        ) {
            setIsCancelled(!isCancelled);
            setIsOpen(!isOpen);
        } else if (validateOnclick) {
            dispatchOptions({
                type: ACTIONS.OPTIONS,
                payload: optionsTobeInsertedToItem,
            });
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.OPTIONS));
            setSelectedItemOptions([]);
            setValidateOnclick(false);
            setIsOpen(!isOpen);
            SuccessToast(t("Successful registration"));
            setValidateOnclick(true);
        }
    };
    React.useEffect(() => {
        const convertedItemOptions = Object.keys(itemOptions).reduce(
            (prev: any, current: any) => {
                const result: optionValueProps[] = [];
                const listOptionValues: string[] =
                    itemOptions[current as keyof typeof itemOptions];
                listOptionValues.map((optionValue: string) =>
                    result.push({
                        optionIuud: current,
                        optionValueKey: optionValue,
                    })
                );
                return [...prev, ...result];
            },
            []
        );
        const newState = [...convertedItemOptions];
        const UniqueOptions = newState.reduce((unique: any, o: any) => {
            if (
                !unique.some(
                    (obj: any) =>
                        obj.optionIuud === o.optionIuud &&
                        obj.optionValueKey === o.optionValueKey
                )
            ) {
                unique.push(o);
            }
            return unique;
        }, []);

        setSelectedItemOptions(UniqueOptions);
    }, [itemOptions, isCancelled]);

    return (
        <Modal
            isOpen={isOpen}
            className=" d-flex justify-content-center "
            style={{
                minWidth: "1228px",
                height: "95vh,",
            }}
        >
            <ModalHeader
                toggle={() => {
                    setIsCancelled(!isCancelled);
                    setIsOpen(!isOpen);
                }}
            >
                Options
            </ModalHeader>

            <ModalBody
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                <BodyOptions
                    listOptions={listOptions}
                    selectedItemOptions={selectedItemOptions}
                    setSelectedItemOptions={setSelectedItemOptions}
                />
            </ModalBody>

            <ModalFooter>
                <div
                    className="d-flex justify-content-end"
                    style={{ gap: "10px" }}
                >
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            setIsCancelled(!isCancelled);
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </ModalFooter>
        </Modal>
    );
}
