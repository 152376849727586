import { createSlice } from "@reduxjs/toolkit";
import { fetchSettings } from "./settingsSlice";

type VisibilityStateType = {
    defaultVisibility: Record<string, visibilityInfoType>;
};

const initialState: VisibilityStateType = {
    defaultVisibility: {},
};

export const visibilitySlice = createSlice({
    name: "visibility",
    initialState,
    reducers: {
        setDefaultVisibility: (state, action) => {
            state.defaultVisibility = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            state.defaultVisibility = action.payload.defaultVisibility;
        });
    },
});

export function sellingSupport(saleSupport: any) {
    const DataSellingSupport: any = [];
    saleSupport.forEach((element: any) => {
        DataSellingSupport.push({
            sellingSupport: element,
            visibility: true,
        });
    });
    return DataSellingSupport;
}

export const { setDefaultVisibility } = visibilitySlice.actions;

export default visibilitySlice.reducer;
