import React from "react";

import ReactSwitch from "react-switch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";

import { ACTIONS } from "@helpers/actions";
import { RootState } from "@store/index";

type headerCompositionProps = {
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    isRedirect: boolean;
};
export function HeaderComposition({
    setIsChecked,
    isChecked,
    dispatchItem,
    isRedirect,
}: headerCompositionProps) {
    const { t } = useTranslation();
    const ETKType = localStorage.getItem("ETKType");
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const handleToggleQuantity = () => {
        setIsChecked(!isChecked);
        dispatchItem({
            type: ACTIONS.QUANTITY_OF_BASIC_COMPOSITION,
            payload: !isChecked,
        });
    };

    return (
        <div className="d-flex justify-content-between  align-items-betwwen align-items-center my-2">
            <StyledLabel>{t("Basic composition")}</StyledLabel>
            <div
                className="d-flex justify-content-between  align-items-betwwen align-items-center"
                style={
                    isRedirect ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                        ? { pointerEvents: "none", opacity: "0.4" }
                        : { opacity: "1" }
                }
            >
                <span className="mr-2">{t("Activate quantity")}</span>
                <ReactSwitch
                    activeBoxShadow="0px 0px 1px 10px #000000"
                    boxShadow="0px 1px 5px 0px #000000"
                    handleDiameter={20}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    onChange={handleToggleQuantity}
                    checked={isChecked}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={45}
                    height={15}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    disabled={ETKType === "3"}
                />
            </div>
        </div>
    );
}
