/* eslint-disable react/prop-types */
import React from "react";

export default function TagIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={107} height={107}>
            <defs>
                <linearGradient
                    id="a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor={props.color} />
                    <stop offset={1} stopColor={props.color} />
                </linearGradient>
            </defs>
            <g data-name="Groupe 17340" transform="translate(0 -.373)">
                <circle
                    cx={53.5}
                    cy={53.5}
                    r={53.5}
                    transform="translate(0 .373)"
                    fill={props.stopColor}
                />
                <g fill={props.fill}>
                    <path
                        data-name="Trac\xE9 17660"
                        d="M39.398 77.689a5.516 5.516 0 0 1-3.867-1.6L19.117 59.675a5.467 5.467 0 0 1 0-7.735l24.619-24.618a5.516 5.516 0 0 1 3.867-1.6h16.414a4.141 4.141 0 0 1 1.015.123 5.422 5.422 0 0 1 4.452 5.347v16.411a5.516 5.516 0 0 1-1.6 3.867L43.265 76.086a5.516 5.516 0 0 1-3.867 1.603Zm8.205-49.232a2.761 2.761 0 0 0-1.934.8L21.053 53.874a2.734 2.734 0 0 0 0 3.867l16.411 16.411a2.823 2.823 0 0 0 3.865 0l24.615-24.616a2.752 2.752 0 0 0 .8-1.934v-16.41a2.707 2.707 0 0 0-2.257-2.67 1.857 1.857 0 0 0-.473-.066Z"
                    />
                    <path
                        data-name="Trac\xE9 17661"
                        d="M77.498 83.159H53.265a5.765 5.765 0 0 1-3.988-1.589 5.444 5.444 0 0 1-1.674-3.881v-9.245a1.377 1.377 0 0 1 .4-.968l17.941-17.947a2.74 2.74 0 0 0 .8-1.931V31.192a2.707 2.707 0 0 0-2.262-2.669 1.368 1.368 0 0 1-.041-2.7 5.62 5.62 0 0 1 4.923 1.485L81.48 38.929a5.45 5.45 0 0 1 1.679 3.887v34.873a5.444 5.444 0 0 1-1.674 3.881 5.765 5.765 0 0 1-3.987 1.589ZM50.338 69.01v8.679a2.7 2.7 0 0 0 .842 1.917 3.01 3.01 0 0 0 2.084.818h24.234a3.015 3.015 0 0 0 2.084-.818 2.7 2.7 0 0 0 .842-1.917V42.816a2.7 2.7 0 0 0-.842-1.917l-10.1-9.688v16.392a5.516 5.516 0 0 1-1.6 3.867Z"
                    />
                    <path
                        data-name="Trac\xE9 17662"
                        d="M59.908 39.397a4.1 4.1 0 1 1 4.1-4.1 4.108 4.108 0 0 1-4.1 4.1Zm0-5.47a1.368 1.368 0 1 0 1.37 1.365 1.368 1.368 0 0 0-1.37-1.365Z"
                    />
                    <path
                        data-name="Trac\xE9 17663"
                        d="M76.321 36.663a1.368 1.368 0 0 1-1.368-1.368V27.09a6.838 6.838 0 1 0-13.675 0v5.47a1.368 1.368 0 1 1-2.735 0v-5.47a9.573 9.573 0 0 1 19.146 0v8.205a1.368 1.368 0 0 1-1.368 1.368Z"
                    />
                </g>
            </g>
        </svg>
    );
}
