import { ACTIONS } from "@helpers/actions";

type ColumnFileCategoryType = {
    index: string;
    name: string;
    value: string;
    selected: boolean;
};
export const ColumnFileStep: ColumnFileCategoryType[] = [
    {
        index: "Designation",
        name: "Désignation",
        value: ACTIONS.TITLE,
        selected: false,
    },
    {
        index: "Display name",
        name: "Nom d'affichage",
        value: ACTIONS.DEFAULT_DISPLAY_NAME,
        selected: false,
    },
    {
        index: " Liste des articles dans l'étape",
        name: "stepItems",
        value: ACTIONS.STEP_ITEMS,
        selected: false,
    },

    {
        index: "Nbr de choix min par étape",
        name: "minNbrOfChoices",
        value: ACTIONS.MIN_NUMBER_OF_CHOICES,
        selected: false,
    },
    {
        index: "Nbr de choix max par étape",
        name: "maxNbrOfChoices",
        value: ACTIONS.MAX_NUMBER_OF_CHOICES,
        selected: false,
    },

    {
        index: "Nbr de choix groupe 1",
        name: "nbrOfChoicesWithspecialPrice",
        value: ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE,
        selected: false,
    },
    {
        index: "Nbr de choix groupe 2",
        name: "nbrOfChoicesWithItemPrice",
        value: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
        selected: false,
    },
    {
        index: "Image",
        name: "urlImage",
        value: ACTIONS.DEFAULT_IMAGE_URL,
        selected: false,
    },
];
