import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type ExtensionModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    setFile: any;
};

export default function ExtensionInvalidModal({
    isOpen,
    setIsOpen,
    setFile,
}: ExtensionModalPropsType): JSX.Element {
    const { t } = useTranslation();
    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader
                toggle={() => {
                    setIsOpen(false);
                    setFile(null);
                }}
            >
                <StyledH2 className="text-uppercase">
                    {t("Invalid format")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {t("Invalid file format!")}
                <br />
                {t("Only these file types are supported")} :{" "}
                {t(".xls and .xlsx")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(false);
                        setFile(null);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Close")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
