import React from "react";

import { Navigate, useRoutes } from "react-router-dom";

import AuthLayout from "@components/AuthLayout";
import Items from "@pages/Catalogue/Items/index";
import Options from "@pages/Catalogue/Options/index";
import Tags from "@pages/Catalogue/Tags/index";
import Categories from "@pages/Catalogue/Categories/index";
import Home from "@pages/Home";
import Cards from "@pages/Cards";
import SelectAccount from "@pages/SelectAccount";
import File from "@components/File";
import Configuration from "@pages/Configuration/Configuration";
import CatalogWrapper from "@pages/Catalogue";
import Allergens from "@pages/Catalogue/Allergens";
import Steps from "@pages/Catalogue/steps";
import WorkFlow from "@pages/WorkFlow";
import Languages from "@pages/Configuration/Languages/Languages";
import Tva from "@pages/Configuration/VatRate/VatRate";
import ModeOfSale from "@pages/Configuration/ModeOfSale/ModeOfSale";
import Login from "@pages/Authentication/Login";
import { Callback } from "@pages/Authentication/Callback";
import RequireSelectedCard from "./middleware/RequireSelectedCard";
import NavigationBlocker from "./middleware/NavigationBlocker";
import AuthGuard from "./middleware/AuthGuard";
import AuthRouterCatalogue from "./middleware/AuthRouterCatalogue";

export default function Router() {
    const homeRederiction =
        Number(localStorage.getItem("selectedAccounts")) > 1
            ? "selectaccount"
            : "/";

    return (
        <React.Fragment>
            <NavigationBlocker />
            {useRoutes([
                {
                    element:
                        process.env.REACT_APP_IS_SSO_ACTIVE === "false" ? (
                            <AuthRouterCatalogue>
                                <AuthLayout />
                            </AuthRouterCatalogue>
                        ) : (
                            <AuthGuard>
                                <AuthLayout />
                            </AuthGuard>
                        ),
                    children: [
                        {
                            element: (
                                <RequireSelectedCard>
                                    <CatalogWrapper />
                                </RequireSelectedCard>
                            ),
                            children: [
                                { path: "items", element: <Items /> },
                                { path: "categories", element: <Categories /> },
                                { path: "steps", element: <Steps /> },
                                { path: "workflow", element: <WorkFlow /> },
                                { path: "tags", element: <Tags /> },
                                { path: "allergens", element: <Allergens /> },
                            ],
                        },
                        // { path: "profile", element: <UserProfile /> },
                        { path: "options", element: <Options /> },
                        { path: "SelectAccount", element: <SelectAccount /> },

                        {
                            path: "Configuration",
                            children: [
                                {
                                    path: "Categories",
                                    element: <Configuration />,
                                },
                                {
                                    path: "Articles",
                                    element: <Configuration />,
                                },
                                { path: "Languages", element: <Languages /> },
                                { path: "Tva", element: <Tva /> },
                                { path: "Sale", element: <ModeOfSale /> },
                            ],
                        },

                        { path: "home", element: <Home /> },
                        { path: "workflow", element: <WorkFlow /> },
                        { path: "cards", element: <Cards /> },

                        {
                            path: "File",
                            children: [
                                {
                                    path: "Categories",
                                    element: <File />,
                                },
                                {
                                    path: "Articles",
                                    element: <File />,
                                },
                                {
                                    path: "Steps",
                                    element: <File />,
                                },
                                {
                                    path: "Workflow",
                                    element: <File />,
                                },
                            ],
                        },

                        // {
                        //     path: "/",
                        //     element: !process.env.REACT_APP_SELECT_ACCOUNT ? (
                        //         <Navigate to="home" />
                        //     ) : (
                        //         <Navigate to={homeRederiction} />
                        //     ),
                        // },
                    ],
                },

                {
                    element: <Login />,
                    path: "/",
                },
                {
                    element: <Callback />,
                    path: "/Callback",
                },
                {
                    path: "*",
                    element: <Navigate to={homeRederiction} />,
                },
            ])}
        </React.Fragment>
    );
}
