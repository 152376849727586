import React from "react";
import { useTranslation } from "react-i18next";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";

import { DominantColor } from "@components/Common/";

import StepItemVisibility from "./StepItemVisibility";

type StepItemVisibilityModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    ItemVisibility: StepItemWithIuud;
    setItemVisibility: Function;
};

export default function StepItemVisibilityModal({
    isOpen,
    setIsOpen,
    ItemVisibility,
    setItemVisibility,
}: StepItemVisibilityModalPropsType): JSX.Element {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const [ItemModalVisibility, setItemModalVisibility] =
        React.useState<visibilityCategoryType>(ItemVisibility.visibilityInfo);
    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    return (
        <StyledModal
            isOpen={isOpen}
            centered
            style={{
                maxWidth: "50vw",
            }}
        >
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    {t("visibility")}
                </StyledH2>
            </ModalHeader>
            <ModalBody
                style={
                    cardType === "shop" && cardStatus === "En production"
                        ? {
                              pointerEvents: "none",
                          }
                        : { opacity: "1" }
                }
            >
                <StepItemVisibility
                    ItemModalVisibility={ItemModalVisibility}
                    setItemModalVisibility={setItemModalVisibility}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        toggleOpen();
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={() => {
                        setItemVisibility((prevState: StepItemWithIuud[]) =>
                            prevState.map((item: StepItemWithIuud) => {
                                if (item.itemIuud === ItemVisibility.itemIuud) {
                                    return {
                                        ...item,
                                        visibilityInfo: ItemModalVisibility,
                                    };
                                }
                                return item;
                            })
                        );

                        toggleOpen();
                    }}
                >
                    {t("Save")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
