import React from "react";

import CustomCurrencyInput from "@components/Common/CurrencyInput";
import { generateItemPriceUuid } from "@helpers/general";
import { VatRateContext } from "@pages/Catalogue/Items/addItem/Price";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

export default function PriceTTCInput({ record, index }: any) {
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const [priceTTC, setPriceTTC] = React.useState<string | number>(
        record.PrixTTC
    );
    const keys = record.key.split("|");
    const advancedPriceUuid = generateItemPriceUuid(keys);
    const vatRateContext = React.useContext(VatRateContext);
    function updatePriceHT() {
        vatRateContext.setAdvancedPriceCopy((advancedPrice) => {
            if (advancedPrice[advancedPriceUuid] === undefined) {
                return {
                    ...advancedPrice,
                    [advancedPriceUuid]: {
                        priceHT:
                            Number(priceTTC) / (1 + Number(record.TVA) / 100),
                        pricettc: Number(priceTTC),
                        tva: Number(record.TVA),
                        methodePrice: "",
                        serviceAvailability: {},
                        scheduledPrice: [],
                        originalKeyElements: keys,
                    },
                };
            }
            return {
                ...advancedPrice,
                [advancedPriceUuid]: {
                    ...advancedPrice[advancedPriceUuid],
                    priceHT: Number(priceTTC) / (1 + Number(record.TVA) / 100),
                    pricettc: Number(priceTTC),
                    tva: Number(record.TVA),
                    originalKeyElements: keys,
                },
            };
        });
    }
    let advancedPriceElement =
        vatRateContext.advancedPriceCopy[advancedPriceUuid];
    const keysCopy = [...keys];

    while (advancedPriceElement === undefined && keysCopy.length > 0) {
        keysCopy.splice(-2, 2);

        advancedPriceElement =
            vatRateContext.advancedPriceCopy[generateItemPriceUuid(keysCopy)];
    }

    React.useEffect(() => {
        if (advancedPriceElement) {
            setPriceTTC(
                parseFloat(String(advancedPriceElement.pricettc)).toFixed(2)
            );
        }
    }, [advancedPriceUuid, advancedPriceElement]);

    return record.children ? null : (
        <div className="mr-4">
            <CustomCurrencyInput
                id={`priceHT${index}`}
                name={`priceHT${index}`}
                decimalsLimit={2}
                defaultValue={priceTTC}
                onChange={(e: string) => {
                    setPriceTTC(Number.isNaN(Number(e)) ? 0 : e);
                }}
                onBlur={() => {
                    updatePriceHT();
                }}
                disabled={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                }
            />
        </div>
    );
}
