import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DominantColor } from "@components/Common/";
import { useTranslation } from "react-i18next";

type CardModalProps = {
    duplicateMaster: Function;
    isOpen: boolean;
    setIsOpen: Function;
};
function AlerteModal({ duplicateMaster, isOpen, setIsOpen }: CardModalProps) {
    const { t } = useTranslation();

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">DUPLICATION</StyledH2>
            </ModalHeader>

            <ModalBody>
                On a détecté qu'une ou plusieurs boutiques ne contiennent pas
                des cartes en production. Voulez-vous dupliquer les cartes en
                attente ?
            </ModalBody>
            <ModalFooter>
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    //     // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => {
                        duplicateMaster("In production");

                        toggleOpen();
                    }}
                    rounded
                    variant={DominantColor}
                >
                    Duplique
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}

export default AlerteModal;
