import React from "react";

import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";
import { StyledLabel, TrashIcon } from "@aureskonnect/react-ui";

import "../Configuration.css";

export type optionAddType = {
    index: number;
    element: Setting;
    currentOption: Array<Setting>;
    setCurrentOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
    addOption: Array<Setting>;
    setAddOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
};
export default function OptionCurrent({
    index,
    element,
    currentOption,
    setCurrentOption,
    addOption,
    setAddOption,
}: optionAddType) {
    const { t } = useTranslation();
    const pathname = window.location.pathname.toString().split("/")[2];
    let CategoryParent: string = "";
    if (pathname === "Categories") {
        CategoryParent = "";
    } else {
        CategoryParent = "category parent";
    }
    function handleAdd(index: number, NameItem: any) {
        const indexOfItem: number = addOption.findIndex((item: any) => {
            return item.name === NameItem;
        });

        if (indexOfItem === -1) {
            addOption.push({
                name: currentOption[index].name,
                id: currentOption[index].id,
                rank: addOption.length + 1,
                visibility: false,
            });
        }

        setAddOption(addOption);
    }

    function handleDelete(NameItem: string) {
        const indexOfItem: number = currentOption.findIndex(
            (el: any) => el.name! === NameItem
        );
        if (indexOfItem !== -1) {
            setCurrentOption((prevState: any) => {
                const newState = [...prevState];
                newState.splice(indexOfItem, 1);
                return newState;
            });
        }
    }
    const OrderByIndex = () => {
        setCurrentOption((prevState: any) => {
            const newState = [...prevState];
            newState.map((element: any, indice: number) => {
                element.rank = indice + 1;
                return element;
            });
            return newState;
        });
    };

    return (
        <Draggable
            draggableId={`${element.id.toString()} Current`}
            index={index}
        >
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="mt-2 ml-2 mr-2 option_btn__clz align-items-center"
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            className="mt-2 d-flex justify-content-between align-items-center "
                            id={element.id}
                        >
                            <StyledLabel className="ml-3 mt-1">
                                {t(element.name)}
                            </StyledLabel>
                            <div>
                                {element.id !== "designation" &&
                                    element.id !== CategoryParent &&
                                    element.id !== "reference" && (
                                        <span
                                            role="button"
                                            tabIndex={0}
                                            className="mr-2 "
                                            onClick={() => {
                                                handleAdd(index, element.name);
                                                handleDelete(element.name);
                                                OrderByIndex();
                                            }}
                                        >
                                            <TrashIcon width={20} height={20} />
                                        </span>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
