import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { ModalFooter } from "react-bootstrap";

import { DominantColor, SuccessToast } from "@components/Common/.";

export default function PlanningModalFooter({
    handleSave,
    setActive,
    record,
    period,
}: any) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const [, setValidateOnclick] = React.useState<boolean>(true);

    const handleValidateButtonOnClickEvent = () => {
        // setselectedrow((prevouisRecord: any) => {
        //     return { ...prevouisRecord, period };
        // });
        //  record.period = period;
        record = {
            ...record,
            period: JSON.parse(JSON.stringify(period)),
        };
        setValidateOnclick(false);
        SuccessToast(t("Successful registration"));
        setActive(false);

        handleSave(record);
    };

    return (
        <ModalFooter className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
                <div>
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            setActive(false);
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                </div>
                <div className="ml-3">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </ModalFooter>
    );
}
