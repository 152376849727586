import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
} from "@components/Common/";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type DeleteModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    itemToDelete: { iuud: string; title: string };
    setIsLoading: Function;
};

export default function DeleteItemModal({
    isOpen,
    setIsOpen,
    itemToDelete,
    setIsLoading,
}: DeleteModalPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const handleRemoveButtonOnClickEvent = async (): Promise<void> => {
        setIsOpen(!isOpen);
        setIsLoading(true);

        axios
            .delete(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items/${itemToDelete.iuud}`
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                setIsLoading(false);
            })
            .then((): void => {
                setIsLoading(false);

                SuccessToast(t("The Item deleted successfully"));
            })
            .catch((): void => {
                ErrorToast(t("There is a mistake!"));
            });
    };
    const toggleOpen = (): void => setIsOpen(!isOpen);
    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    <div className="d-flex row">
                        {t("Archive Item")}
                        {itemToDelete.title.length > 20 ? (
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={itemToDelete.title}
                                id="archiver"
                            />
                        ) : null}

                        <span className="mx-1">:</span>
                        <div className="modal_clz" id="archiver">
                            {itemToDelete.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to confirm the archive of this item?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleRemoveButtonOnClickEvent}
                    rounded
                    variant="primary"
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
