import React from "react";

import { ACTIONS } from "@helpers/.";

import ImageUploader from "@components/Common_DB/ImageUploader";

type ImagePropsType = {
    stepImageUrl: string;
    dispatch: Function;
    id: string;
};

export default function Image({ stepImageUrl, dispatch, id }: ImagePropsType) {
    const [uploadedImage, setUploadedImage] = React.useState<string | Blob>(
        stepImageUrl
    );
    const [, setFileType] = React.useState<"image" | "video" | "">("image");

    React.useEffect(() => {
        dispatch({
            type: ACTIONS.DEFAULT_IMAGE_URL,
            payload: uploadedImage,
        });
    }, [uploadedImage, dispatch]);

    React.useEffect(() => {
        setUploadedImage(stepImageUrl);
    }, [stepImageUrl]);

    return (
        <div
            className="d-flex justify-content-center align-items-center p-2"
            style={{
                border: "1px solid #C6C6C6",
                width: "100%",
                height: "240px",
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <ImageUploader
                id={id}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                fileType="image"
                setFileType={setFileType}
            />
        </div>
    );
}
