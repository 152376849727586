import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel } from "@aureskonnect/react-ui";

import {
    ValuesContainer,
    Value,
    XButton,
} from "@components/Common/StyledComponent";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";

import OptionsModal from "@components/Common_DB/options/OptionsModal";
import { RootState } from "@store/index";

type OptionsPropsType = {
    itemOptions: itemOptions;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    isOptionModalOpen: boolean;
    setIsOptioneModalOpen: Function;
    listOptions: any[];
    advancedPrice: Record<string, Price>;
};

export default function Options({
    itemOptions,
    dispatch,
    isOptionModalOpen,
    setIsOptioneModalOpen,
    listOptions,
    advancedPrice,
}: OptionsPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const optionsCard = listOptions?.reduce((reslt: any, current: any) => {
        return {
            ...reslt,
            [current.iuud]: current.Option,
        };
    }, {});
    const handleXButton = (e: React.MouseEvent<HTMLElement>, iuud: string) => {
        e.preventDefault();

        const newSelectedOption: itemOptions = Object.keys(itemOptions).reduce(
            (pre, curr) => {
                return {
                    ...pre,
                    [curr]: itemOptions[curr].filter((elm) => elm !== iuud),
                };
            },
            {}
        );

        for (const option of Object.keys(newSelectedOption)) {
            if (newSelectedOption[option].length === 0) {
                delete newSelectedOption[option];
            }
        }

        dispatch({
            type: ACTIONS.OPTIONS,
            payload: newSelectedOption,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.OPTIONS));
    };

    return (
        <div className="py-2" id="options">
            <div className="d-flex justify-content-between ">
                <StyledLabel>{t("Options")}</StyledLabel>

                <span
                    style={{
                        color: "blue",
                        cursor: "pointer",
                        border: "transparent",
                        backgroundColor: "transparent",
                        textAlign: "center",
                        textDecoration: "underline",
                        font: "normal normal 600 16px/21px Segoe UI",
                    }}
                    role="button"
                    tabIndex={0}
                    onClick={(): void => {
                        setIsOptioneModalOpen(true);
                    }}
                >
                    {t("Access")}
                </span>

                <OptionsModal
                    dispatchOptions={dispatch}
                    itemOptions={itemOptions}
                    setIsOpen={setIsOptioneModalOpen}
                    isOpen={isOptionModalOpen}
                    listOptions={listOptions}
                    advancedPrice={advancedPrice}
                />
            </div>

            <ValuesContainer
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                {Object.keys(itemOptions)?.map((key: any) =>
                    itemOptions[key].map((optionValueKey: string) => (
                        <Value key={optionValueKey}>
                            {optionsCard[key]?.values[optionValueKey]?.title}

                            <XButton
                                name={
                                    optionsCard[key]?.values[optionValueKey]
                                        ?.title
                                }
                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                    handleXButton(e, optionValueKey)
                                }
                            >
                                ✕
                            </XButton>
                        </Value>
                    ))
                )}
            </ValuesContainer>
        </div>
    );
}
