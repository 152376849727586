import React from "react";

import { useTranslation } from "react-i18next";

import { StyledSelectInput } from "@aureskonnect/react-ui";

import { ACTIONS } from "@helpers/actions";

type PriceGroupe2SelectInputPropsType = {
    dispatch: React.Dispatch<ReducerActionType>;
    priceGroup1: number;
    priceGroup2: number;
    maxNbrOfChoices: number;
};
export default function PriceGroupe2SelectInput({
    dispatch,
    priceGroup1,
    priceGroup2,
    maxNbrOfChoices,
}: PriceGroupe2SelectInputPropsType) {
    const { t } = useTranslation();
    const maxChoices = maxNbrOfChoices - priceGroup1;
    const choicesList: number[] = [0, maxChoices];

    const choicesOptions = choicesList.map((choice: any) => {
        return { label: choice, value: choice };
    });
    if (priceGroup2 !== maxChoices && priceGroup2 !== 0) {
        dispatch({
            type: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
            payload: maxChoices,
        });
    }
    const handleOnchangePriceGroup2Choices = (selectedValue: any): void => {
        dispatch({
            type: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
            payload: selectedValue.value,
        });
    };

    return (
        <div className="py-2" id="step">
            <StyledSelectInput
                id="pricegroup2"
                name="pricegroup2"
                placeholder={t("Select")}
                noOptionsMessage={() => "Pas d'option"}
                value={{ label: priceGroup2.toString(), value: priceGroup2 }}
                options={choicesOptions}
                onChange={handleOnchangePriceGroup2Choices}
            />
        </div>
    );
}
