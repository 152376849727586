import React from "react";

import ReactSwitch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { RootState } from "@store/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";

type isRedirectProps = {
    dispatchItem: any;
    isRedirect: boolean;
};

export default function RedirectItem({
    dispatchItem,
    isRedirect,
}: isRedirectProps) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const ETKType = localStorage.getItem("ETKType");

    return (
        <div
            className="d-flex justify-content-between  align-items-betwwen align-items-center my-2"
            id="redirectItem"
        >
            <StyledLabel>
                {t("Set this article as a redirect button")}
            </StyledLabel>

            <ReactSwitch
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={20}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={() => {
                    dispatchItem({
                        type: ACTIONS.IS_REDIRECT,
                        payload: ETKType === "1" ? true : !isRedirect,
                    });
                    isMultiModify &&
                        dispatchStore(
                            addListKeytoEdit(MULTI_EDIT_KEY.IS_REDIRECT)
                        );
                }}
                checked={ETKType === "1" ? true : isRedirect}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={45}
                height={15}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                }
            />
        </div>
    );
}
