import { WORKFLOW_LIST } from "@constants/WorkFlow";
import swapElement from "@helpers/swapElement";

export function ItemIsCrushed(
    globalWorkflow: any,
    globalIndex: number,
    modifierWorkflow: any,
    typeModifier: string,
    cardData: any
) {
    let count = 0;
    const typeStep =
        typeModifier === WORKFLOW_LIST.TYPESTEPS
            ? WORKFLOW_LIST.STEPS
            : WORKFLOW_LIST.ADD;
    globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
        (elementUuid: string) => {
            // duplicate workflow data
            if (
                Object.keys?.(
                    globalWorkflow[globalIndex!]?.data[elementUuid].content ||
                        {}
                ).length > 0
            ) {
                const UuidModifier = Object.keys(
                    globalWorkflow[globalIndex!]?.data[elementUuid].content
                )[0];

                if (
                    Object.keys?.(modifierWorkflow[UuidModifier] || {}).length >
                        0 &&
                    modifierWorkflow[UuidModifier][typeModifier] &&
                    Object.keys?.(
                        modifierWorkflow[UuidModifier][typeStep] || {}
                    ).length > 0 &&
                    StepExist(
                        modifierWorkflow,
                        UuidModifier,
                        typeStep,
                        cardData
                    )
                ) {
                    count += 1;
                }
            }
        }
    );
    if (count > 0) {
        return true;
    }
    return false;
}
export function ItemStepExist(workFlow: any) {
    let count = 0;
    if (Object.keys?.(workFlow?.data || {}).length > 0) {
        Object.entries(workFlow?.data)
            .filter(
                (element: any) =>
                    element[1] !== null && element[1].type === "items"
            )
            .forEach(() => {
                count += 1;
                return true;
            });
        if (count > 0) {
            return true;
        }
    }

    return false;
}
export function StepExist(
    modifierWorkflow: any,
    UuidModifier: string,
    modifiertypeStep: string,
    cardData: any
) {
    if (
        Object.keys?.(modifierWorkflow[UuidModifier]?.[modifiertypeStep] || {})
            .length > 0
    ) {
        let count = 0;
        Object.entries(modifierWorkflow[UuidModifier][modifiertypeStep])
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(() => {
                count += 1;
            });
        if (count > 0) {
            return true;
        }
        return false;
    }
    return false;
}
export function StepSalesExist(
    modifierWorkflow: any,
    UuidModifier: string,
    cardData: any
) {
    let count = 0;
    if (Object.keys?.(modifierWorkflow[UuidModifier]?.steps || {}).length > 0) {
        Object.entries(modifierWorkflow[UuidModifier].steps)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(() => {
                count += 1;
            });
    }
    if (count > 0) {
        return true;
    }
    if (Object.keys?.(modifierWorkflow[UuidModifier]?.add || {}).length > 0) {
        Object.entries(modifierWorkflow[UuidModifier].add)
            .filter(
                ([iuudStep]: any) =>
                    cardData.steps[iuudStep].archive === false &&
                    Object.keys?.(cardData.steps[iuudStep].stepItems || {})
                        .length > 0
            )
            .forEach(() => {
                count += 1;
            });
    }
    if (count > 0) {
        return true;
    }
    return false;
}

export function overrideWorkflowDataElementWithPath(
    workflowData: any,
    updatedKeyPath: string[],
    updatedWorkflowStepElement: ItemType | CategoryType,
    updatedWorkflowStepElementUuid: string
) {
    const workflowDataCopy = JSON.parse(JSON.stringify(workflowData));
    let pathIuud: string = "workflowDataCopy?.";
    if (updatedKeyPath.length > 0) {
        updatedKeyPath.forEach((key) => {
            pathIuud += `["${key}"]?.content`;
        });

        // eslint-disable-next-line no-eval
        const stringEvaluate = eval(pathIuud);
        stringEvaluate[updatedWorkflowStepElementUuid] = {
            ...stringEvaluate[updatedWorkflowStepElementUuid],
            override: {
                [updatedWorkflowStepElementUuid]: {
                    ...updatedWorkflowStepElement,
                },
            },
        };
    } else {
        workflowDataCopy[updatedWorkflowStepElementUuid] = {
            ...workflowDataCopy[updatedWorkflowStepElementUuid],
            override: {
                [updatedWorkflowStepElementUuid]: {
                    ...updatedWorkflowStepElement,
                },
            },
        };
    }

    return workflowDataCopy;
}

export function overrideWorkflowDataElementByUuid(
    Workflow: WorkFlowStepProps[],
    updatedWorkflowStepElement: ItemType | CategoryType,
    updatedWorkflowStepElementUuid: string,
    stepIndexInWorkflow: number
) {
    return Object.assign([], Workflow, {
        [stepIndexInWorkflow]: {
            ...Workflow[stepIndexInWorkflow],
            data: {
                ...Workflow[stepIndexInWorkflow].data,
                [updatedWorkflowStepElementUuid]: {
                    ...Workflow[stepIndexInWorkflow].data[
                        updatedWorkflowStepElementUuid
                    ],
                    override: {
                        [updatedWorkflowStepElementUuid]: {
                            ...updatedWorkflowStepElement,
                        },
                    },
                },
            },
        },
    });
}

export function OrderStepWorkflowByRank(
    workFlows: any,
    indexSource: number,
    indexDestination: number
) {
    const Newlist = swapElement([...workFlows], indexSource, indexDestination);
    let count = 1;
    Newlist.forEach((element: any, index: number) => {
        if (element.type === WORKFLOW_LIST.STEPS) {
            Newlist[index] = {
                ...Newlist[index],
                title: `Etape ${count}`,
            };
            count += 1;
        }
    });
    return Newlist;
}
export function OrderProductWorkflowByRank(
    arrayToUpdate: any,
    indexSource: number,
    indexDestination: number
) {
    const sourceItem = arrayToUpdate[indexSource];
    arrayToUpdate.splice(indexSource, 1);
    /* Destination Item Logic */
    arrayToUpdate.splice(indexDestination, 0, sourceItem);
    const Newlist = JSON.parse(JSON.stringify(arrayToUpdate));
    const updatedObj = Newlist.reduce(
        (prev: any, element: any, index: number) => {
            return {
                ...prev,
                [element.key]: { ...element.value, rank: index + 1 },
            };
        },
        {}
    );

    return updatedObj;
}

export function OrderStepModifierByRank(
    workFlows: WorkFlowStepProps[],
    source: any,
    copyModifier: any,
    destination: any,
    modifierType: string,
    previousModifier: string
) {
    const oldRank =
        copyModifier[previousModifier][modifierType][
            workFlows[source.index].uuidStep
        ].rank;
    const newRank =
        copyModifier[previousModifier][modifierType][
            workFlows[destination.index].uuidStep
        ].rank;

    copyModifier = {
        ...copyModifier,
        [previousModifier]: {
            ...copyModifier[previousModifier],
            [modifierType]: {
                ...copyModifier[previousModifier][modifierType],

                [workFlows[source.index].uuidStep]: {
                    ...copyModifier[previousModifier][modifierType][
                        workFlows[source.index].uuidStep
                    ],
                    rank: newRank,
                },
                [workFlows[destination.index].uuidStep]: {
                    ...copyModifier[previousModifier][modifierType][
                        workFlows[destination.index].uuidStep
                    ],
                    rank: oldRank,
                },
            },
        },
    };
    return copyModifier;
}

export function InsertStepUuidInModifer(
    modifierWorkflow: any,
    triggerItemIuud: string,
    modifierType: WORKFLOW_LIST,
    stepItemsSource: {
        type: "step" | "category" | "workflow" | "option";
        iuud: string;
    },
    workflowType: any,
    rank: number
): any {
    return {
        ...modifierWorkflow,
        [workflowType.previousModifier]: {
            ...modifierWorkflow[workflowType.previousModifier],
            "uuid-item": triggerItemIuud,
            [modifierType]: {
                ...modifierWorkflow[workflowType.previousModifier][
                    modifierType
                ],
                [stepItemsSource.iuud]: {
                    overrides: {},
                    itemModifiers: {},
                    rank,
                },
            },
        },
    };
}

export function resetMultipleWorkflow(
    copyWorkflowModal: any[],
    globalIndex: any
) {
    copyWorkflowModal[globalIndex!] = {
        ...copyWorkflowModal[globalIndex!],
        multipleStep: {
            ...copyWorkflowModal[globalIndex!].multipleStep,
            isMultiple: false,
            listeUuidItem: [],
        },
    };
}
export function GlobalVisibilityMultipleInProduct(
    globalWorkflow: any,
    globalIndex: number,
    cardData: any
) {
    if (globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.length > 0) {
        return globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.some(
            (elementUuid: string) => {
                const currentItem: ItemType =
                    Object.keys(
                        globalWorkflow[globalIndex].data[elementUuid]
                            .override || {}
                    ).length > 0
                        ? globalWorkflow[globalIndex].data[elementUuid]
                              .override[elementUuid]
                        : cardData?.items?.[elementUuid];
                return currentItem && currentItem.visibilityInfo.isVisible;
            }
        );
    }
    return true;
}

export function checkModifierExist(
    firstUuidSelected: string,
    typeWorkflow: string,
    startIndex: number,
    copyModifierMultiple: any,
    copyWorkflowModal: any
) {
    const modifierForm: any = { isModifier: false, modifierUuid: "" };

    if (
        Object.keys?.(
            copyWorkflowModal[startIndex].data[firstUuidSelected].content || {}
        ).length > 0
    ) {
        // eslint-disable-next-line prefer-destructuring
        modifierForm.modifierUuid = Object.keys(
            copyWorkflowModal[startIndex].data[firstUuidSelected].content
        )[0];
        if (
            Object.keys?.(copyModifierMultiple[modifierForm.modifierUuid] || {})
                .length > 0 &&
            copyModifierMultiple[modifierForm.modifierUuid][typeWorkflow]
        ) {
            modifierForm.isModifier = true;
        }
    }
    return modifierForm;
}
export function checkMultipleItemInStep(
    multipleWorkflow: any,
    globalWorkflow: any,
    globalIndex: number,
    uuid: string
) {
    const multipleForm: any = { isMultiple: false, firstItemContent: {} };

    if (
        multipleWorkflow.length > 0 &&
        multipleWorkflow[0].popupIndex === -1 &&
        multipleWorkflow[0].StepIndex === globalIndex &&
        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.includes(uuid)
    ) {
        multipleForm.isMultiple = true;
    }

    if (globalWorkflow[globalIndex!]?.multipleStep?.isMultiple) {
        multipleForm.firstItemContent =
            globalWorkflow[globalIndex!]?.data[
                globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem[0]
            ].content;
    }

    return multipleForm;
}
export function checkStepExistEtkLight(
    UuidSteps: string,
    ETKType: string | null,
    multipleWorkflow: any,
    modifierWorkflow: any,
    IuudModifier: string
) {
    if (ETKType !== "3" || ETKType === null) {
        return true;
    }
    if (IuudModifier === undefined) {
        return true;
    }
    const copyModifier =
        multipleWorkflow.length > 0
            ? {
                  ...multipleWorkflow[multipleWorkflow.length - 1]
                      .VirtualModifier,
                  ...modifierWorkflow,
              }
            : modifierWorkflow;
    // eslint-disable-next-line no-prototype-builtins
    if (!copyModifier.hasOwnProperty(IuudModifier)) {
        return true;
    }
    if (
        Object.keys(copyModifier[IuudModifier].steps || {})?.includes(
            UuidSteps
        ) ||
        Object.keys(copyModifier[IuudModifier].add || {})?.includes(UuidSteps)
    ) {
        return false;
    }
    return true;
}
export function checkAllItemsIsRedirect(
    listeUuidItem: string[],
    cardData: any
) {
    const itemsRedirect = listeUuidItem.filter(
        (element: string) => cardData.items[element].isRedirect
    );
    const itemsNoRedirect = listeUuidItem.filter(
        (element: string) => !cardData.items[element].isRedirect
    );
    if (itemsRedirect.length > 0 && itemsNoRedirect.length > 0) {
        return true;
    }
    return false;
}
