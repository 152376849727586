/* eslint-disable no-nested-ternary */
import React from "react";

import CategoriesIcon from "@components/Common/SvgIcons/CategoriesIconMinimal";
import ItemIcon from "@components/Common/SvgIcons/ItemIcon";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type IconElementProps = {
    iconType: string;
    globalIndex?: number;
    order?: number;
};
export function IconElement({
    iconType,
    globalIndex,
    order,
}: IconElementProps) {
    const { t } = useTranslation();
    return (
        <div>
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={
                    iconType === WORKFLOW_LIST.CATEGORIES
                        ? t("Category")
                        : t("Item")
                }
                id={`lockcIcon${globalIndex!}${order!}`}
            />
            {iconType === WORKFLOW_LIST.CATEGORIES ? (
                <CategoriesIcon
                    id={`lockcIcon${globalIndex!}${order!}`}
                    width={31}
                    height={31}
                    fill="#9E1470"
                    className="mx-1"
                    onClick={(event: any) => {
                        event.stopPropagation();
                    }}
                />
            ) : (
                <ItemIcon
                    id={`lockcIcon${globalIndex!}${order!}`}
                    className="mx-1"
                    width={31}
                    height={31}
                    style={{
                        background: "#096595",
                        borderRadius: "100%",
                        padding: "5px",
                    }}
                    onClick={(event: any) => {
                        event.stopPropagation();
                    }}
                />
            )}
        </div>
    );
}
