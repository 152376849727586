import React from "react";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { setWorkflow } from "@store/features/workflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";

type VisibilityStepProductProps = {
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    globalIndex: number;
    cardData: any;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    isVisibleMultiple: boolean;
};

export function VisibilityStepProduct({
    setIndexSelected,
    setIsClickStep,
    isClickStep,
    globalIndex,
    cardData,
    setIsUnsavedDataMultiple,
    isVisibleMultiple,
}: VisibilityStepProductProps) {
    const dispatch = useDispatch();
    const ETKType = localStorage.getItem("ETKType");

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const handleVisibility = () => {
        if (multipleWorkflow.length > 0) {
            setIsUnsavedDataMultiple(true);

            return;
        }

        const listUuidItems =
            globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem;

        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        let copyWorkflowData = JSON.parse(JSON.stringify(globalWorkflowData));

        listUuidItems.forEach((elementUuid: string) => {
            const currentItem: ItemType =
                Object.keys(
                    copyWorkflow[globalIndex].data[elementUuid].override || {}
                ).length > 0
                    ? copyWorkflow[globalIndex].data[elementUuid].override[
                          elementUuid
                      ]
                    : cardData?.items?.[elementUuid];

            const updatedWorkflowStepElement = {
                ...currentItem,
                visibilityInfo: {
                    ...currentItem.visibilityInfo,
                    isVisible: isVisibleMultiple !== true,
                },
            };
            copyWorkflow = overrideWorkflowDataElementByUuid(
                copyWorkflow,
                updatedWorkflowStepElement,
                elementUuid,
                globalIndex
            );

            const { previousUuids } = copyWorkflow[globalIndex];

            copyWorkflowData = overrideWorkflowDataElementWithPath(
                copyWorkflowData,
                previousUuids,
                updatedWorkflowStepElement,
                elementUuid
            );
        });
        dispatch(setWorkflow(copyWorkflow));
        dispatch(setWorkflowData(copyWorkflowData));
    };
    return (
        <div
            className={
                ETKType === "3"
                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                    : "icon_step__CLZ text-center mr-2"
            }
        >
            {isVisibleMultiple ? (
                <EyeIcon
                    height={20}
                    width={20}
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        handleVisibility();
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                        event.stopPropagation();
                    }}
                />
            ) : (
                <EyeCrossedIcon
                    height={20}
                    width={20}
                    fill="red"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        handleVisibility();
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                        event.stopPropagation();
                    }}
                />
            )}
        </div>
    );
}
