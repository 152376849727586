export function handleDeleteItemOptions(
    itemOptions: itemOptions,
    updatedItemOptions: itemOptions,
    advancedPrice: Record<string, Price>
): Record<string, Price> {
    const deletedOptions = Object.entries(itemOptions).reduce(
        (deletedOptions: string[], [optionKey, optionValues]) => {
            if (updatedItemOptions[optionKey] === undefined) {
                deletedOptions.push(optionKey);
            } else {
                optionValues.forEach((optionValue) => {
                    if (!updatedItemOptions[optionKey].includes(optionValue)) {
                        deletedOptions.push(optionValue);
                    }
                });
            }
            return deletedOptions;
        },
        []
    );
    return Object.entries(advancedPrice).reduce(
        (
            updatedAdvancedPrice: Record<string, Price>,
            [advancedPriceKey, advancedPrice]
        ) => {
            const canDeleteAdvancedPrice =
                advancedPrice.originalKeyElements?.some((key) =>
                    deletedOptions.includes(key)
                );

            if (canDeleteAdvancedPrice === false) {
                updatedAdvancedPrice[advancedPriceKey] = advancedPrice;
            }
            return updatedAdvancedPrice;
        },
        {}
    );
}

export function priceInputOnKeyDown(e: any) {
    const acceptedInputs = [
        "ArrowRight",
        "ArrowLeft",
        "ArrowUp",
        "ArrowDown",
        "Backspace",
        ".",
    ];

    if (Number.isNaN(Number(e.key)) && !acceptedInputs.includes(e.key)) {
        e.preventDefault();
    }
}
