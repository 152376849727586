import React from "react";

import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { PageTitle } from "@components/.";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import { LoadingDataAnimation } from "@components/Common";
import { RootState } from "@store/index";
import StepsList from "./StepsList";
import AddStep from "./AddStep";

export default function Steps() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    const [initialData, setInitialData] = React.useState<any>(null);
    const [isLoading] = React.useState(false);
    React.useEffect(() => {
        return () => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <>
            <PageTitle title={t("Etapes")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },

                        {
                            item: t("Steps").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />

                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is essential to create and configure the steps that allow the customisation of articles. A step can be used by several articles and personalised according to its trigger article."
                        )}
                    />

                    <DynamicTableWrapper
                        TableComponent={
                            <StepsList setInitialData={setInitialData} />
                        }
                        ActionColumnComponent={
                            <AddStep initialData={initialData} />
                        }
                    />
                </Container>
            </motion.div>
        </>
    );
}
