import { DocumentIcon, TrashIcon, FolderIcon } from "@aureskonnect/react-ui";
import React from "react";
import { IndexPeriodTable } from "@store/features";
import { RootState } from "@store/index";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";

export type ModifyPeriodType = {
    setPeriod: React.Dispatch<any>;
    period: any;
    ValueRender: string;
    indexPeriod: number;
    setRender: React.Dispatch<React.SetStateAction<string>>;
    setEnable: React.Dispatch<React.SetStateAction<boolean>>;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModifyPeriod({
    setPeriod,
    period,
    ValueRender,
    indexPeriod,
    setRender,
    setEnable,
    setIsActive,
}: ModifyPeriodType) {
    const dispatch = useDispatch();
    let indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    let { principalIndex } = indexPeriodCopyPrincipal;

    function VerifyCkeckbox(indexValue: number) {
        // close all checkbox
        period.map((element: any, indice: number) => {
            if (indexValue !== indice && period.length > 1) {
                element.active = false;
            }
            return element;
        });

        // Verify Enable field
        const verifyDateDebut = period[principalIndex].date.findIndex(
            (element: any) => element.DateDebut.length !== 0
        );
        const verifyDateFin = period[principalIndex].date.findIndex(
            (element: any) => element.DateFin.length !== 0
        );

        if (period[principalIndex].active === true) {
            if (verifyDateDebut !== -1) {
                setEnable(true);
            } else {
                setEnable(false);
                setIsActive(false);
            }
            if (verifyDateFin !== -1) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        }
    }

    function CopyPasteChangeDelete(action: string) {
        let verify = false;
        switch (action) {
            case "Copy":
                indexPeriodCopyPrincipal = {
                    ...indexPeriodCopyPrincipal,
                    copyIndex: indexPeriod,
                };
                dispatch(IndexPeriodTable(indexPeriodCopyPrincipal));

                return indexPeriodCopyPrincipal;
            case "Paste":
                if (indexPeriodCopyPrincipal.copyIndex !== -1) {
                    setPeriod((prevState: any) => {
                        const newState = [...prevState];
                        const PasteId: number = newState[indexPeriod].id;

                        newState[indexPeriod] = {
                            id: PasteId,
                            name: newState[indexPeriodCopyPrincipal.copyIndex]
                                .name,
                            date: newState[indexPeriodCopyPrincipal.copyIndex]
                                .date,
                            Day: newState[indexPeriodCopyPrincipal.copyIndex]
                                .Day,
                        };
                        return newState;
                    });
                }
                return period;
            case "Delete":
                if (period[indexPeriod].active === true) {
                    verify = true;
                }

                period.splice(indexPeriod, 1);

                if (verify === true && indexPeriod > 0) {
                    period[indexPeriod - 1].active = true;
                    principalIndex = indexPeriod - 1;
                    indexPeriodCopyPrincipal = {
                        ...indexPeriodCopyPrincipal,
                        principalIndex,
                    };
                    dispatch(IndexPeriodTable(indexPeriodCopyPrincipal));
                } else if (verify === true && indexPeriod === 0) {
                    period[0].active = true;
                    indexPeriodCopyPrincipal = {
                        ...indexPeriodCopyPrincipal,
                        principalIndex: 0,
                    };
                    dispatch(IndexPeriodTable(indexPeriodCopyPrincipal));
                }
                setPeriod(period);
                return period;
            case "Change":
                if (
                    period[indexPeriod].name.length !== 0 &&
                    period[indexPeriod].date.length === 0
                ) {
                    period[indexPeriod].date = [{ DateDebut: "", DateFin: "" }];
                    if (period[indexPeriod].active === true) {
                        setEnable(false);
                    }
                } else if (period[indexPeriod].name.length === 0) {
                    period[indexPeriod].date = [];
                    if (period[indexPeriod].active === true) {
                        setEnable(false);
                        setIsActive(false);
                    }
                }
                return period;
            default:
                return period;
        }
    }
    return (
        <div className="d-flex ml-2 mr-2 mt-2 mb-2  justify-content-between align-items-center">
            <div className="d-flex ml-3 align-items-center">
                <Input
                    id={period[indexPeriod].id}
                    type="checkbox"
                    checked={period[indexPeriod].active}
                    value={period[indexPeriod].active}
                    onChange={() => {
                        if (period[indexPeriod].active === false) {
                            period[indexPeriod].active =
                                !period[indexPeriod].active;
                            principalIndex = indexPeriod;

                            VerifyCkeckbox(indexPeriod);
                            setPeriod(period);
                            indexPeriodCopyPrincipal = {
                                ...indexPeriodCopyPrincipal,
                                principalIndex,
                            };
                            dispatch(
                                IndexPeriodTable(indexPeriodCopyPrincipal)
                            );
                            setRender(
                                `${ValueRender} ${indexPeriod} Changecheckbox`
                            );
                        }
                    }}
                    style={{ position: "relative" }}
                />
                <Input
                    className="ml-2"
                    id={period[indexPeriod].id}
                    name={period[indexPeriod].name}
                    value={period[indexPeriod].name}
                    onChange={(e: any) => {
                        period[indexPeriod].name = e.target.value;
                        CopyPasteChangeDelete("Change");
                        setRender(`${ValueRender} ChangeName`);
                    }}
                    placeholder=""
                    type="text"
                />
            </div>
            <div className="d-flex justify-content-end ml-2">
                <div>
                    <FolderIcon
                        height={20}
                        width={20}
                        onClick={() => {
                            CopyPasteChangeDelete("Paste");
                            setRender(`${ValueRender} Paste Period`);
                        }}
                    />
                </div>
                <div>
                    <DocumentIcon
                        height={20}
                        width={20}
                        onClick={() => {
                            CopyPasteChangeDelete("Copy");
                        }}
                    />
                </div>
                <div>
                    <TrashIcon
                        height={20}
                        width={20}
                        onClick={() => {
                            if (period.length > 1) {
                                CopyPasteChangeDelete("Delete");
                                VerifyCkeckbox(principalIndex);
                                setRender(`${ValueRender} deletePeriod`);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
