import { Navigate } from "react-router-dom";

type Props = {
    children: JSX.Element;
};
export default function RequireSelectedCard({ children }: Props): JSX.Element {
    if (!localStorage.getItem("selectedCard")) {
        return <Navigate to="/home" />;
    }
    return children;
}
