import React from "react";

import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { motion } from "framer-motion/dist/framer-motion";

import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { LoadingDataAnimation } from "@components/Common";
import { RootState } from "@store/.";
import CardsList from "./CardsList";
import AddCard from "./AddCard";
import { PageTitle } from "../../components/index";

export default function Cards() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );
    React.useEffect((): (() => void) => {
        return (): void => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);
    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <React.Fragment>
            <PageTitle title="Carte" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE ",
                            link: "home",
                        },
                        {
                            item: selectedStore
                                ? selectedStore.toUpperCase()
                                : selectedStore,
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        {
                            item: t("CARD LIST"),
                            link: "#",
                        },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is possible to duplicate, publish or plan a franchise card and manage the different store cards one by one or simultaneously. Only one map per store can be in production (published) and will not be editable. All modifications must be made on a copy (project card)"
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<CardsList />}
                        ActionColumnComponent={
                            <AddCard setIsLoading={setIsLoading} />
                        }
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
