import React from "react";

import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/index";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import DescriptionModal from "@components/Common_DB/AdvancedDescriptionModal";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";

type DescriptionPropsType = {
    isDescriptionNameModalOpen: boolean;
    setisDescriptionNameModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    description: DisplayNameType;
    DefaultValueName: string;
    // eslint-disable-next-line no-unused-vars
};

export default function Description({
    isDescriptionNameModalOpen,
    setisDescriptionNameModalOpen,
    dispatch,
    description,
    DefaultValueName,
}: DescriptionPropsType) {
    const { t } = useTranslation();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const dispatchStore = useDispatch();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const [counter, setCounter] = React.useState<number>(2000);

    const handleOnchangeDescription = (Description: string) => {
        setCounter(2000 - Description.length);
        dispatch({
            type: ACTIONS.DEFAULT_DISCRIPTION,
            payload: Description,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.DESCRIPTION));
    };

    if (DefaultValueName === null) {
        DefaultValueName = "";
    }
    React.useEffect(() => {
        if (DefaultValueName !== null) {
            setCounter(2000 - DefaultValueName.length);
        }
    }, [DefaultValueName]);

    return (
        <div className="py-2" id="desription">
            <div className="d-flex justify-content-between">
                <StyledLabel>{t("Description")}</StyledLabel>
                {!isMultiModify && (
                    <span
                        style={{
                            color: "blue",
                            cursor: "pointer",
                            border: "transparent",
                            backgroundColor: "transparent",
                            textAlign: "center",
                            textDecoration: "underline",
                            font: "normal normal 600 16px/21px Segoe UI",
                        }}
                        role="button"
                        tabIndex={0}
                        onClick={(): void => {
                            setisDescriptionNameModalOpen(true);
                        }}
                    >
                        {t("Advanced")}
                    </span>
                )}

                {isDescriptionNameModalOpen && (
                    <DescriptionModal
                        dispatchItem={dispatch}
                        description={description}
                        setIsOpen={setisDescriptionNameModalOpen}
                        isOpen={isDescriptionNameModalOpen}
                        DefaultValueName={DefaultValueName}
                    />
                )}
            </div>
            <div>
                <StyledInputWithTooltip
                    maxlength={2000}
                    id="Description"
                    name="Description"
                    type="textarea"
                    value={DefaultValueName}
                    requiredValidateValue={false}
                    onFocus={(event: any) => {
                        event.target.select();
                    }}
                    valueSetterFunction={handleOnchangeDescription}
                    disabled={
                        isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production"
                    }
                />
                {DefaultValueName !== null ? (
                    <div className="d-flex justify-content-end ">
                        {DefaultValueName.length > 0 ? (
                            <small>
                                {t("Maximum number of characters")}:{counter}
                                /2000
                            </small>
                        ) : (
                            <small>
                                {t("Maximum number of characters 2000")}
                            </small>
                        )}
                    </div>
                ) : (
                    <div className="d-flex justify-content-end ">
                        <small>{t("Maximum number of characters 2000")}</small>
                    </div>
                )}
            </div>
        </div>
    );
}
