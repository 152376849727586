import React from "react";

import { useTranslation } from "react-i18next";

import VatRateSelectInput from "./VatRateSelectInput";

type SaleModeVatRatePropsType = {
    saleMode: { id: string; designation: string };
    saleModeVatRates: {
        saleModeUuid: string;
        value: number;
    }[];
    setSalesModeVatRates: React.Dispatch<
        React.SetStateAction<
            {
                saleModeUuid: string;
                value: number;
            }[]
        >
    >;
};

export default function SaleModeVatRate({
    saleMode,
    saleModeVatRates,
    setSalesModeVatRates,
}: SaleModeVatRatePropsType) {
    const { t } = useTranslation();

    const filteredSelectedVatRate = saleModeVatRates.find(
        (vatRate) => vatRate.saleModeUuid === saleMode.id
    );

    const selectedVatRate: {
        label: string;
        value: string;
    } = filteredSelectedVatRate
        ? {
              label: `${filteredSelectedVatRate.value} %`,
              value: String(filteredSelectedVatRate.value),
          }
        : {
              label: t("Select"),
              value: "0",
          };

    const handleVatRateChange = (selectedVatRate: number) => {
        setSalesModeVatRates((prevVatRates) => {
            const saleModeVatRateIndex = prevVatRates.findIndex(
                (saleModeVatRate) =>
                    saleModeVatRate.saleModeUuid === saleMode.id
            );
            if (saleModeVatRateIndex === -1) {
                return [
                    ...prevVatRates,
                    { saleModeUuid: saleMode.id, value: selectedVatRate },
                ];
            }
            return prevVatRates.map((vatRate) =>
                vatRate.saleModeUuid === saleMode.id
                    ? { value: selectedVatRate, saleModeUuid: saleMode.id }
                    : vatRate
            );
        });
    };
    return (
        <div className="row justify-content-center align-items-center mb-4">
            <div className="col-3">{saleMode.designation}</div>
            <div className="col-5">
                <VatRateSelectInput
                    handleVatRateChange={handleVatRateChange}
                    selectedVatRate={selectedVatRate}
                />
            </div>
        </div>
    );
}
