import React from "react";

export default function ChoiceIcon(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24.007}
            height={21.893}
            {...props}
        >
            <g data-name="Groupe 18578" fill="#2b2828">
                <path
                    data-name="Trac\xE9 17835"
                    d="M15.391 14.645a1.371 1.371 0 0 0-.551.1 1.024 1.024 0 0 0-1.045-.736 1.365 1.365 0 0 0-.557.1 1.051 1.051 0 0 0-1.039-.708 1.368 1.368 0 0 0-.541.092v-1.314a.993.993 0 0 0-1.055-.85 1.287 1.287 0 0 0-.8.234.75.75 0 0 0-.335.6v2.914a1.366 1.366 0 0 0-.5-.093 1.28 1.28 0 0 0-.774.245.746.746 0 0 0-.321.591v.945a.307.307 0 0 0 .592 0v-.945a.342.342 0 0 1 .147-.271.588.588 0 0 1 .355-.112.455.455 0 0 1 .5.383v1.46l-.133.1a.191.191 0 0 0 0 .32.364.364 0 0 0 .419 0l.219-.168a.2.2 0 0 0 .087-.16v-5.211a.344.344 0 0 1 .153-.276.59.59 0 0 1 .367-.107.458.458 0 0 1 .483.4v4.556a.307.307 0 0 0 .593 0v-2.5a.344.344 0 0 1 .153-.276.592.592 0 0 1 .367-.107.457.457 0 0 1 .483.4v2.484a.307.307 0 0 0 .592 0v-1.89a.344.344 0 0 1 .153-.275.59.59 0 0 1 .367-.107.458.458 0 0 1 .483.4v1.875a.307.307 0 0 0 .592 0v-1.249a.344.344 0 0 1 .153-.276.59.59 0 0 1 .367-.107.458.458 0 0 1 .483.4v3.372a1.713 1.713 0 0 1-.087.536l-.208.636a2.066 2.066 0 0 0-.1.646v.754H9.661v-.749a.984.984 0 0 0-.3-.684l-.718-.731a.6.6 0 0 1-.18-.413v-1.047a.307.307 0 0 0-.592 0v1.047a.985.985 0 0 0 .3.685l.718.731a.594.594 0 0 1 .18.413v.975a.269.269 0 0 0 .3.226h6.384a.268.268 0 0 0 .3-.226v-.98a1.714 1.714 0 0 1 .087-.536l.208-.636a2.066 2.066 0 0 0 .1-.646v-3.381a.993.993 0 0 0-1.057-.853Z"
                />
                <path
                    data-name="Trac\xE9 17836"
                    d="M3.502 5.246a.588.588 0 0 0 0 .832l1.163 1.163a.589.589 0 0 0 .832 0l2.325-2.325a.589.589 0 0 0-.832-.832L5.079 5.992l-.747-.746a.589.589 0 0 0-.83 0Z"
                />
                <path
                    data-name="Trac\xE9 17837"
                    d="M11.323 8.319V3a3.008 3.008 0 0 0-3-3H7.035a.589.589 0 0 0 0 1.177H8.32A1.829 1.829 0 0 1 10.146 3v5.319a1.829 1.829 0 0 1-1.827 1.828H3.005A1.83 1.83 0 0 1 1.18 8.319V3a1.83 1.83 0 0 1 1.828-1.828h1.284a.589.589 0 1 0 0-1.177H3.005a3.008 3.008 0 0 0-3 3v5.324a3.008 3.008 0 0 0 3 3H8.32a3.008 3.008 0 0 0 3.003-3Z"
                />
                <path
                    data-name="Trac\xE9 17838"
                    d="M15.687 11.324h5.315a3.008 3.008 0 0 0 3-3V3a3.008 3.008 0 0 0-3-3h-5.315a3.008 3.008 0 0 0-3 3v5.319a3.008 3.008 0 0 0 3 3.005ZM13.86 3a1.829 1.829 0 0 1 1.827-1.827h5.315A1.829 1.829 0 0 1 22.834 3v5.319a1.829 1.829 0 0 1-1.827 1.827h-5.315a1.829 1.829 0 0 1-1.827-1.827Z"
                />
            </g>
        </svg>
    );
}
