import React from "react";

import { Card } from "reactstrap";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { PageTitle, Breadcurmb } from "@components/index";
import SelectedAccount from "./SelectedAccount";

import "./index.css";

export default function SelectAccount() {
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    const [isVisible] = React.useState(true);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const arrayOfCustomColumns = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: SelectedAccount,
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title="Accueil" />

            <div className="page-content">
                <Breadcurmb
                    title="ACCUEIL"
                    breadcrumbItem="BURGER KING FRANCE  NOTRE CARTE"
                />
                <Card style={{ padding: "20px" }}>
                    <DynamicTable
                        url={`${process.env.REACT_APP_Access_API_URL}entity/${uidUserEntity}`}
                        actionColumn={() => null}
                        canResize
                        showGlobalFilter
                        showFilter
                        canExpand
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        minHeight={`calc(100vh - (370px + ${
                            isVisible ? "100px" : "0px"
                        }))`}
                        maxHeight={`calc(100vh - (370px + ${
                            isVisible ? "125px" : "0px"
                        }))`}
                    />
                </Card>
            </div>
        </React.Fragment>
    );
}
