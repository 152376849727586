import React from "react";

type CheckedProductModalProps = {
    uuid: string;
    globalIndex: number;
    colorElement: string | undefined;
    order: number | undefined;
    IsOptionStep: boolean;
    IsBasicCompositionStep: boolean;
    contentModal: any;
    setContentModal: React.Dispatch<any>;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
};

export function CheckedProductModal({
    uuid,
    globalIndex,
    colorElement,
    order,
    IsOptionStep,
    IsBasicCompositionStep,
    contentModal,
    setContentModal,
    setIsModify,
    isModify,
    setIndexSelected,
}: CheckedProductModalProps) {
    const ETKType = localStorage.getItem("ETKType");
    const handleMultipleStep = () => {
        const copyWorkflow = [...contentModal];
        const listeUuidItem = [
            ...contentModal[contentModal.length - 1].data[globalIndex!]
                .multipleStep.listeUuidItem,
        ];
        const indexUuid = contentModal[contentModal.length - 1].data[
            globalIndex!
        ].multipleStep.listeUuidItem.findIndex(
            (element: string) => element === uuid
        );

        indexUuid === -1
            ? listeUuidItem.push(uuid)
            : listeUuidItem.splice(indexUuid, 1);

        const isMultiple = listeUuidItem.length > 1;

        // edit data modal
        copyWorkflow[contentModal.length - 1].data[globalIndex!] = {
            ...copyWorkflow[contentModal.length - 1].data[globalIndex!],
            multipleStep: {
                ...copyWorkflow[contentModal.length - 1].data[globalIndex!]
                    .multipleStep,
                isMultiple,
                listeUuidItem,
            },
            selected: uuid,
        };

        setIndexSelected(globalIndex);
        setContentModal(copyWorkflow);
        setIsModify(!isModify);
    };

    return (
        <div
            style={{
                display: "flex",
                borderRight: `4px solid ${colorElement}`,
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <div style={{ fontSize: "17px" }}>{order}</div>
            {!IsOptionStep && !IsBasicCompositionStep && (
                <div className={ETKType === "3" ? " disabledDiv__CLZ" : ""}>
                    <input
                        type="checkbox"
                        id="option"
                        checked={contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.multipleStep?.listeUuidItem.includes(uuid)}
                        onChange={() => {
                            handleMultipleStep();
                        }}
                        style={{ height: "17px", width: "17px" }}
                    />
                </div>
            )}
        </div>
    );
}
