import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DeleteAllergenModal({
    isOpen,
    setIsOpen,
    cardIuud,
    allergenToDelete,
    setIsLoading,
}: {
    isOpen: boolean;
    setIsOpen: Function;
    cardIuud: string;
    allergenToDelete: { iuud: string; title: string };
    setIsLoading: Function;
}) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleRemoveButtonOnClickEvent = async (): Promise<void> => {
        setIsLoading(true);

        axios
            .delete(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/allergens/${allergenToDelete.iuud}`
            )
            .then(() => {
                setIsOpen(!isOpen);
                setIsLoading(false);
                dynamicTableWrapperContext?.setDataUpdated(true);
            })
            .then(() => {
                SuccessToast(t("Allergen deleted successfully"));
            })
            .catch(() => {
                setIsLoading(false);
                ErrorToast(t("There is a mistake!"));
            });
    };
    const toggleOpen = () => setIsOpen(!isOpen);
    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex" style={{ whiteSpace: "nowrap" }}>
                        {t("Archive Allergen")} <span className="mx-1">:</span>
                        <LockIconWithToolTip
                            type="title"
                            lengthMax={20}
                            message={allergenToDelete.title}
                            id="archiver"
                        />
                        <div id="archiver" className="modal_clz">
                            {allergenToDelete.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to confirm the archive of this allergen?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleRemoveButtonOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
