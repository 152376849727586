import React from "react";

import { ACTIONS } from "@helpers/actions";
import { AllergenIconMinimal } from "@components/Common/SvgIcons";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import { initialState } from "./reducer";

type AddAllergenHeaderPropsType = {
    // eslint-disable-next-line no-undef
    dispatchAllergenForm: React.Dispatch<ReducerActionType>;
};

export default function AddAllergenHeader({
    dispatchAllergenForm,
}: AddAllergenHeaderPropsType) {
    const handleRefreshIconClick = (): void => {
        dispatchAllergenForm({
            type: ACTIONS.RESET,
            payload: initialState,
        });
    };
    return (
        <StyleCardHeader
            title="Allergen"
            refrechIconFunction={handleRefreshIconClick}
            Icon={
                <AllergenIconMinimal
                    width={30}
                    height={30}
                    fill="#DE5100"
                    style={{
                        borderRadius: "50%",
                    }}
                />
            }
        />
    );
}
