import { ACTIONS } from "@helpers/actions";

export const initialStateCard: CardType = {
    title: "",
    remark: "",
    color: "",
};

export const reducerCard = (
    state: CardType,
    action: ReducerActionType
): CardType => {
    switch (action?.type) {
        case ACTIONS.TITLE:
            return { ...state, title: action.payload };

        case ACTIONS.REMARQUE:
            return { ...state, remark: action.payload };
        case ACTIONS.COLOR:
            return { ...state, color: action.payload };

        case ACTIONS.EDIT:
            return action.payload;
        default:
            return state;
    }
};
