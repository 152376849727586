import { FixedSizeList as List } from "react-window";

import StepItem from "./StepItem";

type StepItemsPropsType = {
    step: StepWithChildrenType;
    selectedStepItems: StepItemWithIuud[];
    collapsedStepsUuids: string[];
    addSelectedItem: Function;
    deleteSelectedItem: Function;
};

export default function StepItems({
    step,
    collapsedStepsUuids,
    selectedStepItems,
    addSelectedItem,
    deleteSelectedItem,
}: StepItemsPropsType) {
    const isCurrentStepCollapsed = collapsedStepsUuids.includes(step.iuud);
    return (
        <div
            className="ml-4"
            style={{
                display: isCurrentStepCollapsed ? "none" : "block",
            }}
        >
            <List
                height={35 * step.items.length}
                itemCount={step.items.length}
                itemSize={35}
                width="100%"
            >
                {({
                    index,
                    style = {
                        overflowY: "hidden",
                    },
                }) => (
                    <div style={style}>
                        <StepItem
                            item={step.items[index]}
                            selectedStepItems={selectedStepItems}
                            addSelectedItem={addSelectedItem}
                            deleteSelectedItem={deleteSelectedItem}
                            key={step.items[index].itemIuud}
                        />
                    </div>
                )}
            </List>
        </div>
    );
}
