import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";

import { RootState } from "@store/.";
import AllergensModal from "./AllergensModal";
import AllergensSelectInput from "./AllergensSelectInput";

type AllergensModalPropsType = {
    itemAllergens: string[];
    dispatch: Function;
    isRedirect: boolean;
};

export default function Allergens({
    dispatch,
    itemAllergens,
    isRedirect,
}: AllergensModalPropsType): JSX.Element {
    const { t } = useTranslation();
    const allergensList = useSelector(
        (
            state: RootState
        ): {
            iuud: string;
            allergen: AllergenType;
        }[] => state.allergenSlice.allergens
    );

    return (
        <div id="allergene">
            <div className="d-flex justify-content-between py-2">
                <StyledLabel>{t("Allergens")}</StyledLabel>

                {allergensList.length > 0 && (
                    <AllergensModal
                        isRedirect={isRedirect}
                        itemAllergens={itemAllergens}
                        dispatch={dispatch}
                    />
                )}
            </div>

            <AllergensSelectInput
                isRedirect={isRedirect}
                itemAllergens={itemAllergens}
                dispatch={dispatch}
            />
        </div>
    );
}
