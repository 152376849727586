import { ACTIONS } from "@helpers/actions";

export const initialStateStep: StepType = {
    reference: "",
    title: "",
    displayName: {
        Default: {
            nameDefault: "",
            salesSupport: [],
            impression: [],
        },
    },
    isBasic: false,
    isCreational: false,
    isModifiable: true,
    minNbrOfChoices: 0,
    maxNbrOfChoices: 1,
    nbrOfChoicesWithspecialPrice: 0,
    nbrOfChoicesWithItemPrice: 0,
    urlImage: "",
    required: false,
    archive: false,
    specificOption: {
        withoutStep: false,
        nextButton: false,
        noButton: false,
        zeroPrice: false,
        isNext: true,
        isCheapest: false,
        isExpensive: false,
    },
    stepItems: {},
};
export function reducer(
    state: StepType,
    action: { type: string; payload: any }
): StepType {
    switch (action.type) {
        case ACTIONS.TITLE:
            return { ...state, title: action.payload };
        case ACTIONS.REFERENCE:
            return { ...state, reference: action.payload };
        case ACTIONS.REQUIRED:
            return { ...state, required: action.payload };
        case ACTIONS.IS_BASIC:
            return { ...state, isBasic: action.payload };
        case ACTIONS.IS_CREATIONAL:
            return { ...state, isCreational: action.payload };
        case ACTIONS.IS_MODIFIABLE:
            return { ...state, isModifiable: action.payload };

        case ACTIONS.DEFAULT_DISPLAY_NAME:
            return {
                ...state,
                displayName: {
                    ...state.displayName,
                    Default: {
                        ...state.displayName?.Default,
                        nameDefault: action.payload,
                    },
                },
            };
        case ACTIONS.DISPLAY_NAME:
            return {
                ...state,
                displayName: action.payload,
            };
        case ACTIONS.DEFAULT_IMAGE_URL:
            return {
                ...state,
                urlImage: action.payload,
            };
        case ACTIONS.STEP_ITEMS:
            return {
                ...state,
                stepItems: action.payload,
            };
        case ACTIONS.MIN_NUMBER_OF_CHOICES:
            return {
                ...state,
                minNbrOfChoices:
                    action.payload === "" ? 0 : parseInt(action.payload, 10),
            };
        case ACTIONS.MAX_NUMBER_OF_CHOICES:
            return {
                ...state,
                maxNbrOfChoices: parseInt(action.payload, 10),
            };
        case ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE:
            return {
                ...state,
                nbrOfChoicesWithItemPrice: parseInt(action.payload, 10),
            };
        case ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE:
            return {
                ...state,
                nbrOfChoicesWithspecialPrice: parseInt(action.payload, 10),
            };
        case ACTIONS.SPECIFIC_OPTION:
            return {
                ...state,
                specificOption: action.payload,
            };
        case ACTIONS.IS_NEXT_BUTTON:
            return {
                ...state,
                specificOption: {
                    ...state.specificOption,
                    nextButton: action.payload,
                },
            };
        case ACTIONS.IS_NO_BUTTON:
            return {
                ...state,
                specificOption: {
                    ...state.specificOption,
                    noButton: action.payload,
                },
            };
        case ACTIONS.IS_WIHOUT_STEP:
            return {
                ...state,
                specificOption: {
                    ...state.specificOption,
                    withoutStep: action.payload,
                },
            };
        case ACTIONS.IS_ZERO_PRICE:
            return {
                ...state,
                specificOption: {
                    ...state.specificOption,
                    zeroPrice: action.payload,
                },
            };

        case ACTIONS.EDIT:
            return action.payload;
        case ACTIONS.Clear:
            return initialStateStep;
        default:
            return state;
    }
}
