import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { DominantColor, ErrorToast, SuccessToast } from "@components/Common";
import { StyledButton, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedExchangeCard } from "@store/features/cardExchangeSlice";
import { SelectedExchangeCard } from "./SelectedExchangeCard";

type ListCardType = {
    isOpenList: boolean;
    setIsOpenList: React.Dispatch<React.SetStateAction<boolean>>;
    rowData: any;
};
type customColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function ExchangeCardModal({
    isOpenList,
    setIsOpenList,
    rowData,
}: ListCardType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const selectedExchangeCard = useSelector(
        (state: RootState) => state.cardExchangeSlice?.value?.Card
    );
    const arrayOfCustomColumns: customColumnProps[] = [];
    arrayOfCustomColumns.push({
        indexOFColumn: 7,
        columnName: "Action",
        customJsx: SelectedExchangeCard,
    });
    const toggleOpen = (): void => {
        setIsOpenList(!isOpenList);
    };
    const exchangeCarte = () => {
        const api = axios.create({
            baseURL: `${process.env.REACT_APP_ADB_API_URL}`,
        });
        try {
            api.put(
                `${schemaAdbFranchise}/cards/${rowData.selectedRow.original.iuud}/exchange/`,
                {
                    withItem: true,
                    withCategory: true,
                    title: `${selectedExchangeCard.designation}`,
                    iuudSelected: selectedExchangeCard.iuud,
                }
            )
                .then((): void => {
                    SuccessToast(t("Card updated successfully"));
                })
                .catch((): void => {
                    ErrorToast(t(`There is a mistake!`));
                });
        } catch {
            ErrorToast(t("There is a mistake!"));
        }
    };
    return (
        <Modal centered isOpen={isOpenList} style={{ minWidth: "max-content" }}>
            <ModalHeader
                toggle={() => {
                    toggleOpen();
                    dispatch(setSelectedExchangeCard(""));
                }}
            >
                <StyledH2 className="text-uppercase">LISTE DES CARTES</StyledH2>
            </ModalHeader>
            <ModalBody className="mx-5">
                <StyledLabel>
                    Toutes les informations de la carte de la boutique (Nom de
                    la boutique) seront écrasées. Cliquez sur "Récupérer" pour
                    sélectionner la carte que vous souhaitiez récupérer son
                    contenu
                </StyledLabel>
                <DynamicTable
                    url={`${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cards`}
                    canSort
                    actionColumn={() => null}
                    name="Card_table"
                    canExpand
                    canResize
                    showGlobalFilter
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    minHeight="calc(100vh - 500px )"
                    maxHeight="calc(100vh - 500px )"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        toggleOpen();
                        dispatch(setSelectedExchangeCard(""));
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={() => {
                        if (selectedExchangeCard === "") {
                            ErrorToast(t("Please select a card"));
                        } else {
                            exchangeCarte();
                            dispatch(setSelectedExchangeCard(""));
                            toggleOpen();
                        }
                    }}
                >
                    {t("Retrieve")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
