/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import {
    EyeCrossedIcon,
    EyeIcon,
    StyledTextInput,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import { WarningToast } from "@components/Common";
import customNoRowsOverlay from "./customNoRowsOverlay";
import StepItemVisibilityModal from "./StepItemVisibilityModal";
import MultipleStepEdit from "./MultipleStepEdit";

import "./Style.css";

type StepItemManagementTablePropsType = {
    isActionColumnDisplayed: boolean;
    setActionColumnDisplay: Function;
    InsertedItems: StepItemWithIuud[];
    setInsertedItems: Function;
    setSelectedItemsToEdit: React.Dispatch<React.SetStateAction<string[]>>;
    stepItemForm: StepItemType;
    dispatchStepItem: React.Dispatch<ReducerActionType>;
    itemToEditVisibility: visibilityCategoryType;
    setItemToEditVisibility: React.Dispatch<
        React.SetStateAction<visibilityCategoryType>
    >;
    stepForm: StepType;
};
export default function StepItemManagementTable({
    isActionColumnDisplayed,
    setActionColumnDisplay,
    InsertedItems,
    setInsertedItems,
    setSelectedItemsToEdit,
    stepItemForm,
    dispatchStepItem,
    itemToEditVisibility,
    setItemToEditVisibility,
    stepForm,
}: StepItemManagementTablePropsType): JSX.Element {
    const { t } = useTranslation();
    const isInputNumber = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const [isVisibilityModalOpen, setIsVisibilityModalOpen] =
        React.useState<boolean>(false);
    const [selectedItemVisibility, setSelectedItemVisibility] = React.useState<
        StepItemWithIuud | undefined
    >(undefined);

    const updatedWorkflowStep = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const columns: GridColDef[] = [
        {
            field: "refrence",
            headerName: t("Reference"),
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "center",
            width: 130,
            renderCell: ({ row }) => (
                <Tooltip title={row.refrence}>
                    <span className="table-cell-trucate">{row.refrence}</span>
                </Tooltip>
            ),
        },
        {
            field: "rank",
            headerName: t("Order"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            type: "number",
            renderCell: ({ row }) => {
                const [, setItemRank] = React.useState<number>(row.rank);

                return (
                    <StyledTextInput
                        className="form-control mt-3"
                        type="number"
                        id={`${row.itemIuud}+${"rank - input"}`}
                        name={`${row.itemIuud}+${"rank - input"}`}
                        min={1}
                        max={InsertedItems.length}
                        onKeyPress={isInputNumber}
                        placeholder={t("Enter")}
                        value={row.rank.toString()}
                        onPaste={(e: any) => {
                            e.preventDefault();
                            const pastedData = Math.abs(
                                Number(e.clipboardData.getData("text"))
                            );

                            const split = pastedData.toString().split(".")[0];

                            setItemRank(Number((row.rank = split)));
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setItemRank(Number(e.target.value));
                            row.rank = Number(e.target.value);
                            setInsertedItems(() => {
                                const InsertedItemsWithNewRank =
                                    InsertedItems.map(
                                        (stepItem: StepItemWithIuud) => {
                                            if (
                                                stepItem.itemIuud ===
                                                row.itemIuud
                                            ) {
                                                stepItem.rank = row.rank;
                                            }
                                            return stepItem;
                                        }
                                    );

                                return [...InsertedItemsWithNewRank];
                            });
                        }}
                    />
                );
            },
        },
        {
            field: "title",
            headerName: t("Designation"),
            type: "string",
            headerAlign: "left",
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            width: 140,
            renderCell: ({ row }) => (
                <Tooltip title={row.title}>
                    <span className="table-cell-trucate">{row.title}</span>
                </Tooltip>
            ),
        },
        {
            field: "parentCategoryTitle",
            headerName: t("Category"),
            type: "string",
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            width: 130,
            renderCell: ({ row }) => (
                <Tooltip title={row.parentCategoryTitle}>
                    <span className="table-cell-trucate">
                        {row.parentCategoryTitle}
                    </span>
                </Tooltip>
            ),
        },

        {
            field: "maxNbrOfChoicesByItems",
            headerName: t("Max num of choices per item"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setMaxNbrOfChoicesByItems] = React.useState<number>(
                //     row.maxNbrOfChoicesByItems
                // );

                return (
                    <StyledTextInput
                        className="form-control mt-3"
                        id={`${
                            row.itemIuud
                        }+${"maxNbrOfChoicesByItems - input"}`}
                        name={`${
                            row.itemIuud
                        }+${"maxNbrOfChoicesByItems - input"}`}
                        type="number"
                        autoComplete="off"
                        min={0}
                        onKeyPress={isInputNumber}
                        placeholder={t("Enter")}
                        value={row.maxNbrOfChoicesByItems}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );
                        //     const split = pastedData.toString().split(".")[0];
                        //     // setMaxNbrOfChoicesByItems(
                        //     //     Number((row.maxNbrOfChoicesByItems = split))
                        //     // );
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === "") {
                                row.maxNbrOfChoicesByItems = null;
                            } else {
                                row.maxNbrOfChoicesByItems = Number(
                                    e.target.value
                                );
                            }
                            // setMaxNbrOfChoicesByItems(Number(e.target.value));
                        }}
                    />
                );
            },
        },
        // {
        //     field: "minNbrOfChoicesByItems",
        //     headerName: t("Min num of choices per item"),
        //     flex: 1.0,
        //     sortable: true,
        //     disableColumnMenu: true,
        //     headerAlign: "left",
        //     renderCell: ({ row }) => {
        //         return (
        //             <StyledTextInput
        //                 className="mt-3"
        //                 id="step-modal-min-nbr-of-choices-by-items"
        //                 name="step-modal-min-nbr-of-choices-by-items"
        //                 type="number"
        //                 onKeyPress={isInputNumber}
        //                 min={0}
        //                 placeholder={t("Enter")}
        //                 value={row.minNbrOfChoicesByItems}
        //                 onChange={(e: any) => {
        //                     row.minNbrOfChoicesByItems = Number(e.target.value);
        //                 }}
        //             />
        //         );
        //     },
        // },
        {
            field: "nbrOfChoicesWithspecialPrice",
            headerName: t("Nbr choice group 1 per item"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setNbrOfChoicesWithSpecialPrice] =
                //     React.useState<number>(row.nbrOfChoicesWithspecialPrice);

                return (
                    <StyledTextInput
                        className="form-control mt-3"
                        id={`${
                            row.itemIuud
                        }+${"nbrOfChoicesWithspecialPrice - input"}`}
                        name={`${
                            row.itemIuud
                        }+${"nbrOfChoicesWithspecialPrice - input"}`}
                        autoComplete="off"
                        type="number"
                        placeholder={t("Enter")}
                        onKeyPress={isInputNumber}
                        min={0}
                        value={row.nbrOfChoicesWithspecialPrice}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );

                        //     const split = pastedData.toString().split(".")[0];

                        //     // setNbrOfChoicesWithSpecialPrice(
                        //     //     Number(
                        //     //         (row.nbrOfChoicesWithspecialPrice = split)
                        //     //     )
                        //     // );
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === "") {
                                row.nbrOfChoicesWithspecialPrice = null;
                            } else {
                                row.nbrOfChoicesWithspecialPrice = Number(
                                    e.target.value
                                );
                            }
                            // setNbrOfChoicesWithSpecialPrice(
                            //     Number(e.target.value)
                            // );
                        }}
                    />
                );
            },
        },
        {
            field: "specialPrice",
            headerName: t("Price group 1"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setSpecialPrice] = React.useState<number>(
                //     row.specialPrice
                // );
                return (
                    <StyledTextInput
                        className="mt-3"
                        id={`${row.itemIuud}+${"specialPrice-input"}`}
                        name={`${row.itemIuud}+${"specialPrice-input"}`}
                        type="number"
                        autoComplete="off"
                        placeholder={t("Enter")}
                        min={0}
                        value={row.specialPrice}
                        onKeyPress={(event: any) => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (/\+|-/.test(keyValue)) event.preventDefault();
                        }}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );
                        //     // setSpecialPrice(
                        //     //     Number((row.specialPrice = pastedData))
                        //     // );
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            row.specialPrice = e.target.value;
                            // setSpecialPrice(Number(e.target.value));
                        }}
                    />
                );
            },
        },

        {
            field: "nbrOfChoicesWithItemPriceInStep",
            headerName: t("Nbr choice group 2 per item"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setNbrOfChoicesWithItemPriceInStep] =
                //     React.useState<number>(row.nbrOfChoicesWithItemPriceInStep);
                return (
                    <StyledTextInput
                        className="form-control mt-3"
                        id={`${
                            row.itemIuud
                        }+${"nbrOfChoicesWithItemPrice - input"}`}
                        name={`${
                            row.itemIuud
                        }+${"nbrOfChoicesWithItemPrice - input"}`}
                        autoComplete="off"
                        type="number"
                        onKeyPress={isInputNumber}
                        placeholder={t("Enter")}
                        min={0}
                        value={row.nbrOfChoicesWithItemPriceInStep}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );

                        //     const split = pastedData.toString().split(".")[0];

                        //     // setNbrOfChoicesWithItemPriceInStep(
                        //     //     Number(
                        //     //         (row.nbrOfChoicesWithItemPriceInStep =
                        //     //             split)
                        //     //     )
                        //     // );
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === "") {
                                row.nbrOfChoicesWithItemPriceInStep = null;
                            } else {
                                row.nbrOfChoicesWithItemPriceInStep = Number(
                                    e.target.value
                                );
                            }
                            // setNbrOfChoicesWithItemPriceInStep(
                            //     Number(e.target.value)
                            // );
                        }}
                    />
                );
            },
        },

        {
            field: "priceOfItemInStep",
            headerName: t("Price group 2"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setPriceOfItemInStep] = React.useState<number>(
                //     row.priceOfItemInStep
                // );

                return (
                    <StyledTextInput
                        className="mt-3"
                        id={`${row.itemIuud}+${"priceOfItemInStep - input"}`}
                        name={`${row.itemIuud}+${"priceOfItemInStep - input"}`}
                        autoComplete="off"
                        type="number"
                        min={0}
                        placeholder={t("Enter")}
                        onKeyPress={(event: any) => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (/\+|-/.test(keyValue)) event.preventDefault();
                        }}
                        value={row.priceOfItemInStep}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );

                        //     // setPriceOfItemInStep(
                        //     //     (row.priceOfItemInStep = pastedData)
                        //     // );
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            row.priceOfItemInStep = e.target.value;
                            // setPriceOfItemInStep(Number(e.target.value));
                        }}
                    />
                );
            },
        },
        {
            field: "fidelity",
            headerName: t("Loyalty points"),
            flex: 1.0,
            sortable: true,
            disableColumnMenu: true,
            headerAlign: "left",
            renderCell: ({ row }) => {
                // const [, setFidelity] = React.useState<number>(row.fidelity);
                return (
                    <StyledTextInput
                        className="mt-3"
                        id={`${row.itemIuud}+${"step-modal-fidelity - input"}`}
                        name={`${
                            row.itemIuud
                        }+${"step-modal-fidelity - input"}`}
                        type="number"
                        placeholder={t("Enter")}
                        autoComplete="off"
                        min={0}
                        onKeyPress={isInputNumber}
                        value={row.fidelity}
                        // onPaste={(e: any) => {
                        //     e.preventDefault();
                        //     const pastedData = Math.abs(
                        //         Number(e.clipboardData.getData("text"))
                        //     );
                        //     const split = pastedData.toString().split(".")[0];
                        //     // setFidelity(Number((row.fidelity = split)));
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            row.fidelity = Number(e.target.value);
                            // setFidelity(Number(e.target.value));
                        }}
                    />
                );
            },
        },
        {
            field: "color",
            headerName: t("Color"),
            flex: 1.0,
            sortable: false,
            disableColumnMenu: true,
            editable: false,
            headerAlign: "left",
            renderCell: ({ row }) => {
                return (
                    <StyledTextInput
                        className="mt-3 "
                        id="modal-color"
                        name="modal-color"
                        placeholder={t("Enter")}
                        type="color"
                        style={{ width: "50px" }}
                        value={row.color}
                        onChange={(e: any) => {
                            row.color = e.target.value;
                        }}
                    />
                );
            },
        },
        {
            field: "imageUrl",
            headerName: t("Picture"),
            sortable: false,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img
                        src={
                            params.row.imageUrl instanceof Blob
                                ? URL.createObjectURL(params.row.imageUrl)
                                : params.row.imageUrl
                        }
                        alt="default"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "contain" }}
                    />
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",

            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.visibilityInfo?.isVisible ? (
                            <EyeIcon
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                onClick={() => {
                                    setIsVisibilityModalOpen(
                                        !isVisibilityModalOpen
                                    );
                                    setSelectedItemVisibility(params.row);
                                }}
                            />
                        ) : (
                            <EyeCrossedIcon
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                fill="red"
                                onClick={() => {
                                    setIsVisibilityModalOpen(
                                        !isVisibilityModalOpen
                                    );
                                    setSelectedItemVisibility(params.row);
                                }}
                            />
                        )}
                        {isVisibilityModalOpen &&
                            selectedItemVisibility !== undefined &&
                            Object.keys(selectedItemVisibility).length > 0 && (
                                <StepItemVisibilityModal
                                    isOpen={isVisibilityModalOpen}
                                    setIsOpen={setIsVisibilityModalOpen}
                                    ItemVisibility={selectedItemVisibility}
                                    setItemVisibility={setInsertedItems}
                                />
                            )}

                        <TrashIcon
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="red"
                            onClick={() => {
                                if (
                                    (updatedWorkflowStep.uuid ||
                                        stepForm.required) &&
                                    InsertedItems.length === 1
                                ) {
                                    WarningToast(
                                        `${t(
                                            "It is impossible to delete all items"
                                        )}, 
                                        ${t(
                                            "The step must contain at least one product."
                                        )}`
                                    );
                                } else {
                                    setInsertedItems(
                                        (prevState: StepItemWithIuud[]) => {
                                            const filtredStepItems =
                                                prevState.filter((stepItem) => {
                                                    return (
                                                        stepItem.itemIuud !==
                                                        params.id
                                                    );
                                                });
                                            const ordredStepItems =
                                                filtredStepItems.map((item) => {
                                                    if (
                                                        item.rank >
                                                        params.row.rank
                                                    ) {
                                                        item.rank -= 1;
                                                    }
                                                    // item.rank = index + 1;
                                                    return item;
                                                });

                                            return ordredStepItems;
                                        }
                                    );
                                }
                            }}
                        />
                    </>
                );
            },
        },
    ];
    const filterByReference = (
        tableRow: any,
        selectedRowID: GridSelectionModel
    ) => {
        return tableRow
            .filter((row: any): any =>
                selectedRowID.find((element) => element === row.itemIuud)
            )
            .map((item: any) => item.itemIuud);
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: isActionColumnDisplayed
                    ? "2fr 1fr "
                    : "auto",
                gridColumnGap: "4px",
            }}
        >
            <DataGrid
                rows={InsertedItems}
                columns={columns}
                getRowId={(row) => {
                    return row.itemIuud;
                }}
                // pageSize={5}
                components={{
                    NoRowsOverlay: customNoRowsOverlay,
                }}
                hideFooter
                checkboxSelection
                disableSelectionOnClick
                rowHeight={65}
                onSelectionModelChange={(selectedRowID) => {
                    const selectedRows = filterByReference(
                        InsertedItems,
                        selectedRowID
                    );
                    setSelectedItemsToEdit(selectedRows);
                }}
                sx={{
                    minHeight: "calc( 60vh)",
                    width: "100%",
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeaderTitle ": {
                        fontFamily: "Segoe UI",
                        fontWeight: "600 !important",
                        fontSize: "16px",
                        whiteSpace: " pre-wrap !important",
                        lineHeight: "1.1 !important",
                    },
                    " & .MuiDataGrid-selectedRowCount": {
                        display: "none",
                    },
                    " & .MuiDataGrid-cell--textLeft": {
                        justifyContent: "left ",
                        alignItems: "left",
                    },
                    " & .MuiDataGrid-cell--textRight": {
                        justifyContent: "left ",
                        alignItems: "left",
                    },
                    " & .MuiDataGrid-cell": {
                        whiteSpace: " pre-wrap !important",
                        lineHeight: "1.1 !important",
                    },
                }}
            />

            {isActionColumnDisplayed ? (
                <MultipleStepEdit
                    setActionColumnDisplay={setActionColumnDisplay}
                    stepItemForm={stepItemForm}
                    dispatchStepItem={dispatchStepItem}
                    itemToEditVisibility={itemToEditVisibility}
                    setItemToEditVisibility={setItemToEditVisibility}
                />
            ) : null}
        </div>
    );
}
