import React from "react";

function NumberOfChoices(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.153"
            height="19.358"
            viewBox="0 0 18.153 19.358"
            {...props}
        >
            <g id="pointer" transform="translate(0)">
                <g
                    id="Groupe_31805"
                    data-name="Groupe 31805"
                    transform="translate(0 0)"
                >
                    <g
                        id="Groupe_31804"
                        data-name="Groupe 31804"
                        transform="translate(0)"
                    >
                        <path
                            id="Tracé_22574"
                            data-name="Tracé 22574"
                            d="M105.855,78.833l.58-1.319c.034-.078.072-.156.113-.23a2.017,2.017,0,0,1,2.575-.877l.223.1.286-.65h0l.21-.479a1.663,1.663,0,0,1,2.2-.851l.741.321a1.388,1.388,0,0,1,.5-.445,1.638,1.638,0,0,1,1.451.053l.531.233.687-1.562a1.994,1.994,0,0,1,1.424-1.2,1.8,1.8,0,0,1,1.6.445,2,2,0,0,1,.456,2.271l-2.6,5.955a3.065,3.065,0,0,1,.68-.059,2.369,2.369,0,0,1,1.513.532.386.386,0,0,1,.067.055,1.738,1.738,0,0,1,.522,1.319,2.491,2.491,0,0,1-1,1.731l-.01.008-5.348,4.209a.386.386,0,0,1-.394.05l-7.661-3.368a.386.386,0,0,1-.23-.329l-.042-.668A11.337,11.337,0,0,1,105.855,78.833Zm-.163,5.2.027.433,7.234,3.18,5.161-4.062a1.781,1.781,0,0,0,.724-1.176.95.95,0,0,0-.252-.684.391.391,0,0,1-.037-.034,1.948,1.948,0,0,0-2.214-.067L115.007,82.9a.386.386,0,0,1-.534-.556l1.292-1.241,2.956-6.766a1.222,1.222,0,0,0-.282-1.4,1.045,1.045,0,0,0-.917-.246,1.218,1.218,0,0,0-.869.751l-2.239,5.093a.386.386,0,0,1-.706-.31l1.242-2.825-.531-.233a.928.928,0,0,0-.786-.074.794.794,0,0,0-.334.407l-1.32,3a.386.386,0,1,1-.706-.31l1.164-2.649-.706-.306,0,0a.892.892,0,0,0-1.179.456l-1.569,3.57a.386.386,0,0,1-.706-.31l.763-1.735-.221-.1a1.239,1.239,0,0,0-1.59.54c-.03.055-.058.112-.084.17l-.58,1.319A10.564,10.564,0,0,0,105.692,84.032Z"
                            transform="translate(-104.901 -69.119)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22575"
                            data-name="Tracé 22575"
                            d="M411.251,383.263l.38.167a.386.386,0,1,1-.31.706l-.38-.167a.386.386,0,1,1,.31-.706Z"
                            transform="translate(-408.944 -368.452)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22576"
                            data-name="Tracé 22576"
                            d="M275.972,400.939l4.063,1.782a.386.386,0,1,1-.31.706l-4.063-1.782a.386.386,0,1,1,.31-.706Z"
                            transform="translate(-272.131 -385.447)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22577"
                            data-name="Tracé 22577"
                            d="M120.722,1.743a.386.386,0,1,1-.755-.156l.264-1.279a.386.386,0,0,1,.755.156Z"
                            transform="translate(-106.864 0)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22578"
                            data-name="Tracé 22578"
                            d="M15.683,28.122a.386.386,0,0,1,.125-.531l.266-.165a.386.386,0,1,1,.406.656l-.266.165a.385.385,0,0,1-.531-.125Z"
                            transform="translate(1.491 -26.313)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22579"
                            data-name="Tracé 22579"
                            d="M57.368,54.6l-.823.541a.386.386,0,1,1-.424-.644l.823-.541a.386.386,0,0,1,.424.644Z"
                            transform="translate(-40.944 -51.811)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22580"
                            data-name="Tracé 22580"
                            d="M33.66,127.82a.386.386,0,0,1,.456-.3l1.279.264a.386.386,0,0,1-.078.763.39.39,0,0,1-.078-.008l-1.279-.264A.386.386,0,0,1,33.66,127.82Z"
                            transform="translate(-18.245 -122.595)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22581"
                            data-name="Tracé 22581"
                            d="M77.839,185.4a.386.386,0,0,1,.534.11l.718,1.091a.386.386,0,1,1-.644.424l-.718-1.091A.386.386,0,0,1,77.839,185.4Z"
                            transform="translate(-63.393 -178.188)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22582"
                            data-name="Tracé 22582"
                            d="M222.763,89.228a.386.386,0,0,1-.377.308.39.39,0,0,1-.078-.008l-.853-.176a.386.386,0,1,1,.156-.755l.853.176A.386.386,0,0,1,222.763,89.228Z"
                            transform="translate(-212.543 -85.172)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22583"
                            data-name="Tracé 22583"
                            d="M284,79.711a.389.389,0,0,1-.078-.008l-.267-.055a.386.386,0,0,1,.156-.755l.267.055a.386.386,0,0,1-.078.763Z"
                            transform="translate(-276.557 -75.843)"
                            fill="black"
                        />
                        <path
                            id="Tracé_22584"
                            data-name="Tracé 22584"
                            d="M187.716,17.124a.386.386,0,0,1-.534-.11l-.718-1.091a.386.386,0,0,1,.644-.424l.718,1.091A.386.386,0,0,1,187.716,17.124Z"
                            transform="translate(-176.322 -14.733)"
                            fill="black"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default NumberOfChoices;
