import React from "react";

import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { StyledLabel } from "@aureskonnect/react-ui";

import { synchronizeVisibilityWithSettings } from "@helpers/visibility";
import { ACTIONS } from "@helpers/.";
import { RootState } from "@store/.";
import SalesModeVisibility from "./SalesModeVisibility";
import SellingSupportVisibility from "./SellingSupportVisibility";

type VisibilityPropsType = {
    dispatch: Function;
    visibilityInfo: visibilityCategoryType;
};

export default function Visibility({
    dispatch,
    visibilityInfo,
}: VisibilityPropsType) {
    const { t } = useTranslation();
    const visibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );

    const updatedVisibility: visibilityCategoryType = React.useMemo(
        () => synchronizeVisibilityWithSettings(visibility, visibilityInfo),
        [visibilityInfo, visibility]
    );

    function updateGlobalVisibility() {
        dispatch({
            type: ACTIONS.IS_VISIBLE,
            payload: !visibilityInfo.isVisible,
        });
    }

    return (
        <div className="py-2" id="visibility">
            <div className="d-flex bd-highlight ">
                <div className=" w-100 bd-highlight">
                    <StyledLabel> {t("Visibility")}</StyledLabel>
                </div>
                <ReactSwitch
                    className=""
                    activeBoxShadow="0px 0px 1px 10px #000000"
                    boxShadow="0px 1px 5px 0px #000000"
                    handleDiameter={20}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    onChange={() => updateGlobalVisibility()}
                    checked={visibilityInfo.isVisible}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={45}
                    height={15}
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
            </div>
            <Card>
                <CardBody>
                    {Object.keys(updatedVisibility.default).map(
                        (salesMode): JSX.Element => (
                            <div
                                className="text-danger  row  mb-2 align-items-center"
                                key={salesMode}
                            >
                                <div
                                    className="d-flex    col-4  "
                                    style={{ color: "#212529 " }}
                                >
                                    <div className="w-100 bd-highlight px-1 ">
                                        <h6>{salesMode}</h6>
                                    </div>
                                    <div className=" flex-shrink-1 bd-highlight ml-2 ">
                                        <SalesModeVisibility
                                            defaultVisibility={
                                                updatedVisibility
                                            }
                                            dispatch={dispatch}
                                            salesMode={salesMode}
                                        />
                                    </div>
                                </div>
                                <div
                                    className=" col-7 "
                                    style={{
                                        background:
                                            "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                                        border: "1px solid rgb(230, 230, 230)",
                                        margin: "1em",
                                        color: "#212529 ",
                                    }}
                                >
                                    <div className=" row  d-flex justify-content-between ">
                                        {updatedVisibility.default[
                                            salesMode
                                        ].sellingSupport.map(
                                            (sellingSupport): JSX.Element => (
                                                <div
                                                    className="d-flex justify-content-between bd-highlight"
                                                    key={
                                                        sellingSupport.sellingSupport
                                                    }
                                                >
                                                    <div
                                                        className="p-2  bd-highlight"
                                                        style={{
                                                            width: "60px",
                                                        }}
                                                    >
                                                        {
                                                            sellingSupport.sellingSupport
                                                        }
                                                    </div>
                                                    <div className="p-2 flex-shrink-1 bd-highlightml-2">
                                                        <SellingSupportVisibility
                                                            sellingSupportVisibility={
                                                                sellingSupport
                                                            }
                                                            salesMode={
                                                                salesMode
                                                            }
                                                            defaultVisibility={
                                                                updatedVisibility
                                                            }
                                                            dispatch={dispatch}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </CardBody>
            </Card>
        </div>
    );
}
