/* eslint-disable no-lone-blocks */
import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/.";
import { DominantColor } from "@components/Common";
import { setSelectedExchangeCard } from "@store/features/cardExchangeSlice";

export function SelectedExchangeCard(e: any) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const dispatch = useDispatch();

    const selectedExchangeCard = useSelector(
        (state: RootState) => state.cardExchangeSlice.value?.Card
    );
    const IuudExchangeCard = useSelector(
        (state: RootState) => state.cardExchangeSlice.value?.Iuud
    );
    return (
        <div>
            {e.selectedRow.iuud === selectedExchangeCard?.iuud ? null : (
                <div>
                    {e.selectedRow.CardMaster || e.selectedRow.Cardshop ? (
                        <StyledButton
                            outline
                            rounded
                            disabled={e.selectedRow.iuud === IuudExchangeCard}
                            variant={dominantColor}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                                dispatch(
                                    setSelectedExchangeCard({
                                        Card: e.selectedRow,
                                        Iuud: IuudExchangeCard,
                                    })
                                );
                            }}
                        >
                            {t("Retrieve")}
                        </StyledButton>
                    ) : null}
                </div>
            )}
        </div>
    );
}
