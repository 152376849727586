import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import {
    StyledLabel,
    StyledSearchInput,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import LoadingDataAnimation from "@components/Common/LoadingDataAnimation";
import useParentsCategory from "@hooks/useParentsCategory";
import { DynamicTableWrapperContext, ErrorToast } from "@components/Common";
import {
    convertStepItemsObjectToArray,
    filterNameWithAlphabeticRank,
} from "@helpers/general";

import FilterStepItem from "./FilterStepItem";
import customNoRowsOverlay from "./customNoRowsOverlay";

type AddItemsStepModalBodyPropsType = {
    setSelectedItems: React.Dispatch<React.SetStateAction<StepItemWithIuud[]>>;
    selectedDataRow: StepItemWithIuud[];
    stepDesignation: string;
    initialData: any;
};
export default function AddItemsStepModalBody({
    setSelectedItems,
    selectedDataRow,
    stepDesignation,
    initialData,
}: AddItemsStepModalBodyPropsType) {
    const { t } = useTranslation();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const allParentsCategory = useParentsCategory(
        cardIuud,
        dynamicTableWrapperContext?.rowData?.iuud
    );
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listOptions, setListOptions] = React.useState<
        Partial<StepItemWithIuud>[]
    >([]);

    const [designationFilter, setDesignationFilter] = React.useState("");
    const [categoryFilter, setCategoryFilter] = React.useState("");
    const [stepFilter, setStepFilter] = React.useState("");
    const [selectionModel, setSelectionModel] =
        React.useState<GridSelectionModel>([]);
    const AddStepItemsColumns: GridColDef[] = [
        {
            field: "refrence",
            headerName: t("Reference"),
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            width: 130,
        },
        {
            field: "title",
            headerName: t("Item"),
            type: "string",
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            width: 140,
            renderCell: ({ row }) => (
                <Tooltip title={row.title}>
                    <span className="table-cell-trucate gestionDeArticle_clz">
                        {row.title}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "parentCategoryTitle",
            headerName: t("Categories"),
            type: "string",
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            flex: 1,
            renderCell: ({ row }) => (
                <Tooltip title={row.parentCategoryTitle}>
                    <span className="table-cell-trucate gestionDeArticle_clz">
                        {row.parentCategoryTitle}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "price",
            headerName: t("Price"),
            type: "number",
            sortable: true,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "imageUrl",
            headerName: "Image",
            type: "string",
            sortable: false,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img
                        src={params.row.imageUrl}
                        alt="default"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "contain" }}
                    />
                );
            },
        },
        {
            field: "color",
            headerName: t("Color"),
            type: "string",
            sortable: false,
            filterable: false,
            hide: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "left",
            flex: 1,
            renderCell: (e: any) => {
                return (
                    <StyledTextInput
                        className="mt-3"
                        id="step-modal-color"
                        name="step-moda-color"
                        type="color"
                        style={{ width: "50px", pointerEvents: "none" }}
                        value={e?.value}
                    />
                );
            },
        },
    ];
    let filteredSelectedDataRow: Partial<StepItemWithIuud>[] = [];

    const handleOnSearchItems = () => {
        // eslint-disable-next-line
        return listOptions.filter((item) => {
            if (
                item.parentCategoryTitle?.includes(categoryFilter) &&
                item.title
                    ?.toLowerCase()
                    .includes(designationFilter.toLowerCase())
            ) {
                return item;
            }
        });
    };

    if (
        designationFilter === "" &&
        categoryFilter === "" &&
        stepFilter === ""
    ) {
        filteredSelectedDataRow.push(...listOptions);
    } else {
        filteredSelectedDataRow = handleOnSearchItems();
    }

    const selectedDataRowItemIuud = selectedDataRow.map(
        (item) => item.itemIuud
    );
    const filterByReference = (
        tableRow: any,
        selectedRowID: GridSelectionModel
    ) => {
        return tableRow.filter((row: any): any =>
            selectedRowID.find((element) => element === row.itemIuud)
        );
    };

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/items`
                )
                .then((response: any): void => {
                    const selectedDataRowIuuds = selectedDataRow.map(
                        (row) => row.itemIuud
                    );

                    setListOptions(
                        response.data.filter(
                            (item: StepItemWithIuud) =>
                                !selectedDataRowIuuds.includes(item.itemIuud)
                        )
                    );
                })

                .finally(() => {
                    setIsLoading(false);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    }, [cardIuud, schemaAdbFranchise, selectedDataRow, t]);

    const handleOnSearchSteps = React.useCallback(() => {
        if (stepFilter === "") {
            fetchData();
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/steps/${stepFilter}/step-items`
                )
                .then((stepItems: any) => {
                    convertStepItemsObjectToArray(stepItems.data).then(
                        (data) => {
                            const newData = data.filter(
                                (item: StepItemWithIuud) => {
                                    return !selectedDataRowItemIuud.includes(
                                        item.itemIuud
                                    );
                                }
                            );

                            setListOptions(newData);
                        }
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardIuud, fetchData, schemaAdbFranchise, stepFilter]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    React.useEffect(() => {
        handleOnSearchSteps();
    }, [stepFilter, handleOnSearchSteps]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <React.Fragment>
            <div className="d-flex justify-content-between mb-3">
                <div style={{ width: "35%" }}>
                    <StyledLabel>{t("Designation")}</StyledLabel>
                    <StyledSearchInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDesignationFilter(e.target.value);
                        }}
                        placeholder={t("Rechercher")}
                        style={{ border: "1px solid ##C6C6C6" }}
                    />
                </div>
                <div style={{ width: "30%", zIndex: "10" }}>
                    <StyledLabel>{t("Category")}</StyledLabel>

                    <StyledSelectInput
                        name="steps"
                        options={allParentsCategory.sort(
                            filterNameWithAlphabeticRank
                        )}
                        placeholder={t("Select")}
                        isClearable
                        onChange={(selectedCategory: any) => {
                            setCategoryFilter(selectedCategory?.label ?? "");
                        }}
                    />
                </div>
                <div style={{ width: "30%", zIndex: "10" }}>
                    <FilterStepItem
                        stepDesignation={stepDesignation}
                        setStepIuud={setStepFilter}
                        stepList={initialData.othersData.stepsList}
                    />
                </div>
            </div>

            <DataGrid
                rows={filteredSelectedDataRow}
                columns={AddStepItemsColumns}
                getRowId={(row) => row.itemIuud}
                components={{
                    NoRowsOverlay: customNoRowsOverlay,
                }}
                hideFooter
                checkboxSelection
                onCellClick={(cell) => {
                    if (selectionModel.includes(cell.id)) {
                        const updatedSelectionModel = selectionModel.filter(
                            (selectedCell) => selectedCell !== cell.id
                        );
                        setSelectionModel(updatedSelectionModel);
                        const selectedRows = filterByReference(
                            filteredSelectedDataRow,
                            updatedSelectionModel
                        );
                        setSelectedItems(selectedRows);
                    } else {
                        const updatedSelectionModel = [
                            ...selectionModel,
                            cell.id,
                        ];
                        setSelectionModel(updatedSelectionModel);
                        const selectedRows = filterByReference(
                            filteredSelectedDataRow,
                            updatedSelectionModel
                        );

                        setSelectedItems((previousSelectedItems) => {
                            const newSelectedItems = selectedRows.filter(
                                (selectedItem: any) =>
                                    previousSelectedItems.findIndex(
                                        (pevItem: any) =>
                                            selectedItem.itemIuud ===
                                            pevItem.itemIuud
                                    ) === -1
                            );

                            return [
                                ...previousSelectedItems,
                                ...newSelectedItems,
                            ];
                        });
                    }
                }}
                selectionModel={selectionModel}
                sx={{
                    boxShadow: 2,

                    minHeight: "calc( 57vh)",

                    "& .MuiDataGrid-columnHeaderTitle ": {
                        fontFamily: "Segoe UI",
                        fontWeight: "600 !important",
                        fontSize: "16px",
                    },
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                    },

                    " & .MuiDataGrid-cell--textLeft": {
                        justifyContent: "left ",
                        alignItems: "left",
                    },
                    " & .MuiDataGrid-cell--textRight": {
                        justifyContent: "left ",
                        alignItems: "left",
                    },
                    " & .MuiDataGrid-selectedRowCount": {
                        display: "none",
                    },
                }}
            />
        </React.Fragment>
    );
}
