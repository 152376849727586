/* eslint-disable no-restricted-globals */
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledSearchInput,
} from "@aureskonnect/react-ui";

import {
    DominantColor,
    RequirementToast,
    WarningToast,
} from "@components/Common/";
import { convertStepItemsObjectToArray } from "@helpers/general";
import { ACTIONS } from "@helpers/actions";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import StepItemManagementTable from "./StepItemManagementTable";
import AddItemsStepModal from "./AddItemsStepModal";
import { initialStateStepItem, reducer } from "./reducer";

type StepItemManagementModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    stepForm: StepType;
    dispatchStep: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    initialData?: any;
};

export default function StepItemManagementModal({
    isOpen,
    setIsOpen,
    stepForm,
    dispatchStep: dispatch,
    initialData,
}: StepItemManagementModalPropsType): JSX.Element {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");

    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    const [stepItemForm, dispatchStepItem] = React.useReducer(
        reducer,
        initialStateStepItem
    );

    const [selectedDataRow, setSelectedDataRow] = React.useState<
        StepItemWithIuud[]
    >([]);
    const [selectedItemsToEdit, setSelectedItemsToEdit] = React.useState<
        string[]
    >([]);
    const [itemToEditVisibility, setItemToEditVisibility] =
        React.useState<visibilityCategoryType>(stepItemForm.visibilityInfo);
    const [isAddItemsStepModalOpen, setIsAddItemsStepModalOpen] =
        React.useState(false);
    const [isActionColumnDisplayed, setActionColumnDisplay] =
        React.useState(false);

    const [searchedItemDesignation, setsearchedItemDesignation] =
        React.useState("");

    let filteredIems = [];

    if (searchedItemDesignation !== "") {
        filteredIems = selectedDataRow.filter((row) =>
            row.title?.includes(searchedItemDesignation)
        );
    } else {
        filteredIems = [...selectedDataRow];
    }

    const handleOnSearchItems = (e: React.ChangeEvent<HTMLInputElement>) => {
        setsearchedItemDesignation(e.target.value);
    };

    const toggleOpen = (): void => {
        setIsOpen(false);
        setActionColumnDisplay(false);
        setItemToEditVisibility({
            isVisible: true,
            basicCompositionVisibility: true,
            default: {},
        });
        dispatchStepItem({
            type: ACTIONS.ClEAR,
            payload: {},
        });

        convertStepItemsObjectToArray(stepForm.stepItems).then((data) =>
            setSelectedDataRow(data)
        );
    };
    const checkVisibility = () => {
        let count = 0;
        selectedDataRow.length > 0 &&
            selectedDataRow?.forEach((element: any) => {
                const isAllVisibility = Object.values(
                    element.visibilityInfo.default
                ).every(
                    (item: any) =>
                        item.visibility &&
                        item.sellingSupport.every(
                            (support: any) => support.visibility
                        )
                );
                if (isAllVisibility && element.visibilityInfo.isVisible) {
                    count += 1;
                }
            });
        if (count > 0) {
            return true;
        }
        return false;
    };

    const submitStepItems = () => {
        const stepItems: Record<string, StepItemType> = {};
        const ranksTable: any[] = [];
        if (stepForm.required) {
            const isAllVisibility = checkVisibility();
            if (!isAllVisibility) {
                RequirementToast(
                    t(
                        "In a mandatory step,i he step must contain at least one visible item"
                    )
                );
                return;
            }
        }

        const nbrOfChoices = selectedDataRow.filter((stepItem) => {
            return (
                Number(stepItem.maxNbrOfChoicesByItems) <
                Number(stepItem.minNbrOfChoicesByItems)
            );
        });

        const stepItemMaxRank = selectedDataRow.filter((stepItem) => {
            return stepItem.rank > selectedDataRow.length;
        });
        selectedDataRow.filter((stepItem: any) =>
            ranksTable.push(stepItem.rank)
        );
        const rankOccurency = new Set(ranksTable);
        if (nbrOfChoices.length > 0) {
            RequirementToast(
                t(
                    "The maximum number of choices must be greater than the minimum number of choices"
                )
            );
        } else if (stepItemMaxRank.length > 0) {
            WarningToast(t("You have exceeded the max order"));
        } else if (selectedDataRow.length !== rankOccurency.size) {
            WarningToast(
                t("It is impossible to have two articles with the same order")
            );
        } else if (isActionColumnDisplayed) {
            handleOnApplyModification();
            setIsOpen(!isOpen);
        } else {
            selectedDataRow.forEach((stepItem) => {
                stepItems[stepItem.itemIuud] = {
                    maxNbrOfChoicesByItems:
                        stepItem.maxNbrOfChoicesByItems === undefined
                            ? null
                            : stepItem.maxNbrOfChoicesByItems,
                    minNbrOfChoicesByItems: stepItem.minNbrOfChoicesByItems,
                    nbrOfChoicesWithspecialPrice:
                        stepItem.nbrOfChoicesWithspecialPrice === undefined
                            ? null
                            : stepItem.nbrOfChoicesWithspecialPrice,
                    nbrOfChoicesWithItemPriceInStep:
                        stepItem.nbrOfChoicesWithItemPriceInStep === undefined
                            ? null
                            : stepItem.nbrOfChoicesWithItemPriceInStep,

                    specialPrice: isNaN(stepItem.specialPrice)
                        ? 0
                        : Number(stepItem.specialPrice),

                    priceOfItemInStep: isNaN(stepItem.priceOfItemInStep)
                        ? 0
                        : Number(stepItem.priceOfItemInStep),
                    title: stepItem.title,
                    color: stepItem.color,
                    parentCategory: stepItem.parentCategory,
                    price: +stepItem.price,
                    rank: stepItem.rank,
                    basicCompositionVisibility:
                        stepItem.basicCompositionVisibility ?? true,
                    refrence: stepItem.refrence,
                    imageUrl: stepItem.imageUrl ?? "",
                    fidelity: stepItem.fidelity ?? 0,
                    visibilityInfo: stepItem.visibilityInfo,
                };
            });

            setIsOpen(!isOpen);

            dispatch({
                type: ACTIONS.STEP_ITEMS,
                payload: stepItems,
            });
        }
        setActionColumnDisplay(false);
        setItemToEditVisibility({
            isVisible: true,
            basicCompositionVisibility: true,
            default: {},
        });
        dispatchStepItem({
            type: ACTIONS.ClEAR,
            payload: {},
        });
    };
    const handleCancelButtonOnClick = async () => {
        toggleOpen();
        convertStepItemsObjectToArray(stepForm.stepItems).then((data) =>
            setSelectedDataRow(data)
        );
    };
    const handleOnApplyModification = () => {
        setSelectedDataRow((previousState) => {
            return previousState.map((item) => {
                if (selectedItemsToEdit.includes(item.itemIuud)) {
                    return {
                        ...item,
                        maxNbrOfChoicesByItems:
                            stepItemForm.maxNbrOfChoicesByItems,
                        nbrOfChoicesWithItemPriceInStep:
                            stepItemForm.nbrOfChoicesWithItemPriceInStep,
                        nbrOfChoicesWithspecialPrice:
                            stepItemForm.nbrOfChoicesWithspecialPrice,
                        specialPrice: stepItemForm.specialPrice,
                        priceOfItemInStep: stepItemForm.priceOfItemInStep,
                        color: stepItemForm.color,
                        fidelity: stepItemForm.fidelity,
                        imageUrl: stepItemForm.imageUrl,
                        visibilityInfo: itemToEditVisibility,
                    };
                }
                return item;
            });
        });
        setActionColumnDisplay(!isActionColumnDisplayed);
    };

    React.useEffect(() => {
        convertStepItemsObjectToArray(stepForm.stepItems).then((data) =>
            setSelectedDataRow(data)
        );
    }, [stepForm.stepItems]);

    return (
        <StyledModal
            isOpen={isOpen}
            centered
            style={{
                minWidth: "calc( 100vw - 200px)",
            }}
        >
            <ModalHeader toggle={toggleOpen}>
                {!isActionColumnDisplayed ? (
                    <StyledH2 className="text-uppercase">
                        {t("Step item management")}
                    </StyledH2>
                ) : (
                    <StyledH2 className="text-uppercase">
                        {t("Multiple edit")}
                    </StyledH2>
                )}
            </ModalHeader>
            <ModalBody
                style={
                    cardType === "shop" && cardStatus === "En production"
                        ? {
                              pointerEvents: "none",
                              maxHeight: "calc( 100vh - 40px)",
                              minHeight: "calc( 70vh)",
                          }
                        : {
                              maxHeight: "calc( 100vh - 40px)",
                              minHeight: "calc( 70vh)",
                          }
                }
            >
                <div className="d-flex justify-content-between">
                    <StyledSearchInput
                        onChange={handleOnSearchItems}
                        placeholder={t("Rechercher")}
                        style={{ border: "1px solid ##C6C6C6" }}
                    />
                    {!isActionColumnDisplayed ? (
                        <div className="d-flex justify-content-end">
                            <StyledButton
                                className="mr-3"
                                rounded
                                outline
                                variant="light"
                                disabled={selectedItemsToEdit.length < 2}
                                onClick={(): void => {
                                    setActionColumnDisplay(true);
                                }}
                            >
                                {t("Multiple edit")}
                            </StyledButton>

                            <StyledButton
                                rounded
                                variant={dominantColor}
                                onClick={() => setIsAddItemsStepModalOpen(true)}
                                disabled={
                                    updateItemWorkflow.pathUpdateItem.length >
                                        0 && ETKType === "3"
                                }
                            >
                                <AddItemsStepModal
                                    isOpen={isAddItemsStepModalOpen}
                                    setIsOpen={setIsAddItemsStepModalOpen}
                                    setSelectedDataRow={setSelectedDataRow}
                                    selectedDataRow={selectedDataRow}
                                    stepDesignation={stepForm.title}
                                    initialData={initialData}
                                />

                                {t("Add items")}
                            </StyledButton>
                        </div>
                    ) : (
                        <StyledButton
                            rounded
                            variant={dominantColor}
                            onClick={handleOnApplyModification}
                        >
                            {t("Apply modifications")}
                        </StyledButton>
                    )}
                </div>

                <div className="mt-3">
                    <StepItemManagementTable
                        isActionColumnDisplayed={isActionColumnDisplayed}
                        setActionColumnDisplay={setActionColumnDisplay}
                        InsertedItems={filteredIems}
                        setInsertedItems={setSelectedDataRow}
                        setSelectedItemsToEdit={setSelectedItemsToEdit}
                        stepItemForm={stepItemForm}
                        dispatchStepItem={dispatchStepItem}
                        itemToEditVisibility={itemToEditVisibility}
                        setItemToEditVisibility={setItemToEditVisibility}
                        stepForm={stepForm}
                    />
                </div>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    rounded
                    outline
                    variant="light"
                    onClick={handleCancelButtonOnClick}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={submitStepItems}
                    disabled={
                        updateItemWorkflow.pathUpdateItem.length > 0 &&
                        ETKType === "3"
                    }
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
