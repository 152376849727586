import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    // eslint-disable-next-line camelcase
    UNSAFE_NavigationContext,
    useLocation,
    useNavigate,
} from "react-router-dom";

import { RootState } from "@store/index";
import { initEditObserver } from "@store/features/editObserverSlice";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";

export default function NavigationBlocker() {
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    // eslint-disable-next-line prefer-destructuring
    const navigator: any = React.useContext(UNSAFE_NavigationContext).navigator;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const [path, setPath] = React.useState("");
    const [isNavigationBlocked, setIsNavigationBlocked] = React.useState(false);

    React.useEffect(() => {
        if (path !== "" && !isNavigationBlocked) {
            navigate(path);
            setPath("");
        }
    }, [path, navigate, isNavigationBlocked]);
    React.useEffect(() => {
        if (!isDirty) return;

        const unblock = navigator.block((tx: any) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            setIsUnsavedChangesWarningModalOpen(true);
            setPath(autoUnblockingTx.location.pathname);
            setIsNavigationBlocked(true);
        });
        // eslint-disable-next-line consistent-return
        return unblock;
    }, [isDirty, path, navigator]);
    return (
        <UnsavedChangesWarningModal
            isUnsavedChangesWarningModalOpen={
                isUnsavedChangesWarningModalOpen && location.pathname !== path
            }
            setIsUnsavedChangesWarningModalOpen={
                setIsUnsavedChangesWarningModalOpen
            }
            action={() => {
                dispatch(initEditObserver());
                setIsUnsavedChangesWarningModalOpen(false);
                setIsNavigationBlocked(false);
            }}
        />
    );
}
