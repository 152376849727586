import { SVGProps } from "react";

export default function AllergenIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || "107"}
            height={props.height || "107"}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27814"
                data-name="Groupe 27814"
                transform="translate(18139 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                        rx={15}
                        transform="translate(-2)"
                        fill={props.stopColor}
                    />
                </g>
                <g id="gluten-free" transform="translate(-18383.074 -4576.993)">
                    <path
                        id="Trac\xE9_21002"
                        data-name="Trac\xE9 21002"
                        d="M259.15,270.16a9.082,9.082,0,1,1,6.425-2.657A9.082,9.082,0,0,1,259.15,270.16Zm0-17.135a8.054,8.054,0,1,0,5.7,2.356A8.054,8.054,0,0,0,259.15,253.025Z"
                        transform="translate(0 0)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21003"
                        data-name="Trac\xE9 21003"
                        d="M286.653,315.1h-1.046a2.379,2.379,0,0,1-2.378-2.378v-.612a.434.434,0,0,1,.434-.434h.6a2.379,2.379,0,0,1,2.378,2.378Zm-2.7-2.7v.324a1.658,1.658,0,0,0,1.656,1.656h.324v-.324a1.658,1.658,0,0,0-1.656-1.656Z"
                        transform="translate(-27.189 -48.943)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21004"
                        data-name="Trac\xE9 21004"
                        d="M286.653,301.121h-1.046a2.378,2.378,0,0,1-2.378-2.378v-.61a.434.434,0,0,1,.434-.434h.6a2.377,2.377,0,0,1,2.378,2.378Zm-2.7-2.7v.322a1.659,1.659,0,0,0,1.656,1.658h.324v-.324a1.658,1.658,0,0,0-1.656-1.656Z"
                        transform="translate(-27.189 -37.479)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21005"
                        data-name="Trac\xE9 21005"
                        d="M286.653,287.133h-1.046a2.379,2.379,0,0,1-2.378-2.378v-.612a.434.434,0,0,1,.434-.434h.6a2.379,2.379,0,0,1,2.378,2.378Zm-2.7-2.7v.324a1.658,1.658,0,0,0,1.656,1.656h.324v-.324a1.658,1.658,0,0,0-1.656-1.656Z"
                        transform="translate(-27.189 -26.007)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21006"
                        data-name="Trac\xE9 21006"
                        d="M299.236,315.1H298.19v-1.046a2.378,2.378,0,0,1,2.378-2.378h.61a.432.432,0,0,1,.434.434v.612a2.378,2.378,0,0,1-2.376,2.378Zm-.324-.72h.324a1.658,1.658,0,0,0,1.656-1.656V312.4h-.324a1.658,1.658,0,0,0-1.656,1.656Z"
                        transform="translate(-39.456 -48.943)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21007"
                        data-name="Trac\xE9 21007"
                        d="M299.236,301.121H298.19v-1.044a2.376,2.376,0,0,1,2.378-2.378h.61a.432.432,0,0,1,.434.434v.61A2.376,2.376,0,0,1,299.236,301.121Zm-.324-.72h.324a1.659,1.659,0,0,0,1.656-1.658v-.322h-.324a1.658,1.658,0,0,0-1.656,1.656Z"
                        transform="translate(-39.456 -37.479)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21008"
                        data-name="Trac\xE9 21008"
                        d="M299.236,287.133H298.19v-1.046a2.378,2.378,0,0,1,2.378-2.378h.677a.36.36,0,0,1,.36.369v.677A2.378,2.378,0,0,1,299.236,287.133Zm-.324-.72h.324a1.658,1.658,0,0,0,1.656-1.656v-.324h-.324a1.658,1.658,0,0,0-1.656,1.656Z"
                        transform="translate(-39.456 -26.007)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21009"
                        data-name="Trac\xE9 21009"
                        d="M293.435,271.885l-.761-.763a2.489,2.489,0,0,1,0-3.522l.518-.518a.349.349,0,0,1,.495,0l.518.518a2.493,2.493,0,0,1,0,3.522Zm0-4.034-.256.256a1.775,1.775,0,0,0,0,2.5l.256.256.257-.256a1.775,1.775,0,0,0,0-2.5Z"
                        transform="translate(-34.334 -12.287)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21010"
                        data-name="Trac\xE9 21010"
                        d="M298.65,298.361a.36.36,0,0,1-.36-.36V289.77a.36.36,0,1,1,.72,0V298a.36.36,0,0,1-.36.362Z"
                        transform="translate(-39.538 -30.681)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21011"
                        data-name="Trac\xE9 21011"
                        d="M268.271,317.156a2.418,2.418,0,0,1-.133-.666l-3.272,3.274a.373.373,0,0,0,.263.635.38.38,0,0,0,.265-.11l2.946-2.946C268.316,317.28,268.293,317.219,268.271,317.156Z"
                        transform="translate(-12.041 -52.887)"
                        fill={props.fill}
                    />
                    <path
                        id="Trac\xE9_21012"
                        data-name="Trac\xE9 21012"
                        d="M319.035,266.822a.373.373,0,0,0-.527,0l-3.117,3.119a1.073,1.073,0,0,1,.265.741.37.37,0,0,0,0,.045l3.378-3.378a.373.373,0,0,0,0-.527Z"
                        transform="translate(-53.56 -12.069)"
                        fill={props.fill}
                    />
                </g>
            </g>
        </svg>
    );
}
