/* eslint-disable camelcase */
export const ITEMS_PER_PAGE = 5;
export const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/;
export const FORGOT_PASSWORD_CODE_LENGHT = 6;
// export const CARD_IUUD = "cdb7b965-f1a2-aceb-ca61-ec32a4117035";
// // export const schemaAdbFranchise = "franchise_sushi_meal_94400";
// // export const schemaAdbFranchise = "franchise_test_sofiene";
// // export const schemaAdbFranchise = "franchise_amal_2022";
// export const schemaAdbFranchise = "franchise_mohamed_2022";
export { legalCharacters, legalExpression } from "./RegExpression";
export { SELLING_SUPPORT } from "./sellingsupport";
export { DEFAULT_LANGUAGE } from "./defaultLanguage";
export { WORKfLOW_LIST } from "./WorkFlow";
