import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { Nav } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
    DynamicTableWrapperContext,
    ErrorToast,
    LoadingDataAnimation,
    SuccessToast,
} from "@components/Common/";

import axios from "axios";
import { useTranslation } from "react-i18next";
import {
    initialStateCategory,
    reducerCategory,
} from "@pages/Catalogue/Categories/reducer";
import { initialStateItem, reducerItem } from "@pages/Catalogue/Items/reducer";
import { initialStateStep, reducer } from "@pages/Catalogue/steps/reducer";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

type ImportreportType = {
    file: any;
    selectednumber: number;
    Updatenumber: number;
    creatednumber: number;
};
function Importreport({
    file,
    selectednumber,
    Updatenumber,
    creatednumber,
}: ImportreportType) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const defaultVisibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const pathname = window.location.pathname
        .toString()
        .split("/")[2]
        .toUpperCase();
    let to = `/${pathname}`;
    let url: string = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/importcards/${cardIuud}/categories`;

    let message1 = "";
    let message2 = "";
    let message3 = "";
    let message4 = "";
    let message5 = "";
    let message6 = "";

    if (pathname === "ARTICLES") {
        url = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/importcards/${cardIuud}/items`;
        to = "/items";
    }
    if (pathname === "WORKFLOW") {
        // url = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/importcards/${cardIuud}/items`;
        to = "/workflow";
    }
    if (pathname === "STEPS") {
        //  /:schemaAdbFranchise/cards/:cardIuud/stepfordemo
        url = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/importcards/${cardIuud}/steps`;
        to = "/steps";
    }

    const insert = async (): Promise<void> => {
        setIsLoading(true);
        const listdata: any = [];

        file.rows.forEach((element: any) => {
            let data: any = {};
            if (pathname === "CATEGORIES") {
                data = {
                    ...initialStateCategory,

                    visibilityInfo: {
                        ...initialStateCategory.visibilityInfo,
                        default: defaultVisibility,
                    },
                };
            }
            if (pathname === "ARTICLES") {
                data = {
                    ...initialStateItem,

                    visibilityInfo: {
                        ...initialStateItem.visibilityInfo,
                        default: defaultVisibility,
                    },
                };
            }

            if (pathname === "STEPS") {
                data = initialStateStep;
            }
            if (element[element.length - 3]) {
                element.forEach((row: any, index2: number) => {
                    if (
                        element.length - 2 !== index2 &&
                        element[element.length - 3] &&
                        element.length - 1 !== index2 &&
                        element.length - 3 !== index2
                    ) {
                        if (pathname === "ARTICLES") {
                            data = reducerItem(data, {
                                type: file.cols[index2].key,
                                payload: row,
                            });
                        }
                        if (pathname === "STEPS") {
                            data = reducer(data, {
                                type: file.cols[index2].key,
                                payload: row,
                            });
                        } else {
                            data = reducerCategory(data, {
                                type: file.cols[index2].key,
                                payload: row,
                            });
                        }
                    }
                });
                let iuud;
                if (element[element.length - 2] !== "To add") {
                    iuud = element[element.length - 1];
                }
                const state = {
                    iuud,
                    value: data,
                };

                listdata.push(state);
            }
        });

        axios
            .post(url, listdata)
            .then(() => {
                setIsLoading(false);
                SuccessToast(t("Card updated successfully"));
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(true);
                navigate(to);
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    };
    switch (pathname) {
        case "ARTICLES":
            message1 = t("Total number of items");
            message2 = t("Number of selected items");
            message3 = t("Number of items imported");
            message4 = t("Number of new items added");
            message5 = t("Import of items is successful");
            message6 = t("Number of modified items");
            break;
        case "STEPS":
            message1 = t("Total number of steps");
            message2 = t("Number of selected steps");
            message3 = t("Number of steps imported");
            message4 = t("Number of new steps added");
            message5 = t("Import of steps is successful");
            message6 = t("Number of modified steps");

            break;
        default:
            message1 = t("Total number of categories");
            message2 = t("Number of selected categories");
            message3 = t("Number of categories imported");
            message4 = t("Number of new categories added");
            message5 = t("Import of categories is successful");
            message6 = t("Number of modified categories");
            break;
    }
    let ImportedElement: number = 0;
    file.rows.forEach((cell: any) => {
        if (cell[0] !== true) {
            ImportedElement += 1;
        }
    });

    return (
        <Nav>
            {isLoading && (
                <div style={{ position: "absolute" }}>
                    <LoadingDataAnimation />
                </div>
            )}
            <div className="d-flex bd-highlight col-12 d-flex align-items-center">
                <div className="p-2 w-100 bd-highlight">
                    {t("Import report")} :
                </div>
                <div className="p-2 flex-shrink-1 bd-highlight">
                    <StyledButton
                        className={` ${file ? " " : " disabledDiv"}`}
                        outline
                        rounded
                        variant="light"
                        style={{
                            color: "#ffffff",
                            background: "#5d5d5d",
                        }}
                        onClick={insert}
                    >
                        {t("To end")}
                    </StyledButton>
                </div>
            </div>

            <div className="col-12 d-flex align-items-center my-3 px-5 ">
                <span
                    className="iconify "
                    data-icon="ph:check-circle-light"
                    style={{
                        fontSize: "40px",
                        color: "#66BB00",
                    }}
                />
                {message5}
            </div>
            <div
                style={{
                    background: "#E6E6E6 0% 0% no-repeat padding-box",
                    height: "80%",
                    width: "80%",
                    border: "1px solid #707070",
                    borderRadius: "5px",
                    opacity: "1",
                }}
            >
                <div className="my-3">
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2 " style={{ width: "65%" }}>
                            {message1}
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#F3F3F9",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            {ImportedElement}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2 " style={{ width: "65%" }}>
                            {message2}
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#9B9D9F",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            {selectednumber}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2 " style={{ width: "65%" }}>
                            {message3}
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#1BAC47",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            {selectednumber}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2 px-5 " style={{ width: "65%" }}>
                            {message4}
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#1D4AFE",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            {creatednumber}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2 px-5 " style={{ width: "65%" }}>
                            {message6}
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#FF8000",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            {Updatenumber}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div className="p-2" style={{ width: "65%" }}>
                            {t("Number of detected errors")} :
                        </div>
                        <div
                            className="p-2  d-flex justify-content-center "
                            style={{
                                background: "#FF4141",
                                color: "black",
                                borderRadius: "10px",
                                width: "20%",
                                height: "35px",
                            }}
                        >
                            0
                        </div>
                    </div>
                </div>
            </div>
        </Nav>
    );
}

export default Importreport;
