/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { StyledTextInput, MinusIcon } from "@aureskonnect/react-ui";

import setFocus from "@helpers/setFocus";
import DropdownDisplayNameModal from "./DropdownDisplayNameModal";

import "./DisplayName.css";

type DisplayName = {
    impression: LanguageType[];
    setImpression: React.Dispatch<React.SetStateAction<LanguageType[]>>;
    sellingSupport: sellingSupportType[];
    setSellingSupport: React.Dispatch<
        React.SetStateAction<sellingSupportType[]>
    >;
    DefaultValueName: string;
    elementType?: string;
};

export default function DisplayNameTable({
    impression,
    setImpression,
    sellingSupport,
    setSellingSupport,
    DefaultValueName,
    elementType,
}: DisplayName) {
    const defaultLanguage: string = "Francais";
    const [, setSupport] = useState("");

    function handleChangeHiddenSupport(NameSupport: string) {
        const index = sellingSupport.findIndex(
            (element): boolean => element.sellingSupport === NameSupport
        );

        if (index !== -1) {
            sellingSupport[index].visibility = false;
        }
        setSellingSupport(sellingSupport);

        const filteredArrayLength = sellingSupport.filter(
            (item): boolean | undefined => item.visibility
        ).length;
        if (index !== -1 && filteredArrayLength === 0) {
            sellingSupport[index].visibility = true;
        }

        impression?.map((element: LanguageType): sellingSupportType[] => {
            const NameLangue: string = element.designation;

            sellingSupport[index].Langue.map(
                (item: LanguageType): LanguageType => {
                    if (item.designation === NameLangue) {
                        item.visibility = element.visibility;
                    }
                    return item;
                }
            );
            return sellingSupport;
        });
        setSellingSupport(sellingSupport);
    }

    function handleChangeImpression(nameLangue: string, value: string) {
        setImpression((prevState: any) => {
            const newState = [...prevState];

            const indexLangue = impression.findIndex(
                (element: any): boolean => element.designation === nameLangue
            );

            function NewLangue(): {
                designation: string;
                value: string;
                visibility?: boolean | undefined;
            } {
                const selectLanguage = {
                    ...impression[indexLangue],
                };
                selectLanguage.value = value;
                return selectLanguage;
            }

            const updatedLangue = NewLangue();

            newState[indexLangue] = {
                value: updatedLangue.value,
                visibility: updatedLangue.visibility,
                designation: updatedLangue.designation,
            };
            return newState;
        });
    }

    function handlechangeInputSupport(
        nameSupport: string,
        value: string,
        nameLanguage: string
    ) {
        setSellingSupport((prevState: any) => {
            const newState = [...prevState];
            const indexSupport = sellingSupport.findIndex(
                (element: any): boolean =>
                    element.sellingSupport === nameSupport
            );
            const indexLanguage = sellingSupport[indexSupport].Langue.findIndex(
                (element: any): boolean => element.designation === nameLanguage
            );
            function NewLangue(): {
                designation: string;
                value: string;
                visibility?: boolean | undefined;
            } {
                const selectLanguage = {
                    ...sellingSupport[indexSupport].Langue[indexLanguage],
                };
                selectLanguage.value = value;
                return selectLanguage;
            }

            const updatedLangue = NewLangue();
            const newArr = [...newState[indexSupport].Langue];
            newArr[indexLanguage] = updatedLangue;
            newState[indexSupport] = {
                ...newState[indexSupport],
                Langue: newArr,
            };

            return newState;
        });
    }
    function setFocusImpression(index: number, event: any) {
        switch (event.keyCode) {
            case 37: // left
                return document?.getElementById(`${index - 1}`)?.focus();
            case 39: //  right
                return document?.getElementById(`${index + 1}`)?.focus();
            default:
                return event.keyCode;
        }
    }

    return (
        <>
            <table className="table table-bordered mt-3">
                <thead
                    style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    <tr>
                        <th
                            scope="col"
                            style={{
                                width: "170px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                            }}
                        >
                            <div className="ml-5">Langues</div>
                            <hr className="Line__CLZ" />

                            <div style={{ marginLeft: "-35px" }}>
                                Supports de vente
                            </div>
                        </th>
                        {impression
                            .filter(
                                (element): boolean | undefined =>
                                    element.visibility
                            )
                            .map((element: LanguageType, index: number) => (
                                <th
                                    scope="col"
                                    className="align-middle"
                                    key={`${index}+${element.designation}`}
                                >
                                    {element.designation}
                                </th>
                            ))}
                        <th
                            scope="col"
                            className="align-middle"
                            style={{
                                width: "15px",
                            }}
                        >
                            <DropdownDisplayNameModal
                                impression={impression}
                                setImpression={setImpression}
                                sellingSupport={sellingSupport}
                                setSellingSupport={setSellingSupport}
                                setSupport={setSupport}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody
                    className="text-center"
                    style={{ fontSize: 15, fontWeight: "bold" }}
                >
                    {sellingSupport
                        .filter((item): boolean | undefined => item.visibility)
                        .map((item: any, index: number) => (
                            <tr key={`${index}+${item.sellingSupport}+Row`}>
                                <td
                                    className="align-middle pl-2"
                                    style={{ textAlign: "start" }}
                                    key={`${index}+${item.sellingSupport}+Column`}
                                >
                                    {item.sellingSupport}
                                </td>
                                {item.Langue.filter(
                                    (element: any) => element.visibility
                                ).map((element: LanguageType, id: number) => (
                                    <td key={`${id}+${item.sellingSupport}`}>
                                        <div className="d-flex justify-content-center mt-1 mb-1">
                                            <StyledTextInput
                                                onKeyDown={(event: any) => {
                                                    setFocus(id, index, event);
                                                }}
                                                id={`${id}+${index}`}
                                                name={`${item.sellingSupport}-${element.designation}`}
                                                placeholder="Saisir"
                                                value={element.value}
                                                defaultValue={
                                                    element.designation ===
                                                        defaultLanguage &&
                                                    element.value === ""
                                                        ? DefaultValueName
                                                        : element.value
                                                }
                                                style={{
                                                    border: "0px",
                                                    borderColor: "inherit",
                                                    webkitBoxShadow: "none",
                                                    boxShadow: "none",
                                                    textAlign: "center",
                                                }}
                                                onChange={(e: any) => {
                                                    handlechangeInputSupport(
                                                        item.sellingSupport,
                                                        e.target.value,
                                                        element.designation
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                ))}
                                <td
                                    className="align-middle"
                                    key={`${index}+${item.sellingSupport}+Choice`}
                                >
                                    <MinusIcon
                                        height={25}
                                        width={25}
                                        onClick={() => {
                                            handleChangeHiddenSupport(
                                                item.sellingSupport
                                            );
                                            setSupport(item.sellingSupport);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <table className="table table-bordered">
                <thead
                    style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    <tr>
                        {elementType !== "step" ? (
                            <>
                                <th
                                    scope="col"
                                    className="align-middle pl-2"
                                    style={{
                                        width: "173px",
                                        paddingBottom: "0px",
                                        paddingTop: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                        textAlign: "start",
                                    }}
                                >
                                    <div>Impression</div>
                                </th>
                                {impression
                                    .filter(
                                        (element): boolean | undefined =>
                                            element.visibility
                                    )
                                    .map(
                                        (
                                            element: LanguageType,
                                            index: number
                                        ) => (
                                            <th
                                                scope="col"
                                                className=" align-middle justify-content-center"
                                                key={`${index}+${element.designation}`}
                                            >
                                                <StyledTextInput
                                                    onKeyDown={(event: any) => {
                                                        setFocusImpression(
                                                            index,
                                                            event
                                                        );
                                                    }}
                                                    id={`${index}`}
                                                    name={`${element.designation}-${element.designation}`}
                                                    placeholder="Saisir"
                                                    value={element.value}
                                                    defaultValue={
                                                        element.designation ===
                                                            defaultLanguage &&
                                                        element.value === ""
                                                            ? DefaultValueName
                                                            : element.value
                                                    }
                                                    onChange={(e: any) => {
                                                        handleChangeImpression(
                                                            element.designation,
                                                            e.target.value
                                                        );
                                                    }}
                                                    style={{
                                                        border: "0px",
                                                        borderColor: "inherit",
                                                        webkitBoxShadow: "none",
                                                        boxShadow: "none",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </th>
                                        )
                                    )}
                                <th
                                    scope="col"
                                    className="align-middle"
                                    key="Choice"
                                    style={{
                                        width: "15px",
                                        visibility: "hidden",
                                    }}
                                >
                                    <MinusIcon height={25} width={25} />
                                </th>
                            </>
                        ) : null}
                    </tr>
                </thead>
            </table>
        </>
    );
}
