import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    ErrorToast,
    DominantColor,
    DynamicTableWrapperContext,
    SuccessToast,
} from "@components/Common/";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
    StyledH2,
} from "@aureskonnect/react-ui";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import { ACTIONS } from "@helpers/actions";
import { AvForm } from "availity-reactstrap-validation";
import { initialStateCard, reducerCard } from "../reducer";

type CardModalProps = {
    isOpen: boolean;
    setIsOpen: Function;
    rowData: any;
    setIsLoading: Function;
};
export default function ConfigureCard({
    isOpen,
    setIsOpen,
    rowData,
    setIsLoading,
}: CardModalProps) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const [cardState, dispatchCard] = React.useReducer(
        reducerCard,
        initialStateCard
    );
    const dominantColor: string = DominantColor();
    const { t } = useTranslation();
    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleValidateButtonOnClickEvent = () => {
        try {
            if (cardState?.title) {
                setIsLoading(true);
                axios
                    .put(
                        `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/card/${rowData?.selectedRow?.original.iuud}`,
                        cardState
                    )
                    .then((): void => {
                        SuccessToast(t("Card updated successfully"));
                        dynamicTableWrapperContext?.setDataUpdated(true) &&
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        toggleOpen();
                        setIsLoading(false);
                    })
                    .catch((): void => {
                        setIsLoading(false);
                        ErrorToast(t("There is a mistake!"));
                    });
            } else {
                ErrorToast(t("The ''Designation'' field must be filled in"));
            }
        } catch (error: unknown) {
            ErrorToast(t("There is a mistake!"));
        }
    };
    const handleOnchangeDésignation = (Désignation: string): void => {
        dispatchCard?.({
            type: ACTIONS.TITLE,
            payload: Désignation,
        });
    };
    const handleOnchangeRemark = (Reference: string): void => {
        dispatchCard?.({
            type: ACTIONS.REMARQUE,
            payload: Reference,
        });
    };
    const handleColor = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatchCard?.({
            type: ACTIONS.COLOR,
            payload: e.target.value,
        });
    };

    React.useEffect((): void => {
        if (rowData) {
            dispatchCard({
                type: ACTIONS.EDIT,
                payload: {
                    title: rowData?.selectedRow?.original?.Désignation,
                    remark:
                        rowData?.selectedRow?.original.remark === null
                            ? ""
                            : rowData?.selectedRow?.original.remark,
                    color: rowData?.selectedRow?.original.color
                        ? rowData?.selectedRow?.original.color
                        : "#ffffff",
                },
            });
        }

        // eslint-disable-next-line
    }, [rowData, isOpen]);

    return (
        <div>
            <Modal centered isOpen={isOpen}>
                <ModalHeader toggle={toggleOpen}>
                    <StyledH2 className="text-uppercase">
                        CONFIGURATION
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <div id="désignation">
                        <StyledLabel>{t("Designation *")}</StyledLabel>
                        <AvForm>
                            <StyledInputWithTooltip
                                // innerRef={addToRefs}
                                id="désignation"
                                name="title"
                                type="text"
                                value={
                                    rowData.selectedRow.original.CardMaster
                                        ? cardState?.title
                                        : rowData?.selectedRow?.original
                                              .Désignation
                                }
                                requiredValidateValue={false}
                                valueSetterFunction={handleOnchangeDésignation}
                                autoFocus
                                onFocus={(event: any) => {
                                    event.target.select();
                                }}
                            />
                        </AvForm>
                    </div>

                    <StyledLabel>{t("Remark ")}</StyledLabel>

                    <AvForm>
                        <StyledInputWithTooltip
                            // innerRef={addToRefs}
                            id="remarque"
                            name="remark"
                            type="text"
                            value={cardState?.remark}
                            requiredValidateValue={false}
                            valueSetterFunction={handleOnchangeRemark}
                            autoFocus
                            onFocus={(event: any) => {
                                event.target.select();
                            }}
                        />
                        {rowData.selectedRow.original.CardMaster ? (
                            <div className="py-2" id="couleur">
                                <div className=" row d-flex align-content-center flex-wrap pt-3">
                                    <div className=" col-6 ">
                                        <StyledLabel>{t("Color")}</StyledLabel>
                                        <StyledTextInput
                                            id="couleur"
                                            name="Couleur"
                                            placeholder={t("Enter")}
                                            value={cardState.color}
                                            type="color"
                                            onChange={handleColor}
                                        />
                                    </div>
                                    <div className=" col-6 ">
                                        <StyledLabel>
                                            {t("Hexadecimal code")}
                                        </StyledLabel>

                                        <StyledTextInput
                                            id="Hexadecimal"
                                            name="Hexadecimal"
                                            placeholder={t("Enter")}
                                            type="text"
                                            value={cardState.color}
                                            onChange={handleColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </AvForm>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <StyledButton
                        onClick={toggleOpen}
                        rounded
                        variant="light"
                        outline
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        onClick={handleValidateButtonOnClickEvent}
                        variant={dominantColor}
                        rounded
                    >
                        {t("Valider")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}
