import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type deleteModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    optionIuud: string;
    setIsLoading: Function;
    isNotBeDeleted?: boolean;
};

export default function DeleteOptionModal({
    isOpen,
    setIsOpen,
    optionIuud,
    setIsLoading,
    isNotBeDeleted = false,
}: deleteModalPropsType) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const canBeDeleted =
        dynamicTableWrapperContext?.rowData?.isConsumed || isNotBeDeleted;
    const dominantColor: string = DominantColor();

    const handleRemoveButtonOnClickEvent = async () => {
        setIsLoading(true);
        const CardIuud = JSON.parse(
            localStorage.getItem("selectedCard") || "{}"
        ).iuud;

        axios
            .delete(
                `${process.env.REACT_APP_ADB_API_URL}option/${schemaAdbFranchise}/${CardIuud}/${optionIuud}`
            )
            .then(() => {
                setIsOpen(!isOpen);
                setIsLoading(false);
                dynamicTableWrapperContext?.setDataUpdated(true);
            })
            .then(() => {
                SuccessToast(t("The option removed successfully"));
            })
            .catch(() => {
                setIsLoading(false);
                ErrorToast(t("There is a mistake!"));
            });
    };

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex">
                        {t("Archive Option")} <span className="mx-1">:</span>
                        {dynamicTableWrapperContext?.rowData?.title.length >
                        20 ? (
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={
                                    dynamicTableWrapperContext?.rowData?.title
                                }
                                id="archiver"
                            />
                        ) : null}
                        <div id="archiver" className="modal_clz ">
                            {dynamicTableWrapperContext?.rowData?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {canBeDeleted ? (
                    <>
                        {" "}
                        <h3 className="d-flex justify-content-center">
                            {t("Warning")} !
                        </h3>
                        <div className="my-2">
                            {t(
                                "This option is used by one or more categories and/or one or more items"
                            )}
                        </div>
                    </>
                ) : (
                    <>{t("Do you want to confirm archiving ?")}</>
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {canBeDeleted ? t("Close") : t("Cancel")}
                </StyledButton>
                {!canBeDeleted && (
                    <StyledButton
                        onClick={handleRemoveButtonOnClickEvent}
                        rounded
                        variant={dominantColor}
                    >
                        {t("Archive")}
                    </StyledButton>
                )}
            </ModalFooter>
        </Modal>
    );
}
