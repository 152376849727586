import React from "react";

import { useTranslation } from "react-i18next";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
    SearchIcon,
    StyledIconButton,
    StyledSearchInput,
} from "@aureskonnect/react-ui";
import { RootState } from "@store/index";
import { useSelector, useDispatch } from "react-redux";
import { setWorkflow } from "@store/features/workflowSlice";
import { WORKFLOW_LIST } from "@constants/WorkFlow";

import ItemSelectionDropdown from "./ItemSelectionDropdown";

type searchDateProps = {
    data: WorkFlowDataType;
    cardData: any;
    FilterData: React.Dispatch<React.SetStateAction<WorkFlowDataType>>;
    globalIndex: number;
    checkstepItem: boolean;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    IsBasicCompositionStep: boolean;
    isModal: boolean;
    workflow: any;
    setContentModal?: React.Dispatch<any> | undefined;
    contentModal?: any | undefined;
};
export function SearchData({
    data,
    cardData,
    FilterData,
    globalIndex,
    checkstepItem,
    setIsUnsavedDataMultiple,
    IsBasicCompositionStep,
    isModal,
    workflow,
    setContentModal,
    contentModal,
}: searchDateProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const ETKType = localStorage.getItem("ETKType");
    const [isDropdownMenuActive, setIsDropdownMenuActive] =
        React.useState(false);
    const [searchedDesignation, setSearchedDesignation] = React.useState("");
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const filter = React.useCallback(
        (params: string) => {
            if (params !== "") {
                const filteredData: WorkFlowDataType = {};
                Object.entries(data).forEach(([iuud, elm]) => {
                    if (
                        cardData[elm.type][iuud].title
                            .toLowerCase()
                            .indexOf(params.toLowerCase()) > -1
                    ) {
                        filteredData[iuud] = elm;
                    }
                });
                FilterData(filteredData);
            } else {
                FilterData(data);
            }
        },
        [FilterData, data, cardData]
    );
    const selectedAllProducts = () => {
        if (
            multipleWorkflow.length > 0 &&
            workflow[globalIndex!]?.type === WORKFLOW_LIST.CATEGORIES
        ) {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const copyGlobalWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        const listUuidData = Object.keys(
            copyGlobalWorkflow[globalIndex!]?.data
        ).filter(
            (key) =>
                copyGlobalWorkflow[globalIndex!]?.data[key].type ===
                WORKFLOW_LIST.ITEMS
        );

        if (
            Object.keys(globalWorkflow[globalIndex!]?.data).length ===
            globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.length
        ) {
            /* deselect all product */
            copyGlobalWorkflow[globalIndex] = {
                ...copyGlobalWorkflow[globalIndex],
                multipleStep: {
                    isMultiple: false,
                    listeUuidItem: [],
                },
            };
        } else {
            /* select all product */
            copyGlobalWorkflow[globalIndex] = {
                ...copyGlobalWorkflow[globalIndex],
                multipleStep: {
                    isMultiple: listUuidData.length > 1,
                    listeUuidItem: listUuidData,
                },
            };
        }
        dispatch(setWorkflow(copyGlobalWorkflow));
    };
    const selectedAllProductsModal = () => {
        const copyGlobalWorkflow = JSON.parse(
            JSON.stringify(contentModal[contentModal.length - 1].data)
        );
        const listUuidData = Object.keys(
            copyGlobalWorkflow[globalIndex!]?.data
        ).filter(
            (key) =>
                copyGlobalWorkflow[globalIndex!]?.data[key].type ===
                WORKFLOW_LIST.ITEMS
        );

        if (
            Object.keys(workflow[globalIndex!]?.data).length ===
            workflow[globalIndex!]?.multipleStep?.listeUuidItem.length
        ) {
            /* deselect all product */
            copyGlobalWorkflow[globalIndex] = {
                ...copyGlobalWorkflow[globalIndex],
                multipleStep: {
                    isMultiple: false,
                    listeUuidItem: [],
                },
            };
        } else {
            /* select all product */
            copyGlobalWorkflow[globalIndex] = {
                ...copyGlobalWorkflow[globalIndex],
                multipleStep: {
                    isMultiple: listUuidData.length > 1,
                    listeUuidItem: listUuidData,
                },
            };
        }
        const copyContentModal: any = [...contentModal];
        copyContentModal[copyContentModal.length - 1].data = copyGlobalWorkflow;
        setContentModal?.(copyContentModal);
    };

    React.useEffect(() => {
        !isDropdownMenuActive && filter(searchedDesignation);
    }, [isDropdownMenuActive, searchedDesignation, filter]);
    return (
        <Dropdown
            className="d-flex align-items-center justify-content-around  "
            style={{ background: "#F9F9F9", height: "50px" }}
            isOpen={isDropdownMenuActive}
            toggle={() => {}}
        >
            {checkstepItem &&
                ((workflow[globalIndex!]?.type === WORKFLOW_LIST.CATEGORIES &&
                    (globalIndex === workflow.length - 1 ||
                        multipleWorkflow.length > 0)) ||
                    (workflow[globalIndex!]?.type === WORKFLOW_LIST.STEPS &&
                        !IsBasicCompositionStep)) && (
                    <div
                        className={
                            ETKType === "3" &&
                            !cardData?.items?.[
                                globalWorkflow[globalIndex!]?.uuid
                            ]?.isRedirect &&
                            (globalWorkflow[globalIndex!]?.type ===
                                WORKFLOW_LIST.STEPS ||
                                isModal)
                                ? " disabledDiv__CLZ"
                                : ""
                        }
                    >
                        <input
                            type="checkbox"
                            id="option"
                            checked={
                                Object.keys(workflow[globalIndex!]?.data)
                                    .length > 0 &&
                                Object.keys(workflow[globalIndex!]?.data)
                                    .length ===
                                    workflow[globalIndex!]?.multipleStep
                                        ?.listeUuidItem.length
                            }
                            onChange={() => {
                                isModal
                                    ? selectedAllProductsModal()
                                    : selectedAllProducts();
                            }}
                            style={{ height: "17px", width: "17px" }}
                        />
                    </div>
                )}
            <DropdownToggle data-toggle="dropdown" tag="span">
                <StyledSearchInput
                    value={searchedDesignation}
                    placeholder={t("Search")}
                    onChange={(e) => {
                        setSearchedDesignation(e.target.value);
                    }}
                    style={{
                        background: "white",
                        height: "max-content",
                    }}
                />
            </DropdownToggle>
            <DropdownMenu
                style={{
                    cursor: "auto",
                    border: "1px solid #E8E8E8",
                    borderRadius: "6px",
                    boxShadow: "0px 3px 6px #000000BD",
                }}
                className="p-2"
            >
                <ItemSelectionDropdown
                    searchedDesignation={searchedDesignation}
                />
            </DropdownMenu>

            <StyledIconButton
                onClick={() => {
                    setIsDropdownMenuActive((prevState) => !prevState);
                    setSearchedDesignation("");
                }}
                icon="SearchIcon"
                style={{
                    border: "1px solid #C6C6C6",
                    borderRadius: "6px",
                    height: "30px",
                    visibility: "hidden",
                }}
            >
                <SearchIcon height={15} width={15} />
            </StyledIconButton>
        </Dropdown>
    );
}
