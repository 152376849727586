import { ItemIcon } from "@aureskonnect/react-ui";

type StepItemPropsType = {
    item: StepItemWithIuud;
    selectedStepItems: StepItemWithIuud[];
    addSelectedItem: Function;
    deleteSelectedItem: Function;
};

export default function StepItem({
    item,
    selectedStepItems,
    addSelectedItem,
    deleteSelectedItem,
}: StepItemPropsType) {
    const isCurrentItemSelected = selectedStepItems.some(
        (selectedStepItem) => selectedStepItem.itemIuud === item.itemIuud
    );
    return (
        <div className="d-flex  justify-content-between my-1">
            <div>
                <ItemIcon
                    width={20}
                    height={20}
                    fill="#096595"
                    className="mr-2"
                    style={{
                        borderRadius: "4px",
                    }}
                />
                {item.title}
            </div>
            <div>
                <input
                    type="checkbox"
                    className="ml-3"
                    id={item.title}
                    checked={isCurrentItemSelected}
                    onChange={() => {
                        isCurrentItemSelected
                            ? deleteSelectedItem(item)
                            : addSelectedItem(item);
                    }}
                />
            </div>
        </div>
    );
}
