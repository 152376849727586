import React from "react";

import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { RootState } from "@store/index";
import { PageTitle } from "@components/index";
import {
    CommonMenu,
    DynamicTableWrapper,
    LoadingDataAnimation,
} from "@components/Common";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import WorkFlowGlobalV2 from "./WorkFlowGlobalV2";
import { OverrideWorkflowElement } from "./OverrideWorkflowElement";

import "@components/Common/CardMenu/Cartestyle.css";

export default function WorkFlow() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [initialData, setInitialData] = React.useState<any>();
    const [typeElement, setTypeElement] = React.useState("category");
    const [othersData, setOthersData] = React.useState({
        itemOthersData: null,
        categoryOthersData: null,
        stepOthersData: null,
    });
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardUuid = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const othersDataUrl = `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardUuid}/workflows/others-data`;

    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );

    React.useEffect(() => {
        axios.get(othersDataUrl).then((response) => {
            setOthersData(response.data.data);
        });
    }, [othersDataUrl]);

    if (isLoading) return <LoadingDataAnimation />;

    return (
        <React.Fragment>
            <PageTitle title="WorkFlow" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },
                        { item: t("workflow".toUpperCase()), link: "#" },
                    ]}
                />
                <Container fluid>
                    <div className="d-flex justify-content-between ">
                        <div style={{ width: "100%" }}>
                            <HeaderComponent
                                title={t(
                                    "The workflow represents the process of taking an order for the final customer. It will be created by linking the different elements together with the different steps."
                                )}
                            />
                        </div>

                        <div className="my-3 mx-1">
                            <CommonMenu title="Workflow" />
                        </div>
                    </div>

                    <DynamicTableWrapper
                        TableComponent={
                            <WorkFlowGlobalV2
                                setTypeElement={setTypeElement}
                                othersData={othersData}
                                setInitialData={setInitialData}
                            />
                        }
                        ActionColumnComponent={
                            <OverrideWorkflowElement
                                othersData={othersData}
                                typeElement={typeElement}
                                setIsLoading={setIsLoading}
                                initialData={initialData}
                            />
                        }
                        showFormsInModal
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
