import React from "react";

import axios from "axios";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

import {
    SuccessToast,
    ErrorToast,
    DominantColor,
    DynamicTableWrapperContext,
} from "@components/Common/";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type duplicateModalPropsType = {
    isShow: boolean;
    setIsShow: Function;
    setIsLoading: Function;
};

export default function DuplicateTagModal({
    isShow,
    setIsShow,
    setIsLoading,
}: duplicateModalPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const toggleShow = (): void => setIsShow(!isShow);

    const handleOnDuplicateTag = (): void => {
        if (validateOnclick) {
            setValidateOnclick(false);
            const CardIuud = JSON.parse(
                localStorage.getItem("selectedCard") || "{}"
            ).iuud;
            try {
                axios
                    .post(
                        `${process.env.REACT_APP_ADB_API_URL}/tag/${schemaAdbFranchise}/${CardIuud}/${dynamicTableWrapperContext?.rowData?.iuud}/tagduplicated`
                    )
                    .then((data: any): void => {
                        if (data.data.error === true) {
                            ErrorToast(
                                t("Designation already exists try another!")
                            );
                        } else {
                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            setIsLoading(false);
                            SuccessToast(t("Tag duplicated successfully"));
                        }
                        setIsShow(!isShow);
                        setValidateOnclick(true);
                    })
                    .catch((): void => {
                        ErrorToast(t("There is a mistake!"));
                    });
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    return (
        <Modal centered isOpen={isShow}>
            <ModalHeader toggle={toggleShow}>
                <StyledH2 className="text-uppercase">
                    <div className="d-flex">
                        {t("Duplicate Tag")} <span className="mx-1">:</span>
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={dynamicTableWrapperContext?.rowData?.title}
                            id="archiver"
                        />
                        <div className="modal_clz" id="archiver">
                            {dynamicTableWrapperContext?.rowData?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t("Do you want to duplicate the tag")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleShow}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleOnDuplicateTag}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
