import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import PortalLogoSvgIcon from "@components/Common/SvgIcons/PortalLogoSvgIcon";

import PageTitle from "@components/Common/PageTitle";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import { BloqArchiveAccountLogin } from "./BloqArchiveAccountLogin";

import ForgotPasswordModal from "./ForgotPasswordModal";

import { decryptWord } from "./Crypto";

import "./Login.css";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);

    const [messageError, setMessageError] = React.useState<string>(
        // eslint-disable-next-line sonarjs/no-duplicate-string
        t("This field is required")
    );
    const [messagePwdError, setMessagePwdError] = React.useState<string>(
        t("This field is required")
    );

    const [inputEmail, setInputEmail] = React.useState<string>("");
    const [inputPwd, setInputPwd] = React.useState<string>("");

    const [email, setEmail] = React.useState<string>("");
    const [passWord, setPassWord] = React.useState<string>("");

    const [isActiveUserModalOpned, setIsActiveUserModalOpned] =
        React.useState<boolean>(false);
    const [msgTitleModal, setMsgTitleModal] = React.useState<string>("");
    const [isForgotPasswordClick, setIsForgotPasswordClick] =
        React.useState<boolean>(false);

    const [, setIsFocusInInputs] = React.useState<boolean>(false);

    const [isInvalidPassword, setIsInvalidPassword] =
        React.useState<boolean>(false);
    React.useEffect(() => {
        if (inputPwd.length === 0 && !isInvalidPassword) {
            setMessagePwdError(t("This field is required"));
        } else {
            setMessagePwdError(t("Incorrect password"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputPwd]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleValidSubmit() {
        setIsFocusInInputs(false);

        if (!isForgotPasswordClick) {
            const urlVerifyUserAccount: string = `${process.env.REACT_APP_Access_API_URL}/customerAccount`;

            await fetch(urlVerifyUserAccount, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `email=${email}&password=${passWord}`,
                method: "POST",
            })
                .then((result) => result.json())
                .then((response: any) => {
                    if (!response.error) {
                        if (response.data.etataccount === "Activer") {
                            localStorage.setItem(
                                "Uid_Entitie_User",
                                response.data.uidentitie
                            );
                            localStorage.setItem(
                                "firstName",
                                response.data.firstname
                            );
                            localStorage.setItem(
                                "lastName",
                                response.data.name
                            );
                            setLoading(false);
                            navigate("/SelectAccount");
                        } else {
                            setIsActiveUserModalOpned(true);

                            if (response.data.accounttype === "ADMIN") {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter notre service support."
                                );
                            } else {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter votre administrateur."
                                );
                            }
                        }
                    } else if (response.data?.email) {
                        const decryptEmail = decryptWord(response.data.email);
                        const decryptPwd = decryptWord(response.data.pwd);
                        if (email === decryptEmail && passWord !== decryptPwd) {
                            // setMessagePwdError(t("Incorrect password"));
                            setIsInvalidPassword(true);
                            setInputPwd("");
                            // setMessagePwdError(t("This field is required"));
                        } else if (
                            email !== decryptEmail &&
                            passWord === decryptPwd
                        ) {
                            setMessageError(
                                t(
                                    "Compte introuvable. Vérifiez votre adresse e-mail."
                                )
                            );
                            setInputEmail("");
                        }
                    } else {
                        setMessageError(
                            t(
                                "Compte introuvable. Vérifiez votre adresse e-mail."
                            )
                        );
                        setInputEmail("");
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(true);

                    return false;
                });
        }
    }

    function toggleOpeningForgotPasswordModal() {
        setIsModalOpened(!isModalOpened);
    }

    function handleCreateAccountButtonOnClickEvent() {
        navigate("registration");
    }

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null) {
            navigate("/SelectAccount");
        }
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={t("Login")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />
                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden login-card w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Login")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            {/* <div> */}
                            <AvForm
                                style={{ position: "relative" }}
                                className="form-horizontal"
                                onValidSubmit={(e: any, v: any) => {
                                    setEmail(v.email);
                                    setPassWord(v.password);

                                    if (v.password === "" || v.email === "") {
                                        if (v.password === "") {
                                            setMessagePwdError(
                                                t("This field is required")
                                            );
                                        }
                                        if (v.email === "") {
                                            setMessageError(
                                                t("This field is required")
                                            );
                                        }
                                    } else {
                                        handleValidSubmit();
                                    }
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") e.preventDefault();
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <StyledLabel htmlFor="email">
                                        {t("Username")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="form-control__clz"
                                        placeholder={t("Username")}
                                        onFocus={() => setIsFocusInInputs(true)}
                                        onChange={(e: any) => {
                                            setEmail(e.target.value);
                                            setInputEmail(e.target.value);
                                        }}
                                        value={inputEmail}
                                        validate={{
                                            email: {
                                                value: true,
                                                errorMessage: messageError,
                                            },
                                            required: {
                                                value: true,
                                                errorMessage: messageError,
                                            },
                                        }}
                                        onBlur={(inputText: any) => {
                                            const forbiddenCharactersRegex =
                                                // eslint-disable-next-line prefer-regex-literals
                                                new RegExp(
                                                    /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/
                                                );

                                            if (
                                                !inputText.target.value.match(
                                                    forbiddenCharactersRegex
                                                )
                                            ) {
                                                setMessageError(
                                                    t(
                                                        "Please enter a valid username in the format name@example.com"
                                                    )
                                                );
                                            }

                                            if (
                                                inputText.target.value
                                                    .length === 0
                                            ) {
                                                setMessageError(
                                                    t("This field is required")
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                <div className="password-toggle__clz">
                                    {isPasswordDisplayed ? (
                                        <HidePasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <ShowPasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )}
                                </div>

                                <div className="mb-4">
                                    <StyledLabel htmlFor="password">
                                        {t("Password")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        id="password"
                                        name="password"
                                        type={
                                            isPasswordDisplayed
                                                ? "text"
                                                : "password"
                                        }
                                        onFocus={() => setIsFocusInInputs(true)}
                                        value={inputPwd}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: messagePwdError,
                                            },
                                        }}
                                        onChange={(e: any) => {
                                            setPassWord(e.target.value);
                                            setInputPwd(e.target.value);
                                        }}
                                        onBlur={(inputText: any) => {
                                            if (
                                                inputText.target.value
                                                    .length === 0
                                            ) {
                                                setMessageError(
                                                    t("This field is required")
                                                );
                                            }
                                        }}
                                        placeholder={t("Password")}
                                        className="form-control__clz"
                                    />
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="d-none custom-control custom-checkbox mb-5">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customControlInline"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customControlInline"
                                        >
                                            {t("Remember me")}
                                        </label>
                                    </div>
                                    <StyledButton
                                        variant="link"
                                        className="mb-5"
                                        style={{
                                            textDecoration: "underline",
                                            color: "#495057",
                                            background: "transparent",
                                            position: "fixed !important",
                                        }}
                                        onClick={() => {
                                            setIsForgotPasswordClick(true);

                                            toggleOpeningForgotPasswordModal();
                                        }}
                                    >
                                        {t("Forgot your password?")}
                                    </StyledButton>
                                </div>

                                <div className="d-flex justify-content-center submit-button__clz ">
                                    <StyledButton
                                        variant="primary"
                                        rounded
                                        outline={false}
                                        className="text-uppercase"
                                        type="submit"
                                    >
                                        {t("Login")}
                                    </StyledButton>
                                </div>

                                <div className="d-none">
                                    <StyledButton
                                        variant="link"
                                        style={{
                                            textDecoration: "underline",
                                            color: "#495057",
                                            background: "transparent",
                                        }}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onClick={
                                            handleCreateAccountButtonOnClickEvent
                                        }
                                    >
                                        {t("Create new account")}
                                    </StyledButton>
                                </div>
                            </AvForm>
                            {/* </div> */}
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <ForgotPasswordModal
                isModalOpened={isModalOpened}
                // eslint-disable-next-line react/jsx-no-bind
                toggleOpeningForgotPasswordModal={
                    toggleOpeningForgotPasswordModal
                }
                setIsForgotPasswordClick={setIsForgotPasswordClick}
                initEmail={email}
            />
            <BloqArchiveAccountLogin
                isModalOpened={isActiveUserModalOpned}
                setIsModalOpened={setIsActiveUserModalOpned}
                msgTitleModal={msgTitleModal}
            />
            ;
        </React.Fragment>
    );
}
