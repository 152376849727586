import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    MenuIcon,
    SettingsIcon,
    BigBrochureIcon,
} from "@aureskonnect/react-ui";

import { RootState } from "@store/.";
import { DominantColor } from "@components/Common";
import { StyledSidebarMenu } from "./StyledSidebarMenu";

type SidebarProps = {
    brand: string;
};
export default function Sidebar({ brand }: SidebarProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const collapsed = useSelector(
        (state: RootState) => state.layoutSlice.collapsed
    );

    const navigate = useNavigate();
    const redirectionLink =
        Number(localStorage.getItem("selectedAccounts")) > 1
            ? "selectaccount"
            : "home";

    return (
        <StyledSidebarMenu
            brand={
                <span
                    onClick={() => navigate(redirectionLink)}
                    style={{ cursor: "pointer" }}
                    role="button"
                    tabIndex={0}
                >
                    {collapsed ? brand.slice(0, 2) : brand}
                </span>
            }
            collapsed={collapsed}
            backgroundColor={dominantColor}
            menuItems={[
                {
                    icon: <BigBrochureIcon width={25} height={25} />,
                    link: "home",
                    menuItem: t("Homepage"),
                },
                {
                    icon: <BigBrochureIcon width={25} height={25} />,
                    link: "cards",
                    menuItem: t("List of cards"),
                },
                {
                    icon: <MenuIcon width={25} height={25} />,
                    link: "",
                    menuItem: t("Catalog"),
                    subMenu: [
                        {
                            link: "categories",
                            menuItem: t("Categories"),
                        },
                        {
                            link: "items",
                            menuItem: t("Items"),
                        },
                        {
                            link: "steps",
                            menuItem: t("Steps"),
                        },
                        // {
                        //     link: "ingredients",
                        //     menuItem: t("Ingredients"),
                        // },
                        {
                            link: "options",
                            menuItem: t("Options"),
                        },
                        {
                            link: "tags",
                            menuItem: t("Tags"),
                        },

                        {
                            link: "allergens",
                            menuItem: t("Allergens"),
                        },
                        {
                            link: "workflow",
                            menuItem: t("Workflow"),
                        },
                    ],
                },
                // {
                //     icon: <ModuleIcon width={25} height={25} />,
                //     link: "#",
                //     menuItem: t("Modules"),
                //     subMenu: [
                //         {
                //             link: "#",
                //             menuItem: t("Formulas"),
                //         },
                //         {
                //             link: "#",
                //             menuItem: t("Offers"),
                //         },
                //         {
                //             link: "#",
                //             menuItem: t("Additional sales"),
                //         },
                //     ],
                // },
                {
                    icon: <SettingsIcon height={25} width={25} />,
                    link: "",
                    menuItem: t("Configuration"),
                    subMenu: [
                        {
                            link: "#",
                            menuItem: t("Type of elements"),
                            subMenu: [
                                {
                                    link: "Configuration/Categories",
                                    menuItem: t("Category"),
                                },
                                {
                                    link: "Configuration/Articles",
                                    menuItem: t("Item"),
                                },
                            ],
                        },
                        {
                            link: "Configuration/Languages",
                            menuItem: t("Languages"),
                        },
                        {
                            link: "Configuration/TVA",
                            menuItem: "TVA",
                        },
                        {
                            link: "Configuration/Sale",
                            menuItem: t("Mode of sale"),
                        },
                        // {
                        //     link: "#",
                        //     menuItem: t("Allergens"),
                        // },
                        // {
                        //     link: "#",
                        //     menuItem: t("Measurement units"),
                        // },
                        // {
                        //     link: "#",
                        //     menuItem: "TVA",
                        // },
                        // {
                        //     link: "#",
                        //     menuItem: t("Sales support"),
                        // },
                        // {
                        //     link: "#",
                        //     menuItem: t("Sales modes"),
                        // },
                        // {
                        //     link: "#",
                        //     menuItem: t("Languages"),
                        // },
                    ],
                },
            ]}
        />
    );
}
