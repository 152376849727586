import { useLocation, useNavigate } from "react-router-dom";
import {
    HeaderWrapper,
    MenuBurgerIcon,
    StyledIconButton,
    StyledH1,
} from "@aureskonnect/react-ui";

import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "@components/CommonForBoth/TopbarDropdown/ProfileMenu";
import HomeIcon from "@components/Common/SvgIcons/HomeIcon";
import ShopIconHeader from "@components/Common/SvgIcons/ShoIconHeader";

type HeaderPropsType = {
    handleSideBarCollapse: any;
    brand: string;
    showBrand: boolean;
    // eslint-disable-next-line
    customCss?: React.CSSProperties;
};
type BrandPropsType = {
    brand: string;
    redirectionLink: string;
};
function Brand({ brand, redirectionLink }: BrandPropsType) {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(redirectionLink)}
            style={{
                cursor: "pointer",
            }}
            role="button"
            tabIndex={0}
        >
            <div className="navbar-brand-box d-none d-sm-flex">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand}
                </StyledH1>
            </div>
            <div className="navbar-brand-box d-block d-sm-none">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand.slice(0, 2)}
                </StyledH1>
            </div>
        </div>
    );
}

export default function Header({
    handleSideBarCollapse,
    brand,
    showBrand,
}: HeaderPropsType): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const redirectionLink =
        Number(localStorage.getItem("selectedAccounts")) > 1
            ? "selectaccount"
            : "home";
    return (
        <HeaderWrapper
            style={{
                gridArea: "header",
                backgroundColor: "#ffffff",
            }}
        >
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {(location.pathname.toLowerCase() !== "/selectaccount" &&
                        showBrand) ||
                    location.pathname.toLowerCase() === "/selectaccount" ? (
                        <Brand
                            brand={brand}
                            redirectionLink={redirectionLink}
                        />
                    ) : null}

                    {location.pathname.toLowerCase() !== "/selectaccount" && (
                        <StyledIconButton
                            className="m-0 border-none__clz"
                            icon="CloudIcon"
                        >
                            <MenuBurgerIcon
                                height={20}
                                width={20}
                                onClick={handleSideBarCollapse}
                            />
                        </StyledIconButton>
                    )}
                </div>
                <div className="d-flex align-items-center">
                    <LanguageDropdown />
                    <div className="m-2">
                        <StyledIconButton
                            onClick={() => {
                                window.location.replace(
                                    process.env.REACT_APP_PORTAIL_URL ??
                                        "https://demo-portail.aureskonnect.com"
                                );
                            }}
                        >
                            <HomeIcon height={25} width={25} />
                        </StyledIconButton>
                    </div>
                    <div className="m-2">
                        <StyledIconButton
                            onClick={() => navigate("/selectaccount")}
                            className="m-2"
                        >
                            <ShopIconHeader height={25} width={25} />
                        </StyledIconButton>
                    </div>
                    <ProfileMenu />
                </div>
            </div>
        </HeaderWrapper>
    );
}
