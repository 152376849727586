import React, { useState } from "react";

import {
    StyledModal,
    StyledH2,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { FormGroup, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "antd";

import { DominantColor } from "@components/Common/.";
import { VatRateContext } from "@pages/Catalogue/Items/addItem/Price";
import {
    filterKeysToUpdate,
    multipleUpdateAdvanced,
} from "@helpers/advancedPrice";
import UpdatePrice from "./UpdatePrice";
import IncreasePrice from "./IncreasePrice";
import DecreasePrice from "./DecreasePrice";

import "../Price.css";

type MultipleEditPricePropsType = {
    selectedRows: any;
    filteredOptions: string[];
    filteredSaleModes: string[];
    filteredSaleSupports: string[];
};

export default function MultipleEditPrice({
    selectedRows,
    filteredOptions,
    filteredSaleModes,
    filteredSaleSupports,
}: MultipleEditPricePropsType) {
    const { t } = useTranslation();
    const vatRateContext = React.useContext(VatRateContext);

    const [active, setActive] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState<
        "newPrice" | "increase" | "decrease"
    >("newPrice");

    const [amount, setAmount] = React.useState<number | "">("");
    const [percentage, setPercentage] = React.useState<number | "">("");

    const rowKeysToUpdate: string[] = filterKeysToUpdate(
        selectedRows,
        filteredSaleModes,
        filteredSaleSupports,
        filteredOptions
    );

    return (
        <div>
            <Button
                className=" multipleEdit__button"
                onClick={() => {
                    setActive(true);
                    setAmount("");
                    setPercentage("");
                    setSelectedOption("newPrice");
                }}
                disabled={selectedRows.length < 2}
            >
                {t("Modification Multiple")}
            </Button>

            <StyledModal size="lg" isOpen={active} centered>
                <ModalHeader
                    toggle={() => {
                        setActive(false);
                    }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("Multiple edit")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <fieldset className="border rounded p-3 m-auto position-relative">
                        <legend className="w-auto px-2">
                            <StyledLabel htmlFor="text">
                                {t("Select an option")}
                            </StyledLabel>
                        </legend>
                        <FormGroup check className="mb-4">
                            <UpdatePrice
                                selectedOption={selectedOption}
                                amount={amount}
                                setAmount={setAmount}
                                setSelectedOption={setSelectedOption}
                            />
                            <IncreasePrice
                                selectedOption={selectedOption}
                                amount={amount}
                                percentage={percentage}
                                setPercentage={setPercentage}
                                setAmount={setAmount}
                                setSelectedOption={setSelectedOption}
                            />
                            <DecreasePrice
                                selectedOption={selectedOption}
                                amount={amount}
                                percentage={percentage}
                                setPercentage={setPercentage}
                                setAmount={setAmount}
                                setSelectedOption={setSelectedOption}
                            />
                        </FormGroup>
                        <div
                            className="position-absolute w-100 border-top d-flex align-items-center justify-content-end pr-4"
                            style={{
                                bottom: "0px",
                                left: "0px",
                                backgroundColor: "#C6C6C6",
                                height: "55px",
                            }}
                        >
                            <b>{t("New price TTC in euros")}</b>
                        </div>
                    </fieldset>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div>
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={() => {
                                    setActive(false);
                                    setAmount("");
                                    setPercentage("");
                                }}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                        <div className="ml-3">
                            <StyledButton
                                rounded
                                variant={DominantColor}
                                disabled={amount === "" && percentage === ""}
                                onClick={() => {
                                    let updatedAdvancedPrice: Record<
                                        string,
                                        Price
                                    > = JSON.parse(
                                        JSON.stringify(
                                            vatRateContext.advancedPriceCopy
                                        )
                                    );
                                    updatedAdvancedPrice =
                                        multipleUpdateAdvanced(
                                            selectedOption,
                                            amount,
                                            vatRateContext.itemPrice
                                                .saleModeVatRates,
                                            percentage,
                                            rowKeysToUpdate,
                                            updatedAdvancedPrice
                                        );

                                    vatRateContext.setAdvancedPriceCopy(
                                        updatedAdvancedPrice
                                    );
                                    setActive(false);
                                    setAmount("");
                                    setPercentage("");
                                    setSelectedOption("newPrice");
                                }}
                            >
                                {t("Validate")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
