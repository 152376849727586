/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-param-reassign */
import React from "react";

import { useTranslation } from "react-i18next";
import {
    ExclamationIcon,
    MinusIcon,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import DropdownDescriptionModal from "./DropdownDescriptionModal";

import "./description.css";

type Description = {
    impression: LanguageType[];
    setImpression: React.Dispatch<React.SetStateAction<LanguageType[]>>;
    sellingSupport: sellingSupportType[];
    setSellingSupport: React.Dispatch<
        React.SetStateAction<sellingSupportType[]>
    >;
    DefaultValueName: string;
};

export default function DescriptionTable({
    impression,
    setImpression,
    sellingSupport,
    setSellingSupport,
    DefaultValueName,
}: Description) {
    const { t } = useTranslation();
    const [title, setTitle] = React.useState("");
    const [support, setSupport] = React.useState("");
    const [elementSelect, setElementSelect] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [inputValueIsConfirmed, setInputValueIsConfirmed] =
        React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const forbiddenCharactersRegex = new RegExp(/^[^\\|\"@^#?!²``°<>§;]+$/);
    const i18nextLng: string | null = localStorage.getItem("i18nextLng");

    const defaultLanguage: string = "Francais";
    function handleChangeHiddenSupport(NameSupport: string) {
        const index = sellingSupport.findIndex(
            (element) => element.sellingSupport === NameSupport
        );

        if (index !== -1) {
            sellingSupport[index].visibility = false;
        }
        setSellingSupport(sellingSupport);

        const filteredArrayLength = sellingSupport.filter(
            (item): boolean | undefined => item.visibility
        ).length;
        if (index !== -1 && filteredArrayLength === 0) {
            sellingSupport[index].visibility = true;
        }

        impression?.map((element: LanguageType): sellingSupportType[] => {
            const NameLangue: string = element.designation;

            sellingSupport[index].Langue.map(
                (item: LanguageType): LanguageType => {
                    if (item.designation === NameLangue) {
                        item.visibility = element.visibility;
                    }
                    return item;
                }
            );
            return sellingSupport;
        });
        setSellingSupport(sellingSupport);
    }

    function handlechangeInputSupport(
        nameSupport: string,
        value: string,
        nameLanguage: string
    ) {
        if (value) {
            if (value.match(forbiddenCharactersRegex)) {
                setInputValueIsConfirmed(true);
                setInputValue(true);
            } else {
                setElementSelect(`${nameSupport}${nameLanguage}`);
                setInputValueIsConfirmed(false);
                setInputValue(true);
            }
        } else {
            setInputValue(false);
        }
        setCounter(2000 - value.length);
        setSellingSupport((prevState: any) => {
            const newState = [...prevState];
            const indexSupport = sellingSupport.findIndex(
                (element: any): boolean =>
                    element.sellingSupport === nameSupport
            );
            const indexLanguage = sellingSupport[indexSupport].Langue.findIndex(
                (element: any): boolean => element.designation === nameLanguage
            );
            function NewLangue(): {
                designation: string;
                value: string;
                visibility?: boolean | undefined;
            } {
                const selectLanguage = {
                    ...sellingSupport[indexSupport].Langue[indexLanguage],
                };
                selectLanguage.value = value;
                return selectLanguage;
            }

            const updatedLangue = NewLangue();
            const newArr = [...newState[indexSupport].Langue];
            newArr[indexLanguage] = updatedLangue;
            newState[indexSupport] = {
                ...newState[indexSupport],
                Langue: newArr,
            };

            return newState;
        });
    }
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        setTitle(
            `${t(
                "List of forbidden characters"
                // eslint-disable-next-line
            )}   | \  " @ ^  # ?!   /  ²  ° < > § ;
                  `
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18nextLng, title]);

    return (
        <>
            <table className="table table-bordered mt-3">
                <thead
                    style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    <tr>
                        <th
                            scope="col"
                            style={{
                                width: "170px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                            }}
                        >
                            <div className="ml-5">Langues</div>
                            <hr className="Line__CLZ" />

                            <div style={{ marginLeft: "-35px" }}>
                                Supports de vente
                            </div>
                        </th>
                        {impression
                            .filter(
                                (element): boolean | undefined =>
                                    element.visibility
                            )
                            .map(
                                (element: LanguageType): JSX.Element => (
                                    <th
                                        scope="col"
                                        className="align-middle"
                                        key={`${element.designation}`}
                                    >
                                        {element.designation}
                                    </th>
                                )
                            )}
                        <th
                            scope="col"
                            className="align-middle"
                            style={{ width: "15px" }}
                        >
                            <DropdownDescriptionModal
                                impression={impression}
                                setImpression={setImpression}
                                sellingSupport={sellingSupport}
                                setSellingSupport={setSellingSupport}
                                support={support}
                                setSupport={setSupport}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody
                    className="text-center"
                    style={{ fontSize: 15, fontWeight: "bold" }}
                >
                    {sellingSupport
                        .filter((item): boolean | undefined => item.visibility)
                        .map((item: any, index: number) => (
                            <tr key={`${item.sellingSupport}+Row`}>
                                <td
                                    className="align-middle pl-2"
                                    style={{ textAlign: "start" }}
                                    key={`$${item.sellingSupport}+Column`}
                                >
                                    {item.sellingSupport}
                                </td>
                                {item.Langue.filter(
                                    (element: any) => element.visibility
                                ).map((element: LanguageType, id: number) => (
                                    <td key={`+${item.sellingSupport}`}>
                                        <div
                                            className="d-flex justify-content-start mt-1 mb-1"
                                            style={{ textAlign: "start" }}
                                        >
                                            <StyledTextInput
                                                maxlength={2000}
                                                placeholder={t("Enter")}
                                                // onKeyDown={(event: any) => {
                                                //     setFocus(id, index, event);
                                                // }}
                                                id={`Advanced description ${id}+${index}`}
                                                name={`${item.sellingSupport}-${element.designation}`}
                                                value={element.value}
                                                defaultValue={
                                                    element.designation ===
                                                        defaultLanguage &&
                                                    element.value === ""
                                                        ? DefaultValueName
                                                        : element.value
                                                }
                                                onChange={(e: any) => {
                                                    handlechangeInputSupport(
                                                        item.sellingSupport,
                                                        e.target.value,
                                                        element.designation
                                                    );
                                                }}
                                                style={{
                                                    backgroundImage: "unset",
                                                    border: "0px",
                                                    borderColor: "inherit",
                                                    webkitBoxShadow: "none",
                                                    boxShadow: "none",
                                                    textAlign: "center",
                                                }}
                                                type="textarea"
                                                validate={{
                                                    required: {
                                                        value: false,
                                                        errorMessage: t(
                                                            "This field is required"
                                                        ),
                                                    },
                                                    pattern: {
                                                        value: forbiddenCharactersRegex,
                                                        errorMessage: t(
                                                            "Forbidden characters"
                                                        ),
                                                    },
                                                }}
                                            />
                                            {!inputValueIsConfirmed &&
                                                inputValue &&
                                                elementSelect ===
                                                    `${item.sellingSupport}${element.designation}` && (
                                                    <Tooltip
                                                        title={title}
                                                        arrow
                                                        followCursor
                                                        placement="bottom-end"
                                                        TransitionComponent={
                                                            Zoom
                                                        }
                                                        TransitionProps={{
                                                            timeout: 600,
                                                        }}
                                                        onClose={
                                                            handleTooltipClose
                                                        }
                                                        open={open}
                                                    >
                                                        <h5>
                                                            <ExclamationIcon
                                                                fill="red"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                height={15}
                                                                width={15}
                                                                onClick={
                                                                    handleTooltipOpen
                                                                }
                                                            />
                                                        </h5>
                                                    </Tooltip>
                                                )}
                                        </div>
                                    </td>
                                ))}
                                <td
                                    className="align-middle"
                                    key={`+${item.sellingSupport}+Choice`}
                                >
                                    <MinusIcon
                                        height={25}
                                        width={25}
                                        onClick={() => {
                                            handleChangeHiddenSupport(
                                                item.sellingSupport
                                            );
                                            setSupport(item.sellingSupport);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-end ">
                {inputValue ? (
                    <small>
                        {t("Maximum number of characters")}:{counter}
                        /2000
                    </small>
                ) : (
                    <small>{t("Maximum number of characters 2000")}</small>
                )}
            </div>
        </>
    );
}
