import React from "react";

import { useSelector } from "react-redux";
import { ModalBody, ModalHeader } from "reactstrap";
import { StyledModal, StyledH2 } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import DescriptionTable from "./DescriptionTable";
import DescriptionModalFooter from "./DescriptionModalFooter";

type DescriptionModalProps = {
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    description: DisplayNameType;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    DefaultValueName: string;
};
export default function DescriptionModal({
    description,
    dispatchItem,
    setIsOpen,
    isOpen,
    DefaultValueName,
}: DescriptionModalProps) {
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const [impression, setImpression] = React.useState<LanguageType[]>(
        description.Default.impression!
    );

    const [sellingSupport, setSellingSupport] = React.useState<
        sellingSupportType[]
    >(description.Default.salesSupport!);

    React.useEffect(() => {
        if (sellingSupport.length === 0) {
            (async () => {
                await fetch("/fakeData/sellingSupport.json")
                    .then((res) => res.json())
                    .then((data) => setSellingSupport(data));
            })();
        }
        if (impression.length === 0) {
            (async () => {
                await fetch("/fakeData/impression.json")
                    .then((res) => res.json())
                    .then((data) => setImpression(data));
            })();
        }
    }, [impression.length, sellingSupport.length]);

    return (
        <StyledModal size="xl" isOpen={isOpen}>
            <ModalHeader
                toggle={() => {
                    setIsOpen(false);
                }}
            >
                <StyledH2 className="text-uppercase">
                    DESCRIPTION AVANCÉE
                </StyledH2>
            </ModalHeader>
            <ModalBody
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : {
                              maxHeight: "750px",
                              overflowY: "auto",
                          }
                }
            >
                <DescriptionTable
                    impression={impression}
                    setImpression={setImpression}
                    sellingSupport={sellingSupport}
                    setSellingSupport={setSellingSupport}
                    DefaultValueName={DefaultValueName}
                />
            </ModalBody>
            <DescriptionModalFooter
                setActive={setIsOpen}
                impression={impression}
                sellingSupport={sellingSupport}
                description={description}
                dispatchItem={dispatchItem}
            />
        </StyledModal>
    );
}
