import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import {
    ValuesContainer,
    Value,
    XButton,
} from "@components/Common/StyledComponent";
import CreatableSelect from "react-select/creatable";

type LinkedTagsPropsType = {
    tagForm: string[];
    dispatch: React.Dispatch<ReducerActionType>;
    setSelectedLinkTagsIuud: Function;
    allTags: any[];
};
export default function LinkedTags({
    tagForm,
    dispatch,
    setSelectedLinkTagsIuud,
    allTags,
}: LinkedTagsPropsType): JSX.Element {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );
    const [tag, setTag] = React.useState<string[]>(allTags);

    const tagsOptions: any = allTags.map((tag: any): any => {
        return {
            label: tag.label,
            value: tag.value,
        };
    });
    let updatedtags: string[] = [...tagForm];
    const handleSelectLinkTagsChange = (selectedValue: any): void => {
        // eslint-disable-next-line no-underscore-dangle
        if (selectedValue.__isNew__ === true) {
            selectedValue.value = `isNew|${selectedValue.value}`;

            const NewTag: { label: string; value: string } = {
                value: selectedValue.value,
                label: selectedValue.label,
            };
            const index = tag.findIndex(
                (e: any) => e.label === selectedValue?.label
            );
            if (index === -1) {
                if (NewTag.label.trim() !== "") {
                    setTag((prev: any) => [...prev, NewTag]);
                    updatedtags = [...tagForm, selectedValue?.value];
                }
            }
        } else {
            updatedtags = [...tagForm, selectedValue?.value];
        }

        setSelectedLinkTagsIuud(updatedtags);

        dispatch({
            type: ACTIONS.LINK_TAGS,
            payload: updatedtags,
        });
        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.LINK_TAGS));
    };
    const handleXButton = (e: React.MouseEvent<HTMLElement>, iuud: string) => {
        e.preventDefault();
        const updatedtags = tagForm.filter(
            (tagIuud): boolean => tagIuud !== iuud
        );
        const indexTag = tag.findIndex((el: any) => el.value === iuud);
        iuud.includes("isNew") && tag.splice(indexTag, 1);
        setSelectedLinkTagsIuud(updatedtags);
        dispatch({
            type: ACTIONS.LINK_TAGS,
            payload: updatedtags,
        });
    };

    const newGroupeTagJsx = (TagGroupInputValue: string) => (
        <span>
            {TagGroupInputValue} ({t("New Tag")})
        </span>
    );

    return (
        <div className="py-2" id="linked tags">
            <StyledLabel htmlFor="tags-link">{t("Tags")}</StyledLabel>

            <CreatableSelect
                id="Step"
                name="Step"
                placeholder={t("Select")}
                formatCreateLabel={newGroupeTagJsx}
                // eslint-disable-next-line react/no-unstable-nested-components
                noOptionsMessage={() => (
                    <React.Fragment>{t("No options")}</React.Fragment>
                )}
                options={
                    tagsOptions.filter(
                        (tag: any): any => !tagForm.includes(tag.value)
                    )
                    // .sort(filterNameWithAlphabeticRank) //
                }
                value={t("Choose")}
                onChange={handleSelectLinkTagsChange}
                isDisabled={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                }
            />
            <div
                className="d-flex flex-wrap "
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : { opacity: "1" }
                }
            >
                {tag.map(
                    ({ value, label }: any) =>
                        tagForm.includes(value) && (
                            <ValuesContainer key={value}>
                                <Value>
                                    {label}
                                    <XButton
                                        onClick={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) => handleXButton(e, value)}
                                    >
                                        ✕
                                    </XButton>
                                </Value>
                            </ValuesContainer>
                        )
                )}
            </div>
        </div>
    );
}
