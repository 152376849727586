/* eslint-disable no-param-reassign */
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { DominantColor } from "@components/Common/";
import { setWorkflow } from "@store/features/workflowSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { checkModifierExist, resetMultipleWorkflow } from "../helpers";

export type WorkFlowFooterModalProps = {
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    contentModal: any;
    setContentModal: React.Dispatch<any>;
};

export default function WorkFlowFooterModal({
    setIsModify,
    isModify,
    setIsOpenStepModal,
    contentModal,
    setContentModal,
}: WorkFlowFooterModalProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const dominantColor = DominantColor();
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );

    let copyModifier =
        multipleWorkflow.length > 0
            ? JSON.parse(
                  JSON.stringify(
                      multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier
                  )
              )
            : JSON.parse(JSON.stringify(modifierWorkflow));
    const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

    const closeModal = () => {
        if (contentModal.length === 1) {
            setIsOpenStepModal(false);
        }
        dispatch(
            setMultipleWorkflow(
                contentModal[contentModal.length - 1].previousState
                    .multipleWorkflow
            )
        );
        dispatch(
            setWorkflowData(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflowData
            )
        );

        dispatch(
            setWorkflow(
                contentModal[contentModal.length - 1].previousState
                    .globalWorkflow
            )
        );

        dispatch(
            setModifier(
                contentModal[contentModal.length - 1].previousState
                    .modifierSlice
            )
        );

        setContentModal(
            contentModal[contentModal.length - 1].previousState.modalWorkflow
        );
        setIsModify(!isModify);
    };
    const ValidateModal = () => {
        if (contentModal.length === 1) {
            if (
                contentModal[contentModal.length - 1].isMultiple &&
                contentModal[contentModal.length - 1].previousUuidStep ===
                    "empty"
            ) {
                addMultipleSalesAddInProduct(); /* multiple product sales add */
            } else if (contentModal[contentModal.length - 1].isMultiple) {
                multipleWorkflow.length > 1
                    ? addMultipleInMultipleFirstModal() /* multiple item with multiple item in step */
                    : addMultipleStepFistModal(); /* multiple item in step */
            }
            setContentModal([]);
            setIsOpenStepModal(false);
        } else {
            if (contentModal[contentModal.length - 1].isMultiple) {
                multipleWorkflow.length > 1
                    ? addMultipleStepModal(
                          true
                      ) /* multiple item with multiple item in step modal */
                    : addMultipleStepModal(
                          false
                      ); /* multiple item in step modal */
            }
            const copyModal = [...contentModal];
            copyModal.splice(copyModal.length - 1, 1);
            setContentModal(copyModal);
        }
        setIsModify(!isModify);
    };

    const insertUuidMultipleModifier = (
        previousModifier: string,
        MD5modifier: string,
        modifierType: string,
        stepObject: any,
        fistProduct: boolean,
        isTypeSteps: boolean,
        isTypeAdd: boolean
    ) => {
        if (!fistProduct) {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: copyModifier?.[previousModifier!],
            };
            // eslint-disable-next-line no-prototype-builtins
        } else if (copyModifier.hasOwnProperty(MD5modifier)) {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: isTypeSteps
                    ? {
                          /* steps */ ...copyModifier[MD5modifier],
                          typeSteps: true,
                          steps: stepObject,
                      }
                    : {
                          /* vente additionnel */ ...copyModifier[MD5modifier],
                          typeAdd: true,
                          add: stepObject,
                      },
            };
        } else {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: isTypeSteps
                    ? {
                          /* steps */ steps: stepObject,
                          add: {},
                          category: {},
                          typeSteps: isTypeSteps,
                          typeAdd: isTypeAdd,
                          typeCategorie: false,
                      }
                    : {
                          /* vente additionnel */ steps: {},
                          add: stepObject,
                          category: {},
                          typeSteps: isTypeSteps,
                          typeAdd: isTypeAdd,
                          typeCategorie: false,
                      },
            };
        }
        if (
            Object.keys?.(copyModifier[MD5modifier][modifierType] || {})
                .length > 0
        ) {
            Object.entries(copyModifier[MD5modifier][modifierType])?.forEach(
                ([iuudStepModifier]: any) => {
                    if (
                        Object.keys?.(
                            copyModifier[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers
                        ).length > 0
                    ) {
                        Object.entries(
                            copyModifier[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers
                        )?.forEach(([iuudItemModifier]: any) => {
                            const MD5modifier2 = uuidv4();
                            previousModifier =
                                copyModifier[MD5modifier][modifierType][
                                    iuudStepModifier
                                ].itemModifiers[iuudItemModifier];
                            copyModifier = {
                                ...copyModifier,
                                [MD5modifier]: {
                                    ...copyModifier[MD5modifier],
                                    [modifierType]: {
                                        ...copyModifier[MD5modifier][
                                            modifierType
                                        ],
                                        [iuudStepModifier]: {
                                            ...copyModifier[MD5modifier][
                                                modifierType
                                            ][iuudStepModifier],
                                            itemModifiers: {
                                                ...copyModifier[MD5modifier][
                                                    modifierType
                                                ][iuudStepModifier]
                                                    .itemModifiers,
                                                [iuudItemModifier]:
                                                    MD5modifier2,
                                            },
                                        },
                                    },
                                },
                            };

                            insertUuidMultipleModifier(
                                previousModifier,
                                MD5modifier2,
                                modifierType,
                                stepObject,
                                false,
                                isTypeSteps,
                                isTypeAdd
                            );
                        });
                    }
                }
            );
        }
    };

    const addMultipleStepFistModal = () => {
        const globalIndex =
            multipleWorkflow[contentModal.length - 1]?.StepIndex;
        const listUuidItem =
            multipleWorkflow[contentModal.length - 1]?.listeUuidItem;
        const previousModifier =
            multipleWorkflow[contentModal.length - 1]?.PreviousModifier;

        const modifierType =
            contentModal[contentModal.length - 1]?.type ===
            WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS /* déclencheur d'etape */
                : WORKFLOW_LIST.ADD; /* vente additionnel */

        /* object to duplicate */
        const modifierObject =
            modifierType === WORKFLOW_LIST.STEPS
                ? copyModifier?.[previousModifier!].steps
                : copyModifier?.[previousModifier!].add;

        // concatenate virtual modifier to modifier
        copyModifier = { ...copyModifier, ...modifierWorkflow };

        listUuidItem.forEach((elementUuid: string) => {
            // isModifier: false, modifierUuid
            const modifierForm = checkModifierExist(
                elementUuid,
                WORKFLOW_LIST.TYPESTEPS,
                globalIndex,
                copyModifier,
                globalWorkflow
            );
            const MD5modifier = modifierForm.modifierUuid;

            insertUuidMultipleModifier(
                contentModal[contentModal.length - 1].previousModifier!,
                MD5modifier,
                modifierType,
                modifierObject,
                true,
                modifierType === WORKFLOW_LIST.STEPS,
                modifierType === WORKFLOW_LIST.ADD
            );
            // insert modifier to global workflow
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflow[globalIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            /* update item modifiers */
            copyModifier = {
                ...copyModifier,
                [globalWorkflow[globalIndex!].previousModifier!]: {
                    ...copyModifier[
                        globalWorkflow[globalIndex!].previousModifier!
                    ],
                    [WORKFLOW_LIST.STEPS]: {
                        ...copyModifier[
                            globalWorkflow[globalIndex!].previousModifier!
                        ][WORKFLOW_LIST.STEPS],

                        [globalWorkflow[globalIndex!].uuidStep!]: {
                            ...copyModifier[
                                globalWorkflow[globalIndex!].previousModifier!
                            ][WORKFLOW_LIST.STEPS][
                                globalWorkflow[globalIndex!].uuidStep!
                            ],
                            itemModifiers: {
                                ...copyModifier[
                                    globalWorkflow[globalIndex!]
                                        .previousModifier!
                                ][WORKFLOW_LIST.STEPS][
                                    globalWorkflow[globalIndex!].uuidStep!
                                ].itemModifiers,
                                [elementUuid]: MD5modifier,
                            },
                        },
                    },
                },
            };
        });
        // reset multiple workflow
        resetMultipleWorkflow(copyWorkflow, globalIndex);

        dispatch(setModifier(copyModifier));
        dispatch(setWorkflow(copyWorkflow));
        dispatch(setMultipleWorkflow([]));
    };

    const addMultipleStepModal = (isMultipleInMultiple: boolean) => {
        const globalIndex =
            multipleWorkflow[multipleWorkflow.length - 1]?.StepIndex;
        const listUuidItem =
            multipleWorkflow[multipleWorkflow.length - 1]?.listeUuidItem;
        const copyWorkflowModal = [
            ...contentModal[contentModal.length - 2].data,
        ];
        const previousModifier =
            multipleWorkflow[multipleWorkflow.length - 1]?.PreviousModifier;

        let stepType: string = "";
        const modifierType =
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS /* step */
                : WORKFLOW_LIST.ADD; /* vente additionnel */

        /* object to duplicate */
        const modifierObject =
            modifierType === WORKFLOW_LIST.STEPS
                ? copyModifier?.[previousModifier!].steps
                : copyModifier?.[previousModifier!].add;
        /* concatenate virtual modifier to modifier */
        copyModifier = isMultipleInMultiple
            ? {
                  ...copyModifier,
                  ...multipleWorkflow[multipleWorkflow.length - 2]
                      .VirtualModifier,
              }
            : { ...copyModifier, ...modifierWorkflow };

        // add liste item to modifier
        listUuidItem.forEach((elementUuid: string) => {
            const modifierForm = checkModifierExist(
                elementUuid,
                WORKFLOW_LIST.TYPESTEPS,
                globalIndex,
                copyModifier,
                copyWorkflowModal
            );
            const MD5modifier = modifierForm.modifierUuid;

            insertUuidMultipleModifier(
                previousModifier,
                MD5modifier,
                modifierType,
                modifierObject,
                true,
                modifierType === WORKFLOW_LIST.STEPS,
                modifierType === WORKFLOW_LIST.ADD
            );

            // add uuid modifier to global workflow modal
            copyWorkflowModal[globalIndex!] = {
                ...copyWorkflowModal[globalIndex!],
                data: {
                    ...copyWorkflowModal[globalIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflowModal[globalIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            stepType = modifierType;
            if (
                contentModal[contentModal.length - 1].type !==
                contentModal[contentModal.length - 2].type
            ) {
                contentModal[contentModal.length - 2].type ===
                WORKFLOW_LIST.TRIGGER
                    ? (stepType = WORKFLOW_LIST.STEPS) // step
                    : (stepType = WORKFLOW_LIST.ADD); // sales add
            }

            // add uuid modifier to item modifiers
            copyModifier = {
                ...copyModifier,
                [contentModal[contentModal.length - 2].previousModifier!]: {
                    ...copyModifier[
                        contentModal[contentModal.length - 2].previousModifier!
                    ],
                    [stepType]: {
                        ...copyModifier[
                            contentModal[contentModal.length - 2]
                                .previousModifier!
                        ][stepType],

                        [contentModal[contentModal.length - 2].data[
                            globalIndex!
                        ].uuidStep!]: {
                            ...copyModifier[
                                contentModal[contentModal.length - 2]
                                    .previousModifier!
                            ][stepType][
                                contentModal[contentModal.length - 2].data[
                                    globalIndex!
                                ].uuidStep!
                            ],
                            itemModifiers: {
                                ...copyModifier[
                                    contentModal[contentModal.length - 2]
                                        .previousModifier!
                                ][stepType][
                                    contentModal[contentModal.length - 2].data[
                                        globalIndex!
                                    ].uuidStep!
                                ].itemModifiers,
                                [elementUuid]: MD5modifier,
                            },
                        },
                    },
                },
            };
        });

        /* reset multiple workflow */
        resetMultipleWorkflow(copyWorkflowModal, globalIndex);

        const copyVirtualModifier = JSON.parse(
            JSON.stringify(multipleWorkflow)
        );

        if (isMultipleInMultiple) {
            copyVirtualModifier[multipleWorkflow.length - 2].VirtualModifier =
                copyModifier;
        } else {
            dispatch(setModifier(copyModifier));
        }

        const ContentModal = [...contentModal];
        ContentModal[ContentModal.length - 2].data = copyWorkflowModal;
        setContentModal(ContentModal);

        copyVirtualModifier.splice(copyVirtualModifier.length - 1, 1);
        dispatch(setMultipleWorkflow(copyVirtualModifier));
    };

    const addMultipleInMultipleFirstModal = () => {
        const globalIndex =
            multipleWorkflow[multipleWorkflow.length - 1]?.StepIndex;

        const listUuidItem =
            multipleWorkflow[multipleWorkflow.length - 1]?.listeUuidItem;

        const previousStepModifier =
            contentModal[contentModal.length - 1].previousModifierStep;

        const previousStepUuid =
            contentModal[contentModal.length - 1].previousUuidStep;

        const previousModifier =
            multipleWorkflow[multipleWorkflow.length - 1]?.PreviousModifier;

        const modifierType =
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS /* declencheur d'etape */
                : WORKFLOW_LIST.ADD; /* vente additionnel */

        /* object to duplicate */
        const modifierObject =
            modifierType === WORKFLOW_LIST.STEPS
                ? copyModifier?.[previousModifier!].steps
                : copyModifier?.[previousModifier!].add;

        // concatenate virtual modifier to modifier
        copyModifier = {
            ...copyModifier,
            ...multipleWorkflow[multipleWorkflow.length - 2].VirtualModifier,
        };

        // add liste item to modifier
        listUuidItem.forEach((elementUuid: string) => {
            const modifierForm = checkModifierExist(
                elementUuid,
                WORKFLOW_LIST.TYPESTEPS,
                globalIndex,
                copyModifier,
                globalWorkflow
            );
            const MD5modifier = modifierForm.modifierUuid;

            insertUuidMultipleModifier(
                previousModifier,
                MD5modifier,
                modifierType,
                modifierObject,
                true,
                modifierType === WORKFLOW_LIST.STEPS,
                modifierType === WORKFLOW_LIST.ADD
            );

            // add uuid modifier to global workflow modal
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflow[globalIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            // add uuid modifier to item modifiers
            copyModifier = {
                ...copyModifier,
                [previousStepModifier]: {
                    ...copyModifier[previousStepModifier],
                    [WORKFLOW_LIST.STEPS]: {
                        ...copyModifier[previousStepModifier][
                            WORKFLOW_LIST.STEPS
                        ],

                        [previousStepUuid]: {
                            ...copyModifier[previousStepModifier][
                                WORKFLOW_LIST.STEPS
                            ][previousStepUuid],
                            itemModifiers: {
                                ...copyModifier[previousStepModifier][
                                    WORKFLOW_LIST.STEPS
                                ][previousStepUuid].itemModifiers,
                                [elementUuid]: MD5modifier,
                            },
                        },
                    },
                },
            };
        });
        const copyVirtualModifier = JSON.parse(
            JSON.stringify(multipleWorkflow)
        );

        // reset multiple workflow
        resetMultipleWorkflow(copyWorkflow, globalIndex);

        copyVirtualModifier[multipleWorkflow.length - 2].VirtualModifier =
            copyModifier;

        copyVirtualModifier.splice(copyVirtualModifier.length - 1, 1);
        dispatch(setMultipleWorkflow(copyVirtualModifier));
        dispatch(setWorkflow(copyWorkflow));
    };
    const checkTriggerExist = (
        firstUuidSelected: string,
        globalIndex: number
    ) => {
        const trigger: any = { isTrigger: false, modifierTrigger: "" };

        if (
            Object.keys?.(
                globalWorkflow[globalIndex].data[firstUuidSelected].content ||
                    {}
            ).length > 0
        ) {
            // eslint-disable-next-line prefer-destructuring
            trigger.modifierTrigger = Object.keys(
                globalWorkflow[globalIndex].data[firstUuidSelected].content
            )[0];
            if (
                Object.keys?.(copyModifier[trigger.modifierTrigger] || {})
                    .length > 0 &&
                copyModifier[trigger.modifierTrigger][WORKFLOW_LIST.TYPEADD]
            ) {
                trigger.isTrigger = true;
            }
        }
        return trigger;
    };
    const insertUuidMultipleModifierSalesAdd = (
        previousModifier: string,
        MD5modifier: string,
        modifierType: string,
        salesAddObject: any,
        action: string
    ) => {
        if (action === "second") {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: copyModifier?.[previousModifier!],
            };
            // eslint-disable-next-line no-prototype-builtins
        } else if (copyModifier.hasOwnProperty(MD5modifier)) {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: {
                    ...copyModifier[MD5modifier],
                    typeAdd: true,
                    add: salesAddObject,
                },
            };
        } else {
            copyModifier = {
                ...copyModifier,
                [MD5modifier]: {
                    steps: {},
                    add: salesAddObject,
                    category: {},
                    typeSteps: false,
                    typeAdd: true,
                    typeCategorie: false,
                },
            };
        }

        if (
            Object.keys?.(copyModifier[MD5modifier][modifierType] || {})
                .length > 0
        ) {
            Object.entries(copyModifier[MD5modifier][modifierType])?.forEach(
                ([iuudStepModifier]: any) => {
                    if (
                        Object.keys?.(
                            copyModifier[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers
                        ).length > 0
                    ) {
                        Object.entries(
                            copyModifier[MD5modifier][modifierType][
                                iuudStepModifier
                            ].itemModifiers
                        )?.forEach(([iuudItemModifier]: any) => {
                            const MD5modifier2 = uuidv4();
                            previousModifier =
                                copyModifier[MD5modifier][modifierType][
                                    iuudStepModifier
                                ].itemModifiers[iuudItemModifier];
                            copyModifier = {
                                ...copyModifier,
                                [MD5modifier]: {
                                    ...copyModifier[MD5modifier],
                                    [modifierType]: {
                                        ...copyModifier[MD5modifier][
                                            modifierType
                                        ],
                                        [iuudStepModifier]: {
                                            ...copyModifier[MD5modifier][
                                                modifierType
                                            ][iuudStepModifier],
                                            itemModifiers: {
                                                ...copyModifier[MD5modifier][
                                                    modifierType
                                                ][iuudStepModifier]
                                                    .itemModifiers,
                                                [iuudItemModifier]:
                                                    MD5modifier2,
                                            },
                                        },
                                    },
                                },
                            };

                            insertUuidMultipleModifierSalesAdd(
                                previousModifier,
                                MD5modifier2,
                                modifierType,
                                salesAddObject,
                                "second"
                            );
                        });
                    }
                }
            );
        }
    };
    const addMultipleSalesAddInProduct = () => {
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );

        const globalIndex = multipleWorkflow[0]?.StepIndex;
        const listUuidItem = multipleWorkflow[0]?.listeUuidItem;
        const previousModifier = multipleWorkflow[0]?.PreviousModifier;
        const salesAddObject = copyModifier?.[previousModifier!].add;

        /* concatenate virtual modifier to modifier */
        copyModifier = { ...copyModifier, ...modifierWorkflow };

        listUuidItem.forEach((elementUuid: string) => {
            const trigger = checkTriggerExist(elementUuid, globalIndex);

            const MD5modifier = trigger.modifierTrigger;
            let pathIuud: string = "copyGlobalWorkflowData?.";
            insertUuidMultipleModifierSalesAdd(
                previousModifier,
                MD5modifier,
                WORKFLOW_LIST.ADD,
                salesAddObject,
                "first"
            );
            // insert modifier to global workflow
            copyWorkflow[globalIndex!] = {
                ...copyWorkflow[globalIndex!],
                data: {
                    ...copyWorkflow[globalIndex!].data,
                    [elementUuid]: {
                        ...copyWorkflow[globalIndex!].data[elementUuid],
                        content: {
                            [MD5modifier]: {
                                type: "modifier",
                                override: {},
                                content: {},
                            },
                        },
                    },
                },
            };

            // duplicate workflow data
            if (!trigger.isTrigger) {
                [
                    ...globalWorkflow[globalIndex!].previousUuids!,
                    elementUuid,
                ]?.forEach((element: string) => {
                    pathIuud += `["${element}"]?.content`;
                });

                // eslint-disable-next-line no-eval

                pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?")); // eslint-disable-next-line no-eval

                const stringEvaluate = eval(pathIuud);
                stringEvaluate.content = {};
                stringEvaluate.content[MD5modifier] = {
                    type: "modifier",
                    override: {},
                    content: {},
                };
                ///
                // const stringEvaluate = eval(pathIuud);
                // stringEvaluate[MD5modifier] = {
                //     type: "modifier",
                //     override: {},
                //     content: {},
                // };
            }
        });

        // duplicate in workflow data
        dispatch(setWorkflowData(copyGlobalWorkflowData));

        // reset multiple workflow
        copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
        resetMultipleWorkflow(copyWorkflow, globalIndex);

        dispatch(setModifier(copyModifier));
        dispatch(setWorkflow(copyWorkflow));
        dispatch(setMultipleWorkflow([]));
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="mt-2 ml-2">
                <StyledButton
                    rounded
                    variant={dominantColor}
                    style={{
                        // visibility: count > 0 ? "visible" : "hidden",
                        visibility: "hidden",
                    }}
                    onClick={() => {}}
                >
                    Enregistrer parcours
                </StyledButton>
            </div>
            <div className="mt-2 d-flex justify-content-center align-items-center">
                <StyledButton
                    rounded
                    outline
                    variant="light"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    {t("Cancel")}
                </StyledButton>

                <div className="ml-3 mr-2">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        disabled={
                            cardType === "shop" &&
                            cardStatus === "En production"
                        }
                        onClick={() => {
                            if (
                                contentModal[contentModal.length - 1]?.data !==
                                undefined
                            ) {
                                contentModal[contentModal.length - 1]?.data
                                    .length === 1 &&
                                contentModal[contentModal.length - 1]?.data[0]
                                    .data.length === 0
                                    ? closeModal()
                                    : ValidateModal();
                            }
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
}
