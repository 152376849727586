import React from "react";
import { Nav } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { ExcelRenderer } from "react-excel-renderer";
import {
    VerticalDotsIcon,
    AddFolderIcon,
    StyledButtonWithIcon,
} from "@aureskonnect/react-ui";
import { ColumnFileitem } from "@constants/ColumnFileitem";
import { useTranslation } from "react-i18next";
import Report from "./Report/Index";
import ExtensionInvalidModal from "./ExtensionInvalidModal";

type SelectFilePropsType = {
    setFile: React.Dispatch<any>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    step: number;
    file: any;
    createdNumber: number;
    selectedNumber: number;
    updatedNumber: number;
    setFilteredRow: React.Dispatch<any>;
    filteredRow: any;
};
export default function SelectFile({
    setFile,
    setStep,
    step,
    file,
    createdNumber,
    selectedNumber,
    updatedNumber,
    setFilteredRow,
    filteredRow,
}: SelectFilePropsType) {
    const { t } = useTranslation();
    const [filePath, setFilePath] = React.useState<any>("");
    const [extensionName, setExtensionName] = React.useState<any>("");
    const [isOpen, setIsOpen] = React.useState<any>(false);
    let rowsLength: number;
    const fileHandler = async (e: any) => {
        const fileObj = e.target.files[0];
        setFilePath(fileObj);
        if (fileObj) {
            setExtensionName(fileObj.name.split(".").pop());
        }
    };

    const hundleupload = async (filePath: any) => {
        ExcelRenderer(filePath, (err: any, resp: any) => {
            if (!err) {
                resp.cols.forEach((element: any, index: number) => {
                    resp.cols[index].name = `Colonne ${index}`;
                    resp.cols[index].key = `Colonne ${index}`;
                    resp.cols[index].index = `Colonne ${index}`;
                    resp.cols[index].visibility = true;
                    resp.cols[index].listOfTitle = ColumnFileitem;
                    resp.cols[index].errorColumn = false;
                });
                resp.rows.forEach((element: any, index1: number) => {
                    resp.rows[index1].push(true);
                    if (index1 === 0) {
                        rowsLength = element.length;
                    }
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < rowsLength; i++) {
                        if (element[i] === undefined) {
                            element[i] = "";
                        }
                    }
                    filteredRow.push(element);
                });
                setFile({
                    cols: resp.cols,
                    rows: resp.rows,
                });
            }
        });
    };

    return (
        <>
            {(() => {
                switch (step) {
                    case 1:
                        return (
                            <Nav className="mt-2 ">
                                <div className="  d-flex bd-highlight col-12 d-flex align-items-center">
                                    <p className="input_ty">
                                        {t("Select File")} :
                                    </p>
                                    <div className="input-group ">
                                        <div className="input-path pt-1">
                                            {filePath.name}
                                        </div>
                                        <label
                                            htmlFor="file"
                                            className="pl-2 d-flex align-items-center icon"
                                        >
                                            {" "}
                                            <AddFolderIcon
                                                className="mr-2"
                                                height={28}
                                                width={28}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </label>
                                        <input
                                            type="file"
                                            onChange={fileHandler}
                                            id="file"
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <div className="d-flex bd-highlight ">
                                            <AvForm
                                                onSubmit={() => {
                                                    if (
                                                        extensionName ===
                                                            "xlsx" ||
                                                        extensionName === "xls"
                                                    ) {
                                                        if (filePath) {
                                                            filteredRow = [];
                                                            hundleupload(
                                                                filePath
                                                            );
                                                            setStep(1);
                                                            setFilteredRow(
                                                                filteredRow
                                                            );
                                                        }
                                                    } else {
                                                        setIsOpen(true);
                                                    }
                                                }}
                                            >
                                                <StyledButtonWithIcon
                                                    icon="DownloadIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t("Generate")}
                                                </StyledButtonWithIcon>
                                            </AvForm>
                                        </div>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <div className=" d-none d-sm-block">
                                            <div className="d-flex justify-content-end ">
                                                <VerticalDotsIcon
                                                    height={20}
                                                    width={20}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Nav>
                        );
                    case 2:
                        return (
                            <Report
                                file={file}
                                creatednumber={createdNumber}
                                selectednumber={selectedNumber}
                                Updatenumber={updatedNumber}
                            />
                        );
                    default:
                        return "";
                }
            })()}
            <ExtensionInvalidModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setFile={setFile}
            />
        </>
    );
}
