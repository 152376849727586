import React from "react";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { setWorkflow } from "@store/features/workflowSlice";
import { WORKFLOW_LIST } from "@constants/WorkFlow";

type checkedProductProps = {
    uuid: string;
    globalIndex: number;
    colorElement: string | undefined;
    order: number | undefined;
    IsOptionStep: boolean;
    IsBasicCompositionStep: boolean;
    checkstepItem: boolean;
    type: string;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    typeGolbal: string | undefined;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    cardData: any;
};

export function CheckedProduct({
    uuid,
    globalIndex,
    colorElement,
    order,
    IsOptionStep,
    IsBasicCompositionStep,
    checkstepItem,
    type,
    setIsUnsavedDataMultiple,
    typeGolbal,
    setIndexSelected,
    cardData,
}: checkedProductProps) {
    const dispatch = useDispatch();
    const ETKType = localStorage.getItem("ETKType");
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const handleMultipleStep = () => {
        if (multipleWorkflow.length > 0 && typeGolbal === "categories") {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const copyglobalWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        const indexUuid = globalWorkflow[
            globalIndex!
        ].multipleStep.listeUuidItem.findIndex(
            (element: string) => element === uuid
        );
        indexUuid === -1
            ? copyglobalWorkflow[globalIndex!].multipleStep.listeUuidItem.push(
                  uuid
              )
            : copyglobalWorkflow[
                  globalIndex!
              ].multipleStep.listeUuidItem.splice(indexUuid, 1);

        copyglobalWorkflow[globalIndex!].multipleStep.listeUuidItem.length > 1
            ? (copyglobalWorkflow[globalIndex!].multipleStep.isMultiple = true)
            : (copyglobalWorkflow[globalIndex!].multipleStep.isMultiple =
                  false);

        copyglobalWorkflow[globalIndex!] = {
            ...copyglobalWorkflow[globalIndex!],
            selected: uuid,
        };
        setIndexSelected(globalIndex);
        dispatch(setWorkflow(copyglobalWorkflow));
    };

    return (
        <div
            style={{
                display: "flex",
                borderRight: `4px solid ${colorElement}`,
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <div style={{ fontSize: "17px" }}> {order}</div>
            {type === "items" &&
                checkstepItem &&
                !IsOptionStep &&
                !IsBasicCompositionStep &&
                (globalIndex === globalWorkflow.length - 1 ||
                    multipleWorkflow.length > 0 ||
                    typeGolbal === "steps") && (
                    <div
                        className={
                            ETKType === "3" &&
                            !cardData?.items?.[
                                globalWorkflow[globalIndex!]?.uuid
                            ]?.isRedirect &&
                            globalWorkflow[globalIndex!]?.type ===
                                WORKFLOW_LIST.STEPS
                                ? " disabledDiv__CLZ"
                                : ""
                        }
                    >
                        <input
                            type="checkbox"
                            id="option"
                            checked={globalWorkflow[
                                globalIndex!
                            ]?.multipleStep?.listeUuidItem.includes(uuid)}
                            onChange={() => {
                                handleMultipleStep();
                            }}
                            style={{ height: "17px", width: "17px" }}
                        />
                    </div>
                )}
        </div>
    );
}
