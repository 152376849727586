import React from "react";

import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DominantColor } from "@components/Common/";
import { useDispatch } from "react-redux";

import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setWorkflow } from "@store/features/workflowSlice";

type UnsavedChangesWarningModalPropsType = {
    isUnsavedChangesModalOpen: boolean;
    setIsUnsavedChangesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    resetGlobalWorkflow: WorkFlowResetProps;
};

export default function UnsavedChangesWarningModal({
    isUnsavedChangesModalOpen,
    setIsUnsavedChangesModalOpen,
    resetGlobalWorkflow,
}: UnsavedChangesWarningModalPropsType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const dispatch = useDispatch();

    const resetWorkflow = () => {
        dispatch(setModifier(resetGlobalWorkflow.modifier));
        dispatch(setWorkflowData(resetGlobalWorkflow.WorkflowData));
        dispatch(setWorkflow(resetGlobalWorkflow.GlobalWorkflow));
    };

    return (
        <Modal centered isOpen={isUnsavedChangesModalOpen}>
            <ModalHeader toggle={() => setIsUnsavedChangesModalOpen(false)}>
                <StyledH2 className="text-uppercase">
                    {t("Confirm Changes")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t("Do you want to save the changes made")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        resetWorkflow();
                        setIsUnsavedChangesModalOpen(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("No")}
                </StyledButton>
                <StyledButton
                    onClick={() => setIsUnsavedChangesModalOpen(false)}
                    rounded
                    variant={dominantColor}
                >
                    {t("Yes")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
