import React from "react";

export default function ThreedotIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={31}
            onClick={(event: any) => {
                event.stopPropagation();
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <g data-name="Groupe 27607" transform="translate(.021)">
                <ellipse
                    data-name="Ellipse 697"
                    cx={16}
                    cy={15.5}
                    rx={16}
                    ry={15.5}
                    transform="translate(-.021)"
                    fill="#fff"
                />
                <g
                    data-name="Groupe 13262"
                    transform="translate(12.515 6)"
                    fill="#d5d5d5"
                >
                    <circle
                        data-name="Ellipse 714"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(.465)"
                    />
                    <circle
                        data-name="Ellipse 714"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(.465 7)"
                    />
                    <circle
                        data-name="Ellipse 714"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(.465 14)"
                    />
                </g>
            </g>
        </svg>
    );
}
