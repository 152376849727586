export function filterCategories(
    categories: CategoryWithChildrenType[],
    title: string
) {
    return categories.reduce(
        (
            filterResult: CategoryWithChildrenType[],
            { childCategories = [], ...category }
        ) => {
            const filteredItems = category.items.filter((item) =>
                item.title
                    .toLocaleLowerCase()
                    .includes(title.toLocaleLowerCase())
            );
            if (
                category.title
                    .toLocaleLowerCase()
                    .includes(title.toLocaleLowerCase()) ||
                filteredItems.length > 0
            ) {
                filterResult.push({
                    ...category,
                    items: filteredItems,
                    childCategories: filterCategories(childCategories, title),
                });
                return filterResult;
            }
            childCategories = filterCategories(childCategories, title);

            if (childCategories.length) {
                filterResult.push({
                    ...category,
                    items: filteredItems,
                    childCategories,
                });
            }
            return filterResult;
        },
        []
    );
}

export function filterSteps(steps: StepWithChildrenType[], title: string) {
    const filteredSteps: StepWithChildrenType[] = [];
    steps.forEach((step) => {
        const filteredStepItems = step.items.filter((item) =>
            item.title.toLocaleLowerCase().includes(title.toLocaleLowerCase())
        );
        if (step.title.includes(title) || filteredStepItems.length > 0) {
            filteredSteps.push({ ...step, items: filteredStepItems });
        }
    });
    return filteredSteps;
}
