import React from "react";
import ReactSwitch from "react-switch";
import { RootState } from "@store/index";
import { useSelector } from "react-redux";

type Switchday = {
    data: string | any;
    setRender: React.Dispatch<React.SetStateAction<string>>;
    indiceData: number;
    isActive: boolean;
    setPeriod: React.Dispatch<any>;
    period: any;
};
function SwitchVisibility({
    data,
    setRender,
    indiceData,
    isActive,
    setPeriod,
    period,
}: Switchday) {
    const Allday: string = "Toute la semaine";

    const indexPeriodCopyPrincipal: any = useSelector(
        (State: RootState) => State.indexPeriodSlice.value
    );
    const index = indexPeriodCopyPrincipal.principalIndex;

    function handlechangeEvent(isActive: boolean, indexdata: number) {
        setPeriod((prevState: any) => {
            const newState = [...prevState];
            const selectedDate = {
                ...newState[index].Day[indexdata],
                visibility: !isActive,
            };

            const newArr = [...newState[index].Day];
            newArr[indexdata] = selectedDate;

            newState[index] = {
                ...newState[index],
                Day: newArr,
            };

            period[index] = newState[index];

            return newState;
        });
    }
    function HandleSwitch() {
        const indexNameDay = period[index].Day.findIndex(
            (element: any) => element.name === data
        );
        const indexAllDay = period[index].Day.findIndex(
            (element: any) => element.name === Allday
        );

        if (
            indexNameDay === indexAllDay &&
            period[index].Day[indexNameDay].visibility === true
        ) {
            period[index].Day.map((item: any, indexData: number) => {
                if (Allday !== item.name) {
                    handlechangeEvent(false, indexData);
                }
                return period;
            });
        } else if (
            data !== Allday &&
            period[index].Day[indexNameDay].visibility === false &&
            period[index].Day[indexAllDay].visibility === true
        ) {
            handlechangeEvent(true, indexAllDay);
        }
    }

    return (
        <div>
            <ReactSwitch
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={26}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={() => {
                    handlechangeEvent(
                        period[index].Day[indiceData].visibility,
                        indiceData
                    );

                    HandleSwitch();
                    setRender(
                        `${data} ${period[index].Day[indiceData].visibility} switch`
                    );
                }}
                checked={period[index].Day[indiceData].visibility}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={55}
                height={20}
                disabled={isActive === false}
            />
        </div>
    );
}

export default SwitchVisibility;
