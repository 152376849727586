/* eslint-disable no-restricted-globals */
import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    VerticalDotsIcon,
    LinkIcon,
    TrashIcon,
    DuplicateIcon,
    StyledIconButton,
    EyeIcon,
} from "@aureskonnect/react-ui";

import PrintersModal from "@components/Common_DB/PrintersModal";
import MultiDeleteItemsModal from "./MultiDeleteItemsModal";
import MultiDeleteCategoriesModal from "./MultiDeleteCategoriesModal";
import MultiDuplicateItemsModal from "./MultiDuplicateItemsModal";
import MultiDuplicateCategoriesModal from "./MultiDuplicateCategoriesModal";
import PrinterIcon from "../SvgIcons/Printercon";

type CommonMenuType = {
    title: string;
};

export default function CommonMenu({ title }: CommonMenuType) {
    const { t } = useTranslation();
    const ETKType = localStorage.getItem("ETKType");
    const [isMultiDeleteModalOpen, setIsMultiDeleteModalOpen] =
        React.useState(false);
    const [isMultiDuplicateModalOpen, setIsMultiDuplicateModalOpen] =
        React.useState(false);

    const to = `/File/${title}`;

    const handleDelete = (): void => {
        setIsMultiDeleteModalOpen(!isMultiDeleteModalOpen);
    };
    const handleDuplicate = (): void => {
        setIsMultiDuplicateModalOpen(!isMultiDuplicateModalOpen);
    };

    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const shopId = localStorage.getItem("shopId");
    const franchiseId = localStorage.getItem("franchiseId");

    const urlKiosk = `${process.env.REACT_APP_KIOSK}#/?schema=${schemaAdbFranchise}&uuidCatalog=${CardIuud}&franchise_uid=${franchiseId}&shop_uid=${shopId}`;

    const [isPrintersModalOpen, setIsPrintersModalOpen] = React.useState(false);

    return (
        <React.Fragment>
            <div className="dropdown ml-2   ">
                <StyledIconButton
                    icon="VerticalDotsIcon"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                >
                    <VerticalDotsIcon height={25} width={25} />
                </StyledIconButton>
                <div className="dropdown-menu">
                    {title === "Workflow" ? null : (
                        <Link to={to} key="Article">
                            <div className="dropdown-item ">
                                <LinkIcon
                                    className="mr-2"
                                    height={20}
                                    width={20}
                                />
                                {t("Import excel file")}
                            </div>
                        </Link>
                    )}

                    {ETKType === "1" ||
                    title === "Steps" ||
                    title === "Workflow" ? null : (
                        <>
                            <div
                                className="dropdown-item"
                                role="button"
                                tabIndex={0}
                                onClick={handleDuplicate}
                                style={{
                                    display:
                                        title === "Categories"
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <DuplicateIcon
                                    className="mr-2"
                                    height={20}
                                    width={20}
                                    style={{ cursor: "pointer" }}
                                />
                                {t("Multiple duplication")}
                            </div>
                            {!location.pathname.includes("categories") && (
                                <div
                                    className="dropdown-item"
                                    role="button"
                                    tabIndex={0}
                                    onClick={handleDelete}
                                >
                                    <TrashIcon
                                        className="mr-2"
                                        height={20}
                                        width={20}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                    />
                                    {t("Multiple archiving")}
                                </div>
                            )}
                        </>
                    )}
                    <div className="dropdown-item" role="button" tabIndex={0}>
                        <a
                            href={urlKiosk}
                            target="_blank"
                            className="mt-4 mx-1 mb-md-4"
                            style={{ color: "black" }}
                            rel="noreferrer"
                        >
                            <EyeIcon className="mr-1" height={25} width={25} />

                            {t("Visualiser design")}
                        </a>
                    </div>
                    {title.toLocaleLowerCase() === "articles" && (
                        <React.Fragment>
                            <div
                                className="dropdown-item"
                                role="button"
                                tabIndex={0}
                                onClick={() => setIsPrintersModalOpen(true)}
                            >
                                <PrinterIcon height={25} width={25} />
                                <span className="text-capitalize">
                                    {t("dispatch printing")}
                                </span>
                            </div>
                            <PrintersModal
                                isPrintersModalOpen={isPrintersModalOpen}
                                setIsPrintersModalOpen={setIsPrintersModalOpen}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {isMultiDeleteModalOpen &&
                (title === "Articles" ? (
                    <MultiDeleteItemsModal
                        isOpen={isMultiDeleteModalOpen}
                        setIsOpen={setIsMultiDeleteModalOpen}
                    />
                ) : (
                    <MultiDeleteCategoriesModal
                        isOpen={isMultiDeleteModalOpen}
                        setIsOpen={setIsMultiDeleteModalOpen}
                    />
                ))}
            {isMultiDuplicateModalOpen &&
                (title === "Articles" ? (
                    <MultiDuplicateItemsModal
                        isOpen={isMultiDuplicateModalOpen}
                        setIsOpen={setIsMultiDuplicateModalOpen}
                    />
                ) : (
                    <MultiDuplicateCategoriesModal
                        isOpen={isMultiDuplicateModalOpen}
                        setIsOpen={setIsMultiDuplicateModalOpen}
                    />
                ))}
        </React.Fragment>
    );
}
