import React, { useReducer } from "react";

import axios from "axios";
import ReactSwitch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { CardFooter } from "reactstrap";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { StepIcon } from "@components/Common/SvgIcons";
import { RootState } from "@store/.";
import {
    SuccessToast,
    DominantColor,
    DynamicTableWrapperContext,
    RequirementToast,
    CustomCardBody,
    ErrorToast,
} from "@components/Common/";
import StyledCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { setWorkflow } from "@store/features/workflowSlice";
import { setModifier } from "@store/features/modifierSlice";
import StepItemManagementModal from "@components/Common_DB/AdvancedStepItemManagementModal/StepItemManagementModal";
import BorneOptionsModal from "@components/Common_DB/AdvancedBorneOptionsModal/BorneOptionsModal";
import FlagIcon from "@components/Common/SvgIcons/FlagIcon";
import FlagIconWithBorder from "@components/Common/SvgIcons/FlagIconWithBorder";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { resetUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";

import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { initialStateStep, reducer } from "./reducer";
import Image from "./Image";
import DisplayName from "../Common/DisplayName";
import PriceGroupe2SelectInput from "./PriceGroupe2SelectInput";

import "./style.css";

type WorkflowProps = {
    contentModal?: any;
    setContentModal?: React.Dispatch<any>;
    initialData?: any;
    setIsModify?: React.Dispatch<React.SetStateAction<boolean>>;
    isModify?: boolean;
};

export default function AddStep({
    contentModal,
    setContentModal,
    initialData,
    setIsModify,
    isModify,
}: WorkflowProps): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dominantColor: string = DominantColor();
    const ref = React.useRef<HTMLDivElement>(null);
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const workflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifier = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const updatedWorkflowStep = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    const [validateOnClick, setValidateOnClick] = React.useState<boolean>(true);
    const [isDisplayNameModalOpen, setIsDisplayNameModalOpen] =
        React.useState<boolean>(false);
    const [stepForm, dispatchStep] = useReducer(reducer, initialStateStep);

    const [isStepItemManagementModalOpen, setIsStepItemManagementModalOpen] =
        React.useState<boolean>(false);

    const [isBorneOptionsModalOpen, setIsBorneOptionsModalOpen] =
        React.useState<boolean>(false);

    const isNextStepDisabled = stepForm.minNbrOfChoices !== 0;

    const putStep = (data: FormData) =>
        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/steps/${dynamicTableWrapperContext?.rowData?.iuud}`,
                data
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                SuccessToast(t("Step updated successfully"));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    const addStep = (data: FormData) => {
        if (validateOnClick) {
            setValidateOnClick(false);
            try {
                axios
                    .post(
                        `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/step`,
                        data
                    )
                    .then((): void => {
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("Step added successfully"));
                        setValidateOnClick(true);
                    })
                    .catch(() => {
                        ErrorToast(t("There is a mistake!"));
                    });
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };

    const overrideWorkflowStep = () => {
        let copyModifier =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                  }
                : { ...modifier };
        const previousData =
            workflow[updatedWorkflowStep.indexWorkflowGlobal].data;
        const copyWorkflow: any = Object.assign([], workflow, {
            [updatedWorkflowStep.indexWorkflowGlobal]: {
                ...workflow[updatedWorkflowStep.indexWorkflowGlobal],
                override: {
                    ...stepForm,
                },
            },
        });

        copyWorkflow[updatedWorkflowStep.indexWorkflowGlobal] = {
            ...copyWorkflow[updatedWorkflowStep.indexWorkflowGlobal],
            data: Object.entries(stepForm.stepItems)
                .sort((a: any, b: any) =>
                    // eslint-disable-next-line no-nested-ternary
                    a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                )
                .reduce((prev: any, [key]: any, index: number) => {
                    return {
                        ...prev,
                        [key]: {
                            type: "items",
                            content:
                                previousData[key] !== undefined &&
                                Object.keys(previousData[key].content || {})
                                    .length > 0
                                    ? previousData[key].content
                                    : {},
                            override: {},
                            rank: index + 1,
                        },
                    };
                }, {}),
        };
        dispatch(setWorkflow(copyWorkflow));
        copyModifier = {
            ...copyModifier,
            [workflow[updatedWorkflowStep.indexWorkflowGlobal]
                .previousModifier]: {
                ...copyModifier[
                    workflow[updatedWorkflowStep.indexWorkflowGlobal]
                        .previousModifier
                ],
                steps: {
                    ...copyModifier[
                        workflow[updatedWorkflowStep.indexWorkflowGlobal]
                            .previousModifier
                    ].steps,
                    [workflow[updatedWorkflowStep.indexWorkflowGlobal]
                        .uuidStep]: {
                        ...copyModifier[
                            workflow[updatedWorkflowStep.indexWorkflowGlobal]
                                .previousModifier
                        ].steps[
                            workflow[updatedWorkflowStep.indexWorkflowGlobal]
                                .uuidStep
                        ],
                        overrides: {
                            ...stepForm,
                        },
                    },
                },
            },
        };
        if (multipleWorkflow.length > 0) {
            const copymultipleStep = JSON.parse(
                JSON.stringify(multipleWorkflow)
            );
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: copyModifier,
            };
            dispatch(setMultipleWorkflow(copymultipleStep));
        } else {
            dispatch(setModifier(copyModifier));
        }
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };
    const overrideModalWorkflowStep = () => {
        const modifierType =
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS // step
                : WORKFLOW_LIST.ADD; // sales add
        if (contentModal.length > 0) {
            const copyModal = JSON.parse(JSON.stringify(contentModal));
            const previousData =
                copyModal[copyModal.length - 1].data[
                    updatedWorkflowStep.indexWorkflowGlobal
                ].data;

            copyModal[copyModal.length - 1].data[
                updatedWorkflowStep.indexWorkflowGlobal
            ] = {
                ...copyModal[copyModal.length - 1].data[
                    updatedWorkflowStep.indexWorkflowGlobal
                ],
                data: Object.entries(stepForm.stepItems)
                    .sort((a: any, b: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        a[1].rank ? (b[1].rank ? a[1].rank - b[1].rank : -1) : 1
                    )
                    .reduce((prev: any, [key]: any, index: number) => {
                        return {
                            ...prev,
                            [key]: {
                                type: "items",
                                content:
                                    previousData[key] !== undefined &&
                                    Object.keys(previousData[key].content || {})
                                        .length > 0
                                        ? previousData[key].content
                                        : {},
                                override: {},
                                rank: index + 1,
                            },
                        };
                    }, {}),
            };

            copyModal[copyModal.length - 1].data = Object.assign(
                [],
                copyModal[copyModal.length - 1].data,
                {
                    [updatedWorkflowStep.indexWorkflowGlobal]: {
                        ...copyModal[copyModal.length - 1].data[
                            updatedWorkflowStep.indexWorkflowGlobal
                        ],
                        override: {
                            ...stepForm,
                        },
                    },
                }
            );
            setContentModal?.(copyModal);
            setIsModify?.(!isModify);
        }
        if (multipleWorkflow.length > 0) {
            const copymultipleStep = JSON.parse(
                JSON.stringify(multipleWorkflow)
            );
            const { VirtualModifier } =
                copymultipleStep[copymultipleStep.length - 1];

            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: {
                    ...copymultipleStep[copymultipleStep.length - 1]
                        .VirtualModifier,
                    [contentModal[contentModal.length - 1].previousModifier]: {
                        ...copymultipleStep[copymultipleStep.length - 1]
                            .VirtualModifier[
                            contentModal[contentModal.length - 1]
                                .previousModifier
                        ],
                        [modifierType]: {
                            ...VirtualModifier[
                                contentModal[contentModal.length - 1]
                                    .previousModifier
                            ][modifierType],
                            [updatedWorkflowStep.uuidStep]: {
                                ...VirtualModifier[
                                    contentModal[contentModal.length - 1]
                                        .previousModifier
                                ][modifierType][updatedWorkflowStep.uuidStep],
                                overrides: { ...stepForm },
                            },
                        },
                    },
                },
            };

            dispatch(setMultipleWorkflow(copymultipleStep));
        } else {
            dispatch(
                setModifier({
                    ...modifier,
                    [updatedWorkflowStep.previousModifier]: {
                        ...modifier[updatedWorkflowStep.previousModifier],
                        [modifierType]: {
                            ...modifier[updatedWorkflowStep.previousModifier][
                                modifierType
                            ],
                            [updatedWorkflowStep.uuidStep]: {
                                ...modifier[
                                    updatedWorkflowStep.previousModifier
                                ][modifierType][updatedWorkflowStep.uuidStep],
                                overrides: {
                                    ...stepForm,
                                },
                            },
                        },
                    },
                })
            );
        }
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };

    const insertStep = async (): Promise<void> => {
        if (
            stepForm.required &&
            Object.keys?.(stepForm.stepItems || {}).length === 0
        ) {
            RequirementToast(
                t(
                    "In a mandatory step,i he step must contain at least one visible item"
                )
            );
            return;
        }
        if (stepForm.maxNbrOfChoices < stepForm.minNbrOfChoices) {
            RequirementToast(
                t(
                    "The maximum number of choices must be greater than the minimum number of choices"
                )
            );
        } else {
            const data = new FormData();
            data.append("step", JSON.stringify(stepForm));

            data.append("image", stepForm.urlImage);
            try {
                if (updatedWorkflowStep.pathUpdateItem.length > 0) {
                    updatedWorkflowStep.isModal
                        ? overrideModalWorkflowStep()
                        : overrideWorkflowStep();
                } else {
                    dynamicTableWrapperContext?.rowData?.iuud
                        ? putStep(data)
                        : addStep(data);
                }
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    const handleOnchangeDesignation = (title: string): void => {
        dispatchStep?.({
            type: ACTIONS.TITLE,
            payload: title,
        });
        dispatchStep(addListKeytoEdit(MULTI_EDIT_KEY.TITLE));
    };

    const handleMinCliqueOnChangeEvent = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatchStep({
            type: ACTIONS.MIN_NUMBER_OF_CHOICES,
            payload: e.target.value,
        });
        if (e.target.value !== "0") {
            dispatchStep({
                type: ACTIONS.REQUIRED,
                payload: true,
            });
            dispatchStep({
                type: ACTIONS.IS_CREATIONAL,
                payload: false,
            });

            dispatchStep({
                type: ACTIONS.IS_MODIFIABLE,
                payload: false,
            });
        } else {
            dispatchStep({
                type: ACTIONS.REQUIRED,
                payload: false,
            });
            dispatchStep({
                type: ACTIONS.IS_CREATIONAL,
                payload: false,
            });

            dispatchStep({
                type: ACTIONS.IS_MODIFIABLE,
                payload: true,
            });
        }
    };
    const handleMaxCliqueOnChangeEvent = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatchStep({
            type: ACTIONS.MAX_NUMBER_OF_CHOICES,
            payload: Number(e.target.value) === 0 ? 1 : Number(e.target.value),
        });
    };
    const handleSpecialPriceOnChangeEvent = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatchStep({
            type: ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE,
            payload: Number(e.target.value),
        });
    };
    const handleStepItemPriceOnChangeEvent = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatchStep({
            type: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
            payload: Number(e.target.value),
        });
    };

    const handleOnRefrechData = (): void => {
        dispatchStep({
            type: ACTIONS.Clear,
            payload: initialStateStep,
        });
    };
    const isInputNumber = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };
    const handleOnClose = (e: any): void => {
        if (updatedWorkflowStep.pathUpdateItem.length > 0) {
            dispatch(resetUpdateItemWorkflow());
        }
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        e.preventDefault();
    };
    const handleOnscroll = (): void => {
        ref.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
        });
        if (stepForm.title === "") {
            RequirementToast(t("The field ''Designation'' must be filled"));
        }
    };

    React.useEffect((): void => {
        if (dynamicTableWrapperContext?.rowData?.iuud) {
            dispatchStep({
                type: ACTIONS.EDIT,
                payload: dynamicTableWrapperContext?.rowData.data,
            });
            dispatch(setIsEditing(true));
        }
    }, [dynamicTableWrapperContext?.rowData, dispatch]);
    useUnsavedChangesWarning(stepForm);

    const isSubmitting = useSelector(
        (state: RootState): boolean => state.editObserverSlice.isSubmitting
    );
    const validateButtonRef = React.useRef<HTMLButtonElement>(null);
    if (isSubmitting === true) {
        validateButtonRef.current?.click();
        dispatch(initEditObserver());
    }
    return (
        <Card style={{ border: "0px " }}>
            <StyledCardHeader
                title="Step"
                refrechIconFunction={handleOnRefrechData}
                Icon={
                    <StepIcon
                        width={31}
                        height={31}
                        fill="white"
                        stopColor="#c50db9"
                        style={{
                            borderRadius: "100%",
                        }}
                    />
                }
            />
            <AvForm
                onValidSubmit={insertStep}
                onInvalidSubmit={handleOnscroll}
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") e.preventDefault();
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <div
                        className="px-3 py-2"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <div
                            style={
                                isEditing &&
                                cardType === "shop" &&
                                cardStatus === "En production"
                                    ? { pointerEvents: "none" }
                                    : { opacity: "1" }
                            }
                        >
                            {stepForm.isBasic ? (
                                <FlagIcon
                                    width={25}
                                    height={25}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        dispatchStep({
                                            type: ACTIONS.IS_BASIC,
                                            payload: !stepForm.isBasic,
                                        })
                                    }
                                />
                            ) : (
                                <FlagIconWithBorder
                                    width={25}
                                    height={25}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        dispatchStep({
                                            type: ACTIONS.IS_BASIC,
                                            payload: !stepForm.isBasic,
                                        })
                                    }
                                />
                            )}
                            <StyledLabel className="ml-3">
                                {t("Step linked to the main item")}
                            </StyledLabel>
                        </div>

                        <div ref={ref} id="désignation">
                            <StyledLabel>{t("Designation *")}</StyledLabel>
                            <StyledInputWithTooltip
                                id="step_designation"
                                name="designation"
                                type="text"
                                value={stepForm.title}
                                requiredValidateValue
                                disabled={
                                    isEditing &&
                                    cardType === "shop" &&
                                    cardStatus === "En production"
                                }
                                autoFocus
                                onFocus={(event: any) => {
                                    event.target.select();
                                }}
                                valueSetterFunction={handleOnchangeDesignation}
                            />
                        </div>
                        <DisplayName
                            DefaultValueName={
                                stepForm?.displayName?.Default?.nameDefault
                            }
                            dispatch={dispatchStep}
                            displayName={stepForm?.displayName}
                            isDisplayNameModalOpen={isDisplayNameModalOpen}
                            setIsDisplayNameModalOpen={
                                setIsDisplayNameModalOpen
                            }
                        />

                        <div className="py-2 d-flex justify-content-between">
                            <StyledLabel>
                                {t("Step item management")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font: "normal normal 600 16px/21px Segoe UI",
                                }}
                                tabIndex={0}
                                role="button"
                                onClick={(): void => {
                                    setIsStepItemManagementModalOpen(true);
                                }}
                            >
                                {t("Access")}
                            </span>

                            <StepItemManagementModal
                                isOpen={isStepItemManagementModalOpen}
                                setIsOpen={setIsStepItemManagementModalOpen}
                                dispatchStep={dispatchStep}
                                stepForm={stepForm}
                                initialData={initialData}
                            />
                        </div>
                        <div className="py-2 d-flex justify-content-between ">
                            <StyledLabel>{t("Number of choices")}</StyledLabel>
                        </div>
                        <div className="py-2   border border-1">
                            <div className="d-flex justify-content-start align-items-start px-2 py-2">
                                <StyledLabel className="col-5 px-1 py-1">
                                    {t("Minimum per step")}
                                </StyledLabel>
                                <StyledTextInput
                                    type="number"
                                    autoComplete="off"
                                    onPaste={(e: any) => {
                                        e.preventDefault();
                                        const pastedData = Math.abs(
                                            Number(
                                                e.clipboardData.getData("text")
                                            )
                                        );

                                        const split = pastedData
                                            .toString()
                                            .split(".")[0];

                                        dispatchStep({
                                            type: ACTIONS.MIN_NUMBER_OF_CHOICES,
                                            payload: split,
                                        });
                                    }}
                                    className="form-control"
                                    style={{
                                        width: "100%",
                                        marginLeft: "25px",
                                    }}
                                    onKeyPress={isInputNumber}
                                    id="min_step"
                                    name="min_step"
                                    min={0}
                                    disabled={
                                        isEditing &&
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                    value={stepForm.minNbrOfChoices.toString()}
                                    placeholder={t("Enter")}
                                    onChange={handleMinCliqueOnChangeEvent}
                                />
                            </div>
                            <div className="  d-flex align  justify-content-between  px-2 py-2">
                                <span>
                                    <StyledLabel>
                                        {t("Skip this step in the workflow")}
                                    </StyledLabel>
                                </span>
                                <div className="d-flex  justify-content-end">
                                    <ReactSwitch
                                        className="ml-5"
                                        activeBoxShadow="0px 0px 1px 10px #000000"
                                        boxShadow="0px 1px 5px 0px #000000"
                                        handleDiameter={20}
                                        offColor={
                                            isNextStepDisabled
                                                ? "#727272"
                                                : "#f7b4b8"
                                        }
                                        offHandleColor={
                                            isNextStepDisabled
                                                ? "#727272"
                                                : "#E30613"
                                        }
                                        onChange={() => {
                                            dispatchStep({
                                                type: ACTIONS.IS_CREATIONAL,
                                                payload: !stepForm.isCreational,
                                            });

                                            dispatchStep({
                                                type: ACTIONS.IS_MODIFIABLE,
                                                payload: stepForm.isCreational,
                                            });
                                        }}
                                        checked={stepForm.isCreational}
                                        disabled={
                                            isNextStepDisabled ||
                                            (isEditing &&
                                                cardType === "shop" &&
                                                cardStatus === "En production")
                                        }
                                        onColor={
                                            isNextStepDisabled
                                                ? "#727272"
                                                : "#c2eddd"
                                        }
                                        onHandleColor={
                                            isNextStepDisabled
                                                ? "#727272"
                                                : "#34C38F"
                                        }
                                        width={45}
                                        height={15}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                    />
                                </div>
                            </div>
                            <div
                                className="ml-2"
                                style={{
                                    fontFamily: "normal  Segoe UI;",
                                    fontSize: "14px",
                                }}
                            >
                                {t(
                                    "If enabled ,the user skips this step but can return to it"
                                )}
                            </div>
                            <div className="d-flex justify-content-start align-items-start  px-2 py-2">
                                <StyledLabel className="col-5 px-1 ">
                                    {t("Maximum per step *")}
                                </StyledLabel>
                                <StyledTextInput
                                    onPaste={(e: any) => {
                                        e.preventDefault();
                                        const pastedData = Math.abs(
                                            Number(
                                                e.clipboardData.getData("text")
                                            )
                                        );

                                        const split = pastedData
                                            .toString()
                                            .split(".")[0];

                                        dispatchStep({
                                            type: ACTIONS.MAX_NUMBER_OF_CHOICES,
                                            payload: split,
                                        });
                                    }}
                                    autoComplete="off"
                                    type="number"
                                    className="form-control"
                                    style={{
                                        width: "100%",
                                        marginLeft: "25px",
                                    }}
                                    onKeyPress={isInputNumber}
                                    id="max_step"
                                    name="max_step"
                                    min={1}
                                    value={stepForm.maxNbrOfChoices.toString()}
                                    defaultValue={stepForm.maxNbrOfChoices.toString()}
                                    placeholder={t("Enter")}
                                    onChange={handleMaxCliqueOnChangeEvent}
                                    disabled={
                                        isEditing &&
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-start align-items-start px-2 py-2">
                                <StyledLabel className="col-5 px-1 py-2">
                                    {t("Price group 1")}
                                </StyledLabel>
                                <StyledTextInput
                                    type="number"
                                    onPaste={(e: any) => {
                                        e.preventDefault();
                                        const pastedData = Math.abs(
                                            Number(
                                                e.clipboardData.getData("text")
                                            )
                                        );

                                        const split = pastedData
                                            .toString()
                                            .split(".")[0];

                                        dispatchStep({
                                            type: ACTIONS.NUMBER_OF_CHOICE_WITH_SPECIAL_PRICE,
                                            payload: split,
                                        });
                                    }}
                                    className="form-control"
                                    style={{
                                        width: "100%",
                                        marginLeft: "25px",
                                    }}
                                    autoComplete="off"
                                    onKeyPress={isInputNumber}
                                    name="zero_priced"
                                    min={0}
                                    disabled={
                                        isEditing &&
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                    placeholder={t("Enter")}
                                    value={
                                        stepForm.nbrOfChoicesWithspecialPrice
                                    }
                                    onChange={handleSpecialPriceOnChangeEvent}
                                />
                            </div>
                            <div className="d-flex justify-content-start align-items-start px-2 py-2">
                                <StyledLabel className="col-5 px-1 ">
                                    {t("Price group 2")}
                                </StyledLabel>
                                {ETKType === "3" ? (
                                    <div
                                        className="mb-3"
                                        style={{
                                            width: "100%",
                                            marginLeft: "25px",
                                        }}
                                    >
                                        <PriceGroupe2SelectInput
                                            dispatch={dispatchStep}
                                            priceGroup1={
                                                stepForm.nbrOfChoicesWithspecialPrice
                                            }
                                            priceGroup2={
                                                stepForm.nbrOfChoicesWithItemPrice
                                            }
                                            maxNbrOfChoices={
                                                stepForm.maxNbrOfChoices
                                            }
                                        />
                                    </div>
                                ) : (
                                    <StyledTextInput
                                        autoComplete="off"
                                        type="number"
                                        className="form-control"
                                        style={{
                                            width: "100%",
                                            marginLeft: "25px",
                                        }}
                                        onKeyPress={isInputNumber}
                                        name="item_step_price"
                                        disabled={
                                            isEditing &&
                                            cardType === "shop" &&
                                            cardStatus === "En production"
                                        }
                                        min={0}
                                        value={
                                            stepForm.nbrOfChoicesWithItemPrice
                                        }
                                        placeholder={t("Enter")}
                                        onChange={
                                            handleStepItemPriceOnChangeEvent
                                        }
                                        onPaste={(e: any) => {
                                            e.preventDefault();
                                            const pastedData = Math.abs(
                                                Number(
                                                    e.clipboardData.getData(
                                                        "text"
                                                    )
                                                )
                                            );

                                            const split = pastedData
                                                .toString()
                                                .split(".")[0];

                                            dispatchStep({
                                                type: ACTIONS.NUMBER_OF_CHOICE_WITH_ITEM_PRICE,
                                                payload: split,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="py-2" id="step_image">
                            <StyledLabel>{t("Picture")}</StyledLabel>
                            <Image
                                id="step_image"
                                dispatch={dispatchStep}
                                stepImageUrl={stepForm.urlImage}
                            />
                        </div>

                        <div className="py-2 d-flex justify-content-between">
                            <StyledLabel>{t("Specific Options")}</StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font: "normal normal 600 16px/21px Segoe UI",
                                }}
                                tabIndex={0}
                                role="button"
                                onClick={(): void => {
                                    setIsBorneOptionsModalOpen(true);
                                }}
                            >
                                {isBorneOptionsModalOpen && (
                                    <BorneOptionsModal
                                        isOpen={isBorneOptionsModalOpen}
                                        setIsOpen={setIsBorneOptionsModalOpen}
                                        stepForm={stepForm}
                                        dispatchStep={dispatchStep}
                                    />
                                )}
                                {t("Advanced")}
                            </span>
                        </div>
                    </div>
                </CustomCardBody>

                <CardFooter className="Footercard p-0 ">
                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                        <div className="p-2 bd-highlight">
                            <StyledButton
                                ref={validateButtonRef}
                                rounded
                                variant={dominantColor}
                                disabled={
                                    updatedWorkflowStep.pathUpdateItem.length >
                                        0 && ETKType === "3"
                                }
                            >
                                {t("Validate")}
                            </StyledButton>
                        </div>
                        <div className="p-2 bd-highlight ">
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={handleOnClose}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </AvForm>
        </Card>
    );
}
