import React from "react";

import shortid from "shortid";
// import { Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { setDominantColor } from "@store/features/dominantColorSlice";
import WorkFlowStepModal from "./WorkflowStepModal";

import "@components/Common/CardMenu/Cartestyle.css";

export type WorkFlowGlobalModalProps = {
    setContentModal: React.Dispatch<any>;
    contentModal: any;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    cardData: any;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function WorkflowGlobalModal({
    contentModal,
    setContentModal,
    setIsModify,
    isModify,
    cardData,
    setIsOpenStepModal,
}: WorkFlowGlobalModalProps) {
    // const [isDragging, setIsDragging] = React.useState(false);
    const dispatch = useDispatch();
    const [isClickStep, setIsClickStep] = React.useState(false);
    const [indexSelected, setIndexSelected] = React.useState(0);
    // const [workFlows, setWorkFlows] = React.useState<WorkFlowStepProps[]>(
    //     contentModal[contentModal.length - 1]?.data
    // );

    dispatch(setDominantColor({ dominantColor: "#232323" }));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if (indexSelected !== -1) {
            setTimeout(() => {
                const divStepItem = document.getElementById(
                    `${indexSelected}${contentModal.length}`
                );
                divStepItem && divStepItem?.scrollIntoView({ block: "start" });
            }, 5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClickStep]);

    return (
        <div style={{ overflow: "hidden auto" }}>
            <div
                style={{
                    alignItems: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    overflowY: "auto",
                    background: "#F7F7FA 0% 0% no-repeat padding-box",
                    minHeight: `calc(100vh - 255px )`,
                    maxHeight: `calc(100vh - 255px )`,
                    marginLeft: "auto",
                }}
            >
                {contentModal[contentModal.length - 1]?.data?.map(
                    (workFlow: any, index: number) => {
                        let isRequired =
                            contentModal.length > 0 &&
                            cardData?.steps?.[
                                contentModal[contentModal.length - 1].data[
                                    index
                                ]?.uuidStep!
                            ]?.required;

                        let maxNbrClick =
                            contentModal.length > 0 &&
                            cardData?.steps?.[
                                contentModal[contentModal.length - 1].data[
                                    index
                                ]?.uuidStep!
                            ]?.maxNbrOfChoices;

                        const isMultipleStep =
                            contentModal[contentModal.length - 1]?.data[index]
                                ?.multipleStep.isMultiple;

                        const workflowStep: any = JSON.parse(
                            JSON.stringify(workFlow)
                        );
                        if (
                            Object.keys(workflowStep.override || {}).length > 0
                        ) {
                            isRequired = workflowStep.override.required;

                            maxNbrClick = workflowStep.override.maxNbrOfChoices;

                            workflowStep.title = workflowStep.override.title;

                            const previousData = JSON.parse(
                                JSON.stringify(workflowStep.data)
                            );

                            workflowStep.data = {};
                            Object.entries(
                                workflowStep.override.stepItems as Record<
                                    string,
                                    StepItemType
                                >
                            )
                                .sort((a: any, b: any) =>
                                    // eslint-disable-next-line no-nested-ternary
                                    a[1].rank
                                        ? b[1].rank
                                            ? a[1].rank - b[1].rank
                                            : -1
                                        : 1
                                )
                                .forEach(([iuud], index: number) => {
                                    workflowStep.data[iuud] = {
                                        type: "items",
                                        content:
                                            previousData[iuud] !== undefined &&
                                            Object.keys(
                                                previousData[iuud].content || {}
                                            ).length > 0
                                                ? previousData[iuud].content
                                                : {},
                                        override: {},
                                        rank: index + 1,
                                    };
                                });
                        }

                        return (
                            <WorkFlowStepModal
                                key={shortid.generate()}
                                title={workflowStep?.title}
                                data={workflowStep.data as any}
                                type={workFlow.type}
                                uuid={workFlow.uuid}
                                globalIndex={index}
                                setContentModal={setContentModal}
                                contentModal={contentModal}
                                isModify={isModify}
                                setIsModify={setIsModify}
                                cardData={cardData}
                                workFlows={
                                    contentModal[contentModal.length - 1]?.data
                                }
                                setIsOpenStepModal={setIsOpenStepModal}
                                // isDragging={isDragging}
                                isClickStep={isClickStep}
                                setIsClickStep={setIsClickStep}
                                setIndexSelected={setIndexSelected}
                                isMultipleStep={isMultipleStep}
                                maxNbrClick={maxNbrClick}
                                isRequired={isRequired}
                            />
                        );
                    }
                )}
            </div>
        </div>
    );
}
