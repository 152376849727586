/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";

import {
    HeartIcon,
    HeartIconWithBorder,
    StyledTooltip,
    TrashIcon,
} from "@aureskonnect/react-ui";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

import "./style.css";

type listOptionValuesProps = {
    handleEditOption: Function;
    element: {
        name: string;
        isFavorite: boolean;
    };
    index: number;
    // eslint-disable-next-line no-unused-vars
    Defaultvalue: (index: number) => void;
    Deletelist: (index: number) => void;
};

export function ListValuesOption({
    handleEditOption,
    element,
    index,
    Defaultvalue,
    Deletelist,
}: listOptionValuesProps) {
    const { t } = useTranslation();
    return (
        <div>
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={
                    element.isFavorite
                        ? t("Default value")
                        : t(" Set as default value")
                }
                id={`Supp${index}`}
            />
            <div
                className=" mb-2 mt-2 "
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #0800FF",
                    borderRadius: "5px",
                    opacity: "1",
                    width: "90%",
                    marginTop: "1px",

                    color: "#f050505",
                }}
            >
                <div className=" d-flex align-items-center  ">
                    <div role="button" tabIndex={0} className="p-2 ">
                        {index + 1}
                    </div>
                    <div
                        className="p-2 w-100 bd-highlight"
                        role="button"
                        tabIndex={0}
                        onClick={(e: any) => {
                            e.preventDefault();
                            handleEditOption(element, index);
                        }}
                    >
                        {element.name}
                    </div>

                    <div className="p-2 d-flex justify-content-end  ">
                        {element.isFavorite ? (
                            <div
                                className=" d-flex justify-content-end  mr-5 "
                                id={`Supp${index}`}
                            >
                                <HeartIcon
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    height={20}
                                    width={20}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        Defaultvalue(index);
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                className=" d-flex justify-content-end  mr-5 "
                                id={`Supp${index}`}
                            >
                                <HeartIconWithBorder
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    height={20}
                                    width={20}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        Defaultvalue(index);
                                    }}
                                />
                                <LockIconWithToolTip
                                    type="icon"
                                    lengthMax={0}
                                    message={t(`Remove the value`)}
                                    id={`Supprimer${index}`}
                                />
                            </div>
                        )}
                        <div
                            className=" d-flex justify-content-end   "
                            id={`Supprimer${index}`}
                        >
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t(`Remove the value`)}
                                id={`Supprimer${index}`}
                            />
                            <TrashIcon
                                style={{
                                    cursor: "pointer",
                                }}
                                height={20}
                                width={20}
                                fill="red"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    Deletelist(index);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
