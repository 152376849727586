import React from "react";

import { Container } from "reactstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { resetHeighSlice } from "@store/features/headerCompoHeighSlice";
import { RootState } from "@store/index";
import { PageTitle } from "@components/.";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import { LoadingDataAnimation } from "@components/Common";
import Optionlist from "./Optionlist";
import { Addoption } from "./Addoption";

export default function Options() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const selectedStore = useSelector(
        (state: RootState): string => state.franchiseNameSlice.name
    );

    React.useEffect(() => {
        return () => {
            dispatch(resetHeighSlice());
        };
    }, [dispatch]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <>
            <PageTitle title="Options" />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "CATALOGUE",
                            link: "home",
                        },
                        {
                            item: selectedStore.toUpperCase(),
                            link:
                                Number(
                                    localStorage.getItem("selectedAccounts")
                                ) > 1
                                    ? "selectaccount"
                                    : "home",
                        },
                        { item: t("catalogue".toUpperCase()), link: "#" },
                        { item: t("options".toUpperCase()), link: "#" },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "Options are the different variants of a product. Each option can have different values and only one default value."
                        )}
                    />

                    <DynamicTableWrapper
                        TableComponent={<Optionlist />}
                        ActionColumnComponent={
                            <Addoption setIsLoading={setIsLoading} />
                        }
                    />
                </Container>
            </motion.div>
        </>
    );
}
