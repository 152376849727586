import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
} from "@components/Common/";
import { getNestedIdofArray } from "@helpers/getNestedId";

type MultiDeleteItemsModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};

export default function MultiDeleteItemsModal({
    isOpen,
    setIsOpen,
}: MultiDeleteItemsModalPropsType) {
    const { t } = useTranslation();
    const [isWarningModal, SetIsWarningModal] = React.useState(false);
    const [textBody, SetTextBody] = React.useState("");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
        SetIsWarningModal(true);
    };

    const getBodyTex = () => {
        let bodyTex = t(
            `Do you want to confirm the archiving of these articles?`
        );

        if (dynamicTableWrapperContext?.rowData?.data.length > 1) {
            SetIsWarningModal(false);
            SetTextBody(bodyTex);
        } else {
            bodyTex = t("Two or more items must be selected");
            SetIsWarningModal(true);
        }
        SetTextBody(bodyTex);
    };

    const handleMultipleDelete = () => {
        setIsOpen(!isOpen);

        const listUuid: any[] = getNestedIdofArray(
            dynamicTableWrapperContext?.rowData?.data
        );

        try {
            axios
                .delete(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/items`,
                    { data: { itemsIuuds: listUuid } }
                )

                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                })
                .then((): void => {
                    SuccessToast("Suppression effectuée avec succès");
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    React.useEffect(() => {
        getBodyTex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <StyledModal isOpen={isOpen} centered>
            <ModalHeader toggle={toggleOpen}>
                {t("ARCHIVE ARTICLES")}
            </ModalHeader>
            <ModalBody>{textBody}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {!isWarningModal ? t("Cancel") : t("Close")}
                </StyledButton>
                {!isWarningModal && (
                    <StyledButton
                        onClick={handleMultipleDelete}
                        rounded
                        variant="primary"
                    >
                        {t("Archive")}
                    </StyledButton>
                )}
            </ModalFooter>
        </StyledModal>
    );
}
