import React from "react";
import { EyeIcon, EyeCrossedIcon, LockIcon } from "@aureskonnect/react-ui";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";
import { InsertOverrideBasicCompositionInItem } from "@pages/WorkFlow/hooks/helpersBasicComposition";
import { setModifier } from "@store/features/modifierSlice";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type VisibilityProductModalProps = {
    contentModal: any;
    cardData: any;
    uuid: string;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    isVisible: boolean;
    globalIndex: number;
    IsOptionStep: boolean;
    IsBasicCompositionStep: boolean;
    isObligatory: boolean;
    order: number | undefined;
};

export function VisibilityProductModal({
    contentModal,
    cardData,
    uuid,
    setIsModify,
    isModify,
    isVisible,
    globalIndex,
    IsOptionStep,
    IsBasicCompositionStep,
    isObligatory,
    order,
}: VisibilityProductModalProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );

    const handleVisibilityOption = () => {
        const isOverride =
            Object.keys(
                modifierWorkflow[
                    contentModal[contentModal.length - 1]?.previousModifierStep
                ]?.steps[
                    contentModal[contentModal.length - 1]?.previousUuidStep
                ]?.overrides || {}
            ).length > 0;

        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[
                          contentModal[contentModal.length - 1]
                              ?.previousModifierStep
                      ]?.steps[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(
                  JSON.stringify(
                      cardData?.steps?.[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]
                  )
              );

        let copyOverrideOption: any = {};
        isOverride
            ? (copyOverrideOption = modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[
                  contentModal[contentModal.length - 1]?.previousUuidStep
              ]?.overrides.stepItems[
                  contentModal[contentModal.length - 1].triggerItemIuud
                  //  eslint-disable-next-line
              ].hasOwnProperty("overrideOptions")
                  ? modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ]?.steps[
                        contentModal[contentModal.length - 1]?.previousUuidStep
                    ]?.overrides.stepItems[
                        contentModal[contentModal.length - 1].triggerItemIuud
                    ]?.overrideOptions
                  : InsertOverrideOptionInItem(
                        copyOverrideOption,
                        cardData,
                        contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.uuid
                    ))
            : (copyOverrideOption = InsertOverrideOptionInItem(
                  copyOverrideOption,
                  cardData,
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuid
              ));

        const UuidOptions =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep;

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions].content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                isVisible:
                                    !copyOverrideOption[UuidOptions].content[
                                        uuid
                                    ].isVisible,
                            },
                        },
                    },
                },
            },
        };

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [contentModal[contentModal.length - 1]?.previousModifierStep]: {
                    ...modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ],
                    steps: {
                        ...modifierWorkflow[
                            contentModal[contentModal.length - 1]
                                ?.previousModifierStep
                        ].steps,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...modifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    ?.previousModifierStep
                            ].steps[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
        setIsModify(!isModify);
    };
    const handleVisibilityBasicComposition = () => {
        const isOverride =
            Object.keys(
                modifierWorkflow[
                    contentModal[contentModal.length - 1]?.previousModifierStep
                ]?.steps[
                    contentModal[contentModal.length - 1]?.previousUuidStep
                ]?.overrides || {}
            ).length > 0;

        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[
                          contentModal[contentModal.length - 1]
                              ?.previousModifierStep
                      ]?.steps[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(
                  JSON.stringify(
                      cardData?.steps?.[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]
                  )
              );

        let copyOverrideBasicComposition: any = {};
        isOverride
            ? (copyOverrideBasicComposition = modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[
                  contentModal[contentModal.length - 1]?.previousUuidStep
              ]?.overrides.stepItems[
                  contentModal[contentModal.length - 1].triggerItemIuud
                  // eslint-disable-next-line no-prototype-builtins
              ].hasOwnProperty("overrideBasicComposition")
                  ? modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ]?.steps[
                        contentModal[contentModal.length - 1]?.previousUuidStep
                    ]?.overrides.stepItems[
                        contentModal[contentModal.length - 1].triggerItemIuud
                    ]?.overrideBasicComposition
                  : InsertOverrideBasicCompositionInItem(
                        copyOverrideBasicComposition,
                        cardData,
                        contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.uuid
                    ))
            : (copyOverrideBasicComposition =
                  InsertOverrideBasicCompositionInItem(
                      copyOverrideBasicComposition,
                      cardData,
                      contentModal[contentModal.length - 1]?.data[globalIndex!]
                          ?.uuid
                  ));
        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideBasicComposition: {
                    ...copyOverrideBasicComposition,
                    [WORKFLOW_LIST.BASICCOMPOSITION]: {
                        ...copyOverrideBasicComposition[
                            WORKFLOW_LIST.BASICCOMPOSITION
                        ],
                        content: {
                            ...copyOverrideBasicComposition[
                                WORKFLOW_LIST.BASICCOMPOSITION
                            ].content,
                            [uuid]: {
                                ...copyOverrideBasicComposition[
                                    WORKFLOW_LIST.BASICCOMPOSITION
                                ]?.content[uuid],
                                isVisible:
                                    !copyOverrideBasicComposition[
                                        WORKFLOW_LIST.BASICCOMPOSITION
                                    ].content[uuid].isVisible,
                            },
                        },
                    },
                },
            },
        };

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [contentModal[contentModal.length - 1]?.previousModifierStep]: {
                    ...modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ],
                    steps: {
                        ...modifierWorkflow[
                            contentModal[contentModal.length - 1]
                                ?.previousModifierStep
                        ].steps,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...modifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    ?.previousModifierStep
                            ].steps[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
        setIsModify(!isModify);
    };

    return (
        <div
            style={{
                justifyContent: "end",
                paddingRight: "4px",
                display: "flex",
                alignItems: "center",
            }}
        >
            {IsBasicCompositionStep && contentModal.length > 0 && (
                <div className="d-flex justify-content-center align-items-center icon_step__CLZ text-center mr-2">
                    <LockIconWithToolTip
                        type="icon"
                        lengthMax={0}
                        message={
                            isObligatory
                                ? t("Required composant")
                                : t("composant not required")
                        }
                        id={`LockIconComposantModal${globalIndex}${order}`}
                    />

                    <LockIcon
                        id={`LockIconComposantModal${globalIndex}${order}`}
                        role="button"
                        width={20}
                        height={20}
                        style={{
                            fill:
                                // eslint-disable-next-line no-nested-ternary
                                contentModal.length > 0 && isObligatory
                                    ? "red"
                                    : "black",
                        }}
                        onClick={() => {}}
                    />
                </div>
            )}

            <div
                className="icon_step__CLZ text-center"
                style={{
                    display:
                        IsOptionStep || IsBasicCompositionStep
                            ? "block"
                            : "none",
                }}
            >
                <LockIconWithToolTip
                    type="icon"
                    lengthMax={0}
                    message={t("Global visibility")}
                    id={`VisiblityComposantModal${globalIndex}${order}`}
                />
                {isVisible ? (
                    <EyeIcon
                        id={`VisiblityComposantModal${globalIndex}${order}`}
                        height={20}
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            IsOptionStep
                                ? handleVisibilityOption()
                                : handleVisibilityBasicComposition();
                            event.stopPropagation();
                        }}
                    />
                ) : (
                    <EyeCrossedIcon
                        id={`VisiblityComposantModal${globalIndex}${order}`}
                        height={20}
                        width={20}
                        fill="red"
                        style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            IsOptionStep
                                ? handleVisibilityOption()
                                : handleVisibilityBasicComposition();
                            event.stopPropagation();
                        }}
                    />
                )}
            </div>
        </div>
    );
}
