import React from "react";

function FlagIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.357"
            height="22.124"
            viewBox="0 0 17.357 22.124"
            {...props}
        >
            <path
                id="Icon_ionic-ios-flag"
                data-name="Icon ionic-ios-flag"
                d="M23.6,5.468c-.168.029-.347.058-.537.081a30.128,30.128,0,0,1-3.286.3,18.871,18.871,0,0,1-4.24-.7,19.625,19.625,0,0,0-4.37-.645c-3.047,0-4.083.7-4.191.772l-.222.167V25.794a.808.808,0,0,0,.667.824.777.777,0,0,0,.851-.8v-8.9a.463.463,0,0,1,.347-.455,12.405,12.405,0,0,1,2.548-.225,19.265,19.265,0,0,1,4.153.893,18.067,18.067,0,0,0,4.522.663,24.246,24.246,0,0,0,3.893-.38.456.456,0,0,0,.374-.455V5.923A.45.45,0,0,0,23.6,5.468Z"
                transform="translate(-6.75 -4.5)"
            />
        </svg>
    );
}

export default FlagIcon;
