import React from "react";

type optionCheckboxProps = {
    key: string;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    title: string;
    disabled: boolean;
};

function OptionCheckbox({
    key,
    checked,
    onChange,
    title,
    disabled,
}: optionCheckboxProps) {
    const ETKType = localStorage.getItem("ETKType");
    return (
        <div
            key={key}
            style={
                disabled && ETKType === "3"
                    ? {
                          display: "flex",
                          alignItems: "center",
                          pointerEvents: "none",
                          opacity: "0.5",
                      }
                    : { display: "flex", alignItems: "center" }
            }
        >
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                style={{ width: "17px" }}
            />

            <label className=" mx-3 form-check-label" htmlFor="title">
                {title}
            </label>
        </div>
    );
}

export default OptionCheckbox;
