import React from "react";

import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { RootState } from "@store/index";
import { useSelector } from "react-redux";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { DominantColor } from "@components/Common";
import { filterCategories, filterSteps } from "./helpers";
import ItemSelectionDropdownHeader from "./ItemSelectionDropdownHeader";
import StepWithItems from "./StepsTree/StepWithItems";
import { categorySize } from "./CategoriesTree/helper";
import CategoryWithItems from "./CategoriesTree/CategoryWithItems";

export default function ItemSelectionDropdown({
    searchedDesignation,
}: {
    searchedDesignation: string;
}) {
    const dominantColor: string = DominantColor();
    const { t } = useTranslation();

    const { categories, steps } = useSelector(
        (State: RootState) => State.workFlowSlice.elementsTree
    );

    const [showSteps, setShowSteps] = React.useState(true);
    const [showCategories, setShowCategories] = React.useState(true);
    const [selectedItems, setSelectedItems] = React.useState<ItemWithIuud[]>(
        []
    );
    const [selectedStepItems, setSelectedStepItems] = React.useState<
        StepItemWithIuud[]
    >([]);
    const [collapsedStepsUuids, setCollapsedStepsUuids] = React.useState<
        string[]
    >([]);
    const [collapsedCategoriesUuids, setCollapsedCategoriesUuids] =
        React.useState<string[]>([]);

    const filteredCategories: CategoryWithChildrenType[] = [];
    const filteredSteps: StepWithChildrenType[] = [];

    if (searchedDesignation !== "") {
        filteredCategories.push(
            ...filterCategories(categories, searchedDesignation)
        );
        filteredSteps.push(...filterSteps(steps, searchedDesignation));
    } else {
        filteredCategories.push(...categories);
        filteredSteps.push(...steps);
    }

    const elements: any[] = [...filteredCategories, ...filteredSteps];

    const listRef: any = React.useRef({});

    return (
        <div style={{ width: "400px" }}>
            <ItemSelectionDropdownHeader
                showCategories={showCategories}
                showSteps={showSteps}
                setShowCategories={setShowCategories}
                setShowSteps={setShowSteps}
            />
            <div
                className="px-3"
                style={{
                    overflow: "hidden",
                    maxHeight: "40vh",
                    minHeight: "30vh",
                }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height}
                            itemCount={elements.length}
                            itemSize={(index) =>
                                // eslint-disable-next-line no-nested-ternary
                                elements[index].childCategories !== undefined
                                    ? collapsedCategoriesUuids.includes(
                                          elements[index].iuud
                                      )
                                        ? 35
                                        : 35 *
                                              categorySize(
                                                  elements[index],
                                                  collapsedCategoriesUuids
                                              ) +
                                          35
                                    : collapsedStepsUuids.includes(
                                          elements[index].iuud
                                      )
                                    ? 35
                                    : elements[index].items.length * 35 + 35
                            }
                            width={width}
                            ref={listRef}
                            style={{ overflowX: "hidden" }}
                        >
                            {({
                                index,
                                style = {
                                    overflow: "hidden",
                                },
                            }) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                React.useEffect(() => {
                                    listRef.current.resetAfterIndex(0, false);
                                }, []);
                                return (
                                    <div style={style}>
                                        {elements[index].childCategories !==
                                        undefined ? (
                                            <CategoryWithItems
                                                key={elements[index].iuud}
                                                category={elements[index]}
                                                selectedItems={selectedItems}
                                                setSelectedItems={
                                                    setSelectedItems
                                                }
                                                collapsedCategoriesUuids={
                                                    collapsedCategoriesUuids
                                                }
                                                setCollapsedCategoriesUuids={
                                                    setCollapsedCategoriesUuids
                                                }
                                            />
                                        ) : (
                                            <StepWithItems
                                                key={elements[index].iuud}
                                                step={elements[index]}
                                                selectedStepItems={
                                                    selectedStepItems
                                                }
                                                collapsedStepsUuids={
                                                    collapsedStepsUuids
                                                }
                                                setSelectedStepItems={
                                                    setSelectedStepItems
                                                }
                                                setCollapsedStepsUuids={
                                                    setCollapsedStepsUuids
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            }}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <hr />
            <StyledButton
                onClick={() => {}}
                rounded
                disabled={
                    selectedItems.length === 0 && selectedStepItems.length === 0
                }
                variant={dominantColor}
                style={{ marginLeft: "auto" }}
            >
                {t("Insert")}
            </StyledButton>
        </div>
    );
}
