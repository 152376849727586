import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DynamicTableWrapperContext } from "@components/Common";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";
import { PencilIcon } from "@aureskonnect/react-ui";
import { RootState } from "@store/.";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type editStepProps = {
    isMultipleStep: boolean;
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    data: WorkFlowDataType;
    globalIndex: number;
    uuid: string;
    setTypeElement: React.Dispatch<React.SetStateAction<string>>;
    cardData: any;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
};
export function EditStep({
    isMultipleStep,
    IsBasicCompositionStep,
    IsOptionStep,
    data,
    globalIndex,
    uuid,
    setTypeElement,
    cardData,
    setIsClickStep,
    isClickStep,
    setIndexSelected,
}: editStepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );

    const handleEdit = (event: any) => {
        if (Object.keys(data).length > 0) {
            const copyPathUpdateItem = {
                pathUpdateItem: [
                    ...globalWorkflow[globalIndex!].previousUuids!,
                    uuid,
                ],
                indexWorkflowGlobal: globalIndex!,
                uuid,
                isModal: false,
                previousModifier: globalWorkflow[globalIndex!].previousModifier,
                uuidStep: globalWorkflow[globalIndex!].uuidStep,
            };
            dispatch(setUpdateItemWorkflow(copyPathUpdateItem));
            event.stopPropagation();
            setTypeElement(WORKFLOW_LIST.STEPS);

            dynamicTableWrapperContext?.setActionColumnDisplay(
                (isActionColumnDisplayed) => !isActionColumnDisplayed
            );
            if (Object.keys(globalWorkflow[globalIndex]?.override).length > 0) {
                dynamicTableWrapperContext?.setRowData({
                    iuud: globalWorkflow[globalIndex].uuidStep,
                    data: globalWorkflow[globalIndex!].override,
                });
            } else {
                const stepForm = JSON.parse(
                    JSON.stringify(
                        cardData?.steps?.[
                            globalWorkflow[globalIndex!]?.uuidStep
                        ]
                    )
                );
                /* add reference & image in step items */
                stepForm.stepItems = Object.entries(stepForm.stepItems).reduce(
                    (prev: any, [key, Value]: any) => {
                        Value.refrence = cardData?.items[key].reference;
                        Value.imageUrl =
                            cardData?.items[key].imageUrl.Default.urlDefault;
                        return { ...prev, [key]: Value };
                    },
                    {}
                );

                dynamicTableWrapperContext?.setRowData({
                    iuud: globalWorkflow[globalIndex].uuidStep,
                    data: stepForm,
                });
            }

            setIndexSelected(globalIndex!);
            setIsClickStep(!isClickStep);
        }
    };

    return (
        <div
            className={
                isMultipleStep
                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                    : "icon_step__CLZ text-center mr-2"
            }
            style={{
                display:
                    IsBasicCompositionStep || IsOptionStep
                        ? "none"
                        : " d-flex justify-content-center align-items-center",
            }}
        >
            <LockIconWithToolTip
                type="icon"
                lengthMax={0}
                message={t("Edit")}
                id={`iconmodifier${globalIndex}`}
            />
            <PencilIcon
                id={`iconmodifier${globalIndex}`}
                role="button"
                width={20}
                height={20}
                onClick={handleEdit}
            />
        </div>
    );
}
