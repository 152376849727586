import React from "react";
import { RootState } from "@store/.";
import { EyeIcon, EyeCrossedIcon, LockIcon } from "@aureskonnect/react-ui";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { useDispatch, useSelector } from "react-redux";
import { setWorkflow } from "@store/features/workflowSlice";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { InsertOverrideBasicCompositionInItem } from "@pages/WorkFlow/hooks/helpersBasicComposition";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";

import { setWorkflowData } from "@store/features/workflowDataSlice";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";

type visibilityProductProps = {
    isVisible: boolean;
    typeGolbal: string | undefined;
    type: string;
    item: any;
    uuid: string;
    cardData: any;
    globalIndex: number;
    order?: number;
    startIndex: number;
    IsBasicCompositionStep: boolean;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    isObligatory: boolean;
};

export function VisibilityProduct({
    isVisible,
    order,
    typeGolbal,
    type,
    item,
    uuid,
    cardData,
    globalIndex,
    startIndex,
    IsBasicCompositionStep,
    setIsUnsavedDataMultiple,
    isObligatory,
}: visibilityProductProps) {
    const dispatch = useDispatch();
    const ETKType = localStorage.getItem("ETKType");
    const { t } = useTranslation();
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData: any = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const handleVisibility = () => {
        if (type === WORKFLOW_LIST.ITEMS || type === WORKFLOW_LIST.CATEGORIES) {
            const currentItem: ItemType | CategoryType =
                Object.keys(item.override).length > 0
                    ? item.override[uuid]
                    : cardData?.[type]?.[uuid];
            const updatedWorkflowStepElement = {
                ...currentItem,
                visibilityInfo: {
                    ...currentItem?.visibilityInfo,
                    isVisible: !isVisible,
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        uuid,
                        globalIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[globalIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        uuid
                    )
                )
            );
        }
    };
    const handleOptionVisibility = async () => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;

        if (globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS) {
            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[globalUuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]
                    : cardData?.items?.[globalUuid];

            let copyOverrideOption: WorkflowOptionType = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.overrideOptions || {}
            ).length > 0
                ? (copyOverrideOption =
                      globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]?.overrideOptions)
                : (copyOverrideOption = InsertOverrideOptionInItem(
                      copyOverrideOption,
                      cardData,
                      globalUuid
                  ));

            const UuidOptions = globalWorkflow[globalIndex!]?.uuidStep;
            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions]?.content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                isVisible:
                                    !copyOverrideOption[UuidOptions].content[
                                        uuid
                                    ].isVisible,
                            },
                        },
                    },
                },
            };
            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        globalUuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        globalUuid
                    )
                )
            );
        }
    };
    const handleBasicCompositionVisibility = async () => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;

        if (IsBasicCompositionStep) {
            const currentItem: any =
                Object.keys(
                    globalWorkflow[startIndex].data[globalUuid].override || {}
                ).length > 0
                    ? globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]
                    : cardData?.items?.[globalUuid];

            let copyOverrideBasicComposition: WorkflowOptionType = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.overrideBasicComposition || {}
            ).length > 0
                ? (copyOverrideBasicComposition =
                      globalWorkflow[startIndex].data[globalUuid].override[
                          globalUuid
                      ]?.overrideBasicComposition)
                : (copyOverrideBasicComposition =
                      InsertOverrideBasicCompositionInItem(
                          copyOverrideBasicComposition,
                          cardData,
                          globalUuid
                      ));

            const updatedWorkflowStepElement = {
                ...currentItem,
                overrideBasicComposition: {
                    ...copyOverrideBasicComposition,
                    [WORKFLOW_LIST.BASICCOMPOSITION]: {
                        ...copyOverrideBasicComposition[
                            WORKFLOW_LIST.BASICCOMPOSITION
                        ],
                        content: {
                            ...copyOverrideBasicComposition[
                                WORKFLOW_LIST.BASICCOMPOSITION
                            ]?.content,
                            [uuid]: {
                                ...copyOverrideBasicComposition[
                                    WORKFLOW_LIST.BASICCOMPOSITION
                                ]?.content[uuid],
                                isVisible:
                                    !copyOverrideBasicComposition[
                                        WORKFLOW_LIST.BASICCOMPOSITION
                                    ].content[uuid].isVisible,
                            },
                        },
                    },
                },
            };

            dispatch(
                setWorkflow(
                    overrideWorkflowDataElementByUuid(
                        globalWorkflow,
                        updatedWorkflowStepElement,
                        globalUuid,
                        startIndex
                    )
                )
            );

            const { previousUuids } = globalWorkflow[startIndex];

            dispatch(
                setWorkflowData(
                    overrideWorkflowDataElementWithPath(
                        globalWorkflowData,
                        previousUuids,
                        updatedWorkflowStepElement,
                        globalUuid
                    )
                )
            );
        }
    };

    const handleClickVisibility = () => {
        if (
            multipleWorkflow.length > 0 &&
            (type === "items" || type === "categories")
        ) {
            setIsUnsavedDataMultiple(true);

            return;
        }
        if (typeGolbal === WORKFLOW_LIST.OPTIONS) {
            /* option  */
            handleOptionVisibility();
        } else if (IsBasicCompositionStep) {
            /* composition de base */
            handleBasicCompositionVisibility();
        } else {
            /* item */
            handleVisibility();
        }
    };

    return (
        <div
            className={ETKType === "3" ? "disabledDiv__CLZ" : ""}
            style={{
                justifyContent: "end",
                paddingRight: "4px",
                display: "flex",
                alignItems: "center",
            }}
        >
            {IsBasicCompositionStep && (
                <div className="d-flex justify-content-center align-items-center icon_step__CLZ text-center mr-2">
                    <LockIconWithToolTip
                        type="icon"
                        lengthMax={0}
                        message={
                            isObligatory
                                ? t("Required composant")
                                : t("composant not required")
                        }
                        id={`LockIconComposant${globalIndex}${order}`}
                    />
                    <LockIcon
                        id={`LockIconComposant${globalIndex}${order}`}
                        role="button"
                        width={20}
                        height={20}
                        style={{
                            fill: isObligatory ? "red" : "black",
                        }}
                    />
                </div>
            )}

            <div
                className="icon_step__CLZ text-center"
                style={{
                    zIndex: "450",
                }}
            >
                <LockIconWithToolTip
                    type="icon"
                    lengthMax={0}
                    message={
                        isVisible
                            ? t("Global visibility")
                            : t("Global visibility")
                    }
                    id={`lockIcon${globalIndex}${order}`}
                />

                {isVisible ? (
                    <EyeIcon
                        id={`lockIcon${globalIndex}${order}`}
                        height={20}
                        width={20}
                        style={{
                            zIndex: "150",
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            if (ETKType !== "3") {
                                handleClickVisibility();
                            }
                            event.stopPropagation();
                        }}
                    />
                ) : (
                    <EyeCrossedIcon
                        id={`lockIcon${globalIndex}${order}`}
                        height={20}
                        width={20}
                        fill="red"
                        style={{
                            zIndex: "150",
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            if (ETKType !== "3") {
                                handleClickVisibility();
                            }
                            event.stopPropagation();
                        }}
                    />
                )}
            </div>
        </div>
    );
}
