import { CategoryIcon } from "@aureskonnect/react-ui";

type CategoryPropsType = {
    category: CategoryWithChildrenType;
    isCurrentCategorySelected: boolean;
    setCollapsedCategoriesUuids: React.Dispatch<React.SetStateAction<string[]>>;
    addSelectedItem: Function;
    deleteSelectedItem: Function;
};

export default function Category({
    category,
    isCurrentCategorySelected,
    setCollapsedCategoriesUuids,
    addSelectedItem,
    deleteSelectedItem,
}: CategoryPropsType) {
    const deleteSelectedCategoryItems = (
        selectedCategoryItems: ItemWithIuud[]
    ) => {
        selectedCategoryItems.forEach((selectedItem) =>
            deleteSelectedItem(selectedItem)
        );
    };

    const addSelectedCategoryItems = (
        selectedCategoryItems: ItemWithIuud[]
    ) => {
        selectedCategoryItems.forEach((selectedItem) => {
            deleteSelectedItem(selectedItem);
            addSelectedItem(selectedItem);
        });
    };
    return (
        <div className="d-flex justify-content-between my-1 ">
            <div
                role="button"
                tabIndex={0}
                onClick={() =>
                    setCollapsedCategoriesUuids((collapsedCategoriesUuids) => {
                        if (collapsedCategoriesUuids.includes(category.iuud)) {
                            return [...collapsedCategoriesUuids].filter(
                                (categoryUuid) => categoryUuid !== category.iuud
                            );
                        }
                        return [...collapsedCategoriesUuids, category.iuud];
                    })
                }
            >
                <CategoryIcon
                    width={20}
                    height={20}
                    fill="#6e5100"
                    className="mr-2"
                    style={{
                        borderRadius: "4px",
                    }}
                />
                {category.title}
            </div>
            <div>
                <input
                    type="checkbox"
                    className="ml-3"
                    id={category.iuud}
                    disabled={category.items.length === 0}
                    checked={isCurrentCategorySelected}
                    onChange={() => {
                        isCurrentCategorySelected
                            ? deleteSelectedCategoryItems(category.items)
                            : addSelectedCategoryItems(category.items);
                    }}
                />
            </div>
        </div>
    );
}
