import React from "react";

import { useSelector } from "react-redux";
import { ModalBody, ModalHeader } from "reactstrap";
import { StyledModal, StyledH2 } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import DisplayNameModalFooter from "./DisplayNameModalFooter";
import DisplayNameTable from "./DisplayNameTable";

type DisplayNameModalProps = {
    dispatchDisplayName: Function;
    displayName: DisplayNameType;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    DefaultValueName: string;
    elementType?: string;
};
export default function DisplayNameModal({
    displayName,
    dispatchDisplayName,
    setIsOpen,
    isOpen,
    DefaultValueName,
    elementType,
}: DisplayNameModalProps) {
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [impression, setImpression] = React.useState<LanguageType[]>(
        displayName.Default.impression
    );

    const [sellingSupport, setSellingSupport] = React.useState<
        sellingSupportType[]
    >(displayName.Default.salesSupport);

    React.useEffect(() => {
        if (sellingSupport.length === 0) {
            (async () => {
                await fetch("/fakeData/sellingSupport.json")
                    .then((res) => res.json())
                    .then((data) => setSellingSupport(data));
            })();
        }
        if (impression.length === 0) {
            (async () => {
                await fetch("/fakeData/impression.json")
                    .then((res) => res.json())
                    .then((data) => setImpression(data));
            })();
        }
    }, [impression.length, sellingSupport.length]);

    return (
        <StyledModal size="xl" isOpen={isOpen}>
            <ModalHeader
                toggle={() => {
                    setIsOpen(false);
                }}
            >
                <StyledH2 className="text-uppercase">
                    NOM D&apos;AFFICHAGE AVANCÉ
                </StyledH2>
            </ModalHeader>
            <ModalBody
                style={
                    isEditing &&
                    cardType === "shop" &&
                    cardStatus === "En production"
                        ? { pointerEvents: "none", opacity: "1" }
                        : {
                              maxHeight: "750px",
                              overflowY: "auto",
                          }
                }
            >
                <DisplayNameTable
                    impression={impression}
                    setImpression={setImpression}
                    sellingSupport={sellingSupport}
                    setSellingSupport={setSellingSupport}
                    DefaultValueName={DefaultValueName}
                    elementType={elementType}
                />
            </ModalBody>
            <DisplayNameModalFooter
                setActive={setIsOpen}
                impression={impression}
                sellingSupport={sellingSupport}
                displayName={displayName}
                dispatchDisplayName={dispatchDisplayName}
            />
        </StyledModal>
    );
}
