import React from "react";
import { EyeIcon, EyeCrossedIcon } from "@aureskonnect/react-ui";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";
import { setModifier } from "@store/features/modifierSlice";

type VisibilityStepModalProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    optionIsVisible: any;
    basicCompositionIsVisible: any;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    globalIndex: number;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    isClickStep: boolean;
    contentModal: any;
    cardData: any;
};

export function VisibilityStepModal({
    IsBasicCompositionStep,
    IsOptionStep,
    optionIsVisible,
    basicCompositionIsVisible,
    setIndexSelected,
    globalIndex,
    setIsClickStep,
    isClickStep,
    contentModal,
    cardData,
}: VisibilityStepModalProps) {
    const dispatch = useDispatch();
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );

    const handleVisibility = () => {
        if (
            contentModal[contentModal.length - 1]?.data[globalIndex!]
                ?.uuidStep === WORKFLOW_LIST.BASICCOMPOSITION
        ) {
            const UuidItem =
                contentModal[contentModal.length - 1].triggerItemIuud;
            const previousUuidStep =
                contentModal[contentModal.length - 1]?.previousUuidStep;
            const previousModifierStep =
                contentModal[contentModal.length - 1]?.previousModifierStep;

            const isOverride =
                Object.keys(
                    modifierWorkflow[previousModifierStep]?.steps[
                        previousUuidStep
                    ]?.overrides || {}
                ).length > 0;

            const copyStepForm: any = isOverride
                ? JSON.parse(
                      JSON.stringify(
                          modifierWorkflow[previousModifierStep]?.steps[
                              previousUuidStep
                          ]?.overrides
                      )
                  )
                : JSON.parse(
                      JSON.stringify(cardData?.steps?.[previousUuidStep])
                  );

            const visibility =
                copyStepForm.stepItems[UuidItem].basicCompositionVisibility ===
                undefined
                    ? !cardData?.items[UuidItem].visibilityInfo
                          .basicCompositionVisibility
                    : !copyStepForm.stepItems[UuidItem]
                          .basicCompositionVisibility;

            copyStepForm.stepItems = {
                ...copyStepForm.stepItems,
                [UuidItem]: {
                    ...copyStepForm.stepItems[UuidItem],
                    basicCompositionVisibility: visibility,
                },
            };

            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [previousModifierStep]: {
                        ...modifierWorkflow[previousModifierStep],
                        steps: {
                            ...modifierWorkflow[previousModifierStep].steps,
                            [previousUuidStep]: {
                                ...modifierWorkflow[previousModifierStep].steps[
                                    previousUuidStep
                                ],
                                overrides: {
                                    ...copyStepForm,
                                },
                            },
                        },
                    },
                })
            );
        }
    };

    const handleOptionVisibility = () => {
        if (
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
            WORKFLOW_LIST.OPTIONS
        ) {
            const previousModifierStep =
                contentModal[contentModal.length - 1]?.previousModifierStep;
            const previousUuidStep =
                contentModal[contentModal.length - 1]?.previousUuidStep;
            const UuidOptions =
                contentModal[contentModal.length - 1]?.data[globalIndex!]
                    ?.uuidStep;
            const UuidItem =
                contentModal[contentModal.length - 1].triggerItemIuud;

            const isOverride =
                Object.keys(
                    modifierWorkflow[previousModifierStep]?.steps[
                        previousUuidStep
                    ]?.overrides || {}
                ).length > 0;
            const copyStepForm: any = isOverride
                ? JSON.parse(
                      JSON.stringify(
                          modifierWorkflow[previousModifierStep]?.steps[
                              previousUuidStep
                          ]?.overrides
                      )
                  )
                : JSON.parse(
                      JSON.stringify(cardData?.steps?.[previousUuidStep])
                  );
            let copyOverrideOption: any = {};
            isOverride &&
            Object.keys(
                modifierWorkflow[previousModifierStep]?.steps[previousUuidStep]
                    ?.overrides.stepItems[UuidItem]?.overrideOptions || {}
            ).length > 0
                ? (copyOverrideOption =
                      modifierWorkflow[previousModifierStep]?.steps[
                          previousUuidStep
                      ]?.overrides.stepItems[UuidItem]?.overrideOptions)
                : (copyOverrideOption = InsertOverrideOptionInItem(
                      copyOverrideOption,
                      cardData,
                      contentModal[contentModal.length - 1]?.data[globalIndex!]
                          ?.uuid
                  ));

            copyStepForm.stepItems = {
                ...copyStepForm.stepItems,
                [UuidItem]: {
                    ...copyStepForm.stepItems[UuidItem],
                    overrideOptions: {
                        ...copyOverrideOption,
                        [UuidOptions]: {
                            ...copyOverrideOption[UuidOptions],
                            isVisible:
                                !copyOverrideOption[UuidOptions].isVisible,
                        },
                    },
                },
            };

            dispatch(
                setModifier({
                    ...modifierWorkflow,
                    [previousModifierStep]: {
                        ...modifierWorkflow[previousModifierStep],
                        steps: {
                            ...modifierWorkflow[previousModifierStep].steps,
                            [previousUuidStep]: {
                                ...modifierWorkflow[previousModifierStep].steps[
                                    previousUuidStep
                                ],
                                overrides: {
                                    ...copyStepForm,
                                },
                            },
                        },
                    },
                })
            );
        }
    };

    return (
        <div
            className="icon_step__CLZ text-center mr-2"
            style={{
                visibility:
                    IsBasicCompositionStep || IsOptionStep
                        ? "visible"
                        : "hidden",
            }}
        >
            {(optionIsVisible && IsOptionStep) ||
            (basicCompositionIsVisible && IsBasicCompositionStep) ? (
                <EyeIcon
                    height={20}
                    width={20}
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        IsOptionStep
                            ? handleOptionVisibility()
                            : handleVisibility();
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                        event.stopPropagation();
                    }}
                />
            ) : (
                <EyeCrossedIcon
                    height={20}
                    width={20}
                    fill="red"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                        IsOptionStep
                            ? handleOptionVisibility()
                            : handleVisibility();
                        setIndexSelected(globalIndex);
                        setIsClickStep(!isClickStep);
                        event.stopPropagation();
                    }}
                />
            )}
        </div>
    );
}
