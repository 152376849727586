import React from "react";

import ReactSwitch from "react-switch";

import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/.";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";

type SalesModeVisibilityPropsType = {
    defaultVisibility: visibilityCategoryType;
    dispatch: Function;
    salesMode: string;
};
export default function SalesModeVisibility({
    defaultVisibility,
    salesMode,
    dispatch,
}: SalesModeVisibilityPropsType) {
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): any => state.multipleEditSlice.isMultiModify
    );

    function updateSaleModeVisibility(
        defaultVisibility: Record<string, visibilityInfoType>,
        salesMode: string
    ) {
        dispatch({
            type: ACTIONS.INIT_VISIBILITY,
            payload: {
                ...defaultVisibility,

                [salesMode]: {
                    ...defaultVisibility[salesMode],
                    visibility: !defaultVisibility[salesMode].visibility,
                },
            },
        });

        isMultiModify &&
            dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.VISIBILITYINFO));
    }

    return (
        <div>
            <ReactSwitch
                activeBoxShadow="0px 0px 1px 10px #000000"
                boxShadow="0px 1px 5px 0px #000000"
                handleDiameter={20}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                onChange={(): void => {
                    updateSaleModeVisibility(
                        defaultVisibility.default,
                        salesMode
                    );
                }}
                checked={defaultVisibility.default[salesMode].visibility}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={45}
                height={15}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!defaultVisibility.isVisible}
            />
        </div>
    );
}
