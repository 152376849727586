import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import {
    DominantColor,
    LoadingDataAnimation,
    DynamicTableWrapperContext,
    CommonMenu,
    CustomCRUDColumn,
} from "@components/Common";
import { RootState } from "@store/.";

import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import DeleteStepModal from "./DeleteStepModal";
import DuplicateStepModal from "./DuplicateStepModal";

type stepListProps = {
    setInitialData?: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function StepsList({
    setInitialData,
}: stepListProps): JSX.Element {
    const { t } = useTranslation();
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const dominantColor = DominantColor();
    const [stepIuud, setStepIuud] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const dispatch = useDispatch();

    const handleDelete = (e: any) => {
        handleOnEditoption(e.selectedRow);
        if (e?.selectedRow.selectedRows.length <= 1) {
            setIsOpen(!isOpen);
            setStepIuud(e.selectedRow.iuud);
            dynamicTableWrapperContext?.setActionColumnDisplay(false);
            dynamicTableWrapperContext?.setLocalFilterActive(false);
        }
    };
    const handleEdit = (e: any) => {
        if (e?.selectedRow.selectedRows.length <= 1) {
            handleOnEditoption(e.selectedRow);

            dynamicTableWrapperContext?.setActionColumnDisplay(true);
        }
    };

    const handleDuplicate = (e: any) => {
        if (e?.selectedRow.selectedRows.length <= 1) {
            handleOnEditoption(e.selectedRow);
            setIsShow(!isShow);
            dynamicTableWrapperContext?.setLocalFilterActive(false);
            dynamicTableWrapperContext?.setActionColumnDisplay(false);
        }
    };

    const customJsxAction = (e: any): JSX.Element =>
        e.selectedRow?.subRows?.length >= 0 ? (
            <CustomCRUDColumn
                e={e}
                handleDuplicate={handleDuplicate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        ) : (
            <div />
        );
    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxAction,
        },
    ];

    function handleOnEditoption(e: any): void {
        dynamicTableWrapperContext?.setRowData({
            iuud: e.iuud,
            data: e.step,
        });
    }
    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData(null);
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
    };

    return !isLoading ? (
        <div>
            <DynamicTable
                url={`${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/steps-details`}
                canSort
                name="step_table"
                canExpand
                customSelect
                canResize
                showGlobalFilter
                showFilter
                setData={setInitialData}
                actionColumn={() => null}
                arrayOfCustomColumns={arrayOfCustomColumns}
                customJsxSideFilterButton={
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            className="d-flex align-items-center"
                            onSubmit={(): void => {
                                if (isDirty) {
                                    setUnsavedChangesWarningModalAction(
                                        () => handleSubmit
                                    );
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dispatch(setIsEditing(false));
                                    handleSubmit();
                                }
                            }}
                        >
                            {!dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant={dominantColor}
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    {t("Add Step")}
                                </StyledButtonWithIcon>
                            )}
                            {dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledIconButton
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                    }}
                                    icon="PlusIcon"
                                    className="bg-dark"
                                    rounded
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    <PlusIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            )}
                            {cardType === "shop" &&
                            cardStatus === "En production" ? null : (
                                <CommonMenu title="Steps" />
                            )}
                        </AvForm>
                    </div>
                }
                filterActive={dynamicTableWrapperContext?.filterActive}
                setLocalFilterActive={
                    dynamicTableWrapperContext?.setLocalFilterActive
                }
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                onClick={(row: any) => {
                    if (isDirty) {
                        setIsUnsavedChangesWarningModalOpen(true);
                        setUnsavedChangesWarningModalAction(() => () => {
                            dynamicTableWrapperContext?.setRowData({
                                iuud: row.original.iuud,
                                data: row.original.step,
                            });
                        });
                    } else {
                        dispatch(setIsEditing(false));
                        dynamicTableWrapperContext?.setRowData({
                            iuud: row.original.iuud,
                            data: row.original.step,
                        });
                    }
                }}
            />
            <DeleteStepModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                stepToDeleteIuud={stepIuud}
                setIsLoading={setIsLoading}
            />
            <DuplicateStepModal
                isShow={isShow}
                setIsShow={setIsShow}
                setIsLoading={setIsLoading}
            />
            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    ) : (
        <LoadingDataAnimation />
    );
}
