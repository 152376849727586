import { SVGProps } from "react";

export default function SaleAddIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.805"
            height="20.209"
            viewBox="0 0 17.805 20.209"
            {...props}
        >
            <g id="save-money" transform="translate(-0.359)">
                <rect
                    id="Rectangle_10647"
                    data-name="Rectangle 10647"
                    width={30}
                    height={30}
                    rx={15}
                    transform="translate(-2)"
                    fill={props.stopColor}
                />
                <path
                    id="Tracé_21026"
                    data-name="Tracé 21026"
                    d="M187.481,151.938a.316.316,0,0,0-.114,0,.754.754,0,0,1-.667-.747.331.331,0,0,0-.663,0,1.418,1.418,0,0,0,1.043,1.365v.331a.331.331,0,1,0,.663,0v-.311a1.415,1.415,0,0,0-.29-2.8.753.753,0,1,1,.753-.753.331.331,0,1,0,.663,0,1.418,1.418,0,0,0-1.126-1.386v-.3a.331.331,0,1,0-.663,0v.322a1.415,1.415,0,0,0,.373,2.781.752.752,0,0,1,.028,1.5Zm0,0"
                    transform="translate(-177.985 -140.909)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_21027"
                    data-name="Tracé 21027"
                    d="M104.2,111.3A4.807,4.807,0,1,0,109,106.492,4.807,4.807,0,0,0,104.2,111.3ZM109,107.155a4.144,4.144,0,1,1-4.144,4.144A4.144,4.144,0,0,1,109,107.155Zm0,0"
                    transform="translate(-99.533 -102.079)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_21028"
                    data-name="Tracé 21028"
                    d="M15.91,327.677l-2.6,1.227a2.115,2.115,0,0,0-1.8-1.113l-2.4-.066a2.532,2.532,0,0,1-1.089-.281l-.244-.126a4.3,4.3,0,0,0-3.985,0l.015-.553a.331.331,0,0,0-.322-.341l-2.629-.072a.331.331,0,0,0-.34.322L.36,332.447a.332.332,0,0,0,.322.341l2.629.072H3.32a.331.331,0,0,0,.331-.322l.008-.276.683-.366a1.171,1.171,0,0,1,.874-.1l4.078,1.144.022.005a4.369,4.369,0,0,0,.9.092,4.424,4.424,0,0,0,1.848-.4.311.311,0,0,0,.041-.023l5.912-3.824a.332.332,0,0,0,.109-.442,1.675,1.675,0,0,0-2.211-.674ZM1.032,332.133l.14-5.106,1.966.054L3,332.188Zm10.729-.087a3.759,3.759,0,0,1-2.3.256L5.4,331.162a1.833,1.833,0,0,0-1.366.149l-.349.187.093-3.395a3.644,3.644,0,0,1,3.7-.2l.244.126a3.2,3.2,0,0,0,1.376.355l2.4.066a1.454,1.454,0,0,1,1.382,1.159l-3.571-.1a.332.332,0,1,0-.018.663l3.941.108h.009a.331.331,0,0,0,.331-.322,2.1,2.1,0,0,0-.032-.43l2.663-1.258.008,0a1.013,1.013,0,0,1,1.145.16Zm0,0"
                    transform="translate(0 -312.833)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_21029"
                    data-name="Tracé 21029"
                    d="M212.866,3.481V.331a.331.331,0,0,0-.663,0V3.481a.331.331,0,0,0,.663,0Zm0,0"
                    transform="translate(-203.065 0)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_21030"
                    data-name="Tracé 21030"
                    d="M272.866,41.823V40.331a.331.331,0,0,0-.663,0v1.492a.331.331,0,0,0,.663,0Zm0,0"
                    transform="translate(-260.579 -38.343)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_21031"
                    data-name="Tracé 21031"
                    d="M152.866,41.823V40.331a.331.331,0,0,0-.663,0v1.492a.331.331,0,1,0,.663,0Zm0,0"
                    transform="translate(-145.552 -38.343)"
                    fill={props.fill}
                />
            </g>
        </svg>
    );
}
