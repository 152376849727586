import React from "react";

import { useTranslation } from "react-i18next";

import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { PageTitle } from "@components/Common";

import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
    filterName?: string;
    canFilter?: boolean;
};

function ModeOfSale() {
    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const franchiseId = localStorage.getItem("franchiseId") || "";
    const shopId = localStorage.getItem("shopId") || "";
    let url = `${process.env.REACT_APP_SETTINGS_API_URL}settings/general/mode_of_sale?franchiseId=${franchiseId}&userId=106`;

    if (shopId !== "0" && shopId !== "") {
        url += `&shopId=${shopId}`;
    }

    const arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Mode of sale"),
            //  eslint-disable-next-line
            customJsx: (e: any) => <NumberModeOfSale e={e} />,
            filterName: "Mode de vente",
            canFilter: true,
        },
    ];
    // eslint-disable-next-line react/no-unstable-nested-components
    function NumberModeOfSale(e: any) {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz d-flex justify-content-center">
                        {e.e.selectedRow?.Boutique !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow["Mode of sale"]
                )}
            </React.Fragment>
        );
    }

    return (
        <div>
            <PageTitle title="Modes de vente" />
            <StyledBreadcrumb
                items={[
                    {
                        item: "CATALOGUE ",
                        link: "home",
                    },
                    {
                        item: "CONFIGIRATION",
                        link: "cards",
                    },
                    { item: t("MODE OF SALE"), link: "cards" },
                ]}
            />
            <HeaderComponent title={t("Please set your sales methods...")} />

            <DynamicTable
                url={url}
                canResize
                canExpand
                arrayOfCustomColumns={arrayOfCustomColumns}
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                elevationTable={1}
                name="VatRate"
            />
        </div>
    );
}

export default ModeOfSale;
