import React from "react";

import { StyledButton, StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "@store/index";
import { DominantColor } from "@components/Common";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/index";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import SaleModeVatRate from "./SaleModeVatRate";
import { VatRateContext } from "..";

type AdvancedVatRateModalPropsType = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AdvancedVatRateModal({
    isModalOpen,
    setIsModalOpen,
}: AdvancedVatRateModalPropsType) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const vatRateContext = React.useContext(VatRateContext);
    const [saleModeVatRates, setSalesModeVatRates] = React.useState<
        { saleModeUuid: string; value: number }[]
    >(vatRateContext.itemPrice.saleModeVatRates);

    const dispatch = useDispatch();

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );

    const handleVatRateChange = () => {
        const updatedAdvancedPrice = Object.entries(
            vatRateContext.itemPrice.advancedPrice
        ).reduce(
            (
                updatedAdvancedPrice: Record<string, Price>,
                [advancedPriceKey, advancedPrice]
            ) => {
                updatedAdvancedPrice[advancedPriceKey] = advancedPrice;
                if (advancedPrice.originalKeyElements !== undefined) {
                    saleModeVatRates.forEach((saleMode) => {
                        if (
                            advancedPrice.originalKeyElements.includes(
                                saleMode.saleModeUuid
                            )
                        ) {
                            updatedAdvancedPrice[advancedPriceKey] = {
                                ...advancedPrice,
                                tva: saleMode.value,
                                priceHT:
                                    Number(advancedPrice.pricettc ?? 0) /
                                    (1 + Number(saleMode.value) / 100),
                            };
                        }
                    });
                }
                return updatedAdvancedPrice;
            },
            {}
        );

        setIsModalOpen(!isModalOpen);

        vatRateContext.dispatchItem({
            type: ACTIONS.SALE_MODE_TVA,
            payload: saleModeVatRates,
        });

        isMultiModify &&
            dispatch(addListKeytoEdit(MULTI_EDIT_KEY.SALE_MODE_TVA));

        vatRateContext.dispatchItem({
            type: ACTIONS.ADVANCED_PRICE,
            payload: updatedAdvancedPrice,
        });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSalesModeVatRates(vatRateContext.itemPrice.saleModeVatRates);
    };
    return (
        <StyledModal
            centered
            isOpen={isModalOpen}
            size="lg"
            style={{
                overflowX: "hidden",
            }}
        >
            <ModalHeader toggle={closeModal}>
                {`TVA ${t("Advanced")}`}
            </ModalHeader>

            <ModalBody
                style={{
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                }}
            >
                <fieldset className="border rounded p-3 m-auto">
                    <legend className="w-auto px-2">
                        <StyledLabel htmlFor="text">
                            {t("Select the VAT rate produced by mode of sale")}
                        </StyledLabel>
                    </legend>

                    <div className="m-auto">
                        {vatRateContext.salesModes.map((saleMode) => (
                            <SaleModeVatRate
                                saleMode={saleMode}
                                saleModeVatRates={saleModeVatRates}
                                setSalesModeVatRates={setSalesModeVatRates}
                                key={saleMode.id}
                            />
                        ))}
                    </div>
                </fieldset>
            </ModalBody>
            <ModalFooter>
                <StyledButton
                    rounded
                    variant="light"
                    outline
                    onClick={closeModal}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleVatRateChange}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
