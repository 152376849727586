/* eslint-disable no-lone-blocks */
import React from "react";

import { useTranslation } from "react-i18next";
import { HeartIcon, StyledButton } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@store/.";
import { DominantColor, SuccessToast } from "@components/Common";
import { setSelectedCard } from "@store/features/CardSlice";

export function SelectedCard(e: any) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedCardIuud = useSelector(
        (state: RootState) => state.cardSlice.selectedCardIuud
    );

    return (
        <div>
            {e!.selectedRow.iuud === selectedCardIuud ? (
                <HeartIcon height={25} width={25} className="ml-5" />
            ) : (
                <div>
                    {e!.selectedRow.CardMaster || e!.selectedRow.Cardshop ? (
                        <StyledButton
                            outline
                            rounded
                            variant={dominantColor}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                                localStorage.setItem(
                                    "selectedCard",
                                    JSON.stringify({
                                        iuud: e.selectedRow.iuud,
                                        designation: e.selectedRow.Désignation,
                                        type: e.selectedRow.CardMaster
                                            ? "master"
                                            : "shop",
                                        statut: e.selectedRow.Statut,
                                    })
                                );

                                localStorage.setItem(
                                    "shopId",
                                    e.selectedRow.uidShop ?? "0"
                                );

                                dispatch(setSelectedCard(e.selectedRow.iuud));
                                SuccessToast(
                                    t("The card is chosen successfully")
                                );
                                setTimeout(() => {
                                    navigate("/home");
                                }, 500);
                            }}
                        >
                            {t("To access")}
                        </StyledButton>
                    ) : null}
                </div>
            )}
        </div>
    );
}
