import React from "react";

export default function ItemIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14.713}
            height={20.063}
            fill="#fff"
            viewBox="0 0 14.713 20.063"
            {...props}
        >
            <path
                id="Trac\xE9_9565"
                data-name="Trac\xE9 9565"
                d="M93.43,20.034l-.723-2.411V16.334A.334.334,0,0,0,92.372,16H82.341a.334.334,0,0,0-.334.334v1.288l-.723,2.411A30.4,30.4,0,0,0,80,28.775v5.616a.334.334,0,0,0,.229.317l4.013,1.338a.337.337,0,0,0,.106.017h0l.044,0h0l10.031-1.338a.334.334,0,0,0,.29-.331V28.775a30.405,30.405,0,0,0-1.284-8.744ZM82.269,33.214,83.528,35.1l-2.519-.84Zm.406-13.264.083.276a29.731,29.731,0,0,1,1.255,8.549v5.849l-1.338-2.006Zm1.881,6.062,9.259-.926a29.753,29.753,0,0,1,.229,3.689v2.648l-9.363,1.248v-3.9Q84.681,27.389,84.556,26.012Zm9.168-1.589-9.237.924A30.4,30.4,0,0,0,83.4,20.034l-.609-2.028h2.646l.932,3.106a.334.334,0,0,0,.354.237l3.344-.334a.334.334,0,0,0,.284-.438l-.857-2.569h2.631l.666,2.22a29.743,29.743,0,0,1,.935,4.2Zm-4.937-6.417.795,2.384-2.653.265-.795-2.649Zm-.093-.669H86.019v-.669h2.675Zm3.344,0a13.8,13.8,0,0,1-2.675,0v-.669h2.675S92.706,17.17,92.038,17.338Zm-9.363-.669H85.35v.669H82.675Zm-.752,3.558.083-.276V32.562l-1.338,1.115v-4.9a29.736,29.736,0,0,1,1.255-8.551Zm2.758,15.12v-2L94.044,32.1v2Z"
                transform="translate(-80 -16)"
                fill="#fff"
            />
        </svg>
    );
}
