import React from "react";

import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { RootState } from "@store/.";
import {
    setCurrentValue,
    setOldValue,
    setIsDirty,
    initEditObserver,
} from "@store/features/editObserverSlice";
import { setIsModificationConfirmed } from "@store/features/selectedCardListSlice";

export default function useUnsavedChangesWarning(formObject: any) {
    const dispatch = useDispatch();
    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const currentValue = useSelector(
        (state: RootState) => state.editObserverSlice.currentValue
    );
    const oldValue = useSelector(
        (state: RootState) => state.editObserverSlice.oldValue
    );

    if (isEditing) {
        dispatch(setCurrentValue(formObject));
        if (isEqual(currentValue, oldValue)) {
            dispatch(setIsDirty(false));
        } else {
            dispatch(setIsDirty(true));
        }
    }

    React.useEffect(() => {
        return () => {
            dispatch(initEditObserver());
            dispatch(setIsModificationConfirmed(false));
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (isEditing) {
            dispatch(setOldValue(formObject));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isEditing]);
}
