import React from "react";

import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import AdvancedVatRateModal from "./AdvancedVatRateModal";
import VatRateSelectInput from "./VatRateSelectInput";
import { VatRateContext } from "..";

type VatRatePropsType = {
    handleVatRateChange: Function;
};

export default function VatRate({ handleVatRateChange }: VatRatePropsType) {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const vatRateContext = React.useContext(VatRateContext);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
                <StyledLabel>{t("TVA")}</StyledLabel>
                <div
                    style={{
                        cursor: "pointer",
                        color: "blue",
                        border: "transparent",
                        backgroundColor: "transparent",
                        textAlign: "center",
                        textDecoration: "underline",
                        font: "normal normal 600 16px/21px Segoe UI",
                    }}
                    onClick={(): void => {
                        setIsModalOpen(true);
                    }}
                    role="button"
                    tabIndex={0}
                >
                    {t("Access")}
                </div>
            </div>

            <VatRateSelectInput
                handleVatRateChange={handleVatRateChange}
                selectedVatRate={{
                    label: `${vatRateContext.itemPrice.tva} %`,
                    value: String(vatRateContext.itemPrice.tva),
                }}
                isDisabled={vatRateContext.salesModes.length !== 1}
            />

            {isModalOpen && (
                <AdvancedVatRateModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </React.Fragment>
    );
}
