import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { CardFooter } from "reactstrap";
import { Card } from "react-bootstrap";
import axios from "axios";
import { StyledButton, StyledLabel } from "@aureskonnect/react-ui";

import {
    SuccessToast,
    DominantColor,
    DynamicTableWrapperContext,
    CustomCardBody,
    ErrorToast,
    RequirementToast,
} from "@components/Common/";
import { RootState } from "@store/.";
import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import { ACTIONS } from "@helpers/.";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import AllergenGroupSelectInput from "./AllergenGroupSelectInput";
import AddAllergenHeader from "./AddAllergenHeader";
import Image from "./Image";
import { allergenReducer, initialState } from "./reducer";

export default function AddAllergen() {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const dominantColor: string = DominantColor();

    const [allergenForm, dispatchAllergenForm] = React.useReducer(
        allergenReducer,
        initialState
    );
    const putAllergenes = async (data: FormData): Promise<void> => {
        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/allergens/${dynamicTableWrapperContext?.rowData?.iuud}`,
                data
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                SuccessToast(t("Allergen updated successfully"));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    };
    const addAllergenes = async (data: FormData): Promise<void> => {
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/allergen`,
                data
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                SuccessToast(t("Allergen added successfully"));
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    };
    const insertAllergen = () => {
        const data = new FormData();

        data.append(
            "allergen",
            JSON.stringify({
                title: allergenForm.title,
                icon: allergenForm.icon,
                archive: allergenForm.archive,
            })
        );
        data.append("group", JSON.stringify(allergenForm.group));
        data.append("IMAGE", allergenForm.icon);

        dynamicTableWrapperContext?.rowData?.iuud
            ? putAllergenes(data)
            : addAllergenes(data);
    };
    const handleTitle = (Title: string) =>
        dispatchAllergenForm({
            type: ACTIONS.TITLE,
            payload: Title,
        });
    const handleOnscroll = (): void => {
        if (allergenForm.title === "") {
            RequirementToast(t("The ''Designation'' field must be filled in"));
        }
    };
    const handleCancelButton = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };
    // eslint-disable-next-line prefer-regex-literals

    const isSubmitting = useSelector(
        (state: RootState): boolean => state.editObserverSlice.isSubmitting
    );
    const validateButtonRef = React.useRef<HTMLButtonElement>(null);
    if (isSubmitting === true) {
        validateButtonRef.current?.click();
        dispatch(initEditObserver());
    }

    React.useEffect(() => {
        if (dynamicTableWrapperContext?.rowData?.iuud) {
            const allergenData = {
                ...dynamicTableWrapperContext.rowData.data,
                group: {
                    label: dynamicTableWrapperContext.rowData.group,
                    value: dynamicTableWrapperContext.rowData.data.group,
                },
            };
            dispatchAllergenForm({
                type: ACTIONS.EDIT,
                payload: allergenData,
            });
            dispatch(setIsEditing(true));
        }
    }, [dynamicTableWrapperContext?.rowData, dispatch]);

    useUnsavedChangesWarning(allergenForm);
    return (
        <Card style={{ border: "0px " }}>
            <AddAllergenHeader dispatchAllergenForm={dispatchAllergenForm} />
            <AvForm
                onInvalidSubmit={handleOnscroll}
                onValidSubmit={insertAllergen}
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") e.preventDefault();
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <div className="px-3 py-2">
                        <div className="py-2">
                            <StyledLabel>{t("Designation *")}</StyledLabel>
                            <StyledInputWithTooltip
                                id="Désignation"
                                name="title"
                                type="text"
                                value={allergenForm?.title}
                                valueSetterFunction={handleTitle}
                                requiredValidateValue
                                autoFocus
                                onFocus={(event: any) => {
                                    event.target.select();
                                }}
                            />
                        </div>
                        <div className="py-2">
                            <StyledLabel>{t("Group")}</StyledLabel>
                            <AllergenGroupSelectInput
                                group={allergenForm.group}
                                dispatchAllergenForm={dispatchAllergenForm}
                            />
                        </div>

                        <div className="py-2" />
                        <StyledLabel>{t("Picture")}</StyledLabel>
                        <Image
                            allergenImageUrl={allergenForm.icon}
                            dispatch={dispatchAllergenForm}
                        />
                    </div>
                </CustomCardBody>
                <CardFooter className="Footercard p-0">
                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                        <div className="p-2 bd-highlight">
                            <StyledButton
                                rounded
                                variant={dominantColor}
                                ref={validateButtonRef}
                            >
                                {t("Validate")}
                            </StyledButton>
                        </div>
                        <div className="p-2 bd-highlight ">
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={handleCancelButton}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </AvForm>
        </Card>
    );
}
