import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    ErrorToast,
    SuccessToast,
    RequirementToast,
    DynamicTableWrapperContext,
} from "@components/Common/";
import { DominantColor } from "@components/Common/dominantColor";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DuplicateStepModal({
    isShow,
    setIsShow,
    setIsLoading,
}: {
    isShow: boolean;
    setIsShow: Function;
    setIsLoading: Function;
}): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const dominantColor: string = DominantColor();

    const { t } = useTranslation();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleOnDuplicateOption = (): void => {
        setIsLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/steps/${dynamicTableWrapperContext?.rowData?.iuud}/duplicate`,
                {}
            )
            .then((data: any): void => {
                setIsLoading(false);
                if (data.data.error === true) {
                    RequirementToast(
                        t("Designation already exists try another!")
                    );
                } else {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                    SuccessToast(t("The step duplicated successfully"));
                }
                setIsShow(!isShow);
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    };

    const toggleShow = (): boolean => setIsShow(!isShow);
    return (
        <Modal centered isOpen={isShow}>
            <ModalHeader toggle={toggleShow}>
                <StyledH2 className="text-uppercase">
                    <div className="d-flex">
                        {t("Duplicate step")} <span className="mx-1">:</span>
                        <LockIconWithToolTip
                            type="title"
                            lengthMax={20}
                            message={
                                dynamicTableWrapperContext?.rowData?.data?.title
                            }
                            id="archiver"
                        />
                        <div className="modal_clz" id="archiver">
                            {dynamicTableWrapperContext?.rowData?.data?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t("Do you want to duplicate step ?")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    outline
                    rounded
                    variant="light"
                    onClick={toggleShow}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleOnDuplicateOption}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
