import { Entite } from "@constants/Entite";
import React from "react";
import { setNewFranchise } from "@store/features/franchiseNameSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import Router from "./routes";

export default function App() {
    const dispatch = useDispatch();
    localStorage.setItem("ETKType", "3");
    if (process.env.REACT_APP_SELECT_ACCOUNT === "false") {
        localStorage.setItem(
            "Uid_Entitie_User",
            "8ecfe5ee-926b-423e-8050-c911dd81c147"
        );
    }

    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    const [selectedStore, setselectedStore] = React.useState<any>("");
    const selectedAccount: string =
        process.env.REACT_APP_SELECT_ACCOUNT ?? "false";
    const checkEntities = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/auth/user-entities/Activ%C3%A9/${uidUserEntity}`
            )
            .then(({ data }) => {
                if (data.data.length === 1) {
                    localStorage.setItem(
                        "schemaAdbFranchise",
                        data.data[0].SchemaFranchise
                    );
                    setselectedStore(data.data[0].SchemaFranchise);
                    dispatch(setNewFranchise(data.data[0].SchemaFranchise));
                }
                localStorage.setItem(
                    "selectedAccounts",
                    JSON.stringify(data.data.length)
                );
            })
            .catch((): void => {
                // ErrorToast(t("There is a mistake!"));
            });
    };

    React.useEffect(() => {
        if (selectedAccount === "false") {
            localStorage.setItem(
                "schemaAdbFranchise",
                Entite.database_info.schema_franchise
            );
            setselectedStore(Entite.database_info.schema_franchise);
            localStorage.setItem("selectedAccounts", "1");
        } else if (selectedStore === "") {
            checkEntities();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uidUserEntity, selectedStore]);

    return <Router />;
}
