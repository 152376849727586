import React from "react";
import { StepIcon } from "@components/Common/SvgIcons";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";
import uuidByString from "uuid-by-string";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { useTranslation } from "react-i18next";
import {
    checkAllItemsIsRedirect,
    checkModifierExist,
    ItemIsCrushed,
} from "@pages/WorkFlow/helpers";
import { v4 as uuidv4 } from "uuid";
import WarningToast from "@components/Common/WarningToast";

type AddMultipleStepInProductProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    isMultipleStep: boolean;
    globalIndex: number;
    uuid: string;
    setIsCrushed: React.Dispatch<React.SetStateAction<boolean>>;
    setAction: React.Dispatch<React.SetStateAction<string>>;
    setIsUnsavedDataMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    cardData: any;
};

export function AddMultipleStepInProduct({
    IsBasicCompositionStep,
    IsOptionStep,
    isMultipleStep,
    globalIndex,
    uuid,
    setIsCrushed,
    setAction,
    setIsUnsavedDataMultiple,
    cardData,
}: AddMultipleStepInProductProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const addMultipleStepInProduct = () => {
        if (multipleWorkflow.length > 0) {
            setIsUnsavedDataMultiple(true);

            return;
        }
        const CheckAllisRedirect = checkAllItemsIsRedirect(
            globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem,
            cardData
        );
        if (CheckAllisRedirect) {
            WarningToast(
                t(
                    "it is impossible to create a workflow for items and redirect buttons simultaneously"
                )
            );
            return;
        }
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";
        const isCrushed = ItemIsCrushed(
            globalWorkflow,
            globalIndex,
            modifierWorkflow,
            WORKFLOW_LIST.TYPESTEPS,
            cardData
        );
        if (isCrushed) {
            setAction(WORKFLOW_LIST.STEPS);
            setIsCrushed(true);
            return;
        }

        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        /* verifier si vente add existe */
        const modifierForm = checkModifierExist(
            firstUuidSelected,
            WORKFLOW_LIST.TYPEADD,
            globalIndex,
            modifierWorkflow,
            globalWorkflow
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidByString(
                  [
                      ...globalWorkflow[globalIndex!].previousUuids!,
                      firstUuidSelected,
                  ].join("")
              );

        let copyModifier = { ...modifierWorkflow };
        // eslint-disable-next-line no-prototype-builtins
        if (copyModifier.hasOwnProperty(iuudMD5)) {
            copyModifier = {
                [iuudMD5]: {
                    ...modifierWorkflow[iuudMD5],
                    typeSteps: true,
                },
            };
        } else {
            copyModifier = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: true,
                    typeAdd: false,
                    typeCategorie: false,
                },
            };
        }

        // add modifier item to workflow data

        if (!modifierForm.isModifier) {
            [
                ...globalWorkflow[globalIndex!].previousUuids!,
                firstUuidSelected,
            ]?.forEach((element: string) => {
                pathIuud += `["${element}"]?.content`;
            });
            // eslint-disable-next-line no-eval
            pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
            // eslint-disable-next-line no-eval
            const stringEvaluate = eval(pathIuud);
            stringEvaluate.content = {};
            stringEvaluate.content[iuudMD5] = {
                type: "modifier",
                override: {},
                content: {},
            };
            dispatch(setWorkflowData(copyGlobalWorkflowData));
        }

        // check step rank/////////
        let copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        /* Add previous state  */
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            previousState: {
                modifierSlice: JSON.parse(JSON.stringify(modifierWorkflow)),
                multipleWorkflow: [],
                globalWorkflow,
                modalWorkflow: [],
                globalWorkflowData: JSON.parse(
                    JSON.stringify(globalWorkflowData)
                ),
            },
        };
        let count = copyWorkflow.filter((element: any) => {
            if (element.title.includes("Etape")) {
                return true;
            }

            return false;
        }).length;
        if (count > 0 && copyWorkflow.length > globalIndex! + 1) {
            copyWorkflow = copyWorkflow.slice(0, globalIndex! + 1);
            count = 0;
        }

        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                // duplicate in global workflow
                const modifierForm = checkModifierExist(
                    elementUuid,
                    WORKFLOW_LIST.TYPEADD,
                    globalIndex,
                    modifierWorkflow,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                // selected item
                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    selected: uuid,
                };
            }
        );
        dispatch(
            setWorkflow([
                ...copyWorkflow,
                {
                    title: `Etape 1
                        `,
                    data: [],
                    uuid: firstUuidSelected,
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousModifier: iuudMD5,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!.slice(
                            0,
                            globalIndex!
                        ),
                        firstUuidSelected,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ])
        );
        dispatch(
            setMultipleWorkflow([
                {
                    popupIndex: -1,
                    StepIndex: globalIndex,
                    VirtualModifier: copyModifier,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
    };

    return (
        <div
            className="icon_step__CLZ text-center mr-2"
            style={{
                display:
                    IsBasicCompositionStep || IsOptionStep ? "none" : "grid",
            }}
        >
            <StepIcon
                role="button"
                width={30}
                height={30}
                stopColor="white"
                fill="black"
                style={{
                    borderRadius: "100%",
                    backgroundColor: "#transparent",
                    boxShadow: "0px 3px 6px #00000029",
                }}
                onClick={() => {
                    isMultipleStep && addMultipleStepInProduct();
                }}
            />
        </div>
    );
}
