import { ACTIONS } from "@helpers/actions";

type ColumnFileCategoryType = {
    index: string;
    name: string;
    value: string;
    selected: boolean;
};
export const ColumnFileCategory: ColumnFileCategoryType[] = [
    {
        index: "Designation",
        name: "Désignation",
        value: ACTIONS.TITLE,
        selected: false,
    },

    {
        index: "Display name",
        name: "Nom d'affichage",
        value: ACTIONS.DEFAULT_DISPLAY_NAME,
        selected: false,
    },
    {
        index: "Description",
        name: "Description",
        value: ACTIONS.DEFAULT_DISCRIPTION,
        selected: false,
    },
    {
        index: "Color",
        name: "Couleur",
        value: ACTIONS.COLOR,
        selected: false,
    },

    {
        index: "Reference",
        name: "Référence",
        value: ACTIONS.REFERENCE,
        selected: false,
    },
    {
        index: "video",
        name: "Bannière publicitaire",
        value: ACTIONS.VIDEO,
        selected: false,
    },
    {
        index: "Picture",
        name: "Image",
        value: ACTIONS.DEFAULT_IMAGE_URL,
        selected: false,
    },
];
