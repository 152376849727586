import React from "react";

import ImageUploader from "@components/Common_DB/ImageUploader";

type ImageProps = {
    imageUrl: string;
    setImage: Function;
};

export default function Image({ imageUrl, setImage }: ImageProps) {
    const [uploadedImage, setUploadedImage] = React.useState<string | Blob>(
        imageUrl
    );
    const [, setFileType] = React.useState<"image" | "video" | "">("image");

    React.useEffect(() => {
        setImage(uploadedImage);
    }, [uploadedImage, setImage]);

    React.useEffect(() => {
        setUploadedImage(imageUrl);
    }, [imageUrl]);

    return (
        <div
            className="d-flex justify-content-center align-items-center p-2"
            style={{
                border: "1px solid #C6C6C6",
                width: "100%",
                height: "240px",
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <ImageUploader
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                fileType="image"
                setFileType={setFileType}
            />
        </div>
    );
}
