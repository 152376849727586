import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DuplicateAllergenModal({
    isOpen,
    setIsOpen,
    alleregnToDuplicate,
    setIsLoading,
}: {
    isOpen: boolean;
    setIsOpen: Function;
    alleregnToDuplicate: { iuud: string; title: string };
    setIsLoading: Function;
}) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleOnDuplicate = async (): Promise<void> => {
        setIsLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/allergens/${alleregnToDuplicate.iuud}/duplicate`
            )
            .then(() => {
                setIsOpen(!isOpen);
                setIsLoading(false);
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
            })
            .then(() => {
                SuccessToast(t("Allergen duplicated successfully"));
            })
            .catch(() => {
                setIsLoading(false);
                ErrorToast(t("There is a mistake!"));
            });
    };
    const toggleOpen = (): boolean => setIsOpen(!isOpen);
    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex">
                        {t("Duplicate Allergen")}{" "}
                        <span className="mx-1">:</span>
                        {alleregnToDuplicate.title.length > 20 ? (
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={alleregnToDuplicate.title}
                                id="archiver"
                            />
                        ) : null}
                        <div id="archiver" className="modal_clz">
                            {alleregnToDuplicate.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to duplicate this allergen ?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleOnDuplicate}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
