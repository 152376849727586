import { ItemIcon } from "@aureskonnect/react-ui";

type CategoryPropsType = {
    item: ItemWithIuud;
    selectedItems: ItemWithIuud[];
    addSelectedItem: Function;
    deleteSelectedItem: Function;
};

export default function Item({
    item,
    selectedItems,
    addSelectedItem,
    deleteSelectedItem,
}: CategoryPropsType) {
    const isCurrentItemSelected = selectedItems.some(
        (selectedItem) => selectedItem.iuud === item.iuud
    );
    return (
        <div key={item.iuud} className="d-flex justify-content-between  my-1  ">
            <div>
                <ItemIcon
                    width={20}
                    height={20}
                    fill="#096595"
                    className="mr-2"
                    style={{
                        borderRadius: "4px",
                    }}
                />
                {item.title}
            </div>
            <div>
                <input
                    type="checkbox"
                    checked={isCurrentItemSelected}
                    className="ml-3"
                    id={item.title}
                    onChange={() => {
                        isCurrentItemSelected
                            ? deleteSelectedItem(item)
                            : addSelectedItem(item);
                    }}
                />
            </div>
        </div>
    );
}
