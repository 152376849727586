import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel } from "@aureskonnect/react-ui";

import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { RootState } from "@store/index";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";

type ReferencePropsType = {
    value: string;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
};

export default function Reference({
    value,
    dispatch,
    addToRefs,
}: ReferencePropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );

    const handleOnchangeReference = (reference: string): void => {
        dispatch?.({
            type: ACTIONS.REFERENCE,
            payload: reference,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.REFERENCE));
    };

    return (
        <div className="py-2" id="reference">
            <StyledLabel>{t("Reference")}</StyledLabel>

            <StyledInputWithTooltip
                id="reference"
                innerRef={addToRefs}
                name="reference"
                type="text"
                requiredValidateValue={false}
                // requiredValidateValue={!isMultiModify}
                value={value}
                disabled={
                    !!isMultiModify ||
                    (isEditing &&
                        cardType === "shop" &&
                        (cardStatus === "En attente" ||
                            cardStatus === "En production"))
                }
                valueSetterFunction={handleOnchangeReference}
            />
        </div>
    );
}
