import React from "react";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { priceInputOnKeyDown } from "@pages/Catalogue/Items/addItem/FormBodyComponents/helpers";

type UpdatePricePropsType = {
    selectedOption: "newPrice" | "increase" | "decrease";
    amount: number | "";
    setAmount: React.Dispatch<React.SetStateAction<number | "">>;
    setSelectedOption: React.Dispatch<
        React.SetStateAction<"newPrice" | "increase" | "decrease">
    >;
};

export default function UpdatePrice({
    selectedOption,
    amount,
    setAmount,
    setSelectedOption,
}: UpdatePricePropsType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Input
                type="radio"
                id="newPrice"
                checked={selectedOption === "newPrice"}
                onChange={() => {
                    setAmount("");
                    setSelectedOption("newPrice");
                }}
            />
            <StyledLabel htmlFor="newPrice">{t("New price")}</StyledLabel>
            <div className="py-4 mx-4">
                <StyledLabel htmlFor="priceHT" className="d-block">{`${t(
                    "Price"
                )} TTC`}</StyledLabel>

                <StyledTextInput
                    id="priceHT"
                    name="priceHT"
                    placeholder={t("Enter")}
                    disabled={selectedOption !== "newPrice"}
                    value={selectedOption === "newPrice" ? amount : ""}
                    type="number"
                    min="0"
                    className="w-25"
                    onKeyDown={priceInputOnKeyDown}
                    onChange={(e: any) => {
                        setAmount(e.target.value);
                    }}
                    onBlur={(e: any) => {
                        if (amount !== "") {
                            setAmount(Number(e.target.value));
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
}
