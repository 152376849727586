import React from "react";

import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import {
    DominantColor,
    LoadingDataAnimation,
    DynamicTableWrapperContext,
    CustomCRUDColumn,
} from "@components/Common";
import { RootState } from "@store/.";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import DeleteOptionModal from "./DeleteOptionModal";
import DuplicateOptionModal from "./DuplicateOptionModal";

export default function Optionlist() {
    const { t } = useTranslation();
    const isVisible = useSelector(
        (state: RootState) => state.headerCompoHeighSlice.isVisible
    );
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const dominantColor = DominantColor();
    const [optionIuud, setOptionIuud] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const dispatch = useDispatch();
    // const customJsxActions = (e: any) => (
    //     <div className="d-flex justify-content-around w-50">
    //         <div id={`Duplication${e?.selectedRow?.iuud}`}>
    //             <LockIconWithToolTip
    //                 type="icon"
    //                 lengthMax={0}
    //                 message={t(`Duplicate`)}
    //                 id={`Duplication${e?.selectedRow?.iuud}`}
    //             />
    //             <DuplicateIcon
    //                 style={{ cursor: "pointer" }}
    //                 height={25}
    //                 width={25}
    //                 onClick={() => {
    //                     handleOnEditoption(e.selectedRow);
    //                     setIsShow(!isShow);
    //                     dynamicTableWrapperContext?.setLocalFilterActive(false);
    //                     dynamicTableWrapperContext?.setActionColumnDisplay(
    //                         false
    //                     );
    //                 }}
    //             />
    //         </div>
    //         <div id={`Modifier${e?.selectedRow?.iuud}`}>
    //             <LockIconWithToolTip
    //                 type="icon"
    //                 lengthMax={0}
    //                 message={t(`Edit`)}
    //                 id={`Modifier${e?.selectedRow?.iuud}`}
    //             />
    //             <PencilIcon
    //                 style={{ cursor: "pointer" }}
    //                 height={25}
    //                 width={25}
    //                 onClick={() => {
    //                     handleOnEditoption(e.selectedRow);
    //                     dynamicTableWrapperContext?.setActionColumnDisplay(
    //                         true
    //                     );
    //                     dynamicTableWrapperContext?.setLocalFilterActive(false);
    //                 }}
    //             />
    //         </div>
    //         <div id={`Archiver${e?.selectedRow?.iuud}`}>
    //             <LockIconWithToolTip
    //                 type="icon"
    //                 lengthMax={0}
    //                 message={t(`Archive`)}
    //                 id={`Archiver${e?.selectedRow?.iuud}`}
    //             />
    //             <TrashIcon
    //                 style={{ cursor: "pointer" }}
    //                 height={25}
    //                 width={25}
    //                 fill="red"
    //                 onClick={() => {
    //                     dynamicTableWrapperContext?.setRowData({
    //                         ...e.selectedRow.Option,
    //                         iuud: e.selectedRow.iuud,
    //                         isConsumed: e.selectedRow.isConsumed,
    //                     });

    //                     setIsOpen(!isOpen);
    //                     setOptionIuud(e.selectedRow.iuud);
    //                     dynamicTableWrapperContext?.setActionColumnDisplay(
    //                         false
    //                     );
    //                     dynamicTableWrapperContext?.setLocalFilterActive(false);
    //                 }}
    //             />
    //         </div>
    //     </div>
    // );

    const handleDuplicate = (e: any) => {
        handleOnEditoption(e.selectedRow);
        setIsShow(!isShow);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };
    const handleEdit = (e: any) => {
        handleOnEditoption(e.selectedRow);
        dynamicTableWrapperContext?.setActionColumnDisplay(true);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };
    const handleDelete = (e: any) => {
        dynamicTableWrapperContext?.setRowData({
            ...e.selectedRow.Option,
            iuud: e.selectedRow.iuud,
            isConsumed: e.selectedRow.isConsumed,
        });

        setIsOpen(!isOpen);
        setOptionIuud(e.selectedRow.iuud);
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };
    const customJsxAction = (e: any): JSX.Element => (
        <CustomCRUDColumn
            e={e}
            handleDuplicate={handleDuplicate}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
        />
    );

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 3,
            columnName: "Actions",
            customJsx: customJsxAction,
        },
    ];

    function handleOnEditoption(selectedOption: any) {
        dynamicTableWrapperContext?.setRowData({
            ...selectedOption.Option,
            iuud: selectedOption.iuud,
        });
    }

    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData({
            iuud: "",
            title: "",
            values: {},
            defaultValue: "",
        });
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    return !isLoading ? (
        <div className="DynamicTable_clz">
            <DynamicTable
                url={`${process.env.REACT_APP_ADB_API_URL}/options/${schemaAdbFranchise}/${cardIuud}`}
                canSort
                name="Option_table"
                customJsxSideFilterButton={
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            onSubmit={(): void => {
                                if (isDirty) {
                                    setUnsavedChangesWarningModalAction(
                                        () => handleSubmit
                                    );
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dispatch(setIsEditing(false));
                                    handleSubmit();
                                }
                            }}
                        >
                            {!dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant={dominantColor}
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    {t("Add option")}
                                </StyledButtonWithIcon>
                            )}
                            {dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledIconButton
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                    }}
                                    icon="PlusIcon"
                                    className="bg-dark"
                                    rounded
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    <PlusIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            )}
                        </AvForm>
                    </div>
                }
                canResize
                showGlobalFilter
                showFilter
                filterActive={dynamicTableWrapperContext?.filterActive}
                setLocalFilterActive={
                    dynamicTableWrapperContext?.setLocalFilterActive
                }
                arrayOfCustomColumns={
                    cardType === "shop" && cardStatus === "En production"
                        ? []
                        : arrayOfCustomColumns
                }
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                onClick={(row: any) => {
                    if (isDirty) {
                        setIsUnsavedChangesWarningModalOpen(true);
                        setUnsavedChangesWarningModalAction(() => () => {
                            handleOnEditoption(row.original);
                        });
                    } else {
                        dispatch(setIsEditing(false));
                        handleOnEditoption(row.original);
                    }
                }}
            />
            {isOpen && (
                <DeleteOptionModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    optionIuud={optionIuud}
                    setIsLoading={setIsLoading}
                />
            )}
            {isShow && (
                <DuplicateOptionModal
                    isShow={isShow}
                    setIsShow={setIsShow}
                    setIsLoading={setIsLoading}
                />
            )}
            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    ) : (
        <LoadingDataAnimation />
    );
}
