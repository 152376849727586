import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { sellingSupport } from "./visibilitySlice";

type SettingsStateType = {
    paymentMethods: any[];
    saleModes: SaleModeType[];
    saleSupports: string[];
    languages: any[];
    vatRates: any[];
    printers: PrinterType[];
};

const initialState: SettingsStateType = {
    paymentMethods: [],
    saleSupports: [],
    saleModes: [],
    languages: [],
    vatRates: [],
    printers: [],
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload;
        },
        setSaleSupports: (state, action) => {
            state.saleSupports = action.payload;
        },
        setSaleModes: (state, action) => {
            state.saleModes = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setVatRates: (state, action) => {
            state.vatRates = action.payload;
        },
        setPrinters: (state, action) => {
            state.printers = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            state.paymentMethods = action.payload.paymentMethods;
            state.saleSupports = action.payload.saleSupports;
            state.saleModes = action.payload.saleModes;
            state.vatRates = action.payload.vatRates;
            state.printers = action.payload.printers;
            state.languages = action.payload.languages;
        });
    },
});

export const fetchSettings = createAsyncThunk(
    "settings/fetchGeneralSettings",
    async () => {
        try {
            const franchiseId = localStorage.getItem("franchiseId") || "";
            const shopId = localStorage.getItem("shopId") || "";
            const settings = await fetch(
                `${process.env.REACT_APP_SETTINGS_API_URL}settings/general?franchiseId=${franchiseId}&shopId=${shopId}`
            ).then((response) => response.json());

            const saleModes: SaleModeType[] = [];
            const defaultVisibility: any = {};
            settings.data.saleMode.forEach((saleMode: any) => {
                saleModes.push({
                    designation: saleMode.saleMode,
                    id: saleMode.Id,
                    saleSupports: saleMode.support_vente,
                });
                defaultVisibility[saleMode.saleMode] = {
                    id: saleMode.Id,
                    visibility: true,
                    sellingSupport: sellingSupport(saleMode.support_vente),
                };
            });
            const saleSupports = [
                ...new Set(
                    ...saleModes.map((saleMode) => saleMode.saleSupports)
                ),
            ];

            const printers: PrinterType[] = settings.data.printer.map(
                (printer: any): PrinterType => ({
                    id: printer.Id,
                    brand: printer.mark,
                    name: printer.designation,
                    ip: printer.ip,
                    port: printer.port,
                    remark: printer.note,
                    shopName: printer.shopName,
                })
            );

            return {
                saleSupports,
                defaultVisibility,
                saleModes,
                paymentMethods: settings.data.paymentMethod,
                languages: settings.data.language,
                vatRates: settings.data.vatRate,
                printers,
            };
        } catch (error) {
            throw new Error(error as string);
        }
    }
);

export const {
    setPaymentMethods,
    setSaleModes,
    setLanguages,
    setVatRates,
    setSaleSupports,
    setPrinters,
} = settingsSlice.actions;

export default settingsSlice.reducer;
