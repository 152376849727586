export const Entite: any = {
    uid: "8ecfe5ee-926b-423e-8050-c911dd81c147",
    etat: {
        etat: "Archivex",
        note: "notetestx",
    },
    name: "amal",
    type: "franchise",
    catalog: {
        iuudCardReference: {
            b1: ["carte1 Prod", "cc7c52fd-264b-4239-9fc0-eba116e70076 Project"],
            btq2: [
                "cc7c52fd-264b-4239-9fc0-eba116e70075 Prod",
                "cc7c52fd-264b-4239-9fc0-eba116e70076 Project",
            ],
        },
        cartmaster2uid: {
            b1: ["cc7c52fd-264b-4239-9fc0-eba116e70075 Project"],
            btq2: ["cc7c52fd-264b-4239-9fc0-eba116e70075 Project"],
        },
    },
    erpos_id: "",
    licenses: {
        "773c5b96-cef3-420a-b1ef-1c3f401d5afb": {
            motif: "bloq a cause de non solde",
            quantity: "3",
            reserved: "",
            activated: "",
            productName: "kiosk",
        },
        "ec15c91d-233d-4f46-87b5-0730d101455e": {
            motif: "",
            quantity: "5",
            reserved: "",
            activated: "",
            productName: "liv",
        },
    },
    database_info: {
        id_store: [2, 3, 4],
        franchise: "",
        uid_store: ["017448", "017715", "002207"],
        uid_franchise: "",
        schema_franchise: "franchise_test_sofiene",
    },
    actual_ref_catalog: "Cd0012",
    childs_profilelink: ["cc7c52fd-264b-4239-9fc0-eba116e70076"],
    parent_profilelink: "8ecfe5ee-926b-423e-8050-c911dd81c147",
    general_information: {
        vat: "TWRvcEJvTFI4WFgzNENIRnJSNTRpZz09",
        name: "aUIzZ0FnQjJiWGIwQzhLdG9nT1djdz09",
        email: "djd1NktXYkt5b0pNMStpdExaV1lxaXNkZUJXMGlwdzJzWE5TU2p2NERIVT0=",
        siret: "TWRvcEJvTFI4WFgzNENIRnJSNTRpZz09",
        address:
            "TkhpZTRNdUhNaXQ1L0p1VnlCMkFEdz09|TkhpZTRNdUhNaXQ1L0p1VnlCMkFEdz09|TkhpZTRNdUhNaXQ1L0p1VnlCMkFEdz09",
        codeape: "TWRvcEJvTFI4WFgzNENIRnJSNTRpZz09",
        country: "UHB6Z3kyUXhYUjdUUXNRdU9uNWE0dz09",
        firstname: "ZHN3MUFSVnlNOGYwdzZlKy9kYTlaZz09",
        phonenumber: "S290d05McHA3MnBJaVpCZVlmWHBFZz09",
        Socialreason: "cE4wWEprSm91MUFKVkRoTWQ1SUZ5Zz09",
        commercialname: "cE4wWEprSm91MUFKVkRoTWQ1SUZ5Zz09",
    },
    parent_profilelink_allowed_access: [],
    Uid_Entitie_User: "8ecfe5ee-926b-423e-8050-c911dd81c147",
};
