import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";

import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { legalExpression } from "@constants/index";
import { RootState } from "@store/index";

type LoyaltyPointsPropsType = {
    isRedirect: boolean;
    value: any;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
};

export default function LoyaltyPoints({
    isRedirect,
    value,
    dispatch,
}: LoyaltyPointsPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isInputNumber = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };
    const handleFidelity = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({
            type: ACTIONS.FIDELITY,
            payload: e.target.value,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.FIDELITY));
    };
    return (
        <div className="py-2" id="fidelity">
            <StyledLabel>{t("Loyalty points")}</StyledLabel>
            <StyledTextInput
                onPaste={(e: any) => {
                    e.preventDefault();
                    const pastedData = Math.abs(
                        Number(e.clipboardData.getData("text"))
                    );

                    const split = pastedData.toString().split(".")[0];

                    dispatch({
                        type: ACTIONS.FIDELITY,
                        payload: split,
                    });
                }}
                id="fidelity"
                name="fidelity"
                placeholder={t("Enter")}
                type="number"
                min={0}
                onKeyPress={isInputNumber}
                value={value}
                validate={{
                    pattern: {
                        value: legalExpression,
                        errorMessage: t("Please enter valid loyalty points"),
                    },
                }}
                onChange={handleFidelity}
                disabled={
                    isRedirect ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                }
            />
        </div>
    );
}
