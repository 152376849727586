import React from "react";
import { AvForm } from "availity-reactstrap-validation";

import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";

import swapElement from "@helpers/swapElement";
import errorToast from "@components/Common/ErrorToast";
import {
    StyledLabel,
    StyledTextInput,
    StyledIconButton,
    PlusIcon,
} from "@aureskonnect/react-ui";

import { useTranslation } from "react-i18next";
import { ListValuesOption } from "./ListValuesOption";

type editOption = {
    name: string;
    isFavorite: boolean;
    index: number;
};

type valueOptionPropsType = {
    listOptionValues: any;
    editOption: any;
    setEditOption: React.Dispatch<React.SetStateAction<editOption>>;
    setListOptionValues: Function;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsNotBeDeleted: React.Dispatch<React.SetStateAction<boolean>>;
    // eslint-disable-next-line no-unused-vars
    Defaultvalue: (index: number) => void;
};

function ValueOption({
    listOptionValues,
    editOption,
    setEditOption,
    setListOptionValues,
    setIsOpen,
    setIsNotBeDeleted,
    Defaultvalue,
}: valueOptionPropsType) {
    const { t } = useTranslation();

    const handleEditOption = (selectedOption: any, index: number) => {
        setEditOption({ ...selectedOption, index });
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (destination === null) return;
        const Newlist = swapElement(
            [...listOptionValues],
            source.index,
            destination!.index
        );
        setListOptionValues(Newlist);
    };

    const Deletelist = (index: number): void => {
        if (listOptionValues[index].items.length > 0) {
            setIsOpen(true);
            setIsNotBeDeleted(true);
            return;
        }
        setListOptionValues((prevList: any[]) => {
            return prevList.filter((val) => val.name !== prevList[index].name);
        });
    };

    const editSelectedValue = (optionToEdit: any): void => {
        setListOptionValues((prevList: any) => {
            const newState = [...prevList];
            newState[optionToEdit.index] = optionToEdit;
            return newState;
        });
        setEditOption({ name: "", isFavorite: false, index: -1 });
    };

    const Addlist = (e: any): void => {
        e.preventDefault();

        if (editOption.name) {
            if (editOption.index !== -1) {
                editSelectedValue(editOption);
                return;
            }

            if (
                listOptionValues.filter(
                    (elm: any) => elm.name === editOption.name
                ).length > 0 &&
                editOption.index === -1
            ) {
                errorToast(
                    t("The entered value already exists. Please check.")
                );
            } else {
                setListOptionValues([
                    ...listOptionValues,
                    {
                        name: editOption.name,
                        isFavorite: false,
                        iuud: "",
                        items: [],
                    },
                ]);

                setEditOption({ name: "", isFavorite: false, index: -1 });
            }
        }
    };

    return (
        <div className="py-2">
            <StyledLabel htmlFor="example-input">{t("Values")}</StyledLabel>
            <div className="  d-flex bd-highlight d-flex align-items-center">
                <div className="w-100 bd-highlight styled-input-wrapper__clz">
                    <StyledTextInput
                        id="example-input"
                        name="example-input"
                        type="text"
                        autoFocus={editOption.name !== ""}
                        placeholder={t("Value 1, Value 2, Value 3")}
                        value={editOption.name}
                        onChange={(e: any) => {
                            setEditOption((prev) => ({
                                ...prev,
                                name: e.target.value.trim(),
                            }));
                        }}
                        onKeyDown={(event: any) => {
                            if (event.keyCode === 13) {
                                Addlist(event);
                            }
                        }}
                    />
                </div>
                <div className=" mx-2 flex-shrink-1 bd-highlight ">
                    <AvForm onSubmit={Addlist}>
                        <StyledIconButton
                            type="div"
                            style={{
                                background: "#ffffff",

                                borderRadius: " 6px",
                                border: `solid 1px  ${
                                    editOption.name !== ""
                                        ? "#80bdff"
                                        : "#caccd4"
                                } `,
                                outline: 0,

                                boxShadow: `${
                                    editOption.name !== ""
                                        ? "0 0 0 .2rem rgba(0,123,255,.25)"
                                        : "none"
                                }`,
                            }}
                            icon="PlusIcon"
                            onClick={Addlist}
                        >
                            <PlusIcon height={10} width={10} />
                        </StyledIconButton>
                    </AvForm>
                </div>
            </div>
            <div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="vertical">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <div
                                    style={{
                                        //  display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {listOptionValues.map(
                                        (optionValue: any, index: number) => {
                                            listOptionValues[index].rank =
                                                index + 1;
                                            return (
                                                <Draggable
                                                    key={optionValue.name}
                                                    draggableId={
                                                        optionValue.name
                                                    }
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ListValuesOption
                                                                handleEditOption={
                                                                    handleEditOption
                                                                }
                                                                element={
                                                                    optionValue
                                                                }
                                                                index={index}
                                                                Defaultvalue={
                                                                    Defaultvalue
                                                                }
                                                                Deletelist={
                                                                    Deletelist
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    )}
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
}

export default ValueOption;
