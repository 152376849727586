/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";

import { useTranslation } from "react-i18next";

type SelectStepItemPropsType = {
    setStepIuud: React.Dispatch<React.SetStateAction<string>>;
    stepDesignation: string;
    stepList: any;
};
export const initialStep = {
    label: "",
    value: "",
};
export default function FilterStepItem({
    setStepIuud,
    stepDesignation,
    stepList,
}: SelectStepItemPropsType) {
    const { t } = useTranslation();

    const [defaultStep, setDefaultStep] = React.useState<any>("");

    const stepsOptions = stepList?.map((step: any) => {
        return { label: step.label, value: step.iuud };
    });

    const handleOnchangeItemStep = (selectedValue: any): void => {
        setDefaultStep(selectedValue);

        setStepIuud(selectedValue ? selectedValue.value : "");
    };

    return stepsOptions !== undefined ? (
        <>
            <StyledLabel>{t("Step")}</StyledLabel>
            <StyledSelectInput
                id="Step"
                name="Step"
                placeholder={t("Select")}
                noOptionsMessage={() => "Pas d'option"}
                options={stepsOptions?.filter(
                    (step: any) => step.label !== stepDesignation
                )}
                value={defaultStep}
                onChange={handleOnchangeItemStep}
                isClearable
            />
        </>
    ) : (
        <></>
    );
}
