import React from "react";

import { isMobile } from "react-device-detect";
import { Nav } from "reactstrap";
import { useTranslation } from "react-i18next";

type RapportProps = {
    file: any;
    selectednumber: number;
    Updatenumber: number;
    creatednumber: number;
};
function Index({
    file,
    selectednumber,
    Updatenumber,
    creatednumber,
}: RapportProps) {
    const { t } = useTranslation();
    let message1 = "";
    let message2 = "";
    let message3 = "";
    let message4 = "";
    const pathname = window.location.pathname
        .toString()
        .split("/")[2]
        .toUpperCase();

    switch (pathname) {
        case "ARTICLES":
            message1 = t("Total number of items");
            message2 = t("Number of selected items");
            message3 = t("Number of items to create");
            message4 = t("Number of items to update");
            break;
        case "STEPS":
            message1 = t("Total number of steps");
            message2 = t("Number of selected steps");
            message3 = t("Number of steps to create");
            message4 = t("Number of steps to update");
            break;
        default:
            message1 = t("Total number of categories");
            message2 = t("Number of selected categories");
            message3 = t("Number of categories to create");
            message4 = t("Number of categories to update");
            break;
    }
    let ImportedElement: number = 0;
    file.rows.forEach((cell: any) => {
        if (cell[0] !== true) {
            ImportedElement += 1;
        }
    });

    return (
        <Nav>
            <div
                className={`${
                    isMobile ? " p-2 col-12" : " d-flex bd-highlight  col-12"
                }`}
            >
                <div className="p-2 w-100 bd-highlight">
                    {t("Data analysis report")} :
                </div>
            </div>
            <div className="d-flex flex-wrap  col-12 d-flex ">
                <div style={{ width: "400px" }}>
                    <div className="d-flex bd-highlight mb-2">
                        <div
                            style={{ width: "70%" }}
                            className="p-2 flex-fill bd-highlight"
                        >
                            {message1}
                        </div>
                        <div
                            className="p-2 flex-fill bd-highlight d-flex justify-content-center "
                            style={{
                                background: "#F3F3F9",
                                color: "black",
                                borderRadius: "10px",
                                width: "60px",
                                height: "40px",
                            }}
                        >
                            {ImportedElement}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div
                            style={{ width: "70%" }}
                            className="p-2 flex-fill bd-highlight"
                        >
                            {message2}
                        </div>
                        <div
                            className="p-2 flex-fill bd-highlight d-flex justify-content-center "
                            style={{
                                background: "#68C584",
                                color: "black",
                                borderRadius: "10px",
                                width: "60px",
                                height: "40px",
                            }}
                        >
                            {selectednumber}
                        </div>
                    </div>
                </div>
                <div style={{ width: "400px" }}>
                    <div className="d-flex bd-highlight mb-2">
                        <div
                            style={{ width: "70%" }}
                            className="p-2 flex-fill bd-highlight"
                        >
                            {message3}
                        </div>
                        <div
                            className="p-2 flex-fill bd-highlight d-flex justify-content-center "
                            style={{
                                background: "#1D4AFE",
                                color: "#FFFFFF",
                                borderRadius: "10px",
                                width: "60px",
                                height: "40px",
                            }}
                        >
                            {creatednumber}
                        </div>
                    </div>
                    <div className="d-flex bd-highlight mb-2">
                        <div
                            style={{ width: "70%" }}
                            className="p-2 flex-fill bd-highlight"
                        >
                            {message4}
                        </div>
                        <div
                            className="p-2 flex-fill bd-highlight d-flex justify-content-center "
                            style={{
                                background: "#FF8000",
                                color: "black",
                                borderRadius: "10px",
                                width: "60px",
                                height: "40px",
                            }}
                        >
                            {Updatenumber}
                        </div>
                    </div>
                </div>
                <div style={{ width: "400px" }}>
                    <div className="d-flex bd-highlight">
                        <div
                            style={{ width: "70%" }}
                            className="p-2 flex-fill bd-highlight"
                        >
                            {t("Number of detected errors")} :
                        </div>
                        <div
                            className="p-2 flex-fill bd-highlight d-flex justify-content-center "
                            style={{
                                background: "#FF4141",
                                color: "black",
                                borderRadius: "10px",
                                width: "60px",
                                height: "40px",
                            }}
                        >
                            00
                        </div>
                    </div>
                </div>
            </div>
        </Nav>
    );
}

export default Index;
