import React from "react";

import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { CardHeader } from "reactstrap";
import {
    RefreshIcon,
    CrossIcon,
    StyledIconButton,
    SettingsIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { resetUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";
import { IconElement } from "@pages/WorkFlow/IconElement";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import { RootState } from "@store/index";
import { DynamicTableWrapperContext } from "../DynamicTableWrapper";
import UnsavedChangesWarningModal from "../DynamicTableWrapper/UnsavedChangesWarningModal";
import LockIconWithToolTip from "../LockIconWithToolTip/LockIconWithToolTip";

export type PropsType = {
    title: string;
    refrechIconFunction: Function;
    Icon?: JSX.Element;
};

export default function StyledCardHeader({
    title,
    refrechIconFunction,
    Icon = <IconElement iconType="item" />,
}: PropsType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");

    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);

    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const pathname = window.location.pathname.toString().split("/")[1];
    let type: any = "";

    pathname === "categories"
        ? (type = "Categories")
        : pathname === "items" && (type = "Articles");

    const to = `/Configuration/${type}`;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleCrossIconClick = () => {
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        dispatch(initEditObserver());
    };
    return (
        <CardHeader
            className="Headercard  d-flex
            justify-content-between"
        >
            <div className=" d-flex align-items-center justify-content-center  ">
                {Icon}

                <h4
                    className="m-0 pl-2"
                    style={{ font: "normal normal 600 20px/27px Segoe UI" }}
                >
                    {t(title)}
                </h4>
            </div>

            <div className="d-flex align-items-center ">
                <div
                    className=" bd-highlight align-items-center"
                    style={
                        isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production"
                            ? { pointerEvents: "none" }
                            : { opacity: "1" }
                    }
                >
                    <AvForm
                        onSubmit={() => {
                            if (isDirty) {
                                setUnsavedChangesWarningModalAction(
                                    () => refrechIconFunction
                                );
                                setIsUnsavedChangesWarningModalOpen(true);
                            } else {
                                refrechIconFunction();
                            }
                        }}
                    >
                        <div id="refreche">
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t("Reset the fields")}
                                id="refreche"
                            />
                            <StyledIconButton icon="RefreshIcon">
                                <RefreshIcon
                                    height={20}
                                    width={20}
                                    cursor="pointer"
                                />
                            </StyledIconButton>
                        </div>
                    </AvForm>
                </div>
                <div
                    className=" bd-highlight align-items-center"
                    style={{ zIndex: "10", position: "relative" }}
                >
                    <StyledIconButton
                        style={
                            title === "Item" || title === "Category"
                                ? null
                                : { display: "none" }
                        }
                        icon="VerticalDotsIcon"
                    >
                        <VerticalDotsIcon
                            key="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            cursor="pointer"
                            height={20}
                            width={20}
                        />
                        {pathname === "categories" || pathname === "items" ? (
                            <div
                                className="dropdown-menu "
                                aria-labelledby="dropdownMenuButton"
                            >
                                <Link
                                    to={to}
                                    key="Article"
                                    state={{ from: "items" }}
                                >
                                    <div className="dropdown-item ">
                                        <SettingsIcon
                                            className="mr-2"
                                            height={20}
                                            width={20}
                                        />
                                        Configuration
                                    </div>
                                </Link>
                            </div>
                        ) : null}
                    </StyledIconButton>
                </div>

                <div className=" bd-highlight align-items-center">
                    <AvForm
                        onSubmit={() => {
                            /* check surcharge workflow and Etk light */
                            if (
                                updateItemWorkflow.pathUpdateItem.length > 0 &&
                                ETKType === "3"
                            ) {
                                dispatch(resetUpdateItemWorkflow());
                                dispatch(setIsEditing(false));
                                handleCrossIconClick();
                                return;
                            }
                            if (isDirty) {
                                setUnsavedChangesWarningModalAction(
                                    () => handleCrossIconClick
                                );
                                setIsUnsavedChangesWarningModalOpen(true);
                            } else {
                                dispatch(setIsEditing(false));
                                handleCrossIconClick();
                            }
                        }}
                    >
                        <div id="Flèche3">
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t("Close")}
                                id="Flèche3"
                            />
                            <StyledIconButton icon="CrossIcon">
                                <CrossIcon
                                    cursor="pointer"
                                    height={20}
                                    width={20}
                                />
                            </StyledIconButton>
                        </div>
                    </AvForm>
                </div>
            </div>
            <UnsavedChangesWarningModal
                action={() => {
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </CardHeader>
    );
}
