/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-await */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import axios from "axios";
import {
    DominantColor,
    DynamicTableWrapperContext,
    ErrorToast,
    SuccessToast,
} from "@components/Common";

type ListshopProps = {
    isOpen: boolean;
    setIsOpen: Function;
    rowData: any;
    pendingcardmaster: boolean;
    prodCardName: any;
    Open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Listshop({
    isOpen,
    setIsOpen,
    rowData,
    pendingcardmaster,
    prodCardName,
    setOpen,
    Open,
}: ListshopProps) {
    const dominantColor = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [filterActive, setFilterActive] = React.useState(false);
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const toggleOpen2 = (): void => {
        setOpen(!Open);
    };

    const PublishCard = () => {
        const cardData: any = [];
        selectedRows.forEach((element) => {
            const data = { shopid: element.shopid };
            cardData.push(data);
        });

        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}publiccards/${schemaAdbFranchise}/${rowData.selectedRow.original.iuud}`,
                cardData
            )
            .then((): void => {
                SuccessToast(t("Card is successfully published"));
                dynamicTableWrapperContext?.setDataUpdated(true) &&
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                toggleOpen();
            })
            .catch((): void => {
                ErrorToast(t("There is a mistake!"));
            });
    };

    const PublishCardMasterEnAttente = async () => {
        let cardData: any = [];

        cardData = rowData.selectedRow.original.subRows.map((el: any) => {
            return { shopid: el.shopid };
        });
        await axios
            .delete(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/deletecardsProdOfCardMasterProd/${prodCardName.iuud}`
            )
            .then(
                async () =>
                    await axios
                        .put(
                            `${process.env.REACT_APP_ADB_API_URL}publiccards/${schemaAdbFranchise}/${rowData.selectedRow.original.iuud}`,
                            cardData
                        )
                        .then((): void => {
                            SuccessToast(t("Card is successfully updated"));
                            dynamicTableWrapperContext?.setDataUpdated(true) &&
                                dynamicTableWrapperContext?.setActionColumnDisplay(
                                    false
                                );
                            toggleOpen();
                        })
                        .catch((): void => {
                            ErrorToast(t("There is a mistake!"));
                        })
            );
    };
    useEffect(() => {
        pendingcardmaster && setOpen(false);
        !pendingcardmaster && setOpen(false);
    }, []);
    return (
        <div>
            {/* publier carte master en attente si il y a une autre carte master publiée */}
            {pendingcardmaster && Open && (
                <Modal centered isOpen={isOpen}>
                    <ModalHeader toggle={toggleOpen}>
                        <StyledH2 className="text-uppercase">
                            PUBLICATION
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        "La publication des cartes des boutique de la carte{" "}
                        {rowData.selectedRow.original?.Désignation} engendre la
                        mise en archive des cartes en production de la carte{" "}
                        {prodCardName.designation}. Voulez-vous Publier ces
                        cartes ?"
                    </ModalBody>
                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpen}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            onClick={PublishCardMasterEnAttente}
                            rounded
                            variant={dominantColor}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            )}
            {/* publier carte master  si il n y a pas  une autre carte master publiée */}

            {!pendingcardmaster && !Open && (
                <Modal centered isOpen={isOpen} className="modal-dialogshop ">
                    <ModalHeader toggle={toggleOpen}>
                        <StyledH2 className="text-uppercase">
                            {t("SHOP LIST")}
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        <DynamicTable
                            url={`${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cardsOfFranchise/${rowData?.selectedRow?.original.iuud}`}
                            canSort
                            name="Card_table"
                            customSelect
                            canSelect
                            canExpand
                            setSelectedRows={setSelectedRows}
                            canResize
                            showGlobalFilter
                            showFilter
                            filterActive={filterActive}
                            setLocalFilterActive={setFilterActive}
                        />
                    </ModalBody>

                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpen}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            rounded
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={toggleOpen2}
                            variant={dominantColor}
                        >
                            Publier
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            )}

            {!pendingcardmaster && (
                <Modal centered isOpen={Open}>
                    <ModalHeader toggle={toggleOpen2}>
                        <StyledH2 className="text-uppercase">
                            PUBLICATION
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        {rowData.selectedRow.original.CardProd === false ? (
                            <div>
                                {t(
                                    "Do you want to confirm the publication of the maps of the selected shops?"
                                )}
                            </div>
                        ) : (
                            <div>
                                {t(
                                    "The publication of these maps results in the archiving of the maps currently in production."
                                )}
                                <br />
                                {t("Do you want to continue ?")}
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpen2}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            onClick={PublishCard}
                            rounded
                            variant={dominantColor}
                        >
                            Valider
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
}

export default Listshop;
