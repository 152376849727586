/* eslint-disable max-len */
import React from "react";

export default function AllergenIconMinimal(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props}>
            <g data-name="Groupe 27746">
                <g data-name="Groupe 27739">
                    <path data-name="Rectangle 10647" d="M0 0h30v30H0z" />
                </g>
                <g fill="#fff">
                    <path
                        data-name="Trac\xE9 21002"
                        d="M15.076 24.166a9.082 9.082 0 1 1 6.425-2.657 9.082 9.082 0 0 1-6.425 2.657Zm0-17.135a8.054 8.054 0 1 0 5.7 2.356 8.054 8.054 0 0 0-5.7-2.356Z"
                    />
                    <path
                        data-name="Trac\xE9 21003"
                        d="M15.39 20.163h-1.046a2.379 2.379 0 0 1-2.378-2.378v-.612a.434.434 0 0 1 .434-.434h.6a2.379 2.379 0 0 1 2.378 2.378Zm-2.7-2.7v.324a1.658 1.658 0 0 0 1.656 1.656h.324v-.324a1.658 1.658 0 0 0-1.656-1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21004"
                        d="M15.39 17.648h-1.046a2.378 2.378 0 0 1-2.378-2.378v-.61a.434.434 0 0 1 .434-.434h.6a2.377 2.377 0 0 1 2.378 2.378Zm-2.7-2.7v.322a1.659 1.659 0 0 0 1.656 1.658h.324v-.324a1.658 1.658 0 0 0-1.656-1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21005"
                        d="M15.39 15.132h-1.046a2.379 2.379 0 0 1-2.378-2.378v-.612a.434.434 0 0 1 .434-.434h.6a2.379 2.379 0 0 1 2.378 2.378Zm-2.7-2.7v.324a1.658 1.658 0 0 0 1.656 1.656h.324v-.324a1.658 1.658 0 0 0-1.656-1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21006"
                        d="M15.706 20.163H14.66v-1.046a2.378 2.378 0 0 1 2.378-2.378h.61a.432.432 0 0 1 .434.434v.612a2.378 2.378 0 0 1-2.376 2.378Zm-.324-.72h.324a1.658 1.658 0 0 0 1.656-1.656v-.324h-.324a1.658 1.658 0 0 0-1.656 1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21007"
                        d="M15.706 17.648H14.66v-1.044a2.376 2.376 0 0 1 2.378-2.378h.61a.432.432 0 0 1 .434.434v.61a2.376 2.376 0 0 1-2.376 2.378Zm-.324-.72h.324a1.659 1.659 0 0 0 1.656-1.658v-.322h-.324a1.658 1.658 0 0 0-1.656 1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21008"
                        d="M15.706 15.132H14.66v-1.046a2.378 2.378 0 0 1 2.378-2.378h.677a.36.36 0 0 1 .36.369v.677a2.378 2.378 0 0 1-2.369 2.378Zm-.324-.72h.324a1.658 1.658 0 0 0 1.656-1.656v-.324h-.324a1.658 1.658 0 0 0-1.656 1.656Z"
                    />
                    <path
                        data-name="Trac\xE9 21009"
                        d="m15.027 13.604-.761-.763a2.489 2.489 0 0 1 0-3.522l.518-.518a.349.349 0 0 1 .495 0l.518.518a2.493 2.493 0 0 1 0 3.522Zm0-4.034-.256.256a1.775 1.775 0 0 0 0 2.5l.256.256.257-.256a1.775 1.775 0 0 0 0-2.5Z"
                    />
                    <path
                        data-name="Trac\xE9 21010"
                        d="M15.038 21.686a.36.36 0 0 1-.36-.36v-8.231a.36.36 0 1 1 .72 0v8.23a.36.36 0 0 1-.36.362Z"
                    />
                    <path
                        data-name="Trac\xE9 21011"
                        d="M12.156 18.275a2.418 2.418 0 0 1-.133-.666l-3.272 3.274a.373.373 0 0 0 .263.635.38.38 0 0 0 .265-.11l2.946-2.946a7.058 7.058 0 0 1-.069-.187Z"
                    />
                    <path
                        data-name="Trac\xE9 21012"
                        d="M21.401 8.759a.373.373 0 0 0-.527 0l-3.117 3.119a1.073 1.073 0 0 1 .265.741.37.37 0 0 0 0 .045L21.4 9.286a.373.373 0 0 0 0-.527Z"
                    />
                </g>
            </g>
        </svg>
    );
}
