/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { DominantColor } from "@components/Common";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type ListshopProps = {
    isOpen: boolean;
    setIsOpen: Function;
    rowData: any;
    setSelectedRowsToPlanification: Dispatch<SetStateAction<any[]>>;
    selectedRowsToPlanification: any[];
    setIsOpenToplan: Function;
    isOpenToplan: boolean;
    isCardPlanified: boolean;
    setIsCardPlanified: React.Dispatch<React.SetStateAction<boolean>>;
};

function Listshop({
    isOpen,
    setIsOpen,
    rowData,
    setIsOpenToplan,
    setSelectedRowsToPlanification,
    selectedRowsToPlanification,
    isOpenToplan,
    setIsCardPlanified,
}: ListshopProps) {
    const dominantColor = DominantColor();
    const [filterActive, setFilterActive] = React.useState(false);
    const { t } = useTranslation();

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const toggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const openCalendar = (): void => {
        setIsOpenToplan(!isOpenToplan);
    };
    const checkIsAnyCardPlanining = () => {
        for (const element of selectedRowsToPlanification) {
            if (
                element?.Etat !=="En attente" &&
                element?.Etat !== "publier" &&
                element?.Etat !== "archiver" &&
                element?.Etat !== ""
            ) {
                setIsCardPlanified(true); // true
                break;
            }
        }
    };
    useEffect(() => {
        checkIsAnyCardPlanining();
    }, [selectedRowsToPlanification]);

    return (
        <div>
            <Modal centered isOpen={isOpen} className="modal-dialogshop ">
                <ModalHeader toggle={toggleOpen}>
                    <StyledH2 className="text-uppercase">
                        {t("SHOP LIST")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={`${process.env.REACT_APP_ADB_API_URL}/profiles/${schemaAdbFranchise}/0/cardsOfFranchise/${rowData?.selectedRow?.original.iuud}`}
                        canSort
                        name="Card_table"
                        customSelect
                        canSelect
                        canExpand
                        setSelectedRows={setSelectedRowsToPlanification}
                        canResize
                        showGlobalFilter
                        showFilter
                        filterActive={filterActive}
                        setLocalFilterActive={setFilterActive}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <StyledButton
                        onClick={toggleOpen}
                        rounded
                        variant="light"
                        outline
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <StyledButton
                        rounded
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={openCalendar}
                        variant={dominantColor}
                    >
                        planification
                    </StyledButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Listshop;
