import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

type deleteModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    tagIuud: string;
    setIsLoading: Function;
};

export default function DeleteTagModal({
    isOpen,
    setIsOpen,
    tagIuud,
    setIsLoading,
}: deleteModalPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const toggleOpen = (): boolean => setIsOpen(!isOpen);

    const handleRemoveButtonOnClickEvent = async (): Promise<void> => {
        try {
            setIsLoading(true);
            axios
                .delete(
                    `${process.env.REACT_APP_ADB_API_URL}tag/${schemaAdbFranchise}/${CardIuud}/${tagIuud}`
                )
                .then((): void => {
                    setIsOpen(!isOpen);
                    setIsLoading(false);
                    dynamicTableWrapperContext?.setDataUpdated(true);
                })
                .then((): void => {
                    setIsLoading(false);
                    SuccessToast(t("Tag deleted successfully"));
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                });
        } catch {
            ErrorToast(t("There is a mistake!"));
        }
    };

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase ">
                    <div className="d-flex">
                        {t("Archive Tag")} <span className="mx-1">:</span>
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={dynamicTableWrapperContext?.rowData?.title}
                            id="archiver"
                        />
                        <div id="archiver" className="modal_clz">
                            {dynamicTableWrapperContext?.rowData?.title}
                        </div>
                    </div>
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                {dynamicTableWrapperContext?.rowData?.categoriesIuud?.length >
                    0 ||
                dynamicTableWrapperContext?.rowData?.itemsIuud?.length > 0
                    ? t(
                          "Ce tag est utilisé par un ou plusieurs articles et catégories, voulez-vous continuer ?  "
                      )
                    : t("Do you want to confirm the archive of this tag ?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleRemoveButtonOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Archive")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
