/* eslint-disable react/no-unknown-property */
import React from "react";

function FlagIconWithBorder(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.096"
            height="21.12"
            viewBox="0 0 17.096 21.12"
            {...props}
        >
            <g
                id="Icon_feather-flag"
                data-name="Icon feather-flag"
                transform="translate(-5.5 -2.5)"
            >
                <path
                    id="Tracé_20994"
                    data-name="Tracé 20994"
                    d="M6,16.078s1.006-1.006,4.024-1.006,5.03,2.012,8.048,2.012S22.1,16.078,22.1,16.078V4.006S21.09,5.012,18.072,5.012,13.042,3,10.024,3,6,4.006,6,4.006Z"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Tracé_20995"
                    data-name="Tracé 20995"
                    d="M6,29.542V22.5"
                    transform="translate(0 -6.422)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
            </g>
        </svg>
    );
}

export default FlagIconWithBorder;
