/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import React from "react";

import { useTranslation } from "react-i18next";
import { StyledLabel } from "@aureskonnect/react-ui";
import OptionCheckbox from "./OptionCheckbox";

type BodyOptionsPropsType = {
    setSelectedItemOptions: React.Dispatch<
        React.SetStateAction<optionValueProps[]>
    >;
    selectedItemOptions: optionValueProps[];
    listOptions: any[];
};
export type optionValueProps = {
    optionIuud: string;
    optionValueKey: string;
};

export default function BodyOptions({
    setSelectedItemOptions,
    selectedItemOptions,
    listOptions,
}: BodyOptionsPropsType) {
    const { t } = useTranslation();

    function addoption(optionValue: optionValueProps) {
        if (
            selectedItemOptions.filter(
                (elm) =>
                    optionValue.optionIuud === elm.optionIuud &&
                    optionValue.optionValueKey === elm.optionValueKey
            ).length > 0
        ) {
            const newState: optionValueProps[] = selectedItemOptions.filter(
                // eslint-disable-next-line array-callback-return
                (elm) =>
                    optionValue.optionIuud !== elm.optionIuud ||
                    optionValue.optionValueKey !== elm.optionValueKey
            );

            setSelectedItemOptions(newState);
        } else {
            setSelectedItemOptions([...selectedItemOptions, optionValue]);
        }
    }
    const isChecked = (optionValue: optionValueProps) => {
        return (
            selectedItemOptions.filter(
                (elm) =>
                    optionValue.optionIuud === elm.optionIuud &&
                    optionValue.optionValueKey === elm.optionValueKey
            ).length > 0
        );
    };
    const isAllValueOfOptionChecked = (optioniuud: string) => {
        const selectedCardOption = listOptions.filter(
            (elm: any) => optioniuud === elm.iuud
        );

        return (
            selectedItemOptions.filter((elm) => optioniuud === elm.optionIuud)
                .length ===
            Object.keys(selectedCardOption[0]?.Option.values).length
        );
    };
    const handleToggleAllValueOfOption = (optioniuud: string) => {
        const selectedCardOption = listOptions.filter(
            (elm: any) => optioniuud === elm.iuud
        );

        if (isAllValueOfOptionChecked(optioniuud)) {
            const newState = selectedItemOptions.filter(
                (opt) => opt.optionIuud !== optioniuud
            );

            setSelectedItemOptions(() => [...newState]);
        } else {
            const allValues: any = Object.keys(
                selectedCardOption[0].Option.values
            ).map((key) => ({
                optionIuud: optioniuud,
                optionValueKey: key,
            }));
            const newArray = [...selectedItemOptions, ...allValues];
            const unique = [
                ...new Map(
                    newArray.map((option) => [option.optionValueKey, option])
                ).values(),
            ];
            setSelectedItemOptions(unique);
        }
    };

    return listOptions.length > 0 ? (
        <div style={{ overflowY: "scroll", height: "75vh" }}>
            <fieldset className="border p-2">
                <legend className="w-auto px-2">
                    <StyledLabel>{t("Select options")}</StyledLabel>
                </legend>

                {listOptions.map((option: any) => (
                    <div key={option.iuud}>
                        <div
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                fontFamily: "Segoe UI Semibold",
                                marginLeft: "12px",
                            }}
                        >
                            <OptionCheckbox
                                key={option.iuud}
                                title={option.Désignation}
                                checked={isAllValueOfOptionChecked(option.iuud)}
                                onChange={() => {
                                    handleToggleAllValueOfOption(option.iuud);
                                }}
                                disabled={
                                    selectedItemOptions.length !== 0 &&
                                    selectedItemOptions[0].optionIuud !==
                                        option.iuud
                                }
                            />
                        </div>
                        <table className="table table-bordered mt-3">
                            <thead>
                                <tr>
                                    <th
                                        scope="row"
                                        style={{
                                            marginTop: "304px",
                                            marginLeft: "468px",
                                            fontFamily: "Segoe UI, Regular",
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                        }}
                                    >
                                        <div className="d-flex flex-wrap">
                                            {Object.keys(
                                                option.Option.values
                                            ).map((key: string) => (
                                                <OptionCheckbox
                                                    key={key}
                                                    title={
                                                        option.Option.values[
                                                            key
                                                        ].title
                                                    }
                                                    disabled={
                                                        selectedItemOptions.length !==
                                                            0 &&
                                                        selectedItemOptions[0]
                                                            .optionIuud !==
                                                            option.iuud
                                                    }
                                                    checked={isChecked({
                                                        optionIuud: option.iuud,
                                                        optionValueKey: key,
                                                    })}
                                                    onChange={() => {
                                                        addoption({
                                                            optionIuud:
                                                                option.iuud,
                                                            optionValueKey: key,
                                                        });
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                ))}
            </fieldset>
        </div>
    ) : (
        <div className="d-flex justify-content-center border p-2">
            <legend className="w-auto px-2">
                {t("No options have been created !")}
            </legend>
        </div>
    );
}
