import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StyledLabel } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import DisplayNameModal from "@components/Common_DB/AdvancedDisplayNameModal";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";

type DisplayNamePropsType = {
    isDisplayNameModalOpen: boolean;
    setIsDisplayNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    displayName: DisplayNameType;
    DefaultValueName: string;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
};

export default function DisplayName({
    isDisplayNameModalOpen,
    setIsDisplayNameModalOpen,
    displayName,
    DefaultValueName,
    dispatch,
}: DisplayNamePropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const handleOnchangeDisplayName = (displayName: string): void => {
        dispatch?.({
            type: ACTIONS.DEFAULT_DISPLAY_NAME,
            payload: displayName,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.DISPLAYNAME));
    };
    return (
        <div className="py-2" id="display name">
            <div className="d-flex justify-content-between ">
                <StyledLabel>{t("Display name")}</StyledLabel>
                {!isMultiModify && (
                    <span
                        style={{
                            color: "blue",
                            cursor: "pointer",
                            border: "transparent",
                            backgroundColor: "transparent",
                            textAlign: "center",
                            textDecoration: "underline",
                            font: "normal normal 600 16px/21px Segoe UI",
                        }}
                        role="button"
                        tabIndex={0}
                        onClick={(): void => {
                            setIsDisplayNameModalOpen(true);
                        }}
                    >
                        {t("Advanced")}
                    </span>
                )}

                {isDisplayNameModalOpen && (
                    <DisplayNameModal
                        displayName={displayName}
                        dispatchDisplayName={dispatch}
                        setIsOpen={setIsDisplayNameModalOpen}
                        isOpen={isDisplayNameModalOpen}
                        DefaultValueName={DefaultValueName}
                    />
                )}
            </div>
            <div>
                <StyledInputWithTooltip
                    id="display name"
                    name="displayName"
                    type="text"
                    value={DefaultValueName}
                    requiredValidateValue={false}
                    valueSetterFunction={handleOnchangeDisplayName}
                    disabled={
                        isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production"
                    }
                />
            </div>
        </div>
    );
}
