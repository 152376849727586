import React from "react";

import { useTranslation } from "react-i18next";
import { Droppable } from "react-beautiful-dnd";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { BiMessageRoundedError } from "react-icons/bi";
import { StyledH2 } from "@aureskonnect/react-ui";
import { isMobile } from "react-device-detect";
import { PageTitle } from "@components/Common";
import SvgNoData from "@components/Common/SvgIcons/SvgNoData";

import OptionFooter from "./Footer/OptionFooter";
import OptionCurrent from "./AddItems/OptionCurrent";
import OptionAdd from "./AddItems/OptionAdd";

import "./Configuration.css";

type ConfigurationPropsType = {
    addOption: Array<Setting>;
    setAddOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
    currentOption: Array<Setting>;
    setCurrentOption: React.Dispatch<React.SetStateAction<Array<Setting>>>;
    setInitialData: React.Dispatch<React.SetStateAction<boolean>>;
    initialData: boolean;
    redirection: any;
};

export default function ListConfiguration({
    addOption,
    setAddOption,
    currentOption,
    setCurrentOption,
    setInitialData,
    initialData,
    redirection,
}: ConfigurationPropsType) {
    const { t } = useTranslation();
    const pathname = window.location.pathname.toString().split("/")[2];
    const configurationType: string =
        pathname === "Categories" ? "Catégorie" : "Article";
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gridColumnGap: "12px",
            }}
        >
            <PageTitle title={configurationType} />
            <Card>
                <CardHeader
                    style={{
                        background: "white",
                    }}
                >
                    <div className="d-flex justify-content-between">
                        <div className="p-0 mx-2 mt-2 ml-md-3 mt-md-0">
                            <StyledH2 style={{ fontWeight: "bold" }}>
                                {t("Options to add")}
                            </StyledH2>
                        </div>

                        <span className="d-flex justify-content-end ">
                            <BiMessageRoundedError size={25} />
                        </span>
                    </div>
                </CardHeader>
                <CardBody
                    className={`${!isMobile ? "scrollAjouter__clz" : ""} ${
                        addOption.length === 0 ? "center_NoDataIcon" : ""
                    }`}
                >
                    <Droppable droppableId="AddItem" type="droppableItem">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {addOption.length === 0 ? (
                                    <SvgNoData />
                                ) : (
                                    addOption?.map((element, index) => (
                                        <OptionAdd
                                            index={index}
                                            element={element}
                                            key={element.id}
                                            addOption={addOption}
                                            setAddOption={setAddOption}
                                            currentOption={currentOption}
                                            setCurrentOption={setCurrentOption}
                                        />
                                    ))
                                )}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </CardBody>
            </Card>

            <Card>
                <CardHeader
                    style={{
                        background: "white",
                    }}
                >
                    <div className="p-0 mx-2 mt-2 ml-md-3 mt-md-0">
                        <StyledH2 style={{ fontWeight: "bold" }}>
                            {t("Current Options")}
                        </StyledH2>
                    </div>
                </CardHeader>
                <CardBody className={!isMobile ? "scrollAjouter__clz" : "ok"}>
                    <Droppable droppableId="CurrentItem" type="droppableItem">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {currentOption?.map((element, index) => (
                                    <OptionCurrent
                                        index={index}
                                        element={element}
                                        key={element.id}
                                        currentOption={currentOption}
                                        setCurrentOption={setCurrentOption}
                                        addOption={addOption}
                                        setAddOption={setAddOption}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </CardBody>

                <CardFooter
                    style={{
                        background: "white",
                    }}
                >
                    <OptionFooter
                        currentOption={currentOption}
                        addOption={addOption}
                        setInitialData={setInitialData}
                        initialData={initialData}
                        redirection={redirection}
                    />
                </CardFooter>
            </Card>
        </div>
    );
}
