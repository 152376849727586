import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { ModalFooter } from "react-bootstrap";

import { SuccessToast, DominantColor } from "@components/Common/.";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { VatRateContext } from "@pages/Catalogue/Items/addItem/Price";
import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";

type PriceModalType = {
    setActive: any;
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
};
export default function PriceModalFooter({
    setActive,
    dispatchItem,
}: PriceModalType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const dominantColor = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );
    const vatRateContext = React.useContext(VatRateContext);
    const handleValidateButtonOnClickEvent = () => {
        if (
            isEditing &&
            cardType === "shop" &&
            cardStatus === "En production"
        ) {
            setActive(false);
        } else {
            dispatchItem({
                type: ACTIONS.ADVANCED_PRICE,
                payload: vatRateContext.advancedPriceCopy,
            });
            isMultiModify &&
                dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.PRICE));
            setActive(false);
            SuccessToast(t("Successful registration"));
        }
    };
    return (
        <ModalFooter className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
                <div className="">
                    <StyledButton
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            vatRateContext.setAdvancedPriceCopy(
                                vatRateContext.itemPrice.advancedPrice
                            );
                            setActive(false);
                        }}
                        style={{
                            padding: "0%",
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                </div>
                <div className="ml-3">
                    <StyledButton
                        rounded
                        variant={dominantColor}
                        onClick={handleValidateButtonOnClickEvent}
                        style={{
                            padding: "0%",
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </ModalFooter>
    );
}
