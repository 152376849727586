import React from "react";

import { useSelector } from "react-redux";

import { DynamicTableWrapperContext } from "@components/Common";
import { ACTIONS } from "@helpers/actions";
import { RootState } from "@store/.";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";

import "../Style.css";
import ItemIcon from "@components/Common/SvgIcons/ItemIcon";

type AddItemHeaderPropsType = {
    dispatchItem: Function;
    setDefaultParentCategory: Function;
    setselectedLinkCategories: Function;
};

function AddItemHeader({
    dispatchItem,
    setDefaultParentCategory,
    setselectedLinkCategories,
}: AddItemHeaderPropsType) {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const defaultVisibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );

    const updateItemWorkflow = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const oldValue = useSelector(
        (state: RootState) => state.editObserverSlice.oldValue
    );
    const handleRefreshIconClick = (): void => {
        if (updateItemWorkflow.pathUpdateItem.length > 0) {
            dispatchItem({
                type: ACTIONS.EDIT,
                payload: oldValue,
            });
        } else {
            dispatchItem({
                type: ACTIONS.RESET,
                payload: {},
            });

            dispatchItem({
                type: ACTIONS.INIT_VISIBILITY,
                payload: defaultVisibility,
            });
            setDefaultParentCategory({});
            setselectedLinkCategories([]);

            dynamicTableWrapperContext?.setDataUpdated(true);
        }
    };

    return (
        <StyleCardHeader
            title="Item"
            refrechIconFunction={handleRefreshIconClick}
            Icon={
                <ItemIcon
                    width={31}
                    height={31}
                    style={{
                        background: "#096595",
                        borderRadius: "100%",
                        padding: "5px",
                    }}
                />
            }
        />
    );
}

export default AddItemHeader;
