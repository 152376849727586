export const SELLING_SUPPORT: sellingSupportType[] = [
    {
        sellingSupport: "POS",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
    {
        sellingSupport: "KIOSK",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
    {
        sellingSupport: "UBER",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
    {
        sellingSupport: "SCO",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
    {
        sellingSupport: "APPLICATION C&C",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
    {
        sellingSupport: "SITE E-COMMERCE",
        visibility: true,
        Langue: [
            {
                designation: "Francais",
                value: "",
                visibility: true,
            },
            {
                designation: "Anglais",
                value: "",
                visibility: true,
            },
            {
                designation: "Italien",
                value: "",
                visibility: true,
            },
            {
                designation: "Allemand",
                value: "",
                visibility: true,
            },
        ],
    },
];
