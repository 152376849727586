/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import React from "react";
import { TrashIcon, PencilIcon, DuplicateIcon } from "@aureskonnect/react-ui";
import { useDispatch, useSelector } from "react-redux";

import { DynamicTableWrapperContext } from "@components/Common";
import { RootState } from "@store/.";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import { useTranslation } from "react-i18next";
import UnsavedChangesWarningModal from "./UnsavedChangesWarningModal";
import LockIconWithToolTip from "../LockIconWithToolTip/LockIconWithToolTip";

type ActionCRUDColumnProps = {
    e: any;
    handleDuplicate: (e: any) => void;
    handleEdit: (e: any) => void;
    handleDelete: (e: any) => void;
};

export default function ActionCRUDColumn({
    e,
    handleDuplicate,
    handleEdit,
    handleDelete,
}: ActionCRUDColumnProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ETKType = localStorage.getItem("ETKType");
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);

    return (
        <div className="d-flex justify-content-around w-50">
            {ETKType === "1" ||
            (cardType === "shop" && cardStatus === "En production") ? (
                <>
                    {" "}
                    <PencilIcon
                        style={{
                            cursor:
                                e?.selectedRow.selectedRows.length === 0
                                    ? "pointer"
                                    : "default",
                            pointerEvents:
                                e?.selectedRow.selectedRows.length > 1
                                    ? "none"
                                    : "auto",
                            opacity:
                                e?.selectedRow.selectedRows.length > 1
                                    ? 0.5
                                    : 1,
                        }}
                        height={25}
                        width={25}
                        onClick={(event: any) => {
                            event.stopPropagation();
                            if (e?.selectedRow.selectedRows.length <= 1) {
                                if (isDirty) {
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dispatch(setIsEditing(false));
                                    handleEdit(e);
                                    dynamicTableWrapperContext?.setLocalFilterActive(
                                        false
                                    );
                                }
                            }
                        }}
                    />
                </>
            ) : (
                <>
                    <div
                        id={`Duplication${e?.selectedRow?.iuud}`}
                        className=" mr-2"
                    >
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={t(`Duplicate`)}
                            id={`Duplication${e?.selectedRow?.iuud}`}
                        />
                        <DuplicateIcon
                            style={{
                                cursor:
                                    e?.selectedRow.selectedRows.length === 0
                                        ? "pointer"
                                        : "default",
                                pointerEvents:
                                    e?.selectedRow.selectedRows.length > 1
                                        ? "none"
                                        : "auto",
                                opacity:
                                    e?.selectedRow.selectedRows.length > 1
                                        ? 0.5
                                        : 1,
                            }}
                            height={25}
                            width={25}
                            onClick={(event: any) => {
                                event.stopPropagation();
                                if (e?.selectedRow.selectedRows.length <= 1) {
                                    handleDuplicate(e);
                                    dynamicTableWrapperContext?.setLocalFilterActive(
                                        false
                                    );
                                }
                            }}
                        />
                    </div>
                    <div id={`Modifier${e?.selectedRow?.iuud}`}>
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={t(`Edit`)}
                            id={`Modifier${e?.selectedRow?.iuud}`}
                        />
                        <PencilIcon
                            style={{
                                cursor:
                                    e?.selectedRow.selectedRows.length === 0
                                        ? "pointer"
                                        : "default",
                                pointerEvents:
                                    e?.selectedRow.selectedRows.length > 1
                                        ? "none"
                                        : "auto",
                                opacity:
                                    e?.selectedRow.selectedRows.length > 1
                                        ? 0.5
                                        : 1,
                            }}
                            height={25}
                            width={25}
                            onClick={(event: any) => {
                                event.stopPropagation();
                                if (e?.selectedRow.selectedRows.length <= 1) {
                                    if (isDirty) {
                                        setIsUnsavedChangesWarningModalOpen(
                                            true
                                        );
                                    } else {
                                        dispatch(setIsEditing(false));
                                        handleEdit(e);
                                        dynamicTableWrapperContext?.setLocalFilterActive(
                                            false
                                        );
                                    }
                                }
                            }}
                        />
                    </div>
                    {!location.pathname.includes("categories") && (
                        <div id={`Archiver${e?.selectedRow?.iuud}`}>
                            <LockIconWithToolTip
                                type="icon"
                                lengthMax={0}
                                message={t(`Archive`)}
                                id={`Archiver${e?.selectedRow?.iuud}`}
                            />
                            <TrashIcon
                                style={{
                                    cursor:
                                        e?.selectedRow.selectedRows.length === 0
                                            ? "pointer"
                                            : "default",
                                    pointerEvents:
                                        e?.selectedRow.selectedRows.length > 1
                                            ? "none"
                                            : "auto",
                                    opacity:
                                        e?.selectedRow.selectedRows.length > 1
                                            ? 0.5
                                            : 1,
                                }}
                                height={25}
                                width={25}
                                fill="red"
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    if (
                                        e?.selectedRow.selectedRows.length <= 1
                                    ) {
                                        handleDelete(e);
                                        dynamicTableWrapperContext?.setLocalFilterActive(
                                            false
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                </>
            )}

            <UnsavedChangesWarningModal
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                action={() => {
                    setIsUnsavedChangesWarningModalOpen(false);
                    dispatch(initEditObserver());
                    handleEdit(e);
                }}
            />
        </div>
    );
}
