import React from "react";
import { StepIcon } from "@components/Common/SvgIcons";
import SaleAddIcon from "@components/Common/SvgIcons/SaleAddIcon";
import { RootState } from "@store/.";
import { useDispatch, useSelector } from "react-redux";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { setWorkflow } from "@store/features/workflowSlice";
import uuidByString from "uuid-by-string";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { checkModifierExist, ItemIsCrushed } from "@pages/WorkFlow/helpers";

type AddMultipleStepInStepProps = {
    IsBasicCompositionStep: boolean;
    IsOptionStep: boolean;
    isMultipleStep: boolean;
    globalIndex: number;
    cardData: any;
    setContentModal: React.Dispatch<any>;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    uuid: string;
    setIsCrushed: React.Dispatch<React.SetStateAction<boolean>>;
    setAction: React.Dispatch<React.SetStateAction<string>>;
};

export function AddMultipleStepInStep({
    IsBasicCompositionStep,
    IsOptionStep,
    isMultipleStep,
    globalIndex,
    cardData,
    setContentModal,
    setIsOpenStepModal,
    uuid,
    setIsCrushed,
    setAction,
}: AddMultipleStepInStepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const ETKType = localStorage.getItem("ETKType");

    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const addMultipleStep = (
        workflowType: string,
        bTypeSteps: boolean,
        bTypeAdd: boolean
    ) => {
        let copymodifierWorkflow = { ...modifierWorkflow };
        const previousStateWorkflow: previousWorkflowStateProps = {
            modifierSlice: modifierWorkflow,
            multipleWorkflow: JSON.parse(JSON.stringify(multipleWorkflow)),
            globalWorkflow,
            modalWorkflow: [],
            globalWorkflowData: JSON.parse(JSON.stringify(globalWorkflowData)),
        };

        /* verifier si vente add existe */
        const firstUuidSelected =
            globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0];

        const modifierTypeCkecked =
            workflowType === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.TYPEADD /* type vente additionnel */
                : WORKFLOW_LIST.TYPESTEPS; /* type steps */

        const modifierChecked =
            multipleWorkflow.length > 0
                ? {
                      ...multipleWorkflow[multipleWorkflow.length - 1]
                          .VirtualModifier,
                      ...modifierWorkflow,
                  }
                : modifierWorkflow;

        /* check item is crushed */
        const isCrushed = ItemIsCrushed(
            globalWorkflow,
            globalIndex,
            modifierWorkflow,
            workflowType === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.TYPESTEPS
                : WORKFLOW_LIST.TYPEADD,
            cardData
        );
        if (isCrushed) {
            setAction(
                workflowType === WORKFLOW_LIST.TRIGGER
                    ? WORKFLOW_LIST.STEPS
                    : WORKFLOW_LIST.TYPEADD
            );
            setIsCrushed(true);
            return;
        }

        const modifierForm = checkModifierExist(
            firstUuidSelected,
            modifierTypeCkecked,
            globalIndex,
            modifierChecked,
            globalWorkflow
        );

        const iuudMD5 = modifierForm.isModifier
            ? modifierForm.modifierUuid
            : uuidByString(
                  [
                      ...globalWorkflow[globalIndex!].previousUuids!,
                      firstUuidSelected,
                  ].join("")
              );

        /* add modifier */
        // eslint-disable-next-line no-prototype-builtins
        if (copymodifierWorkflow.hasOwnProperty(iuudMD5)) {
            copymodifierWorkflow =
                workflowType === WORKFLOW_LIST.TRIGGER
                    ? {
                          /* type steps */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeSteps: true,
                          },
                      }
                    : {
                          /* type vente additionnel */
                          [iuudMD5]: {
                              ...modifierWorkflow[iuudMD5],
                              typeAdd: true,
                          },
                      };
        } else {
            copymodifierWorkflow = {
                [iuudMD5]: {
                    steps: {},
                    add: {},
                    category: {},
                    typeSteps: bTypeSteps,
                    typeAdd: bTypeAdd,
                    typeCategorie: false,
                },
            };
        }

        const copyWorkflow = [...globalWorkflow];
        copyWorkflow[globalIndex!] = {
            ...copyWorkflow[globalIndex!],
            uuidModifier: iuudMD5!,
        };

        // insert modifier to global workflow
        let listeTitle: string = "";
        globalWorkflow[globalIndex!]?.multipleStep?.listeUuidItem.forEach(
            (elementUuid: string, index: number) => {
                const modifierForm = checkModifierExist(
                    elementUuid,
                    modifierTypeCkecked,
                    globalIndex,
                    modifierChecked,
                    globalWorkflow
                );
                const uuidMD5Multiple =
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                        ? iuudMD5
                        : modifierForm.isModifier
                        ? modifierForm.modifierUuid
                        : uuidv4();

                copyWorkflow[globalIndex!] = {
                    ...copyWorkflow[globalIndex!],
                    data: {
                        ...copyWorkflow[globalIndex!].data,
                        [elementUuid]: {
                            ...copyWorkflow[globalIndex!].data[elementUuid],
                            content: {
                                ...copyWorkflow[globalIndex!].data[elementUuid]
                                    .content,
                                [uuidMD5Multiple]: {
                                    type: "modifier",
                                    override: {},
                                    content: {},
                                },
                            },
                        },
                    },
                };

                listeTitle === ""
                    ? (listeTitle = cardData?.items?.[elementUuid]?.title)
                    : (listeTitle += `, ${cardData?.items?.[elementUuid]?.title}`);
            }
        );

        const ContentModal: any = [];
        ContentModal.push({
            title: listeTitle,
            type: workflowType,
            triggerItemIuud:
                globalWorkflow[globalIndex!].multipleStep.listeUuidItem[0],

            previousUuidStep: globalWorkflow[globalIndex!].uuidStep!,
            previousModifierStep: globalWorkflow[globalIndex!].previousModifier,
            previousModifier: iuudMD5,
            isMultiple: true,
            previousState: previousStateWorkflow,
            indexPreviousStep: globalIndex,
            data: [
                {
                    title: `Etape 1`,
                    data: [],
                    uuid: globalWorkflow[globalIndex!].multipleStep
                        .listeUuidItem[0],
                    uuidStep: "",
                    type: WORKFLOW_LIST.STEPS,
                    previousUuids: [
                        ...globalWorkflow[globalIndex!].previousUuids!,
                        uuid,
                    ],
                    override: {},
                    multipleStep: {
                        isMultiple: false,
                        listeUuidItem: [],
                    },
                },
            ],
        });
        setContentModal(ContentModal);
        setIsOpenStepModal(true);

        dispatch(
            setMultipleWorkflow([
                ...multipleWorkflow,
                {
                    popupIndex: 0,
                    StepIndex: globalIndex,
                    VirtualModifier: copymodifierWorkflow,
                    listeUuidItem:
                        globalWorkflow[globalIndex!]?.multipleStep
                            ?.listeUuidItem,
                    PreviousModifier: iuudMD5,
                },
            ])
        );
        dispatch(setWorkflow(copyWorkflow));
    };

    return (
        <>
            <div
                className={
                    ETKType === "3" &&
                    !cardData?.items?.[globalWorkflow[globalIndex!]?.uuid]
                        ?.isRedirect
                        ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                        : "icon_step__CLZ text-center mr-2"
                }
                style={{
                    display:
                        IsBasicCompositionStep || IsOptionStep
                            ? "none"
                            : "d-flex justify-content-center align-items-center",
                }}
            >
                <LockIconWithToolTip
                    type="icon"
                    lengthMax={0}
                    message={t("Additional sale")}
                    id={`SalesAddStep${globalIndex}`}
                />

                <SaleAddIcon
                    id={`SalesAddStep${globalIndex}`}
                    role="button"
                    width={22}
                    height={22}
                    stopColor="white"
                    fill="black"
                    style={
                        cardType === "shop" && cardStatus === "En production"
                            ? { pointerEvents: "none" }
                            : {
                                  borderRadius: "100%",
                                  backgroundColor: "#transparent",
                                  boxShadow: "0px 3px 6px #00000029",
                              }
                    }
                    onClick={() => {
                        isMultipleStep &&
                            (ETKType !== "3" ||
                                cardData?.items?.[
                                    globalWorkflow[globalIndex!]?.uuid
                                ]?.isRedirect) &&
                            addMultipleStep(WORKFLOW_LIST.ADD, false, true);
                    }}
                />
            </div>
            <div
                className={
                    ETKType === "3" &&
                    !cardData?.items?.[globalWorkflow[globalIndex!]?.uuid]
                        ?.isRedirect
                        ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                        : "icon_step__CLZ text-center mr-2"
                }
                style={{
                    display:
                        IsBasicCompositionStep || IsOptionStep
                            ? "none"
                            : "grid",
                }}
            >
                <LockIconWithToolTip
                    type="icon"
                    lengthMax={0}
                    message={t("Trigger workflow")}
                    id={`apcours10${globalIndex}`}
                />

                <StepIcon
                    id={`apcours10${globalIndex}`}
                    role="button"
                    width={30}
                    height={30}
                    stopColor="white"
                    fill="black"
                    style={
                        cardType === "shop" && cardStatus === "En production"
                            ? { pointerEvents: "none" }
                            : {
                                  borderRadius: "100%",
                                  backgroundColor: "#transparent",
                                  boxShadow: "0px 3px 6px #00000029",
                              }
                    }
                    onClick={() => {
                        isMultipleStep &&
                            (ETKType !== "3" ||
                                cardData?.items?.[
                                    globalWorkflow[globalIndex!]?.uuid
                                ]?.isRedirect) &&
                            addMultipleStep(WORKFLOW_LIST.TRIGGER, true, false);
                    }}
                />
            </div>
        </>
    );
}
