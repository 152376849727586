import { StepIcon } from "@aureskonnect/react-ui";

type StepPropsType = {
    isCurrentStepSelected: boolean;
    step: StepWithChildrenType;
    setCollapsedStepsUuids: React.Dispatch<React.SetStateAction<string[]>>;
    addSelectedItem: Function;
    deleteSelectedItem: Function;
};

export default function Step({
    isCurrentStepSelected,
    step,
    setCollapsedStepsUuids,
    addSelectedItem,
    deleteSelectedItem,
}: StepPropsType) {
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const deleteSelectedStepItems = (selectedStepItems: StepItemWithIuud[]) => {
        selectedStepItems.forEach((selectedItem) =>
            deleteSelectedItem(selectedItem)
        );
    };

    const addSelectedStepItems = (selectedStepItems: StepItemWithIuud[]) => {
        selectedStepItems.forEach((selectedItem) => {
            deleteSelectedItem(selectedItem);
            addSelectedItem(selectedItem);
        });
    };
    return (
        <div className="d-flex  justify-content-between my-1">
            <div
                role="button"
                tabIndex={0}
                onClick={() => {
                    setCollapsedStepsUuids((collapsedStepsUuids) => {
                        if (collapsedStepsUuids.includes(step.iuud)) {
                            return [...collapsedStepsUuids].filter(
                                (stepUuid) => stepUuid !== step.iuud
                            );
                        }
                        return [...collapsedStepsUuids, step.iuud];
                    });
                }}
            >
                <StepIcon
                    width={20}
                    height={20}
                    fill="#6800C2"
                    className="mr-2"
                    style={
                        cardType === "shop" && cardStatus === "En production"
                            ? { pointerEvents: "none" }
                            : {
                                  borderRadius: "4px",
                              }
                    }
                />
                {step.title}
            </div>
            <div>
                <input
                    type="checkbox"
                    className="ml-3"
                    id={step.iuud}
                    checked={isCurrentStepSelected}
                    onChange={() => {
                        isCurrentStepSelected
                            ? deleteSelectedStepItems(step.items)
                            : addSelectedStepItems(step.items);
                    }}
                />
            </div>
        </div>
    );
}
