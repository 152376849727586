import React from "react";
import { RootState } from "@store/.";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import { useDispatch, useSelector } from "react-redux";
import { InsertOverrideOptionInItem } from "@pages/WorkFlow/hooks/helpersOption";
import { InsertOverrideBasicCompositionInItem } from "@pages/WorkFlow/hooks/helpersBasicComposition";
import { uploadGetUrlImage } from "@helpers/uploadGetUrlImage";
import { setModifier } from "@store/features/modifierSlice";

type changeImageProductProps = {
    uuid: string;
    cardData: any;
    globalIndex: number;
    image: string | undefined;
    contentModal: any;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    IsOptionStep: boolean;
};

export function ChangeImageProductModal({
    uuid,
    cardData,
    globalIndex,
    image,
    contentModal,
    setIsModify,
    isModify,
    IsOptionStep,
}: changeImageProductProps) {
    const dispatch = useDispatch();
    const schemaAdbFranchise: any = localStorage.getItem("schemaAdbFranchise");
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );

    const handleOptionImage = async (event: any) => {
        event.stopPropagation();
        let pathImage: string = "";

        if (
            event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpeg"
        ) {
            const result: any = await uploadGetUrlImage(
                event.target.files[0],
                schemaAdbFranchise,
                usedCard
            );
            pathImage = result ? result.data.imagePath : "";
        } else {
            return;
        }

        const isOverride =
            Object.keys(
                modifierWorkflow[
                    contentModal[contentModal.length - 1]?.previousModifierStep
                ]?.steps[
                    contentModal[contentModal.length - 1]?.previousUuidStep
                ]?.overrides || {}
            ).length > 0;

        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[
                          contentModal[contentModal.length - 1]
                              ?.previousModifierStep
                      ]?.steps[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(
                  JSON.stringify(
                      cardData?.steps?.[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]
                  )
              );

        let copyOverrideOption: WorkflowOptionType = {};
        isOverride
            ? (copyOverrideOption = modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[
                  contentModal[contentModal.length - 1]?.previousUuidStep
              ]?.overrides.stepItems[
                  contentModal[contentModal.length - 1].triggerItemIuud
                  // eslint-disable-next-line no-prototype-builtins
              ].hasOwnProperty("overrideOptions")
                  ? modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ]?.steps[
                        contentModal[contentModal.length - 1]?.previousUuidStep
                    ]?.overrides.stepItems[
                        contentModal[contentModal.length - 1].triggerItemIuud
                    ]?.overrideOptions
                  : InsertOverrideOptionInItem(
                        copyOverrideOption,
                        cardData,
                        contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.uuid
                    ))
            : (copyOverrideOption = InsertOverrideOptionInItem(
                  copyOverrideOption,
                  cardData,
                  contentModal[contentModal.length - 1]?.data[globalIndex!]
                      ?.uuid
              ));

        const UuidOptions =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep;

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideOptions: {
                    ...copyOverrideOption,
                    [UuidOptions]: {
                        ...copyOverrideOption[UuidOptions],
                        content: {
                            ...copyOverrideOption[UuidOptions].content,
                            [uuid]: {
                                ...copyOverrideOption[UuidOptions]?.content[
                                    uuid
                                ],
                                image: pathImage,
                            },
                        },
                    },
                },
            },
        };

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [contentModal[contentModal.length - 1]?.previousModifierStep]: {
                    ...modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ],
                    steps: {
                        ...modifierWorkflow[
                            contentModal[contentModal.length - 1]
                                ?.previousModifierStep
                        ].steps,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...modifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    ?.previousModifierStep
                            ].steps[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
        setIsModify(!isModify);
    };
    const handleBasicCompositionImage = async (event: any) => {
        event.stopPropagation();
        let pathImage: string = "";

        if (
            event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpeg"
        ) {
            const result: any = await uploadGetUrlImage(
                event.target.files[0],
                schemaAdbFranchise,
                usedCard
            );
            pathImage = result ? result.data.imagePath : "";
        } else {
            return;
        }

        const isOverride =
            Object.keys(
                modifierWorkflow[
                    contentModal[contentModal.length - 1]?.previousModifierStep
                ]?.steps[
                    contentModal[contentModal.length - 1]?.previousUuidStep
                ]?.overrides || {}
            ).length > 0;

        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[
                          contentModal[contentModal.length - 1]
                              ?.previousModifierStep
                      ]?.steps[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(
                  JSON.stringify(
                      cardData?.steps?.[
                          contentModal[contentModal.length - 1]
                              ?.previousUuidStep
                      ]
                  )
              );

        let copyOverrideBasicComposition: any = {};
        isOverride
            ? (copyOverrideBasicComposition = modifierWorkflow[
                  contentModal[contentModal.length - 1]?.previousModifierStep
              ]?.steps[
                  contentModal[contentModal.length - 1]?.previousUuidStep
              ]?.overrides.stepItems[
                  contentModal[contentModal.length - 1].triggerItemIuud
                  // eslint-disable-next-line no-prototype-builtins
              ].hasOwnProperty("overrideBasicComposition")
                  ? modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ]?.steps[
                        contentModal[contentModal.length - 1]?.previousUuidStep
                    ]?.overrides.stepItems[
                        contentModal[contentModal.length - 1].triggerItemIuud
                    ]?.overrideBasicComposition
                  : InsertOverrideBasicCompositionInItem(
                        copyOverrideBasicComposition,
                        cardData,
                        contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.uuid
                    ))
            : (copyOverrideBasicComposition =
                  InsertOverrideBasicCompositionInItem(
                      copyOverrideBasicComposition,
                      cardData,
                      contentModal[contentModal.length - 1]?.data[globalIndex!]
                          ?.uuid
                  ));

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideBasicComposition: {
                    ...copyOverrideBasicComposition,
                    [WORKFLOW_LIST.BASICCOMPOSITION]: {
                        ...copyOverrideBasicComposition[
                            WORKFLOW_LIST.BASICCOMPOSITION
                        ],
                        content: {
                            ...copyOverrideBasicComposition[
                                WORKFLOW_LIST.BASICCOMPOSITION
                            ].content,
                            [uuid]: {
                                ...copyOverrideBasicComposition[
                                    WORKFLOW_LIST.BASICCOMPOSITION
                                ]?.content[uuid],
                                image: pathImage,
                            },
                        },
                    },
                },
            },
        };

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [contentModal[contentModal.length - 1]?.previousModifierStep]: {
                    ...modifierWorkflow[
                        contentModal[contentModal.length - 1]
                            ?.previousModifierStep
                    ],
                    steps: {
                        ...modifierWorkflow[
                            contentModal[contentModal.length - 1]
                                ?.previousModifierStep
                        ].steps,
                        [contentModal[contentModal.length - 1]
                            ?.previousUuidStep]: {
                            ...modifierWorkflow[
                                contentModal[contentModal.length - 1]
                                    ?.previousModifierStep
                            ].steps[
                                contentModal[contentModal.length - 1]
                                    ?.previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
        setIsModify(!isModify);
    };

    return (
        <label
            htmlFor={`file-${uuid}`}
            style={{
                cursor: "pointer",
                width: " 70px",
                height: "53px",
                marginLeft: "4px",
            }}
            className=" d-flex justify-content-center align-items-center "
        >
            <img
                style={{
                    width: " 70px",
                    height: "53px",
                    marginLeft: "4px",
                }}
                src={image === "" ? "no-pictures.svg" : image}
                className="card-img-top"
                alt="..."
            />
            <input
                type="file"
                id={`file-${uuid}`}
                name="imgCollection"
                onChange={(event: any) => {
                    IsOptionStep
                        ? handleOptionImage(event)
                        : handleBasicCompositionImage(event);
                }}
                style={{ display: "none" }}
            />
        </label>
    );
}
