/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from "react";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CardFooter } from "reactstrap";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    StyledButton,
    StyledLabel,
    StyledSelectInput,
} from "@aureskonnect/react-ui";

import { RootState } from "@store/.";
import {
    ErrorToast,
    RequirementToast,
    SuccessToast,
    DominantColor,
    DynamicTableWrapperContext,
    CustomCardBody,
} from "@components/Common/";

import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import DeleteOptionModal from "./DeleteOptionModal";
import ValueOption from "./ValueOption";

import "./style.css";

type addOptionPropsType = { setIsLoading: Function };
type editOption = {
    name: string;
    isFavorite: boolean;
    index: number;
};

export function Addoption({ setIsLoading }: addOptionPropsType) {
    const { t } = useTranslation();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const dispatch = useDispatch();
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const [listOptionValues, setListOptionValues] = React.useState<
        {
            name: string;
            isFavorite: boolean;
            iuud: string;
            items: [];
            rank?: number;
        }[]
    >([]);

    const [isOpen, setIsOpen] = React.useState(false);
    const [isNotBeDeleted, setIsNotBeDeleted] = React.useState(false);

    const [editOption, setEditOption] = React.useState<editOption>({
        name: "",
        isFavorite: false,
        index: -1,
    });

    const [option, setOption] = React.useState<OptionType>({
        title: "",
        values: {},
        defaultValue: "",
    });

    const dominantColor: string = DominantColor();

    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleOptionsFromChange = (title: string): void => {
        setOption({ ...option, title });
    };

    const handleRefreshIconClick = (): void => {
        setOption({
            title: "",
            values: {},
            defaultValue: "",
        });
        setEditOption({ name: "", isFavorite: false, index: -1 });
        setListOptionValues([]);
    };

    const insertOption = async (): Promise<void> => {
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                const CardIuud = JSON.parse(
                    localStorage.getItem("selectedCard") || "{}"
                ).iuud;

                let defaultValue = "";

                const newValuesStructure = listOptionValues.reduce(
                    (previous: any, current: any) => {
                        if (current.isFavorite) {
                            defaultValue = current.name;
                        }

                        const newValue =
                            current.iuud !== ""
                                ? {
                                      [current.iuud]: {
                                          title: current.name,
                                          items: current.items,
                                          rank: current.rank,
                                          isFavorite: current.isFavorite,
                                      },
                                  }
                                : {
                                      [uuidv4()]: {
                                          title: current.name,
                                          items: [],
                                          rank: current.rank,
                                          isFavorite: current.isFavorite,
                                      },
                                  };

                        return {
                            ...previous,
                            ...newValue,
                        };
                    },
                    {}
                );

                let url = `${process.env.REACT_APP_ADB_API_URL}/addoption/${schemaAdbFranchise}/${CardIuud}`;
                if (dynamicTableWrapperContext?.rowData?.iuud) {
                    url = `${process.env.REACT_APP_ADB_API_URL}${CardIuud}/Updateoption/${schemaAdbFranchise}/${dynamicTableWrapperContext?.rowData?.iuud}`;
                }
                axios
                    .post(url, {
                        ...option,
                        values: newValuesStructure,
                        defaultValue,
                    })
                    .then((data: any): void => {
                        if (data.data.error === true) {
                            ErrorToast(
                                t("Designation already exists try another!")
                            );
                            setValidateOnclick(true);
                        } else {
                            setValidateOnclick(true);
                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            setIsLoading(false);

                            SuccessToast(
                                t(
                                    dynamicTableWrapperContext?.rowData?.iuud
                                        ? "The option edited successfully"
                                        : "The option added successfully"
                                )
                            );
                        }
                        dynamicTableWrapperContext?.setDataUpdated(true);
                    })
                    .catch((): void => {
                        ErrorToast(t("There is a mistake!"));
                    });
            } catch {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    // eslint-disable-next-line
    const Defaultvalue = (index: number): void => {
        listOptionValues.forEach((option: any): void => {
            option.isFavorite = false;
        });
        listOptionValues[index].isFavorite = true;
        setListOptionValues([...listOptionValues]);
    };

    const handleOnscroll = (): void => {
        if (
            option.title === "" &&
            listOptionValues.filter((elm) => elm.isFavorite).length === 0
        ) {
            RequirementToast(
                t(
                    "The fields ''Designation'', ''Value by default'' must be filled"
                )
            );
        } else if (option.title === "") {
            RequirementToast(t("The field ''Designation'' must be filled"));
        } else if (
            listOptionValues.filter((elm) => elm.isFavorite).length === 0
        ) {
            RequirementToast(
                t("The field ''Value by default'' must be filled")
            );
        }
    };

    React.useEffect((): void => {
        if (dynamicTableWrapperContext?.rowData.title !== "") {
            setOption({
                title: dynamicTableWrapperContext?.rowData?.title,
                values: dynamicTableWrapperContext?.rowData?.values,
                defaultValue: dynamicTableWrapperContext?.rowData?.defaultValue,
            });
            dispatch(setIsEditing(true));
            setListOptionValues(
                Object.entries(dynamicTableWrapperContext?.rowData?.values)
                    .sort((a: any, b: any) => a[1].rank - b[1].rank)
                    .map(([key]) => {
                        return {
                            name: dynamicTableWrapperContext?.rowData?.values[
                                key
                            ].title,
                            isFavorite:
                                dynamicTableWrapperContext?.rowData?.values[key]
                                    .isFavorite,
                            iuud: key,
                            items: dynamicTableWrapperContext?.rowData?.values[
                                key
                            ].items,
                        };
                    })
            );
        }
    }, [dispatch, dynamicTableWrapperContext?.rowData]);

    const defaultOptionValue = () => {
        const favoriteValue = listOptionValues.filter(
            (elm: any) => elm.isFavorite
        );
        return favoriteValue.length > 0
            ? { label: favoriteValue[0].name, value: favoriteValue[0].name }
            : { name: t("Select"), label: t("Select") };
    };

    useUnsavedChangesWarning(option);
    const isSubmitting = useSelector(
        (state: RootState): boolean => state.editObserverSlice.isSubmitting
    );
    const validateButtonRef = React.useRef<HTMLButtonElement>(null);
    if (isSubmitting === true) {
        validateButtonRef.current?.click();
        dispatch(initEditObserver());
    }
    return (
        <>
            <Card style={{ border: "0px " }}>
                <StyleCardHeader
                    title="Option"
                    refrechIconFunction={handleRefreshIconClick}
                />
                <AvForm
                    onValidSubmit={insertOption}
                    onInvalidSubmit={handleOnscroll}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                >
                    <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                        <div className="px-3 py-2">
                            <div className="py-2">
                                <StyledLabel>{t("Designation *")}</StyledLabel>
                                <StyledInputWithTooltip
                                    id="Désignation"
                                    name="title"
                                    type="text"
                                    value={option.title as string}
                                    valueSetterFunction={
                                        handleOptionsFromChange
                                    }
                                    requiredValidateValue
                                    autoFocus
                                    onFocus={(event: any) => {
                                        event.target.select();
                                    }}
                                />
                            </div>

                            <ValueOption
                                listOptionValues={listOptionValues}
                                editOption={editOption}
                                setEditOption={setEditOption}
                                setListOptionValues={setListOptionValues}
                                setIsOpen={setIsOpen}
                                setIsNotBeDeleted={setIsNotBeDeleted}
                                Defaultvalue={Defaultvalue}
                            />

                            <div className="py-2">
                                <StyledLabel htmlFor="example-select">
                                    {t("Default value")} *
                                </StyledLabel>
                                <StyledSelectInput
                                    closeMenuOnSelect
                                    value={defaultOptionValue()}
                                    name="example-select"
                                    placeholder="Insert text"
                                    options={listOptionValues.map(
                                        (element: any, index: number) => ({
                                            label: element.name,
                                            value: element.name,
                                            index,
                                        })
                                    )}
                                    noOptionsMessage={() => t("No options")}
                                    onChange={(e: any): void =>
                                        Defaultvalue(e.index)
                                    }
                                />
                                <AvField
                                    name="categorie-parente"
                                    value={defaultOptionValue().value}
                                    className="d-none"
                                    autoComplete="off"
                                    validate={{
                                        required: {
                                            errorMessage: t(
                                                "This field is required"
                                            ),
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </CustomCardBody>
                    <CardFooter className="Footercard p-0 ">
                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                            <div className="p-2 bd-highlight">
                                <StyledButton
                                    ref={validateButtonRef}
                                    rounded
                                    variant={dominantColor}
                                >
                                    {t("Validate")}
                                </StyledButton>
                            </div>
                            <div className="p-2 bd-highlight ">
                                <StyledButton
                                    rounded
                                    outline
                                    variant="light"
                                    onClick={() => {
                                        dynamicTableWrapperContext?.setActionColumnDisplay(
                                            false
                                        );
                                    }}
                                >
                                    {t("Cancel")}
                                </StyledButton>
                            </div>
                        </div>
                    </CardFooter>
                </AvForm>
            </Card>
            {isOpen && option?.iuud !== "" && (
                <DeleteOptionModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    optionIuud={option?.iuud !== undefined ? option?.iuud : ""}
                    setIsLoading={setIsLoading}
                    isNotBeDeleted={isNotBeDeleted}
                />
            )}
        </>
    );
}
