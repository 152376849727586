/* eslint-disable import/order */
import React from "react";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SelectRowDropdown from "@components/Common/SelectRowDropdown";
import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import { DominantColor, CommonMenu } from "@components/Common/";
import CustomCRUDColumn from "@components/Common/DynamicTableWrapper/ActionCRUDColumn";
import LoadingDataAnimation from "@components/Common/LoadingDataAnimation";
import { RootState } from "@store/.";
import {
    isMultiModify,
    resetMultipleEditSlice,
} from "@store/features/multipleEditSlice";
import { getNestedId } from "@helpers/getNestedId";

import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import DuplicateItemModal from "./DuplicateItemModal";
import DeleteItemModal from "./DeleteItemModal";

import "./Style.css";

type itemListProps = {
    setInitialData: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function ItemsList({ setInitialData }: itemListProps) {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const dominantColor = DominantColor();
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = React.useState({
        iuud: "",
        title: "",
    });
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] =
        React.useState(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );

    const handleDuplicate = (e: any): void => {
        setSelectedItem({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.Désignation,
        });

        setIsDuplicateModalOpen(!isDuplicateModalOpen);
    };

    const handleDelete = (e: any): void => {
        setSelectedItem({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.Désignation,
        });
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleEdit = (e: any): void => {
        const item: ItemType[] = [];
        const nestedRowsId = getNestedId((x: any) => x.iuud)(e.selectedRow);
        item.push(e.selectedRow.item);

        dynamicTableWrapperContext?.setRowData({
            iuud: e.selectedRow.iuud,
            data: item,
            nestedRowsId,
        });
        dynamicTableWrapperContext?.setActionColumnDisplay(true);
    };

    const handleSubmit = () => {
        selectedRows.length === 0 &&
            dynamicTableWrapperContext?.setRowData(null);
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed) => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );

    const handelRow = (row: any) => {
        const nestedRowsId = getNestedId((x: any) => x.iuud)(row.original);

        dynamicTableWrapperContext?.setRowData({
            iuud: row.original.iuud,

            data: [row.original.item],

            nestedRowsId,
        });
    };
    const dispatch = useDispatch();
    const customJsxAction = (e: any): JSX.Element => (
        <CustomCRUDColumn
            e={e}
            handleDuplicate={handleDuplicate}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
        />
    );
    const customJsxColor = ({ selectedRow }: any): JSX.Element => (
        <span
            style={{
                borderColor: "#0000",
                borderStyle: "solid",
                borderWidth: "thin",
                backgroundColor: selectedRow.Color,
                height: "30px",
                width: "25px",
                borderRadius: "6px",
            }}
        />
    );
    const arrayOfCustomColumns = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxAction,
        },
        {
            indexOFColumn: 3,
            columnName: "Couleur",
            customJsx: customJsxColor,
        },
    ];

    React.useEffect(() => {
        if (selectedRows.length === 0) {
            dynamicTableWrapperContext?.setRowData(null);
        } else if (selectedRows.length === 1) {
            dynamicTableWrapperContext?.setRowData({
                iuud: selectedRows[0].iuud,
                data: [selectedRows[0].item],
            });
        } else {
            dynamicTableWrapperContext?.setRowData({
                data: selectedRows.map((elm) => ({
                    ...elm.item,
                    iuud: elm.iuud,
                    selectedRows: selectedRows.length,
                })),
            });
            dispatchStore(isMultiModify());
        }

        selectedRows.length <= 1 && dispatchStore(resetMultipleEditSlice());

        // eslint-disable-next-line
    }, [selectedRows, dispatchStore]);

    return isLoading ? (
        <LoadingDataAnimation />
    ) : (
        <div>
            <DynamicTable
                url={`${process.env.REACT_APP_ADB_API_URL}/cards/${schemaAdbFranchise}/${CardIuud}/items`}
                name="Item_table"
                setData={setInitialData}
                canSort
                customSelect
                actionColumn={SelectRowDropdown}
                customJsxSideFilterButton={
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            className="d-flex align-items-center"
                            onSubmit={() => {
                                if (isDirty) {
                                    setUnsavedChangesWarningModalAction(
                                        () => handleSubmit
                                    );
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dispatch(setIsEditing(false));

                                    handleSubmit();
                                }
                            }}
                        >
                            {selectedRows.length > 0 &&
                                !dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                    <div className="mx-1">
                                        <StyledButtonWithIcon
                                            icon="MultiEditIcon"
                                            iconPosition="left"
                                            rounded
                                            outline
                                            variant="light"
                                        >
                                            {selectedRows.length === 1
                                                ? t("Edit item")
                                                : t("Multiple edit")}
                                        </StyledButtonWithIcon>
                                    </div>
                                )}
                            {selectedRows.length === 0 &&
                                !dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                    <StyledButtonWithIcon
                                        icon="PlusIcon"
                                        iconPosition="left"
                                        rounded
                                        variant={dominantColor}
                                        disabled={
                                            cardType === "shop" &&
                                            cardStatus === "En production"
                                        }
                                    >
                                        {t("Add an item")}
                                    </StyledButtonWithIcon>
                                )}

                            {selectedRows.length === 0 &&
                                dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                    <StyledIconButton
                                        style={{
                                            width: "48px",
                                            height: "48px",
                                        }}
                                        icon="PlusIcon"
                                        className="bg-dark"
                                        rounded
                                        disabled={
                                            cardType === "shop" &&
                                            cardStatus === "En production"
                                        }
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </StyledIconButton>
                                )}
                        </AvForm>
                        {cardType === "shop" &&
                        cardStatus === "En production" ? null : (
                            <CommonMenu title="Articles" />
                        )}
                    </div>
                }
                canResize
                canSelect
                setSelectedRows={setSelectedRows}
                showGlobalFilter
                showFilter
                filterActive={dynamicTableWrapperContext?.filterActive}
                setLocalFilterActive={
                    dynamicTableWrapperContext?.setLocalFilterActive
                }
                canMovedCheckboxLeftOnExpand
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                onClick={(row: any) => {
                    if (selectedRows.length <= 1) {
                        if (isDirty) {
                            setIsUnsavedChangesWarningModalOpen(true);
                            setUnsavedChangesWarningModalAction(() => () => {
                                handelRow(row);
                            });
                        } else {
                            dispatch(setIsEditing(false));
                            const nestedRowsId = getNestedId(
                                (x: any) => x.iuud
                            )(row.original);

                            dynamicTableWrapperContext?.setRowData({
                                iuud: row.original.iuud,
                                data: [row.original.item],
                                nestedRowsId,
                            });
                        }
                    }
                }}
            />
            <DeleteItemModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                itemToDelete={selectedItem}
                setIsLoading={setIsLoading}
            />
            <DuplicateItemModal
                isOpen={isDuplicateModalOpen}
                setIsOpen={setIsDuplicateModalOpen}
                itemToDuplicate={selectedItem}
                setIsLoading={setIsLoading}
            />
            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    );
}
