import React from "react";

import cloneDeep from "lodash.clonedeep";

import { updateAdvancedImages } from "@helpers/general";
import ImageUploader from "@components/Common_DB/ImageUploader";

type UploaderPropsType = {
    language: string;
    salesSupport: string;
    advancedImages: sellingSupportType[] | undefined;
    setUpdatedAdvancedImages: React.Dispatch<
        React.SetStateAction<sellingSupportType[] | undefined>
    >;
};

export default function Uploader({
    language,
    salesSupport,
    advancedImages,
    setUpdatedAdvancedImages,
}: UploaderPropsType) {
    const [uploadedImage, setUploadedImage] = React.useState<string | Blob>("");
    const [, setFileType] = React.useState<"image" | "video" | "">("image");

    React.useEffect(() => {
        advancedImages &&
            advancedImages.forEach((image) => {
                if (image.sellingSupport === salesSupport) {
                    image.Langue.forEach((languageImage) => {
                        if (languageImage.designation === language) {
                            setUploadedImage(languageImage.value);
                        }
                    });
                }
            });
    }, [advancedImages, salesSupport, language]);

    React.useEffect(() => {
        setUpdatedAdvancedImages(
            (oldImages): sellingSupportType[] | undefined =>
                updateAdvancedImages(
                    cloneDeep(oldImages),
                    uploadedImage as string,
                    language,
                    salesSupport
                )
        );
    }, [uploadedImage, salesSupport, language, setUpdatedAdvancedImages]);

    return (
        <ImageUploader
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            id={`${language}|${salesSupport}`}
            fileType="image"
            setFileType={setFileType}
        />
    );
}
