import * as React from "react";

import { SVGProps } from "react";

export function OptionIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || "107"}
            height={props.height || "107"}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27812"
                data-name="Groupe 27812"
                transform="translate(18137 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                        rx={15}
                        fill={props.stopColor}
                    />
                    <g id="levels" transform="translate(-9.55 6.001)">
                        <g
                            id="Groupe_21100"
                            data-name="Groupe 21100"
                            transform="translate(15.55)"
                        >
                            <path
                                id="Trac\xE9_18663"
                                data-name="Trac\xE9 18663"
                                d="M30.061,0a2.747,2.747,0,0,0-2.7,2.286H16.013a.463.463,0,0,0,0,.925H27.354A2.744,2.744,0,1,0,30.061,0Zm0,4.569a1.824,1.824,0,1,1,1.824-1.824A1.828,1.828,0,0,1,30.061,4.569Z"
                                transform="translate(-15.55)"
                                fill={props.fill}
                            />
                            <path
                                id="Trac\xE9_18664"
                                data-name="Trac\xE9 18664"
                                d="M32.444,173.486H21.1a2.745,2.745,0,1,0,0,.918H32.44a.459.459,0,1,0,0-.918ZM18.4,175.773a1.824,1.824,0,1,1,1.824-1.824A1.828,1.828,0,0,1,18.4,175.773Z"
                                transform="translate(-15.646 -164.762)"
                                fill={props.fill}
                            />
                            <path
                                id="Trac\xE9_18665"
                                data-name="Trac\xE9 18665"
                                d="M30.061,343.8a2.747,2.747,0,0,0-2.7,2.286H16.013a.462.462,0,1,0,0,.925H27.354a2.744,2.744,0,1,0,2.707-3.211Zm0,4.569a1.824,1.824,0,1,1,1.824-1.824A1.828,1.828,0,0,1,30.061,348.369Z"
                                transform="translate(-15.55 -330.872)"
                                fill={props.fill}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
