/* eslint-disable react/no-unused-prop-types */
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import React from "react";
import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
    DominantColor,
    DynamicTableWrapperContext,
    SuccessToast,
} from "@components/Common";
import "@assets/swal.css";
import "@assets/theme.css";

type CardFooterModalPlaningType = {
    rowData: any;
    startDate: any;
    time: any;
    interval: any;
    hourlyInterval: boolean;
    definedTime: boolean;
    setIsOpenToplan: Function;
    isOpen: boolean;
    endDate: any;
    selectedRowsToPlanification: any[];
    isCardPlanified: boolean;
    setIsCardPlanified: React.Dispatch<React.SetStateAction<boolean>>;
    cancelPlanification: boolean;
};

export default function CardFooterPlaning({
    selectedRowsToPlanification,
    rowData,
    startDate,
    time,
    interval,
    hourlyInterval,
    definedTime,
    setIsOpenToplan,
    isOpen,
    endDate,
    isCardPlanified,
    cancelPlanification,
}: CardFooterModalPlaningType) {
    const [timeDate, setTimeDate] = React.useState<string>("");
    const [, setDateChange] = React.useState<string>("");
    const [isopenPlaningConfirmationModal, setIsopenPlaningConfirmationModal] =
        React.useState<boolean>(false);
    const [openCancelModal, setopenCancelModal] = React.useState(true);

    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const { t } = useTranslation();

    const toggleOpen = (): void => {
        setIsOpenToplan(!isOpen);
    };
    const toggleOpenCancelModal = () => {
        setopenCancelModal(!openCancelModal);
    };
    const cardData: any = [];
    if (selectedRowsToPlanification.length > 0) {
        selectedRowsToPlanification.forEach((element) => {
            const data = { shopid: element.shopid };
            cardData.push(data);
        });
    }

    const PlanCardShop = () => {
        let dataPlanning: any = {
            definedTime: "",
            TimeInterval: [],
            endDate: "",
            startDate: "",
        };

        if (cancelPlanification === false) {
            if (definedTime) {
                dataPlanning.definedTime = definedTime;
            } else {
                const datainterval: any = [];

                interval.forEach((element: any) => {
                    const data = {
                        Start: element.startDate,
                        End: element.endDate,
                    };
                    datainterval.push(data);
                });
                dataPlanning.TimeInterval = datainterval;
            }
            dataPlanning.startDate = startDate;
            if (moment(endDate).format("D/M/YYYY") !== "Invalid date") {
                dataPlanning.endDate = endDate;
            } else {
                dataPlanning.endDate = startDate;
            }
        }
        if (cancelPlanification === true) {
            dataPlanning = "";
        }
        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}plancards/${schemaAdbFranchise}/${rowData.selectedRow.original.iuud}`,
                { dataPlanning, cardData }
            )
            .then((): void => {
                SuccessToast(t("Card is successfully updated"));
                dynamicTableWrapperContext?.setDataUpdated(true) &&
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                toggleOpen();
                toggleOpenCancelModal();
            })
            .catch((error): void => {
                throw new Error(error);
            });
    };

    const handlePlaning = async () => {
        rowData.selectedRow.original.iuudcardreference === "0"
            ? setIsopenPlaningConfirmationModal(() => true)
            : PlanCardShop();
    };

    React.useEffect(() => {
        if (definedTime === true) {
            setTimeDate(
                `${moment(startDate).format("D/M/YYYY")}-${moment(time).format(
                    "HH:mm"
                )}`
            );
            setDateChange(
                ` ${moment(startDate)
                    .subtract(1, "month")
                    .format("YYYY,M,D")},${moment(time).format("HH,mm")}`
            );
        }
        if (hourlyInterval === true) {
            setTimeDate(
                `${moment(startDate).format("D/M/YYYY")}-${moment(
                    interval[0].startDate
                ).format("HH,mm")}`
            );
            setDateChange(
                ` ${
                    (moment(startDate).subtract(1, "month").format("YYYY,M,D"),
                    moment(interval[0].startDate).format("HH,mm"))
                }`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [definedTime, hourlyInterval, interval[0].startDate, time, startDate]);

    return (
        <ModalFooter className="border-top-0">
            {/*! if carte master avec ses cartes boutique */}
            {!cancelPlanification &&
                rowData.selectedRow.original.iuudcardreference === "0" &&
                isopenPlaningConfirmationModal && (
                    <Modal centered isOpen={isOpen}>
                        <ModalHeader toggle={toggleOpen}>
                            <StyledH2 className="text-uppercase">
                                PLANIFICATION
                            </StyledH2>
                        </ModalHeader>
                        <ModalBody>
                            {isCardPlanified ? (
                                <>
                                    <div>Attention !</div>
                                    <div>
                                        Une ou plusieurs cartes boutiques sont
                                        déjà planifiées. Voulez-vous modifier
                                        leurs planification pour {timeDate}"
                                    </div>
                                </>
                            ) : (
                                <>
                                    {t(
                                        "Voulez-vous confirmer la planification des cartes des boutiques sélectionnées pour "
                                    )}
                                    {timeDate}
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter className="border-top-0">
                            <StyledButton
                                onClick={toggleOpen}
                                rounded
                                variant="light"
                                outline
                            >
                                {t("Cancel")}
                            </StyledButton>
                            <StyledButton
                                onClick={PlanCardShop}
                                rounded
                                variant={DominantColor}
                            >
                                {t("Validate")}
                            </StyledButton>
                        </ModalFooter>
                    </Modal>
                )}
            {cancelPlanification ? (
                <Modal centered isOpen={openCancelModal}>
                    <ModalHeader toggle={toggleOpenCancelModal}>
                        <StyledH2 className="text-uppercase">
                            annulation PLANIFICATION
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        <>
                            <div>Attention !</div>
                            <div>
                                vous Voulez supprimer la planification des
                                cartes selectionnees
                            </div>
                        </>
                    </ModalBody>

                    <ModalFooter className="border-top-0">
                        <StyledButton
                            onClick={toggleOpenCancelModal}
                            rounded
                            variant="light"
                            outline
                        >
                            {t("Cancel")}
                        </StyledButton>
                        <StyledButton
                            onClick={PlanCardShop}
                            rounded
                            variant={DominantColor}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </Modal>
            ) : null}

            <StyledButton
                outline
                rounded
                variant="light"
                className="mr-2"
                onClick={() => {
                    setIsOpenToplan(!isOpen);
                }}
            >
                {t("Cancel")}
            </StyledButton>
            <StyledButton rounded variant="primary" onClick={handlePlaning}>
                {t("Validate")}
            </StyledButton>
        </ModalFooter>
    );
}
