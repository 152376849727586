import React, { CSSProperties, useState } from "react";

import { Table } from "antd";
import Select, { GroupBase } from "react-select";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import PlanningModal from "@components/Common_DB/AdvancePrice/PlageHoraire/PlanningModal";
import Calander from "@components/Common/SvgIcons/Calander";
import { handlePrixChange } from "@components/Common_DB/AdvancePrice/MultiEditPriceHelpers";
import { VatRateContext } from "@pages/Catalogue/Items/addItem/Price";
import MultipleEditPrice from "@components/Common_DB/AdvancePrice/MultipleEditPrice/index";
import {
    fillAdvancedPriceOptions,
    getGroupedOptions,
} from "@helpers/advancedPrice";
import useUpdateAdvancedPriceStructure from "@hooks/useUpdateAdvancedPriceStructure";
import PriceHTInput from "./PriceHTInput";
import PriceTTCInput from "./PriceTTCInput";

import "antd/dist/antd.css";
import "./PriceTable.css";

const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: 14,
};
const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};

const formatGroupLabel = (
    group: GroupBase<{
        label: string;
        value: string;
        options: { label: string; value: string }[];
    }>
) => (
    <div style={groupStyles}>
        <span>{group.label}</span>
        <span style={groupBadgeStyles}>{group.options.length}</span>
    </div>
);

const PeriodeType: any = [
    {
        id: "1",
        name: "",
        date: [],
        active: true,
        Day: [
            {
                name: "The whole week",
                id: "1",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],

                visibility: false,
            },
            {
                name: "Monday",
                id: "2",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Tuesday",
                id: "3",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Wednesday",
                id: "4",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Thursday",
                id: "5",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Friday",
                id: "6",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Saturday",
                id: "7",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
            {
                name: "Sunday",
                id: "8",
                Hour: [{ HeureDebut: "", HeureFin: "", Price: "" }],
                visibility: false,
            },
        ],
    },
];

type PriceModalType = {
    advancedPrice?: any;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    niveau: string | number;
    designation: string;
    PrixHT?: number | "" | string;
    TVA?: number | "";
    PrixTTC?: number | "" | string;
    period?: any;
    children?: any;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export default function PriceTable({ advancedPrice }: PriceModalType) {
    const { t } = useTranslation();
    const vatRateContext = React.useContext(VatRateContext);

    const [dataSource, setDataSource] = useState<any>(
        JSON.parse(JSON.stringify(advancedPrice))
    );

    const [filteredSaleModes, setFilteredSaleModes] = React.useState<string[]>(
        []
    );
    const [filteredOptions, setFilteredOptions] = React.useState<string[]>([]);
    const [filteredSaleSupports, setFilteredSaleSupports] = React.useState<
        string[]
    >([]);

    const [selectedrow, setselectedrow] = useState<any>([]);

    const [active, setActive] = React.useState(false);

    const [period, setPeriod] = React.useState(
        JSON.parse(JSON.stringify(PeriodeType))
    );

    const defaultColumns: (ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
    })[] = [
        {
            title: t("Designation"),
            dataIndex: "designation",
            width: "20%",
            editable: false,
        },

        {
            title: t("price incl VAT"),
            dataIndex: "PrixTTC",
            render: (_, record: any, index) => (
                <PriceTTCInput record={record} index={index} />
            ),
        },
        {
            title: t("VAT"),
            dataIndex: "TVA",
            editable: false,
        },
        {
            title: t("Price excl VAT"),
            dataIndex: "PrixHT",
            render: (_, record: any, index) => (
                <PriceHTInput record={record} index={index} />
            ),
        },
        {
            title: t("Action"),
            dataIndex: "operation",
            render: (_: any, record: any): any => {
                return record.children ? null : (
                    <>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setselectedrow(record);

                                setActive(true);
                            }}
                            style={{
                                justifyContent: "center",
                                display: "flex",
                                pointerEvents: "none",
                                opacity: 0.4,
                            }}
                        >
                            <Calander />
                        </div>
                        <PlanningModal
                            handleSave={handleSave}
                            setselectedrow={setselectedrow}
                            record={selectedrow}
                            setActive={setActive}
                            active={active}
                            setPeriod={setPeriod}
                            period={period}
                        />
                    </>
                );
            },
        },
    ];
    const handleSave = (row: DataType) => {
        const newData: any = JSON.parse(JSON.stringify(dataSource));
        const updatedTable = handlePrixChange([row], newData);

        setDataSource(updatedTable);
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const optionCategorie: categoryLiaisonType[] = [
        { label: "dfgda", value: "a" },
        { label: "a", value: "b" },
    ];

    const tableMap = React.useCallback(
        (saleModeOption: SaleModeType[]) => {
            if (Object.keys(vatRateContext.itemOptions).length > 0) {
                return saleModeOption
                    .filter(
                        (saleMode) =>
                            filteredSaleModes.length === 0 ||
                            filteredSaleModes.includes(saleMode.id)
                    )
                    .map((saleMode) => {
                        const saleModeVatRate =
                            vatRateContext.itemPrice.saleModeVatRates.find(
                                (saleModeVatRate) =>
                                    saleModeVatRate.saleModeUuid === saleMode.id
                            );
                        return {
                            designation: saleMode.designation,
                            uuid: saleMode.id,
                            key: saleMode.id,
                            type: "saleMode",
                            PrixHT: "",
                            TVA: "",
                            PrixTTC: "",
                            period: "",

                            children: saleMode.saleSupports
                                .filter(
                                    (saleSupport) =>
                                        filteredSaleSupports.includes(
                                            saleSupport
                                        ) || filteredSaleSupports.length === 0
                                )
                                .map((saleSupport: string) => ({
                                    designation: saleSupport,
                                    key: `${saleMode.id}|${saleSupport}`,
                                    type: "saleSupport",
                                    PrixHT: "",
                                    TVA: "",
                                    PrixTTC: "",
                                    period: "",

                                    children: fillAdvancedPriceOptions(
                                        Object.keys(vatRateContext.itemOptions),
                                        vatRateContext.allOptions,
                                        vatRateContext.itemOptions,
                                        saleModeVatRate?.value ?? 0,
                                        filteredOptions,
                                        `${saleMode.id}|${saleSupport}`
                                    ),
                                })),
                        };
                    });
            }
            return saleModeOption
                .filter(
                    (saleMode) =>
                        filteredSaleModes.length === 0 ||
                        filteredSaleModes.includes(saleMode.id)
                )
                .map((saleMode) => {
                    const saleModeVatRate =
                        vatRateContext.itemPrice.saleModeVatRates.find(
                            (vatRate) => vatRate.saleModeUuid === saleMode.id
                        );
                    return {
                        designation: saleMode.designation,
                        uuid: saleMode.id,
                        key: saleMode.id,
                        PrixHT: "",
                        type: "saleMode",
                        TVA: "",
                        PrixTTC: "",
                        period: "",

                        children: saleMode.saleSupports
                            .filter(
                                (saleSupport) =>
                                    filteredSaleSupports.includes(
                                        saleSupport
                                    ) || filteredSaleSupports.length === 0
                            )
                            .map((saleSupport) => ({
                                designation: saleSupport,
                                key: `${saleMode.id}|${saleSupport}`,
                                PrixHT: 0,
                                type: "saleSupport",
                                TVA: saleModeVatRate?.value ?? 0,
                                PrixTTC: 0,
                                period: "",
                            })),
                    };
                });
        },
        [
            filteredOptions,
            filteredSaleModes,
            filteredSaleSupports,
            vatRateContext.allOptions,
            vatRateContext.itemOptions,
            vatRateContext.itemPrice.saleModeVatRates,
        ]
    );

    useUpdateAdvancedPriceStructure();

    React.useEffect(() => {
        setDataSource(tableMap(vatRateContext.salesModes));
    }, [tableMap, vatRateContext.salesModes]);

    const rowSelection: any = {
        // eslint-disable-next-line no-unused-vars
        onChange: (selectedRowKeys: any, selectedRows: any) => {},
        onSelect: (record: any, selected: any, selectedRows: any) => {
            setselectedrow(selectedRows);
        },
        // eslint-disable-next-line no-unused-vars
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            setselectedrow(selectedRows);
        },
    };

    return (
        <div>
            <div style={{ opacity: "1" }}>
                <div className="categorie__filtre   ">
                    <div className="categorie__options">
                        <StyledLabel htmlFor="Categories">
                            {t("Categories")}
                        </StyledLabel>

                        <Select
                            options={optionCategorie}
                            isMulti
                            placeholder={`${t("Select")}...`}
                            isDisabled
                        />
                    </div>
                    <MultipleEditPrice
                        selectedRows={selectedrow}
                        filteredOptions={filteredOptions}
                        filteredSaleModes={filteredSaleModes}
                        filteredSaleSupports={filteredSaleSupports}
                    />
                </div>
                <div className="  d-flex  align-items-center  filtre__box">
                    <p>{t("Filter")}</p>
                    <div className="filtre ">
                        <StyledLabel htmlFor="Supports de vente">
                            {t("Sale mode")}
                        </StyledLabel>

                        <Select
                            placeholder={`${t("Select")}...`}
                            options={vatRateContext.salesModes.map(
                                (saleMode) => {
                                    return {
                                        value: saleMode.id,
                                        label: saleMode.designation,
                                    };
                                }
                            )}
                            onChange={(saleModes) => {
                                setFilteredSaleModes(
                                    saleModes.map((saleMode) => saleMode.value)
                                );
                            }}
                            isMulti
                        />
                    </div>

                    <div className="filtre ">
                        <StyledLabel htmlFor="mode de vente de vente">
                            {t("sale support")}
                        </StyledLabel>

                        <Select
                            placeholder={`${t("Select")}...`}
                            options={vatRateContext.salesModes
                                .map((saleMode: any) => saleMode.saleSupports)
                                .flat(Infinity)
                                .reduce(
                                    (
                                        saleSupports: string[],
                                        saleSupport: string
                                    ): string[] => {
                                        if (
                                            !saleSupports.includes(saleSupport)
                                        ) {
                                            saleSupports.push(saleSupport);
                                        }
                                        return saleSupports;
                                    },
                                    []
                                )
                                .map((saleSupport: string) => {
                                    return {
                                        label: saleSupport,
                                        value: saleSupport,
                                    };
                                })}
                            onChange={(saleSupports) => {
                                setFilteredSaleSupports(
                                    saleSupports.map(
                                        (saleSupport: any) => saleSupport.value
                                    )
                                );
                            }}
                            isMulti
                        />
                    </div>

                    {Object.keys(vatRateContext.itemOptions).length > 0 ? (
                        <div className="filtre ">
                            <StyledLabel>{t("Options")}</StyledLabel>

                            <Select
                                options={getGroupedOptions(
                                    vatRateContext.itemOptions,
                                    vatRateContext.allOptions
                                )}
                                isMulti
                                onChange={(options) => {
                                    setFilteredOptions(
                                        options.map((option) => option.value)
                                    );
                                }}
                                placeholder={`${t("Select")}...`}
                                formatGroupLabel={formatGroupLabel}
                            />
                        </div>
                    ) : null}
                </div>
                <Table
                    dataSource={dataSource}
                    columns={columns as ColumnTypes}
                    rowSelection={{ ...rowSelection, checkStrictly: false }}
                    scroll={{ y: 450 }}
                />
            </div>
        </div>
    );
}
