export function UpdateFile(File: any, Listtitle: any): any {
    let selected: number = 0;
    let created: number = 0;
    let Update: number = 0;
    let ind: number = 0;

    File.rows.forEach((element: any, index: number) => {
        if (
            element.indexOf("To add") === -1 &&
            element.indexOf("Edit") === -1
        ) {
            if (element[element.length - 1] === true) {
                selected += 1;
                if (Listtitle[ind]?.value) {
                    File.rows[index].push("Edit");
                    File.rows[index].push(Listtitle[ind].iuud);
                    Update += 1;
                } else {
                    File.rows[index].push("To add");
                    File.rows[index].push("iuud");
                    created += 1;
                }

                ind += 1;
            } else {
                File.rows[index].push("To add");
                File.rows[index].push("iuud");
            }
        } else if (element[element.length - 3] === true) {
            selected += 1;
            if (Listtitle[ind].value) {
                Update += 1;
            } else {
                created += 1;
            }
            ind += 1;
        }
    });
    return { selected, created, Update };
}

export function SelectedFile(File: any): any {
    let selected: number = 0;
    let created: number = 0;
    File.rows.forEach((element: any, index: number) => {
        if (
            element.indexOf("To add") === -1 &&
            element.indexOf("Edit") === -1
        ) {
            if (element[element.length - 1] === true) {
                selected += 1;
                created += 1;
            }
            File.rows[index].push("To add");
        }
    });
    return { selected, created };
}

export function TitleIndex(File: any): any {
    let TitleIndex: number = -1;
    File.cols.forEach((element: any, index: number) => {
        if (element.name === "Désignation") {
            TitleIndex = index;
        }
    });

    const data: any[] = [];
    if (TitleIndex > -1) {
        File.rows.forEach((element: any) => {
            if (element[element.length - 1] === true) {
                data.push(element[TitleIndex]);
            }
        });
    }

    return data;
}
