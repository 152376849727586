import { DocumentIcon, TrashIcon, FolderIcon } from "@aureskonnect/react-ui";
import React from "react";
import { copyDateTable } from "@store/features";
import { RootState } from "@store/index";
import { useDispatch, useSelector } from "react-redux";

export type ModifyDateType = {
    setPeriod: React.Dispatch<any>;
    period: any;
    ValueRender: string;
    indexPeriod: number;
    indexDate: number;
    setRender: React.Dispatch<React.SetStateAction<string>>;
};

export default function ModifyDate({
    setPeriod,
    period,
    ValueRender,
    indexPeriod,
    indexDate,
    setRender,
}: ModifyDateType) {
    const dispatch = useDispatch();
    let ListItemsDate: any = useSelector(
        (State: RootState) => State.copyDateSlice.value
    );

    function CopyPasteDelete(action: string) {
        switch (action) {
            case "Copy":
                ListItemsDate = period[indexPeriod].date[indexDate];
                dispatch(copyDateTable(ListItemsDate));

                return ListItemsDate;
            case "Paste":
                if (
                    ListItemsDate.DateDebut.length !== 0 ||
                    ListItemsDate.DateFin.length !== 0
                ) {
                    setPeriod((prevState: any) => {
                        const newState = [...prevState];

                        const selectedDate: any = {};
                        selectedDate.DateDebut = ListItemsDate.DateDebut;
                        selectedDate.DateFin = ListItemsDate.DateFin;

                        const newArr = [...newState[indexPeriod].date];
                        newArr[indexDate] = selectedDate;

                        newState[indexPeriod] = {
                            ...newState[indexPeriod],
                            date: newArr,
                        };

                        return newState;
                    });
                }
                return period;
            case "Delete":
                setPeriod((prevState: any) => {
                    const newState = [...prevState];

                    const newArr = [...newState[indexPeriod].date];
                    newArr.splice(indexPeriod, 1);

                    newState[indexPeriod] = {
                        ...newState[indexPeriod],
                        date: newArr,
                    };

                    return newState;
                });
                return period;
            default:
                return period;
        }
    }

    return (
        <div className="d-flex justify-content-end">
            <div>
                <FolderIcon
                    height={20}
                    width={20}
                    onClick={() => {
                        CopyPasteDelete("Paste");
                        // CopyPasteDate(indice, index, "Paste");

                        setRender(`${ValueRender} Paste Date`);
                    }}
                />
            </div>
            <div>
                <DocumentIcon
                    height={20}
                    width={20}
                    onClick={() => {
                        // CopyPasteDate(indice, index, "Copy");
                        CopyPasteDelete("Copy");
                    }}
                />
            </div>
            <div>
                <TrashIcon
                    height={20}
                    width={20}
                    onClick={() => {
                        // handleDeleteDate(indice, index);
                        CopyPasteDelete("Delete");
                        setRender(`${ValueRender} deleteDate`);
                    }}
                />
            </div>
        </div>
    );
}
