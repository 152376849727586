/* eslint-disable no-nested-ternary */
import React from "react";

import shortid from "shortid";
import { LockIcon, PlusIcon, StyledIconButton } from "@aureskonnect/react-ui";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/.";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import { ArrowUpIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowUpIcons";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import NumberOfChoices from "@components/Common/SvgIcons/NumberOfChoices";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";

import { setWorkflow } from "@store/features/workflowSlice";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";

import WorkFlowCardV2 from "./WorkFlowCardV2";

import { EditStep } from "./hooks/global/step/EditStep";
import { VisibilityStep } from "./hooks/global/step/VisibilityStep";
import { DropdownStep } from "./hooks/global/step/DropdownStep";
import { DeleteStep } from "./hooks/global/step/DeleteStep";
import { AddStep } from "./hooks/global/step/AddStep";
import { AddMultipleStepInStep } from "./hooks/global/step/AddMultipleStepInStep";

import { SearchData } from "./SearchData";
import {
    StepSalesExist,
    OrderProductWorkflowByRank,
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
    GlobalVisibilityMultipleInProduct,
} from "./helpers";

import {
    formElementBasicComposition,
    InsertOverrideBasicCompositionInItem,
    GetlistUuidBasicComposition,
    basicCompositionFormWorkflow,
    GlobalVisibilityBasicComposition,
} from "./hooks/helpersBasicComposition";
import {
    optionFormWorkflow,
    GlobalVisibilityOption,
} from "./hooks/helpersOption";

import { SalesAddMultipleInProduct } from "./hooks/global/step/SalesAddMultipleInProduct";
import { AddMultipleStepInProduct } from "./hooks/global/step/AddMultipleStepInProduct";
import CrushedItemModal from "./hooks/global/step/CrushedItemModal";
import CheckUnsaveDataMultiple from "./hooks/global/step/CheckUnsaveDataMultiple";
import { VisibilityStepProduct } from "./hooks/global/step/VisibilityStepProduct";

import "./style.css";

export type WorkFlowType = {
    globalIndex: number;
    title: string;
    data: WorkFlowDataType;
    type: any;
    uuid: string;
    setTypeElement: React.Dispatch<React.SetStateAction<string>>;
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    setContentModal: React.Dispatch<any>;
    cardData: any;
    // isDragging: boolean;
    isClickStep: boolean;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    isMultipleStep: boolean;
    maxNbrClick: number;
    isRequired: boolean;
    checkstepItem: boolean;
};
export default function WorkFlowStep({
    title,
    data,
    type,
    uuid,
    globalIndex,
    setTypeElement,
    setIsOpenStepModal,
    setContentModal,
    cardData,
    // isDragging,
    isClickStep,
    setIsClickStep,
    setIndexSelected,
    isMultipleStep,
    maxNbrClick,
    isRequired,
    checkstepItem,
}: WorkFlowType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const [isDropdownMenuActive, setIsDropdownMenuActive] =
        React.useState(false);
    const [isDragging, setIsDragging] = React.useState(false);
    const [isCrushed, setIsCrushed] = React.useState(false);
    const [isUnsavedDataMultiple, setIsUnsavedDataMultiple] =
        React.useState(false);
    const [action, setAction] = React.useState("");

    const [searchedStepDesignation, setSearchedStepDesignation] =
        React.useState("");
    const [myData, setMyData] = React.useState(data);

    const [myDataToArray, setMyDataToArray] = React.useState<any>([]);

    const [isGolbalOptionActive, setIsGolbalOptionActive] =
        React.useState(false);
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );
    const globalWorkflowData: any = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );

    const ListeUuidStep: string[] = globalWorkflow
        ?.filter((item: any) => item.type === "steps" && item.uuidStep !== "")
        ?.map((element: any) => element.uuidStep);

    const startIndex = globalWorkflow[globalIndex!]?.previousUuids!.indexOf(
        globalWorkflow[globalIndex!]?.uuid
    );

    const IsBasicCompositionStep =
        globalWorkflow[globalIndex!]?.uuidStep ===
        WORKFLOW_LIST.BASICCOMPOSITION;

    const basicCompositionIsVisible = GlobalVisibilityBasicComposition(
        globalWorkflow,
        startIndex,
        globalIndex,
        cardData
    );

    const IsOptionStep =
        globalWorkflow[globalIndex!]?.type === WORKFLOW_LIST.OPTIONS;

    const optionIsVisible = GlobalVisibilityOption(
        globalWorkflow,
        startIndex,
        globalWorkflow[globalIndex!]?.uuid,
        globalWorkflow[globalIndex]?.uuidStep
    );

    const isVisibleMultiple = GlobalVisibilityMultipleInProduct(
        globalWorkflow,
        globalIndex,
        cardData
    );

    const checkEmptyStep = globalWorkflow.findIndex(
        (el: any) =>
            el.type === "steps" && Object.keys?.(el.data || {}).length > 0
    );
    const hiddenOption = () => {
        setIsGolbalOptionActive(!isGolbalOptionActive);
    };

    const handleChangeRankBasicComposition = (
        copyWorkflow: any,
        source: any,
        destination: any
    ) => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;
        const currentItem: any =
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override || {}
            ).length > 0
                ? JSON.parse(
                      JSON.stringify(
                          globalWorkflow[startIndex].data[globalUuid].override[
                              globalUuid
                          ]
                      )
                  )
                : JSON.parse(JSON.stringify(cardData?.items?.[globalUuid]));

        let copyOverrideBasicComposition: WorkflowOptionType = {};
        // eslint-disable-next-line no-unused-expressions
        Object.keys(
            globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                ?.overrideBasicComposition || {}
        ).length > 0
            ? (copyOverrideBasicComposition =
                  globalWorkflow[startIndex].data[globalUuid].override[
                      globalUuid
                  ]?.overrideBasicComposition)
            : (copyOverrideBasicComposition =
                  InsertOverrideBasicCompositionInItem(
                      copyOverrideBasicComposition,
                      cardData,
                      globalUuid
                  ));

        const arrayOptionsRank = GetlistUuidBasicComposition(
            globalWorkflow,
            startIndex,
            globalUuid,
            cardData
        );

        /* update rank */
        const copyOptionsRank = [...arrayOptionsRank];
        /* source Item Logic */
        const sourceItem = copyOptionsRank[source.index];
        copyOptionsRank.splice(source.index, 1);
        /* Destination Item Logic */
        copyOptionsRank.splice(destination.index, 0, sourceItem);

        const updatedWorkflowStepElement = {
            ...currentItem,
            overrideBasicComposition: {
                ...copyOverrideBasicComposition,
                [WORKFLOW_LIST.BASICCOMPOSITION]: {
                    ...copyOverrideBasicComposition[
                        WORKFLOW_LIST.BASICCOMPOSITION
                    ],
                    ranks: copyOptionsRank,
                },
            },
        };

        const overrideWorkflow: any = overrideWorkflowDataElementByUuid(
            copyWorkflow,
            updatedWorkflowStepElement,
            globalUuid,
            startIndex
        );
        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        overrideWorkflow[globalIndex].data = Newlist;

        dispatch(setWorkflow(overrideWorkflow));
        const { previousUuids } = globalWorkflow[startIndex];

        dispatch(
            setWorkflowData(
                overrideWorkflowDataElementWithPath(
                    globalWorkflowData,
                    previousUuids,
                    updatedWorkflowStepElement,
                    globalUuid
                )
            )
        );
    };
    const handleChangeRankOption = (
        copyWorkflow: any,
        source: any,
        destination: any
    ) => {
        const globalUuid = globalWorkflow[globalIndex!]?.uuid;
        const UuidOptions = globalWorkflow[globalIndex!]?.uuidStep;
        // const typeRank=globalWorkflow[globalIndex!]?.uuidStep===WORKFLOW_LIST.BASICCOMPOSITION ?
        const currentItem: any =
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override || {}
            ).length > 0
                ? JSON.parse(
                      JSON.stringify(
                          globalWorkflow[startIndex].data[globalUuid].override[
                              globalUuid
                          ]
                      )
                  )
                : JSON.parse(JSON.stringify(cardData?.items?.[globalUuid]));

        const arrayOptionsRank =
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override || {}
            ).length > 0 &&
            Object.keys(
                globalWorkflow[startIndex].data[globalUuid].override[globalUuid]
                    ?.options || {}
            ).length > 0 &&
            globalWorkflow[startIndex].data[globalUuid].override[
                globalUuid
                // eslint-disable-next-line no-prototype-builtins
            ]?.options.hasOwnProperty(UuidOptions)
                ? globalWorkflow[startIndex].data[globalUuid].override[
                      globalUuid
                  ]?.options[UuidOptions]
                : cardData?.items?.[globalUuid]?.options[UuidOptions];

        /* update rank */
        const copyOptionsRank = [...arrayOptionsRank];
        /* source Item Logic */
        const sourceItem = copyOptionsRank[source.index];
        copyOptionsRank.splice(source.index, 1);
        /* Destination Item Logic */
        copyOptionsRank.splice(destination.index, 0, sourceItem);

        const updatedWorkflowStepElement = {
            ...currentItem,
            options: {
                ...currentItem.options,
                [UuidOptions]: copyOptionsRank,
            },
        };
        const overrideWorkflow: any = overrideWorkflowDataElementByUuid(
            copyWorkflow,
            updatedWorkflowStepElement,
            globalUuid,
            startIndex
        );
        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        overrideWorkflow[globalIndex].data = Newlist;

        dispatch(setWorkflow(overrideWorkflow));
        const { previousUuids } = globalWorkflow[startIndex];

        dispatch(
            setWorkflowData(
                overrideWorkflowDataElementWithPath(
                    globalWorkflowData,
                    previousUuids,
                    updatedWorkflowStepElement,
                    globalUuid
                )
            )
        );
    };
    const handleChangeRankSteps = (
        copyModifier: any,
        copyWorkflow: any,
        UuidModifier: string,
        UuidStep: string,
        source: any,
        destination: any
    ) => {
        const stepForm =
            Object.keys?.(
                copyModifier[UuidModifier].steps[UuidStep]?.overrides || {}
            ).length > 0
                ? JSON.parse(
                      JSON.stringify(
                          copyModifier[UuidModifier].steps[UuidStep]?.overrides
                      )
                  )
                : JSON.parse(JSON.stringify(cardData.steps[UuidStep]));

        /* update rank */
        const arrayToUpdateItemStep = Object.entries(stepForm.stepItems)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .reduce((acc: any, [key, value]: any) => {
                const obj: any = { key, value };
                acc.push(obj);
                return acc;
            }, []);
        const NewlistItemStep = OrderProductWorkflowByRank(
            arrayToUpdateItemStep,
            source.index,
            destination.index
        );
        /* update rank in override item in step */
        stepForm.stepItems = NewlistItemStep;

        /* update rank item instep in modifier */
        copyModifier[UuidModifier] = {
            ...copyModifier[UuidModifier],
            steps: {
                ...copyModifier[UuidModifier].steps,
                [UuidStep]: {
                    ...copyModifier[UuidModifier].steps[UuidStep],
                    overrides: stepForm,
                },
            },
        };

        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        copyWorkflow[globalIndex].data = Newlist;
        copyWorkflow[globalIndex].override = stepForm;

        dispatch(setWorkflow(copyWorkflow));
        if (multipleWorkflow.length > 0) {
            const copymultipleStep = JSON.parse(
                JSON.stringify(multipleWorkflow)
            );
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: copyModifier,
            };
            dispatch(setMultipleWorkflow(copymultipleStep));
        } else {
            dispatch(setModifier(copyModifier));
        }
    };
    const onDragEndCategory = (result: DropResult) => {
        const { destination, source } = result;
        setIsDragging(false);
        if (!destination) {
            return;
        }
        if (!source) {
            return;
        }

        if (cardType === "shop" && cardStatus === "En production") {
            return;
        }

        const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));

        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        copyWorkflow[globalIndex].data = Newlist;

        /* update workflow data rank */
        const copyGlobalWorkflowData = JSON.parse(
            JSON.stringify(globalWorkflowData)
        );
        let pathIuud: string = "copyGlobalWorkflowData?.";
        const ListOfUuid = globalWorkflow[globalIndex!].previousUuids;
        if (ListOfUuid.length > 0) {
            ListOfUuid?.forEach((element: string) => {
                pathIuud += `["${element}"]?.content`;
            });

            pathIuud = pathIuud.substring(0, pathIuud.lastIndexOf("?"));
            // eslint-disable-next-line no-eval
            const stringEvaluate = eval(pathIuud);
            stringEvaluate.content = Newlist;
            dispatch(setWorkflowData(copyGlobalWorkflowData));
        } else {
            dispatch(setWorkflowData(Newlist));
        }

        dispatch(setWorkflow(copyWorkflow));
    };
    const onDragEndSteps = (result: DropResult) => {
        const { destination, source } = result;
        setIsDragging(false);
        if (!destination) {
            return;
        }
        if (!source) {
            return;
        }

        if (cardType === "shop" && cardStatus === "En production") {
            return;
        }
        const UuidModifier = globalWorkflow[globalIndex].previousModifier;
        const UuidStep = globalWorkflow[globalIndex].uuidStep;

        const copyModifier =
            multipleWorkflow.length > 0
                ? JSON.parse(
                      JSON.stringify(
                          multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier
                      )
                  )
                : JSON.parse(JSON.stringify(modifierWorkflow));

        const copyWorkflow = JSON.parse(JSON.stringify(globalWorkflow));
        if (IsBasicCompositionStep) {
            handleChangeRankBasicComposition(copyWorkflow, source, destination);
        } else {
            IsOptionStep
                ? handleChangeRankOption(copyWorkflow, source, destination)
                : handleChangeRankSteps(
                      copyModifier,
                      copyWorkflow,
                      UuidModifier,
                      UuidStep,
                      source,
                      destination
                  );
        }
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if (
            globalWorkflow[globalIndex!]?.selected !== undefined &&
            globalWorkflow[globalIndex!]?.selected !== ""
        ) {
            setTimeout(() => {
                const divStepItem = document.getElementById(
                    `${
                        globalWorkflow[globalIndex!]?.selected
                    }${globalIndex.toString()}`
                );

                divStepItem &&
                    divStepItem?.scrollIntoView({
                        block: "start",
                    });
            }, 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setMyDataToArray(
            Object.entries(myData)
                .sort((a: any, b: any) =>
                    // eslint-disable-next-line no-nested-ternary
                    a[1]
                        ? // eslint-disable-next-line no-nested-ternary
                          a[1].rank
                            ? b[1].rank
                                ? a[1].rank - b[1].rank
                                : -1
                            : 1
                        : 1
                )
                .reduce((acc: any, [key, value]: any) => {
                    const obj: any = { key, value };
                    acc.push(obj);
                    return acc;
                }, [])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myData]);
    return (
        <div id={`${globalIndex!}`}>
            {type === WORKFLOW_LIST.STEPS || type === WORKFLOW_LIST.OPTIONS ? (
                <div className="d-flex mx-2 ">
                    <div
                        style={{
                            minHeight: "calc(100vh - 408px)",
                            width: "420px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            borderRadius: "0px 0px 5px 5px",
                            boxShadow: "0px 3px 6px #00000029",
                            borderBottom: "1px solid #E4E4E4",
                        }}
                    >
                        <div
                            style={{
                                // minWidth: "max-content",
                                minWidth: "420px",
                                display: "grid",
                                gridTemplateRows: "100px 0px auto",
                                gridRowGap: "6px",
                            }}
                        >
                            <div
                                style={{
                                    font: "normal normal normal 18px/24px Segoe UI",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    width: "100%",
                                    boxShadow: "0px 1px 2px #0000001C",
                                    border: "1px solid #E4E4E4",
                                }}
                            >
                                <div>
                                    <div
                                        style={{ height: "40px" }}
                                        className=" d-flex align-items-center d-flex justify-content-center "
                                    >
                                        <span className="mr-3 mt-2">
                                            {globalWorkflow[globalIndex]?.title}
                                            :
                                        </span>
                                        <DropdownStep
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            isDropdownMenuActive={
                                                isDropdownMenuActive
                                            }
                                            setIsDropdownMenuActive={
                                                setIsDropdownMenuActive
                                            }
                                            searchedStepDesignation={
                                                searchedStepDesignation
                                            }
                                            cardData={cardData}
                                            globalIndex={globalIndex}
                                            title={title}
                                            type={type}
                                            ListeUuidStep={ListeUuidStep}
                                            setSearchedStepDesignation={
                                                setSearchedStepDesignation
                                            }
                                            setIsClickStep={setIsClickStep}
                                            isClickStep={isClickStep}
                                            uuid={uuid}
                                            setIndexSelected={setIndexSelected}
                                        />

                                        <div className="ml-2">
                                            <StyledIconButton
                                                icon="PlusIcon"
                                                style={{
                                                    border: "1px solid #C6C6C6",
                                                    borderRadius: "6px",
                                                    visibility: "hidden",
                                                }}
                                            >
                                                <PlusIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            justifyContent: "end",
                                            paddingRight: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        className="mt-2"
                                    >
                                        <VisibilityStep
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            optionIsVisible={optionIsVisible}
                                            basicCompositionIsVisible={
                                                basicCompositionIsVisible
                                            }
                                            setIndexSelected={setIndexSelected}
                                            globalIndex={globalIndex}
                                            setIsClickStep={setIsClickStep}
                                            isClickStep={isClickStep}
                                            startIndex={startIndex}
                                            uuid={uuid}
                                            cardData={cardData}
                                        />
                                        <DeleteStep
                                            isMultipleStep={isMultipleStep}
                                            globalIndex={globalIndex}
                                            setIndexSelected={setIndexSelected}
                                            setIsClickStep={setIsClickStep}
                                            isClickStep={isClickStep}
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            startIndex={startIndex}
                                        />
                                        <AddMultipleStepInStep
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            isMultipleStep={isMultipleStep}
                                            globalIndex={globalIndex}
                                            cardData={cardData}
                                            setContentModal={setContentModal}
                                            setIsOpenStepModal={
                                                setIsOpenStepModal
                                            }
                                            uuid={uuid}
                                            setIsCrushed={setIsCrushed}
                                            setAction={setAction}
                                        />
                                        <EditStep
                                            isMultipleStep={isMultipleStep}
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            data={data}
                                            globalIndex={globalIndex}
                                            uuid={uuid}
                                            setTypeElement={setTypeElement}
                                            cardData={cardData}
                                            setIsClickStep={setIsClickStep}
                                            isClickStep={isClickStep}
                                            setIndexSelected={setIndexSelected}
                                        />
                                        <div
                                            className={
                                                isMultipleStep
                                                    ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                                                    : "icon_step__CLZ text-center mr-2"
                                            }
                                            style={{
                                                display:
                                                    IsBasicCompositionStep ||
                                                    IsOptionStep
                                                        ? "none"
                                                        : " d-flex justify-content-center align-items-center",
                                            }}
                                        >
                                            <LockIconWithToolTip
                                                type="icon"
                                                lengthMax={0}
                                                message={
                                                    isRequired
                                                        ? t("Required step")
                                                        : t("Step not required")
                                                }
                                                id={`cad2${globalIndex}`}
                                            />
                                            <LockIcon
                                                id={`cad2${globalIndex}`}
                                                role="button"
                                                width={20}
                                                height={20}
                                                style={{
                                                    fill: isRequired
                                                        ? "red"
                                                        : "black",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={
                                                isMultipleStep
                                                    ? "disabledDiv__CLZ"
                                                    : ""
                                            }
                                            style={{
                                                display:
                                                    IsBasicCompositionStep ||
                                                    IsOptionStep
                                                        ? "none"
                                                        : "grid",
                                            }}
                                        >
                                            <div className="icon_nbr_click__CLZ   justify-content-center align-items-center mr-2">
                                                <div
                                                    style={{
                                                        alignSelf: "baseline",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    <LockIconWithToolTip
                                                        type="icon"
                                                        lengthMax={0}
                                                        message={t(
                                                            "Max number of choices"
                                                        )}
                                                        id={`nomber${globalIndex}`}
                                                    />
                                                    <NumberOfChoices
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        id={`nomber${globalIndex}`}
                                                        height={20}
                                                        width={20}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        font: "normal normal normal 15px/15px Segoe UI",
                                                    }}
                                                >
                                                    {maxNbrClick}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id={`enhaut${globalIndex}`}
                                            aria-hidden="true"
                                            className="icon_step__CLZ text-center"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                hiddenOption();
                                            }}
                                        >
                                            <LockIconWithToolTip
                                                type="icon"
                                                lengthMax={0}
                                                message={
                                                    isGolbalOptionActive
                                                        ? t("Hide options")
                                                        : t("Show options")
                                                }
                                                id={`enhaut${globalIndex}`}
                                            />

                                            {isGolbalOptionActive ? (
                                                <ArrowUpIcon color="black" />
                                            ) : (
                                                <ArrowDownIcon color="black" />
                                            )}
                                        </div>
                                    </div>
                                    <CrushedItemModal
                                        isCrushed={isCrushed}
                                        setIsCrushed={setIsCrushed}
                                        globalIndex={globalIndex}
                                        uuid={uuid}
                                        action={action}
                                        setIsOpenStepModal={setIsOpenStepModal}
                                        cardData={cardData}
                                        setContentModal={setContentModal}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ zIndex: "10" }}>
                            <SearchData
                                data={data}
                                FilterData={setMyData}
                                cardData={cardData}
                                globalIndex={globalIndex}
                                checkstepItem={checkstepItem}
                                setIsUnsavedDataMultiple={
                                    setIsUnsavedDataMultiple
                                }
                                IsBasicCompositionStep={IsBasicCompositionStep}
                                isModal={false}
                                workflow={globalWorkflow}
                            />
                        </div>

                        <div
                            style={{
                                minHeight: `calc(100vh - 570px )`,
                                maxHeight: `calc(100vh - 570px )`,
                                overflowY: "auto",
                                alignContent: "center",
                                padding: "5px 10px",
                                paddingBottom: isDragging ? "150px" : "0px",
                            }}
                        >
                            <DragDropContext
                                onDragEnd={onDragEndSteps}
                                onBeforeDragStart={() => setIsDragging(true)}
                            >
                                <Droppable droppableId="droppableSteps">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {myDataToArray.length > 0 &&
                                                myDataToArray?.map(
                                                    (
                                                        element: any,
                                                        index: number
                                                    ) => (
                                                        <Draggable
                                                            key={element.key}
                                                            draggableId={
                                                                element.key
                                                            }
                                                            index={index}
                                                        >
                                                            {(provided) => {
                                                                const list: any =
                                                                    element.value;
                                                                const iuud =
                                                                    element.key;
                                                                let stepItem: any;
                                                                let CheckStepSalesExist: boolean =
                                                                    false;
                                                                if (
                                                                    Object.keys?.(
                                                                        list.content
                                                                    ).length > 0
                                                                ) {
                                                                    const UuidModifier =
                                                                        Object.keys(
                                                                            list.content
                                                                        )[0];
                                                                    CheckStepSalesExist =
                                                                        StepSalesExist(
                                                                            multipleWorkflow.length >
                                                                                0
                                                                                ? {
                                                                                      ...multipleWorkflow[
                                                                                          multipleWorkflow.length -
                                                                                              1
                                                                                      ]
                                                                                          .VirtualModifier,
                                                                                      ...modifierWorkflow,
                                                                                  }
                                                                                : modifierWorkflow,
                                                                            UuidModifier,

                                                                            cardData
                                                                        );
                                                                }
                                                                if (
                                                                    IsOptionStep ||
                                                                    IsBasicCompositionStep
                                                                ) {
                                                                    const productForm =
                                                                        IsOptionStep
                                                                            ? optionFormWorkflow(
                                                                                  globalWorkflow,
                                                                                  startIndex,
                                                                                  uuid,
                                                                                  globalIndex,
                                                                                  iuud,
                                                                                  index +
                                                                                      1
                                                                              )
                                                                            : basicCompositionFormWorkflow(
                                                                                  globalWorkflow,
                                                                                  startIndex,
                                                                                  uuid,
                                                                                  globalIndex,
                                                                                  iuud,
                                                                                  cardData,
                                                                                  list
                                                                              );

                                                                    stepItem = {
                                                                        title: IsOptionStep
                                                                            ? cardData
                                                                                  ?.options?.[
                                                                                  globalWorkflow[
                                                                                      globalIndex!
                                                                                  ]
                                                                                      ?.uuidStep!
                                                                              ]
                                                                                  ?.values[
                                                                                  iuud
                                                                              ]
                                                                                  ?.title
                                                                            : Object.keys(
                                                                                  globalWorkflow[
                                                                                      globalIndex
                                                                                  ]
                                                                                      ?.override ||
                                                                                      {}
                                                                              )
                                                                                  .length >
                                                                              0
                                                                            ? globalWorkflow[
                                                                                  globalIndex
                                                                              ]
                                                                                  ?.override
                                                                                  ?.stepItems?.[
                                                                                  iuud
                                                                              ]
                                                                                  .title
                                                                            : cardData?.[
                                                                                  list
                                                                                      .type
                                                                              ]?.[
                                                                                  iuud
                                                                              ]
                                                                                  .title,
                                                                        image: productForm.image,
                                                                        isVisible:
                                                                            productForm.isVisible,
                                                                        isOptionModifier:
                                                                            productForm.isOptionModifier,
                                                                        color: cardData?.[
                                                                            list
                                                                                .type
                                                                        ]?.[
                                                                            globalWorkflow[
                                                                                globalIndex!
                                                                            ]
                                                                                ?.uuid!
                                                                        ]
                                                                            ?.color,
                                                                        formBasicComposition:
                                                                            IsBasicCompositionStep
                                                                                ? formElementBasicComposition(
                                                                                      iuud,
                                                                                      globalWorkflow[
                                                                                          startIndex
                                                                                      ]
                                                                                          ?.selected,
                                                                                      cardData
                                                                                  )
                                                                                : {
                                                                                      quantity: 1,
                                                                                      title: "",
                                                                                      isVisible:
                                                                                          true,
                                                                                      isObligatory:
                                                                                          false,
                                                                                  },
                                                                    };
                                                                } else {
                                                                    stepItem =
                                                                        Object.keys(
                                                                            globalWorkflow[
                                                                                globalIndex
                                                                            ]
                                                                                ?.override ||
                                                                                {}
                                                                        )
                                                                            .length >
                                                                        0
                                                                            ? globalWorkflow[
                                                                                  globalIndex
                                                                              ]
                                                                                  ?.override
                                                                                  ?.stepItems?.[
                                                                                  iuud
                                                                              ]
                                                                            : cardData?.[
                                                                                  list
                                                                                      .type
                                                                              ]?.[
                                                                                  iuud
                                                                              ];
                                                                    stepItem = {
                                                                        ...stepItem,
                                                                        image: cardData?.[
                                                                            list
                                                                                .type
                                                                        ]?.[
                                                                            iuud
                                                                        ]
                                                                            ?.imageUrl
                                                                            ?.Default
                                                                            ?.urlDefault,
                                                                    };
                                                                }
                                                                return (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        {stepItem ? (
                                                                            <WorkFlowCardV2
                                                                                key={shortid.generate()}
                                                                                item={
                                                                                    list
                                                                                }
                                                                                title={
                                                                                    stepItem.title
                                                                                }
                                                                                image={
                                                                                    stepItem.image
                                                                                }
                                                                                isOptionModifier={
                                                                                    IsOptionStep ||
                                                                                    IsBasicCompositionStep
                                                                                        ? stepItem.isOptionModifier
                                                                                        : ""
                                                                                }
                                                                                isVisible={
                                                                                    IsOptionStep ||
                                                                                    IsBasicCompositionStep
                                                                                        ? stepItem.isVisible
                                                                                        : stepItem
                                                                                              ?.visibilityInfo
                                                                                              ?.isVisible
                                                                                }
                                                                                colorElement={
                                                                                    stepItem.color
                                                                                }
                                                                                order={
                                                                                    index +
                                                                                    1
                                                                                }
                                                                                type={
                                                                                    list.type
                                                                                }
                                                                                typeGolbal={
                                                                                    type
                                                                                }
                                                                                uuid={
                                                                                    iuud
                                                                                }
                                                                                subRows={
                                                                                    list.subRows
                                                                                        ? list.subRows
                                                                                        : []
                                                                                }
                                                                                cardData={
                                                                                    cardData
                                                                                }
                                                                                isGolbalOptionActive={
                                                                                    isGolbalOptionActive
                                                                                }
                                                                                globalIndex={
                                                                                    globalIndex
                                                                                }
                                                                                setTypeElement={
                                                                                    setTypeElement
                                                                                }
                                                                                setIsOpenStepModal={
                                                                                    setIsOpenStepModal
                                                                                }
                                                                                setContentModal={
                                                                                    setContentModal
                                                                                }
                                                                                startIndex={
                                                                                    startIndex
                                                                                }
                                                                                formBasicComposition={
                                                                                    IsBasicCompositionStep
                                                                                        ? stepItem.formBasicComposition
                                                                                        : {
                                                                                              quantity: 1,
                                                                                              title: "",
                                                                                              isVisible:
                                                                                                  true,
                                                                                              isObligatory:
                                                                                                  false,
                                                                                          }
                                                                                }
                                                                                CheckStepSalesExist={
                                                                                    CheckStepSalesExist
                                                                                }
                                                                                checkstepItem={
                                                                                    checkstepItem
                                                                                }
                                                                                setIsUnsavedDataMultiple={
                                                                                    setIsUnsavedDataMultiple
                                                                                }
                                                                                setIndexSelected={
                                                                                    setIndexSelected
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    )
                                                )}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>

                    {globalIndex === globalWorkflow.length - 1 &&
                        // !isDragging &&
                        ((globalWorkflow[startIndex]?.multipleStep.isMultiple &&
                            checkEmptyStep !== -1) ||
                            Object.keys(
                                globalWorkflow[globalWorkflow.length - 1]
                                    ?.data || {}
                            ).length > 0) && (
                            <AddStep
                                setIndexSelected={setIndexSelected}
                                globalIndex={globalIndex}
                                setIsClickStep={setIsClickStep}
                                isClickStep={isClickStep}
                                uuid={uuid}
                                startIndex={startIndex}
                                isRedirect={
                                    cardData?.items?.[
                                        globalWorkflow[globalIndex!]?.uuid
                                    ]?.isRedirect
                                }
                            />
                        )}
                </div>
            ) : (
                <div
                    className=" mx-2 "
                    style={{
                        minHeight: "calc(100vh - 408px)",
                        width: "420px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "0px 0px 5px 5px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderBottom: "1px solid #E4E4E4",
                    }}
                >
                    <div
                        style={{
                            minWidth: "420px",
                            display: "grid",
                            gridTemplateRows:
                                checkstepItem &&
                                globalWorkflow[globalIndex]?.multipleStep
                                    .isMultiple
                                    ? "100px 50px auto"
                                    : "50px 50px auto",
                            gridRowGap: "6px",
                            maxHeight: "250px",
                            minHeight: "250px",
                        }}
                    >
                        <div
                            style={{
                                font: "normal normal normal 18px/24px Segoe UI",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                boxShadow: "0px 1px 2px #0000001C",
                                border: "1px solid #E4E4E4",
                            }}
                        >
                            {checkstepItem &&
                            globalWorkflow[globalIndex]?.multipleStep
                                .isMultiple ? (
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateRows: "35px auto",
                                        gridRowGap: "6px",
                                        width: "100%",
                                        height: "max-content",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",

                                            justifyContent: "center",
                                            boxShadow: "0px 3px 6px #00000029",
                                        }}
                                    >
                                        <span>{title}</span>
                                    </div>
                                    <div className="d-flex justify-content-end items-align-center my-1">
                                        <VisibilityStepProduct
                                            setIndexSelected={setIndexSelected}
                                            setIsClickStep={setIsClickStep}
                                            isClickStep={isClickStep}
                                            globalIndex={globalIndex}
                                            cardData={cardData}
                                            setIsUnsavedDataMultiple={
                                                setIsUnsavedDataMultiple
                                            }
                                            isVisibleMultiple={
                                                isVisibleMultiple
                                            }
                                        />
                                        <SalesAddMultipleInProduct
                                            isMultipleStep={isMultipleStep}
                                            globalIndex={globalIndex}
                                            uuid={uuid}
                                            setIsCrushed={setIsCrushed}
                                            setAction={setAction}
                                            setContentModal={setContentModal}
                                            setIsOpenStepModal={
                                                setIsOpenStepModal
                                            }
                                            cardData={cardData}
                                            setIsUnsavedDataMultiple={
                                                setIsUnsavedDataMultiple
                                            }
                                        />
                                        <AddMultipleStepInProduct
                                            IsBasicCompositionStep={
                                                IsBasicCompositionStep
                                            }
                                            IsOptionStep={IsOptionStep}
                                            isMultipleStep={isMultipleStep}
                                            globalIndex={globalIndex}
                                            uuid={uuid}
                                            setIsCrushed={setIsCrushed}
                                            setAction={setAction}
                                            setIsUnsavedDataMultiple={
                                                setIsUnsavedDataMultiple
                                            }
                                            cardData={cardData}
                                        />
                                    </div>
                                    <CrushedItemModal
                                        isCrushed={isCrushed}
                                        setIsCrushed={setIsCrushed}
                                        globalIndex={globalIndex}
                                        uuid={uuid}
                                        action={action}
                                        setIsOpenStepModal={setIsOpenStepModal}
                                        cardData={cardData}
                                        setContentModal={setContentModal}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <LockIconWithToolTip
                                        type="title"
                                        lengthMax={32}
                                        message={title}
                                        id={`title${globalIndex}`}
                                    />

                                    <div
                                        className="preview-textCard"
                                        id={`title${globalIndex}`}
                                    >
                                        <span>{title}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <SearchData
                            data={data}
                            FilterData={setMyData}
                            cardData={cardData}
                            globalIndex={globalIndex}
                            checkstepItem={checkstepItem}
                            setIsUnsavedDataMultiple={setIsUnsavedDataMultiple}
                            IsBasicCompositionStep={IsBasicCompositionStep}
                            isModal={false}
                            workflow={globalWorkflow}
                        />
                        <CheckUnsaveDataMultiple
                            isUnsavedDataMultiple={isUnsavedDataMultiple}
                            setIsUnsavedDataMultiple={setIsUnsavedDataMultiple}
                        />
                        {myData && (
                            <div
                                style={{
                                    minHeight:
                                        checkstepItem &&
                                        globalWorkflow[globalIndex]
                                            ?.multipleStep.isMultiple
                                            ? `calc(100vh - 570px )`
                                            : `calc(100vh - 525px )`,
                                    maxHeight:
                                        checkstepItem &&
                                        globalWorkflow[globalIndex]
                                            ?.multipleStep.isMultiple
                                            ? `calc(100vh - 570px )`
                                            : `calc(100vh - 525px )`,
                                    overflowY: "auto",
                                    alignContent: "center",
                                    padding: "0px 10px",
                                    paddingBottom: isDragging ? "150px" : "0px",
                                }}
                            >
                                <DragDropContext
                                    onDragEnd={onDragEndCategory}
                                    onBeforeDragStart={() =>
                                        setIsDragging(true)
                                    }
                                >
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {myDataToArray.length > 0 &&
                                                    myDataToArray.map(
                                                        (
                                                            element: any,
                                                            index: number
                                                        ) => (
                                                            <Draggable
                                                                key={
                                                                    element.key
                                                                }
                                                                draggableId={
                                                                    element.key
                                                                }
                                                                index={index}
                                                            >
                                                                {(provided) => {
                                                                    const list: any =
                                                                        element.value;
                                                                    const iuud =
                                                                        element.key;
                                                                    const defaultformBasicComposition: basicComposition =
                                                                        {
                                                                            quantity: 1,
                                                                            title: "",
                                                                            isVisible:
                                                                                true,
                                                                            isObligatory:
                                                                                false,
                                                                        };
                                                                    return (
                                                                        <div
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {list !==
                                                                            null ? (
                                                                                <WorkFlowCardV2
                                                                                    key={shortid.generate()}
                                                                                    item={
                                                                                        list
                                                                                    }
                                                                                    title={
                                                                                        Object.keys?.(
                                                                                            list.override ||
                                                                                                {}
                                                                                        )
                                                                                            .length >
                                                                                        0
                                                                                            ? list
                                                                                                  .override[
                                                                                                  iuud
                                                                                              ]
                                                                                                  .title
                                                                                            : cardData?.[
                                                                                                  list
                                                                                                      .type
                                                                                              ]?.[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.title
                                                                                    }
                                                                                    image={
                                                                                        Object.keys?.(
                                                                                            list.override ||
                                                                                                {}
                                                                                        )
                                                                                            .length >
                                                                                        0
                                                                                            ? list
                                                                                                  .override[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.imageUrl
                                                                                                  ?.Default
                                                                                                  ?.urlDefault
                                                                                            : cardData?.[
                                                                                                  list
                                                                                                      .type
                                                                                              ]?.[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.imageUrl
                                                                                                  ?.Default
                                                                                                  ?.urlDefault
                                                                                    }
                                                                                    isVisible={
                                                                                        Object.keys?.(
                                                                                            list.override ||
                                                                                                {}
                                                                                        )
                                                                                            .length >
                                                                                        0
                                                                                            ? list
                                                                                                  .override[
                                                                                                  iuud
                                                                                              ]
                                                                                                  .visibilityInfo
                                                                                                  .isVisible
                                                                                            : cardData?.[
                                                                                                  list
                                                                                                      .type
                                                                                              ]?.[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.visibilityInfo
                                                                                                  .isVisible
                                                                                    }
                                                                                    colorElement={
                                                                                        Object.keys?.(
                                                                                            list.override ||
                                                                                                {}
                                                                                        )
                                                                                            .length >
                                                                                        0
                                                                                            ? list
                                                                                                  .override[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.color
                                                                                            : cardData?.[
                                                                                                  list
                                                                                                      .type
                                                                                              ]?.[
                                                                                                  iuud
                                                                                              ]
                                                                                                  ?.color
                                                                                    }
                                                                                    isOptionModifier=""
                                                                                    order={
                                                                                        index +
                                                                                        1
                                                                                    }
                                                                                    type={
                                                                                        list.type
                                                                                    }
                                                                                    typeGolbal={
                                                                                        type
                                                                                    }
                                                                                    uuid={
                                                                                        iuud
                                                                                    }
                                                                                    subRows={
                                                                                        list.subRows
                                                                                            ? list.subRows
                                                                                            : []
                                                                                    }
                                                                                    globalIndex={
                                                                                        globalIndex
                                                                                    }
                                                                                    setTypeElement={
                                                                                        setTypeElement
                                                                                    }
                                                                                    setIsOpenStepModal={
                                                                                        setIsOpenStepModal
                                                                                    }
                                                                                    setContentModal={
                                                                                        setContentModal
                                                                                    }
                                                                                    cardData={
                                                                                        cardData
                                                                                    }
                                                                                    startIndex={
                                                                                        startIndex
                                                                                    }
                                                                                    formBasicComposition={
                                                                                        defaultformBasicComposition
                                                                                    }
                                                                                    CheckStepSalesExist={
                                                                                        false
                                                                                    }
                                                                                    checkstepItem={
                                                                                        checkstepItem
                                                                                    }
                                                                                    setIsUnsavedDataMultiple={
                                                                                        setIsUnsavedDataMultiple
                                                                                    }
                                                                                    setIndexSelected={
                                                                                        setIndexSelected
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
