import React from "react";

export default function TagIconMinimal(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={132}
            height={132}
            viewBox="0 0 32 32"
            {...props}
        >
            <g data-name="Groupe 27739">
                <path data-name="Rectangle 10647" d="M0 0h32v32H0z" />
                <g data-name="process (1)" fill="#fff">
                    <path
                        data-name="Trac\xE9 9479"
                        d="M14.521 23.053a1.806 1.806 0 0 0 1.8-1.505h2.782l-.337.324.369.355.782-.753a.244.244 0 0 0 0-.355l-.782-.753-.369.355.337.324h-2.777a1.821 1.821 0 1 0-1.8 2.007Zm0-3.011a1.255 1.255 0 1 1-1.3 1.254 1.282 1.282 0 0 1 1.3-1.255Z"
                    />
                    <path
                        data-name="Trac\xE9 9480"
                        d="M21.824 23.053a1.758 1.758 0 1 0-1.826-1.756 1.794 1.794 0 0 0 1.826 1.756Zm0-3.011a1.255 1.255 0 1 1-1.3 1.254 1.282 1.282 0 0 1 1.3-1.255Z"
                    />
                    <path
                        data-name="Trac\xE9 9481"
                        d="M13.477 13.771a1.806 1.806 0 0 0-1.8 1.505h-.417a3.139 3.139 0 1 0 0 6.272h.54l-.337.324.369.355.782-.753a.244.244 0 0 0 0-.355l-.778-.748-.369.355.337.324h-.544a2.636 2.636 0 1 1 0-5.269h.412a1.821 1.821 0 1 0 1.805-2.01Zm0 3.011a1.255 1.255 0 1 1 1.3-1.254 1.282 1.282 0 0 1-1.3 1.254Z"
                    />
                    <path
                        data-name="Trac\xE9 9482"
                        d="m14.444 8.826-.369.355.337.324h-2.781A1.806 1.806 0 0 0 9.826 8 1.794 1.794 0 0 0 8 9.756a1.794 1.794 0 0 0 1.826 1.756 1.806 1.806 0 0 0 1.8-1.505h2.787l-.337.324.369.355.782-.753a.244.244 0 0 0 0-.355Zm-4.618 2.185a1.282 1.282 0 0 1-1.3-1.254 1.282 1.282 0 0 1 1.3-1.257 1.282 1.282 0 0 1 1.3 1.254 1.282 1.282 0 0 1-1.3 1.257Z"
                    />
                    <path
                        data-name="Trac\xE9 9483"
                        d="M23.649 12.642a3.2 3.2 0 0 0-3.26-3.136h-1.455a1.831 1.831 0 0 0-3.631.25 1.831 1.831 0 0 0 3.631.251h1.455a2.691 2.691 0 0 1 2.739 2.634 2.691 2.691 0 0 1-2.739 2.634h-4.2l.337-.324-.364-.351-.782.753a.244.244 0 0 0 0 .355l.782.753.369-.355-.337-.324h4.2a3.2 3.2 0 0 0 3.255-3.14Zm-6.52-1.631a1.282 1.282 0 0 1-1.3-1.254 1.305 1.305 0 0 1 2.608 0 1.282 1.282 0 0 1-1.308 1.254Z"
                    />
                </g>
            </g>
        </svg>
    );
}
