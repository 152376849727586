import React from "react";

import { VariableSizeList, FixedSizeList as List } from "react-window";

import CategoryWithItems from "./CategoryWithItems";
import { categorySize } from "./helper";

import Item from "./Item";

type CategoryItemsPropsType = {
    category: CategoryWithChildrenType;
    selectedItems: ItemWithIuud[];
    collapsedCategoriesUuids: string[];
    addSelectedItem: Function;
    deleteSelectedItem: Function;
    setSelectedItems: React.Dispatch<React.SetStateAction<ItemWithIuud[]>>;
    setCollapsedCategoriesUuids: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function CategoryItems({
    category,
    selectedItems,
    collapsedCategoriesUuids,
    addSelectedItem,
    deleteSelectedItem,
    setSelectedItems,
    setCollapsedCategoriesUuids,
}: CategoryItemsPropsType) {
    const listRef: any = React.useRef({});
    return (
        <div
            className="ml-4"
            style={{
                display: collapsedCategoriesUuids.includes(category.iuud)
                    ? "none"
                    : "block",
            }}
        >
            <List
                height={35 * category.items.length}
                itemCount={category.items.length}
                itemSize={35}
                width="100%"
                style={{ overflow: "hidden" }}
            >
                {({
                    index,
                    style = {
                        overflow: "hidden",
                    },
                }) => (
                    <div style={style}>
                        <Item
                            key={category.items[index].iuud}
                            item={category.items[index]}
                            selectedItems={selectedItems}
                            addSelectedItem={addSelectedItem}
                            deleteSelectedItem={deleteSelectedItem}
                        />
                    </div>
                )}
            </List>

            <VariableSizeList
                height={
                    collapsedCategoriesUuids.includes(category.iuud)
                        ? 0
                        : 35 *
                              category.childCategories.reduce(
                                  (size: number, category) => {
                                      return (
                                          size +
                                          categorySize(
                                              category,
                                              collapsedCategoriesUuids
                                          )
                                      );
                                  },
                                  0
                              ) +
                          35
                }
                itemCount={category.childCategories.length}
                itemSize={(index) =>
                    collapsedCategoriesUuids.includes(
                        category.childCategories[index].iuud
                    )
                        ? 0
                        : 35 *
                              category.childCategories.reduce(
                                  (size: number, category) => {
                                      return (
                                          size +
                                          categorySize(
                                              category,
                                              collapsedCategoriesUuids
                                          )
                                      );
                                  },
                                  0
                              ) +
                          35
                }
                width="100%"
                ref={listRef}
                style={{ overflow: "hidden" }}
            >
                {({
                    index,
                    style = {
                        overflow: "hidden",
                    },
                }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    React.useEffect(() => {
                        listRef.current.resetAfterIndex(0, false);
                    }, []);
                    return (
                        <div style={style}>
                            <CategoryWithItems
                                key={category.childCategories[index].iuud}
                                category={category.childCategories[index]}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                collapsedCategoriesUuids={
                                    collapsedCategoriesUuids
                                }
                                setCollapsedCategoriesUuids={
                                    setCollapsedCategoriesUuids
                                }
                            />
                        </div>
                    );
                }}
            </VariableSizeList>
        </div>
    );
}
