import React from "react";

import { StyledModal } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { ModalBody, ModalHeader } from "reactstrap";

import PriceTable from "@components/Common_DB/AdvancePrice/PriceItems/PriceTable";

import "./priceModal.css";
import PriceModalFooter from "./PriceModalFooter";

type PriceModalType = {
    dispatchItem: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    advancedPrice?: any;
};

export default function PriceModal({
    dispatchItem,
    advancedPrice,
}: PriceModalType) {
    const { t } = useTranslation();
    const [active, setActive] = React.useState(false);

    return (
        <div>
            <button
                type="button"
                style={{
                    color: "blue",
                    cursor: "pointer",
                    border: "transparent",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    textDecoration: "underline",
                    font: "normal normal 600 16px/21px Segoe UI",
                }}
                onClick={() => {
                    setActive(true);
                }}
            >
                {t("Access")}
            </button>
            <StyledModal size="xl" isOpen={active} centered>
                <ModalHeader
                    toggle={() => {
                        setActive(false);
                    }}
                >
                    <p className="pp">{t("ADVANCED PRICE")}</p>
                </ModalHeader>
                <ModalBody
                    style={{
                        maxHeight: "max-content",

                        overflowY: "auto",
                    }}
                >
                    <PriceTable advancedPrice={advancedPrice} />
                </ModalBody>
                <PriceModalFooter
                    setActive={setActive}
                    dispatchItem={dispatchItem}
                />
            </StyledModal>
        </div>
    );
}
