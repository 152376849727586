import React, { useState } from "react";
import { Table } from "reactstrap";
import { ColumnFileitem } from "@constants/ColumnFileitem";
import { ColumnFileCategory } from "@constants/ColumnFileCategory";
import { useTranslation } from "react-i18next";
import {
    VerticalDotsIcon,
    PlusIcon,
    EyeIcon,
    EyeCrossedIcon,
} from "@aureskonnect/react-ui";
import shortid from "shortid";
import { ColumnFileStep } from "@constants/ColumnFileSteps";

type secondTabletype = {
    file: any;
    setFile: React.Dispatch<any>;
    rowFilter: any;
};

export default function SecondTable({
    file,
    setFile,
    rowFilter,
}: secondTabletype) {
    const pathname = window.location.pathname
        .toString()
        .split("/")[2]
        .toUpperCase();

    const { t } = useTranslation();

    const [ind, setindex] = useState(false);
    const [ColonneFile, setColonneFile] = useState(ColumnFileitem);

    const changeChecked = (row: any, indexCols: number) => {
        setFile((prevState: any) => {
            const newState: any = prevState;
            const selectedOption = {
                ...newState.cols[indexCols],
                visibility: !row.visibility,
            };
            newState.cols[indexCols] = {
                ...newState.cols[indexCols],
                visibility: selectedOption.visibility,
            };
            return newState;
        });
    };

    function changeselect(value: any, row: any, indexCols: number): void {
        row.name = ColonneFile[value].name;
        row.key = ColonneFile[value].value;
        row.index = ColonneFile[value].index;

        const nameSelectIndex = row.listOfTitle.findIndex(
            (element: any) => element.name === row.name
        );

        if (nameSelectIndex !== -1) {
            setFile((prevState: any) => {
                const newState: any = prevState;
                const selectedOption = {
                    ...newState.cols[indexCols].listOfTitle[nameSelectIndex],
                    selected: true,
                };

                const newArr = [...newState.cols[indexCols].listOfTitle];
                newArr[nameSelectIndex] = selectedOption;

                newState.cols[indexCols] = {
                    ...newState.cols[indexCols],
                    listOfTitle: newArr,
                };
                return newState;
            });

            file.cols[indexCols].listOfTitle.map((col: any, ind: number) => {
                if (ind !== nameSelectIndex) {
                    col.selected = false;
                }
                return nameSelectIndex;
            });
        }
    }

    React.useEffect(() => {
        if (pathname.toString() === "CATEGORIES") {
            file.cols.map((col: any) => {
                col.listOfTitle = ColumnFileCategory;
                return pathname;
            });
            setColonneFile(ColumnFileCategory);
            setindex(() => !ind);
        }
        if (pathname.toString() === "STEPS") {
            file.cols.map((col: any) => {
                col.listOfTitle = ColumnFileStep;
                return pathname;
            });
            setColonneFile(ColumnFileStep);
            setindex(() => !ind);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <Table responsive className="text-center ">
            <thead>
                <tr>
                    {file.cols.map((row: any, index: number) => {
                        return (
                            <th
                                key={shortid.generate()}
                                style={{
                                    border: row.errorColumn && "2px solid red",
                                }}
                            >
                                <div className="d-flex bd-highlight d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        name="checklist-list"
                                        className="checkbox-box"
                                        checked={row.visibility}
                                        onChange={() => {
                                            changeChecked(row, index);
                                            setindex(() => !ind);
                                        }}
                                    />
                                    <div className="p-2 flex-fill bd-highlight">
                                        <select
                                            style={{
                                                border: "0px",
                                                backgroundColor: "#ffffff",
                                                width: "100%",
                                                textAlign: "center",
                                            }}
                                            onChange={(val: any) => {
                                                if (
                                                    val.target.value !==
                                                    t("column")
                                                ) {
                                                    changeselect(
                                                        val.target.value,
                                                        row,
                                                        index
                                                    );
                                                    setindex(() => !ind);
                                                } else {
                                                    setindex(() => !ind);
                                                }
                                            }}
                                        >
                                            {row?.listOfTitle.map(
                                                (
                                                    element: any,
                                                    localindex: number
                                                ) => {
                                                    return (
                                                        <React.Fragment
                                                            key={shortid.generate()}
                                                        >
                                                            {localindex ===
                                                            0 ? (
                                                                <>
                                                                    <option
                                                                        key={shortid.generate()}
                                                                        value="colonne"
                                                                    >
                                                                        {t(
                                                                            "column"
                                                                        )}{" "}
                                                                        {index +
                                                                            1}
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            localindex
                                                                        }
                                                                        selected={
                                                                            element.selected
                                                                        }
                                                                    >
                                                                        {t(
                                                                            element.index
                                                                        )}
                                                                    </option>
                                                                </>
                                                            ) : (
                                                                <option
                                                                    key={shortid.generate()}
                                                                    value={
                                                                        localindex
                                                                    }
                                                                    selected={
                                                                        element.selected
                                                                    }
                                                                >
                                                                    {t(
                                                                        element.index
                                                                    )}
                                                                </option>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </th>
                        );
                    })}
                    <th>
                        <div
                            className=" d-flex align-items-center d-flex justify-content-end"
                            style={{ visibility: "hidden" }}
                        >
                            <PlusIcon
                                height={10}
                                width={10}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                            <VerticalDotsIcon
                                height={20}
                                width={20}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {rowFilter?.map((row: any) => {
                    return (
                        <tr key={shortid.generate()}>
                            {row.map((col: any, index: number) => {
                                if (row.length - 1 === index) {
                                    return (
                                        <th key={shortid.generate()}>
                                            <div
                                                role="button"
                                                tabIndex={0}
                                                className=" d-flex align-items-center d-flex justify-content-end"
                                                onClick={() => {
                                                    setindex(!ind);
                                                    row[index] = !row[index];
                                                }}
                                            >
                                                {row[index] ? (
                                                    <EyeIcon
                                                        height={20}
                                                        width={20}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeCrossedIcon
                                                        height={20}
                                                        width={20}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    );
                                }
                                return <th key={shortid.generate()}>{col}</th>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
