export const renameObjKey = (oldObj: any, oldKey: string, newKey: string) => {
    // object to array
    const objectToArray = Object.entries(oldObj);

    // array to object
    const arrayToObject = objectToArray.reduce((prev: {}, elm: any) => {
        return {
            ...prev,
            [elm[0] === oldKey ? newKey : elm[0]]: elm[1],
        };
    }, {});
    return arrayToObject;
};
