import React from "react";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";

import { priceInputOnKeyDown } from "@pages/Catalogue/Items/addItem/FormBodyComponents/helpers";

type IncreasePricePropsType = {
    selectedOption: "newPrice" | "increase" | "decrease";
    amount: number | "";
    percentage: number | "";
    setPercentage: React.Dispatch<React.SetStateAction<number | "">>;
    setAmount: React.Dispatch<React.SetStateAction<number | "">>;
    setSelectedOption: React.Dispatch<
        React.SetStateAction<"newPrice" | "increase" | "decrease">
    >;
};

export default function IncreasePrice({
    selectedOption,
    amount,
    percentage,
    setPercentage,
    setAmount,
    setSelectedOption,
}: IncreasePricePropsType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Input
                type="radio"
                id="increase"
                checked={selectedOption === "increase"}
                onChange={() => {
                    setAmount("");
                    setPercentage("");
                    setSelectedOption("increase");
                }}
            />
            <StyledLabel htmlFor="increase">
                {t("Increase the current price by")}
            </StyledLabel>
            <div className="py-4 d-flex align-items-center">
                <div className="mx-4">
                    <StyledLabel
                        htmlFor="increaseByValue"
                        className="d-block"
                    >{`${t("Value in euros")}`}</StyledLabel>

                    <StyledTextInput
                        id="increaseByValue"
                        name="increaseByValue"
                        placeholder={t("Enter")}
                        type="number"
                        min="0"
                        disabled={
                            selectedOption !== "increase" || percentage !== ""
                        }
                        value={selectedOption === "increase" ? amount : ""}
                        onKeyDown={priceInputOnKeyDown}
                        onChange={(e: any) => {
                            setAmount(e.target.value);
                        }}
                        onBlur={(e: any) => {
                            if (amount !== "") {
                                setAmount(Number(e.target.value));
                            }
                        }}
                    />
                </div>
                <StyledLabel className="mx-4">{t("OR")}</StyledLabel>
                <div className="ml-4">
                    <StyledLabel
                        htmlFor="increaseByPercentage"
                        className="d-block"
                    >{`${t("Percentage")} %`}</StyledLabel>

                    <StyledTextInput
                        id="increaseByPercentage"
                        name="increaseByPercentage"
                        placeholder={t("Enter")}
                        type="number"
                        min="0"
                        disabled={
                            selectedOption !== "increase" || amount !== ""
                        }
                        value={selectedOption === "increase" ? percentage : ""}
                        onKeyDown={priceInputOnKeyDown}
                        onChange={(e: any) => setPercentage(e.target.value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
