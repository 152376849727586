import React from "react";

export default function WorkflowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={107}
            height={107}
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor={props.stopColor} />
                    <stop offset={1} stopColor={props.stopColor} />
                </linearGradient>
            </defs>
            <g transform="translate(-.059)">
                <circle
                    cx={53.5}
                    cy={53.5}
                    r={53.5}
                    transform="translate(.059)"
                    fill={props.stopColor}
                />
                <g fill={props.fill}>
                    <path d="M48.685 83.162a6.993 6.993 0 0 0 6.912-5.989H66.24l-1.291 1.291 1.412 1.412 2.995-2.995a1 1 0 0 0 0-1.412l-2.995-2.995-1.412 1.413 1.291 1.291H55.597a6.984 6.984 0 1 0-6.908 7.986Zm0-11.979a4.991 4.991 0 1 1-4.988 4.992 5 5 0 0 1 4.988-4.988Z" />
                    <path d="M76.635 83.162a6.988 6.988 0 1 0-6.988-6.987 7 7 0 0 0 6.988 6.987Zm0-11.979a4.991 4.991 0 1 1-4.988 4.992 5 5 0 0 1 4.988-4.988ZM44.691 46.228a6.993 6.993 0 0 0-6.908 5.989h-1.577a12.478 12.478 0 1 0 0 24.956h2.082l-1.29 1.291 1.412 1.412 2.995-2.995a1 1 0 0 0 0-1.412l-2.995-2.995-1.412 1.412 1.291 1.291h-2.083a10.482 10.482 0 1 1 0-20.963h1.577a6.984 6.984 0 1 0 6.908-7.986Zm0 11.979a4.991 4.991 0 1 1 4.991-4.991 5 5 0 0 1-4.991 4.991ZM48.392 26.555l-1.412 1.413 1.291 1.291H37.628a6.988 6.988 0 1 0 0 2h10.643L46.98 32.55l1.412 1.412 2.995-2.995a1 1 0 0 0 0-1.412Zm-17.676 8.692a4.991 4.991 0 1 1 4.991-4.991 5 5 0 0 1-4.991 4.991Z" />
                    <path d="M83.623 41.736a12.492 12.492 0 0 0-12.478-12.479h-5.566a6.988 6.988 0 1 0 0 2h5.57a10.482 10.482 0 0 1 0 20.963H55.087l1.292-1.294-1.412-1.412-2.995 2.995a1 1 0 0 0 0 1.412l2.995 2.995 1.412-1.412-1.291-1.291h16.057a12.492 12.492 0 0 0 12.478-12.477Zm-24.956-6.489a4.991 4.991 0 1 1 4.991-4.991 5 5 0 0 1-4.991 4.991Z" />
                </g>
            </g>
        </svg>
    );
}
