import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { CardFooter, Card } from "reactstrap";

import {
    ErrorToast,
    SuccessToast,
    RequirementToast,
    DynamicTableWrapperContext,
    DominantColor,
    CustomCardBody,
} from "@components/Common/";
import { setIsEditing } from "@store/features/editObserverSlice";
import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";
import useParentsCategory from "@hooks/useParentsCategory";
import { ACTIONS } from "@helpers/.";
import { initialParentCategory } from "@pages/Catalogue/Common/ParentCategories";
import { RootState } from "@store/.";
import {
    overrideWorkflowDataElementByUuid,
    overrideWorkflowDataElementWithPath,
} from "@pages/WorkFlow/helpers";
import { setWorkflowData } from "@store/features/workflowDataSlice";
import { setWorkflow } from "@store/features/workflowSlice";
import { resetUpdateItemWorkflow } from "@store/features/updateItemWorkflowSlice";
import { uploadGetUrlImage } from "@helpers/uploadGetUrlImage";
import FormFooter from "@pages/Catalogue/Common/FormFooter";
import { reducerCategory, initialStateCategory } from "../reducer";
import AddCategoryHeader from "./AddCategoryHeader";
import FormBody from "./FormBody";

import "../Style.css";

type addCategoryPropsType = {
    setIsLoading: Function;
    initialDataCategory?: any;
};

export default function AddCategory({
    setIsLoading,
    initialDataCategory,
}: addCategoryPropsType): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const [usedCard] = React.useState<any>(
        JSON.parse(localStorage.getItem("selectedCard") || "{}")
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const refs = React.useRef<HTMLDivElement[]>([]);

    const addToRefs = (el: HTMLDivElement) => {
        if (el && !refs?.current?.includes(el)) {
            refs?.current?.push(el);
        }
    };

    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const [currentOption, setCurrentOption] = React.useState<Array<Setting>>(
        []
    );
    const pathname = window.location.pathname.toString().split("/")[1];
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [selectedLinkTags, setSelectedLinkTags] = React.useState<string>("");
    const defaultVisibility = useSelector(
        (state: RootState): Record<string, visibilityInfoType> =>
            state.visibilitySlice.defaultVisibility
    );

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const globalWorkflow = useSelector(
        (state: RootState) => state.workFlowSlice.workflow
    );
    const globalWorkflowData = useSelector(
        (state: RootState) => state.workFlowDataSlice.workflowData
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );

    const [categoryState, dispatchCategory] = React.useReducer(
        reducerCategory,
        initialStateCategory
    );

    const isMultiModify = useSelector(
        (state: RootState): boolean => state.multipleEditSlice.isMultiModify
    );
    const listMultiEditKeys = useSelector(
        (state: RootState): string[] => state.multipleEditSlice.listkey
    );
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );

    const allParentsCategory = useParentsCategory(
        cardIuud,
        dynamicTableWrapperContext?.rowData?.iuud
    );

    const [defaultParentCategory, setDefaultParentCategory] = React.useState(
        initialParentCategory
    );
    const [selectedLinkCategories, setSelectedLinkCategories] = React.useState<
        string[]
    >([]);

    async function addCategory(data: FormData): Promise<void> {
        try {
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/category`,
                    data
                )
                .then((response): void => {
                    if (
                        response.data.message === "reference  already exists!"
                    ) {
                        ErrorToast(t("Reference already exists try another!"));
                    } else {
                        setIsLoading(true);
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("Category is successfully added"));
                        setIsLoading(false);
                    }
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                })
                .finally((): void => {
                    setValidateOnclick(true);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    }
    async function updateCateogy(data: FormData): Promise<void> {
        try {
            axios
                .put(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/categories/${dynamicTableWrapperContext?.rowData.iuud}`,
                    data
                )
                .then((response) => {
                    if (response.data.error === true) {
                        ErrorToast(t("Reference already exists try another!"));
                    } else {
                        setIsLoading(true);
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("The category is successfully updated"));
                        setIsLoading(false);
                    }
                })
                .catch((): void => {
                    ErrorToast(t("There is a mistake!"));
                })
                .finally((): void => {
                    setValidateOnclick(true);
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    }
    async function overrideWorkflowCategory() {
        let categoryStateWorkflow = { ...categoryState };
        if (
            (categoryStateWorkflow.imageUrl.Default
                .urlDefault as any) instanceof Blob
        ) {
            const result: any = await uploadGetUrlImage(
                categoryStateWorkflow.imageUrl.Default.urlDefault,
                schemaAdbFranchise!,
                usedCard
            );

            const pathImage = result ? result.data.imagePath : "";
            categoryStateWorkflow = {
                ...categoryStateWorkflow,
                imageUrl: {
                    ...categoryStateWorkflow.imageUrl,
                    Default: {
                        ...categoryStateWorkflow.imageUrl.Default,
                        urlDefault: pathImage,
                    },
                },
            };
        }
        dispatch(
            setWorkflow(
                overrideWorkflowDataElementByUuid(
                    globalWorkflow,
                    categoryStateWorkflow,
                    updateItemWorkflow.uuid,
                    updateItemWorkflow.indexWorkflowGlobal
                )
            )
        );

        const { previousUuids } =
            globalWorkflow[updateItemWorkflow.indexWorkflowGlobal];

        dispatch(
            setWorkflowData(
                overrideWorkflowDataElementWithPath(
                    globalWorkflowData,
                    previousUuids,
                    categoryStateWorkflow,
                    updateItemWorkflow.uuid
                )
            )
        );
        dispatch(resetUpdateItemWorkflow());
    }
    const oldCategorieParente = useSelector(
        (state: RootState): any => state.editObserverSlice.oldValue
    );

    async function insertCategory(): Promise<void> {
        if (updateItemWorkflow.pathUpdateItem.length > 0) {
            overrideWorkflowCategory();
            dynamicTableWrapperContext?.setDataUpdated(true);
            dynamicTableWrapperContext?.setActionColumnDisplay(false);
            SuccessToast(t("Item updated successfully"));
        } else if (validateOnclick) {
            setValidateOnclick(false);

            const data = new FormData();

            data.append(
                "oldParentCategorie",
                JSON.stringify(oldCategorieParente?.categoryParent)
            );
            data.append("category", JSON.stringify(categoryState));
            data.append("selectedLinkTags", selectedLinkTags);
            data.append(
                "DEFAULT_IMAGE",
                categoryState.imageUrl.Default.urlDefault
            );
            data.append("VIDEO", categoryState.video.url);

            categoryState.imageUrl.Default.salesSupport?.forEach(
                (image): void => {
                    image.Langue.forEach((imageByLanguage) => {
                        data.append(
                            `${image.sellingSupport}|${imageByLanguage.designation}`,
                            imageByLanguage.value
                        );
                    });
                }
            );

            if (dynamicTableWrapperContext?.rowData !== undefined) {
                dynamicTableWrapperContext?.rowData?.iuud ||
                (dynamicTableWrapperContext?.rowData?.data[0].selectedRows !==
                    undefined &&
                    dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows === 1)
                    ? await updateCateogy(data)
                    : await addCategory(data);
                dynamicTableWrapperContext?.setDataUpdated(true);
            }
        }
    }
    async function multiEditSubmit(): Promise<void> {
        const data = new FormData();

        data.append("category", JSON.stringify(categoryState));

        data.append("DEFAULT_IMAGE", categoryState.imageUrl.Default.urlDefault);
        data.append("VIDEO", categoryState.video.url);
        categoryState.imageUrl.Default.salesSupport?.forEach((image): void => {
            image.Langue.forEach((imageByLanguage) => {
                data.append(
                    `${image.sellingSupport}|${imageByLanguage.designation}`,
                    imageByLanguage.value
                );
            });
        });

        data.append(
            "categoriesIuuds",
            JSON.stringify(
                dynamicTableWrapperContext?.rowData?.data.map(
                    (category: any) => category.iuud
                )
            )
        );

        data.append("keysToEdit", JSON.stringify(listMultiEditKeys));

        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/categories/`,
                data
            )
            .then((): void => {
                dynamicTableWrapperContext?.setDataUpdated(true);
                dynamicTableWrapperContext?.setActionColumnDisplay(false);
                SuccessToast("Modification effectuée  avec succès");
            })
            .catch(() => {
                ErrorToast(t("There is a mistake!"));
            });
    }

    const handleOnscroll = (): void => {
        if (refs) {
            refs?.current?.forEach((elm: any) => {
                if (categoryState.title === "") {
                    if (elm.id === "designation") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''Designation'' field must be filled in")
                        );
                    }
                    // } else if (categoryState.reference === "") {
                    //     if (elm.id === "reference") {
                    //         elm.scrollIntoView({
                    //             behavior: "smooth",
                    //             block: "nearest",
                    //             inline: "start",
                    //         });
                    //         elm.focus();
                    //         RequirementToast(
                    //             t("The field ''Reference'' must be filled")
                    //         );
                    //     }
                }
            });
        }
        setValidateOnclick(true);
    };

    const handleCancelButton = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault();
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        dynamicTableWrapperContext?.setDataUpdated(true);
        updateItemWorkflow.pathUpdateItem.length > 0 &&
            dispatch(resetUpdateItemWorkflow());
    };

    const findSelectedLinkCategories = () => {
        return dynamicTableWrapperContext?.rowData?.data[0]?.categoryLiaison.filter(
            (cat: any) =>
                allParentsCategory.find(
                    (elm: any): boolean => elm.value === cat
                )
        );
    };
    const orderElement = () => {
        currentOption.forEach((element: any) =>
            document
                .getElementById(element.id.toString())
                ?.style.setProperty("order", element.rank)
        );
    };
    const visibilityElement = () => {
        currentOption.forEach((element: any) =>
            document
                .getElementById(element.id.toString())
                ?.style.setProperty(
                    "display",
                    element.visibility ? "block" : "none"
                )
        );
    };
    React.useEffect(() => {
        orderElement();
        visibilityElement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption]);
    React.useEffect((): void => {
        (async () => {
            const data: any = localStorage.getItem("configurationCathegory");
            if (data !== undefined && data !== null) {
                setCurrentOption(JSON.parse(data));
            } else {
                const response = await fetch(
                    "/fakeData/configurationCategory.json"
                );
                const data = await response.json();
                localStorage.setItem(
                    "configurationCathegory",
                    JSON.stringify(data)
                );
                setCurrentOption(data);
            }
        })();

        if (dynamicTableWrapperContext?.rowData?.data) {
            if (dynamicTableWrapperContext.rowData.data.length === 1) {
                if (
                    dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows === undefined ||
                    (dynamicTableWrapperContext?.rowData?.data[0]
                        .selectedRows !== undefined &&
                        dynamicTableWrapperContext?.rowData?.data[0]
                            .selectedRows === 1)
                ) {
                    dispatchCategory({
                        type: ACTIONS.EDIT,
                        payload: dynamicTableWrapperContext?.rowData?.data[0],
                    });
                    dispatch(setIsEditing(true));
                }
                setDefaultParentCategory(
                    allParentsCategory.filter(
                        (cat): boolean =>
                            cat.value ===
                            dynamicTableWrapperContext?.rowData?.data[0]
                                ?.categoryParent
                    )[0]
                );
                setSelectedLinkCategories(findSelectedLinkCategories());
                setSelectedLinkTags(
                    dynamicTableWrapperContext?.rowData?.data[0]?.linkedTags
                );
            } else if (dynamicTableWrapperContext.rowData.data.length > 1) {
                dispatchCategory({
                    type: ACTIONS.RESET,
                    payload: {},
                });
                dispatchCategory({
                    type: ACTIONS.INIT_VISIBILITY,
                    payload: defaultVisibility,
                });

                setDefaultParentCategory(initialParentCategory);
                setSelectedLinkCategories([]);
            }
        } else {
            dispatchCategory({
                type: ACTIONS.INIT_VISIBILITY,
                payload: defaultVisibility,
            });
            dispatchCategory({
                type: ACTIONS.COLOR,
                payload: "#FFFFFF",
            });
        }
        // eslint-disable-next-line
    }, [
        dynamicTableWrapperContext,
        dynamicTableWrapperContext?.rowData?.data,
        allParentsCategory,
        dominantColor,
        pathname,
        defaultVisibility,
    ]);
    useUnsavedChangesWarning(categoryState);

    return (
        <Card style={{ border: "0px " }}>
            <AddCategoryHeader
                dispatchCategory={dispatchCategory}
                setDefaultParentCategory={setDefaultParentCategory}
                setselectedLinkCategories={setSelectedLinkCategories}
            />
            <AvForm
                onValidSubmit={isMultiModify ? multiEditSubmit : insertCategory}
                onInvalidSubmit={handleOnscroll}
                onKeyDown={(e: any) => {
                    const targetEvent: any = e.target;
                    const idEvent = targetEvent.id;
                    if (
                        e.key === "Enter" &&
                        idEvent !== "Description" &&
                        !idEvent.includes("Advanced description")
                    ) {
                        e.preventDefault();
                    }
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <FormBody
                        initialDataCategory={initialDataCategory}
                        categoryState={categoryState}
                        dispatchCategory={dispatchCategory}
                        allParentsCategory={allParentsCategory}
                        defaultParentCategory={defaultParentCategory}
                        setDefaultParentCategory={setDefaultParentCategory}
                        selectedLinkCategories={selectedLinkCategories}
                        setSelectedLinkCategories={setSelectedLinkCategories}
                        addToRefs={addToRefs}
                        setSelectedLinkTags={setSelectedLinkTags}
                    />
                </CustomCardBody>

                <CardFooter className="Footercard p-0">
                    <FormFooter
                        handleCancelButton={handleCancelButton}
                        categoryState={categoryState}
                        elementType="category"
                    />
                </CardFooter>
            </AvForm>
        </Card>
    );
}
