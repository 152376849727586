import React from "react";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";

import LoadingDataAnimation from "@components/Common/LoadingDataAnimation";
import CustomCRUDColumn from "@components/Common/DynamicTableWrapper/ActionCRUDColumn";
import { DominantColor } from "@components/Common/dominantColor";
import { RootState } from "@store/.";
import UnsavedChangesWarningModal from "@components/Common/DynamicTableWrapper/UnsavedChangesWarningModal";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import DeleteAllergenModal from "./DelteAllergenModal";
import DuplicateAllergenModal from "./DuplicateAllergenModal";

export default function AllergensList() {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const isVisible = useSelector(
        (state: RootState) => state.headerCompoHeighSlice.isVisible
    );
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] =
        React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;
    const [selectedAllergen, setSelectedAllergen] = React.useState({
        iuud: "",
        title: "",
    });
    const dispatch = useDispatch();
    const [
        unsavedChangesWarningModalAction,
        setUnsavedChangesWarningModalAction,
    ] = React.useState<Function>(() => () => {});

    const [
        isUnsavedChangesWarningModalOpen,
        setIsUnsavedChangesWarningModalOpen,
    ] = React.useState(false);
    const isDirty = useSelector(
        (state: RootState) => state.editObserverSlice.isDirty
    );
    const costunJsxActions = (e: any) => (
        <CustomCRUDColumn
            e={e}
            handleDuplicate={handleDuplicate}
            handleEdit={() => handleEdit(e.selectedRow)}
            handleDelete={handleDelete}
        />
    );
    const arrayOfCustomColumns = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: costunJsxActions,
        },
    ];

    function handleEdit(e: any) {
        dynamicTableWrapperContext?.setRowData({
            iuud: e.iuud,
            data: e.allergen,
            group: e.Groupe,
        });
        dynamicTableWrapperContext?.setActionColumnDisplay(true);
    }
    function handleDuplicate(e: any) {
        setSelectedAllergen({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.allergen.title,
        });
        setIsDuplicateModalOpen(true);
    }
    function handleDelete(e: any) {
        setSelectedAllergen({
            iuud: e.selectedRow.iuud,
            title: e.selectedRow.allergen.title,
        });
        setIsDeleteModalOpen(true);
    }
    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData(null);
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed) => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };
    return !isLoading ? (
        <div>
            <DynamicTable
                url={`${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/allergens-details`}
                name="Allergens_table"
                canSort
                customJsxSideFilterButton={
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            onSubmit={(): void => {
                                if (isDirty) {
                                    setUnsavedChangesWarningModalAction(
                                        () => handleSubmit
                                    );
                                    setIsUnsavedChangesWarningModalOpen(true);
                                } else {
                                    dispatch(setIsEditing(false));
                                    handleSubmit();
                                }
                            }}
                        >
                            {!dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant={dominantColor}
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    {t("Add an allergen")}
                                </StyledButtonWithIcon>
                            )}
                            {dynamicTableWrapperContext?.isActionColumnDisplayed && (
                                <StyledIconButton
                                    style={{
                                        width: "40px",
                                        height: "48px",
                                    }}
                                    icon="PlusIcon"
                                    className="bg-dark"
                                    rounded
                                    disabled={
                                        cardType === "shop" &&
                                        cardStatus === "En production"
                                    }
                                >
                                    <PlusIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            )}
                        </AvForm>
                    </div>
                }
                canResize
                showGlobalFilter
                showFilter
                arrayOfCustomColumns={
                    cardType === "shop" && cardStatus === "En production"
                        ? []
                        : arrayOfCustomColumns
                }
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                filterActive={dynamicTableWrapperContext?.filterActive}
                setLocalFilterActive={
                    dynamicTableWrapperContext?.setLocalFilterActive
                }
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                onClick={(row: any) => {
                    if (isDirty) {
                        setIsUnsavedChangesWarningModalOpen(true);
                        setUnsavedChangesWarningModalAction(() => () => {
                            handleEdit(row.original);
                        });
                    } else {
                        dispatch(setIsEditing(false));
                        handleEdit(row.original);
                    }
                }}
            />

            <DeleteAllergenModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                cardIuud={cardIuud}
                allergenToDelete={selectedAllergen}
                setIsLoading={setIsLoading}
            />
            <DuplicateAllergenModal
                alleregnToDuplicate={selectedAllergen}
                isOpen={isDuplicateModalOpen}
                setIsOpen={setIsDuplicateModalOpen}
                setIsLoading={setIsLoading}
            />

            <UnsavedChangesWarningModal
                action={() => {
                    dispatch(initEditObserver());
                    unsavedChangesWarningModalAction();
                    setIsUnsavedChangesWarningModalOpen(false);
                }}
                setIsUnsavedChangesWarningModalOpen={
                    setIsUnsavedChangesWarningModalOpen
                }
                isUnsavedChangesWarningModalOpen={
                    isUnsavedChangesWarningModalOpen
                }
            />
        </div>
    ) : (
        <LoadingDataAnimation />
    );
}
