import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton, StyledH2, StyledModal } from "@aureskonnect/react-ui";

import { SuccessToast, DominantColor } from "@components/Common/.";
import { RootState } from "@store/index";
import { ACTIONS } from "@helpers/.";
import TableImage from "./TableImage";

type ImageModalPropsType = {
    dispatch: Function;
    advancedImages: sellingSupportType[] | undefined;
    setIsImageModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    isImageModalActive: boolean;
};

export default function ImageModal({
    dispatch,
    advancedImages,
    setIsImageModalActive,
    isImageModalActive,
}: ImageModalPropsType) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const [updatedAdvancedImages, setUpdatedAdvancedImages] = React.useState<
        sellingSupportType[] | undefined
    >(advancedImages);

    const handleValidateButtonOnClickEvent = () => {
        if (
            isEditing &&
            cardType === "shop" &&
            cardStatus === "En production"
        ) {
            closeModal();
        } else {
            dispatch({
                type: ACTIONS.SALES_SUPPORT_IMAGE_URL,
                payload: updatedAdvancedImages,
            });

            setIsImageModalActive(false);
            SuccessToast(t("Successful registration"));
        }
    };

    const closeModal = () => {
        setIsImageModalActive(false);

        setUpdatedAdvancedImages(advancedImages);
    };

    return (
        <StyledModal isOpen={isImageModalActive} size="xl" scrollable centered>
            <ModalHeader toggle={closeModal}>
                <StyledH2 className="text-uppercase">
                    {t("ADVANCED PICTURE")}
                </StyledH2>
            </ModalHeader>

            <ModalBody>
                <TableImage
                    setUpdatedAdvancedImages={setUpdatedAdvancedImages}
                    advancedImages={advancedImages}
                />
            </ModalBody>

            <ModalFooter>
                <StyledButton
                    rounded
                    outline
                    variant="light"
                    onClick={closeModal}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={handleValidateButtonOnClickEvent}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
