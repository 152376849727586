import {
    CarteIcon,
    CategoriesIcon,
    ItemIconRounded,
    TagIcon,
    WorkflowIcon,
    OptionIcon,
    StepIcon,
    AllergenIcon,
} from "@components/Common/SvgIcons";

import { CardMenuType } from "@components/Common/CardMenu/CardMenu";

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "CATEGORIES",
        number: 0,
        Icon: CategoriesIcon,
        description:
            "It is essential to have categories because they allow you to group together similar products.",
        path: "/categories",
    },
    {
        title: "ITEMS",
        number: 0,
        Icon: ItemIconRounded,
        description:
            "The article gathers all the informations related to the products offered to the customers.",
        path: "/items",
    },
    {
        title: "TAGS",
        number: 0,
        Icon: TagIcon,
        description:
            "Tags are used to group, search and filter different elements (categories, articles).",
        path: "/TAGS",
    },
    {
        title: "WORKFLOW",
        number: 0,
        Icon: WorkflowIcon,
        description:
            "The workflow represents the process of taking an order for the final customer.",
        path: "/WORKFLOW",
    },
    {
        title: "OPTIONS",
        number: 0,
        Icon: OptionIcon,
        description:
            "Options are the different variants of a product. Each option can have different values and only one default value.",
        path: "/options",
    },
    {
        title: "ALLERGENES",
        number: 0,
        Icon: AllergenIcon,
        description:
            "Allergens are the different variants of a product. Each allergen can take on different values.",
        path: "/allergens",
    },
    {
        title: "STEPS",
        number: 0,
        Icon: StepIcon,
        description:
            "It is essential to create and configure the steps that allow the customisation of articles. ",
        path: "/steps",
    },
    {
        title: "CHOOSE A CARD",
        number: 0,
        Icon: CarteIcon,
        description:
            "A card must be selected as the default card to manage it.",
        path: "/cards",
    },
];
