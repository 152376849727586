import React from "react";

import { StyledModal, StyledH2, StyledButton } from "@aureskonnect/react-ui";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DominantColor } from "@components/Common/";
import { useTranslation } from "react-i18next";
import AddItemsStepModalBody from "./AddItemsStepModalBody";

type AddItemsStepModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    stepDesignation: string;
    setSelectedDataRow: React.Dispatch<
        React.SetStateAction<StepItemWithIuud[]>
    >;
    selectedDataRow: StepItemWithIuud[];
    initialData?: any;
};
export default function AddItemsStepModal({
    isOpen,
    setIsOpen,
    stepDesignation,
    selectedDataRow,
    setSelectedDataRow,
    initialData,
}: AddItemsStepModalPropsType): JSX.Element {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const toggleOpen = () => {
        setIsOpen(false);
        setSelectedItems([]);
    };

    const [selectedItems, setSelectedItems] = React.useState<
        StepItemWithIuud[]
    >([]);

    return (
        <StyledModal isOpen={isOpen} centered size="xl">
            <ModalHeader toggle={toggleOpen}>
                <StyledH2 className="text-uppercase">
                    {stepDesignation !== "" ? (
                        <>
                            {t("add items to step")} "{stepDesignation}"
                        </>
                    ) : (
                        <>{t("add items to step")}</>
                    )}
                </StyledH2>
            </ModalHeader>
            <ModalBody
                style={{
                    maxHeight: "calc( 100vh - 40px)",
                }}
            >
                <AddItemsStepModalBody
                    setSelectedItems={setSelectedItems}
                    selectedDataRow={selectedDataRow}
                    stepDesignation={stepDesignation}
                    initialData={initialData}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={toggleOpen}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant={dominantColor}
                    onClick={() => {
                        setSelectedDataRow((prevState) => {
                            selectedItems.forEach(
                                // eslint-disable-next-line
                                (item, index) =>
                                    (item.rank = index + prevState.length + 1)
                            );
                            return [...prevState, ...selectedItems];
                        });

                        toggleOpen();
                    }}
                >
                    {t("Insert")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
