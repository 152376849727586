import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { legalExpression } from "@constants/RegExpression";

import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";

import { addListKeytoEdit } from "@store/features/multipleEditSlice";
import { ACTIONS, MULTI_EDIT_KEY } from "@helpers/actions";
import { RootState } from "@store/index";

type CaloriesPropsType = {
    isRedirect: boolean;
    value: any;
    dispatch: React.Dispatch<{
        type: string;
        payload: any;
    }>;
    // eslint-disable-next-line no-unused-vars
};

export default function Calories({
    isRedirect,
    value,
    dispatch,
}: CaloriesPropsType) {
    const { t } = useTranslation();
    const dispatchStore = useDispatch();
    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const handleCalorie = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({
            type: ACTIONS.CALORIES,
            payload: e.target.value,
        });
        dispatchStore(addListKeytoEdit(MULTI_EDIT_KEY.CALORIES));
    };
    return (
        <div className="py-2" id="calories">
            <StyledLabel>{t("Calories")}</StyledLabel>
            <StyledTextInput
                id="calories"
                name="calories"
                placeholder={t("Enter")}
                min="1"
                validate={{
                    pattern: {
                        value: legalExpression,
                        errorMessage: t("Please enter a valid value"),
                    },
                }}
                type="number"
                value={value}
                onChange={handleCalorie}
                disabled={
                    isRedirect ||
                    (isEditing &&
                        cardType === "shop" &&
                        cardStatus === "En production")
                }
            />
        </div>
    );
}
