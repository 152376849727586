import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";

import {
    ErrorToast,
    SuccessToast,
    DynamicTableWrapperContext,
    DominantColor,
} from "@components/Common/";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

export default function DuplicateCategoryModal({
    isShow,
    setIsShow,
    categoryToDuplicate,
    setIsLoading,
}: {
    isShow: boolean;
    setIsShow: Function;
    categoryToDuplicate: { iuud: string; title: string; nestedRowsId: any[] };
    setIsLoading: Function;
}): JSX.Element {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const [type, setType] = React.useState<number>(1);
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleOnDuplicateCategory = () => {
        if (validateOnclick) {
            setValidateOnclick(false);
            setIsLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/cards/${cardIuud}/${categoryToDuplicate.iuud}/${type}/categoryduplicated`
                )
                .then(() => {
                    setIsShow(!isShow);
                    setIsLoading(false);
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                })
                .then(() => {
                    SuccessToast(t("The category duplicated successfully"));
                })
                .catch(() => {
                    setIsLoading(false);
                    ErrorToast(t("There is a mistake!"));
                });
        }
    };
    return (
        <Modal centered isOpen={isShow}>
            <ModalHeader
                className="text-uppercase"
                toggle={(): boolean => setIsShow(!isShow)}
            >
                <div className="d-flex" style={{ whiteSpace: "nowrap" }}>
                    {t("Duplicate category ")}
                    <span className="mx-1">:</span>
                    {categoryToDuplicate.title.length > 20 ? (
                        <LockIconWithToolTip
                            type="icon"
                            lengthMax={0}
                            message={categoryToDuplicate.title}
                            id="Duplicate"
                        />
                    ) : null}

                    <div id="Duplicate" className="modal_clz">
                        {categoryToDuplicate.title}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to duplicate the category:")}
                <div>
                    <div
                        className="form-check"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            value="type1"
                            id="type1"
                            onChange={() => setType(1)}
                            disabled={false}
                            checked
                        />
                        <label className="form-check-label" htmlFor="type1">
                            {t("Only")}
                        </label>
                    </div>
                    <div
                        className="form-check"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            value="type2"
                            id="type2"
                            onChange={() => setType(2)}
                            disabled={false}
                        />
                        <label className="form-check-label" htmlFor="type2">
                            {t("With subcategories and articles")}
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            id="type3"
                            type="radio"
                            name="type"
                            value="type3"
                            onChange={() => setType(3)}
                            disabled={false}
                        />
                        <label className="form-check-label" htmlFor="type3">
                            {t(
                                " With sub-categories and articles and journey (workflow)"
                            )}
                        </label>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsShow(!isShow);
                        setType(1);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={handleOnDuplicateCategory}
                    rounded
                    variant={dominantColor}
                    disabled={type === 0}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
