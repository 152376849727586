/* eslint-disable no-unused-vars */
import React from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { StyledButton } from "@aureskonnect/react-ui";

import {
    DominantColor,
    DynamicTableWrapperContext,
    ErrorToast,
    RequirementToast,
    SuccessToast,
    WarningToast,
} from "@components/Common";
import { RootState } from "@store/index";
import {
    setIsEditing,
    setIsSubmitting,
} from "@store/features/editObserverSlice";

type FormFooterPropsType = {
    // eslint-disable-next-line no-unused-vars
    handleCancelButton: (e: React.MouseEvent<HTMLButtonElement>) => void;
    itemForm?: ItemType;
    categoryState?: CategoryType;
    elementType: string;
};
export default function FormFooter({
    handleCancelButton,
    itemForm,
    categoryState,
    elementType,
}: FormFooterPropsType) {
    const { t } = useTranslation();

    const dominantColor: string = DominantColor();
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");
    // const cardType = JSON.parse(
    //     localStorage.getItem("selectedCard") || "{}"
    // ).type;
    const ETKType = localStorage.getItem("ETKType");
    const isSelectionMemoized = useSelector(
        (state: RootState): any =>
            state.selectedCardListSlice.isSelectionMemoized
    );
    const selectedChoice = useSelector(
        (state: RootState): any => state.selectedCardListSlice.selectedChoice
    );
    const selectedCardList = useSelector(
        (state: RootState): any => state.selectedCardListSlice.selectedCardList
    );
    const updateItemWorkflow: any = useSelector(
        (state: RootState) => state.updateItemWorkflowSlice.updateItem
    );
    // const isDirty = useSelector(
    //     (state: RootState) => state.editObserverSlice.isDirty
    // );
    // const isEditing = useSelector(
    //     (state: RootState) => state.editObserverSlice.isEditing
    // );
    const currentValue = useSelector(
        (state: RootState) => state.editObserverSlice.currentValue
    );

    const oldValue = useSelector(
        (state: RootState) => state.editObserverSlice.oldValue
    );
    const oldCategorieParente = useSelector(
        (state: RootState): any => state.editObserverSlice.oldValue
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [isSelectionCardModalOpen, setIsSelectionCardModalOpen] =
        React.useState<boolean>(false);
    const [isModificationModalOpen, setIsModificationModalOpen] =
        React.useState<boolean>(false);

    const handleOnClickApplyChangesButton = () => {
        if (isSelectionMemoized) {
            handleUpdateCardsSliceMemoized();
        } else {
            setIsModificationModalOpen(!isModificationModalOpen);
        }
    };

    const handleUpdateCardsSliceMemoized = () => {
        if (isSelectionMemoized && selectedChoice === 1) {
            elementType === "item"
                ? handleUpdateAllCardsItem()
                : handleUpdateAllCardsCategory();
        } else if (isSelectionMemoized && selectedChoice === 2) {
            if (
                !isEqual(currentValue?.title, oldValue.title) ||
                !isEqual(
                    currentValue?.categoryParent,
                    oldValue.categoryParent
                ) ||
                !isEqual(currentValue?.reference, oldValue.reference)
            ) {
                WarningToast(
                    t(
                        "Les modifications effectuées sur les champs {“Désignation”, “Référence”, “Catégorie parente”} seront appliquées automatiquement sur toutes les boutiques."
                    )
                );
                elementType === "item"
                    ? handleUpdateAllCardsItem()
                    : handleUpdateAllCardsCategory();
            } else {
                elementType === "item"
                    ? handleUpdateSelectionOfCardsItem(selectedCardList)
                    : handleUpdateSelectionOfCardsCategory(selectedCardList);
            }
        }
    };
    const handleUpdateAllCardsCategory = async (): Promise<void> => {
        const data = new FormData();

        data.append(
            "oldParentCategorie",
            JSON.stringify(oldCategorieParente?.categoryParent)
        );

        data.append("category", JSON.stringify(categoryState));
        if (categoryState!.title === "") {
            RequirementToast(t("The field ''Designation '' must be filled"));
        } else {
            try {
                axios

                    .put(
                        `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/updateAllCardsCategory/${dynamicTableWrapperContext?.rowData?.iuud}`,
                        data
                    )
                    .then((): void => {
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );

                        SuccessToast(t("Cards updated successfully"));
                    });
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };

    const handleUpdateAllCardsItem = async (): Promise<void> => {
        const data = new FormData();
        data.append("item", JSON.stringify(itemForm));
        if (itemForm!.title === "") {
            RequirementToast(t("The field ''Designation '' must be filled"));
        } else if (itemForm!.categoryParent === "") {
            RequirementToast(t("The field ''Parent category'' must be filled"));
        } else {
            try {
                axios

                    .put(
                        `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/updateAllCardsItem/${dynamicTableWrapperContext?.rowData?.iuud}`,
                        data
                    )
                    .then((): void => {
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("Cards updated successfully"));
                    });
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    const handleUpdateSelectionOfCardsItem = async (
        cardsIuuds: string[]
    ): Promise<void> => {
        const data = new FormData();

        data.append("item", JSON.stringify(itemForm));

        if (itemForm!.categoryParent === "") {
            RequirementToast(t("The field ''Parent category'' must be filled"));
        } else {
            try {
                axios

                    .put(
                        `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/updateSelectionOfCardsItem/${dynamicTableWrapperContext?.rowData?.iuud}/${cardsIuuds}`,
                        data
                    )
                    .then((): void => {
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                        SuccessToast(t("Cards updated successfully"));
                    });
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    const handleUpdateSelectionOfCardsCategory = async (
        cardsIuuds: string[]
    ): Promise<void> => {
        const data = new FormData();
        dispatch(setIsEditing(true));
        data.append(
            "oldParentCategorie",
            JSON.stringify(oldCategorieParente?.categoryParent)
        );
        data.append("category", JSON.stringify(categoryState));
        try {
            axios
                .put(
                    `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/updateSelectionOfCardsCategory/${dynamicTableWrapperContext?.rowData?.iuud}/${cardsIuuds}`,
                    data
                )
                .then((): void => {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                    SuccessToast(t("Cards updated successfully"));
                });
        } catch (error) {
            ErrorToast(t("There is a mistake!"));
        }
    };

    const isSubmitting = useSelector(
        (state: RootState): boolean => state.editObserverSlice.isSubmitting
    );

    const dispatch = useDispatch();
    const validateButtonRef = React.useRef<HTMLButtonElement>(null);

    if (isSubmitting === true) {
        validateButtonRef.current?.click();
        dispatch(setIsSubmitting(false));
    }

    return (
        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
            {/* {cardType === "master" && isEditing ? (
                <>
                    <div className="p-3 bd-highlight ">
                        <StyledIconButton
                            icon="ShopIcon"
                            onClick={(e: any) => {
                                e.preventDefault();
                                setIsSelectionCardModalOpen(
                                    !isSelectionCardModalOpen
                                );
                            }}
                        >
                            <div id="Duplication">
                                <LockIconWithToolTip
                                    type="icon"
                                    lengthMax={0}
                                    message={t(`Sélection boutique`)}
                                    id="Duplication"
                                />
                                <ShopIcon height={25} width={28} />
                            </div>
                        </StyledIconButton>
                        <SelectionCardModal
                            isOpen={isSelectionCardModalOpen}
                            setIsOpen={setIsSelectionCardModalOpen}
                            elementType={elementType}
                            itemForm={itemForm}
                            categoryState={categoryState}
                        />
                    </div>
                    <div className="p-2 bd-highlight">
                        <StyledButton
                            rounded
                            variant={dominantColor}
                            disabled={!isDirty}
                            onClick={(e: any) => {
                                e.preventDefault();
                                handleOnClickApplyChangesButton();
                            }}
                        >
                            {t("Apply Changes")}
                        </StyledButton>
                        <ModificationModal
                            isOpen={isModificationModalOpen}
                            setIsOpen={setIsModificationModalOpen}
                            itemForm={itemForm}
                            categoryState={categoryState}
                            elementType={elementType}
                        />
                    </div>
                </>
            ) :
                (
                <div className="p-2 bd-highlight">rounded
                    <StyledButton rounded variant={dominantColor}>
                        {t("Validate")}
                    </StyledButton>
                </div>
                )
            } */}

            <div className="p-2 bd-highlight">
                <StyledButton
                    ref={validateButtonRef}
                    rounded
                    variant={dominantColor}
                    disabled={
                        updateItemWorkflow.pathUpdateItem.length > 0 &&
                        ETKType === "3"
                    }
                >
                    {t("Validate")}
                </StyledButton>
            </div>

            <div className="p-2 bd-highlight ">
                <StyledButton
                    rounded
                    outline
                    variant="light"
                    onClick={handleCancelButton}
                >
                    {t("Cancel")}
                </StyledButton>
            </div>
        </div>
    );
}
