/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
import { StyledH2 } from "@aureskonnect/react-ui";
import Choice from "./Choice";

type DropdownDisplayNameModalProps = {
    impression: LanguageType[];
    setImpression: React.Dispatch<React.SetStateAction<LanguageType[]>>;
    sellingSupport: sellingSupportType[];
    setSellingSupport: React.Dispatch<React.SetStateAction<sellingSupportType[]>>;
    support: string;
    setSupport: React.Dispatch<React.SetStateAction<string>>;
};

export default function DropdownDescriptionModal({
    impression,
    setImpression,
    sellingSupport,
    setSellingSupport,
    setSupport,
}: DropdownDisplayNameModalProps) {
    function handleInputChangeSupport(NameSupport: string) {
        const index = sellingSupport.findIndex(
            (element): boolean => element.sellingSupport === NameSupport
        );
        if (index !== -1) {
            sellingSupport[index].visibility = !sellingSupport[index].visibility;
        }
        setSellingSupport(sellingSupport);

        const filteredArrayLength = sellingSupport.filter(
            (item): boolean | undefined => item.visibility
        ).length;
        if (index !== -1 && filteredArrayLength === 0) {
            sellingSupport[index].visibility = true;
        }

        impression?.map((element: LanguageType): sellingSupportType[] => {
            const NameLangue: string = element.designation;

            sellingSupport[index].Langue.map((item: LanguageType): LanguageType => {
                if (item.designation === NameLangue) {
                    item.visibility = element.visibility;
                }
                return item;
            });
            return sellingSupport;
        });
        setSellingSupport(sellingSupport);
    }

    function handleInputChangeLangue(index: number, NameLangue: string) {
        if (index !== -1) {
            impression[index].visibility = !impression[index].visibility;
        }
        setImpression(impression);

        const filteredArrayLength = impression.filter(
            (item): boolean | undefined => item.visibility
        ).length;
        if (index !== -1 && filteredArrayLength === 0) {
            impression[index].visibility = true;
        }
        setImpression(impression);

        sellingSupport
            .filter((item: sellingSupportType): boolean | undefined => item.visibility)
            .map((item: sellingSupportType): sellingSupportType[] => {
                item.Langue.map((element: LanguageType): LanguageType => {
                    if (element.designation === NameLangue) {
                        if (filteredArrayLength === 0) {
                            element.visibility = true;
                        } else {
                            element.visibility = !element.visibility;
                        }
                        setSellingSupport(sellingSupport);
                    }
                    return element;
                });
                return sellingSupport;
            });

        setSellingSupport(sellingSupport);
    }

    return (
        <div className="dropdown">
            <Choice
                height={25}
                width={25}
                className="dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            />
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li className="mx-1">
                    <StyledH2
                        style={{
                            font: "15px / 18px Segoe UI",
                            fontWeight: "bold",
                        }}
                    >
                        Langues :
                    </StyledH2>
                </li>
                <hr
                    style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        borderColor: "rgba(0,0, 0, 0.12)",
                    }}
                />

                {impression.map((element: LanguageType, index: number) => (
                    <li key={`${index}+${element.designation}`}>
                        <div className="form-check my-2 mx-2 pl-0 d-flex justify-content-between">
                            <div className="item_font__CLZ">{element.designation}</div>
                            <div>
                                <input
                                    className="form-check-input mt-1"
                                    type="checkbox"
                                    checked={element.visibility}
                                    onChange={() => {
                                        handleInputChangeLangue(
                                            index,
                                            element.designation
                                        );
                                        setSupport(`${index}+${element.visibility}`);
                                    }}
                                    id={`${element.designation}`}
                                />
                            </div>
                        </div>
                    </li>
                ))}
                <li>
                    <StyledH2
                        style={{
                            font: "15px / 18px Segoe UI",
                            fontWeight: "bold",
                        }}
                    >
                        Supports de vente :
                    </StyledH2>
                </li>
                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />

                {sellingSupport.map((item: any, index: number) => (
                    <li key={`${index}+${item.support}`}>
                        <div className="form-check my-2 mx-2 pl-0 d-flex justify-content-between">
                            <div className="item_font__CLZ">{item.sellingSupport}</div>
                            <div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={item.visibility}
                                    onChange={() => {
                                        handleInputChangeSupport(item.sellingSupport);
                                        setSupport(`${index}+${item.visibility}`);
                                    }}
                                    id={item.sellingSupport}
                                />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
