/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import shortid from "shortid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/.";
import { LockIcon, PlusIcon, StyledIconButton } from "@aureskonnect/react-ui";

import { ArrowDownIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowDownIcon";
import { ArrowUpIcon } from "@components/VerticalLayout/StyledSidebarMenu/ArrowUpIcons";
import { WORKFLOW_LIST } from "@constants/WorkFlow";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { useTranslation } from "react-i18next";
import NumberOfChoices from "@components/Common/SvgIcons/NumberOfChoices";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";

import { setModifier } from "@store/features/modifierSlice";
import { setMultipleWorkflow } from "@store/features/multipleWorkflowSlice";

import WorkFlowCardModal from "./WorkFlowCardModal";
import { SearchData } from "../SearchData";
import { StepSalesExist, OrderProductWorkflowByRank } from "../helpers";
import {
    GetlistUuidItemsInStepBasicComposition,
    InsertOverrideBasicCompositionInItem,
    basicCompositionFormModalWorkflow,
    formElementBasicComposition,
    GlobalVisibilityBasicCompositionInModal,
} from "../hooks/helpersBasicComposition";
import {
    GetlistUuidOptions,
    optionFormModalWorkflow,
    GlobalVisibilityOptionInModal,
} from "../hooks/helpersOption";

import { VisibilityStepModal } from "../hooks/modal/step/VisibilityStepModal";
import { DeleteStepModal } from "../hooks/modal/step/DeleteStepModal";
import { DropdownStepModal } from "../hooks/modal/step/DropdownStepModal";
import { EditStepModal } from "../hooks/modal/step/EditStepModal";
import { AddStepModal } from "../hooks/modal/step/AddStepModal";
import { AddMultipleStepModal } from "../hooks/modal/step/AddMultipleStepModal";

import "../style.css";
import CrushedItemModalInModal from "../hooks/modal/step/CrushedItemModalInModal";

export type WorkFlowType = {
    globalIndex: number;
    title: string;
    data: any[];
    type: any;
    uuid: string;
    setContentModal: React.Dispatch<any>;
    contentModal: any;
    setIsModify: React.Dispatch<React.SetStateAction<boolean>>;
    isModify: boolean;
    cardData: any;
    workFlows: WorkFlowStepProps[];
    setIsOpenStepModal: React.Dispatch<React.SetStateAction<boolean>>;
    // isDragging: boolean;
    isClickStep: boolean;
    setIsClickStep: React.Dispatch<React.SetStateAction<boolean>>;
    setIndexSelected: React.Dispatch<React.SetStateAction<number>>;
    isMultipleStep: boolean;
    maxNbrClick: number;
    isRequired: boolean;
};
export default function WorkFlowStepModal({
    data,
    uuid,
    setContentModal,
    contentModal,
    isModify,
    setIsModify,
    cardData,
    globalIndex,
    workFlows,
    setIsOpenStepModal,
    // isDragging,
    isClickStep,
    setIsClickStep,
    setIndexSelected,
    isMultipleStep,
    maxNbrClick,
    isRequired,
}: WorkFlowType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cardType = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).type;
    const cardStatus = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).statut;

    const [isDragging, setIsDragging] = React.useState(false);
    const [isCrushed, setIsCrushed] = React.useState(false);
    const [action, setAction] = React.useState("");
    const [myData, setMyData] = React.useState(data ?? []);
    const [myDataToArray, setMyDataToArray] = React.useState([]);
    const [isGolbalOptionActive, setIsGolbalOptionActive] =
        React.useState(false);
    const [searchedStepDesignation, setSearchedStepDesignation] =
        React.useState("");
    const [isDropdownMenuActive, setIsDropdownMenuActive] =
        React.useState(false);

    const modifierWorkflow: any = useSelector(
        (state: RootState) => state.modifierSlice.modifier
    );
    const multipleWorkflow: any = useSelector(
        (state: RootState) => state.multipleWorkflowSlice.multipleWorkflow
    );

    const lengthStepModal =
        contentModal[contentModal.length - 1]?.data[
            // eslint-disable-next-line no-unsafe-optional-chaining
            contentModal[contentModal.length - 1]?.data.length - 1
        ];

    const ListeUuidStep: string[] = contentModal[contentModal.length - 1]?.data
        ?.filter((item: any) => item.type === "steps" && item.uuidStep !== "")
        ?.map((element: any) => element.uuidStep);

    const IsBasicCompositionStep =
        contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep ===
        WORKFLOW_LIST.BASICCOMPOSITION;

    const basicCompositionIsVisible = GlobalVisibilityBasicCompositionInModal(
        modifierWorkflow,
        contentModal[contentModal.length - 1]?.previousModifierStep,
        contentModal[contentModal.length - 1]?.previousUuidStep,
        contentModal[contentModal.length - 1]?.triggerItemIuud
    );

    const IsOptionStep =
        contentModal[contentModal.length - 1]?.data[globalIndex!]?.type ===
        WORKFLOW_LIST.OPTIONS;

    const optionIsVisible = GlobalVisibilityOptionInModal(
        modifierWorkflow,
        contentModal[contentModal.length - 1]?.previousModifierStep,
        contentModal[contentModal.length - 1]?.previousUuidStep,
        contentModal[contentModal.length - 1]?.triggerItemIuud,
        contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep
    );

    const hiddenOption = () => {
        setIsGolbalOptionActive(!isGolbalOptionActive);
    };
    const handleChangeRankBasicComposition = (
        source: any,
        destination: any
    ) => {
        const previousModiferStep =
            contentModal[contentModal.length - 1]?.previousModifierStep;
        const previousUuidStep =
            contentModal[contentModal.length - 1]?.previousUuidStep;
        const UuidItem = contentModal[contentModal.length - 1].triggerItemIuud;

        const isOverride =
            Object.keys(
                modifierWorkflow[previousModiferStep]?.steps[previousUuidStep]
                    ?.overrides || {}
            ).length > 0;
        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[previousModiferStep]?.steps[
                          previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(JSON.stringify(cardData?.steps?.[previousUuidStep]));

        let copyOverrideBasicComposition: WorkflowOptionType = {};
        // eslint-disable-next-line no-unused-expressions
        isOverride &&
        Object.keys(
            modifierWorkflow[previousModiferStep].steps[previousUuidStep]
                .overrides.stepItems[UuidItem].overrideBasicComposition || {}
        ).length > 0
            ? (copyOverrideBasicComposition =
                  modifierWorkflow[previousModiferStep].steps[previousUuidStep]
                      .overrides.stepItems[UuidItem].overrideBasicComposition)
            : (copyOverrideBasicComposition =
                  InsertOverrideBasicCompositionInItem(
                      copyOverrideBasicComposition,
                      cardData,
                      UuidItem
                  ));

        const arrayOptionsRank = GetlistUuidItemsInStepBasicComposition(
            modifierWorkflow,
            previousModiferStep,
            previousUuidStep,
            UuidItem,
            cardData,
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS
                : WORKFLOW_LIST.ADD
        );

        const copyOptionsRank = [...arrayOptionsRank];
        /* source Item Logic */
        const sourceItem = copyOptionsRank[source.index];
        copyOptionsRank.splice(source.index, 1);
        /* Destination Item Logic */
        copyOptionsRank.splice(destination.index, 0, sourceItem);

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [contentModal[contentModal.length - 1].triggerItemIuud]: {
                ...copyStepForm.stepItems[
                    contentModal[contentModal.length - 1].triggerItemIuud
                ],
                overrideBasicComposition: {
                    ...copyOverrideBasicComposition,
                    [WORKFLOW_LIST.BASICCOMPOSITION]: {
                        ...copyOverrideBasicComposition[
                            WORKFLOW_LIST.BASICCOMPOSITION
                        ],
                        ranks: copyOptionsRank,
                    },
                },
            },
        };

        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        const copyWorkflow = [...contentModal];
        copyWorkflow[contentModal.length - 1].data[globalIndex!] = {
            ...copyWorkflow[contentModal.length - 1].data[globalIndex!],
            data: Newlist,
        };
        setContentModal(copyWorkflow);

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [previousModiferStep]: {
                    ...modifierWorkflow[previousModiferStep],
                    steps: {
                        ...modifierWorkflow[previousModiferStep].steps,
                        [previousUuidStep]: {
                            ...modifierWorkflow[previousModiferStep].steps[
                                previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
    };
    const handleChangeRankOption = (source: any, destination: any) => {
        const UuidOptions =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep;
        const previousModiferStep =
            contentModal[contentModal.length - 1]?.previousModifierStep;
        const previousUuidStep =
            contentModal[contentModal.length - 1]?.previousUuidStep;
        const UuidItem = contentModal[contentModal.length - 1].triggerItemIuud;

        const isOverride =
            Object.keys(
                modifierWorkflow[previousModiferStep]?.steps[previousUuidStep]
                    ?.overrides || {}
            ).length > 0;
        const copyStepForm: any = isOverride
            ? JSON.parse(
                  JSON.stringify(
                      modifierWorkflow[previousModiferStep]?.steps[
                          previousUuidStep
                      ]?.overrides
                  )
              )
            : JSON.parse(JSON.stringify(cardData?.steps?.[previousUuidStep]));

        const arrayOptionsRank = GetlistUuidOptions(
            modifierWorkflow,
            previousModiferStep,
            previousUuidStep,
            UuidOptions,
            UuidItem,
            cardData,
            "rankOptions",
            contentModal[contentModal.length - 1].type === WORKFLOW_LIST.TRIGGER
                ? WORKFLOW_LIST.STEPS
                : WORKFLOW_LIST.ADD
        );

        const copyOptionsRank = [...arrayOptionsRank];
        /* source Item Logic */
        const sourceItem = copyOptionsRank[source.index];
        copyOptionsRank.splice(source.index, 1);
        /* Destination Item Logic */
        copyOptionsRank.splice(destination.index, 0, sourceItem);

        copyStepForm.stepItems = {
            ...copyStepForm.stepItems,
            [UuidItem]: {
                ...copyStepForm.stepItems[UuidItem],
                rankOptions: {
                    ...copyStepForm.stepItems[UuidItem].rankOptions,
                    [UuidOptions]: copyOptionsRank,
                },
            },
        };

        /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        const copyWorkflow = [...contentModal];
        copyWorkflow[contentModal.length - 1].data[globalIndex!] = {
            ...copyWorkflow[contentModal.length - 1].data[globalIndex!],
            data: Newlist,
        };
        setContentModal(copyWorkflow);

        dispatch(
            setModifier({
                ...modifierWorkflow,
                [previousModiferStep]: {
                    ...modifierWorkflow[previousModiferStep],
                    steps: {
                        ...modifierWorkflow[previousModiferStep].steps,
                        [previousUuidStep]: {
                            ...modifierWorkflow[previousModiferStep].steps[
                                previousUuidStep
                            ],
                            overrides: {
                                ...copyStepForm,
                            },
                        },
                    },
                },
            })
        );
    };
    const handleChangeRankSteps = (
        copyModifier: any,
        source: any,
        destination: any,
        UuidModifier: string,
        modalType: string,
        UuidStep: string
    ) => {
        const stepForm =
            Object.keys?.(
                copyModifier[UuidModifier][modalType][UuidStep]?.overrides || {}
            ).length > 0
                ? JSON.parse(
                      JSON.stringify(
                          copyModifier[UuidModifier][modalType][UuidStep]
                              ?.overrides
                      )
                  )
                : JSON.parse(JSON.stringify(cardData.steps[UuidStep]));
        /* update rank */
        const arrayToUpdateItemStep = Object.entries(stepForm.stepItems)
            .sort((a: any, b: any) => a[1].rank - b[1].rank)
            .reduce((acc: any, [key, value]: any) => {
                const obj: any = { key, value };
                acc.push(obj);
                return acc;
            }, []);
        const NewlistItemStep = OrderProductWorkflowByRank(
            arrayToUpdateItemStep,
            source.index,
            destination.index
        );
        /* update rank in override item in step */
        stepForm.stepItems = NewlistItemStep;
        /* update rank item instep in modifier */
        copyModifier[UuidModifier] = {
            ...copyModifier[UuidModifier],
            [modalType]: {
                ...copyModifier[UuidModifier][modalType],
                [UuidStep]: {
                    ...copyModifier[UuidModifier][modalType][UuidStep],
                    overrides: stepForm,
                },
            },
        };
        //     /* update rank in global workflow */
        const arrayToUpdate = [...myDataToArray];
        const Newlist = OrderProductWorkflowByRank(
            arrayToUpdate,
            source.index,
            destination.index
        );
        const copyWorkflow = [...contentModal];
        copyWorkflow[contentModal.length - 1].data[globalIndex!] = {
            ...copyWorkflow[contentModal.length - 1].data[globalIndex!],
            data: Newlist,
        };
        copyWorkflow[contentModal.length - 1].data[globalIndex!] = {
            ...copyWorkflow[contentModal.length - 1].data[globalIndex!],
            override: stepForm,
        };

        if (multipleWorkflow.length > 0) {
            const copymultipleStep = JSON.parse(
                JSON.stringify(multipleWorkflow)
            );
            copymultipleStep[copymultipleStep.length - 1] = {
                ...copymultipleStep[copymultipleStep.length - 1],
                VirtualModifier: copyModifier,
            };
            dispatch(setMultipleWorkflow(copymultipleStep));
        } else {
            dispatch(setModifier(copyModifier));
        }
        setContentModal(copyWorkflow);
    };
    const onDragEndSteps = (result: DropResult) => {
        const { destination, source } = result;
        setIsDragging(false);
        if (!destination) {
            return;
        }
        if (!source) {
            return;
        }
        if (cardType === "shop" && cardStatus === "En production") {
            return;
        }
        const UuidModifier =
            contentModal[contentModal.length - 1]?.previousModifier;
        const UuidStep =
            contentModal[contentModal.length - 1]?.data[globalIndex!]?.uuidStep;

        const modalType =
            contentModal[contentModal.length - 1]?.type === "trigger"
                ? "steps"
                : "add";

        const copyModifier =
            multipleWorkflow.length > 0
                ? JSON.parse(
                      JSON.stringify(
                          multipleWorkflow[multipleWorkflow.length - 1]
                              .VirtualModifier
                      )
                  )
                : JSON.parse(JSON.stringify(modifierWorkflow));

        if (IsBasicCompositionStep) {
            handleChangeRankBasicComposition(source, destination);
        } else {
            IsOptionStep
                ? handleChangeRankOption(source, destination)
                : handleChangeRankSteps(
                      copyModifier,
                      source,
                      destination,
                      UuidModifier,
                      modalType,
                      UuidStep
                  );
        }
    };

    React.useEffect(() => {
        if (
            contentModal[contentModal.length - 1]?.data[globalIndex!]
                ?.selected !== undefined &&
            contentModal[contentModal.length - 1]?.data[globalIndex!]
                ?.selected !== ""
        ) {
            setTimeout(() => {
                const divStepItem = document.getElementById(
                    `${
                        contentModal[contentModal.length - 1]?.data[
                            globalIndex!
                        ]?.selected
                    }${contentModal.length}${globalIndex}`
                );
                divStepItem &&
                    divStepItem?.scrollIntoView({
                        block: "end",
                    });
            }, 5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setMyDataToArray(
            Object.entries(myData)
                .sort((a: any, b: any) => a[1].rank - b[1].rank)
                .reduce((acc: any, [key, value]: any) => {
                    const obj: any = { key, value };
                    acc.push(obj);
                    return acc;
                }, [])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myData]);
    return (
        <div className="d-flex  " id={`${globalIndex!}${contentModal.length}`}>
            {myData && (
                <div
                    className=" mx-2 my-2"
                    style={{
                        minHeight: "calc(100vh - 270px)",
                        width: "420px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "0px 0px 5px 5px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderBottom: "1px solid #E4E4E4",
                    }}
                >
                    <div
                        style={{
                            // minWidth: "max-content",
                            minWidth: "420px",
                            display: "grid",
                            gridTemplateRows: "100px 0px auto",
                            gridRowGap: "6px",
                        }}
                    >
                        <div
                            style={{
                                font: "normal normal normal 18px/24px Segoe UI",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                boxShadow: "0px 1px 2px #0000001C",
                                border: "1px solid #E4E4E4",
                            }}
                        >
                            <div>
                                <div
                                    style={{ height: "40px" }}
                                    className=" d-flex align-items-center d-flex justify-content-center "
                                >
                                    <span className="mr-3 mt-2">
                                        {
                                            contentModal[
                                                contentModal.length - 1
                                            ]?.data[globalIndex]?.title
                                        }
                                        :
                                    </span>
                                    <DropdownStepModal
                                        IsBasicCompositionStep={
                                            IsBasicCompositionStep
                                        }
                                        IsOptionStep={IsOptionStep}
                                        isDropdownMenuActive={
                                            isDropdownMenuActive
                                        }
                                        setIsDropdownMenuActive={
                                            setIsDropdownMenuActive
                                        }
                                        searchedStepDesignation={
                                            searchedStepDesignation
                                        }
                                        cardData={cardData}
                                        globalIndex={globalIndex}
                                        ListeUuidStep={ListeUuidStep}
                                        setSearchedStepDesignation={
                                            setSearchedStepDesignation
                                        }
                                        setIsClickStep={setIsClickStep}
                                        isClickStep={isClickStep}
                                        setIndexSelected={setIndexSelected}
                                        contentModal={contentModal}
                                        setIsModify={setIsModify}
                                        isModify={isModify}
                                    />

                                    <div className="ml-2">
                                        <StyledIconButton
                                            icon="PlusIcon"
                                            style={{
                                                border: "1px solid #C6C6C6",
                                                borderRadius: "6px",
                                                visibility: "hidden",
                                            }}
                                        >
                                            <PlusIcon height={15} width={15} />
                                        </StyledIconButton>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        justifyContent: "end",
                                        paddingRight: "4px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="mt-2"
                                >
                                    <VisibilityStepModal
                                        IsBasicCompositionStep={
                                            IsBasicCompositionStep
                                        }
                                        IsOptionStep={IsOptionStep}
                                        optionIsVisible={optionIsVisible}
                                        basicCompositionIsVisible={
                                            basicCompositionIsVisible
                                        }
                                        setIndexSelected={setIndexSelected}
                                        globalIndex={globalIndex}
                                        setIsClickStep={setIsClickStep}
                                        isClickStep={isClickStep}
                                        contentModal={contentModal}
                                        cardData={cardData}
                                    />
                                    <DeleteStepModal
                                        isMultipleStep={isMultipleStep}
                                        IsBasicCompositionStep={
                                            IsBasicCompositionStep
                                        }
                                        IsOptionStep={IsOptionStep}
                                        isClickStep={isClickStep}
                                        setIsClickStep={setIsClickStep}
                                        contentModal={contentModal}
                                        setContentModal={setContentModal}
                                        globalIndex={globalIndex}
                                        setIsOpenStepModal={setIsOpenStepModal}
                                        setIndexSelected={setIndexSelected}
                                        setIsModify={setIsModify}
                                        isModify={isModify}
                                    />
                                    <AddMultipleStepModal
                                        IsBasicCompositionStep={
                                            IsBasicCompositionStep
                                        }
                                        IsOptionStep={IsOptionStep}
                                        isMultipleStep={isMultipleStep}
                                        globalIndex={globalIndex}
                                        cardData={cardData}
                                        setContentModal={setContentModal}
                                        setIsOpenStepModal={setIsOpenStepModal}
                                        uuid={uuid}
                                        contentModal={contentModal}
                                        setIsModify={setIsModify}
                                        isModify={isModify}
                                        setIsCrushed={setIsCrushed}
                                        setAction={setAction}
                                    />
                                    <EditStepModal
                                        isMultipleStep={isMultipleStep}
                                        IsBasicCompositionStep={
                                            IsBasicCompositionStep
                                        }
                                        IsOptionStep={IsOptionStep}
                                        data={data}
                                        globalIndex={globalIndex}
                                        uuid={uuid}
                                        cardData={cardData}
                                        setIsClickStep={setIsClickStep}
                                        isClickStep={isClickStep}
                                        setIndexSelected={setIndexSelected}
                                        contentModal={contentModal}
                                    />
                                    <div
                                        className={
                                            isMultipleStep
                                                ? "icon_step__CLZ text-center mr-2 disabledDiv__CLZ"
                                                : "icon_step__CLZ text-center mr-2"
                                        }
                                        style={{
                                            display:
                                                IsBasicCompositionStep ||
                                                IsOptionStep
                                                    ? "none"
                                                    : "d-flex justify-content-center align-items-center",
                                        }}
                                    >
                                        <LockIconWithToolTip
                                            type="icon"
                                            lengthMax={0}
                                            message={
                                                isRequired
                                                    ? t("Required step")
                                                    : t("Step not required")
                                            }
                                            id={`cad1${uuid}`}
                                        />

                                        <LockIcon
                                            id={`cad1${uuid}`}
                                            role="button"
                                            width={20}
                                            height={20}
                                            style={{
                                                fill:
                                                    // eslint-disable-next-line no-nested-ternary
                                                    contentModal.length > 0 &&
                                                    isRequired
                                                        ? "red"
                                                        : "black",
                                            }}
                                            onClick={() => {}}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display:
                                                IsBasicCompositionStep ||
                                                IsOptionStep
                                                    ? "none"
                                                    : "grid",
                                        }}
                                        className={
                                            isMultipleStep
                                                ? "disabledDiv__CLZ"
                                                : ""
                                        }
                                    >
                                        <div className="icon_nbr_click__CLZ  d-flex justify-content-center align-items-center mr-2">
                                            <div
                                                style={{
                                                    alignSelf: "baseline",
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <LockIconWithToolTip
                                                    type="icon"
                                                    lengthMax={0}
                                                    message={t(
                                                        `Max number of choices`
                                                    )}
                                                    id={`cad22${globalIndex}`}
                                                />
                                                <NumberOfChoices
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    id={`cad22${globalIndex}`}
                                                    height={20}
                                                    width={20}
                                                    fill="black"
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    font: "normal normal normal 15px/15px Segoe UI",
                                                }}
                                            >
                                                {contentModal.length > 0 &&
                                                    maxNbrClick}
                                            </div>
                                        </div>
                                    </div>
                                    <LockIconWithToolTip
                                        type="icon"
                                        lengthMax={0}
                                        message={
                                            isGolbalOptionActive
                                                ? t("Hide options")
                                                : t("Show options")
                                        }
                                        id={`Flèche${globalIndex}`}
                                    />
                                    <div
                                        id={`Flèche${globalIndex}`}
                                        className="icon_step__CLZ text-center"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            hiddenOption();
                                        }}
                                    >
                                        {isGolbalOptionActive ? (
                                            <ArrowUpIcon color="black" />
                                        ) : (
                                            <ArrowDownIcon color="black" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <CrushedItemModalInModal
                                isCrushed={isCrushed}
                                setIsCrushed={setIsCrushed}
                                globalIndex={globalIndex}
                                uuid={uuid}
                                action={action}
                                cardData={cardData}
                                setContentModal={setContentModal}
                                contentModal={contentModal}
                            />
                        </div>
                        <div style={{ zIndex: "10" }}>
                            <SearchData
                                data={data as any}
                                FilterData={setMyData as any}
                                cardData={cardData}
                                globalIndex={globalIndex}
                                checkstepItem
                                setIsUnsavedDataMultiple={setIsOpenStepModal}
                                IsBasicCompositionStep={IsBasicCompositionStep}
                                isModal
                                workflow={
                                    contentModal[contentModal.length - 1]?.data
                                }
                                setContentModal={setContentModal}
                                contentModal={contentModal}
                            />
                        </div>
                        <div
                            style={{
                                minHeight: `calc(100vh - 400px )`,
                                maxHeight: `calc(100vh - 400px )`,
                                overflowY: "auto",
                                alignContent: "center",
                                padding: "40px 10px",
                                paddingBottom: isDragging ? "150px" : "0px",
                            }}
                        >
                            <DragDropContext
                                onDragEnd={onDragEndSteps}
                                onBeforeDragStart={() => setIsDragging(true)}
                            >
                                <Droppable droppableId="droppableSteps">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {myDataToArray.length > 0 &&
                                                myDataToArray?.map(
                                                    (
                                                        element: any,
                                                        index: number
                                                    ) => (
                                                        <Draggable
                                                            key={element.key}
                                                            draggableId={
                                                                element.key
                                                            }
                                                            index={index}
                                                        >
                                                            {(provided) => {
                                                                const list: any =
                                                                    element.value;
                                                                const iuud =
                                                                    element.key;
                                                                let stepItem: any;
                                                                let CheckStepSalesExist: boolean =
                                                                    false;
                                                                if (
                                                                    Object.keys?.(
                                                                        list.content
                                                                    ).length > 0
                                                                ) {
                                                                    const UuidModifier =
                                                                        Object.keys(
                                                                            list.content
                                                                        )[0];

                                                                    CheckStepSalesExist =
                                                                        StepSalesExist(
                                                                            multipleWorkflow.length >
                                                                                0
                                                                                ? {
                                                                                      ...multipleWorkflow[
                                                                                          multipleWorkflow.length -
                                                                                              1
                                                                                      ]
                                                                                          .VirtualModifier,
                                                                                      ...modifierWorkflow,
                                                                                  }
                                                                                : modifierWorkflow,
                                                                            UuidModifier,

                                                                            cardData
                                                                        );
                                                                }
                                                                if (
                                                                    IsOptionStep ||
                                                                    IsBasicCompositionStep
                                                                ) {
                                                                    const productForm =
                                                                        IsOptionStep
                                                                            ? optionFormModalWorkflow(
                                                                                  modifierWorkflow,
                                                                                  contentModal,
                                                                                  globalIndex,
                                                                                  iuud
                                                                              )
                                                                            : basicCompositionFormModalWorkflow(
                                                                                  modifierWorkflow,
                                                                                  contentModal,
                                                                                  globalIndex,
                                                                                  iuud,
                                                                                  cardData,
                                                                                  list
                                                                              );
                                                                    stepItem = {
                                                                        // eslint-disable-next-line no-nested-ternary
                                                                        title: IsOptionStep
                                                                            ? cardData
                                                                                  .options?.[
                                                                                  contentModal[
                                                                                      contentModal.length -
                                                                                          1
                                                                                  ]
                                                                                      ?.data[
                                                                                      globalIndex!
                                                                                  ]
                                                                                      ?.uuidStep!
                                                                              ]
                                                                                  ?.values[
                                                                                  iuud
                                                                              ]
                                                                                  ?.title
                                                                            : Object.keys(
                                                                                  contentModal[
                                                                                      contentModal.length -
                                                                                          1
                                                                                  ]
                                                                                      ?.data[
                                                                                      globalIndex
                                                                                  ]
                                                                                      ?.override ||
                                                                                      {}
                                                                              )
                                                                                  .length >
                                                                              0
                                                                            ? contentModal[
                                                                                  contentModal.length -
                                                                                      1
                                                                              ]
                                                                                  ?.data[
                                                                                  globalIndex
                                                                              ]
                                                                                  ?.override
                                                                                  ?.stepItems?.[
                                                                                  iuud
                                                                              ]
                                                                                  .title
                                                                            : cardData?.[
                                                                                  list
                                                                                      .type
                                                                              ]?.[
                                                                                  iuud
                                                                              ]
                                                                                  .title,
                                                                        image: productForm.image,
                                                                        isVisible:
                                                                            productForm.isVisible,
                                                                        isOptionModifier:
                                                                            productForm.isOptionModifier,
                                                                        color: cardData?.[
                                                                            list
                                                                                .type
                                                                        ]?.[
                                                                            contentModal[
                                                                                contentModal.length -
                                                                                    1
                                                                            ]
                                                                                ?.data[
                                                                                globalIndex!
                                                                            ]
                                                                                ?.uuid!
                                                                        ]
                                                                            ?.color,
                                                                        formBasicComposition:
                                                                            IsBasicCompositionStep
                                                                                ? formElementBasicComposition(
                                                                                      iuud,
                                                                                      contentModal[
                                                                                          contentModal.length -
                                                                                              1
                                                                                      ]
                                                                                          ?.triggerItemIuud,
                                                                                      cardData
                                                                                  )
                                                                                : {
                                                                                      quantity: 1,
                                                                                      title: "",
                                                                                      isVisible:
                                                                                          true,
                                                                                      isObligatory:
                                                                                          false,
                                                                                  },
                                                                    };
                                                                } else {
                                                                    stepItem =
                                                                        Object.keys(
                                                                            contentModal[
                                                                                contentModal.length -
                                                                                    1
                                                                            ]
                                                                                ?.data[
                                                                                globalIndex
                                                                            ]
                                                                                ?.override ||
                                                                                {}
                                                                        )
                                                                            .length >
                                                                        0
                                                                            ? contentModal[
                                                                                  contentModal.length -
                                                                                      1
                                                                              ]
                                                                                  ?.data[
                                                                                  globalIndex
                                                                              ]
                                                                                  ?.override
                                                                                  ?.stepItems?.[
                                                                                  iuud
                                                                              ]
                                                                            : cardData?.[
                                                                                  list
                                                                                      .type
                                                                              ]?.[
                                                                                  iuud
                                                                              ];

                                                                    stepItem = {
                                                                        ...stepItem,
                                                                        image: cardData?.[
                                                                            list
                                                                                .type
                                                                        ]?.[
                                                                            iuud
                                                                        ]
                                                                            ?.imageUrl
                                                                            ?.Default
                                                                            ?.urlDefault,
                                                                    };
                                                                }
                                                                return (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <WorkFlowCardModal
                                                                            key={shortid.generate()}
                                                                            item={
                                                                                list
                                                                            }
                                                                            title={
                                                                                stepItem?.title
                                                                            }
                                                                            image={
                                                                                stepItem.image
                                                                            }
                                                                            isVisible={
                                                                                IsOptionStep ||
                                                                                IsBasicCompositionStep
                                                                                    ? stepItem.isVisible
                                                                                    : true
                                                                            }
                                                                            isOptionModifier={
                                                                                IsOptionStep ||
                                                                                IsBasicCompositionStep
                                                                                    ? stepItem.isOptionModifier
                                                                                    : ""
                                                                            }
                                                                            colorElement={
                                                                                stepItem?.color
                                                                            }
                                                                            order={
                                                                                index +
                                                                                1
                                                                            }
                                                                            type={
                                                                                list.type
                                                                            }
                                                                            uuid={
                                                                                iuud
                                                                            }
                                                                            subRows={
                                                                                list.subRows
                                                                                    ? list.subRows
                                                                                    : []
                                                                            }
                                                                            isGolbalOptionActive={
                                                                                isGolbalOptionActive
                                                                            }
                                                                            setContentModal={
                                                                                setContentModal
                                                                            }
                                                                            contentModal={
                                                                                contentModal
                                                                            }
                                                                            setIsModify={
                                                                                setIsModify
                                                                            }
                                                                            isModify={
                                                                                isModify
                                                                            }
                                                                            globalIndex={
                                                                                globalIndex
                                                                            }
                                                                            cardData={
                                                                                cardData
                                                                            }
                                                                            formBasicComposition={
                                                                                IsBasicCompositionStep
                                                                                    ? stepItem.formBasicComposition
                                                                                    : {
                                                                                          quantity: 1,
                                                                                          title: "",
                                                                                          isVisible:
                                                                                              true,
                                                                                          isObligatory:
                                                                                              false,
                                                                                      }
                                                                            }
                                                                            CheckStepSalesExist={
                                                                                CheckStepSalesExist
                                                                            }
                                                                            setIndexSelected={
                                                                                setIndexSelected
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    )
                                                )}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            )}
            {contentModal.length > 0 &&
                globalIndex ===
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    contentModal[contentModal.length - 1]?.data.length - 1 &&
                // !isDragging &&
                Object.keys(lengthStepModal?.data || {}).length > 0 && (
                    <AddStepModal
                        globalIndex={globalIndex}
                        setIndexSelected={setIndexSelected}
                        setIsClickStep={setIsClickStep}
                        isClickStep={isClickStep}
                        uuid={uuid}
                        contentModal={contentModal}
                        workFlows={workFlows}
                        setContentModal={setContentModal}
                        setIsModify={setIsModify}
                        isModify={isModify}
                    />
                )}
        </div>
    );
}
