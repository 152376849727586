import React from "react";
// import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import "../index.css";

export default function Footer(): JSX.Element {
    const { t } = useTranslation();
    return (
        <footer
            style={{
                backgroundColor: " #ffffff",
                marginTop: "10px",
                gridArea: "footer",
            }}
        >
            <div className="d-flex col-sm pb-md-row footer__clz">
                <div className="mx-3" />
                <div className="mt-4 mx-1 mb-md-4">
                    © {new Date().getFullYear()} Aureskonnect |
                </div>
                <div
                    className="mt-4 mx-1 mb-md-4 "
                    style={{ color: "#1890ff" }}
                >
                    <u>{t("Terms of use")}</u>
                </div>
                <div className="mt-4 mx-1 mb-md-4" style={{ color: "#1890ff" }}>
                    <u>{t("| Privacy Policy")}</u>
                </div>
            </div>
        </footer>
    );
}
