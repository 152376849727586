import React from "react";

import { useDispatch } from "react-redux";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";

import { setIsSubmitting } from "@store/features/editObserverSlice";
import { DominantColor } from "../dominantColor";

type UnsavedChangesWarningModalPropsType = {
    isUnsavedChangesWarningModalOpen: boolean;
    setIsUnsavedChangesWarningModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    action: Function;
};

export default function UnsavedChangesWarningModal({
    isUnsavedChangesWarningModalOpen,
    setIsUnsavedChangesWarningModalOpen,
    action,
}: UnsavedChangesWarningModalPropsType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();

    const dispatch = useDispatch();
    return (
        <Modal centered isOpen={isUnsavedChangesWarningModalOpen}>
            <ModalHeader
                toggle={() => setIsUnsavedChangesWarningModalOpen(false)}
            >
                <StyledH2 className="text-uppercase">
                    {t("Confirm Changes")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>{t("Do you want to save the changes made ?")}</ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton onClick={action} rounded variant="light" outline>
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        dispatch(setIsSubmitting(true));
                        setIsUnsavedChangesWarningModalOpen(false);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
