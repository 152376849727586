import React from "react";

export default function CategoriesIconMinimal(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={31}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} />
                    <stop offset={1} stopColor="gray" />
                </linearGradient>
            </defs>
            <g data-name="Groupe 27605" transform="translate(-.482)">
                <ellipse
                    data-name="Ellipse 698"
                    cx={16}
                    cy={15.5}
                    rx={16}
                    ry={15.5}
                    transform="translate(.482)"
                    // fill="red"
                />
                <g data-name="box (2)">
                    <g data-name="Groupe 13258">
                        <g data-name="Groupe 13257">
                            <path
                                data-name="Trac\xE9 9576"
                                d="m12.186 20.321-2.534-1.036a.643.643 0 0 0-.83.323.6.6 0 0 0 .336.8l2.534 1.036a.651.651 0 0 0 .247.049.634.634 0 0 0 .583-.372.6.6 0 0 0-.336-.8Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 13260">
                        <g data-name="Groupe 13259">
                            <path
                                data-name="Trac\xE9 9577"
                                d="M26.5 10.237a.6.6 0 0 0-.032-.186.544.544 0 0 0-.06-.091c-.014-.022-.024-.041-.039-.061a.606.606 0 0 0-.264-.2s-.005 0-.009-.006L15.961 6.041a.642.642 0 0 0-.471.008L6.621 9.704c-.009 0-.013.013-.022.017a.608.608 0 0 0-.222.179.637.637 0 0 0-.048.066.5.5 0 0 0-.042.059.591.591 0 0 0-.05.239v10.97a.61.61 0 0 0 .384.561l8.869 3.656a.652.652 0 0 0 .248.049.665.665 0 0 0 .223-.039l.023-.009 10.112-3.648a.61.61 0 0 0 .411-.57V10.265c.002-.011-.007-.019-.007-.028ZM15.106 23.965l-7.6-3.133v-9.643l7.6 3.133Zm.649-10.7-7.275-3 7.275-3 8.316 3-1.983.715-3.551-1.532a.645.645 0 0 0-.556.021l-2.489 1.02a.6.6 0 0 0 .006 1.127l2.3.915Zm4.419-.293 1.263-.457v2.974l-1.267.457Zm-.631-1.074-2.167-.859.9-.367 2.129.917Zm5.694 8.913-8.869 3.2v-9.668l2.539-.914v3.393a.606.606 0 0 0 .272.5.648.648 0 0 0 .361.108.671.671 0 0 0 .223-.039l2.534-.914a.612.612 0 0 0 .411-.57v-3.849l2.529-.915Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
