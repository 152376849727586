import React from "react";

import CategoryItems from "./CategoryItems";
import Category from "./Category";

import "../style.css";

type CategoryWithItemsPropsType = {
    category: CategoryWithChildrenType;
    selectedItems: ItemWithIuud[];
    collapsedCategoriesUuids: string[];
    setSelectedItems: React.Dispatch<React.SetStateAction<ItemWithIuud[]>>;
    setCollapsedCategoriesUuids: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function CategoryWithItems({
    category,
    selectedItems,
    collapsedCategoriesUuids,
    setSelectedItems,
    setCollapsedCategoriesUuids,
}: CategoryWithItemsPropsType) {
    const isCurrentCategorySelected =
        category.items.length === 0
            ? false
            : category.items.every((item) =>
                  selectedItems.some(
                      (selectedItem) => item.iuud === selectedItem.iuud
                  )
              );

    const addSelectedItem = (selectedItem: ItemWithIuud) =>
        setSelectedItems((selectedItems) => [...selectedItems, selectedItem]);

    const deleteSelectedItem = (selectedItem: ItemWithIuud) =>
        setSelectedItems((selectedItems) => [
            ...selectedItems.filter((item) => item.iuud !== selectedItem.iuud),
        ]);

    return category ? (
        <React.Fragment>
            <Category
                category={category}
                isCurrentCategorySelected={isCurrentCategorySelected}
                setCollapsedCategoriesUuids={setCollapsedCategoriesUuids}
                addSelectedItem={addSelectedItem}
                deleteSelectedItem={deleteSelectedItem}
            />
            <CategoryItems
                selectedItems={selectedItems}
                category={category}
                collapsedCategoriesUuids={collapsedCategoriesUuids}
                setCollapsedCategoriesUuids={setCollapsedCategoriesUuids}
                addSelectedItem={addSelectedItem}
                deleteSelectedItem={deleteSelectedItem}
                setSelectedItems={setSelectedItems}
            />
        </React.Fragment>
    ) : null;
}
