import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import { CardFooter } from "reactstrap";
import { Card } from "react-bootstrap";
import axios from "axios";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { TagIconMinimal } from "@components/Common/SvgIcons";
import {
    ErrorToast,
    SuccessToast,
    RequirementToast,
    DominantColor,
    DynamicTableWrapperContext,
    CustomCardBody,
} from "@components/Common/";
import { RootState } from "@store/.";

import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import StyledInputWithTooltip from "@components/Common/StyledComponent/StyledInputWithTooltip";
import { isMultiModify } from "@store/features/multipleEditSlice";
import useUnsavedChangesWarning from "@hooks/useUnsavedChangesWarning";
import {
    initEditObserver,
    setIsEditing,
} from "@store/features/editObserverSlice";
import Image from "./Image";

type addTagPropsType = { setIsLoading: Function };

export function AddTag({ setIsLoading }: addTagPropsType) {
    const CardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const { t } = useTranslation();
    const isVisible = useSelector(
        (state: RootState): boolean => state.headerCompoHeighSlice.isVisible
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [tagImage, setTagImage] = React.useState<string | Blob>("");
    const [tagIuud, setTagIuud] = React.useState<string>("");
    const [tags, setTags] = React.useState<TagType>({
        title: "",
        color: "#FFFFFF",
        imageUrl: "",
        itemsIuud: [],
        categoriesIuud: [],
    });
    const dispatch = useDispatch();
    const handleTagsFromChange = (event: string): void => {
        setTags({ ...tags, title: event });
    };
    const handleColorFromChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => setTags({ ...tags, color: e.target.value });
    const editTag = async (tag: FormData): Promise<void> =>
        axios
            .put(
                `${process.env.REACT_APP_ADB_API_URL}${schemaAdbFranchise}/${CardIuud}/tag/${tagIuud}`,
                tag
            )
            .then(({ data }: any): void => {
                if (data.error === true) {
                    ErrorToast(t("Designation already exists try another!"));
                } else {
                    setIsLoading(true);
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                    setIsLoading(false);
                    SuccessToast(t("Tag updated successfully"));
                }
                dynamicTableWrapperContext?.setDataUpdated(true);
            })
            .catch((): void => {
                ErrorToast(t("There is a mistake!"));
            })
            .finally(() => {
                setValidateOnclick(true);
            });
    const addTag = async (tag: FormData): Promise<void> =>
        axios
            .post(
                `${process.env.REACT_APP_ADB_API_URL}/tag/${schemaAdbFranchise}/${CardIuud}`,
                tag
            )
            .then((data: any): void => {
                if (data.data.error === true) {
                    ErrorToast(t("Designation already exists try another!"));
                } else {
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                    setIsLoading(false);
                    SuccessToast(t("Tag added successfully"));
                }

                dynamicTableWrapperContext?.setDataUpdated(true);
            })
            .catch((): void => {
                ErrorToast(t("There is a mistake!"));
            })
            .finally(() => {
                setValidateOnclick(true);
            });

    const insertTag = async (): Promise<void> => {
        if (validateOnclick) {
            setValidateOnclick(false);

            try {
                if (tags.title) {
                    const data = new FormData();

                    data.append("tag", JSON.stringify(tags));
                    data.append("image", tagImage);
                    dynamicTableWrapperContext?.rowData?.title
                        ? editTag(data)
                        : addTag(data);
                }
            } catch (error) {
                ErrorToast(t("There is a mistake!"));
            }
        }
    };
    const handleOnscroll = (): void => {
        if (tags.title === "") {
            RequirementToast(t("The ''Designation'' field must be filled in"));
        }
    };

    const handleRefreshIconClick = (): void => {
        dynamicTableWrapperContext?.setRowData({
            title: "",
            color: "#FFFFFF",
            imageUrl: "",
        });

        setTags({
            title: "",
            color: "#FFFFFF",
            imageUrl: "",
            itemsIuud: [],
            categoriesIuud: [],
        });
        setTagImage("");
    };

    const closeActionColumnDisplay = (): void => {
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
    };
    React.useEffect((): void => {
        dynamicTableWrapperContext?.rowData &&
            setTags({
                title: dynamicTableWrapperContext?.rowData?.title,
                color: dynamicTableWrapperContext?.rowData?.color,
                imageUrl: dynamicTableWrapperContext?.rowData?.imageUrl,
                categoriesIuud:
                    dynamicTableWrapperContext?.rowData?.categoriesIuud,
                itemsIuud: dynamicTableWrapperContext?.rowData?.itemsIuud,
            });
        dispatch(setIsEditing(true));
        setTagIuud(dynamicTableWrapperContext?.rowData?.iuud);
        setTagImage(dynamicTableWrapperContext?.rowData?.imageUrl);
    }, [dispatch, dynamicTableWrapperContext?.rowData]);

    useUnsavedChangesWarning(tags);

    const isSubmitting = useSelector(
        (state: RootState): boolean => state.editObserverSlice.isSubmitting
    );
    const validateButtonRef = React.useRef<HTMLButtonElement>(null);

    if (isSubmitting === true) {
        validateButtonRef.current?.click();
        dispatch(initEditObserver());
    }
    return (
        <Card style={{ border: "0px " }}>
            <StyleCardHeader
                title="Tag"
                refrechIconFunction={handleRefreshIconClick}
                Icon={
                    <TagIconMinimal
                        width={31}
                        height={31}
                        fill="#247295"
                        style={{
                            borderRadius: "100%",
                        }}
                    />
                }
            />

            <AvForm
                onValidSubmit={insertTag}
                onInvalidSubmit={handleOnscroll}
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === "Enter") e.preventDefault();
                }}
            >
                <CustomCardBody isTablet={isTablet} isVisible={isVisible}>
                    <div className="px-3 py-2">
                        <StyledLabel>{t("Designation *")}</StyledLabel>
                        <StyledInputWithTooltip
                            name="title"
                            id="Désignation"
                            requiredValidateValue={!!isMultiModify}
                            type="text"
                            value={tags?.title}
                            valueSetterFunction={handleTagsFromChange}
                            autoFocus
                            onFocus={(event: any) => {
                                event.target.select();
                            }}
                        />

                        <div className="  my-2 row d-flex align-content-center flex-wrap ">
                            <div className=" col-6 ">
                                <StyledLabel>
                                    {t("background color")}
                                </StyledLabel>

                                <StyledTextInput
                                    id="color"
                                    name="color"
                                    type="color"
                                    value={tags.color}
                                    onChange={handleColorFromChange}
                                />
                            </div>
                            <div className=" col-6 ">
                                <StyledLabel>
                                    {t("Hexadecimal code")}
                                </StyledLabel>

                                <StyledTextInput
                                    id="codeHexa"
                                    name="codeHexa"
                                    type="text"
                                    placeholder={t("Enter")}
                                    value={tags.color}
                                    onChange={handleColorFromChange}
                                />
                            </div>
                        </div>

                        <StyledLabel>{t("Picture")}</StyledLabel>
                        <Image
                            setImage={setTagImage}
                            imageUrl={tags.imageUrl}
                        />
                    </div>
                </CustomCardBody>
                <CardFooter className="Footercard p-0">
                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                        <div className="p-2 bd-highlight">
                            <StyledButton
                                rounded
                                variant={dominantColor}
                                ref={validateButtonRef}
                            >
                                {t("Validate")}
                            </StyledButton>
                        </div>
                        <div className="p-2 bd-highlight ">
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={closeActionColumnDisplay}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </AvForm>
        </Card>
    );
}
