import { ACTIONS } from "@helpers/actions";

type ColumnFileitemType = {
    index: string;
    name: string;
    value: string;
    selected: boolean;
};
export const ColumnFileitem: ColumnFileitemType[] = [
    {
        index: "Designation",
        name: "Désignation",
        value: ACTIONS.TITLE,
        selected: false,
    },
    {
        index: "Catégorie parente",
        name: "Catégorie parente",
        value: ACTIONS.PARENTS_CATEGORIES,
        selected: false,
    },

    {
        index: "Display name",
        name: "Nom d'affichage",
        value: ACTIONS.DEFAULT_DISPLAY_NAME,
        selected: false,
    },
    {
        index: "Description",
        name: "Description",
        value: ACTIONS.DEFAULT_DISCRIPTION,
        selected: false,
    },
    { index: "Color", name: "Couleur", value: ACTIONS.COLOR, selected: false },

    {
        index: "Loyalty points",
        name: "Fidélité",
        value: ACTIONS.FIDELITY,
        selected: false,
    },
    {
        index: "Calories",
        name: "Calories",
        value: ACTIONS.CALORIES,
        selected: false,
    },
    {
        index: "TVA",
        name: "TVA",
        value: ACTIONS.DEFAULT_TVA,
        selected: false,
    },
    {
        index: "Price",
        name: "Prix",
        value: ACTIONS.DEFAULT_PRICE,
        selected: false,
    },

    {
        index: "Picture",
        name: "Image",
        value: ACTIONS.DEFAULT_IMAGE_URL,
        selected: false,
    },
    {
        index: "Reference",
        name: "Référence",
        value: ACTIONS.REFERENCE,
        selected: false,
    },
];
