import React from "react";

import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";

import { StyledSearchInput, StyledButton } from "@aureskonnect/react-ui";
import { ErrorToast } from "@components/Common";
import DuplicateNameModal from "./DuplicateNameModal";

type FiltreType = {
    file: any;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    filteredRow: any;
    setFilteredRow: React.Dispatch<any>;
    render: boolean;
    setRender: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Filtre({
    step,
    file,
    setStep,
    filteredRow,
    setFilteredRow,
    setRender,
    render,
}: FiltreType) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isTableDataValid, setIsTableDataValid] =
        React.useState<boolean>(false);

    const handleOnSearchData = (targetValue: any) => {
        filteredRow = [];
        if (file !== null) {
            file.rows.forEach((row: []) => {
                row.forEach((itemRow: []) => {
                    if (
                        itemRow.toString().toLowerCase().includes(targetValue)
                    ) {
                        filteredRow.push(row);
                    }
                });
            });
        }

        setFilteredRow(filteredRow);
    };
    const changeOnSwitchSteps = () => {
        const ColumnsTitle: any = [];
        let isNextStepAuthorized = true;

        file.cols.forEach((element: any) => {
            if (element.visibility) {
                ColumnsTitle.push(t(element.index));
                if (element.name.includes("Colonne")) {
                    element.errorColumn = true;
                    isNextStepAuthorized = false;
                } else {
                    element.errorColumn = false;
                }
            }
        });

        // Find duplicate title
        setIsTableDataValid(
            ColumnsTitle.some((element: any, index: number) => {
                return ColumnsTitle.indexOf(element) !== index;
            })
        );

        // check if there is a column with no title
        if (!isNextStepAuthorized) {
            setRender(() => !render);
        }

        // check if there is a duplicate title
        if (isTableDataValid) {
            setIsOpen(true);
        } else if (!isNextStepAuthorized) {
            ErrorToast(t("The ''column'' field must be filled in"));
        } else if (ColumnsTitle.indexOf(t("Designation")) === -1) {
            ErrorToast(t("The Designation column is required"));
            isNextStepAuthorized = false;
        }

        if (isNextStepAuthorized && !isTableDataValid) {
            if (step < 3) {
                setStep(step + 1);
            }
        }
    };

    return (
        <div>
            {step <= 2 && (
                <Nav className="mt-2 ">
                    <div className="d-flex bd-highlight col-12">
                        <div className="p-2 flex-grow-1 bd-highlight  ">
                            <div
                                className="SearchInput"
                                style={{ width: "30%" }}
                            >
                                <StyledSearchInput
                                    placeholder={t("Search")}
                                    onChange={(event: any) => {
                                        if (event.target.value !== "") {
                                            handleOnSearchData(
                                                event.target.value
                                            );
                                        } else {
                                            setFilteredRow(file.rows);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className="p-2 bd-highlight"
                            style={{ visibility: "hidden" }}
                        >
                            <StyledButton outline rounded variant="primary">
                                Filtre(s)
                            </StyledButton>
                        </div>
                        <div className="p-2 bd-highlight">
                            <AvForm onSubmit={changeOnSwitchSteps}>
                                <StyledButton
                                    className={` ${
                                        file ? " " : " disabledDiv"
                                    }`}
                                    rounded
                                    variant="secondary"
                                >
                                    {((): string => {
                                        switch (step) {
                                            case 3:
                                                return t("To end");
                                            case 2:
                                                return t("Import");
                                            default:
                                                return t("Next");
                                        }
                                    })()}
                                </StyledButton>
                            </AvForm>
                        </div>
                    </div>
                </Nav>
            )}
            <DuplicateNameModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
}
