import axios from "axios";

export const uploadGetUrlImage = async (
    file: any,
    schemaAdbFranchise: string,
    usedCard: string
): Promise<void> => {
    const data = new FormData();
    data.append("DEFAULT_IMAGE", file);
    return axios.post(
        `${process.env.REACT_APP_ADB_API_URL}/workFlows/${schemaAdbFranchise}/${usedCard}`,
        data
    );
};
